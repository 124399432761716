import * as ActionTypes from "../common/ActionTypes";

// STATE
export interface GovernmentQuestionState {
    borrower: GovernmentQuestion;
    borrowerComplete: boolean;
    submitBorrower: boolean;
    coBorrower: GovernmentQuestion;
    coBorrowerComplete?: boolean;
    submitCoBorrower: boolean;
    isLoading: boolean;
    errorMessage?: string;
}
// MODEL
export interface GovernmentQuestion {
    loanGuid: string;
    borrowerType?: number;
    intentToOccupyIndicator?: boolean;
    homeownerPastThreeYearsIndicator?: boolean;
    priorPropertyUsageType?: string;
    priorPropertyTitleType?: string;
    urla2020CitizenshipResidencyType?: string;
    specialBorrowerSellerRelationshipIndicator?: boolean;
    undisclosedBorrowedFundsIndicator?: boolean;
    undisclosedMortgageApplicationIndicator?: boolean;
    undisclosedCreditApplicationIndicator?: boolean;
    propertyProposedCleanEnergyLienIndicator?: boolean;
    // section 5b
    undisclosedComakerOfNoteIndicator?: boolean;
    outstandingJudgementsIndicator?: boolean;
    presentlyDelinquentIndicatorURLA?: boolean;
    partyToLawsuitIndicatorURLA?: boolean;
    priorPropertyDeedInLieuConveyedIndicator?: boolean;
    priorPropertyShortSaleCompletedIndicator?: boolean;
    priorPropertyForeclosureCompletedIndicator?: boolean;
    bankruptcyIndicator?: boolean;
    isPermanentResidentAlienChecked?: boolean;
    isNonPermanentResidentAlienChecked?: boolean;
    loanPurpose: string;
    interimLoanStagingId?: number;
}

export enum BorrowerType {
    PrimaryBorrower = 0,
    CoBorrower = 1
}

// ACTIONS
interface UpdateGovernmentQuestions {
    type: typeof ActionTypes.UpdateGovernmentQuestions,
    value: string,
    propertyName: string,
    borrowerType: BorrowerType
}

interface SubmitGovernmentQuestions {
    type: typeof ActionTypes.SubmitGovernmentQuestions,
    borrowerType: BorrowerType,
    value: boolean
}

interface UpdateGovernmentAdditionalQuestions {
    type: typeof ActionTypes.UpdateGovernmentAdditionalQuestions,
    value: boolean,
    propertyName: string,
    borrowerType: BorrowerType
}

interface UpdateBorrowerCompleted {
    type: typeof ActionTypes.UpdateBorrowerCompleted,
    borrowerType: BorrowerType,
    value: boolean
}

interface SubmittedGovernmentQuestions {
    type: typeof ActionTypes.SubmittedGovernmentQuestions,
    borrowerData: GovernmentQuestion
}

interface RequestGovernmentQuestions {
    type: typeof ActionTypes.RequestGovernmentQuestions;
    borrowerType: string;
    interimLoanStagingId: number;
}

interface ReceiveGovernmentQuestions {
    type: typeof ActionTypes.ReceiveGovernmentQuestions;
    data: GovernmentQuestion;
    borrowerType: BorrowerType;
}

interface UpdateToInterim {
    type: typeof ActionTypes.UpdateToInterim,
    borrowerData: GovernmentQuestion
}

export type KnownAction =
    UpdateGovernmentQuestions |
    SubmitGovernmentQuestions |
    UpdateGovernmentAdditionalQuestions |
    UpdateBorrowerCompleted |
    SubmittedGovernmentQuestions |
    RequestGovernmentQuestions |
    ReceiveGovernmentQuestions |
    UpdateToInterim;