import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as GovernmentQuestionActions from "../../actions/governmentQuestionActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import * as GovernmentQuestionStore from "../../store/GovernmentQuestionStore";
import styles from "./GovernmentQuestions.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type BorrowerGovernmentQuestionsSection5bProps = GovernmentQuestionStore.GovernmentQuestionState & {
    borrowerName: string,
    borrowerType: GovernmentQuestionStore.BorrowerType;
    setBorrowerSection5bValid: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
}
    & typeof GovernmentQuestionActions.governmentQuestionActionCreators
    & typeof TaskMenuCreators;

const BorrowerGovernmentQuestionSection5b = (props: BorrowerGovernmentQuestionsSection5bProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    React.useEffect(() => {
        if (props.borrowerType === GovernmentQuestionStore.BorrowerType.PrimaryBorrower) {
            props.setBorrowerSection5bValid(validateBorrower(props.borrower));
        } else if (props.coBorrower) {
            props.setBorrowerSection5bValid(validateBorrower(props.coBorrower));
        }

    }, [props.borrower, props.coBorrower]);

    const validateBorrower = (borrower: GovernmentQuestionStore.GovernmentQuestion): boolean => {
        let isValid = true;
        if (
            borrower.undisclosedComakerOfNoteIndicator === undefined ||
            borrower.outstandingJudgementsIndicator === undefined ||
            borrower.presentlyDelinquentIndicatorURLA === undefined ||
            borrower.partyToLawsuitIndicatorURLA === undefined ||
            borrower.priorPropertyDeedInLieuConveyedIndicator === undefined ||
            borrower.priorPropertyShortSaleCompletedIndicator === undefined ||
            borrower.priorPropertyForeclosureCompletedIndicator === undefined ||
            borrower.bankruptcyIndicator === undefined
        ) {
            isValid = false;
        }
        return isValid;
    };
    const updateProperty = (value: boolean, fieldName: string) => {
        props.updateGovernmentAdditionalQuestions(value, fieldName, props.borrowerType);
    };
    let currentBorrower = props.borrowerType === GovernmentQuestionStore.BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower;
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <>
            <h1 className={styles.textCenter} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Government Questions Cont.')}>
                Government Questions Cont.
            </h1>
            <h2 className={styles.textCenter} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please answer for' + props.borrowerName)}> Please answer for {props.borrowerName}
            </h2>

            <div className={styles.borrowerQueForm}>
                <div className={styles.borrowerQuecontainer}>
                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(' Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application ?')}>
                            Are you a co-signer or guarantor on any debt or loan that is not
                            disclosed on this application?
                        </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.undisclosedComakerOfNoteIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "undisclosedComakerOfNoteIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.undisclosedComakerOfNoteIndicator &&
                                currentBorrower?.undisclosedComakerOfNoteIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "undisclosedComakerOfNoteIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Are there any Outstanding Judgements against you?')}>
                            Are there any Outstanding Judgements against you?
                    </label>

                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.outstandingJudgementsIndicator ? "active" : ""}`} onClick={() => updateProperty(true, "outstandingJudgementsIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                         </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.outstandingJudgementsIndicator &&
                                currentBorrower?.outstandingJudgementsIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "outstandingJudgementsIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                        </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Are you currently delinquent or in default on a federal debt?')}>
                            Are you currently delinquent or in default on a federal debt?
                    </label>
                        <div className={styles.answerButton}>

                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.presentlyDelinquentIndicatorURLA ? "active" : ""}`}
                                onClick={() => updateProperty(true, "presentlyDelinquentIndicatorURLA")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.presentlyDelinquentIndicatorURLA &&
                                currentBorrower?.presentlyDelinquentIndicatorURLA !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "presentlyDelinquentIndicatorURLA")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Are you party to a lawsuit in which you potentially have any personal financial liability ?')}>
                            Are you party to a lawsuit in which you potentially have any
                            personal financial liability?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.partyToLawsuitIndicatorURLA ? "active" : ""}`}
                                onClick={() => updateProperty(true, "partyToLawsuitIndicatorURLA")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                         </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.partyToLawsuitIndicatorURLA &&
                                currentBorrower?.partyToLawsuitIndicatorURLA !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "partyToLawsuitIndicatorURLA")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                        </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Have you conveyed title to any property in lieu of foreclosure in the past 7 Years ?')}>
                            Have you conveyed title to any property in lieu of foreclosure in
                            the past 7 Years?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.priorPropertyDeedInLieuConveyedIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "priorPropertyDeedInLieuConveyedIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.priorPropertyDeedInLieuConveyedIndicator &&
                                currentBorrower?.priorPropertyDeedInLieuConveyedIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "priorPropertyDeedInLieuConveyedIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Within the past 7 years have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the Lender agreed to accept less than the outstanding balance due ?')}>
                            Within the past 7 years have you completed a pre-foreclosure
                            sale or short sale, whereby the property was sold to a third
                            party and the Lender agreed to accept less than the
                            outstanding balance due?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.priorPropertyShortSaleCompletedIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "priorPropertyShortSaleCompletedIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button className={`common-button ${!currentBorrower?.priorPropertyShortSaleCompletedIndicator &&
                                currentBorrower?.priorPropertyShortSaleCompletedIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "priorPropertyShortSaleCompletedIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Have you had a property foreclosed upon in the last 7 years?')}>
                            Have you had a property foreclosed upon in the last 7 years?

                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.priorPropertyForeclosureCompletedIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "priorPropertyForeclosureCompletedIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.priorPropertyForeclosureCompletedIndicator &&
                                currentBorrower?.priorPropertyForeclosureCompletedIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "priorPropertyForeclosureCompletedIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>


                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Have you declared bankruptcy within the past 7 Years?')}>
                            Have you declared bankruptcy within the past 7 Years?

                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.bankruptcyIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "bankruptcyIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.bankruptcyIndicator &&
                                currentBorrower?.bankruptcyIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "bankruptcyIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: ApplicationState, ownProps: { borrowerType: GovernmentQuestionStore.BorrowerType, setBorrowerSection5bValid: React.Dispatch<React.SetStateAction<boolean>>, isLoanCompleted: boolean }): any => {
    return state.accountCreation ?
        {
            ...state.governmentQuestions,
            borrowerName: ownProps.borrowerType == GovernmentQuestionStore.BorrowerType.PrimaryBorrower ?
                state.accountCreation?.borrower?.firstName + " " + state.accountCreation?.borrower?.lastName :
                state.accountCreation?.coBorrower?.firstName + " " + state.accountCreation?.coBorrower?.lastName,
            borrowerType: ownProps.borrowerType,
            setBorrowerSection5bValid: ownProps.setBorrowerSection5bValid,
            disabled: ownProps.isLoanCompleted
        } :
        state.governmentQuestions;
}


const mapDispatchToProps = (dispatch: any) => ({
    updateGovernmentAdditionalQuestions: (value: boolean, propertyName: string, borrowerType: GovernmentQuestionStore.BorrowerType) => {
        dispatch(GovernmentQuestionActions.governmentQuestionActionCreators.updateGovernmentAdditionalQuestions(value, propertyName, borrowerType));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BorrowerGovernmentQuestionSection5b as any);