import * as React from "react";
import { connect, useSelector } from "react-redux";
import Infographic from "components/common/Infographic";
import { useNavigation } from "hooks/useNavigation";
import styles from "../components/loanView/Dashboard.module.scss";
import welcomeInfographic from "../assets/infographics/dashboard/welcome.svg";
import progressInfographic from "../assets/infographics/dashboard/progress.svg";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import applicationInfographic from "../assets/infographics/dashboard/application.svg";
import documentsInfographic from "../assets/infographics/dashboard/documents.svg";
import loanOfficerInfographic from "assets/infographics/dashboard/LoanOfficer.svg";
import { ApplicationState } from "../store";
import { TaskMenuItem, TaskMenuState } from "../store/taskMenuStore";
import {TaskStatusId, ProgressNavigationEnum } from "../components/common/progressTracker/TaskStatus";
import { useHistory } from "react-router";
import { DashboardInformation } from "../store/dashboardStore";
import { AccountInformationState } from "../store/accountInformationStore";
import { isNullOrWhiteSpace } from "../common/helper/formatHelper";
import { loanState } from "../store/loanStore";

type EncompassDownProps = {
    taskMenuItems?: TaskMenuItem[];
    dashboardInformation?: DashboardInformation;
}

const EncompassDown = (props: EncompassDownProps) => {
    const navigation = useNavigation(() => navigation.HideNavigation());
    const [percentage, setpercentage] = React.useState(0);
    const { loanGuid } = useSelector((state: ApplicationState) => state.accountInformation as AccountInformationState);
    const { isLoanCompleted, isLoanFromEncompassFlag } = useSelector((state: ApplicationState) => state.loan as loanState);

    const history = useHistory();

    React.useEffect(() => {
        if (props.dashboardInformation?.documentSubmitted && props.dashboardInformation?.documentRequested
            && props.dashboardInformation.documentSubmitted !== 0 && props.dashboardInformation.documentRequested !== 0) {
            setpercentage((props.dashboardInformation.documentSubmitted / props.dashboardInformation.documentRequested) * 100);
        }
    }, [props.dashboardInformation?.documentSubmitted, props.dashboardInformation?.documentRequested])


    const navigateRoute = (routeName: string, args: string) => {
   
        if (!isNullOrWhiteSpace(loanGuid)) {
            history.push({ pathname: routeName, state: { args: args } });
        }
    }

    return (
        <div className="w-100-p">
               <h2>Sorry for any inconvenience, but UHMNow  is temporarily not available for you to make changes or review information.   
      Please try back later.</h2>
                   
            <div className={styles.dashboardCardbg}>
                <div className={`${styles.card} ${styles.loanInfoCard} ${styles.closedLoancard}`}>
                    <div className={styles.cardTop}>
                        <div className={styles.cardLeft}>
                            <h6>My Loan Officer</h6>
                            <h4>Not Available</h4>
                        </div>
                        <div className={styles.cardRight}>
                            <div className={styles.tileIcons}>
                                <Infographic src={loanOfficerInfographic} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.card} ${styles.loanInfoCard} ${styles.closedLoancard}`}>
                    <div className={styles.cardTop}>
                        <div className={styles.cardLeft}>
                            <h6>Loan Progress</h6>
                            <h4>Not Available</h4>
                        </div>
                        <div className={styles.cardRight}>
                            <div className={styles.tileIcons}>
                                <Infographic src={progressInfographic} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.card} ${styles.loanInfoCard} ${styles.closedLoancard} `}>

                    <div className={styles.cardTop}>
                        {props.taskMenuItems != undefined && props?.taskMenuItems.length > 0  ? props?.taskMenuItems?.map((item, index) => (
                            <React.Fragment key={index}>
                                {index === 0 &&
                                    <div key={index} className={styles.cardLeft}
                                        onClick={() => navigateRoute(item.path, item.subTasks.filter(x => x.subTaskStatusId === TaskStatusId.InProgress)[0].queryParameter)}>
                                        <h6>Current Step:</h6>
                                        { isLoanCompleted ? <h4>Completed</h4> : < h4 > {item.name}</h4>}
                                    </div>
                                }
                            </React.Fragment>
                        )) :
                            <div className={styles.cardLeft} >
                                <h6>My Application / Current Step</h6>
                            <h4>Not Available</h4>
                            </div>}
                        <div className={styles.cardRight}>
                            <div className={styles.tileIcons}>
                                <Infographic src={applicationInfographic} />
                            </div>
                        </div>
                    </div>
                </div>

            {
                (isLoanCompleted && !isLoanFromEncompassFlag) && 

                <div className={`${styles.card} ${styles.loanInfoCard}`}
                    onClick={() => navigateRoute(ProgressNavigationEnum.BorrowerDocuments, "")}>
                    <div className={styles.cardTop}>
                        <div className={styles.cardLeft}>
                            <h6>
                                <span> {props.dashboardInformation?.documentSubmitted != props.dashboardInformation?.documentRequested
                                    && props.dashboardInformation?.documentSubmitted + '/' + props.dashboardInformation?.documentRequested}
                                </span> Uploaded</h6>
                            <h4>Documents</h4>
                        </div>
                        <div className={styles.cardRight}>
                            <div className={styles.tileIcons}>
                                <Infographic src={documentsInfographic} />
                            </div>
                        </div>
                    </div>
                    <span>View Document <i className="fa fa-angle-right"></i></span>
                </div>

                }
            </div>
                     
       </div>
    )
};
const mapStateToProps = (state: ApplicationState): any => {
    const { taskMenu } = state;
    let taskMenuItems;
    if (taskMenu?.data) {
        taskMenuItems = taskMenu?.data.filter((asset, index) => (asset.taskStatusId === TaskStatusId.InProgress));
    }
    return {
        taskMenuItems
    }
}

export default connect(mapStateToProps,null)(EncompassDown);