import * as React from "react";
import NumberFormat from 'react-number-format';
import { withReadOnlyWrapper } from "./withReadOnlyWrapper";

type NumberFormatInputProps = {
    label?: string;
    value: number;
    id?: string;
    disabled?: boolean;
    placeholder?: string;
};

const NumberFormatInputComponent = (props: NumberFormatInputProps) => {

    const label = (
        <>
            <label htmlFor={props.id} className="inline-label">
                {props.label}
            </label>
            &nbsp;
        </>
    );
    return (
        <div className="input-container number-format">
            {props.label && label}
            <NumberFormat  {...props}
                placeholder={props.placeholder}
                id={props.id}
                value={props.value.toString()}
                displayType={'input'}
                disabled={props.disabled}
            />
        </div>
    );
};

export const NumberFormatInput = withReadOnlyWrapper(NumberFormatInputComponent) as any;