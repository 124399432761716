import * as EmploymentInformationStore from "../../store/employmentInformationStore"

export const DummyData: EmploymentInformationStore.Employment = {
    id: "Employment/0",
    employmentId: 0,
    owner: "",
    employerName: "",
    positionDescription: "",
    employmentStartDate: "",
    addressCity: "",
    addressState: "",
    addressPostalCode: "",
    timeOnJobTermYears: 0,
    timeOnJobTermMonths: 0,
    basePayAmount: "",
    overtimeAmount: "",
    bonusAmount: "",
    commissionsAmount: "",
    otherAmount: "",
    needAdditionalEmployment: false,
    militaryEntitlement: undefined,
    urlA2020StreetAddress: "",
    totalAmount: 0,
    selfEmployedIndicator: undefined,
    currentEmploymentIndicator: undefined
};

export enum EmploymentInformationFields {
    Veteran = "veteran",
    ActiveMilitaryDuty = "activeMilitaryDuty",
    CurrentEmploymentDoesNotApply = "currentEmploymentDoesNotApply",
    SelfEmployedIndicator = "selfEmployedIndicator",
    SelfDeclaredMilitaryServiceIndicator = "selfDeclaredMilitaryServiceIndicator",
    AdditionalEmploymentDoesNotApply = "additionalEmploymentDoesNotApply",
    PreviousGrossMonthlyIncome = "previousGrossMonthlyIncome",
    PreviousEmploymentDoesNotApply = "previousEmploymentDoesNotApply",

    //employment
    TimeOnJobTermYears = "timeOnJobTermYears",
    TimeOnJobTermMonths = "timeOnJobTermMonths",
    CurrentEmploymentIndicator = "currentEmploymentIndicator",
    OwnershipInterestType = "ownershipInterestType",
    MonthlyIncomeAmount = "monthlyIncomeAmount",
    BasePayAmount = "basePayAmount",
    OvertimeAmount = "overtimeAmount",
    BonusAmount = "bonusAmount",
    CommissionsAmount = "commissionsAmount",
    OtherAmount = "otherAmount",
    TotalAmount = "totalAmount",
    EmployerName = "employerName",
    PositionDescription = "positionDescription",
    StartDate = "employmentStartDate",
    StreetAddress = "urlA2020StreetAddress",
    AddressState = "addressState",
    AddressCity = "addressCity",
    AddressPostalCode = "addressPostalCode",
    EmploymentMonthlyIncomeAmount = "employmentMonthlyIncomeAmount",
    Owner = "owner",
    IncomeDetail = "incomeDetail"
};

export enum EmploymentInformationQuestions {
    Veteran = "Are You a Veteran?",
    ActiveMilitaryDuty = "Are you in the active duty military?",
    CurrentEmployment = "Are you currently employed?",
    AdditionalEmployment = "Do you have past additional employment?",
    SelfEmployed = "Are you self-employed?",
    AdditionalSelfEmployment = "Were you self-employed?",
    OwnershipInterestType = "In your business do you have an ownership share of more than 25%?",
    MonthlyIncomeAmount = "What is your monthly income (or loss)?",
    AdditionalMonthlyIncomeAmount = "What was your previous gross monthly income (or loss)?",
    Finished = "We need information from the past two years.Please take the time to fill out a few more fields"
};

export enum EmploymentScreensNew {
    Landing = 0,
    Veteran = 1,
    ActiveMilitaryDuty = 2,
    CurrentlyEmployed = 3,
    SelfEmployed = 4,
    OwnerShipShare = 5,
    MonthlyIncome = 6,
    EmployerDetail = 7,
    IncomeDetail = 8,
    AdditionalEmployment = 11,
    AdditonalSelfEmployment = 12,
    AdditionalGrossMonthlyIncome = 13,
    PastEmployerDetail = 14,
    PastIncomeDetail = 15,
    AdditonalSelfEmployment1 = 21,
    PreviousGrossMonthlyIncome1 = 22,
    PastEmployerDetail1 = 23,
    Finished = 31,
    PreviousEmployment = 32,
    PreviousAdditionalInformationLanding = 33,
    PastIncomeDetail1 = 34
};

export interface ScreenDetails {
    header?: string
    infographics?: any
    fieldName: EmploymentInformationFields[]
    borrowerType?: EmploymentInformationStore.BorrowerType
    nextScreen?: EmploymentScreensNew
    skipScreen?: EmploymentScreensNew
    prevScreen?: EmploymentScreensNew
    currentScreen?: EmploymentScreensNew
    value?: any
    id?: string
    tooltip?: string
};

export const borrowerField = [EmploymentInformationFields.Veteran,
EmploymentInformationFields.ActiveMilitaryDuty,
EmploymentInformationFields.CurrentEmploymentDoesNotApply,
EmploymentInformationFields.AdditionalEmploymentDoesNotApply,
EmploymentInformationFields.SelfDeclaredMilitaryServiceIndicator,
EmploymentInformationFields.PreviousGrossMonthlyIncome,
EmploymentInformationFields.PreviousEmploymentDoesNotApply
]

export const fieldsForTotal = [EmploymentInformationFields.BasePayAmount, EmploymentInformationFields.OvertimeAmount,
EmploymentInformationFields.BonusAmount, EmploymentInformationFields.CommissionsAmount, EmploymentInformationFields.OtherAmount];

export const reverseNameFields = [EmploymentInformationFields.AdditionalEmploymentDoesNotApply, EmploymentInformationFields.CurrentEmploymentDoesNotApply,
EmploymentInformationFields.BonusAmount, EmploymentInformationFields.CommissionsAmount, EmploymentInformationFields.OtherAmount
    , EmploymentInformationFields.PreviousEmploymentDoesNotApply];