import * as React from "react";
import { connect } from "react-redux";
import * as AccountCreationStore from "../../store/accountCreationStore";
import { ApplicationState } from "../../store";
import * as AccountCreationActions from "../../actions/accountCreationActions";
import styles from "./reviewApplication.module.scss";
import TextInput from "../common/Input/TextInput";
import * as ReviewApplicationStore from "../../store/reviewApplicationStore";
import * as ReviewApplicationActions from "../../actions/reviewApplicationActions";
import LocationSearch from "components/common/Input/LocationSearch";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ReviewAddressInformationProps = {
    borrowerAddress: AccountCreationStore.Address;
    borrowerType: AccountCreationStore.BorrowerType;
    addressType: AccountCreationStore.AddressType;
    isEdit: boolean;
    section: string;
    setAddressInformationValid: (borrowerType: number) => void;
    disabled: boolean;
}
    & ReviewApplicationStore.ReviewApplicationState
    & typeof ReviewApplicationActions.reviewApplicationActionCreators
    & typeof AccountCreationActions.accountCreationActionCreators
    & typeof TaskMenuCreators;

const ReviewAddressInformation = (props: ReviewAddressInformationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [zipFormat, setZipFormat] = React.useState("#########");

    const handleChange = (value: string, propertyName: string, borrowerType: AccountCreationStore.BorrowerType,
        addressType: AccountCreationStore.AddressType) => {
        props.updateBorrowerAddressProperty(value, propertyName, borrowerType, addressType);
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        if (props.borrowerAddress?.addressPostalCode &&
            props.borrowerAddress?.addressPostalCode?.length > 5) {
            setZipFormat("#####-####");
            return;
        }
        setZipFormat("#########");
    }, [props.borrowerAddress?.addressPostalCode]);

    React.useEffect(() => {
        props.setAddressInformationValid(props.borrowerType);
    }, [props.borrowerAddress.addressCity,
    props.borrowerAddress.addressState,
    props.borrowerAddress.addressStreetLine1,
    props.borrowerAddress.addressPostalCode]);

    const updateAdditionalAddresses = (street: string, city: string, state: string, zip: string, item: AccountCreationStore.Address) => {
        item.addressStreetLine1 = street;
        item.addressCity = city;
        item.addressState = state;
        item.addressPostalCode = zip;
        props.EditAddressProperty(item, props.borrowerType);
    }

    return <>
        <div className={`${styles.inputGroup} ${styles.addressBox}`}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.addressType === AccountCreationStore.AddressType.CurrentAddress ? "Current Address" : "Prior Address")}>{props.addressType === AccountCreationStore.AddressType.CurrentAddress ? "Current" : "Prior"} Address <span className={styles.requiredMsg}> * </span>:
                </label>

            {props.addressType === AccountCreationStore.AddressType.CurrentAddress ?
                !props.isEdit ?
                    <div className={styles.inputContainer}>

                        <LocationSearch
                            isDisabled={props.disabled}
                            addressStreetLine1={props.borrowerAddress?.addressStreetLine1 || ""}
                            addresssFieldName={"addressStreetLine1"}
                            borrowerType={props.borrowerType}
                            customType={AccountCreationStore.AddressType.CurrentAddress}
                            setStoreAddress={props.updateBorrowerAddressProperty}
                            styles={""}
                        /> </div> : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerAddress?.addressStreetLine1)}>{props.borrowerAddress?.addressStreetLine1}</label>
                : !props.isEdit ? <div className={styles.inputContainer}>

                    <LocationSearch
                        isDisabled={props.disabled}
                        addressStreetLine1={props.borrowerAddress?.addressStreetLine1 ?? ""}
                        addresssFieldName={"addressStreetLine1"}
                        borrowerType={props.borrowerType}
                        customType={"AdditionalAddress"}
                        setFullAddress={(street, city, state, zip) => {
                            updateAdditionalAddresses(street, city, state, zip, props.borrowerAddress);
                        }}
                        styles={styles.input}
                    /></div>
                    : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerAddress?.addressStreetLine1)}>{props.borrowerAddress?.addressStreetLine1}</label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City')}>City <span className={styles.requiredMsg}> * </span>
                :</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#addressCity"
                name="City"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (props.addressType === AccountCreationStore.AddressType.CurrentAddress) {
                        handleChange(e.target.value, "addressCity", props.borrowerType, AccountCreationStore.AddressType.CurrentAddress)
                    } else {
                        props.borrowerAddress.addressCity = e.target.value;
                        props.EditAddressProperty(props.borrowerAddress, props.borrowerType);
                    }
                }}
                value={props.borrowerAddress?.addressCity || ""}
                readOnly={props.isEdit}
                maxLength="20"
                minLength="2"
                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerAddress?.addressCity || "")} />
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State
            <span className={styles.requiredMsg}> * </span>
                :</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#addressState"
                name="State"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (props.addressType === AccountCreationStore.AddressType.CurrentAddress) {
                        handleChange(e.target.value, "addressState", props.borrowerType, AccountCreationStore.AddressType.CurrentAddress)
                    } else {
                        props.borrowerAddress.addressState = e.target.value;
                        props.EditAddressProperty(props.borrowerAddress, props.borrowerType);
                    }
                }}
                value={props.borrowerAddress?.addressState?.toUpperCase() || ""}
                maxLength="2"
                minLength="2"
                readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerAddress?.addressState?.toUpperCase() || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Zip')}>Zip
            <span className={styles.requiredMsg}> * </span>
                :</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        className={styles.numberFormat}
                        value={props.borrowerAddress?.addressPostalCode || ''}
                        format={zipFormat}
                        name="addressPostalCode"
                        id="#addressPostalCode"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            if (props.addressType === AccountCreationStore.AddressType.CurrentAddress) {
                                handleChange(values.value, "addressPostalCode", props.borrowerType, AccountCreationStore.AddressType.CurrentAddress)
                            } else {
                                props.borrowerAddress.addressPostalCode = values.value;
                                props.EditAddressProperty(props.borrowerAddress, props.borrowerType);
                            }
                        }}
                        readOnly={props.isEdit}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerAddress?.addressPostalCode || '')} />
                </div>
                : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerAddress?.addressPostalCode)}>{props.borrowerAddress?.addressPostalCode}</label>
            }
        </div>

    </>;
};

const mapStateToProps = (state: ApplicationState, ownProps: {
    borrowerType: AccountCreationStore.BorrowerType,
    borrowerAddress: AccountCreationStore.Address,
    addressType: AccountCreationStore.AddressType,
    isEdit: boolean,
    section: string,
    setAddressInformationValid: (isValid: any, borrowerType: number) => void;
    isLoanCompleted: boolean
}): any => {
    return state.accountCreation ?
        {
            borrowerAddress: ownProps.borrowerAddress,
            borrowerType: ownProps.borrowerType,
            isEdit: ownProps.isEdit,
            section: ownProps.section,
            setAddressInformationValid: ownProps.setAddressInformationValid,
            addressType: ownProps.addressType,
            disabled: ownProps.isLoanCompleted
        } : state;
}

const mapDispatchToProps = (dispatch: any) => ({
    updateBorrowerAddressProperty: (value: string, propertyName: string, borrowerType: AccountCreationStore.BorrowerType, addressType: AccountCreationStore.AddressType) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.updateBorrowerAddressProperty(value, propertyName, borrowerType, addressType));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    EditAddressProperty: (address: AccountCreationStore.Address, borrowerType: AccountCreationStore.BorrowerType) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.EditAddressProperty(address, borrowerType));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAddressInformation as any);