import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { MilestoneNavigationState, KnownAction, } from "../store/MilestoneNavigationStore";
import { TaskStatus } from '../components/common/progressTracker/TaskStatus';

const unloadedState: MilestoneNavigationState = {
    estimatedClosingDate: "",
    actualClosingDate: "",
    creditAnalysis: "",
    closed: "",
    completingTheApplication: "",
    processingTheApplication: "",
    readyForClosing: ""
}

export const milestoneNavigationReducer: Reducer<MilestoneNavigationState> = (state: MilestoneNavigationState | undefined, incomingAction: Action): MilestoneNavigationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.ReceiveMilestoneDetails: {
            if (action.closed === "True") {
                action.closed = TaskStatus.InProgress;
                action.readyForClosing = TaskStatus.Completed;
                action.creditAnalysis = TaskStatus.Completed;
                action.completingTheApplication = TaskStatus.Completed;
                action.processingTheApplication = TaskStatus.Completed;
            } else if (action.readyForClosing === "True") {
                action.readyForClosing = TaskStatus.InProgress;
                action.creditAnalysis = TaskStatus.Completed;
                action.completingTheApplication = TaskStatus.Completed;
                action.processingTheApplication = TaskStatus.Completed;
            } else if (action.creditAnalysis === "True") {
                action.creditAnalysis = TaskStatus.InProgress;
                action.completingTheApplication = TaskStatus.Completed;
                action.processingTheApplication = TaskStatus.Completed;
            } else if (action.processingTheApplication === "True") {
                action.completingTheApplication = TaskStatus.Completed;
                action.processingTheApplication = TaskStatus.InProgress;
            } else {
                action.completingTheApplication = TaskStatus.InProgress;
            }

            return {
                ...state,
                estimatedClosingDate: action.estimatedClosingDate,
                actualClosingDate: action.actualClosingDate,
                closed: action.closed,
                completingTheApplication: action.completingTheApplication,
                creditAnalysis: action.creditAnalysis,
                readyForClosing: action.readyForClosing,
                processingTheApplication: action.processingTheApplication,
            };
        }
        default:
            return state;
    }
}