import * as React from "react";
import { connect, useSelector } from "react-redux";
import { dashboardActionCreators } from "../../actions/dashboardActions";
import { ApplicationState } from "../../store";
import { DashboardInformation, DashboardState } from "../../store/dashboardStore";
import styles from "./Dashboard.module.scss";
import { useNavigation } from "hooks/useNavigation";
import 'react-circular-progressbar/dist/styles.css';
import appFeePaymentInfographic from "assets/infographics/dashboard/appFeePayment.svg";
import applicationInfographic from "assets/infographics/dashboard/application.svg";
import documentsInfographic from "assets/infographics/dashboard/documents.svg";
import prequalLetterInfographic from "assets/infographics/dashboard/prequalLetter.svg";
import signDisclosuresInfographic from "assets/infographics/dashboard/signDisclosures.svg";
import progressInfographic from "assets/infographics/dashboard/progress.svg";
import Infographic from "../common/Infographic";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { TaskMenuItem } from "../../store/taskMenuStore";
import { useHistory } from "react-router";
import Loading from "../common/Loader";
import { MilestoneNavigationServiceActionCreators } from "../../actions/milestoneNavigationActions";
import { TaskStatus, TaskStatusId, ProgressNavigationEnum } from "../common/progressTracker/TaskStatus";
import { EnumMilestoneNavigation } from "../common/Enums/EnumMilestoneNavigation";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import { accountCreationActionCreators } from "../../actions/accountCreationActions";
import { Borrower } from "../../store/accountCreationStore";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { appConfigAsync } from "../../authConfig";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import EncompassDown from "../EncompassDown";
import { loanActionCreators } from "../../actions/loanActions";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import * as accountInformationService from "services/accountInformationService";
import UhmLogo from "assets/logo/uhm-logo.png";
import moment from 'moment';
import { NavigationState } from "../../store/navigationStore";
import { InterimLoanSummary, LoanOfficerDetail, LoanSummaryState } from "../../store/interimLoanSummaryStore";
import LoanViewHeader from "./LoanViewHeader";
import { UserAccountState } from "../../store/userAccountStore";
import { loanState } from "../../store/loanStore";
import { interimLoanSummaryActionCreators } from "../../actions/interimLoanSummaryActions";
import * as loanSummaryService from "../../services/interimLoanSummaryService";
import * as AccountInformationActions from "../../actions/accountInformationActions";
import { getLoanCompletedStatus } from "../../services/taskMenuService";
import { readIsLoanFromEncompass } from "services/accountInformationService";
import { isNullOrUndefined } from "util";
import { reviewApplicationActionCreators } from "../../actions/reviewApplicationActions";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type DashboardDetailsProps = {
    dashboardInformation: DashboardInformation;
    loanGuid: string;
    taskMenuItems: TaskMenuItem[];
    isLoading: boolean;
    milestoneStatus: string;
    borrower: Borrower;
    borrowerType: any;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    isLoanCompleted: boolean;
    isPopupFlag: boolean;
    loanSummary: LoanSummaryState;
    userAccountInformationObj: UserAccountState;
    interimLoanStagingIdOfLoan: number;
    submitFlag: boolean;
    loanFromEncompassMsgFlag: boolean;
}
    & typeof dashboardActionCreators
    & typeof TaskMenuCreators
    & typeof MilestoneNavigationServiceActionCreators
    & typeof accountCreationActionCreators
    & typeof loanUpdateResponseActionCreators
    & typeof loanActionCreators
    & typeof interimLoanSummaryActionCreators
    & typeof AccountInformationActions
    & typeof reviewApplicationActionCreators
    & DashboardState;

const DashboardDetails = (props: DashboardDetailsProps) => {
    const [submit, setSubmit] = React.useState(false);
    const [isOpen, setisOpen] = React.useState(true);
    const [date, setDate] = React.useState("");
    const [releaseDate, setReleaseDate] = React.useState("");
    const [currentLoan, setCurrentLoan] = React.useState<InterimLoanSummary>();
    const history = useHistory();
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const [responsive, setResponsive] = React.useState(false);
    const { isLoanFromEncompassFlag, isLoanCompleted } = useSelector((state: ApplicationState) => state.loan as loanState);
    const [hideDocumentTileFlag, setHideDocumentTileFlag] = React.useState(true);
    const [loanOfficerDetail, setLoanOfficerDetail] = React.useState<LoanOfficerDetail>();
    const [userMessage, setUseMessage] = React.useState(false);
    const [isOpenUserMessage, setIsOpenUserMessage] = React.useState(true);
    const [isLoanFromEncompassMessage, setIsLoanFromEncompassMessage] = React.useState(true);
    const [isModal, setIsModal] = React.useState(false);
    const { speak, cancel } = useSpeechSynthesis();

    const [currentStagId, setCurrentStagId] = React.useState(0);

    const [signInError, setSignInError] = React.useState(false);
    const [signInErrorMsg, setSignInErrorMsg] = React.useState({});


    const navigation = useNavigation(() => {
        navigation.RenameNavigation("Continue", "BACK TO ALL LOANS");
        navigation.SetBackOnClick(() => {
            history.push({ pathname: "/loan-view" });
        });

        if (props.loanSummary.loanSummary.length < 2) {
            navigation.HideNavigation();
        }
    });

    const navigateToeConsent = () => {
        history.push({ pathname: "/econsent" });
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    React.useEffect(() => {
        if (props.loanGuid && !isEncompassDown) {
            props.getDashboardDetails(props.loanGuid, props.borrowerType);
            props.GetSubTaskItemLoanStatus(props.loanGuid);
            props.getMilestoneDetails(props.loanGuid);
        }
        getLoanCompletedStatus(props.loanGuid).then(response => {
            //isLoanCompleted = response.parsedBody;
            props.setIsLoanCompleted(response.parsedBody);
        });
    }, [props.loanGuid])

    React.useEffect(() => {
        let currentLoanSummary: InterimLoanSummary | undefined;
        let stagginId = history.location.state?.staggingId;

        if (props.loanGuid && !isEncompassDown) {
            props.getDashboardDetails(props.loanGuid, props.borrowerType);
            props.GetSubTaskItemLoanStatus(props.loanGuid);
            props.getMilestoneDetails(props.loanGuid);
        } else {
            props.setIsLoading(true);
        }


        if (history.location.state && stagginId) {

            setCurrentStagId(stagginId);
            props.setIsLoading(true);
            loanSummaryService.ReadActiveLoanUserDetail(stagginId).then((response: any) => {
                if (response.status != 200) {
                    return;
                }
                return response.parsedBody as any;
            }).then((result: any) => {
                const consentStatus = result?.consentStatus;

                if (consentStatus === "" || consentStatus === null) {
                    history.push({ pathname: "/econsent" });
                }

                const loanGuid = result?.loanGuid;
                let isLoanCompleted: any;

                if (consentStatus === "Rejected") {
                    const msg = <div>
                        <h2>You have rejected the eConsent, you cannot continue applying for a Loan Application until you have agreed to the eConsent.  Please contact your loan officer for further assistance</h2>
                        <h3>Click <a onClick={navigateToeConsent}>HERE</a> to continue for eConsent.</h3>
                    </div>;
                    setSignInErrorMsg(msg);
                    setSignInError(true);
                    return;
                }

                if (consentStatus === "PrimaryRejected") {
                    const msg = <div>
                        <h2>The primary borrower has rejected the eConsent, you cannot continue applying for a Loan Application until the primary borrower has agreed to the eConsent.  Please contact your loan officer for further assistance</h2>
                        <h3>Click <a onClick={signOut}>HERE</a> to continue.</h3>
                    </div>;
                    setSignInErrorMsg(msg);
                    setSignInError(true);
                    return;
                }

                if (loanGuid) {
                    props.setLoanGuid(loanGuid);

                    getLoanCompletedStatus(loanGuid).then(response => {

                        props.setIsLoanCompleted(response.parsedBody);
                        isLoanCompleted = response.parsedBody;

                        readIsLoanFromEncompass(loanGuid)
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error("Unable to fetch loan from encompass flag.");
                                }
                                return response.parsedBody as any;
                            })
                            .then(data => {
                                props.setIsLoanFromEncompassFlag(data);

                                if (!isNullOrUndefined(isLoanCompleted) && data) {
                                    if (!isNullOrUndefined(consentStatus) && consentStatus !== "Accepted") {
                                        history.push({ pathname: "/econsent" });
                                    }
                                    else if (!isLoanCompleted) {
                                        history.push({ pathname: "/contact-preference" });
                                    }
                                    else if (isLoanCompleted) {
                                        history.push({ pathname: "/dashboard" });
                                    }
                                }
                            })
                            .catch((error: Error) => {
                                console.log(error.message);
                            });

                    }).catch((error: Error) => {
                        console.log("getLoanCompleted:", error.message);
                    });
                }
            }).catch((error: Error) => {
                console.error("Error occurred while reading ReadActiveLoanUserDetail data", error);
            });

            currentLoanSummary = props.loanSummary.loanSummary.find(x => x.interimLoanStagingId === stagginId);
            if (currentLoanSummary) {
                setCurrentLoan(currentLoanSummary);
                props.setCurrentInterimLoanSummary(currentLoanSummary);
                props.getBorrowerInformation(BorrowerTypeEnum.Borrower, currentLoanSummary.interimLoanStagingId);
                props.getBorrowerInformation(BorrowerTypeEnum.CoBorrower, currentLoanSummary.interimLoanStagingId);
            }
        } else {
            stagginId = props.loanSummary.currentLoanSummary.interimLoanStagingId;
            setCurrentStagId(stagginId);
            props.getInterimLoanSummary(props.userEmail);
        }

        props.setInterimLoanStagingId(stagginId);

        if (props.userEmail) {
            props.setIsLoading(true);
            loanSummaryService.ReadLoanOfficerDetailByInterimLoanStagingAsync(props.interimLoanStagingIdOfLoan).then(
                (response: any) => {
                    if (response.status != 200) {
                        return;;
                    }
                    let loInfo = response.parsedBody as LoanOfficerDetail;
                    setLoanOfficerDetail(loInfo);
                    props.setIsLoading(false);
                });
        }

    }, [])

    React.useEffect(() => {
        if (!history.location.state && props.loanSummary?.loanSummary.length > 0) {

            const currentLoanSummary = props.loanSummary.loanSummary.find(x => x.interimLoanStagingId === currentStagId);
            if (currentLoanSummary) {
                setCurrentLoan(currentLoanSummary);

                props.setInterimLoanStagingId(currentLoanSummary.interimLoanStagingId);
                props.setCurrentInterimLoanSummary(currentLoanSummary);
            }
        }
        if (props.loanSummary?.loanSummary.length === 1) {
            navigation.HideNavigation();
        }
    }, [props.loanSummary?.loanSummary])


    React.useEffect(() => {
        if (props.dashboardInformation.isEncompassDown) {
            props.setIsEncompassDown(true);
        }
        if (props.dashboardInformation.loanOriginationDate) {
            setDate(props.dashboardInformation.loanOriginationDate);
            setReleaseDate('05/23/2022');
        }
    }, [props.dashboardInformation.isEncompassDown, props.dashboardInformation.loanOriginationDate])


    const navigateRoute = (routeName: string, args: string) => {
        history.push({ pathname: "/" + routeName, state: { args: args } });
    }

    const signOut = async () => {
        if (!isNullOrWhiteSpace(props.loanGuid) && currentLoan) {
            await accountInformationService.signOut(props.borrowerType, props.loanGuid, currentLoan.interimLoanStagingId);
        }
        history.push("/");
        window.location.reload();
    }

    React.useEffect(() => {
        if (props.isPopupFlag && props.isLoanCompleted && !isLoanFromEncompassFlag) {
            setSubmit(true);
        }
        if (props.submitFlag) {
            setUseMessage(true);
            //props.submitCheck(false);
        }
    }, [])

    const openPaymentWindow = () => {
        appConfigAsync().then((config: any) => {
            const paymentUrl = config.PaymentDashboardUrl;
            const borrowerType = props.borrowerType === BorrowerTypeEnum.Borrower ? 0 : 1;
            window.open(`${paymentUrl}?loanGuid=${props.loanGuid}&borrowerType=${borrowerType}`, "_self", "noopener");
        });
    }

    const userMessageToggleModle = () => {
        setIsOpenUserMessage(!isOpenUserMessage);
        if (isOpenUserMessage) {
            setIsModal(true);
        }
    }

    const toggleModle = () => {
        setisOpen(!isOpen)
        props.setDocumentPopupFlag(false);
        props.submitCheckFlag(false);
    }
    const loanFromEncompassModle = () => {
        setIsLoanFromEncompassMessage(!isLoanFromEncompassMessage);
        props.setLoanFromEncompassMessageFlag(false);

    }

    return (
        <>
            {
                props.loanFromEncompassMsgFlag && isLoanFromEncompassFlag ?
                    <Modal className={styles.modal} isOpen={isLoanFromEncompassMessage} >
                        <ModalHeader className={styles.modalHeader} toggle={loanFromEncompassModle}>
                            <div className="content align-center">
                                <img src={UhmLogo} alt="Union Home Mortgage" />
                            </div>
                            <ModalBody className={styles.modalBody} >
                                <div>
                                    <h2>Thank you for setting up your UHMNOW account! Your application is completed.
                                    You will be notified when your LO activates your Documents module!</h2>
                                </div>
                            </ModalBody>
                        </ModalHeader>
                        <ModalFooter></ModalFooter>
                    </Modal> : ""
            }

            {submit && props.isPopupFlag && (!props.submitFlag ? true : isModal) ?
                <Modal className={styles.modal} isOpen={isOpen} >
                    <ModalHeader className={styles.modalHeader} toggle={toggleModle}>
                        <div>
                            <img src={UhmLogo} alt="Union Home Mortgage" />
                            <h1>Doc Upload</h1>
                            <h2>Please access the Document Upload module and provide the outstanding documents associated with your application.</h2>
                        </div>
                    </ModalHeader>
                    <ModalBody className={styles.modalBody}>
                        <div className="content align-center">
                            <button className={`common-green-button ${styles.button}`} onClick={() => { history.push(`/borrower-document-view`); props.setDocumentPopupFlag(false); props.submitCheckFlag(false) }}>Access Doc Upload</button>
                        </div>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </Modal>
                : ""}
            {userMessage ?
                <Modal className={styles.modal} isOpen={isOpenUserMessage} >
                    <ModalHeader className={styles.modalHeader} toggle={userMessageToggleModle}>
                        <div className="content align-center">
                            <img src={UhmLogo} alt="Union Home Mortgage" />
                        </div>
                        <ModalBody className={styles.modalBody} >
                            <div>
                                <h2>Thank you for completing our loan application. You should receive a response from {props.dashboardInformation.loanOfficerFirstName} {props.dashboardInformation.loanOfficerLastName} shortly.
                                    We appreciate your business and look forward to
                                    helping you achieve homeownership.</h2>
                            </div>
                        </ModalBody>
                    </ModalHeader>
                    <ModalFooter></ModalFooter>
                </Modal> : ""
            }


            {signInError ? <div className="align-center">
                {signInErrorMsg}
            </div> :

                (props.dashboardInformation.isEncompassDown || isEncompassDown) ?
                    <EncompassDown dashboardInformation={props.dashboardInformation} /> :
                    props.isLoading ? <Loading /> :
                        <div className={styles.dashboardPage}>

                            <div className={styles.dashboardBorrowerDetails}>
                                <LoanViewHeader
                                    userInfo={props.userAccountInformationObj}
                                    signOut={signOut}
                                    isResponsive={setResponsive}
                                    loanOfficerDetailInfo={loanOfficerDetail}
                                    showLoanOfficerDetail={true}
                                />
                                <div className={`${styles.loanDetails} ${responsive ? styles.displayFlex : ""}`}>
                                    <div className={styles.propertyAddress}>
                                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Property')}>Property</h6>
                                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(currentLoan?.zip !== undefined && currentLoan?.zip > 0 ? currentLoan?.address:"")}>{currentLoan?.address} </h4> {currentLoan?.address && <br />}
                                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(currentLoan?.zip !== undefined && currentLoan?.zip > 0 ? currentLoan?.city + "" + currentLoan?.state + "" + currentLoan?.zip:"")}>{currentLoan?.city}, {currentLoan?.state} {currentLoan?.zip}</h4>
                                    </div>
                                    <div>
                                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Number')}>Loan Number:</h6>
                                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(currentLoan?.loanNumber)}>{currentLoan?.loanNumber}</h4>
                                    </div>
                                    <div>
                                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Amount')}>Loan Amount:</h6>
                                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(currentLoan?.loanAmount)}>${currentLoan?.loanAmount}</h4>
                                    </div>
                                    <div>
                                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Term')}>Loan Term:</h6>
                                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(currentLoan?.loanTerm !== undefined && currentLoan?.loanTerm > 0 ? currentLoan?.loanTerm : "")}>{currentLoan?.loanTerm !== undefined && currentLoan?.loanTerm > 0 ? currentLoan?.loanTerm : ""}</h4>
                                    </div>
                                    <div>
                                        <div>
                                            <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Type')}>Loan Type:</h6>
                                            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(currentLoan?.loanType)}>{currentLoan?.loanType}</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className={styles.dashboardCardbg}>
                                <div className={`${styles.card} ${styles.loanInfoCard}`}>

                                    <div className={styles.cardTop}>
                                        {props.taskMenuItems && props.taskMenuItems.length > 0 &&
                                            <div className={styles.currentStep}>

                                                <div className={styles.cardLeft}
                                                    onClick={() => navigateRoute(props.taskMenuItems[0].path, props.taskMenuItems[0].subTasks.filter(x => x.subTaskStatusId === TaskStatusId.InProgress)[0].queryParameter)}>
                                                <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Current Step')}>Current Step:</h6>
                                                {isLoanFromEncompassFlag && isLoanCompleted ? <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Completed')}>Completed</h4> : < h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.taskMenuItems[0].name)}> {props.taskMenuItems[0].name} </h4>}
                                                </div>

                                            </div>
                                        }
                                        {props?.taskMenuItems?.length < 1 && <div className={styles.cardLeft} >
                                            <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Current Step')}>Current Step</h6>
                                            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Application Complete')}>Application Complete</h4>
                                        </div>}
                                        <div className={styles.cardRight}>
                                            <div className={styles.tileIcons}>
                                                <Infographic src={applicationInfographic} />
                                            </div>
                                        </div>
                                    </div>
                                    <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Review Application')}>Review Application <i className="fa fa-angle-right"></i></span>
                                </div>

                                {(isLoanFromEncompassFlag ? hideDocumentTileFlag : true) &&

                                    <div className={`${styles.card} ${styles.loanInfoCard}`}
                                        onClick={() => navigateRoute(ProgressNavigationEnum.BorrowerDocuments, "")}>
                                        <div className={styles.cardTop}>
                                            <div className={styles.cardLeft}>
                                                <h6>
                                                    <span> {props.dashboardInformation.documentSubmitted != props.dashboardInformation.documentRequested
                                                        && props.dashboardInformation.documentSubmitted + '/' + props.dashboardInformation.documentRequested}
                                                    </span> Uploaded</h6>
                                            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Documents')}>Documents</h4>
                                            </div>
                                            <div className={styles.cardRight}>
                                                <div className={styles.tileIcons}>
                                                    <Infographic src={documentsInfographic} />
                                                </div>
                                            </div>
                                        </div>
                                    <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('View Document')}>View Document <i className="fa fa-angle-right"></i></span>
                                    </div>}

                                <div className={`${styles.card} ${styles.loanInfoCard}`} onClick={() => navigateRoute(ProgressNavigationEnum.MilestoneProgress, "")}>
                                    <div className={styles.cardTop}>
                                        <div className={styles.cardLeft}>
                                            <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Progress')}>Loan Progress</h6>
                                            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Processing the Application')}>Processing the Application</h4>
                                        </div>
                                        <div className={styles.cardRight}>
                                            <div className={styles.tileIcons}>
                                                <Infographic src={progressInfographic} />
                                            </div>
                                        </div>
                                    </div>
                                    <span>Learn more <i className="fa fa-angle-right"></i></span>
                                </div>



                                {props.dashboardInformation.isDisclosureAuthenticated === true &&

                                    <div className={`${styles.card} ${styles.loanInfoCard}`} onClick={() => window.open("/document", "_blank", "noopener")}>
                                        <div className={styles.cardTop}>
                                            <div className={styles.cardLeft}>
                                            <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Sign Disclosures')}>Sign Disclosures</h6>
                                            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Sign Disclosures')}>Sign Disclosures</h4>
                                            </div>
                                            <div className={styles.cardRight}>
                                                <div className={styles.tileIcons}>
                                                    <Infographic src={signDisclosuresInfographic} />
                                                </div>
                                            </div>
                                        </div>
                                        <span>Learn more <i className="fa fa-angle-right"></i></span>
                                    </div>
                                }

                                {(props.dashboardInformation.isPreQualInitiate && props.dashboardInformation.loanPurpose === "Purchase" && !isLoanFromEncompassFlag
                                    && (Date.parse(date) < Date.parse(moment(new Date()).format(releaseDate)))) &&

                                    <div className={`${styles.card} ${styles.loanInfoCard}`} onClick={() => navigateRoute(ProgressNavigationEnum.SystematicLetter, "")}>
                                        <div className={styles.cardTop}>
                                            <div className={styles.cardLeft}>
                                            <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Prequalification Letter')}>Prequalification Letter</h6>
                                            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Prequalification Letter')}>Prequalification Letter</h4>
                                            </div>
                                            <div className={styles.cardRight}>
                                                <div className={styles.tileIcons}>
                                                    <Infographic src={prequalLetterInfographic} />
                                                </div>
                                            </div>
                                        </div>
                                        <span>Learn more <i className="fa fa-angle-right"></i></span>

                                    </div>
                                }
                                {(props.dashboardInformation.loanPurpose === "Purchase" && props.dashboardInformation.preApprovalLetterFlag) &&

                                    <div className={`${styles.card} ${styles.loanInfoCard}`} onClick={() => navigateRoute(ProgressNavigationEnum.BorrowerPreApprovalLetter, "")}>
                                        <div className={styles.cardTop}>
                                            <div className={styles.cardLeft}>
                                            <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Pre-approval Letter')}>Pre-approval Letter</h6>
                                            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('You are Pre - Approved')}>You're Pre-Approved</h4>
                                            </div>
                                            <div className={styles.cardRight}>
                                                <div className={styles.tileIcons}>
                                                    <Infographic src={applicationInfographic} />
                                                </div>
                                            </div>
                                        </div>
                                        <span>View Your Letter <i className="fa fa-angle-right"></i></span>
                                    </div>

                                }

                                {(props.dashboardInformation.appFeeComplete === false ?

                                    <div className={`${styles.card} ${styles.loanInfoCard}`} onClick={() => openPaymentWindow()}>
                                        <div className={styles.cardTop}>
                                            <div className={styles.cardLeft}>
                                                <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('App Fee Payment')}>App Fee Payment</h6>
                                                <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('App Fee Payment')}>App Fee Payment</h4>
                                            </div>
                                            <div className={styles.cardRight}>
                                                <div className={styles.tileIcons}>
                                                    <Infographic src={appFeePaymentInfographic} />
                                                </div>
                                            </div>
                                        </div>
                                        <span>Make Your Payment <i className="fa fa-angle-right"></i></span>
                                    </div> : ""
                                )}


                            </div>

                        </div>

            }


        </>
    );
};

const mapStateToProps = (state: ApplicationState): any => {
    const { accountInformation, accountCreation, dashboard, milestoneNavigation, userAccountInformation, interimLoanSummary } = state;

    const taskMenu = state.taskMenu;
    let loanGuid;
    let dashboardInformation;
    let taskMenuItems;
    let milestoneStatus;
    let borrower;
    let isLoading = dashboard?.isLoading;
    let borrowerType;
    const isLoanCompleted = state.loan?.isLoanCompleted;
    let isPopupFlag = state.taskMenu?.isPopupFlag;
    let submitFlag = state.reviewApplication?.submitCheckFlag;
    let loanFromEncompassMsgFlag = state.reviewApplication?.loanFromEncompassMessageFlag;


    const loanSummary = interimLoanSummary;
    const interimLoanStagingIdOfLoan = state.loan?.interimLoanStagingId;

    let userAccountInformationObj: UserAccountState | undefined;
    userAccountInformationObj = userAccountInformation;

    if (accountInformation?.loanGuid) {
        loanGuid = accountInformation.loanGuid;
    }

    if (userAccountInformation?.borrowerType) {
        borrowerType = userAccountInformation?.borrowerType;
    }

    if (accountCreation?.borrower) {
        borrower = accountCreation.borrower;
    }

    if (dashboard?.dashboardInformation) {
        dashboardInformation = dashboard.dashboardInformation;
    }

    if (taskMenu?.data) {
        taskMenuItems = taskMenu?.data.filter((asset, index) => (asset.taskStatusId === TaskStatusId.InProgress));
    }

    const userFirstName = userAccountInformation?.firstName;
    const userLastName = userAccountInformation?.lastName;
    const userEmail = userAccountInformation?.email;

    return {
        loanGuid,
        dashboardInformation,
        taskMenuItems,
        isLoading,
        milestoneStatus,
        borrower,
        borrowerType,
        userFirstName,
        userLastName,
        userEmail,
        isLoanCompleted,
        isPopupFlag,
        loanSummary,
        userAccountInformationObj,
        interimLoanStagingIdOfLoan,
        submitFlag,
        loanFromEncompassMsgFlag
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getDashboardDetails: (loanGuid: string, borrowerType: string) => {
        dispatch(dashboardActionCreators.getDashboardDetails(loanGuid, borrowerType));
    },
    GetSubTaskItemLoanStatus: (loanGuid: string) => {
        dispatch(TaskMenuCreators.GetSubTaskItemLoanStatus(loanGuid));
    },
    getMilestoneDetails: (loanGuid: string) => {
        dispatch(MilestoneNavigationServiceActionCreators.getMilestoneDetails(loanGuid));
    },
    getBorrowerInformation: (borrowerType: string, interimLoanStagingId: number) => {
        dispatch(accountCreationActionCreators.getBorrowerInformation(borrowerType, interimLoanStagingId));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    setIsLoanCompleted: (value?: boolean) => {
        dispatch(loanActionCreators.setIsLoanCompleted(value));
    },
    setDocumentPopupFlag: (value: boolean) => {
        dispatch(TaskMenuCreators.setDocumentPopupFlag(value));
    },
    setCurrentInterimLoanSummary: (data: InterimLoanSummary) => {
        dispatch(interimLoanSummaryActionCreators.setCurrentInterimLoanSummary(data));
    },
    setLoanGuid: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.setLoanGuid(value));
    },
    setIsLoading: (value: boolean) => {
        dispatch(dashboardActionCreators.setIsLoading(value));
    },
    getInterimLoanSummary: (email: string) => {
        dispatch(interimLoanSummaryActionCreators.getInterimLoanSummary(email));
    },
    setInterimLoanStagingId: (value?: number) => {
        dispatch(loanActionCreators.setInterimLoanStagingId(value));
    },
    setIsLoanFromEncompassFlag: (value?: boolean) => {
        dispatch(loanActionCreators.setIsLoanFromEncompassFlag(value));
    },
    // ReviewApplication
    submitCheckFlag: (value: boolean) => {
        dispatch(reviewApplicationActionCreators.submitCheckFlag(value));
    },
    setLoanFromEncompassMessageFlag: (value: boolean) => {
        dispatch(reviewApplicationActionCreators.setLoanFromEncompassMessageFlag(value));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardDetails as any);