import React, { useEffect, useState } from "react";
import { getDocumentContents } from "services/documentService";
import styles from "./documentViewers.module.scss";
import { isSafari, isMobile } from "react-device-detect";

interface PdfDocumentViewerProps {
    data?: string;
    loanGuid?: string;
    documentId?: number;
}

const PdfDocumentViewer = (props: PdfDocumentViewerProps) => {
    const [blobString, setBlobString] = useState("");
    const [data, setData] = useState("");

    useEffect(() => {
        if (props.loanGuid && (props.documentId ?? -1) > 0) {
            if (props.data != undefined && props.data != "") {
                setData(props.data);
            }
            else {
                getData();
            }
        }
    }, [props.documentId, props.loanGuid, props.data]);

    const getData = async () => {
        setData("");
        var result = await getDocumentContents(props.loanGuid ?? "", props.documentId ?? -1);
        if (result.ok && !result.parsedBody?.hasError) {
            setData(result.parsedBody?.body.data ?? "");
        }
    }

    useEffect(() => {
        if (data != "") {
            setBlobString(getBlobUrl(data, "application/pdf"));
        }
    }, [data]);

    const getBlobUrl = (encodedByteString: string, documentContentType: string) => {
        let byteString = atob(encodedByteString);
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let byteArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([byteArray], { type: documentContentType });
        return URL.createObjectURL(blob);
    };

    return (
        <div className={styles.PDFdisplayPage}>

            {!data && <span className="loader medium" />}

            {data && isMobile &&
                <div className={styles.downloadPDF}>
                    <p>This browser does not support PDF. Please download the PDF to view it.</p>
                <a className={styles.downloadButton} href={blobString} download >Download PDF</a>
                </div>
            }

            {data && !isSafari && !isMobile &&
                <div className={styles.PDFSection}>
                    <embed src={blobString} type="application/pdf" />
                </div>
            }
            {data && isSafari && !isMobile &&
                <>
                    <div className={styles.PDFSection}>
                        <object data={`data:application/pdf;base64,${data}`} type="application/pdf" width="100%" height="100%">
                            <p> <a href={blobString} download >Download PDF</a>This browser does not support PDFs. Please download the PDF to view it.</p>
                        </object>
                    </div>
                </>
            }
        </div>
    );
};

export default PdfDocumentViewer;