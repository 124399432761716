import axios, { AxiosRequestConfig } from "axios";
import SessionInfo from "../SessionDataTracker";

interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

export async function http<T>(request: RequestInfo): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(request);

    if (!response.ok) {
        return response;
    }

    try {
        response.parsedBody = await response.json();
    } catch (ex) { }
    return response;
}

const getHeaders = ((contentType: string) => {
    let headers: any = {
        Authorization: `Bearer ${SessionInfo.tokens?.accessToken?.accessToken}`,
        "ClientKey": SessionInfo.config?.clientId, 
        "lo": SessionInfo.loEmail ?? ""
    }

    
    if (contentType !== "") {
        headers = {
            "Content-Type": contentType,
            ...headers
        }
    }

    return headers;
});

export async function get<T>(
    path: string,
    args: RequestInit = { method: "get", headers: getHeaders("") }
): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, args));
};

export async function getBlob<T>(path: string) {
    const config: AxiosRequestConfig = { responseType: "blob", headers: getHeaders("") };
    const result = axios.get(path, config);
    return await result;
};

export async function post<T>(
    path: string,
    body: any,
    args: RequestInit = { method: "post", body: JSON.stringify(body), headers: getHeaders("application/json") }
): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, args));
};

export async function postForm<T>(path: string, submissionData: any) {
    const config = { headers: getHeaders("multipart/form-data") };
    const result = axios.post(path, submissionData, config);
    return (await result).data;
};
