import * as React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { ManualAssetInformation as AssetInformation, ManualAssetInformationState } from "../../store/ManualAssetInformationStore";
import { ManualAssetInformationActionCreators } from "../../actions/manualAssetInformationActions";
import styles from "./assets.module.scss";
import { Link, useHistory } from "react-router-dom";
import { useNavigation } from "hooks/useNavigation";
import Currency from "../../components/common/Currency";
import { TaskItem, SubTaskItem, TaskStatusId } from "../common/progressTracker/TaskStatus";
import { TaskMenuCreators } from "../../actions/taskMenuAction";

type AssetDetailsProps = {
    assets: AssetInformation[];
    loanGuid: string;
}
    & typeof ManualAssetInformationActionCreators
    & typeof TaskMenuCreators
    & ManualAssetInformationState;

const AssetDetails = (props: AssetDetailsProps) => {
    const history = useHistory();
    const navigation = useNavigation(() => {
        navigation.ShowContinue(() => { onContinue() }, false);
        navigation.SetBackOnClick(() => { onBack() });
    });

    const onBack = () => {
        history.push({ pathname: "/manual-asset-information" });
    };

    const onContinue = () => {
        props.updateNavigationStatus(TaskItem.BorrowerREO, SubTaskItem.BorrowerREO, TaskStatusId.InProgress,TaskStatusId.InProgress, props.loanGuid);//update next in nav bar
        props.updateNavigationStatus(TaskItem.AssetInformation, SubTaskItem.AssetInformation, TaskStatusId.Completed, TaskStatusId.Completed, props.loanGuid);
        history.push({ pathname: "/reo-properties" });
    };

    React.useEffect(() => {
        props.getAssetInformationFromEncompass(props.loanGuid);
    }, []);

    let totalAssets = 0.00;
    if (props.assets?.length > 0) {
        for (let i = 0; i < props.assets.length; i++) {
            let temp = props.assets[i].urla2020CashOrMarketValueAmount ? parseFloat(props.assets[i].urla2020CashOrMarketValueAmount) : 0
            totalAssets += temp;
        }
    }
    return (
        <div className={`${styles.assetContainer} ${styles.w100}`}>
            <div className={`${styles.content} ${styles.w100}`}>
                <h1>Total Assets  <Currency value={totalAssets} decimalScale={2}></Currency> </h1>
                <div className={styles.basicInfo}>
                    <div className={styles.assetRow}>
                        <div className={styles.entryData}>
                            <div className="text-center">
                            </div>
                            <div className={styles.entryContainer}>
                                {props.assets?.map((asset, index) => (
                                    <div className={styles.inputGroup}>
                                        <div>
                                            <label>{asset.holderName}</label>
                                            <h5>{asset.assetType}</h5>
                                        </div>
                                        <div>
                                            <label><Currency value={asset.urla2020CashOrMarketValueAmount ? parseFloat(asset.urla2020CashOrMarketValueAmount) : parseFloat('0')} decimalScale={2}></Currency></label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

const mapStateToProps = (appState: ApplicationState, ownProps: {
}): any => {
    const { manualAssetInformation } = appState;
    let assets;
    let loanGuid;

    if (manualAssetInformation && manualAssetInformation.assets) {
        assets = manualAssetInformation.assets;
    }
    loanGuid = appState.loan?.loanGuid;
    return {
        assets,
        loanGuid
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    getAssetInformationFromEncompass: (loanGuid: string) => {
        dispatch(ManualAssetInformationActionCreators.getAssetInformationFromEncompass(loanGuid));
    },
    updateNavigationStatus: (taskItemId: any, subTaskItemId: any, taskStatusId: any, subTaskStatusId: any, loanGuid: any) => {
        dispatch(TaskMenuCreators.updateNavigationStatus(taskItemId, subTaskItemId, taskStatusId, subTaskStatusId, loanGuid));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(AssetDetails as any);