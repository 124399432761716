import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as PropertyInformationActions from "../../actions/propertyInformationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import InfographicSideLayout from "../common/Layout/InfographicSideLayout";
import PropertyAddress from "./PropertyAddress";
import PropertyInformationCurrencyInput from "./PropertyInformationCurrencyInput";
import addressInfographic from "../../assets/infographics/propertyInformation/address.svg";
import styles from "./propertyInformation.module.scss";
import { PropertyInformationState } from "../../store/propertyInformationStore";
import { bindActionCreators } from "redux";
import { PropertyInformationStep } from "./PropertyInformation";
import InfographicTopLayout from "../common/Layout/InfographicTopLayout";
import { isNullOrUndefined } from "util";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type PropertyInformationProps = {
    changeStep: (step: PropertyInformationStep) => void;
    showContinue: (nextStep: PropertyInformationStep, isDisabled?: boolean) => void;
    setSaveOnContinue: () => void;
    resetContinue: () => void;
    setContinueDisabled: (isDisabled: boolean) => void;
    currentStep: PropertyInformationStep;
    disabled: boolean | undefined;
};

const PropertyInformationRefinance = (props: PropertyInformationProps) => {
    const [propertyAddressValid, setPropertyAddressValid] = useState(false);
    const { speak, cancel } = useSpeechSynthesis();
    const state: PropertyInformationState = useSelector(
        (state: ApplicationState) => state.propertyInformation as PropertyInformationState
    );

    const actions = {
        ...bindActionCreators(
            {
                ...PropertyInformationActions.propertyInformationActionCreators, ...TaskMenuCreators
            },
            useDispatch()
        ),
    };
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    useEffect(() => {
        if ((state.taxesPerYear && state.taxesPerYear?.length <= 15 && state.taxesPerYear?.length > 1) &&
            (state.estimatedCurrentValue !== undefined && state.estimatedCurrentValue?.length <= 10 && state.estimatedCurrentValue?.length > 1) &&
            (state.yearPropertyBuilt !== undefined && state.yearPropertyBuilt?.length == 4 && state.yearPropertyBuilt < new Date().getFullYear().toString()) &&
            (state.homeOwnersInsurancePerYear !== undefined && state.homeOwnersInsurancePerYear?.length <= 15 && state.homeOwnersInsurancePerYear?.length > 1) &&
            (state.currentMortgageBalance !== undefined && state.currentMortgageBalance && state.currentMortgageBalance?.length <= 14 && state.currentMortgageBalance?.length > 1))
        {
            //divide by 12 for monthly tax.
            //round it to two most nearest decimal places.
            let value = Math.round(((+state.taxesPerYear / 12) + Number.EPSILON) * 100) / 100;
            actions.updatePropertyInformationField(
                value,
                "monthlyTax",
                false
            );
        }
    }, [state.taxesPerYear])

    useEffect(() => {
        switch (props.currentStep) {
            case PropertyInformationStep.Address: {
                props.showContinue(PropertyInformationStep.RefinanceQuestions, !propertyAddressValid);
                break;
            }
            case PropertyInformationStep.RefinanceQuestions: {
                if ((state.taxesPerYear && state.taxesPerYear?.length <= 15 && state.taxesPerYear?.length > 1) &&
                    (state.estimatedCurrentValue !== undefined && state.estimatedCurrentValue?.length <= 10 && state.estimatedCurrentValue?.length > 1) &&
                    (state.yearPropertyBuilt !== undefined && state.yearPropertyBuilt?.length == 4 && state.yearPropertyBuilt < new Date().getFullYear().toString()) &&
                    (state.homeOwnersInsurancePerYear !== undefined && state.homeOwnersInsurancePerYear?.length <= 15 && state.homeOwnersInsurancePerYear?.length > 1) &&
                    (state.currentMortgageBalance !== undefined && state.currentMortgageBalance && state.currentMortgageBalance?.length <= 14 && state.currentMortgageBalance?.length > 1)) {
                    props.setSaveOnContinue();
                }
                else {
                    props.setContinueDisabled(true);
                }
                break;
            }
        }
    });

    // Update Continue button disabled value on value changes
    useEffect(() => {
        switch (props.currentStep) {
            case PropertyInformationStep.Address: {
                props.setContinueDisabled(!propertyAddressValid);
                break;
            }
            case PropertyInformationStep.RefinanceQuestions: {
                props.setContinueDisabled(
                    (state.estimatedCurrentValue === undefined || state.estimatedCurrentValue?.length > 10 || state.estimatedCurrentValue?.length < 1) ||
                    (state.yearPropertyBuilt === undefined || state.yearPropertyBuilt?.length != 4 || state.yearPropertyBuilt > new Date().getFullYear().toString()) ||
                    (state.taxesPerYear === undefined || state.taxesPerYear == null || state.taxesPerYear?.length > 15 || state.taxesPerYear?.length < 1) ||
                    (state.homeOwnersInsurancePerYear === undefined || state.homeOwnersInsurancePerYear?.length > 15 || state.homeOwnersInsurancePerYear?.length < 1) ||
                    (state.currentMortgageBalance === undefined || state.currentMortgageBalance == null || state.currentMortgageBalance?.length > 14 || state.currentMortgageBalance?.length < 1));
                break;
            }            
        }
    }, [props.currentStep,
    state.estimatedCurrentValue,
    state.yearPropertyBuilt,
    state.taxesPerYear,
    state.homeOwnersInsurancePerYear,
        propertyAddressValid,
    state.currentMortgageBalance,
    ]);

    let propertyInformationMarkup = <></>;

    switch (props.currentStep) {
        case PropertyInformationStep.Address: {
            propertyInformationMarkup = (
                <InfographicSideLayout infographic={addressInfographic}>
                    <PropertyAddress
                        question="What is the address of the property you want to refinance?"
                        address={state.address}
                        city={state.city}
                        state={state.state}
                        zip={state.zip}
                        county={state.county}
                        addressUnitIdentifier={state.addressUnitIdentifier}
                        isAddressSameAsBorrower={state.isAddressSameAsBorrower}
                        updatePropertyInformationValue={actions.updatePropertyInformationField}
                        onValidateAddressChange={(isValid) => {
                            setPropertyAddressValid(isValid);
                            actions.setLoanIsDirty(true);
                        }}
                        disabled={props.disabled}
                    />
                </InfographicSideLayout>
            );
            break;
        }
        case PropertyInformationStep.RefinanceQuestions: {
            propertyInformationMarkup = (
                <InfographicTopLayout className={"align-center"}>
                    <div className="question-row">
                        <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What is the estimated current value of your property?')}>What is the estimated current value of your property?</div>
                        <div className="answerItem rfpiansweritem">
                            <PropertyInformationCurrencyInput
                                value={state.estimatedCurrentValue}
                                parameterName={"estimatedCurrentValue"}
                                disabled={props.disabled}
                            />
                            {(state.estimatedCurrentValue && state.estimatedCurrentValue.length > 10) && <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Input limit exceeds, please enter a valid amount!')}>Input limit exceeds, please enter a valid amount!</span>}
                        </div>
                     </div>
                    
                    <div className="question-row">
                        <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What year was your property built?')}>What year was your property built?</div>
                        <div className="answerItem rfpiansweritem">
                            <input
                                disabled={props.disabled}
                                type="number"
                                placeholder="1950"
                                value={state.yearPropertyBuilt ?? ""}
                                name="yearPropertyBuilt"
                                onChange={(e) => {
                                    if (e.target.value?.length <= 4) {
                                        actions.updatePropertyInformationField(
                                            e.target.value,
                                            e.target.name,
                                            false
                                        );
                                        actions.setLoanIsDirty(true);
                                    }
                                }}
                            />
                            {(isNullOrUndefined(state.yearPropertyBuilt) || state.yearPropertyBuilt?.length != 4 || state.yearPropertyBuilt) > new Date().getFullYear().toString() ? <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please enter a valid year built')}>Please enter a valid year built.</span> : ""}
                        </div>     
                    </div>

                    <div className="question-row">
                        <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What are your property taxes per year?')}>What are your property taxes per year?</div>
                        <div className="answerItem rfpiansweritem">
                            <PropertyInformationCurrencyInput
                                value={state.taxesPerYear}
                                parameterName={"taxesPerYear"}
                                disabled={props.disabled}
                            />
                            {(state.taxesPerYear && state.taxesPerYear.length > 15) && <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Invalid value')}>invalid value</span>}
                        </div>
                    </div>
                    
                    <div className="question-row">
                        <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What is your homeowner’s insurance cost per year?')}>What is your homeowner’s insurance cost per year?</div>
                        <div className="answerItem rfpiansweritem">
                            <PropertyInformationCurrencyInput
                            value={state.homeOwnersInsurancePerYear}
                            parameterName={"homeOwnersInsurancePerYear"}
                            disabled={props.disabled}
                            />
                            {(state.homeOwnersInsurancePerYear && state.homeOwnersInsurancePerYear.length > 15) && <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Invalid value')}>invalid value</span>}
                        </div>  
                    </div>
                    
                    <div className="question-row">
                        <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What is your current mortgage balance?')}>What is your current mortgage balance?</div>
                        <div className="answerItem rfpiansweritem">
                            <PropertyInformationCurrencyInput
                                value={state.currentMortgageBalance}
                                parameterName={"currentMortgageBalance"}
                                disabled={props.disabled}
                            />
                            {(state.currentMortgageBalance && state.currentMortgageBalance.length > 14) && <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Invalid valie')}>invalid value</span>}
                        </div>                       
                    </div>
                </InfographicTopLayout>
            );
            break;
        }
    }

    return <>{propertyInformationMarkup}</>;
};

export default PropertyInformationRefinance;
