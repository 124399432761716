import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as ConsumerCreditAuthorizationStore from "../store/consumerCreditAuthorizationStore";
import { BorrowerType } from "../store/Enum";
import { KnownAction } from '../store/consumerCreditAuthorizationStore';
import * as consumerCreditAuthorizationService from "../services/consumerCreditAuthorizationService";

const request = (borrowerType: string, interimLoanStagingId:number): KnownAction => {
    return {
        type: ActionTypes.RequestConsumerCreditAuthorization,        
        borrowerType,
        interimLoanStagingId
    };
};
const receive = (data: ConsumerCreditAuthorizationStore.ConsumerCreditAuthorization, borrowerType: BorrowerType): KnownAction => {
    return { type: ActionTypes.ReceiveConsumerCreditAuthorization, data, borrowerType };
};

const isLoading = (loading: boolean): ConsumerCreditAuthorizationStore.KnownAction => {
    return {
        type: ActionTypes.IsLoading,
        isLoading: loading
    };
}

export const consumerCreditAuthorizationActionCreators = {
    updateCreditReportIndicator: (value: boolean, borrowerType: BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateCreditReportIndicator, value: value, borrowerType: borrowerType });
    },
    getConsumerCreditAuthorization: (borrowerType: string, interimLoanStagingId:number): AppThunkAction<ConsumerCreditAuthorizationStore.KnownAction> => (dispatch) => {
        dispatch(request(borrowerType, interimLoanStagingId));
        consumerCreditAuthorizationService.getConsumerCreditAuthorization(borrowerType, interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + interimLoanStagingId + " .");
                }
                return response.parsedBody as ConsumerCreditAuthorizationStore.ConsumerCreditAuthorization;
            })
            .then((data: any) => {
                dispatch(receive(data, data.borrowerType));
            })
            .catch((error: Error) => {
                console.error(error);
            });
    },
    updateCreditAuthorizationProperty: (value: boolean, borrowerType: BorrowerType, loanGuid: string, interimLoanStagingId:number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: ActionTypes.UpdateCreditAuthorizationProperty, value: value, borrowerType: borrowerType, loanGuid: loanGuid, interimLoanStagingId: interimLoanStagingId });
    },
    getConsumerCreditAuthorizationStatus: (interimLoanStagingId: number): AppThunkAction<ConsumerCreditAuthorizationStore.KnownAction> => (dispatch) => {

        consumerCreditAuthorizationService.getConsumerCreditAuthorizationStatus(interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + interimLoanStagingId + " .");
                }
                return response.parsedBody as ConsumerCreditAuthorizationStore.ConsumerCreditAuthorization;
            })
            .then((data: any) => {
                dispatch(receive(data, data.borrowerType));
            })
            .catch((error: Error) => {
                console.error(error);
            });
    },

    setIsLoading: (loding: boolean): AppThunkAction<ConsumerCreditAuthorizationStore.KnownAction> => (dispatch) => {
        dispatch(isLoading(loding));
    }
}

