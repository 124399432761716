import React, { useState, useEffect } from "react";
import ChoiceQuestion from "../common/ChoiceQuestion";
import singleFamilyGraphic from "assets/infographics/accountInformation/single-family.svg";
import multiUnitGraphic from "assets/infographics/accountInformation/multi-unit.svg";
import condoGraphic from "assets/infographics/accountInformation/condo.svg";
import { Choice } from "../../interfaces/Question";
import styles from "./AccountInformation.module.scss";

const PropertyType = (props: {
    updatePropertyTypeChoice: (value: string) => any, data: any, propertyType: any, loanPurpose: any
}) => {
    const [propertyTypeChoices, setPropertyTypeChoices] = useState([]);

    useEffect(() => {
        if (props.data.length > 0) {
            setPropertyTypeChoices(props.data);
        }
    }, [props.data]);

    const loadSvg = (name: string) => {

        switch (name) {
            case 'single-family':
                return singleFamilyGraphic
            case 'multi-unit':
                return multiUnitGraphic
            case 'condo':
                return condoGraphic
        }
    };

    const choices: Choice[] = [];
    propertyTypeChoices.map((res: any) => {
        let choice: Choice = {
            text: res.name,
            icon: loadSvg(res.icon),
            onClick: () => props.updatePropertyTypeChoice(res.name),
            toolTipText: res.description,
            className: props.propertyType === res.name ? styles.selected : ""
        };
        choices.push(choice);
    })

    return (
        <ChoiceQuestion
            disabled={false}
            intro="Property Type"
            question="What type of property are you looking for?"
            choices={choices}
        />
    );
}

export default PropertyType;