import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ApplicationState } from "store";
import { DocumentDto } from "../models/DocumentDto";
import { DocumentStatus } from "../models/DocumentStatus";
import styles from "./BorrowerDocument.module.scss";
import Tooltip from "../../common/InfoIconTooltip";
import { FileFormat } from "../models/FileFormat";
import WordDocumentViewer from "../documentViewers/WordDocumentViewer";
import { Modal, ModalBody } from "reactstrap";
import { getDocumentContents } from "../../../services/documentService";
import { isNullOrWhiteSpace } from "../../../common/helper/formatHelper";
import NoImage from "assets/icons/document/NoImage.png";

interface DocumentProps {
    fileName?: string | null | undefined;
    status?: DocumentStatus | null | undefined;
    documentDto?: DocumentDto | null | undefined;
    deleteDocument: (loanGuid: string, documentDto?: DocumentDto | null | undefined) => void;
}

const Document = (props: DocumentProps) => {
    const history = useHistory();
    const loanGuid = useSelector((state: ApplicationState) => state.accountInformation?.loanGuid ?? "");
    const [data, setData] = useState("");
    const [isOpen, setOpen] = useState(false);

    const openDocument = async () => {
        if (props.documentDto?.fileFormat === FileFormat.Word) {
            setData("");
            var result = await getDocumentContents(loanGuid ?? "", props.documentDto?.documentId ?? -1);
            if (result.ok && !result.parsedBody?.hasError) {
                setData(result.parsedBody?.body.data ?? "");
            }
            setOpen(true);
        } else {
            history.push("/document-viewer", {
                loanGuid: loanGuid,
                documentDto: props.documentDto,
                viewerActions: false
            });
        }
    };

    const getStatus = (status: DocumentStatus | null | undefined) => {
        switch (status) {
            case DocumentStatus.Accepted:
                return styles.accepted;
            case DocumentStatus.Replaced:
                return styles.discarded;
            case DocumentStatus.Rejected:
                return styles.rejected;
            case DocumentStatus.Submitted:
                return styles.received;
            default:
                return <></>;
        }
    }

    return (
        <>
            <div className={styles.documentContainer}>
                <div onClick={() => openDocument()}>
                    <h5 className={styles.fileName}>{props.fileName}</h5>
                    <div className={styles.documentStatus}>
                        <h5 className={`${styles.statusText} ${getStatus(props.status)}`}>{DocumentStatus[props.status as any]}</h5>
                        <span>{props?.documentDto?.rejectionComment && <Tooltip iconClass="fa fa-exclamation-circle">
                            {props?.documentDto?.rejectionComment}</Tooltip>}</span>
                    </div>
                    <br></br>
                </div>
                {props.status === DocumentStatus.Submitted &&
                    <i className={`fa fa-trash ${styles.documentDelete}`} onClick={() => props.deleteDocument(loanGuid, props.documentDto)}></i>
                }
                
            </div>

            <Modal
                isOpen={isOpen}
                centered={true}
                returnFocusAfterClose={false}
            >
                <ModalBody className={`${styles.modalBody} ${styles.docPreviewBody}`}>
                    <div className={styles.closeIcon}>
                        <i className={`fa fa-times ${styles.icon}`} aria-hidden="true"
                            onClick={() => setOpen(false)} ></i>
                    </div>
                    <div className="align-center">
                        <h2 className="align-center">{props.documentDto?.fileName}</h2>
                        <div className={styles.previewDiv}>
                            <img src={NoImage} alt="No Image" />
                            <h4 className={styles.previewText}>It looks like this file doesn't have a preview we can show you.</h4>
                            <WordDocumentViewer
                                data={!isNullOrWhiteSpace(data) ? data : "Error occured!"}
                                fileName={props.documentDto?.fileName ?? "Error.docx"}
                            ></WordDocumentViewer>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Document;
