import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FinicityMessage from './FinicityMessage'
import * as assetInformationService from "../../services/assetInformationService";
import * as assetInformationStore from "../../store/assetInformationStore";
import styles from "./assetInformation.module.scss";
import { UserAccountState } from "store/userAccountStore";
import { ApplicationState } from "../../store";
import { useSelector } from "react-redux";
import UhmLogo from "assets/logo/uhm-logo.png";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface SubmitVoaProps {
    modal?: boolean;
    close: () => void;
    submitted: (connectUrl: string) => void;
    borrowerAsset: assetInformationStore.BorrowerAsset;
    errors: (errMsg: string[]) => void;
}

const SubmitVoaModal = (props: SubmitVoaProps) => {
    const [showLoader, setShowLoader] = React.useState(false);
    const { borrowerType } = useSelector((state: ApplicationState) => state.userAccountInformation as UserAccountState);
    const closeModal = () => {
        props.close();
    };
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    const submitVoa = () => {
        setShowLoader(true);
        assetInformationService.submitVoa(props.borrowerAsset)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem submitting your Asset verification request.");
                }
                else if (response.parsedBody.hasError) {
                    var errors = response.parsedBody.messages.map((m: any) => m.description);
                    props.errors(errors);
                } else {
                    props.submitted(response.parsedBody.finicityConnectUrl);
                }
                setShowLoader(false);
            })
            .catch((error: Error) => {
                props.errors([error.message]);
                setShowLoader(false);
            });
    };

    return (
        <Modal className={styles.modal} isOpen={props.modal}>
            <ModalHeader className={styles.modalHeader} toggle={closeModal}>
                <div>
                    <img src={UhmLogo} alt="Union Home Mortgage" />
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Verification Of Assets')}>Verification Of Assets</h1>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('To make your home buying process as easy as possible, Union Home Mortgage has provided a simple way for you to input your assets into the application for verification. Your information will be confidential and safe through this process')}>To make your home buying process as easy as possible, Union Home Mortgage has provided a simple way for you to input your assets into the application for verification. Your information will be confidential and safe through this process.</h2>
                    <p className={styles.popupBlocker} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('To proceed with Finicity, ensure the pop-up blocker is disabled on the internet browser')}>To proceed with Finicity, ensure the pop-up blocker is disabled on the internet browser.</p>
                </div>
            </ModalHeader>
            {showLoader && (<div className="loader medium"></div>)}
            <ModalBody className={styles.modalBody}>
                <div className="content align-center">
                    <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to submit')} className={`common-green-button ${styles.button}`} onClick={submitVoa}>SUBMIT<FinicityMessage></FinicityMessage></button>
                </div>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </Modal>
    );
};

export default SubmitVoaModal;
