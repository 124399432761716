import * as ActionTypes from "../common/ActionTypes";

// STATE
export interface AccountCreationState {
    borrower: Borrower;
    borrowerComplete: boolean;
    coBorrower: Borrower;
    coBorrowerComplete: boolean;
    isLoading: boolean;
    errorMessage?: string;
    validation: any;
    oktaNameResult?: boolean;
}

// MODEL
export interface Borrower {
    loanGuid?: string;
    borrowerType?: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    suffixToName?: string;
    phoneNumber?: string;
    phoneType?: string;
    emailAddressText?: string;
    maritalStatusType?: string;
    printOnAdditionalBorrowerPage?: boolean;
    addresses?: Address[];
    currentAddress?: Address;
    formerAddress?: Address;
    birthDate?: string;
    taxIdentificationIdentifier?: string;
    realEstateDoesNotApply?: boolean;
    totalMonths?: number;
    needAdditionalAddress?: boolean;
    isCoBorrowerSelectionAllowed?: boolean;
    interimLoanStagingId?: number;
}

export enum BorrowerType {
    PrimaryBorrower = 1,
    CoBorrower = 2
}

export interface Address {
    id?: string;
    indexId?: string;
    editMode?: boolean;
    residencyType?: string;
    addressStreetLine1?: string;
    addressCity?: string;
    addressState?: string;
    addressPostalCode?: string;
    moveInYear?: number;
    moveInMonth?: number;
    durationTermYears?: number;
    durationTermMonths?: number;
    applicantType?: string;
    mailingAddressIndicator?: boolean;
    coBorrowerAddressChecked?: boolean;
    isValid?: boolean;
}

export enum AddressType {
    CurrentAddress = 1,
    FormerAddress = 2
}

// ACTIONS
interface UpdateAddressMoveInDate {
    type: typeof ActionTypes.UpdateAddressMoveInDate,
    moveInYear: number,
    moveInMonth: number,
    borrowerType: BorrowerType,
    addressType: AddressType
}

interface UpdateBorrowerProperty {
    type: typeof ActionTypes.UpdateBorrowerProperty,
    value: any,
    propertyName: string,
    borrowerType: BorrowerType
}

interface UpdateBorrowerAddressProperty {
    type: typeof ActionTypes.UpdateBorrowerAddressProperty,
    value: string,
    propertyName: string,
    borrowerType: BorrowerType,
    addressType: AddressType
}

interface AddAddressProperty {
    type: typeof ActionTypes.AddAddressProperty,
    address: Address,
    borrowerType: BorrowerType,
}

interface EditAddressProperty {
    type: typeof ActionTypes.EditAddressProperty,
    address: Address,
    borrowerType: BorrowerType,
}

interface DeleteAddressProperty {
    type: typeof ActionTypes.DeleteAddressProperty,
    id: string,
    borrowerType: BorrowerType,
}

interface SetAddressSameAsBorrower {
    type: typeof ActionTypes.SetAddressSameAsBorrower,
    addressType: AddressType,
    value: boolean
}

interface SetValidation {
    type: typeof ActionTypes.SetBorrowerValidation;
    validationError: any;
    field: string;
}

interface ResetFormerAddress {
    type: typeof ActionTypes.ResetFormerAddress,
    borrowerType: BorrowerType
}

interface GetBorrowerInformation {
    type: typeof ActionTypes.GetBorrowerInformation,
    data: Borrower,
    borrowerType: string
}
interface UpdateOktaResult {
    type: typeof ActionTypes.UpdateOktaResult,
    oktaNameResult: boolean
}

export type KnownAction =
    UpdateAddressMoveInDate |
    UpdateBorrowerProperty |
    UpdateBorrowerAddressProperty |
    SetAddressSameAsBorrower |
    SetValidation |
    ResetFormerAddress |
    GetBorrowerInformation |
    AddAddressProperty |
    EditAddressProperty |
    DeleteAddressProperty|
UpdateOktaResult;