import { NavigationActions, useNavigation } from "./useNavigation";
import { useEffect, useState } from "react";

export interface NavigationWithHistoryActions<T> {
  /**
   * The current step. This will be updated when the user clicks the back button to the previous step in the historical array
   */
  CurrentStep: T;
  /**
   * The historical array of all the previous steps. The current step will be the last item in the array
   */
  StepHistory: T[];
  /**
   * Sets the current step to the paramater passed in and adds that step to the historical array
   */
  ChangeStep: (step: T) => void;
  /**
   * useNavigation hook to be referenced if needed
   */
  Navigation: NavigationActions;
  /**
   * This will be true whenever the current step is the initial value. This can be used to alter
   *  the navigation when the user has gone all the way back to the start of this series of steps
   */
  IsAtInitialStep: boolean;
}

/**
 * Hook to maintain a history of what steps the user has gone through to use for the back button
 * @param initalValue The first value that will be put into the history
 */
export function useNavigationWithHistory<T>(
  initalValue: T
): NavigationWithHistoryActions<T> {
  const [stepHistory, changeStepHistory] = useState<T[]>([initalValue]);
  const [currentStep, setStep] = useState(initalValue);
  const [isAtInitialStep, setIsAtInitialStep] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    setIsAtInitialStep(currentStep === initalValue);

    if (currentStep !== initalValue) {
      navigation.SetBackOnClick(() => {
        goBack();
      });
    }
  }, [currentStep]);

  const changeStep = (step: T) => {
    setStep(step);
    changeStepHistory([...stepHistory, step]);
  };

  const goBack = () => {
    if (stepHistory.length > 1) {
      var previous = stepHistory[stepHistory.length - 2];
      setStep(previous);
      changeStepHistory(stepHistory.splice(0, stepHistory.length - 1));
    }
  };

  var actions: NavigationWithHistoryActions<T> = {
    Navigation: navigation,
    ChangeStep: (step) => changeStep(step),
    CurrentStep: currentStep,
    StepHistory: stepHistory,
    IsAtInitialStep: isAtInitialStep,
  };
  return actions;
}
