import { Action, Reducer } from "redux";
import * as ActionTypes from "../common/ActionTypes";
import { CreditInformationState, KnownAction } from "../store/creditInformationStore";

const unloadedState: CreditInformationState = {
    borrower: {
        firstName: "",
        lastName: "",
        birthday: new Date(),
        ssn: "",
    },
    coBorrower: {
        firstName: "",
        lastName: "",
        birthday: new Date(),
        ssn: "",
    },
    showCoBorrower: false,
    inputDisabled: true
};

export const creditInformationReducer: Reducer<CreditInformationState> = (
    state: CreditInformationState | undefined,
    incomingAction: Action
): CreditInformationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.SetCreditInformationModel: {
            const inputDisabled = action.borrowerSSN != undefined && action.borrowerSSN.length > 0;
            const showCoBorrower = action.coBorrowerLastName != undefined && action.coBorrowerLastName.length > 0;
            return {
                ...state,
                borrower: {
                    firstName: action.borrowerFirstName,
                    lastName: action.borrowerLastName,
                    birthday: action.borrowerBirthday,
                    ssn: action.borrowerSSN,
                },
                coBorrower: {
                    firstName: action.coBorrowerFirstName ?? "",
                    lastName: action.coBorrowerLastName ?? "",
                    birthday: action.coBorrowerBirthday ?? new Date(),
                    ssn: action.coBorrowerSSN ?? "",
                },
                showCoBorrower: showCoBorrower,
                inputDisabled: inputDisabled
            };
        }

        case ActionTypes.SetBorrowerDetail: {
            if (action.isBorrower) {
                return {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        birthday: action.birthday,
                        ssn: action.ssn,
                    }
                };
            } else {
                return {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        birthday: action.birthday,
                        ssn: action.ssn,
                    }
                };
            }
        };
        default:
            return state;
    }
};
