export enum UserAccountFields {
    FirstName = "FirstName",
    LastName = "LastName",
    Email = "Email",
    MobileNumber = "MobileNumber",
    VerificationCode ="VerificationCode",
    Password="Password",
    ConfirmPassword = "ConfirmPassword",
    ResetQuestion = "ResetQuestion",
    ResetAnswer = "ResetAnswer"
}

export enum BorrowerInformation {
    firstName = "firstName",
    middleName = "middleName",
    lastName = "lastName",
    suffixToName = "suffixToName",
    phoneNumber = "phoneNumber",
    phoneType = "phoneType",
    emailAddressText = "emailAddressText",
    maritalStatusType = "maritalStatusType",
    Password = "Password",
    ConfirmPassword = "ConfirmPassword",
}

export enum BorrowerType {
    PrimaryBorrower = 0,
    CoBorrower = 1
}

export enum ReportStatus {
  Pending,
  InProgress,
  ReadyToBeProcessed,
  ValuesProcessed,
  Complete,
  Error
}