import React, { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import Loading from "../common/Loader";

export default function SignDocument() {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        //Ensure user is logged into Okta for SSO before taken to the EM Consumer Connect (ECC) for document signing
        history.push({ pathname: "/id", state: { action: "SSO", qs: location.search } });
    }, []);

    return (
        <Loading/>
    );
}
