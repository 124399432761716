import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as PropertyInformationActions from "../../actions/propertyInformationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import InfographicSideLayout from "../common/Layout/InfographicSideLayout";
import PropertyAddress from "./PropertyAddress";
import PropertyInformationFundSource from "./PropertyInformationFundSource";
import PropertyInformationRealtor from "./PropertyInformationRealtor";
import TwoChoiceQuestion from "./TwoChoiceQuestion";
import addressInfographic from "../../assets/infographics/propertyInformation/address.svg";
import loanEstimateInfographic from "../../assets/infographics/propertyInformation/loanEstimate.svg";
import styles from "./propertyInformation.module.scss";
import { PropertyInformationState } from "../../store/propertyInformationStore";
import { bindActionCreators } from "redux";
import { PropertyInformationStep } from "./PropertyInformation";
import Currency from "components/common/Currency";
import PropertyInformationCurrencyInput from "./PropertyInformationCurrencyInput";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import NumberFormat from 'react-number-format';
import { NumberFormatValues } from "react-number-format";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type PropertyInformationProps = {
    changeStep: (step: PropertyInformationStep) => void;
    showContinue: (nextStep: PropertyInformationStep, isDisabled?: boolean) => void;
    currentStep: PropertyInformationStep;
    disabled: boolean | undefined;
};

const PropertyInformationPurchase = (props: PropertyInformationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [propertyAddressValid, setPropertyAddressValid] = React.useState(false);
    const [disabledRealtor, setDisabledRealtor] = React.useState(false);

    const actions = {
        ...bindActionCreators(
            {
                ...PropertyInformationActions.propertyInformationActionCreators, ...TaskMenuCreators
            },
            useDispatch()
        ),
    };

    const state: PropertyInformationState = useSelector(
        (state: ApplicationState) => state.propertyInformation as PropertyInformationState
    );

    useEffect(() => {
        switch (props.currentStep) {
            case PropertyInformationStep.PropertyLocation: {
                props.showContinue(PropertyInformationStep.RealtorQuestion, !propertyAddressValid);
                break;
            }
            case PropertyInformationStep.DownPaymentSource: {
                props.showContinue(PropertyInformationStep.LoanValueAndTerm, (isNullOrWhiteSpace(state.downPaymentSource)));
                break;
            }
            case PropertyInformationStep.RealtorQuestion: {
                if (state.downPayment && state.totalAvailableToSpendOnNewHome) {
                    props.showContinue(PropertyInformationStep.DownPaymentSource, (disabledRealtor || (state.buyExistingHome === undefined || state.buyExistingHome === null) ||
                        isNullOrWhiteSpace(state.downPayment) || Number(state.downPayment) > Number(state.totalAvailableToSpendOnNewHome) || state.downPaymentPercentage === 0 ||
                        ((!state.buyExistingHome && (state.ownLand === undefined || state.ownLand === null)) && (state.ownLand && isNullOrWhiteSpace(state.landValue)) && (state.landValue && state.landValue?.length > 14) && (state.securedConstructionFinancing === undefined || state.securedConstructionFinancing === null)) ||
                        (!state.buyExistingHome && state.ownLand && isNullOrWhiteSpace(state.landValue)) ||
                        isNullOrWhiteSpace(state.totalAvailableToSpendOnNewHome)));
                } else {
                    props.showContinue(PropertyInformationStep.DownPaymentSource, (isNullOrWhiteSpace(state.downPayment) || isNullOrWhiteSpace(state.totalAvailableToSpendOnNewHome) ||
                        ((!state.buyExistingHome && (state.ownLand === undefined || state.ownLand === null)) && (state.ownLand && isNullOrWhiteSpace(state.landValue)) && (state.landValue && state.landValue?.length > 14) && (state.securedConstructionFinancing === undefined || state.securedConstructionFinancing === null))));
                }
                break;
            }
            case PropertyInformationStep.LoanValueAndTerm: {
                props.showContinue(PropertyInformationStep.Finished, isNullOrWhiteSpace(state.loanTermInYears));
                break;
            }

        }
    });

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    useEffect(() => {
        switch (props.currentStep) {
            case PropertyInformationStep.PropertyLocation: {
                props.showContinue(PropertyInformationStep.RealtorQuestion, !propertyAddressValid);
                break;
            }
            case PropertyInformationStep.DownPaymentSource: {
                props.showContinue(PropertyInformationStep.LoanValueAndTerm, (isNullOrWhiteSpace(state.downPaymentSource)));
                break;
            }
            case PropertyInformationStep.RealtorQuestion: {
                if (state.downPayment && state.totalAvailableToSpendOnNewHome) {
                    props.showContinue(PropertyInformationStep.DownPaymentSource, (disabledRealtor || (state.buyExistingHome === undefined || state.buyExistingHome === null) ||
                        isNullOrWhiteSpace(state.downPayment) || Number(state.downPayment) > Number(state.totalAvailableToSpendOnNewHome) || state.downPaymentPercentage === 0 ||
                        ((!state.buyExistingHome && (state.ownLand === undefined || state.ownLand === null)) && (state.ownLand && isNullOrWhiteSpace(state.landValue)) && (state.landValue && state.landValue?.length > 14) && (state.securedConstructionFinancing === undefined || state.securedConstructionFinancing === null)) ||
                        (!state.buyExistingHome && state.ownLand && isNullOrWhiteSpace(state.landValue)) ||
                        isNullOrWhiteSpace(state.totalAvailableToSpendOnNewHome)));
                } else {
                    props.showContinue(PropertyInformationStep.DownPaymentSource, (isNullOrWhiteSpace(state.downPayment) || isNullOrWhiteSpace(state.totalAvailableToSpendOnNewHome) ||
                        ((!state.buyExistingHome && (state.ownLand === undefined || state.ownLand === null)) && (state.ownLand && isNullOrWhiteSpace(state.landValue)) && (state.landValue && state.landValue?.length > 14) && (state.securedConstructionFinancing === undefined || state.securedConstructionFinancing === null))));
                }
                break;
            }
            case PropertyInformationStep.LoanValueAndTerm: {
                props.showContinue(PropertyInformationStep.Finished, isNullOrWhiteSpace(state.loanTermInYears));
                break;
            }

        }
    }, [state, props.currentStep, propertyAddressValid, disabledRealtor]);

    useEffect(() => {
        actions.calculateEstimatedLoanAmount();
    }, [state.totalAvailableToSpendOnNewHome, state.downPayment]);

    useEffect(() => {
        if (!state.ownLand) {
            actions.updatePropertyInformationField("", "landValue");
        }
    }, [state.ownLand]);

    let propertyInformationMarkup = <></>;

    switch (props.currentStep) {
        case PropertyInformationStep.PropertyLocation: {
            propertyInformationMarkup = (
                <InfographicSideLayout infographic={addressInfographic}>
                    <PropertyAddress
                        question="Where are you looking to purchase a home?"
                        city={state.city}
                        state={state.state}
                        zip={state.zip}
                        county={state.county}
                        updatePropertyInformationValue={actions.updatePropertyInformationField}
                        onValidateAddressChange={(isValid) => {
                            setPropertyAddressValid(isValid);
                            actions.setLoanIsDirty(true); //loan state dirty as city state zip changes
                        }}
                        disabled={props.disabled}
                    />
                </InfographicSideLayout>
            );
            break;
        }
        case PropertyInformationStep.RealtorQuestion: {
            propertyInformationMarkup = (
                <div>
                    <div className="question-row">
                        <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Are you working with a realtor?")}>Are you working with a realtor? <span className={styles.requiredMsg}> * </span></div>
                        <div className="answerItem">
                            <button disabled={props.disabled} className={`option-button ${state.workingWithRealtor === true ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(true, "workingWithRealtor"); actions.setLoanIsDirty(true) }}
                                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to yes")}>
                                Yes
                            </button>
                            <button disabled={props.disabled} className={`option-button ${state.workingWithRealtor === false ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(false, "workingWithRealtor"); actions.setLoanIsDirty(true) }}
                                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to no")}>
                                No
                            </button>
                        </div>
                     </div>

                    {state.workingWithRealtor && (
                        <PropertyInformationRealtor disabledRealtor={setDisabledRealtor} disabled={props.disabled} />
                    )}

                    {(state.workingWithRealtor != undefined || state.workingWithRealtor != null) && (
                        <div className="question-row">
                            <div className="fullWidthItem">
                                <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Are you interested in purchasing an existing home or building a new home?")}>Are you interested in purchasing an existing home or building a new home? <span className={styles.requiredMsg}> * </span></div>
                                <div className={styles.homeOptionButtonRow}>
                                    <button disabled={props.disabled} className={`option-button ${state.buyExistingHome == true ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(true, "buyExistingHome"); actions.setLoanIsDirty(true) }}
                                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Existing home")}>
                                        Existing home
                                    </button>
                                    <button disabled={props.disabled} className={`option-button ${state.buyExistingHome == false ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(false, "buyExistingHome"); actions.setLoanIsDirty(true) }}
                                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Building a home")}>
                                        Building a home
                                    </button>
                                </div>
                            </div>
                        </div>                    
                    )}

                    {((state.workingWithRealtor != undefined || state.workingWithRealtor != null) && !state.buyExistingHome && (state.buyExistingHome != undefined || state.buyExistingHome != null)) && (
                        <div className="question-row">
                            <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Do you own the land you are going to build on?")}>Do you own the land you are going to build on? <span className={styles.requiredMsg}> * </span></div>
                            <div className="answerItem">
                                <button disabled={props.disabled} className={`option-button ${state.ownLand === true ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(true, "ownLand"); actions.setLoanIsDirty(true) }}
                                    onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to yes")}>
                                    Yes
                                </button>
                                <button disabled={props.disabled} className={`option-button ${state.ownLand === false ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(false, "ownLand"); actions.setLoanIsDirty(true) }}
                                    onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to no")}>
                                    No
                                </button>
                            </div>
                        </div>
                    )}

                    {!state.buyExistingHome && state.ownLand && (
                        <div className="question-row">
                            <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("What is the value of the land you own?")}>What is the value of the land you own? <span className={styles.requiredMsg}> * </span></div>
                            <div className="answerItem rfpiansweritem">
                                <PropertyInformationCurrencyInput
                                    disabled={props.disabled}
                                    value={state.landValue}
                                    parameterName={"landValue"}
                                />
                                {(state.landValue && state.landValue.length > 14) &&
                                    <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Input limit exceeds, please enter a valid amount!")}>Input limit exceeds, please enter a valid amount!</span>}
                            </div>
                        </div>
                    )}

                    {(!state.buyExistingHome && (state.ownLand != undefined || state.ownLand != null)) && (
                        <div className="question-row">
                            <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Have you already secured construction financing?")}>Have you already secured construction financing? <span className={styles.requiredMsg}> * </span></div>
                            <div className="answerItem">
                                <button disabled={props.disabled} className={`option-button ${state.securedConstructionFinancing === true ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(true, "securedConstructionFinancing"); actions.setLoanIsDirty(true) }}
                                    onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to yes")}>
                                    Yes
                                </button>
                                <button disabled={props.disabled} className={`option-button ${state.securedConstructionFinancing === false ? "active" : ""}`} onClick={() => { actions.updatePropertyInformationField(false, "securedConstructionFinancing"); actions.setLoanIsDirty(true) }}
                                    onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to no")}>
                                    No
                                </button>
                            </div>
                        </div>
                    )}

                    {((!state.buyExistingHome && (state.ownLand != undefined || state.ownLand != null) && (state.securedConstructionFinancing != undefined || state.securedConstructionFinancing != null)) || state.buyExistingHome) && (
                        <div className="question-row">
                            <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("What is the price you are looking to spend on your new home?")}>What is the price you are looking to spend on your new home? <span className={styles.requiredMsg}> * </span></div>
                            <div className="answerItem rfpiansweritem">
                                <PropertyInformationCurrencyInput
                                    disabled={props.disabled}
                                    value={state.totalAvailableToSpendOnNewHome}
                                    parameterName={"totalAvailableToSpendOnNewHome"}
                                />
                                {(state.totalAvailableToSpendOnNewHome && state.totalAvailableToSpendOnNewHome.length > 11) &&
                                    <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Input limit exceeds, please enter a valid amount!")}>Input limit exceeds, please enter a valid amount!</span>}
                            </div>
                        </div>
                    )}                
            
                    {((!state.buyExistingHome && (state.ownLand != undefined || state.ownLand != null) && (state.securedConstructionFinancing != undefined || state.securedConstructionFinancing != null)
                        && !isNullOrWhiteSpace(state.totalAvailableToSpendOnNewHome) && (state.totalAvailableToSpendOnNewHome && state.totalAvailableToSpendOnNewHome.length <= 11))
                        || (state.buyExistingHome && !isNullOrWhiteSpace(state.totalAvailableToSpendOnNewHome) && (state.totalAvailableToSpendOnNewHome && state.totalAvailableToSpendOnNewHome.length <= 11))) && (
                        <div className={styles.inputRangeQuestionRow}>                            
                            <div className={styles.questionItem} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("How much are you able to put as a down payment?")}>How much are you able to put as a down payment? <span className={styles.requiredMsg}> * </span></div>
                            <div className={styles.sliderAnswerItem}>
	                        <div className={styles.sliderAndInput}>	
                                <div className={styles.inputRangeItem}>
                                    <label>
                                        {state.downPaymentPercentage ? state.downPaymentPercentage.toFixed(0) : 0}% (
                                        <Currency value={state.downPayment ? Number(state.downPayment) : 0} decimalScale={0}></Currency>)
                                    </label>
                                    <input
                                        disabled={props.disabled}
                                        type="range"
                                        className={styles.slider}
                                        value={state.downPaymentPercentage ?? 0}
                                        name="downPaymentPercentage"
                                        min="0"
                                        max="100"
                                        step="1"
                                        onChange={(e) => {
                                            actions.updatePropertyInformationField((e.target.value as unknown) as number, "downPaymentPercentage", true);
                                            actions.setLoanIsDirty(true);
                                        }}
                                    />
                                </div>
                                <div className={styles.or}>
                                    OR
                                </div>
                                <div className={styles.inputRangeValue}>
                                    <NumberFormat
                                        placeholder="$00.00"                            
                                        value={state.downPayment ?? ""}
                                        displayType={'input'}
                                        disabled={props.disabled}
                                        onValueChange={(values: NumberFormatValues) => {
                                            actions.updatePropertyInformationField(values.value, "downPayment", false);
                                            actions.setLoanIsDirty(true);
                                        }}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={0}
                                        />
                                    </div>
                                </div>
                                <div className={styles.errorMSG}>
                                    {(state.totalAvailableToSpendOnNewHome
                                        && state.downPayment
                                        && Number(state.totalAvailableToSpendOnNewHome) < Number(state.downPayment))
                                        && <span className={styles.errmsgRefinance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Down payment can not exceed the desired amount!")}>Down payment can not exceed the desired amount!</span>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            );
            break;
        }
        case PropertyInformationStep.DownPaymentSource: {
            propertyInformationMarkup = (
                <PropertyInformationFundSource
                    disabled={props.disabled}
                    emitValueSelection={(value, propertyName) => {
                        actions.updatePropertyInformationField(value, propertyName);
                        actions.setLoanIsDirty(true);
                    }}
                    value={state.downPaymentSource}
                />
            );
            break;
        }
        case PropertyInformationStep.LoanValueAndTerm: {
            propertyInformationMarkup = (
                <>
                    <TwoChoiceQuestion
                        disabled={props.disabled}
                        infographic={loanEstimateInfographic}
                        optionOne={{
                            message: "30-Year",
                            onClick: () => {
                                actions.updatePropertyInformationField("30", "loanTermInYears", false);
                            },
                        }}
                        optionTwo={{
                            message: "15-Year",
                            onClick: () => {
                                actions.updatePropertyInformationField("15", "loanTermInYears", false);
                            },
                        }}
                        value={isNullOrWhiteSpace(state.loanTermInYears) ? state.loanTermInYears : (state.loanTermInYears === "30" ? true : false)}
                    >
                        <div className={styles.estimatedLoanContainer}>
                            <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Estimated Loan Amount')}>Estimated Loan Amount</h1>
                            <h2 className={styles.largeText}>
                                <Currency value={state.estimatedLoanAmount ? Number(state.estimatedLoanAmount) : 0}
                                    decimalScale={0}/>
                            </h2>
                        </div>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Which Loan Term do you plan to take')}>Which Loan Term do you plan to take?</h2>
                    </TwoChoiceQuestion>
                </>
            );
            break;
        }
    }

    return <>{propertyInformationMarkup}</>;
};

export default PropertyInformationPurchase;
