import * as React from "react";
import InfographicSideLayout from "../common/Layout/InfographicSideLayout";
import styles from "./LoanOfficer.module.scss";
import preQualLetternfographic from "assets/infographics/systematicLetter/prequal-letter.svg";

const MissingPreQualLetter = () => {

    return (
        <div className={styles.missCreditAuth}>
            <InfographicSideLayout infographic={preQualLetternfographic}>
                <h2>Pre-Qual Letter is not generated</h2>
            </InfographicSideLayout>
        </div>
    );
};

export default MissingPreQualLetter;