import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { AccountCreationState, AddressType, BorrowerType, KnownAction, Address } from "../store/accountCreationStore";
import { dateDiff } from '../common/helper/formatHelper';
import { BorrowerTypeEnum } from '../common/BorrowerTypeEnum';

const unloadedState: AccountCreationState = {
    borrower: {
        firstName: undefined,
        middleName: undefined,
        lastName: undefined,
        suffixToName: undefined,
        phoneNumber: undefined,
        phoneType: undefined,
        emailAddressText: undefined,
        maritalStatusType: undefined,
        currentAddress: undefined,
        formerAddress: undefined,
        birthDate: undefined,
        taxIdentificationIdentifier: undefined,
        totalMonths: 0,
        isCoBorrowerSelectionAllowed: undefined
    },
    borrowerComplete: false,
    coBorrower: {},
    coBorrowerComplete: false,
    oktaNameResult: false,
    isLoading: false,
    validation: undefined,
    errorMessage: ''
}

const calculateTotalDuration = (currentAddress: Address | undefined, additionalAddressList: Address[], moveInYear: number | undefined, moveInMonth: number | undefined) => {
    let totalResidencyMonths = 0;
     
    if (additionalAddressList && additionalAddressList.filter(x => x.residencyType === "Prior").length > 0) {
        let lastAddress = additionalAddressList[additionalAddressList.length - 1];
        if (lastAddress && lastAddress.moveInMonth != undefined && lastAddress.moveInYear) {
            let lastAddressDate = new Date(lastAddress.moveInYear ?? 0, lastAddress.moveInMonth - 1);
            totalResidencyMonths = dateDiff(lastAddressDate);
        }
    }
    else {
        if (currentAddress && currentAddress.moveInYear && currentAddress.moveInMonth != undefined) {
            let currentAddressDate = new Date(currentAddress.moveInYear ?? 0, currentAddress.moveInMonth - 1);
            if (currentAddressDate > new Date()) {
                totalResidencyMonths = -1;
            }
            else {
                totalResidencyMonths = dateDiff(currentAddressDate);
            }
        }
    }
    return totalResidencyMonths;
}

export const accountCreationReducer: Reducer<AccountCreationState> = (state: AccountCreationState | undefined, incomingAction: Action): AccountCreationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UpdateAddressMoveInDate:
            if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                if (action.addressType === AddressType.CurrentAddress) {
                    return {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            currentAddress: {
                                ...state.borrower.currentAddress,
                                moveInYear: action.moveInYear,
                                moveInMonth: action.moveInMonth
                            },
                            totalMonths: calculateTotalDuration(state.borrower.currentAddress, state.borrower.addresses ?? [], action.moveInYear, action.moveInMonth)
                        }
                    };
                }
            } else {
                if (action.addressType === AddressType.CurrentAddress) {
                    return {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            currentAddress: {
                                ...state.coBorrower?.currentAddress,
                                moveInYear: action.moveInYear,
                                moveInMonth: action.moveInMonth
                            },
                            totalMonths: calculateTotalDuration(state.coBorrower.currentAddress, state.coBorrower.addresses ?? [], action.moveInYear, action.moveInMonth)
                        },
                    };
                }
            }
            return { ...state }
        case ActionTypes.UpdateBorrowerProperty:
            if (action.propertyName == "printOnAdditionalBorrowerPage") {
                state.borrower.printOnAdditionalBorrowerPage = action.value;
            }
            return action.borrowerType === BorrowerType.PrimaryBorrower ?
                {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        [action.propertyName]: action.value
                    }
                } :
                {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        [action.propertyName]: action.value
                    }
                };
        case ActionTypes.UpdateBorrowerAddressProperty:
            if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                if (action.addressType === AddressType.CurrentAddress) {
                    return {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            currentAddress: {
                                ...state.borrower.currentAddress,
                                [action.propertyName]: action.value,
                                residencyType: "Current",
                                applicantType: "Borrower",
                                mailingAddressIndicator: false
                            },
                        },

                    };
                }
                else {
                    return {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            formerAddress: {
                                ...state.borrower.formerAddress,
                                [action.propertyName]: action.value,
                                residencyType: "Prior",
                                applicantType: "Borrower",
                                mailingAddressIndicator: false
                            }
                        }
                    };
                }
            }
            else {
                if (action.addressType === AddressType.CurrentAddress) {
                    return {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            currentAddress: {
                                ...state.coBorrower?.currentAddress,
                                [action.propertyName]: action.value,
                                residencyType: "Current",
                                applicantType: "CoBorrower",
                                mailingAddressIndicator: false
                            }
                        }
                    };
                }
                else {
                    return {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            formerAddress: {
                                ...state.coBorrower?.formerAddress,
                                [action.propertyName]: action.value,
                                residencyType: "Prior",
                                applicantType: "CoBorrower",
                                mailingAddressIndicator: false
                            }
                        }
                    };
                }
            }
        case ActionTypes.AddAddressProperty:
            {
                let borrower = state.borrower;
                let coBorrower = state.coBorrower;
                if (action.borrowerType === BorrowerType.PrimaryBorrower && borrower && borrower.addresses) {
                    const copyItems = borrower.addresses;
                    if (borrower && (borrower.addresses == undefined || (borrower.addresses && borrower.addresses.length < 1))) {
                        borrower.addresses = [];
                        borrower.addresses.push(action.address);
                        borrower.totalMonths = calculateTotalDuration(borrower.currentAddress, borrower.addresses, state.borrower.currentAddress?.moveInYear, state.borrower.currentAddress?.moveInMonth)
                    }
                    else if (borrower && borrower.addresses && copyItems) {
                        copyItems.splice(copyItems.length, 0, action.address);
                        borrower.addresses = copyItems;
                        borrower.totalMonths = calculateTotalDuration(borrower.currentAddress, borrower.addresses, state.borrower.currentAddress?.moveInYear, state.borrower.currentAddress?.moveInMonth)
                    }
                }
                else if (action.borrowerType === BorrowerType.CoBorrower && coBorrower && coBorrower.addresses) {
                    const copyItems = coBorrower.addresses;
                    if (coBorrower && (coBorrower.addresses == undefined || (coBorrower.addresses && coBorrower.addresses.length < 1))) {
                        coBorrower.addresses = [];
                        coBorrower.addresses.push(action.address);
                        coBorrower.totalMonths = calculateTotalDuration(coBorrower.currentAddress, coBorrower.addresses, state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth)
                    }
                    else if (coBorrower && coBorrower.addresses && copyItems) {
                        copyItems.splice(copyItems.length, 0, action.address);
                        coBorrower.addresses = copyItems;
                        coBorrower.totalMonths = calculateTotalDuration(coBorrower.currentAddress, coBorrower.addresses, state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth)
                    }
                }
                return {
                    ...state,
                    borrower: {
                        ...borrower
                    },
                    coBorrower: {
                        ...coBorrower
                    }
                }
            }
        case ActionTypes.EditAddressProperty: {
            let borrower = state.borrower;
            let coBorrower = state.coBorrower;
            if (action.borrowerType === BorrowerType.PrimaryBorrower && borrower && borrower.addresses) {
                let index = borrower.addresses.findIndex(x => x.id == action.address.id);
                if (index > -1) {
                    borrower.addresses[index] = action.address;
                    borrower.totalMonths = calculateTotalDuration(borrower.currentAddress, borrower.addresses, state.borrower.currentAddress?.moveInYear, state.borrower.currentAddress?.moveInMonth)
                }
            }
            else if (action.borrowerType === BorrowerType.CoBorrower && coBorrower && coBorrower.addresses) {
                let index = coBorrower.addresses.findIndex(x => x.id == action.address.id);
                if (index > -1) {
                    coBorrower.addresses[index] = action.address;
                    coBorrower.totalMonths = calculateTotalDuration(coBorrower.currentAddress, coBorrower.addresses, state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth)
                }
            }
            return {
                ...state,
                borrower: {
                    ...borrower
                },
                coBorrower: {
                    ...coBorrower
                }
            }
        }
        case ActionTypes.DeleteAddressProperty:
            if (action.borrowerType == BorrowerType.PrimaryBorrower && state.borrower && state.borrower.addresses) {
                 
                if (state.borrower.addresses.length == 1) {
                    let borrower = state.borrower;
                    borrower.addresses = [];
                    borrower.totalMonths = calculateTotalDuration(state.borrower.currentAddress, state.borrower.addresses, state.borrower.currentAddress?.moveInYear, state.borrower.currentAddress?.moveInMonth)
                    return {
                        ...state, borrower: { ...borrower }
                    }
                }
                else {
                    let copyItems = state.borrower.addresses.slice();
                    const index = copyItems.findIndex(i => i.id === action.id);
                    copyItems.splice(index, 1);
                    let borrower = state.borrower;
                    borrower.addresses = copyItems;
                    borrower.totalMonths = calculateTotalDuration(state.borrower.currentAddress, state.borrower.addresses,state.borrower.currentAddress?.moveInYear, state.borrower.currentAddress?.moveInMonth)
                    return {
                        ...state, borrower: { ...borrower }
                    }
                }
            }
            else if (action.borrowerType == BorrowerType.CoBorrower && state.coBorrower && state.coBorrower.addresses) {
                if (state.coBorrower.addresses.length == 1) {
                    let coBorrower = state.coBorrower;
                    coBorrower.addresses = [];
                    coBorrower.totalMonths = calculateTotalDuration(state.coBorrower.currentAddress, state.coBorrower.addresses, state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth)
                    return {
                        ...state, coBorrower: { ...coBorrower }
                    }
                }
                else {
                    let copyItems = state.coBorrower.addresses.slice();
                    const index = copyItems.findIndex(i => i.id === action.id);
                    copyItems.splice(index, 1);
                    let coBorrower = state.coBorrower;
                    coBorrower.addresses = copyItems;
                    coBorrower.totalMonths = calculateTotalDuration(state.coBorrower.currentAddress, state.coBorrower.addresses, state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth)
                    return {
                        ...state, coBorrower: { ...coBorrower }
                    }
                }
            }
            else {
                return {
                    ...state
                }
            }
        case ActionTypes.SetAddressSameAsBorrower:
            if (action.addressType == AddressType.CurrentAddress) {
                return {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        currentAddress: {
                            addressCity: action.value ? state.borrower.currentAddress?.addressCity : "",
                            addressPostalCode: action.value ? state.borrower.currentAddress?.addressPostalCode : "",
                            addressState: action.value ? state.borrower.currentAddress?.addressState : "",
                            addressStreetLine1: action.value ? state.borrower.currentAddress?.addressStreetLine1 : "",
                            residencyType: "Current",
                            applicantType: BorrowerTypeEnum.CoBorrower,
                            mailingAddressIndicator: false,
                            coBorrowerAddressChecked: action.value
                        },
                        totalMonths: calculateTotalDuration(state.coBorrower.currentAddress, state.coBorrower.addresses ?? [], state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth)
                    }
                };
            }
            else {
                state.coBorrower.addresses = [];
                let adddres = state.borrower.addresses?.filter(x => x.residencyType == "Prior");
                adddres?.forEach((item: Address) => {
                    item.applicantType = BorrowerTypeEnum.CoBorrower;
                    item.coBorrowerAddressChecked = action.value;
                });
                return {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        addresses: action.value ? adddres : [],
                        formerAddress: {
                            addressCity: action.value ? state.borrower.formerAddress?.addressCity : "",
                            addressPostalCode: action.value ? state.borrower.formerAddress?.addressPostalCode : "",
                            addressState: action.value ? state.borrower.formerAddress?.addressState : "",
                            addressStreetLine1: action.value ? state.borrower.formerAddress?.addressStreetLine1 : "",
                            residencyType: "Prior",
                            applicantType: BorrowerTypeEnum.CoBorrower,
                            mailingAddressIndicator: false,
                            coBorrowerAddressChecked: action.value
                        },
                        totalMonths: calculateTotalDuration(state.coBorrower.currentAddress, action.value ? adddres ?? [] : [], state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth)
                    }
                };
            }
        case ActionTypes.SetBorrowerValidation:

            let copyValidation = { ...state.validation };
            if (action.validationError) {
                copyValidation[action.field] = action.validationError;
            }
            else {
                if (copyValidation[action.field]) {
                    delete copyValidation[action.field];
                }
            }
            return {
                ...state,
                validation: copyValidation
            }
        case ActionTypes.ResetFormerAddress:
            if (action.borrowerType == BorrowerType.PrimaryBorrower) {
                state?.borrower?.addresses?.filter(x => x.residencyType == 'Prior').map((item, index) => {
                    if (state && state.borrower && state.borrower.addresses)
                        state.borrower.addresses.splice(index, 1);
                });
            }
            else if (action.borrowerType == BorrowerType.CoBorrower) {
                state.coBorrower.addresses?.filter(x => x.residencyType == 'Prior').map((item, index) => {
                    if (state && state.coBorrower && state.coBorrower.addresses)
                        state.coBorrower.addresses.splice(index, 1);
                });
            }
            return { ...state };
        case ActionTypes.GetBorrowerInformation: {
            if (action.borrowerType) {
                switch (action.borrowerType) {
                    case "Borrower": {
                        action.data.addresses = action.data.addresses ?? [];
                        const index = action.data.addresses.findIndex((i: any) => i.residencyType === "Current");
                        action.data.currentAddress = action.data.addresses[index];
                        action.data.addresses.splice(index, 1);
                        return {
                            ...state,
                            borrower: {
                                ...action.data,
                                totalMonths: calculateTotalDuration(action.data.currentAddress, action.data.addresses ?? [], state.borrower.currentAddress?.moveInYear, state.borrower.currentAddress?.moveInMonth),
                            }

                        }
                    }
                    case "CoBorrower": {
                        action.data.addresses = action.data.addresses ?? [];
                        const index = action.data.addresses.findIndex((i: any) => i.residencyType === "Current");
                        action.data.currentAddress = action.data.addresses[index];
                        action.data.addresses.splice(index, 1);
                        return {
                            ...state,
                            coBorrower: {
                                ...action.data,
                                totalMonths: calculateTotalDuration(action.data.currentAddress, action.data.addresses ?? [], state.coBorrower.currentAddress?.moveInYear, state.coBorrower.currentAddress?.moveInMonth),
                            }
                        }
                    }
                }
            }
            return { ...state };
        };
        case ActionTypes.UpdateOktaResult: {
            return { ...state, oktaNameResult: action.oktaNameResult };
        }
        default:
            return state;
    }
}