import Checkbox from "components/common/Input/Checkbox";
import LocationSearch from "components/common/Input/LocationSearch";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import styles from "./propertyInformation.module.scss";
import Tooltip from "../common/InfoIconTooltip";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { PropertyInformationState } from "../../store/propertyInformationStore";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import AddressFields, { AddressFieldsChange } from "../common/shared/AddressFields";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

interface PropertyAddressProps {
    question: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    county?: string;
    addressUnitIdentifier?: string;
    isAddressSameAsBorrower?: boolean;
    updatePropertyInformationValue: (value: any, propertyName: any) => any;
    onValidateAddressChange: (isValid: boolean) => any;
    disabled: boolean | undefined
}

const PropertyAddress = (props: PropertyAddressProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [isSameAsBorrower, setIsSameAsBorrower] = useState(props.isAddressSameAsBorrower);
    const [includeAddress, setIncludeAddress] = useState(false);
    const [showSameAsBorrowerCheckbox, setShowSameAsBorrowerCheckbox] = useState(false);
    const [format, setFormat] = React.useState("#########");

    const borrowerCurrentAddress = useSelector((state: ApplicationState) => state.accountCreation?.borrower?.currentAddress ?? undefined);
    const state: PropertyInformationState = useSelector((state: ApplicationState) => state.propertyInformation as PropertyInformationState);
    const [isLoading, setIsLoading] = useState(false);
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
    useEffect(() => {
        setShowSameAsBorrowerCheckbox(state.purposeType === "Refinance");
        setIncludeAddress(state.purposeType === "Refinance");
        handleZipCode(state.zip ?? "");
    }, [state.purposeType]);

    const handleZipCode = (zip: string) => {
        // remove all non digit characters
        let value = zip.replace(/\D/g, '');

        if (value.length > 5) {
            setFormat("#####-####");
        }
        else {
            setFormat("#########");
        }
        props.updatePropertyInformationValue(zip.trim(), "zip")
    }

    useEffect(() => {
        if (isSameAsBorrower) {
            let address = borrowerCurrentAddress?.addressStreetLine1 ?? "";
            let city = borrowerCurrentAddress?.addressCity ?? "";
            let state = borrowerCurrentAddress?.addressState ?? "";
            let zip = borrowerCurrentAddress?.addressPostalCode ?? "";

            props.updatePropertyInformationValue(address, "address");
            props.updatePropertyInformationValue(city, "city");
            props.updatePropertyInformationValue(state, "state");
            props.updatePropertyInformationValue(zip, "zip");
        }
    }, [isSameAsBorrower]);

    useEffect(() => {
        let isValidAddress = false;
        if (!isNullOrWhiteSpace(props.city) &&
            !isNullOrWhiteSpace(props.state) &&
            !isNullOrWhiteSpace(props.zip) &&
            (props.zip && props.zip?.length >= 5) &&
            !isNullOrWhiteSpace(props.county) &&
            !isLoading) {
            isValidAddress = true;
        }

        if (includeAddress) {
            isValidAddress = isValidAddress ? !isNullOrWhiteSpace(props.address) : isValidAddress;
        }

        props.onValidateAddressChange(isValidAddress);
    }, [props.address, props.city, props.state, props.zip, props.county, isLoading]);

    const setFromLocationSearch = (
        street: string,
        city: string,
        state: string,
        zip: string
    ) => {
        onLoading(true);
        props.updatePropertyInformationValue(city, "city");
        props.updatePropertyInformationValue(state, "state");
        props.updatePropertyInformationValue(zip, "zip");
        props.updatePropertyInformationValue(street, "address");
        props.updatePropertyInformationValue("", "county");
        onLoading(false);
    };

    const handleAddressFieldsChange = ({ city, state, postalCode, county }: AddressFieldsChange) => {
        if (city !== props.city) {
            props.updatePropertyInformationValue(city, "city");
        }
        if (state !== props.state) {
            props.updatePropertyInformationValue(state, "state");
        }
        if (postalCode !== props.zip) {
            props.updatePropertyInformationValue(postalCode, "zip");
        }
        if (county !== props.county) {
            props.updatePropertyInformationValue(county, "county");
        }
    }

    const onLoading = (value: boolean) => {
        setIsLoading(value);
    }
   
    return (
        <>
            <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.question)}>{props.question} <Tooltip>{"Please fill out the address information."}</Tooltip></h1>
            <div className={styles.addressDetails}>
                <div
                    className={`${styles.addressFieldContainer} ${
                        includeAddress ? styles.grid : ""
                        }`}>
                    {includeAddress && (
                        <div className={styles.inputGroup}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Street Address")}>Street Address <span className={styles.requiredMsg}> * </span></label>
                            <div className={styles.locationSearch}>
                                <LocationSearch
                                    addressStreetLine1={props.address ?? ""}
                                    addresssFieldName={"street"}
                                    borrowerType={"Primary"}
                                    customType={"Custom"}
                                    styles={""}
                                    isDisabled={props.disabled || isSameAsBorrower}
                                    setFullAddress={(street, city, state, zip) => {
                                        setFromLocationSearch(street, city, state, zip);
                                    }}
                                    />
                            </div>
                        </div>
                    )}
                    <AddressFields
                        city={props.city || ""}
                        cityFieldName={"city"}
                        state={props.state || ""}
                        stateFieldName={"state"}
                        postalCode={props.zip || ""}
                        postalCodeFieldName={"zip"}
                        county={props.county || ""}
                        countyFieldName={"county"}
                        isCountyFlag={true}
                        onValidChange={handleAddressFieldsChange}
                        onLoading={onLoading}
                        isDisabled={isSameAsBorrower}
                        onChange={props.updatePropertyInformationValue}
                        isloanCompleted={props.disabled}

                    />
                    {includeAddress && (
                        <div className={styles.inputGroup}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Unit Number (Optional)')}>Unit Number <span className={styles.optionalMsg}>(Optional)</span></label>

                            <NumberFormatInput
                                disabled={props.disabled}
                                className={styles.numberFormat}
                                value={props.addressUnitIdentifier ?? ""}
                                name="addressUnitIdentifier"
                                id="#addressUnitIdentifier"
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updatePropertyInformationValue(values.value, "addressUnitIdentifier")
                                }}
                            />
                        </div>
                    )}

                </div>
                {showSameAsBorrowerCheckbox && (
                    <div className={styles.coBorrowerCheckbox}>
                        <label className={styles.label} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Same as Borrower current address")}>Same as Borrower current address</label>
                        <Checkbox checked={isSameAsBorrower}
                            onClick={(checked) => {
                                setIsSameAsBorrower(checked);
                                props.updatePropertyInformationValue(checked, "isAddressSameAsBorrower");
                            }}
                            disabled={props.disabled}
                        ></Checkbox>
                    </div>
                )}
            </div>
            {isLoading && <div className="screenLoader"><span className="loader medium"></span> </div>}
        </>
    );
};

export default PropertyAddress;
