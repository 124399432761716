import { get, post } from "../common/dataFetch";
import { ManualAssetInformation } from "../store/ManualAssetInformationStore";
import { BorrowerAsset, BorrowerType } from "../store/assetInformationStore";
import { ResponseDto } from "common/models/ResponseDto";

export const getAll = (borrowerType:string,interimLoanStagingId:number) => {
    return get<any>(`AssetInformation/ReadByInterimIdAsync?borrowerType=${borrowerType}&interimLoanStagingId=${interimLoanStagingId}`);
};
export const getAssetDetailsFromEncompass = (loanGuid: string) => {
    return get<any>(`AssetInformation/ReadEncompassLoanAsync?loanGuid=${loanGuid}`);
};
export const updateAsset = (manualAsset: ManualAssetInformation, interimLoanStagingId:number) => {
    return post<any>(`AssetInformation/UpdateAsset?interimLoanStagingId=${interimLoanStagingId}`, manualAsset);
};
export const deleteAssetFromInterim = (id: string, interimLoanStagingId:number) => {
    return post<any>(`AssetInformation/DeleteAsset?id=${id}&interimLoanStagingId=${interimLoanStagingId}`,null);
};

export const readCoBorrowerAssetsStatus = (interimLoanStagingId: number) => {
    return get<any>(`AssetInformation/ReadCoBorrowerAssetsStatus?interimLoanStagingId=${interimLoanStagingId}`);
};

export const updateCoBorrowerAssetStatus = (interimLoanStagingId:number,coBorrowerAssetStatus ?: boolean) => {
    return post<any>(`AssetInformation/UpdateCoBorrowerAssetStatus?interimLoanStagingId=${interimLoanStagingId}`, coBorrowerAssetStatus);
};
export const updateProgressTracker = (borrowerType: string, loanGuid: string, hasCoborrower: boolean) => {
    return post<any>(`AssetInformation/UpdateProgressTracker?borrowerType=${borrowerType}&loanGuid=${loanGuid}&hasCoborrower=${hasCoborrower}`, null);
};

// fincity services
export const getSystematicAsset = (loanGuid: string) => {
    return get<any>(`AssetInformation/ReadSystematicAssetActionName?loanGuid=${loanGuid}`);
};

export const getAssetFinicityInformation = (loanGuid: string) => {
    return get<ResponseDto<any>>(`AssetInformation/ReadAssetFinicityInformationAsync?loanGuid=${loanGuid}`);
};

export const updateBorrowerInfo = (borrowerAsset: BorrowerAsset) => {
    return post<any>(`AssetInformation/UpsertAsync`, borrowerAsset);
};

export const submitVoa = (assetInformation: BorrowerAsset) => {
    return post<any>(`AssetInformation/SubmitVoa`, assetInformation);
};
export const UpsertAsyncsystematicInformation = (assetInformation: BorrowerAsset) => {
    return post<any>(`AssetInformation/SaveSystematicAssetInformation`, assetInformation);
};

export const getsystematicAssetInformation = (loanGuid: string, borrowerType: string) => {
    return get<BorrowerAsset>(`AssetInformation/ReadByLoanGuidAsync?loanGuid=${loanGuid}&borrowerType=${borrowerType}`);
};