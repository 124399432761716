export enum AccountTypeEnum {
    CheckingAccount = "Checking Account",
    SavingsAccount = "Savings Account",
    MoneyMarketFund = "Money Market Fund",
    CertificateOfDepositTimeDeposit = "Certificate Of Deposit",
    MutualFund = "Mutual Funds",
    Stock = "Stock",
    StockOptions = "Stock Options",
    Bond = "Bond",
    RetirementFund = "Retirement Funds",
    BridgeLoanNotDeposited = "Bridge Loan Not Deposited",
    IndividualDevelopmentAccount = "Individual Development Account",
    LifeInsurance = "Life Insurance",
    TrustAccount = "Trust Account",
    Other = "Other"

    //Not in Encompass
    //OtherLiquidAssets = "Other Liquid Assets",
    //PendingNetSaleProceedsFromRealEstateAssets = "Net Proceeds From Real Estate Assets",
    //GiftsTotal = "Gifts Total",
    //GiftsNotDeposited = "Gifts Not Deposited",
    //GiftOfEquity = "Gift Of Equity",
    //SecuredBorrowedFundsNotDeposited = "Secured Borrowed Funds Not Deposited",
    //CashDepositOnSalesContract = "Cash Deposit On Sales Contract",
    //CashOnHand = "Cash On Hand",
    //NetWorthOfBusinessOwned = "Net Worth Of Business Owned",
    //OtherNonLiquidAssets = "Other Non Liquid Assets",
    //NetEquity = "Net Equity",
    //Other = "Other",
}
