import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as BorrowerEmploymentStore from "../store/employmentInformationStore";
import { KnownAction } from '../store/employmentInformationStore';
import * as employmentInformationService from "../services/employmentInformationService";

const SubmittedEmploymentInformation = (borrowerType: BorrowerEmploymentStore.BorrowerType, value: boolean): KnownAction => {
    return { type: ActionTypes.SubmittedEmploymentInformation, borrowerType: borrowerType, value: value };
};

export const employmentInformationActionCreators = {
    getEmploymentInformation: (borrowerType: string,interimLoanStagingId:number): AppThunkAction<KnownAction> => async (dispatch) => {
        await employmentInformationService.getEmploymentInformation(borrowerType, interimLoanStagingId)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("There was a problem.");
                }
                return response.parsedBody as BorrowerEmploymentStore.Borrower;
            })
            .then((data: any) => {
                dispatch({ type: ActionTypes.GetEmploymentInformation, data: data, borrowerType: borrowerType });
            });

    },
    UpdateEmploymentInformationProperty: (value: any, propertyName: string, borrowerType: BorrowerEmploymentStore.BorrowerType, employmentItemId: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateEmploymentInformationProperty, value: value, propertyName: propertyName, borrowerType: borrowerType, employmentItemId: employmentItemId });
    },
    AddEmploymentItem: (id: string, data: BorrowerEmploymentStore.Employment, borrowerType: BorrowerEmploymentStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ id: "", type: ActionTypes.AddEmploymentItem, data: data, borrowerType: borrowerType });
    },
    DeleteEmploymentItem: (id: string, borrowerType: BorrowerEmploymentStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.DeleteEmploymentItem, id: id, borrowerType: borrowerType });
    },
    SubmitEmploymentInformation: (borrowerData: BorrowerEmploymentStore.Borrower): AppThunkAction<KnownAction> => async (dispatch) => {
        await employmentInformationService.updateEmploymentInformation(borrowerData)
            .then((response) => {
                if (!response.ok) {
                    // Should this be outside of the if statement?
                    dispatch(SubmittedEmploymentInformation(BorrowerEmploymentStore.BorrowerType.PrimaryBorrower, true));
                    console.error(response);
                    return false;
                }
                return true;
            })
            // This code below does seem un-neccessary
            .then(isSuccessful => {
                dispatch(SubmittedEmploymentInformation(BorrowerEmploymentStore.BorrowerType.PrimaryBorrower, true));
                if (!isSuccessful) {
                    return null;
                }
                else {
                    return true;
                }
            });
    },
    ResetEmploymentItem: (employmentItemId: string, borrowerType: BorrowerEmploymentStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.ResetEmploymentItem, employmentItemId: employmentItemId, borrowerType: borrowerType });
    },
    updateEmploymentInformationState: (data: BorrowerEmploymentStore.Borrower, borrowerType: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.GetEmploymentInformation, data: data, borrowerType: borrowerType });
    },
    SubmittedEmploymentInformation: (data: BorrowerEmploymentStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(SubmittedEmploymentInformation(data, true));
    },
    ReIndexingEmployment: (data: BorrowerEmploymentStore.Employment[], borrowerType: BorrowerEmploymentStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.ReIndexingEmployment, data: data, borrowerType: borrowerType });
    }
}