import React, { useState } from "react";
import ChoiceQuestion from "../common/ChoiceQuestion";
import PropertyInformationFundSourceComparison from "./PropertyInformationFundSourceComparison";
import { Choice } from "../../interfaces/Question";
import styles from "./propertyInformation.module.scss";
import checkingSavings from "assets/infographics/propertyInformation/funds/checkingSavings.svg";
import giftFunds from "assets/infographics/propertyInformation/funds/giftFunds.svg";
import pendingSale from "assets/infographics/propertyInformation/funds/pendingSale.svg";
import personalInvestmentAccount from "assets/infographics/propertyInformation/funds/personalInvestmentAccount.svg";
import retirementFunds from "assets/infographics/propertyInformation/funds/retirementFunds.svg";
import securedBorrowedFunds from "assets/infographics/propertyInformation/funds/securedBorrowedFunds.svg";
import { EnumManualAsset } from "../common/Enums/EnumManualAsset";

export interface PropertyInformationFundSourceProps {
    emitValueSelection: (value: string, propertyName: string) => void;
    value?: string;
    disabled: boolean | undefined;
}

const PropertyInformationFundSource = (props: PropertyInformationFundSourceProps) => {
    const [selectedValue, setSelectedValue] = useState("");

    const cardClicked = (value: string) => {
        setSelectedValue(value);
    };

    let downPaymentSourceChoices: Choice[] = [
        {
            text: EnumManualAsset.CheckingSavings,
            onClick: () => {
                cardClicked("CheckingSavings");
            },
            icon: checkingSavings,
            toolTipText: EnumManualAsset.CheckingSavings,
            className: props.value === "CheckingSavings" ? styles.selected : ""
        },
        {
            text: EnumManualAsset.RetirementFunds,
            onClick: () => {
                cardClicked("RetirementFunds");
            },
            icon: retirementFunds,
            toolTipText: EnumManualAsset.RetirementFunds,
            className: props.value === "RetirementFunds" ? styles.selected : ""
        },
        {
            text: EnumManualAsset.EquityOnPendingSale,
            onClick: () => {
                cardClicked("EquityOnPendingSale");
            },
            icon: pendingSale,
            toolTipText: EnumManualAsset.EquityOnPendingSale,
            className: props.value === "EquityOnPendingSale" ? styles.selected : ""
        },
        {
            text: EnumManualAsset.SecuredBorrowedFunds,
            onClick: () => {
                cardClicked("SecuredBorrowedFunds");
            },
            icon: securedBorrowedFunds,
            toolTipText: EnumManualAsset.SecuredBorrowedFunds,
            className: props.value === "SecuredBorrowedFunds" ? styles.selected : ""
        },
        {
            text: EnumManualAsset.StocksAndBonds,
            onClick: () => {
                cardClicked("StocksAndBonds");
            },
            icon: personalInvestmentAccount,
            toolTipText: EnumManualAsset.StocksAndBonds,
            className: props.value === "StocksAndBonds" ? styles.selected : ""
        },
        {
            text: EnumManualAsset.GiftFunds,
            onClick: () => {
                cardClicked("GiftFunds");
            },
            icon: giftFunds,
            toolTipText: EnumManualAsset.GiftFunds,
            className: props.value === "GiftFunds" ? styles.selected : ""
        },
    ];
    return (
        <div className={styles.fundSourceContainer}>
            <ChoiceQuestion
                disabled={props.disabled}
                intro=""
                question="What Is The Source For Your Down Payment?"
                choices={downPaymentSourceChoices}
            />
            <PropertyInformationFundSourceComparison
                selectedType={selectedValue}
                onContinue={() => props.emitValueSelection(selectedValue, "downPaymentSource")}
                disabled={props.disabled}
            ></PropertyInformationFundSourceComparison>
        </div>
    );
};

export default PropertyInformationFundSource;
