import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { AssetInformationState, BorrowerType, KnownAction } from "../store/assetInformationStore";

const unloadedState: AssetInformationState = {
    coBorrowerAssetStatus: undefined,
    borrower: {
        birthDate: undefined,
        taxIdentificationIdentifier: undefined,
    },
    coBorrower: {},
    borrowerComplete: false,
    coBorrowerComplete: false,
    submitBorrower: false,
    submitCoBorrower: false
}
export const assetInformationReducer: Reducer<AssetInformationState> = (state: AssetInformationState | undefined, incomingAction: Action): AssetInformationState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {

        case ActionTypes.UPDATE_BORROWER_ASSET_PROPERTY:
            return action.borrowerType === BorrowerType.PrimaryBorrower ?
                {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        [action.propertyName]: action.value
                    }
                } :
                {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        [action.propertyName]: action.value
                    }
                };

        case ActionTypes.SUBMIT_BORROWER_ASSET_INFORMATION:
            if (action.borrowerType === 1) {
                return {
                    ...state,
                    submitBorrower: true
                }
            }
            else if (action.borrowerType === 2) {
                return {
                    ...state,
                    submitCoBorrower: true
                }
            }
            else {
                return {
                    ...state,
                    submitCoBorrower: false,
                    submitBorrower: false
                }
            };



        case ActionTypes.UpdateCoBorrowerAssetStatus: {
            return {
                ...state,
                coBorrowerAssetStatus: action.value
            };
        };
        case ActionTypes.ReadSystematicAsset:
            {
                let result;
                let data = action.data;

                if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                    result = {

                        ...state,
                        borrower: data,
                    }
                    return result;
                }
                else if (action.borrowerType === BorrowerType.CoBorrower) {
                    result = {

                        ...state,
                        coBorrower: data,
                    }
                    return result;
                }
            };
        default:
            return state;
    }
}