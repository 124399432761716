import * as React from "react";
import { connect } from 'react-redux';
import { ZipDetail } from '../../../store/ZipCodeLookUpStore';
import { CountyDetail } from '../../../store/CountyLookUpStore';
import { zipCodeLookupActionCreators } from '../../../actions/zipCodeLookupActions';
import { ApplicationState } from '../../../store';
import { FormGroup, Label } from "reactstrap";
import TextInput from "../../common/Input/TextInput";
import { NumberFormatInput } from "../../common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";
import CountySelection from "../../common/shared/CountySelectionBox";
import ZipCodeSelection from "../../common/shared/ZipCodeSelectionBox";
import * as ZipCodeLookUpService from "services/zipCodeLookupService"
import { countyLookupActionCreators } from '../../../actions/countyLookupActions';
import * as CountyLookUpService from "services/countyLookupService"
import styles from "../../common/shared/zipCode.module.scss";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type AddressFieldProps = AddressFieldModel & typeof zipCodeLookupActionCreators & typeof countyLookupActionCreators

enum Fields {
    City = "city",
    State = "state",
    PostalCode = "postalCode",
    County = "county"
};

export interface AddressFieldsChange {
    city: string;
    state: string;
    postalCode: string;
    county?: string;
}

interface AddressFieldModel {
    zipDetails?: ZipDetail[];
    countyDetails?: CountyDetail[];
    city: string;
    cityFieldName: string;
    state: string;
    stateFieldName: string;
    postalCode: string;
    postalCodeFieldName: string;
    county?: string;
    countyFieldName?: string;
    isCountyFlag: boolean;
    onChange: (value: any, name: string) => void;
    onValidChange?: (values: AddressFieldsChange) => void;
    onLoading: (values: boolean) => void;
    isDisabled?: boolean,
    isloanCompleted: boolean | undefined,
};

const AddressFields = (props: AddressFieldProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [cityStateSelectionModalEntries, setCityStateSelectionModalEntries] = React.useState<ZipDetail[] | null>(null);
    const [postalCodeSelectionModalEntries, setPostalCodeSelectionModalEntries] = React.useState<ZipDetail[] | null>(null);
    const [cityStateCountySelectionModalEntries, setCityStateCountySelectionModalEntries] = React.useState<CountyDetail[] | null>(null);

    const [city, setCity] = React.useState(props.city);
    const [state, setState] = React.useState(props.state);
    const [postalCode, setPostalCode] = React.useState(props.postalCode);
    const [county, setCounty] = React.useState(props.county);

    const [lastValidCity, setLastValidCity] = React.useState(props.city);
    const [lastValidState, setLastValidState] = React.useState(props.state);
    const [lastValidPostalCode, setLastValidPostalCode] = React.useState(props.postalCode);
    const [lastValidCounty, setLastValidCounty] = React.useState(props.county);

    const [isCityStateValid, setIsCityStateValid] = React.useState(true);
    const [isPostalCodeValid, setIsPostalCodeValid] = React.useState(true);

    const [lastModifiedField, setLastModifiedField] = React.useState<Fields | undefined>();

    const handleOnValidChange = (values: AddressFieldsChange) => {
        setLastValidCity(values.city);
        setLastValidState(values.state);
        setLastValidPostalCode(values.postalCode);
        setLastValidCounty(values.county);

        if (props.onValidChange) {
            props.onValidChange(values);
        }
    }

    const fetchZipCodeByCityState = async (cityQuery: string, stateQuery: string) => {
        const response = await ZipCodeLookUpService.getZipCodeByCityState({ city: cityQuery, stateCode: stateQuery, zipCode: "" });
        if (!response.ok || !response.parsedBody) {
            throw new Error("There was a problem getting zip code detail.");
        }
        return response.parsedBody;
    }

    const fetchZipCodeDetail = async (postalCodeQuery: string) => {
        const response = await ZipCodeLookUpService.getZipCodeDetail(postalCodeQuery);
        if (!response.ok || !response.parsedBody) {
            throw new Error("There was a problem getting zip code detail.");
        }
        return response.parsedBody;
    }

    const fetchCountyDetail = async (postalCodeQuery: string) => {
        const response = await CountyLookUpService.getCountyDetail(postalCodeQuery);
        if (!response.ok || !response.parsedBody) {
            console.log("There was a problem getting county detail.");
        }
        return response.parsedBody;
    }

    const validateCityState = async (cityQuery: string, stateQuery: string): Promise<[boolean, ZipDetail[] | undefined]> => {
        let isValid = true;
        let zipDetails: ZipDetail[] | undefined;

        if (cityQuery === null || stateQuery === null) {
            isValid = false;
        } else if (cityQuery === lastValidCity && stateQuery === lastValidState) {
            isValid = isCityStateValid;
        } else {
            zipDetails = await fetchZipCodeByCityState(cityQuery, stateQuery);
            isValid = zipDetails.length !== 0;
        }

        return [isValid, zipDetails];
    }

    const validatePostalCode = async (postalCodeQuery: string): Promise<[boolean, ZipDetail[] | undefined]> => {
        let isValid = true;
        let zipDetails: ZipDetail[] | undefined;

        if (postalCodeQuery.length < 5) {
            isValid = false;
        } else if (postalCodeQuery === lastValidPostalCode && props.county != "") {
            isValid = isPostalCodeValid;
        } else {
            zipDetails = await fetchZipCodeDetail(postalCodeQuery);
            isValid = zipDetails.length !== 0;
        }
        return [isValid, zipDetails];
    }

    const validatePostalCodeforCounty = async (postalCodeQuery: string): Promise<[boolean, CountyDetail[] | undefined]> => {
        let isValid = true;
        let countyDetails: CountyDetail[] | undefined;

        if (postalCodeQuery.length < 5) {
            isValid = false;
        } else if (postalCodeQuery === lastValidPostalCode && county != "") {
            isValid = isPostalCodeValid;
        } else {
            countyDetails = await fetchCountyDetail(postalCodeQuery);
            isValid = countyDetails != undefined && countyDetails.length !== 0;
        }
        return [isValid, countyDetails];
    }

    const isNullOrEmpty = (value?: string | null) => {
        return value === undefined || value === null || value.length === 0;
    }

    const isAddressValid = (city: string, state: string, postalCode: string, isCityStateValid: boolean, isPostalCodeValid: boolean) => {
        const allFieldsEmpty = isNullOrEmpty(city) && isNullOrEmpty(state) && isNullOrEmpty(postalCode);
        const allFieldsNotEmpty = !isNullOrEmpty(city) && !isNullOrEmpty(state) && !isNullOrEmpty(postalCode);

        return allFieldsEmpty || (isCityStateValid && isPostalCodeValid && allFieldsNotEmpty);
    }

    const validateAddress = async (city: string, state: string, postalCode: string, lastModifiedField: Fields): Promise<[boolean, ZipDetail | undefined]> => {
        let isValid = false;
        let zipDetails: ZipDetail[] | undefined;

        switch (lastModifiedField) {
            case Fields.City:
            case Fields.State:
                [isValid, zipDetails] = await validateCityState(city, state);
                break;
            case Fields.PostalCode:
                [isValid, zipDetails] = await validatePostalCode(postalCode);
                break;
        }

        if ((zipDetails && zipDetails.length === 1)) {
            const zipDetail = zipDetails[0];

            if (zipDetail != undefined) {
                props.onChange(zipDetail.city, props.cityFieldName);
                props.onChange(zipDetail.stateCode, props.stateFieldName);
                props.onChange(zipDetail.zipCode, props.postalCodeFieldName);
            }
            switch (lastModifiedField) {
                case Fields.City:
                case Fields.State:
                    setIsCityStateValid(true);
                    return [isAddressValid(zipDetail.city, zipDetail.stateCode, zipDetail.zipCode, true, isPostalCodeValid), zipDetail,];
                case Fields.PostalCode:
                    setIsPostalCodeValid(true);
                    return [isAddressValid(zipDetail.city, zipDetail.stateCode, zipDetail.zipCode, isCityStateValid, true), zipDetail];
            }
        }

        else if (zipDetails && zipDetails.length > 1) {
            switch (lastModifiedField) {
                case Fields.City:
                case Fields.State:
                    setPostalCodeSelectionModalEntries(zipDetails);
                    break;
                case Fields.PostalCode:
                    setCityStateSelectionModalEntries(zipDetails);
                    break;
            }
            return [false, undefined];
        }
        else {

            switch (lastModifiedField) {
                case Fields.City:
                case Fields.State:
                    setIsCityStateValid(isValid);
                    return [isAddressValid(city, state, postalCode, false, isPostalCodeValid), undefined];
                case Fields.PostalCode:
                    setIsPostalCodeValid(isValid);
                    return [isAddressValid(city, state, postalCode, isCityStateValid, false), undefined];
            }
            return [isValid, undefined];
        }
        return [false, undefined];
    }

    const validateAddressCounty = async (city: string, state: string, postalCode: string, lastModifiedField: Fields): Promise<[boolean, CountyDetail | undefined]> => {
        let isValid = false;
        let countyDetails: CountyDetail[] | undefined;

        switch (lastModifiedField) {
            case Fields.City:
            case Fields.State:
            case Fields.PostalCode:
                [isValid, countyDetails] = await validatePostalCodeforCounty(postalCode);
                break;
        }

        if ((props.isCountyFlag && countyDetails && countyDetails.length === 1)) {
            const countyDetail = countyDetails[0];

            props.onChange(countyDetail.countyCity, props.cityFieldName);
            props.onChange(countyDetail.countyStateCode, props.stateFieldName);
            props.onChange(countyDetail.countyZipCode, props.postalCodeFieldName);
            props.onChange(countyDetail.countyName, "county");

            switch (lastModifiedField) {
                case Fields.City:
                case Fields.State:
                case Fields.PostalCode:
                    setIsPostalCodeValid(true);
                    return [isAddressValid(countyDetail.countyCity, countyDetail.countyStateCode, countyDetail.countyZipCode, isCityStateValid, true), countyDetail];
            }
        }
        else if (props.isCountyFlag && countyDetails && countyDetails.length > 1) {
            switch (lastModifiedField) {
                case Fields.City:
                case Fields.State:
                case Fields.PostalCode:
                    setCityStateCountySelectionModalEntries(countyDetails);
                    break;
            }
            return [false, undefined];
        }
        else {
            switch (lastModifiedField) {
                case Fields.City:
                case Fields.State:
                case Fields.PostalCode:
                    setIsPostalCodeValid(isValid);
                    return [isAddressValid(city, state, postalCode, isCityStateValid, false), undefined];
            }
            return [isValid, undefined];
        }
        return [false, undefined];
    }

    const onModalSelection = (data: ZipDetail) => {
        if (postalCodeSelectionModalEntries) {
            setPostalCodeSelectionModalEntries(null);
            props.onChange(data.stateCode, props.stateFieldName)
            props.onChange(data.zipCode, props.postalCodeFieldName);
        }

        if (cityStateSelectionModalEntries) {
            setCityStateSelectionModalEntries(null);
            props.onChange(data.city, props.cityFieldName)
            props.onChange(data.stateCode, props.stateFieldName);
        }

        setIsCityStateValid(true);
        setIsPostalCodeValid(true);
        setLastModifiedField(undefined);

        handleOnValidChange({ city: data.city, state: data.stateCode, postalCode: data.zipCode });
    };

    const onCountyModalSelection = (data: CountyDetail) => {

        if (cityStateCountySelectionModalEntries) {
            setCityStateCountySelectionModalEntries(null);

            props.onChange(data.countyCity, props.cityFieldName);
            props.onChange(data.countyStateCode, props.stateFieldName);
            props.onChange(data.countyName, "county");
        }
        setIsCityStateValid(true);
        setIsPostalCodeValid(true);
        setLastModifiedField(undefined);

        handleOnValidChange({ city: data.countyCity, state: data.countyStateCode, postalCode: data.countyZipCode, county: data.countyName });
    };

    const onCityBlur = async (value: any) => {
        props.onLoading(true);
        const [isValid, zipDetail] = await validateAddress(value, state, postalCode, Fields.City);
        if (isValid) {
            const newValues = zipDetail
                ? { city: value, state: zipDetail.stateCode, postalCode: zipDetail.zipCode }
                : { city: value, state, postalCode };
            handleOnValidChange(newValues);
        }
        if (city) {
            setLastModifiedField(Fields.City);
        }

        props.onChange(value, props.cityFieldName);
        props.onChange(value, props.cityFieldName);
        props.onLoading(false);
    }

    const onStateBlur = async (value: any) => {
        props.onLoading(true);

        const [isValid, zipDetail] = await validateAddress(city, value, postalCode, Fields.State);
        if (isValid) {
            const newValues = zipDetail
                ? { city: zipDetail.city, state: value, postalCode: zipDetail.zipCode }
                : { city, state: value, postalCode };
            handleOnValidChange(newValues);
        }
        if (state) {
            setLastModifiedField(Fields.State);
        }
        props.onChange(value, props.stateFieldName);
        props.onLoading(false);
    }

    const onPostalCodeBlur = async (value: any) => {
        props.onLoading(true);
        if (props.isCountyFlag) {
            const [isValid, countydetail] = await validateAddressCounty(city, state, value, Fields.PostalCode);
            if (isValid) {
                const newValues = countydetail
                    ? { city: countydetail.countyCity, state: countydetail.countyStateCode, postalCode: value, county: countydetail.countyName }
                    : { city, state, postalCode: value };
                handleOnValidChange(newValues);
            }
        }
        else {
            const [isValid, zipCodeDetails] = await validateAddress(city, state, value, Fields.PostalCode);
            if (isValid) {
                const newValues = zipCodeDetails
                    ? { city: zipCodeDetails.city, state: zipCodeDetails.stateCode, postalCode: value }
                    : { city, state, postalCode: value };
                handleOnValidChange(newValues);
            }
        }
        props.onChange(value, props.postalCodeFieldName);

        if (props.postalCode) {
            setLastModifiedField(Fields.PostalCode);
        }
        props.onLoading(false);
    }
    const zipCodeFormat = (value: string) => {
        if (value.length > 5) {
            value = [value.slice(0, 5), '-', value.slice(5)].join('');

            if (value.length >= 10) {
                return value.slice(0, 10);
            }
        }
        return value;
    }

    React.useEffect(() => {
        if (props.isCountyFlag && !props.isloanCompleted) {
            if ((props.county == "") && (props.postalCode)) {
                onPostalCodeBlur(props.postalCode);
            }
        }
    }, [props.postalCode]);

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }

    return (
        <>
            <div>
                <FormGroup>
                    <Label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City')}>City <span className={styles.requiredMsg}> * </span></Label>
                    <TextInput
                        disabled={props.isDisabled || props.isloanCompleted}
                        id={props.cityFieldName}
                        name={Fields.City}
                        value={props.city || ''}
                        onChange={(e: any) => { props.onChange(e.target.value, props.cityFieldName) }}
                        onBlur={(e: any) => !props.isCountyFlag ? onCityBlur(e.target.value) : ""}
                    />
                    {!isCityStateValid && lastModifiedField === Fields.City && state !== "" && city !== ""
                        && <span className={styles.errorMessage}>Invalid city '{city}' for state '{state}'.</span>}
                </FormGroup>
            </div>
            <div>
                <FormGroup>
                    <Label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State <span className={styles.requiredMsg}> * </span></Label>
                    <TextInput
                        disabled={props.isDisabled || props.isloanCompleted}
                        id={props.stateFieldName}
                        name={Fields.State}
                        value={props.state || ''}
                        maxLength="2"
                        onChange={(e: any) => { props.onChange(e.target.value.toUpperCase(), props.stateFieldName) }}
                        onBlur={(e: any) => !props.isCountyFlag ? onStateBlur(e.target.value.toUpperCase()) : ""}
                    />
                    {!isCityStateValid && lastModifiedField === Fields.State && state !== "" && city !== ""
                        && <span className={styles.errorMessage}>Invalid state '{state}' for city '{city}'.</span>}
                </FormGroup>
            </div>
            <div>
                <FormGroup>
                    <Label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Zip Code')}>Zip Code <span className={styles.requiredMsg}> * </span></Label>
                    <NumberFormatInput
                        format={zipCodeFormat}
                        disabled={props.isDisabled || props.isloanCompleted}
                        id={props.postalCodeFieldName}
                        name={Fields.PostalCode}
                        value={props.postalCode || ''}
                        onValueChange={(values: NumberFormatValues) => { props.onChange(values.formattedValue.trim(), props.postalCodeFieldName) }}
                        onChange={(value: any) => { onPostalCodeBlur(value.target.value); }}
                    />
                    {!isPostalCodeValid && lastModifiedField === Fields.PostalCode
                        && <span className={styles.errorMessage}>Invalid Zip code.</span>}
                </FormGroup>
            </div>
            {props.isCountyFlag &&
                <div>
                    <FormGroup>
                    <Label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('County')}>County <span className={styles.requiredMsg}> * </span></Label>
                        <TextInput
                            disabled={props.isloanCompleted}
                            id={props.countyFieldName}
                            name={Fields.County}
                            value={props.county || ''}
                            onChange={(e: any) => { props.onChange(e.target.value, "county") }}
                        />
                    </FormGroup>
                </div>
            }

            {!props.isDisabled && !props.isCountyFlag && postalCode !== "" && cityStateSelectionModalEntries && cityStateSelectionModalEntries.length > 1 && lastModifiedField === Fields.PostalCode &&
                <ZipCodeSelection
                    zipDetails={cityStateSelectionModalEntries}
                    zipCode={postalCode}
                    isOpen={true}
                    onSelection={(data: any) => onModalSelection(data)}
                    onCancel={() => setCityStateSelectionModalEntries(null)}
                />
            }

            {!props.isDisabled && !props.isCountyFlag && city !== "" && state !== "" && postalCodeSelectionModalEntries && postalCodeSelectionModalEntries.length > 1 &&
                (lastModifiedField === Fields.City || lastModifiedField === Fields.State) &&
                <ZipCodeSelection
                    zipDetails={postalCodeSelectionModalEntries}
                    cityName={city}
                    stateCode={state}
                    isOpen={true}
                    onSelection={(data: any) => onModalSelection(data)}
                    onCancel={() => setPostalCodeSelectionModalEntries(null)}
                />
            }
            {/*-- Showing modal for county selection --*/}
            {props.isCountyFlag && (!props.isloanCompleted) && props.postalCode !== "" && cityStateCountySelectionModalEntries && cityStateCountySelectionModalEntries.length > 1
                && lastModifiedField === Fields.PostalCode &&
                <CountySelection
                    countyDetails={cityStateCountySelectionModalEntries}
                    isOpen={true}
                    zipCode={postalCode}
                    onSelection={(data: any) => onCountyModalSelection(data)}
                    onCancel={() => setCityStateCountySelectionModalEntries(null)}
                />
            }
        </>);
}

const mapStateToProps = (state: ApplicationState): any => {
    const { zipCodeLookUp, countyLookUp } = state;
    let zipDetails: any = [];
    let countyDetails: any = [];

    if (zipCodeLookUp && zipCodeLookUp.data !== undefined) {
        zipDetails = zipCodeLookUp.data;
    }

    if (countyLookUp && countyLookUp.data !== undefined) {
        countyDetails = countyLookUp.data;
    }

    return {
        zipDetails,
        countyDetails
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    getZipCodeDetail: (zipCode: string) => {
        dispatch(zipCodeLookupActionCreators.getZipCodeDetail(zipCode));
    },
    getZipCodeByCityState: (zipDetail: ZipDetail) => {
        dispatch(zipCodeLookupActionCreators.getZipCodeByCityState(zipDetail));
    },
    clearZipDetails: () => {
        dispatch(zipCodeLookupActionCreators.clearZipDetails());
    },
    getCountyDetail: (zipCode: string) => {
        dispatch(countyLookupActionCreators.getCountyDetail(zipCode));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(AddressFields as any) as React.FC<AddressFieldModel>;