import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { EmploymentInformationState, BorrowerType, KnownAction, Employment } from "../store/employmentInformationStore";
import { EmploymentInformationFields, borrowerField, DummyData } from "../components/employmentInformation/EmploymentInformationFields";
import { from } from "linq"
import { dateDiff, isValidDate, toDate } from '../common/helper/formatHelper';

const unloadedState: EmploymentInformationState = {
    borrower: {
        loanGuid: "",
        employments: [],
        name: ""
    },
    coBorrower: {
        loanGuid: "",
        employments: [],
        name: ""
    },
    borrowerComplete: false,
    coBorrowerComplete: false,
    submittedBorrower: false,
    submittedCoBorrower: false,
    hasCoBorrower: false,
    previousId: ""
}

const getNewEmployment = (employmentDetail: Employment[], value: string, index: number, employmentItemId: string) => {

    let addNewEmployment = false;
    let totalEmploymnetInMonths: number = 0;
    let currentEmploymentYears: number = 0;
    let currentEmploymentMonths: number = 0;
    let endDate = new Date();
    let currentEmployer = employmentDetail.find(x => x.id === employmentItemId);

    if (index > 0) {
        if ((employmentDetail[index - 1].employmentStartDate) && !currentEmployer?.currentEmploymentIndicator) {
            endDate = new Date(employmentDetail[index - 1].employmentStartDate);
        } else {
            new Date();
        }
        if (value)
            totalEmploymnetInMonths = dateDiff(value, endDate ?? new Date());
    } else {
        if (employmentDetail.length > 0 && value) {
            totalEmploymnetInMonths = dateDiff(value);
        }
    }

    currentEmploymentYears = Math.floor(totalEmploymnetInMonths / 12);
    currentEmploymentMonths = totalEmploymnetInMonths % 12;
    let months = 0;
    let tempIndex = index;
    if (index > 0) {
        let currentEmployers = employmentDetail.filter(x => x.currentEmploymentIndicator == true).slice()
            .sort((a, b) => Date.parse(a.employmentStartDate) - Date.parse(b.employmentStartDate));
        if (currentEmployers.length > 1) {
            employmentDetail = employmentDetail.filter(x => x.employmentStartDate != currentEmployers[0].employmentStartDate);
            tempIndex = employmentDetail.findIndex(i => i.id === employmentItemId) > -1 ?
                employmentDetail.findIndex(i => i.id === employmentItemId) : index - 1;
        }

        employmentDetail.map((item, i) => {
            if (i <= (tempIndex)) {
                months += ((item?.timeOnJobTermYears ?? 0) * 12) + (item?.timeOnJobTermMonths ?? 0);
            }
        });
    }
    else {
        months = currentEmploymentYears * 12 + currentEmploymentMonths;
    }

    if (months < 24) {
        addNewEmployment = true;
    }
    else {
        addNewEmployment = false;
    }

    return {
        addNewEmployment: addNewEmployment,
        currentEmploymentYears: currentEmploymentYears,
        currentEmploymentMonths: currentEmploymentMonths,
        endDate: endDate
    };
}

export const employmentInformationReducer: Reducer<EmploymentInformationState> = (state: EmploymentInformationState | undefined, incomingAction: Action): EmploymentInformationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UpdateEmploymentInformationProperty: {
            if (action.borrowerType === BorrowerType.PrimaryBorrower && state.borrower && state.borrower.employments) {
                if (borrowerField.filter((val) => val.includes(action.propertyName))[0] == action.propertyName) {
                    return {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            [action.propertyName]: action.value
                        }
                    }
                }
                else {
                    let copyItem = state.borrower.employments;
                    const index = copyItem.findIndex(i => i.id === action.employmentItemId);
                    let currentEmployment = copyItem[index];
                    if (EmploymentInformationFields.StartDate === action.propertyName) {
                        if (action.value && isValidDate(toDate(action.value))) {

                            let data = getNewEmployment(copyItem, action.value, index, action.employmentItemId);

                            if (data) {

                                currentEmployment.timeOnJobTermMonths = data.currentEmploymentMonths;
                                currentEmployment.timeOnJobTermYears = data.currentEmploymentYears;
                                currentEmployment.endDate = data.endDate;
                                currentEmployment.needAdditionalEmployment = data.addNewEmployment;
                                currentEmployment.employmentStartDate = action.value;
                            }
                        } else {
                            currentEmployment.employmentStartDate = action.value;
                        }
                    }
                    else {
                        currentEmployment = {
                            ...currentEmployment,
                            [action.propertyName]: action.value
                        }
                        let months = 0;
                        if (index > 0) {

                            let tempEmployments: Employment[];
                            tempEmployments = state.borrower.employments;
                            let currentEmployers = tempEmployments.filter(x => x.currentEmploymentIndicator == true).slice()
                                .sort((a, b) => Date.parse(a.employmentStartDate) - Date.parse(b.employmentStartDate));
                            let tempIndex = index;
                            if (currentEmployers.length > 1) {
                                tempEmployments = tempEmployments.filter(x => x.employmentStartDate != currentEmployers[0].employmentStartDate);
                                tempIndex = tempEmployments.findIndex(i => i.id === action.employmentItemId);
                            }

                            tempEmployments.map((item, i) => {
                                if (i <= (tempIndex)) {
                                    months += ((item?.timeOnJobTermYears ?? 0) * 12) + (item?.timeOnJobTermMonths ?? 0);
                                }
                            });
                        } else {
                            months = dateDiff(currentEmployment?.employmentStartDate);
                        }

                        currentEmployment.needAdditionalEmployment = false;
                        if (months < 24) {
                            currentEmployment.needAdditionalEmployment = true;
                        }
                    }

                    copyItem[index] = currentEmployment;
                    let borrower = state.borrower;
                    return {
                        ...state,
                        borrower: {
                            ...borrower,
                            employments: copyItem
                        }
                    }
                }
            }
            else if (action.borrowerType === BorrowerType.CoBorrower && state.coBorrower && state.coBorrower.employments) {
                if (borrowerField.filter((val) => val.includes(action.propertyName))[0] == action.propertyName) {
                    return {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            [action.propertyName]: action.value
                        }
                    }
                }
                else {
                    let copyItem = state.coBorrower.employments;
                    const index = copyItem.findIndex(i => i.id === action.employmentItemId);
                    let currentEmployment = copyItem[index];
                    if (EmploymentInformationFields.StartDate === action.propertyName) {
                        if (action.value && isValidDate(toDate(action.value))) {
                            let data = getNewEmployment(copyItem, action.value, index, action.employmentItemId);

                            if (data) {
                                currentEmployment.timeOnJobTermMonths = data.currentEmploymentMonths;
                                currentEmployment.timeOnJobTermYears = data.currentEmploymentYears;
                                currentEmployment.endDate = data.endDate;
                                currentEmployment.needAdditionalEmployment = data.addNewEmployment;
                                currentEmployment.employmentStartDate = action.value;
                            }
                        }
                        else {
                            currentEmployment.employmentStartDate = action.value;
                        }
                    }
                    else {
                        currentEmployment = {
                            ...currentEmployment,
                            [action.propertyName]: action.value
                        }
                        let months = 0;
                        if (index > 0) {
                            let tempEmployments: Employment[];
                            tempEmployments = state.coBorrower.employments;
                            let currentEmployers = tempEmployments.filter(x => x.currentEmploymentIndicator == true).slice()
                                .sort((a, b) => Date.parse(a.employmentStartDate) - Date.parse(b.employmentStartDate));
                            let tempIndex = index;
                            if (currentEmployers.length > 1) {
                                tempEmployments = tempEmployments.filter(x => x.employmentStartDate != currentEmployers[0].employmentStartDate);
                                tempIndex = tempEmployments.findIndex(i => i.id === action.employmentItemId);
                            }


                            tempEmployments.map((item, i) => {
                                if (i <= (tempIndex)) {
                                    months += ((item?.timeOnJobTermYears ?? 0) * 12) + (item?.timeOnJobTermMonths ?? 0);
                                }
                            });
                        } else {
                            months = dateDiff(currentEmployment?.employmentStartDate);
                        }

                        currentEmployment.needAdditionalEmployment = false;
                        if (months < 24) {
                            currentEmployment.needAdditionalEmployment = true;
                        }
                    }

                    copyItem[index] = currentEmployment;
                    let coBorrower = state.coBorrower;
                    return {
                        ...state,
                        coBorrower: {
                            ...coBorrower,
                            employments: copyItem
                        }
                    }
                }
            }
        }
            return { ...state };
        case ActionTypes.AddEmploymentItem: {

            if (action.borrowerType === BorrowerType.PrimaryBorrower && state.borrower && state.borrower.employments) {
                let newItem = action.data;
                if (state.borrower.employments.length < 1) {
                    state.borrower.employments.push(newItem);
                    state.borrower.borrowerType = BorrowerType.PrimaryBorrower;
                    return { ...state };
                }
                else {
                    let lastId = (from(state.borrower.employments).lastOrDefault()?.employmentId ?? 0) + 1;
                    let id = `Employment/${lastId}`;
                    newItem.id = id;
                    newItem.employmentId = lastId;
                    //newItem.currentEmploymentIndicator = false;
                    newItem.owner = "Borrower";
                    const copyItems = state.borrower.employments;
                    copyItems.splice(copyItems.length, 0, newItem);
                    let borrower = { ...state.borrower };
                    borrower.employments = copyItems;
                    return {
                        ...state,
                        borrower: {
                            ...borrower,
                            borrowerType: BorrowerType.PrimaryBorrower
                        }
                    }
                }
            }
            else if (action.borrowerType === BorrowerType.CoBorrower && state.coBorrower && state.coBorrower.employments) {
                let newItem = action.data;
                if (state.coBorrower.employments.length < 1) {
                    //append id by 1 for coborrower
                    newItem.employmentId = newItem.employmentId + 1;
                    newItem.id = `Employment/${newItem.employmentId}`;

                    state.coBorrower.employments.push(newItem);
                    state.borrower.borrowerType = BorrowerType.CoBorrower;
                    return { ...state };
                }
                else {
                    let lastId = (from(state.coBorrower.employments).lastOrDefault()?.employmentId ?? 0) + 1;
                    let id = `Employment/${lastId}`;
                    newItem.id = id;
                    newItem.employmentId = lastId;
                    //newItem.currentEmploymentIndicator = false;
                    newItem.owner = "CoBorrower";
                    const copyItems = state.coBorrower.employments;
                    copyItems.splice(copyItems.length, 0, newItem);
                    let coBorrower = { ...state.coBorrower };
                    coBorrower.employments = copyItems;
                    return {
                        ...state,
                        coBorrower: {
                            ...coBorrower,
                            borrowerType: BorrowerType.CoBorrower
                        }
                    }
                }
            }
            else {
                return { ...state };
            }
        };
        case ActionTypes.DeleteEmploymentItem: {

            if (action.borrowerType == BorrowerType.PrimaryBorrower && state.borrower && state.borrower.employments) {
                const copyItems = [...state.borrower.employments];
                const index = copyItems.findIndex(i => i.id === action.id);
                copyItems.splice(index, 1);
                let borrower = state.borrower;
                borrower.employments = copyItems;
                return {
                    ...state, borrower: { ...borrower }
                }
            }
            else if (action.borrowerType == BorrowerType.CoBorrower && state.coBorrower && state.coBorrower.employments) {
                const copyItems = [...state.coBorrower.employments];
                const index = copyItems.findIndex(i => i.id === action.id);
                copyItems.splice(index, 1);
                let coBorrower = state.coBorrower;
                coBorrower.employments = copyItems;
                return {
                    ...state, coBorrower: { ...coBorrower }
                }
            }
            else { return { ...state }; }
        };
        case ActionTypes.SubmittedEmploymentInformation:
            if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                return {
                    ...state,
                    submittedBorrower: action.value
                };
            }
            else {
                return {
                    ...state,
                    submittedCoBorrower: action.value
                };
            }


        case ActionTypes.GetEmploymentInformation: {
            switch (action.borrowerType) {

                case "Borrower": {
                    return {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            employments: action.data?.employments,
                            borrowerType: BorrowerType.PrimaryBorrower,
                            activeMilitaryDuty: action.data.activeMilitaryDuty,
                            additionalEmploymentDoesNotApply: action.data.additionalEmploymentDoesNotApply,
                            previousEmploymentDoesNotApply: action.data.previousEmploymentDoesNotApply,
                            currentEmploymentDoesNotApply: action.data.currentEmploymentDoesNotApply,
                            loanGuid: action.data.loanGuid,
                            name: action.data.name,
                            previousGrossMonthlyIncome: action.data.previousGrossMonthlyIncome,
                            printOnAdditionalBorrowerPage: action.data.printOnAdditionalBorrowerPage,
                            selfDeclaredMilitaryServiceIndicator: action.data.selfDeclaredMilitaryServiceIndicator,
                            veteran: action.data.veteran,
                            interimLoanStagingId: action.data.interimLoanStagingId
                        }
                    }
                }
                case "CoBorrower": {
                    return {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            employments: action.data?.employments,
                            borrowerType: BorrowerType.CoBorrower,
                            activeMilitaryDuty: action.data.activeMilitaryDuty,
                            additionalEmploymentDoesNotApply: action.data.additionalEmploymentDoesNotApply,
                            previousEmploymentDoesNotApply: action.data.previousEmploymentDoesNotApply,
                            currentEmploymentDoesNotApply: action.data.currentEmploymentDoesNotApply,
                            loanGuid: action.data.loanGuid,
                            name: action.data.name,
                            previousGrossMonthlyIncome: action.data.previousGrossMonthlyIncome,
                            printOnAdditionalBorrowerPage: action.data.printOnAdditionalBorrowerPage,
                            selfDeclaredMilitaryServiceIndicator: action.data.selfDeclaredMilitaryServiceIndicator,
                            veteran: action.data.veteran,
                            interimLoanStagingId: action.data.interimLoanStagingId

                        }
                    }
                }
            }
            return { ...state }
        };

        case ActionTypes.ResetEmploymentItem: {
            if (action.borrowerType == BorrowerType.PrimaryBorrower) {
                let copyItem = state.borrower.employments;
                if (copyItem) {
                    const index = copyItem.findIndex(i => i.id === action.employmentItemId);
                    let currentEmployment = copyItem[index];
                    let defualtData = currentEmployment;
                    defualtData.employmentId = currentEmployment.employmentId;
                    defualtData.id = currentEmployment.id;
                    defualtData.selfEmployedIndicator = currentEmployment.selfEmployedIndicator;
                    copyItem[index] = defualtData;
                }
                return {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        employments: copyItem
                    }
                }
            }
            else if (action.borrowerType == BorrowerType.CoBorrower) {
                let copyItem = state.coBorrower.employments;
                if (copyItem) {
                    const index = copyItem.findIndex(i => i.id === action.employmentItemId);
                    let currentEmployment = copyItem[index];
                    let defualtData = DummyData;
                    defualtData.employmentId = currentEmployment.employmentId;
                    defualtData.id = currentEmployment.id;
                    defualtData.selfEmployedIndicator = currentEmployment.selfEmployedIndicator;
                    copyItem[index] = defualtData;
                }
                return {
                    ...state,
                    borrower: {
                        ...state.coBorrower,
                        employments: copyItem
                    }
                }
            }
            return { ...state };
        };

        case ActionTypes.ReIndexingEmployment: {

            let index = 0;
            let copyItems = action.data;

            const borrowerCurrentEmployer = copyItems.filter(x => x.currentEmploymentIndicator == true);
            borrowerCurrentEmployer.map((item, i) => {
                item.employmentId = index;
                item.id = `Employment/${index}`;
                index++;
            });
            const borrowerPriorEmployer = copyItems.filter(x => x.currentEmploymentIndicator !== true);
            borrowerPriorEmployer.map((item, i) => {
                item.employmentId = index;
                item.id = `Employment/${index}`;
                index++;
            });
            if (state.borrower.printOnAdditionalBorrowerPage == true) {
                const coborrowerCurrentEmployer = copyItems.filter(x => x.currentEmploymentIndicator == true);
                coborrowerCurrentEmployer.map((item, i) => {
                    item.employmentId = index;
                    item.id = `Employment/${index}`;
                    index++;
                }); const coborrowerPriorEmployer = copyItems.filter(x => x.currentEmploymentIndicator !== true);
                coborrowerPriorEmployer.map((item, i) => {
                    item.employmentId = index;
                    item.id = `Employment/${index}`;
                    index++;
                });
                return {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        employments: copyItems.slice()
                            .sort((a, b) => a.employmentId - b.employmentId)
                    }
                }
            }
            return {
                ...state,
                borrower: {
                    ...state.borrower,
                    employments: copyItems.slice()
                        .sort((a, b) => a.employmentId - b.employmentId)
                }
            }
        }

        default:
            return state;
    }
}