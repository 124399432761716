import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as GovernmentQuestionActions from "../../actions/governmentQuestionActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import * as GovernmentQuestionStore from "../../store/GovernmentQuestionStore";
import styles from "./GovernmentQuestions.module.scss";
import { BorrowerTypeEnum } from "components/common/Enums/BorrowerTypeEnum";
import * as governmentQuestionService from "../../services/governmentQuestionService";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type BorrowerGovernmentQuestionsSection5aProps = GovernmentQuestionStore.GovernmentQuestionState & {
    borrowerName: string,
    loanPurposeChoice: string,
    loanGuid: string,
    borrowerType: GovernmentQuestionStore.BorrowerType;
    setBorrowerValid: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
    interimLoanStagingId: number;
}
    & typeof GovernmentQuestionActions.governmentQuestionActionCreators
    & typeof TaskMenuCreators;

const BorrowerGovernmentQuestionSection5a = (props: BorrowerGovernmentQuestionsSection5aProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    let currentBorrower = props.borrowerType === GovernmentQuestionStore.BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower;

    React.useEffect(() => {
        if (props.borrowerType === GovernmentQuestionStore.BorrowerType.PrimaryBorrower) {
            props.setBorrowerValid(validateBorrower(props.borrower));
        } else if (props.coBorrower) {
            props.setBorrowerValid(validateBorrower(props.coBorrower));
        }
    }, [props.borrower, props.coBorrower]);

    React.useEffect(() => {
        if (props.borrowerType === GovernmentQuestionStore.BorrowerType.PrimaryBorrower) {
            getGovernmnetQuestions(BorrowerTypeEnum.Borrower);
        } else if (props.borrowerType === GovernmentQuestionStore.BorrowerType.CoBorrower) {
            getGovernmnetQuestions(BorrowerTypeEnum.CoBorrower);
        }
    }, [props.borrowerType]);

    const getGovernmnetQuestions = async (borrowerType: BorrowerTypeEnum) => {
        if (props.interimLoanStagingId !== undefined && props.interimLoanStagingId > 0) {
        let response = await governmentQuestionService.getGovernmentQuestion(borrowerType, props.interimLoanStagingId);
        if (response.ok) {
            if (response.parsedBody?.urla2020CitizenshipResidencyType != null) {
                let data = response.parsedBody as GovernmentQuestionStore.GovernmentQuestion;
                props.setGovernmentQuestions(data, data.borrowerType ?? GovernmentQuestionStore.BorrowerType.PrimaryBorrower);
            }
            else {
                currentBorrower.borrowerType = response.parsedBody?.borrowerType;
                props.setGovernmentQuestions(currentBorrower, response.parsedBody?.borrowerType ?? 0)
            }
            }
        }
    };

    const validateBorrower = (borrower: GovernmentQuestionStore.GovernmentQuestion): boolean => {
        let isValid = true;
        if (
            borrower.intentToOccupyIndicator === undefined ||
            borrower.homeownerPastThreeYearsIndicator === undefined ||
            borrower.priorPropertyUsageType === undefined ||
            borrower.priorPropertyTitleType === undefined ||
            borrower.urla2020CitizenshipResidencyType === undefined ||
            borrower.urla2020CitizenshipResidencyType === null ||
            borrower.undisclosedBorrowedFundsIndicator === undefined ||
            borrower.undisclosedMortgageApplicationIndicator === undefined ||
            borrower.undisclosedCreditApplicationIndicator === undefined ||
            borrower.propertyProposedCleanEnergyLienIndicator === undefined

        ) {
            isValid = false;
        }
        else if (borrower.homeownerPastThreeYearsIndicator
            && ((borrower.priorPropertyUsageType === undefined || borrower.priorPropertyUsageType === "" || borrower.priorPropertyUsageType === null)
                || (borrower.priorPropertyTitleType == undefined || borrower.priorPropertyTitleType === "" || borrower.priorPropertyTitleType === null))) {
            isValid = false;
        }
        else if (!borrower.homeownerPastThreeYearsIndicator
            && (
                (borrower.priorPropertyUsageType !== "" && borrower.priorPropertyUsageType !== null)
                && (borrower.priorPropertyTitleType !== "" && borrower.priorPropertyTitleType !== null)
            )
        ) {
            isValid = false;
        }
        else if (props.loanPurposeChoice === "Purchase" && borrower.specialBorrowerSellerRelationshipIndicator === undefined) {
            isValid = false;
        }
        return isValid;
    };
    const updateProperty = (value: boolean, fieldName: string) => {
        if (fieldName === "homeownerPastThreeYearsIndicator") {
            if (!value) {
                props.updateGovernmentQuestions("", "priorPropertyUsageType", props.borrowerType);
                props.updateGovernmentQuestions("", "priorPropertyTitleType", props.borrowerType);
            }
        }
        props.updateGovernmentAdditionalQuestions(value, fieldName, props.borrowerType);
    };
    const setCheckbox = (e: any, value: string) => {
        if (value === "NonPermanentResidentAlien") {
            props.updateGovernmentQuestions(value, "urla2020CitizenshipResidencyType", props.borrowerType);
        }
        else {
            props.updateGovernmentQuestions(value, "urla2020CitizenshipResidencyType", props.borrowerType)
        }
    };
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <>
            <h1 className={styles.textCenter} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Government Questions')}>
                Government Questions
            </h1>
            <h2 className={styles.textCenter} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please answer for' + props.borrowerName)}>Please answer for {props.borrowerName}
            </h2>
            <div className={styles.borrowerQueForm} >
                <div className={styles.borrowerQuecontainer}>
                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Will you be occupying the property as your primary residence?')}>
                            Will you be occupying the property as your primary residence?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.intentToOccupyIndicator ? "active" : ""}`} onClick={() => updateProperty(true, "intentToOccupyIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.intentToOccupyIndicator &&
                                currentBorrower?.intentToOccupyIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "intentToOccupyIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Have you had an ownership interest in another property in the last three years?')}>
                            Have you had an ownership interest in another property in the last three years?
                    </label>

                        <div className={styles.answerButton}>

                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.homeownerPastThreeYearsIndicator ? `${"active"} ${styles.greenBoxBtn}` : ""}`} onClick={() => updateProperty(true, "homeownerPastThreeYearsIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                         </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.homeownerPastThreeYearsIndicator && currentBorrower?.homeownerPastThreeYearsIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "homeownerPastThreeYearsIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                        </button>

                        </div>

                    </div>

                    {!currentBorrower?.homeownerPastThreeYearsIndicator ? <></> :
                        <div className={styles.borrowerGovtActiveContainer}>
                            <div className={styles.inputGroup}>
                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What type of property did you own?')}>
                                    What type of property did you own?
                                </label>
                                <div className={styles.answerButton}>
                                    <div className={styles.select}>
                                    <select disabled={props.disabled} className="priorPropertyUsageType" name="priorPropertyUsageType" value={currentBorrower?.priorPropertyUsageType ?? ""} onChange={(e) => props.updateGovernmentQuestions(e.target.value, e.target.name, props.borrowerType)}>
                                        <option value="" className={styles.selectOption}>Select...</option>
                                        <option value="PrimaryResidence" className={styles.selectOption}>Primary Residence</option>
                                        <option value="FHASecondaryResidence" className={styles.selectOption}>FHA Secondary Residence</option>
                                        <option value="SecondaryResidence" className={styles.selectOption}>Second Home</option>
                                        <option value="Investment" className={styles.selectOption}>Investment Property</option>
                                        </select>
                                      </div>
                                </div>
                            </div>

                            <div className={styles.inputGroup}>
                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('How did you hold title to the property?')}>
                                    How did you hold title to the property?
                                </label>
                                <div className={styles.answerButton}>
                                    <div className={styles.select}>
                                    <select disabled={props.disabled} className="priorPropertyTitleType" name="priorPropertyTitleType" value={currentBorrower?.priorPropertyTitleType ?? ""} onChange={(e) => props.updateGovernmentQuestions(e.target.value, e.target.name, props.borrowerType)}>
                                        <option value="" className={styles.selectOption}>Select...</option>
                                        <option value="Sole" className={styles.selectOption}>By Myself</option>
                                        <option value="JointWithSpouse" className={styles.selectOption}>Joint With Spouse</option>
                                        <option value="JointWithOtherThanSpouse" className={styles.selectOption}>Joint With Other Than Spouse</option>
                                        </select>
                                        </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(' Are you a US Citizen?')}>
                            Are you a US Citizen?
                    </label>

                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.urla2020CitizenshipResidencyType === "USCitizen" ? "active" : ""}`}
                                onClick={() => props.updateGovernmentQuestions("USCitizen", "urla2020CitizenshipResidencyType", props.borrowerType)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.urla2020CitizenshipResidencyType !== "USCitizen" &&
                                currentBorrower?.urla2020CitizenshipResidencyType !== undefined ? `${"active"} ${styles.greenBoxBtn}` : ""}`}
                                onClick={() => props.updateGovernmentQuestions("", "urla2020CitizenshipResidencyType", props.borrowerType)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    {currentBorrower?.urla2020CitizenshipResidencyType === undefined || currentBorrower?.urla2020CitizenshipResidencyType === "USCitizen" ? <></>
                        : <div className={styles.borrowerGovtActiveContainer}>
                            <div className={styles.inputGroup}>
                                <div className={styles.gvtQuecheck}>
                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Which of the following applies to you?')}>
                                        Which of the following applies to you?
                                </label>
                                    <div className={styles.answerButton}>
                                        <label>
                                            <div className={styles.customCheckBox}>
                                                <input disabled={props.disabled} name="permanentResidentAlien" checked={currentBorrower.isPermanentResidentAlienChecked} type="checkbox" onChange={(e) => setCheckbox(e.target.name, "PermanentResidentAlien")} />
                                            </div>
                                            <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Permanent Resident Alien')}> Permanent Resident Alien </span>
                                        </label>

                                        <label>
                                            <div className={styles.customCheckBox}>
                                                <input disabled={props.disabled} name="nonPermanentResidentAlien"
                                                    type="checkbox" checked={currentBorrower.isNonPermanentResidentAlienChecked}
                                                    onChange={(e) => setCheckbox(e.target.name, "NonPermanentResidentAlien")} />
                                            </div>
                                            <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Non-Permanent Alien')}>  Non-Permanent Alien </span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }

                    {(currentBorrower?.loanPurpose === "Purchase" || props.loanPurposeChoice === "Purchase") ?
                        <div className={styles.inputGroup}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Do you have a family relationship or business affiliation with the seller of the Property?')}>
                                Do you have a family relationship or business affiliation with the
                                seller of the Property?
                    </label>
                            <div className={styles.answerButton}>

                                <button disabled={props.disabled} className={`common-button ${currentBorrower?.specialBorrowerSellerRelationshipIndicator ? "active" : ""}`}
                                    onClick={() => updateProperty(true, "specialBorrowerSellerRelationshipIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                    Yes
                     </button>
                                <button disabled={props.disabled} className={`common-button ${!currentBorrower?.specialBorrowerSellerRelationshipIndicator &&
                                    currentBorrower?.specialBorrowerSellerRelationshipIndicator !== undefined ? "active" : ""}`}
                                    onClick={() => updateProperty(false, "specialBorrowerSellerRelationshipIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                    No
                    </button>
                            </div>
                        </div>
                        : <></>}


                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Are you borrowing any money for this real estate transaction (e.g.Money for your closing costs or down payment or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?')}>
                            Are you borrowing any money for this real estate transaction
                            (e.g. Money for your closing costs or down payment or
                            obtaining any money from another party, such as the seller or
                            realtor, that you have not disclosed on this loan application?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.undisclosedBorrowedFundsIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "undisclosedBorrowedFundsIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                         </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.undisclosedBorrowedFundsIndicator &&
                                currentBorrower?.undisclosedBorrowedFundsIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "undisclosedBorrowedFundsIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                        </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Have you or will you be applying for a mortgage loan on another property(not the property securing this loan) on or before closing this transaction that is not disclosed on this transaction ?')}>
                            Have you or will you be applying for a mortgage loan on
                            another property (not the property securing this loan) on or
                            before closing this transaction that is not disclosed on this
                            transaction?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.undisclosedMortgageApplicationIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "undisclosedMortgageApplicationIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.undisclosedMortgageApplicationIndicator &&
                                currentBorrower?.undisclosedMortgageApplicationIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "undisclosedMortgageApplicationIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Have you or will you be applying for any new credit (e.g.,Installment loan, Credit Card, etc.) on or before closing this loan that is not disclosed on this application?')}>
                            Have you or will you be applying for any new credit (e.g.,
                            Installment loan, Credit Card, etc.) on or before closing this
                            loan that is not disclosed on this application?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.undisclosedCreditApplicationIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "undisclosedCreditApplicationIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.undisclosedCreditApplicationIndicator &&
                                currentBorrower?.undisclosedCreditApplicationIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "undisclosedCreditApplicationIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                    <div className={styles.inputGroup}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes(e.g., The Property Assessed Clean Energy Program) ?')}>
                            Will this property be subject to a lien that could take priority
                            over the first mortgage lien, such as a clean energy lien paid
                            through your property taxes (e.g., The Property Assessed Clean
                            Energy Program)?
                    </label>
                        <div className={styles.answerButton}>
                            <button disabled={props.disabled} className={`common-button ${currentBorrower?.propertyProposedCleanEnergyLienIndicator ? "active" : ""}`}
                                onClick={() => updateProperty(true, "propertyProposedCleanEnergyLienIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>
                                Yes
                     </button>
                            <button disabled={props.disabled} className={`common-button ${!currentBorrower?.propertyProposedCleanEnergyLienIndicator &&
                                currentBorrower?.propertyProposedCleanEnergyLienIndicator !== undefined ? "active" : ""}`}
                                onClick={() => updateProperty(false, "propertyProposedCleanEnergyLienIndicator")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>
                                No
                    </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state: ApplicationState, ownProps: { borrowerType: GovernmentQuestionStore.BorrowerType, setBorrowerValid: React.Dispatch<React.SetStateAction<boolean>>, isLoanCompleted: boolean }): any => {
    return state.accountInformation && state.accountCreation && state.taskMenu && state.propertyInformation ?
        {
            ...state.governmentQuestions,
            borrowerName:
                ownProps.borrowerType == GovernmentQuestionStore.BorrowerType.PrimaryBorrower ?
                    state.accountCreation?.borrower?.firstName + " " + state.accountCreation?.borrower?.lastName :
                    state.accountCreation?.coBorrower?.firstName + " " + state.accountCreation?.coBorrower?.lastName,
            loanPurposeChoice: state.taskMenu?.loanSummary?.loanPurpose != undefined ? state.taskMenu?.loanSummary?.loanPurpose
                : state.propertyInformation.purposeType,
            borrowerType: ownProps.borrowerType,
            setBorrowerValid: ownProps.setBorrowerValid,
            loanGuid: state.loan?.loanGuid,
            disabled: ownProps.isLoanCompleted,
            interimLoanStagingId: state.loan?.interimLoanStagingId
        } :
        state.governmentQuestions;
}

const mapDispatchToProps = (dispatch: any) => ({
    updateGovernmentQuestions: (value: string, propertyName: string, borrowerType: GovernmentQuestionStore.BorrowerType) => {
        dispatch(GovernmentQuestionActions.governmentQuestionActionCreators.updateGovernmentQuestions(value, propertyName, borrowerType));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    updateGovernmentAdditionalQuestions: (value: boolean, propertyName: string, borrowerType: GovernmentQuestionStore.BorrowerType) => {
        dispatch(GovernmentQuestionActions.governmentQuestionActionCreators.updateGovernmentAdditionalQuestions(value, propertyName, borrowerType));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    getGovernmentQuestions: (borrowerType: string, interimLoanStagingId:number) => {
        dispatch(GovernmentQuestionActions.governmentQuestionActionCreators.getGovernmentQuestions(borrowerType, interimLoanStagingId));
    },
    setGovernmentQuestions: (data: GovernmentQuestionStore.GovernmentQuestion, borrowerType: GovernmentQuestionStore.BorrowerType) => {
        dispatch(GovernmentQuestionActions.governmentQuestionActionCreators.setGovernmentQuestions(data, borrowerType));
    }

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BorrowerGovernmentQuestionSection5a as any);