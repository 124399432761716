import React from "react";
import ChoiceQuestion from "../common/ChoiceQuestion";
import { HowManyUnitsChoices } from "interfaces/accountInformationInterfaces";
import multiUnitGraphic from "assets/infographics/accountInformation/multi-unit-count.svg";
import styles from "../common/ChoiceQuestion.module.scss";
import accountInfoStyles from "./AccountInformation.module.scss";

const HowManyUnits = (props: {
    updateHowManyUnitsChoice: (value: HowManyUnitsChoices) => any, howManyUnits: any, loanPurpose: any
}) => (
        <div className={styles.howmanyUnits}>
            <ChoiceQuestion
                disabled={false}
                intro="A Multi-Unit! Before We Continue..."
                question="How Many Units?"

                choices={[
                    {
                        text: HowManyUnitsChoices.Two.toString(),
                        onClick: () => props.updateHowManyUnitsChoice(HowManyUnitsChoices.Two),
                        className: props.howManyUnits === HowManyUnitsChoices.Two ? accountInfoStyles.selected : ""
                    },
                    {
                        text: HowManyUnitsChoices.Three.toString(),
                        onClick: () => props.updateHowManyUnitsChoice(HowManyUnitsChoices.Three),
                        className: props.howManyUnits === HowManyUnitsChoices.Three ? accountInfoStyles.selected : ""
                    },
                    {
                        text: HowManyUnitsChoices.Four.toString(),
                        onClick: () => props.updateHowManyUnitsChoice(HowManyUnitsChoices.Four),
                        className: props.howManyUnits === HowManyUnitsChoices.Four ? accountInfoStyles.selected : ""
                    },
                ]}

                infographic={multiUnitGraphic}
            />
        </div>
    );

export default HowManyUnits;
