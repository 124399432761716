import React from "react";
import DocumentSection from "./DocumentSection";
import { DocumentSectionDto } from "../models/DocumentSectionDto";
import styles from "./BorrowerDocument.module.scss";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface DocumentGroupProps {
    sections: DocumentSectionDto[];
    category: string | null | undefined;
    getData: () => void;
}
const DocumentGroup = (props: DocumentGroupProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
            <div className={styles.BoxContainer}>
            <h5 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.category)}>{props.category}</h5>
                {props.sections?.map((value) => {
                    return (
                        <DocumentSection
                            key={value.documentSectionId}
                            section={value}
                            getData={props.getData}
                        ></DocumentSection>
                    );
                })}
            </div>
    );
};

export default DocumentGroup;
