import * as React from "react";
import Infographic from "../Infographic";

interface InfographicTopLayoutProps {
  children?: React.ReactNode;
  infographic?: string;
  className?: string;
}

const InfographicTopLayout = (props: InfographicTopLayoutProps) => {
  return (
    <div>
      {props.infographic && (
        <div className="infographic-top-panel propertyInformationTop">
          <Infographic src={props.infographic} />
        </div>
      )}
      <div className={props.className}>{props.children}</div>
    </div>
  );
};

export default InfographicTopLayout;
