import React from "react";
import styles from "./assetInformation.module.scss";

function FinicityMessage() {
  return (
    <div className={styles.finicity}>
      Powered by <span> Finicity </span>
    </div>
  );
}

export default FinicityMessage;
