import React from "react";
import styles from "./infoIconTooltip.module.scss";

const Tooltip = (props: { children?: React.ReactNode; iconClass?: string }) => (
  <span
    className={styles.tooltip}
    data-has-tooltip
    aria-describedby="tooltip"
  >
    <i className={props.iconClass ? props.iconClass : "fa fa-info-circle"}></i>
    <span data-tooltip id="tooltip" className={styles.tooltipText}>
      {props.children}
    </span>
  </span>
);
export default Tooltip;