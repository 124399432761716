import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { SystematicLetterState, KnownAction } from "../store/systematicLetterStore";

const unloadedState: SystematicLetterState = {
    data: {},
    isLoading: false,
    errorMessage: ""
}

export const systematicLetterReducer: Reducer<SystematicLetterState> = (state: SystematicLetterState | undefined, incomingAction: Action): SystematicLetterState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.RequestSystematicLetter:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ReceiveSystematicLetter:
            {
                let result;
                let data = action.data;
                    result = {

                        ...state,
                        data: data,
                        isLoading: false
                    }
                    return result;
            }
        default:
            return state;
    }
}