import * as React from "react";
import * as EmploymentInformationStore from "../../store/employmentInformationStore";
import { EmploymentScreensNew, EmploymentInformationFields, ScreenDetails } from "./EmploymentInformationFields";
import styles from "./employmentInformation.module.scss";
import { BorrowerType } from "../../store/employmentInformationStore";
import { useNavigation } from "hooks/useNavigation";
import { NumberFormatInput } from "components/common/Input/NumberFormatInput";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { bindActionCreators } from "redux";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { useDispatch } from "react-redux";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type IncomeDetailProps = {
    info: ScreenDetails,
    employmentData: EmploymentInformationStore.Employment,
    changeScreen: React.Dispatch<React.SetStateAction<EmploymentScreensNew>>,
    UpdateEmploymentInformationProperty: (value: any, fieldName: string, borrowerType?: BorrowerType, itemId?: string) => {},
    UpdateBackClick: any,
    disabled: boolean,
    empList: EmploymentInformationStore.Employment[]
}

const CurrentIncomeDetail = (props: IncomeDetailProps) => {
    const [total, updateTotal] = React.useState(props.employmentData?.totalAmount);
    const navigation = useNavigation();
    const actions = { ...bindActionCreators({ ...TaskMenuCreators }, useDispatch()) };
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    const isDisabled = (): boolean => {
        let isValid = true;
        if (props.empList.length > 0) {
            isValid = props.empList.filter(x => isNullOrWhiteSpace(x.basePayAmount) || x.basePayAmount == "0").length > 0;
        }
        return isValid;
    }

    const moveFrwdBackwrd = (back: boolean) => {
        if (back) {
            props.UpdateBackClick(true);
            if (props.info.prevScreen)
                props.changeScreen(props.info.prevScreen)
        }
        else {
            props.UpdateBackClick(false);
            if (props.info.nextScreen)
                props.changeScreen(props.info.nextScreen)
        }
    }

    const updateProperty = (value: any, fieldname: string) => {
        actions.setLoanIsDirty(true);
        props.UpdateEmploymentInformationProperty(value, fieldname, props.info.borrowerType, props.employmentData?.id);
    }

    React.useEffect(() => {
        updateTotal(parseFloat(props.employmentData?.basePayAmount ? props.employmentData?.basePayAmount : "0") +
            parseFloat(props.employmentData?.bonusAmount ? props.employmentData?.bonusAmount : "0") +
            parseFloat(props.employmentData?.overtimeAmount ? props.employmentData?.overtimeAmount : "0") +
            parseFloat(props.employmentData?.commissionsAmount ? props.employmentData?.commissionsAmount : "0") +
            parseFloat(props.employmentData?.otherAmount ? props.employmentData?.otherAmount : "0"));

        props.UpdateEmploymentInformationProperty(total, EmploymentInformationFields.TotalAmount, props.info.borrowerType,
            props.employmentData?.id);

    }, [props.employmentData?.basePayAmount, props.employmentData?.bonusAmount,
    props.employmentData?.overtimeAmount, props.employmentData?.commissionsAmount,
    props.employmentData?.otherAmount])

    const onBack = () => {
        moveFrwdBackwrd(true);
    };

    const onContinue = () => {
        moveFrwdBackwrd(false);
    };

    return (
        <div >
            <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentData.employerName)}>{props.employmentData.employerName}</h4>
            <div className={styles.content}>
                <div className={styles.incomeDetailRow}>

                    <div className={`input-group ${styles.inputGroup}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Base Income per Month')}> Base Income per Month <span className={styles.requiredMsg}> * </span></label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            value={props.employmentData?.basePayAmount || ''}
                            id="#basePayAmount"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                updateProperty(values.value, EmploymentInformationFields.BasePayAmount)
                            }}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        />
                    </div>

                    <div className={`input-group ${styles.inputGroup}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Overtime Pay per Month Optional')}>Overtime Pay per Month <span className={styles.optionalMsg}>(Optional)</span></label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            value={props.employmentData?.overtimeAmount || ''}
                            id="#overtimeAmount"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                updateProperty(values.value, EmploymentInformationFields.OvertimeAmount)
                            }}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        />
                    </div>

                    <div className={`input-group ${styles.inputGroup}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Bonus per Month Optional')}>Bonus per Month <span className={styles.optionalMsg}>(Optional)</span></label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            value={props.employmentData?.bonusAmount || ''}
                            id="#bonusAmount"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                updateProperty(values.value, EmploymentInformationFields.BonusAmount)
                            }}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        />
                    </div>

                    <div className={`input-group ${styles.inputGroup}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Commission per Month Optional')}>Commission per Month <span className={styles.optionalMsg}>(Optional)</span></label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            value={props.employmentData?.commissionsAmount || ''}
                            id="#commissionsAmount"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                updateProperty(values.value, EmploymentInformationFields.CommissionsAmount)
                            }}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        />
                    </div>

                    <div className={`input-group ${styles.inputGroup}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Other Income per Month Optional')}>Other Income per Month <span className={styles.optionalMsg}>(Optional)</span></label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            value={props.employmentData?.otherAmount || ''}
                            id="#otherAmount"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                updateProperty(values.value, EmploymentInformationFields.OtherAmount)
                            }}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        />
                    </div>
                    <div className={`input-group ${styles.inputGroup}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Total')}>Total</label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            value={total ? total.toFixed(2) : 0}
                            id="#total"
                            name={EmploymentInformationFields.TotalAmount}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        />
                    </div>
                </div>
            </div>

            {navigation.SetBackOnClick(
                () => onBack()
            )}
            {navigation.ShowContinue(
                () => onContinue(),
                isDisabled()
            )}
        </div>
    );
}

export default React.memo(CurrentIncomeDetail) as any;