import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction } from "../store/creditReportStore";
import {
  getCreditReportValues,
  getCreditReportStatus,
  startCreditReport,
} from "../services/creditReportService";
import { ApplicationState } from "../store";
import { ReportStatus } from "../store/Enum";

export interface CreditReportActions {
  StartCreditReport: () => void;
  QueryCreditReportStatus: () => void;
  QueryCreditValues: () => void;
}

export function useCreditReportActions(): CreditReportActions {
  const dispatch = useDispatch();
  const loanGuid = useSelector(
    (state: ApplicationState) => state.accountInformation?.loanGuid ?? ""
  );
  const reportId = useSelector(
    (state: ApplicationState) => state.creditReport?.reportId ?? 0
  );

  const queryForCreditScores = async (loanGuid: string) => {
    const result = await getCreditReportValues(loanGuid);
    if (result.ok && result.parsedBody && !result.parsedBody.hasError) {
      const scoresResult = result.parsedBody.body;
      if (result.parsedBody.body.borrowerCreditScore) {
        dispatch({
          type: ActionTypes.CompleteCreditReport,
          status: ReportStatus.Complete,
          borrowerScore: scoresResult.borrowerCreditScore as any,
          coBorrowerScore: scoresResult.coBorrowerCreditScore as any,
        } as KnownAction);
        return true;
      }
    } else {
      console.error(result);
    }
    return false;
  };

  var actions: CreditReportActions = {
    StartCreditReport: async () => {
      if (await queryForCreditScores(loanGuid)) {
        // Credit score is already complete. No need to start a new one
        return;
      }

      const result = await startCreditReport(loanGuid);

      if (result.ok && result.parsedBody && !result.parsedBody.hasError) {
        dispatch({
          type: ActionTypes.StartCreditReport,
          reportId: result.parsedBody.body.creditReportId,
        } as KnownAction);
      } else {
        dispatch({
          type: ActionTypes.SetCreditReportStatus,
          status: ReportStatus.Error,
        } as KnownAction);
      }
    },

    QueryCreditReportStatus: async () => {
      const result = await getCreditReportStatus(loanGuid, reportId);

      if (result.ok && result.parsedBody && !result.parsedBody.hasError) {
        dispatch({
          type: ActionTypes.SetCreditReportStatus,
          status: result.parsedBody.body.status,
        } as KnownAction);
      } else {
        console.error(result);
      }
    },

    QueryCreditValues: async () => {
      await queryForCreditScores(loanGuid);
    },
  };
  return actions;
}
