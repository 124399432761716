import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getDocumentContents } from "services/documentService";
import PdfDocumentViewer from "./PdfDocumentViewer";
import WordDocumentViewer from "./WordDocumentViewer";
import ImageViewer from "./ImageViewer";
import DocumentViewerActions from "./DocumentViewerActions";
import { DocumentDto } from "../models/DocumentDto";
import styles from "./documentViewers.module.scss";
import { FileFormat } from "../models/FileFormat";
import NoImage from "assets/icons/document/NoImage.png";

const DocumentViewer = () => {
    const [documentDto, setDocumentDto] = useState<DocumentDto | null>(null);
    const [documentList, setDocumentDtoList] = useState(null);
    const [loanGuid, setLoanGuid] = useState("");
    const [viewerActions, setViewerActions] = useState<boolean>();
    const [data, setData] = useState("");
    const [showError, setShowError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoanGuid(history.location.state?.loanGuid);
        setDocumentDto(history.location.state?.documentDto);
        setViewerActions(history.location.state?.viewerActions);
        setDocumentDtoList(history.location.state?.documentList);

    }, [history.location]);

    useEffect(() => {
        if (loanGuid && (documentDto?.documentId ?? -1) > 0) {
            getData();
        }
    }, [documentDto, loanGuid]);

    const getData = async () => {
        setData("");
        var result = await getDocumentContents(loanGuid ?? "", documentDto?.documentId ?? -1);
        if (result.ok && !result.parsedBody?.hasError) {
            setData(result.parsedBody?.body.data ?? "");
        } else {
            setShowError(true);
        }
    };
   

    var loadingSection = <></>;
    if (showError) {
        loadingSection = (
            <h5 className="align-center">An error occurred while loading the document.</h5>
        );
    } else {
        loadingSection = <span className="loader medium"></span>;
    }

    return (
        <>
            {data && (
                <div className={`${styles.documentViewerPage} ${!viewerActions ? styles.noDocAction : ""}`}>
                    <h2 className="align-center">{documentDto?.fileName}</h2>
                    <div>
                        {viewerActions && <DocumentViewerActions
                            loanGuid={loanGuid}
                            documentId={documentDto?.documentId ?? -1}
                            onStatusUpdate={() => history.goBack()}
                            documentList={documentList }
                        ></DocumentViewerActions>
                        }
                    </div>
                    {(() => {
                        switch (documentDto?.fileFormat) {
                            case FileFormat.Jpeg:
                            case FileFormat.Png:
                                return <ImageViewer data={data}></ImageViewer>;
                            case FileFormat.Pdf:
                                return <PdfDocumentViewer loanGuid={loanGuid} documentId={documentDto?.documentId ?? -1}></PdfDocumentViewer>;
                            case FileFormat.Word:
                                return (
                                    <div className={styles.docFileViewer}>
                                        <img src={NoImage} alt="No Image" />
                                        <h4 className={styles.previewText}>It looks like this file doesn't have a preview we can show you.</h4>
                                        <WordDocumentViewer
                                            data={data}
                                            fileName={documentDto.fileName ?? "Error.docx"}
                                        ></WordDocumentViewer>
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })()}
                </div>
            )}
            {!data && loadingSection}
        </>
    );
};
export default DocumentViewer;
