import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction } from '../store/loanStore';

export const loanActionCreators = {
    setLoanGuid: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetLoanGuid, loanGuid: value });
    },
    setIsLoanCompleted: (value?: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetIsLoanCompleted, isLoanCompleted: value });
    },
    setIsLoanFromEncompassFlag: (value?: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetIsLoanFromEncompassFlag, isLoanFromEncompassFlag: value });
    },
    setInterimLoanStagingId: (value?: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetInterimLoanStagingId, interimLoanStagingId: value });
    }
}