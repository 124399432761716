import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { GovernmentQuestionState, BorrowerType, KnownAction } from "../store/GovernmentQuestionStore";

const unloadedState: GovernmentQuestionState = {
    borrower: {
        loanGuid: '',
        intentToOccupyIndicator: undefined,
        homeownerPastThreeYearsIndicator: undefined,
        priorPropertyUsageType: undefined,
        priorPropertyTitleType: undefined,
        urla2020CitizenshipResidencyType: undefined,
        specialBorrowerSellerRelationshipIndicator: undefined,
        undisclosedBorrowedFundsIndicator: undefined,
        undisclosedMortgageApplicationIndicator: undefined,
        undisclosedCreditApplicationIndicator: undefined,
        propertyProposedCleanEnergyLienIndicator: undefined,
        undisclosedComakerOfNoteIndicator: undefined,
        outstandingJudgementsIndicator: undefined,
        presentlyDelinquentIndicatorURLA: undefined,
        partyToLawsuitIndicatorURLA: undefined,
        priorPropertyDeedInLieuConveyedIndicator: undefined,
        priorPropertyShortSaleCompletedIndicator: undefined,
        priorPropertyForeclosureCompletedIndicator: undefined,
        bankruptcyIndicator: undefined,
        isPermanentResidentAlienChecked: undefined,
        isNonPermanentResidentAlienChecked: undefined,
        loanPurpose: '',
        interimLoanStagingId: undefined
    },
    borrowerComplete: false,
    coBorrower: {
        loanGuid: '',
        loanPurpose:''
    },
    coBorrowerComplete: false,
    submitBorrower: false,
    submitCoBorrower: false,
    isLoading: false,
    errorMessage: ''
}

export const governmentQuestionReducer: Reducer<GovernmentQuestionState> = (state: GovernmentQuestionState | undefined, incomingAction: Action): GovernmentQuestionState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UpdateGovernmentQuestions: {
            let result;
            if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                if (action.propertyName === "urla2020CitizenshipResidencyType" && action.value === "NonPermanentResidentAlien") {
                    state.borrower.isPermanentResidentAlienChecked = false;
                    state.borrower.isNonPermanentResidentAlienChecked = true;
                }
                else if (action.propertyName === "urla2020CitizenshipResidencyType" && action.value === "PermanentResidentAlien") {
                    state.borrower.isPermanentResidentAlienChecked = true;
                    state.borrower.isNonPermanentResidentAlienChecked = false;
                }

                result = {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        [action.propertyName]: action.value
                    }
                }
            }
            else {
                if (action.propertyName === "urla2020CitizenshipResidencyType" && action.value === "NonPermanentResidentAlien") {
                    state.coBorrower.isPermanentResidentAlienChecked = false;
                    state.coBorrower.isNonPermanentResidentAlienChecked = true;
                }
                else if (action.propertyName === "urla2020CitizenshipResidencyType" && action.value === "PermanentResidentAlien") {
                    state.coBorrower.isPermanentResidentAlienChecked = true;
                    state.coBorrower.isNonPermanentResidentAlienChecked = false;
                }
                result = {

                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        [action.propertyName]: action.value
                    }
                }
            }
            return result;
        }
        case ActionTypes.UpdateGovernmentAdditionalQuestions:
            return action.borrowerType === BorrowerType.PrimaryBorrower ?
                {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        [action.propertyName]: action.value,

                    }
                } :
                {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        [action.propertyName]: action.value
                    }
                };
        case ActionTypes.SubmitGovernmentQuestions:
            if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                return {
                    ...state,
                    submitBorrower: action.value
                }
            }
            else {
                return {
                    ...state,
                    submitCoBorrower: action.value
                }
            }
        case ActionTypes.UpdateBorrowerCompleted:
            if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                return {
                    ...state,
                    borrowerComplete: action.value
                }
            }
            else {
                return {
                    ...state,
                    coBorrowerComplete: action.value
                }
            }
        case ActionTypes.RequestGovernmentQuestions:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ReceiveGovernmentQuestions:
            {
                let result;
                let data = action.data;
                if (data.urla2020CitizenshipResidencyType === "NonPermanentResidentAlien") {
                    data.isPermanentResidentAlienChecked = false;
                    data.isNonPermanentResidentAlienChecked = true;
                }
                else if (data.urla2020CitizenshipResidencyType === "PermanentResidentAlien") {
                    data.isPermanentResidentAlienChecked = true;
                    data.isNonPermanentResidentAlienChecked = false;
                }
                if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                    result = {

                        ...state,
                        borrower: data,
                        isLoading: false
                    }
                    return result;
                }
                else if (action.borrowerType === BorrowerType.CoBorrower) {
                    result = {

                        ...state,
                        coBorrower: data,
                        isLoading: false
                    }
                    return result;
                }
            };

        default:
            return state;
    }
}