export enum EnumMilestoneNavigation {
    CompletingTheApplication = "Completing The Application",
    ProcessingTheApplication = "Processing The Application",
    CreditAnalysis = "Credit Analysis",
    ReadyForClosing = "Ready For Closing",
    Closed = "Closed"
}

export let MilestoneDetails = {
    completingTheApplication: {
        value: 'Completing The Application',
        videos: ["Loan Estimate", "Documentation", "Appraisal"],
        keyDate: "Estimated Closing Date",
        description: "At this stage in the loan application process, there are several documents you will need to provide when applying for a home loan. Select the Requested Documentation tab to view which documents you’ll need to submit to Union Home Mortgage (UHM). Please be advised additional documentation may be requested as we proceed through the application process. Lastly, an appraisal will be scheduled by your Loan Officer to confirm the purchase price aligns with the home’s value."
    },
    processingTheApplication: {
        value: 'Processing The Application',
        videos: ["Processing"],
        keyDate: "Estimated Closing Date",
        description: "At this stage in the loan application process, UHM has received your financial documentation and reviewing it now. We will verify your assets, income and employment status and credit, as well as evaluate your application disclosures."
    },
    creditAnalysis: {
        value: 'Credit Analysis',
        videos: ["Underwriting", "Conditional Approval"],
        keyDate: "Estimated Closing Date",
        description: "At this stage in the loan application process, your loan is being reviewed by UHM’s Underwriting team. We will thoroughly review your assets, income and employment status, credit, and appraisal documents to ensure they meet government regulations and standards.\n Based on the documentation you’ve submitted, an underwriter will determine your debt-to-income (DTI) ratio and set a credit limit on the loan. If the loan is denied, your Loan Officer will contact you to discuss next steps. Finally, any incomplete documents required for loan approval will appear under the Outstanding Documentation tab."
    },
    readyForClosing: {
        value: 'Ready For Closing',
        videos: ["Clear to close"],
        keyDate: "Actual Closing Date",
        description: "You’re at the final stage in the loan application process. Currently, your loan is being reviewed by UHM’s Closing team. We will work with a title/escrow company or attorney to finalize closing costs. Your Loan Officer will contact you with the final cost of your closing."
    },
    closed: {
        value: 'Closed',
        videos: ["Congrats"],
        keyDate: "",
        description: "Congratulations! At this stage in the loan application process, your closing documents have been sent to your title/escrow company or attorney. All parties involved will sign the closing package. "
    }
}
