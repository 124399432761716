import { get, post } from "../common/dataFetch";
import { DemographicQuestions } from "../store/demographicQuestionsStore";

export const getDemographicQuestions = (borrowerType: string, interimLoanStagingId:number) => {
    return get<any>(`DemographicQuestions/ReadByInterimIdAsync?borrowerType=${borrowerType}&interimLoanStagingId=${interimLoanStagingId}`);
};

export const updateDemographicQuestions = (demographicQuestions: DemographicQuestions) => {
    return post<any>(`DemographicQuestions/UpsertAsync`, demographicQuestions);
};

