import { get, post } from "../common/dataFetch";
import { Borrower } from "../store/accountCreationStore";
import { BorrowerTypeEnum } from "../common/BorrowerTypeEnum";

export const getStates = () => {
    return get<any>("BorrowerInformation/getStates");
};

export const create = (borrowerDto: Borrower, userType: any) => {
    const userTypeEnum = userType === BorrowerTypeEnum.CoBorrower ? 2 : 1;
    return post<any>(`BorrowerInformation/UpsertAsync?userType=${userTypeEnum}`, borrowerDto);
};

export const getBorrowerInformation = (borrowerType: string, interimLoanStagingId:number) => {
    return get<any>(`BorrowerInformation/ReadByInterimIdAsync?borrowerType=${borrowerType}&&interimLoanStagingId=${interimLoanStagingId}`);
};

export const checkIsEConsentResetRequired = (loanGuid: string, userType: any) => {
    const userTypeEnum = userType === BorrowerTypeEnum.CoBorrower ? 2 : 1;
    return post<any>(`BorrowerInformation/CheckIsEConsentResetRequiredAsync?loanGuid=${loanGuid}&&userType=${userTypeEnum}`, null);
};

export const createBorrowerLoanDetailAsync = (borrowerDto: Borrower) => {
    return post<any>(`BorrowerInformation/CreateBorrowerLoanDetailAsync`, borrowerDto);
};

export const updateBorrowerInformationAsync = (borrowerDto: Borrower) => {
    return post<any>(`BorrowerInformation/UpdateBorrowerInformationAsync`, borrowerDto);
};
