import * as React from "react";
import { connect } from "react-redux";
import * as PropertyInformationStore from "../../store/propertyInformationStore";
import * as PropertyInformationActions from "../../actions/propertyInformationActions";
import { ApplicationState } from "../../store";
import TextInput from "../common/Input/TextInput";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import styles from "./reviewApplication.module.scss";
import * as ReviewApplicationActions from "../../actions/reviewApplicationActions";
import moment from 'moment';
import LocationSearch from "components/common/Input/LocationSearch";
import { isNullOrWhiteSpace, isValidDate, toDate } from "../../common/helper/formatHelper";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import Currency from "../common/Currency";
import SelectDropDown from "../common/Input/SelectDropDown";
import CustomDatePicker from "../common/CustomDatePicker";
import { EnumOccupency } from "../common/Enums/EnumOccupency";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ReviewPropertyInformationProps = {
    loanPurpose: string;
    borrowerPropertyInformation: PropertyInformationStore.PropertyInformationState;
    isEdit: boolean;
    setPropertyValid: (isValid: any) => void;
    disabled: boolean
}
    & typeof PropertyInformationActions.propertyInformationActionCreators
    & typeof ReviewApplicationActions.reviewApplicationActionCreators
    & typeof TaskMenuCreators

const ReviewPropertyInformation = (props: ReviewPropertyInformationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [zipFormat, setZipFormat] = React.useState("#########");
    React.useEffect(() => {
        if (props.borrowerPropertyInformation.zip &&
            props.borrowerPropertyInformation.zip.length > 5) {
            setZipFormat("#####-####");
            return;
        }
        setZipFormat("#########");
    }, [props.borrowerPropertyInformation.zip]);

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        let isValid = true;
        isValid =
            !isNullOrWhiteSpace(props.borrowerPropertyInformation.city) &&
            !isNullOrWhiteSpace(props.borrowerPropertyInformation.state) &&
            !isNullOrWhiteSpace(props.borrowerPropertyInformation.zip) &&
            !(props.borrowerPropertyInformation?.zip && props.borrowerPropertyInformation?.zip.length < 5);

        if (props.loanPurpose === "Refinance") {
            if (isNullOrWhiteSpace(props.borrowerPropertyInformation.yearPropertyBuilt) ||
                isNullOrWhiteSpace(props.borrowerPropertyInformation.estimatedCurrentValue) ||
                isNullOrWhiteSpace(props.borrowerPropertyInformation.address) ||
                isNullOrWhiteSpace(props.borrowerPropertyInformation.homeOwnersInsurancePerYear) ||
                isNullOrWhiteSpace(props.borrowerPropertyInformation.taxesPerYear) ||
                isNullOrWhiteSpace(props.borrowerPropertyInformation.currentMortgageBalance)
            ) {
                isValid = false;
            }
        } else if (props.loanPurpose === "Purchase") {
            if (isNullOrWhiteSpace(props.borrowerPropertyInformation.propertyUsageType) ||
                isNullOrWhiteSpace(props.borrowerPropertyInformation.loanTermInYears) ||
                (!isValidDate(toDate(props.borrowerPropertyInformation.estimatedClosingDate))) ||
                isNullOrWhiteSpace(props.borrowerPropertyInformation.estimatedLoanAmount)
            ) {
                isValid = false;
            }
        }
        props.setPropertyValid(isValid);
    }, [props.borrowerPropertyInformation]);

    const setAddress = (streetValue: string, cityValue: any, stateValue: any, zipValue: any) => {
        props.updateAddressFields(streetValue, cityValue, stateValue, zipValue);
    };

    const returnOccupency = (occupancyType: any) => {
        switch (occupancyType) {
            case "PrimaryResidence":
                return EnumOccupency.PrimaryResidence;
            case "SecondHome":
                return EnumOccupency.SecondHome;
            case "Investor":
                return EnumOccupency.Investor;
            default:
                return occupancyType;
        }
    }

    return <>
        {props.loanPurpose === "Refinance" &&
            <div className={`${styles.inputGroup} ${styles.addressBox}`}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Refinance Address')}>Refinance Address <span className={styles.requiredMsg}> * </span>:</label>
                {!props.isEdit ?
                    <div className={styles.inputContainer}>
                        <LocationSearch
                            isDisabled={props.disabled}
                            addressStreetLine1={props.borrowerPropertyInformation?.address ?? ""}
                            addresssFieldName={"address"}
                            borrowerType={"Primary"}
                            customType={"Custom"}
                            setFullAddress={setAddress}
                            styles={""}
                        />
                    </div> :
                <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.address)}>{props.borrowerPropertyInformation?.address}</label>
                }
            </div>
        }

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City')}>City <span className={styles.requiredMsg}> * </span>:</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                name="City"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.updatePropertyInformationField(e.target.value, "city")
                }}
                value={props.borrowerPropertyInformation?.city ?? ""}
                maxLength="20"
                minLength="2"
                readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.city ?? "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State <span className={styles.requiredMsg}> * </span>:</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                name="State"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.updatePropertyInformationField(e.target.value, "state")
                }}
                value={props.borrowerPropertyInformation?.state ?? ""}
                maxLength="2"
                minLength="2"
                readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.state ?? "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Zip')}>Zip <span className={styles.requiredMsg}> * </span>:</label>{!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        value={props.borrowerPropertyInformation?.zip ?? ""}
                        format={zipFormat}
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            props.updatePropertyInformationField(values.value, "zip")
                        }}
                        className={styles.numberFormat}
                        readOnly={props.isEdit}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.zip ?? "")}/>
                </div>
                :
                <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.zip)}>{props.borrowerPropertyInformation?.zip}</label>
            }
        </div>

        {props.loanPurpose === "Purchase" &&
            <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Estimated Value')}>Estimated Value <span className={styles.requiredMsg}> * </span>:</label>
                {!props.isEdit ?
                    <div className={styles.inputContainer}>

                        <NumberFormatInput
                            disabled={props.disabled}
                            name="totalAvailableToSpendOnNewHome"
                            className={styles.numberFormat}
                            value={props.borrowerPropertyInformation?.totalAvailableToSpendOnNewHome ?? ""}
                            id="#totalAvailableToSpendOnNewHome"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                props.updatePropertyInformationField(values.value, "totalAvailableToSpendOnNewHome")
                            }}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.totalAvailableToSpendOnNewHome ?? "")}/></div> : <label className={styles.inputLabel}>
                        <Currency value={props.borrowerPropertyInformation?.totalAvailableToSpendOnNewHome ?
                            Number(props.borrowerPropertyInformation?.totalAvailableToSpendOnNewHome) : 0} decimalScale={2}></Currency>
                    </label>
                }</div>
        }

        {props.loanPurpose === "Refinance" &&
            <>
                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Estimated Value')}>Estimated Value <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <NumberFormatInput
                                disabled={props.disabled}
                                name="estimatedCurrentValue"
                                className={styles.numberFormat}
                                value={props.borrowerPropertyInformation?.estimatedCurrentValue ?? ""}
                                id="#estimatedCurrentValue"
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updatePropertyInformationField(values.value, "estimatedCurrentValue")
                                }}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.estimatedCurrentValue ?? "")}/></div> : <label className={styles.inputLabel}>
                            <Currency value={props.borrowerPropertyInformation?.estimatedCurrentValue ?
                                Number(props.borrowerPropertyInformation?.estimatedCurrentValue) : 0} decimalScale={2}></Currency>
                        </label>
                    }
                </div>

                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Property Built Year')}>Property Built Year <span className={styles.requiredMsg}> * </span>:</label>
                    <div className={styles.inputContainer}>

                        <NumberFormatInput
                            disabled={props.disabled}
                            value={props.borrowerPropertyInformation?.yearPropertyBuilt ?? ""}
                            format="####"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                props.updatePropertyInformationField(values.value, "yearPropertyBuilt")
                            }}
                            className={styles.numberFormat}
                            readOnly={props.isEdit}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.yearPropertyBuilt ?? "")}/>
                    </div>
                </div>

                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Taxes per year')}>Taxes per year <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <NumberFormatInput
                                disabled={props.disabled}
                                value={props.borrowerPropertyInformation?.taxesPerYear ?? ""}
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updatePropertyInformationField(values.value, "taxesPerYear")
                                }}
                                className={styles.numberFormat}
                                readOnly={props.isEdit}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.taxesPerYear ?? "")}/>
                        </div>
                        : <label className={styles.inputLabel}>
                            <Currency value={props.borrowerPropertyInformation?.taxesPerYear ?
                                Number(props.borrowerPropertyInformation?.taxesPerYear) : 0} decimalScale={2}></Currency>
                        </label>
                    }
                </div>

                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Insurance cost')}>Insurance cost <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <NumberFormatInput
                                disabled={props.disabled}
                                name="homeOwnersInsurancePerYear"
                                className={styles.numberFormat}
                                value={props.borrowerPropertyInformation?.homeOwnersInsurancePerYear ?? ""}
                                id="#homeOwnersInsurancePerYear"
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updatePropertyInformationField(values.value, "homeOwnersInsurancePerYear")
                                }}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.homeOwnersInsurancePerYear ?? "")}/>
                        </div>
                        : <label className={styles.inputLabel}>
                            <Currency value={props.borrowerPropertyInformation?.homeOwnersInsurancePerYear ?
                                Number(props.borrowerPropertyInformation?.homeOwnersInsurancePerYear) : 0} decimalScale={2}></Currency>
                        </label>
                    }
                </div>

                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Balance')}>Balance <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <NumberFormatInput
                                disabled={props.disabled}
                                name="currentMortgageBalance"
                                className={styles.numberFormat}
                                value={props.borrowerPropertyInformation?.currentMortgageBalance ?? ""}
                                id="#currentMortgageBalance"
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updatePropertyInformationField(values.value, "currentMortgageBalance")
                                }}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.currentMortgageBalance ?? "")}/>
                        </div>
                        : <label className={styles.inputLabel}>
                            <Currency value={props.borrowerPropertyInformation?.currentMortgageBalance ?
                                Number(props.borrowerPropertyInformation?.currentMortgageBalance) : 0} decimalScale={2}></Currency>
                        </label>
                    }
                </div>
            </>
        }

        {props.loanPurpose === "Purchase" &&
            <>
                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Property use')}>Property use <span className={styles.requiredMsg}> * </span>:</label>
                    <input
                        disabled={props.disabled}
                        className={styles.input}
                        style={{ cursor: "not-allowed" }}
                        name="Property use"
                        value={returnOccupency(props.borrowerPropertyInformation?.propertyUsageType)}
                    readOnly={true} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(returnOccupency(props.borrowerPropertyInformation?.propertyUsageType))}/>
                </div>

                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Term')}>Loan Term:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <SelectDropDown
                                disabled={props.disabled}
                                value={props.borrowerPropertyInformation?.loanTermInYears ?? ""}
                                name="loanTermInYears"
                                id="#loanTermInYears"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.updatePropertyInformationField(e.target.value, "loanTermInYears")
                                }}
                                dropdownclass={styles.select}
                                className={styles.selectDdl}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.loanTermInYears ?? "")} >
                                <option value="15">15 Years</option>
                                <option value="30">30 Years</option>
                            </SelectDropDown>
                        </div>
                        :
                    <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.loanTermInYears + 'Years')}>{props.borrowerPropertyInformation?.loanTermInYears} Years</label>
                    }
                </div>

                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Estimated Closing Date')}>Estimated Closing Date <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <CustomDatePicker
                                disabled={props.disabled}
                                fieldValue={props.borrowerPropertyInformation?.estimatedClosingDate ?
                                    new Date(props.borrowerPropertyInformation?.estimatedClosingDate) : undefined}
                                onDateChange={(date: any) => {
                                    props.updatePropertyInformationField(toDate(date), "estimatedClosingDate");
                                }}
                                className={styles.propertyDatePicker}
                                popperPlacement={true}
                            />
                        </div>
                    : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(moment(props.borrowerPropertyInformation?.estimatedClosingDate).format('MM/DD/YYYY'))}>{moment(props.borrowerPropertyInformation?.estimatedClosingDate).format('MM/DD/YYYY')}</label>
                    }
                </div>

                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Estimated Loan Amount')}>Estimated Loan Amount <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <NumberFormatInput
                                disabled={props.disabled}
                                name="estimatedLoanAmount"
                                className={styles.numberFormat}
                                value={props.borrowerPropertyInformation?.estimatedLoanAmount ?? ""}
                                id="#estimatedLoanAmount"
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updatePropertyInformationField(values.value, "estimatedLoanAmount")
                                }}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerPropertyInformation?.estimatedLoanAmount ?? "")}/>
                        </div>
                        : <label className={styles.inputLabel}>
                            <Currency value={props.borrowerPropertyInformation?.estimatedLoanAmount ?
                                Number(props.borrowerPropertyInformation?.estimatedLoanAmount) : 0} decimalScale={2}></Currency>
                        </label>
                    }
                </div>
            </>
        }
    </>;
};

const mapStateToProps = (state: ApplicationState, ownProps: {
    loanPurpose: any,
    borrowerPropertyInformation: PropertyInformationStore.PropertyInformationState,
    isEdit: boolean,
    setPropertyValid: (isValid: any) => void;
    isLoanCompleted: boolean;
}): any => {
    return state.propertyInformation ?
        {
            loanPurpose: ownProps.loanPurpose,
            borrowerPropertyInformation: ownProps.borrowerPropertyInformation,
            isEdit: ownProps.isEdit,
            setPropertyValid: ownProps.setPropertyValid,
            disabled: ownProps.isLoanCompleted
        } : state;
}

const mapDispatchToProps = (dispatch: any) => ({
    updatePropertyInformationField: (value: any, propertyName: string, isNumericalValue = false) => {
        dispatch(PropertyInformationActions.propertyInformationActionCreators.updatePropertyInformationField(value, propertyName, isNumericalValue));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    updateAddressFields: (streetValue: string, cityValue: string, stateValue: any, zipValue: any) => {
        dispatch(PropertyInformationActions.propertyInformationActionCreators.updateAddressFields(streetValue, cityValue, stateValue, zipValue));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewPropertyInformation as any);