import React, { useState, useEffect } from "react";
import ChoiceQuestion from "../common/ChoiceQuestion";
import primaryResidenceGraphic from "assets/infographics/accountInformation/primary-residence.svg";
import secondHomeGraphic from "assets/infographics/accountInformation/second-home.svg";
import investmentGraphic from "assets/infographics/accountInformation/investment.svg";
import { Choice } from "../../interfaces/Question";
import styles from "./AccountInformation.module.scss";

const OccupancyType = (props: {
    updateOccupancyTypeChoice: (value: string) => any, data: any, occupancyType: any
}) => {
    const [occupancyTypeChoices, setOccupancyTypeChoices] = useState([]);

    useEffect(() => {
        if (props.data.length > 0) {
            setOccupancyTypeChoices(props.data);
        }
    }, [props.data]);

    const loadSvg = (name: string) => {

        switch (name) {
            case 'primary-residence':
                return primaryResidenceGraphic
            case 'second-home':
                return secondHomeGraphic
            case 'investment':
                return investmentGraphic
        }
    };
    const choices: Choice[] = [];
    occupancyTypeChoices.map((res: any) => {
        let choice: Choice = {
            text: res.name,
            icon: loadSvg(res.icon),
            onClick: () => props.updateOccupancyTypeChoice(res.name),
            toolTipText: res.description,
            className: props.occupancyType === res.name ? styles.selected : ""
        };
        choices.push(choice);
    })

    return (
        <ChoiceQuestion
            disabled={false}
            intro="Occupancy Type"
            question="How will you be using this property?"
            choices={choices}
        />
    );
}

export default OccupancyType;
