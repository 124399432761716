import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { BorrowerReoState, KnownAction, ReoProperty } from "../store/borrowerReoStore";

const unloadedState: BorrowerReoState = {
    hasAdditionalProperties: undefined,
    currentReoProperty: {
        id: "",
        owner: "",
        occupancyType: "",
        propertyType: "",
        noOfUnits: 0,
        willContinueToOwnPropertyAfterTransaction: undefined,
        street: "",
        city: "",
        state: "",
        zip: "",
        marketValue: "",
        propertyUsageType: ""

    },
    reoProperties: [] as ReoProperty[],
    realEstateDoesNotApply: undefined,
    isLoading: false
}

export const borrowerReoReducer: Reducer<BorrowerReoState> = (state: BorrowerReoState | undefined, incomingAction: Action): BorrowerReoState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.IsLoading: {
            return { ...state, isLoading: action.loading };
        };

        case ActionTypes.RemoveProperty: {
            if (state.reoProperties) {
                const copyItems = [...state.reoProperties];
                const index = copyItems.findIndex(i => i.id === action.id);
                copyItems.splice(index, 1);
                return {
                    ...state, reoProperties: copyItems
                }
            }
            return { ...state };
        };

        case ActionTypes.UpdateHasAdditionalProperties: {
            if (action.reoType === "HasAdditionalProperties") {
                return {
                    ...state,
                    hasAdditionalProperties: action.value,
                    realEstateDoesNotApply: !action.value
                };
            } else {
                return {
                    ...state,
                    hasAdditionalProperties: !action.value,
                    realEstateDoesNotApply: action.value
                };
            }
        };
        case ActionTypes.AddReoProperty: {
            if (action.data) {
                let allReoProperties = [...state.reoProperties];

                let reo = allReoProperties.filter(x => x.id === action.data?.id);
                if (reo.length > 0) {
                    let index = allReoProperties.findIndex(x => x.id === action.data?.id);

                    reo[0].city = action.data.city;
                    reo[0].marketValue = action.data.marketValue;
                    reo[0].noOfUnits = action.data.noOfUnits;
                    reo[0].occupancyType = action.data.occupancyType;
                    reo[0].owner = action.data.owner;
                    reo[0].propertyType = action.data.propertyType;
                    reo[0].willContinueToOwnPropertyAfterTransaction = action.data.willContinueToOwnPropertyAfterTransaction;
                    reo[0].street = action.data.street;
                    reo[0].state = action.data.state;
                    reo[0].zip = action.data.zip;

                    allReoProperties[index] = reo[0];
                } else {
                    allReoProperties.push(action.data);
                }

                return {
                    ...state,
                    currentReoProperty: action.data,
                    reoProperties: allReoProperties
                };
            }
            return { ...state };
        };
        case ActionTypes.UpdateReoInformationField: {
            const list = [...state.reoProperties];
            list.map((reo: any) => {
                if (action.id === reo.id) {
                    reo[action.field] = action.value;
                }
                return reo;
            });
            return {
                ...state,
                reoProperties: list
            }
        };
        case ActionTypes.UpdateReoAddressField: {
            const list = [...state.reoProperties];
            list.map((reo: ReoProperty) => {
                if (action.id === reo.id) {
                    reo.city = action.cityValue;
                    reo.state = action.stateValue;
                    reo.street = action.streetValue;
                    reo.zip = action.zipValue;
                }
                return reo;
            });
            return {
                ...state,
                reoProperties: list
            }
        };
        case ActionTypes.GetReoDetails: {
            if (action.data) {
                return {
                    ...state,
                    reoProperties: action.data.reoProperties,
                    hasAdditionalProperties: action.data?.reoProperties && action.data.reoProperties.length > 0,
                    realEstateDoesNotApply: action.data.realEstateDoesNotApply
                }
            }
        };


        default:
            return state;
    }
}