export enum EnumLiability {
    Installment = "Installment",
    Revolving = "Revolving",
    ChildCare = "Child Care",
    HomeEquityLineofCredit = "Home Equity Line of Credit",
    Open30DayChargeAccount = "Open 30 Days Charge Account",
    MortgageLoan = "Mortgage",
    LeasePayments = "Lease Payments",
    OtherLiability = "Other Liability",
    ChildSupport = "Child Support"
}
