import * as React from "react";
import BorrowerLiabilityItem from "./BorrowerLiabilityItem";
import BorrowerLiabilityList from "./BorrowerLiabilityList";
import { BorrowerLiabilityDetail, BorrowerLiabilityState } from "../../store/borrowerLiabilityStore";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { borrowerLiabilityActionCreators } from "../../actions/borrowerLiabilityActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import _uniqueId from 'lodash/uniqueId';
import styles from "./BorrowerLiability.module.scss";
import NavigationWrapper from "../common/NavigationWrapper";
import { BorrowerTypeEnum } from "../../common/BorrowerTypeEnum";
import * as borrowerLiabilityService from "../../services/borrowerLiabilityService";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { IsNarrator } from "../../common/CommonMethods";
export enum BorrowerLiabilityStep {
    BorrowerLiabilityList = 2,
    BorrowerLiabilityEdit = 3
}

type BorrowerLiabilityProps = {
    borrowerLiabilityDetail: BorrowerLiabilityDetail[];
    loanGuid: string;
    validation: any;
    isCoBorrowerPresent: boolean;
    history: any;
    isLoanCompleted: boolean;
    isEncompassDown: boolean;
    interimLoanStagingId: number;
}
    & typeof borrowerLiabilityActionCreators
    & typeof TaskMenuCreators
    & typeof loanUpdateResponseActionCreators
    & BorrowerLiabilityState;

interface BorrowerLiabilityInterface {
    isLoading: boolean;
    currentStep: number;
    editId: string;
    isEdit: boolean;
    isValid: boolean;
    isContinueDisabled?: boolean;
    onContinueClick?: () => void;
    onBackClick?: () => void;
    resetContinue?: any;
    additionalLiability?: boolean;
}

class BorrowerLiability extends React.PureComponent<BorrowerLiabilityProps, BorrowerLiabilityInterface> {
    unlisten: any;
    constructor(props: any) {
        super(props);
        this.state = {
            currentStep: 2,
            editId: "",
            isEdit: false,
            isValid: false,
            isContinueDisabled: false,
            isLoading: false,
            additionalLiability: undefined
        }
        this.addLiabilityItem = this.addLiabilityItem.bind(this);
        this.unlisten = this.props.history.listen((location: any) => {
            if (location?.pathname === "/liability") {
                this.resetContinue();
            }
        });
    }
    speakText = (textToSpeak: string) => {
        if (IsNarrator()) {
            let text = new SpeechSynthesisUtterance(textToSpeak);
            speechSynthesis.speak(text);
        }
    }

    stopSpeaking = () => {
        speechSynthesis.cancel();
    }

    componentWillUnmount() {
        //preventing history.listen call in every route change.
        this.unlisten();
    }

    async getLiabilities() {
        if (this.props.interimLoanStagingId && this.props.interimLoanStagingId > 0) {
            this.props.getBorrowerLiabilityInformation(this.props.interimLoanStagingId);
            await borrowerLiabilityService.readAdditionalLiabilityStatus(this.props.interimLoanStagingId).then((res: any) => {
                if (res) {
                    this.setState({ additionalLiability: res.parsedBody });
                }
            })
        }
    }

    async componentDidMount() {
        this.setBackClick();
        await this.getLiabilities();
    }

    addLiabilityItem = () => {
        const emptyliabilityDetail: BorrowerLiabilityDetail = {
            id: _uniqueId('uniqid-'),
            accountIdentifier: "",
            holderName: "",
            liabilityType: "",
            monthlyPaymentAmount: "",
            owner: this.props.isCoBorrowerPresent ? "" : BorrowerTypeEnum.Borrower,
            unpaidBalanceAmount: "",
            isNew: true,
        };
        this.setState({ editId: emptyliabilityDetail.id, isEdit: true });
        this.props.addLiabilityToState(emptyliabilityDetail);
        this.changeStep(BorrowerLiabilityStep.BorrowerLiabilityEdit);
    };

    resetContinue() { //reset the continue button on route param change.
        this.setState({
            ...this.state,
            onContinueClick: undefined,
        }, () => {
            this.showContinue();
        });
    }

    async updateAdditionalLiabilityAsync() {
        if (this.props.isLoanCompleted) {
            this.props.history.push({ pathname: "/property-information" });
        }
        else {
            this.setState({ isLoading: true });
            await borrowerLiabilityService.updateAdditionalLiabilityAsync(this.props.loanGuid, this.props.interimLoanStagingId, this.state.additionalLiability)
                .then((response: any) => {
                    if (!response.ok) {
                        console.log(`There was a problem updating Borrower Liability for loan #${this.props.loanGuid}.`);
                    }
                    let result = response.parsedBody as boolean;
                    this.setState({ isLoading: false });

                    if (result) {
                        this.props.history.push({ pathname: "/property-information" });
                    }
                    
                })
        }
    }

    changeStep = (value: any) => {
        this.setState({
            currentStep: value
        });
    }

    editLiability = (liabilityId: string) => {
        this.setState({ ...this.state, editId: liabilityId, isEdit: true },
            () => this.changeStep(BorrowerLiabilityStep.BorrowerLiabilityEdit));
    }

    removeLiabilityFromState = (liabilityId: string) => {
        let liability = this.props.borrowerLiabilityDetail.find(x => x.id === liabilityId);
        if (liability?.isNew) {
            this.props.removeLiabilityFromState(liabilityId);
        } else {
            this.props.getBorrowerLiabilityInformation(this.props.interimLoanStagingId);
        }
    }

    deleteLiabilityFromCache = async (liabilityId: string) => {
        if (!this.props.isLoanCompleted) {
            let liability = this.props.borrowerLiabilityDetail.find(x => x.id === liabilityId);
            if (liability && !liability.isNew) {
                this.setState({ isLoading: true });
                let response = await borrowerLiabilityService.deleteLiability(this.props.interimLoanStagingId, liability);
                if (response.ok) {
                    this.setState({ isLoading: false });
                }
            } else {
                this.props.setLoanIsDirty(true);
            }
            this.props.removeLiabilityFromState(liabilityId);
        }
    }

    updateBorrowerLiability = (liabilityId: string) => {
        if (!this.props.isLoanCompleted) {
            let liability = this.props.borrowerLiabilityDetail.find(x => x.id === liabilityId);
            if (liability != null) {
                this.setState({ isLoading: true });
                borrowerLiabilityService.create(this.props.interimLoanStagingId, liability, this.state.additionalLiability)
                    .then((response: any) => {
                        this.setState({ isLoading: false });
                        if (!response.ok) {
                            console.log(`There was a problem updating Borrower Liability for loan #${this.props.loanGuid}.`);
                        }
                        this.changeStep(BorrowerLiabilityStep.BorrowerLiabilityList);
                        return response.parsedBody as string;
                    }).then(() => {
                        this.getLiabilities();
                    });
            }
        }
    }

    setValid = (e: any) => { this.setState({ isValid: e }) }

    onContinue = () => {
        if (this.state?.currentStep === BorrowerLiabilityStep.BorrowerLiabilityList) {
            this.updateAdditionalLiabilityAsync();
        }
        else if (this.state?.currentStep === BorrowerLiabilityStep.BorrowerLiabilityEdit) {
            this.updateBorrowerLiability(this.state.editId);
        }
    }

    onBack = () => {
        if (this.state?.currentStep === BorrowerLiabilityStep.BorrowerLiabilityList) {
            this.props.setLoanIsDirty(false);
            const { history } = this.props;
            history.push('/reo-properties');
        }
        else if (this.state?.currentStep === BorrowerLiabilityStep.BorrowerLiabilityEdit) {
            this.changeStep(BorrowerLiabilityStep.BorrowerLiabilityList);
            this.removeLiabilityFromState(this.state.editId);
        }
    }

    showContinue() {
        this.setState({
            onContinueClick: () => { this.onContinue(); },
        });
    }

    setIsDisabled(disabled: boolean) {
        if (disabled !== this.state.isContinueDisabled) {
            this.setState({
                isContinueDisabled: disabled
            });
        }
    }

    setBackClick() {
        this.setState({
            onBackClick: () => { this.onBack(); }
        });
    }

    handleAdditionalLiability(value: boolean) {
        this.setState({ additionalLiability: value });
    }

    render() {
        let borrowerLiabilityPage = <></>;
        let editItem;
        const liabilityDetails = this.props.borrowerLiabilityDetail?.filter(x => x.isNew ||
            (!isNullOrWhiteSpace(x.monthlyPaymentAmount) && x.monthlyPaymentAmount !== "0" && x.monthlyPaymentAmount !== "0.00"));

        const rows = liabilityDetails?.map((item, i) => {
            return (
                <BorrowerLiabilityList
                    key={i}
                    loanGuid={this.props.loanGuid}
                    borrowerLiability={item}
                    deleteLiability={this.deleteLiabilityFromCache}
                    editLiability={this.editLiability}
                    borrowerLiabilityList={this.props.borrowerLiabilityDetail}
                    disabled={this.props.isLoanCompleted}
                />
            );
        });

        if (liabilityDetails?.length > 0 && this.state.isEdit) {
            editItem =
                <BorrowerLiabilityItem setValid={this.setValid} isCoBorrowerPresent={this.props.isCoBorrowerPresent}
                    liabilityDetail={this.props.borrowerLiabilityDetail.find(x => x.id === this.state.editId)} disabled={this.props.isLoanCompleted}
                />
        }

        if (this.state?.currentStep === BorrowerLiabilityStep.BorrowerLiabilityList) {
            borrowerLiabilityPage = (
                <>
                    <div className={styles.backgroundImage}>
                        <div className="vquestion-container">
                            <div className={styles.borrowerLiabilityContainer}>
                                <div>
                                    <h1 onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Providing information about your monthly expenses is not required but will help us find the perfect loan product for your needs")}> Providing information about your monthly expenses is not required but will help us find the perfect loan product for your needs. </h1>
                                </div>
                                <div className={styles.card}>
                                    <div className={styles.cardRow}>
                                        {rows}
                                        <div className={styles.addAccountBtn}>
                                            <button disabled={this.props.isLoanCompleted} onClick={this.addLiabilityItem} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("press button to Add Another Account")}> <i className="fa fa-plus-circle"> </i>  Add Another Account </button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.additionalLiabilityRow}>
                                    <h5 onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Do you have any additional liabilities other than those presented above")}> Do you have any additional liabilities other than those presented above? <pre> * </pre></h5>
                                    <button disabled={this.props.isLoanCompleted}
                                        onClick={() => !this.props.isLoanCompleted ? this.handleAdditionalLiability(true) : ""}
                                        className={`common-button ${(this.state.additionalLiability === true) ? "active" : ""}`} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("press button to yes")}>
                                        <span>Yes</span>
                                    </button>
                                    <button disabled={this.props.isLoanCompleted}
                                        onClick={() => !this.props.isLoanCompleted ? this.handleAdditionalLiability(false) : ""}
                                        className={`common-button ${this.state.additionalLiability === false ? "active" : ""}`} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("press button to no")}>
                                        <span>No</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
            this.setIsDisabled((this.state.additionalLiability == undefined));
        }
        else if (this.state?.currentStep === BorrowerLiabilityStep.BorrowerLiabilityEdit) {
            borrowerLiabilityPage = (
                <>
                    <div className="vquestion-container">
                        <div className="display-table">
                            <div className={`display-cell income-source ${styles.newBorrowerLiability}`}>
                                <h1 className={styles.textCenter} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("New Liability")}>New Liability</h1>
                                <h2 className={styles.textCenter} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Please fill out the following information for your expense")}>Please fill out the following information for your expense.</h2>
                                {editItem}
                            </div>
                        </div>
                    </div>
                </>
            );
            this.setIsDisabled((this.props?.validation && Object.keys(this.props?.validation).length > 0) || !this.state.isValid);
        }

        return (<>
            <div className={styles.loanInfo}>

            </div>
            <div className={styles.loanContainer}>
                {borrowerLiabilityPage}

                {this.state.isLoading && <div className="screenLoader"><span className="loader medium"></span></div>}
                <NavigationWrapper
                    IsContinueDisabled={this.state.isContinueDisabled || this.state.isLoading || this.props.isEncompassDown}
                    OnContinueClick={this.state.onContinueClick}
                    OnBackClick={this.state.onBackClick}
                    ResetContinue={this.state.resetContinue}
                    OnInitialize={() => this.showContinue()}
                    OnResetFromRoute={() => this.resetContinue()}
                ></NavigationWrapper>
            </div>
        </>
        );
    }
}

const mapStateToProps = (appState: ApplicationState): any => {
    const { borrowerLiability, accountCreation, loan } = appState;
    let loanGuid;
    let borrowerLiabilityDetail;
    let validation;
    let isCoBorrowerPresent;
    let isLoanCompleted;
    let isEncompassDown;
    let interimLoanStagingId;
    if (loan?.loanGuid) {
        loanGuid = loan.loanGuid;
        isLoanCompleted = loan.isLoanCompleted;
        interimLoanStagingId = loan.interimLoanStagingId;
    }
    isEncompassDown = appState.loanUpdateResponse?.isEncompassDown;
    if (accountCreation?.borrower) {
        isCoBorrowerPresent = accountCreation?.borrower?.printOnAdditionalBorrowerPage
    }

    if (borrowerLiability && borrowerLiability.data) {
        borrowerLiabilityDetail = borrowerLiability.data;
        validation = borrowerLiability.validation;
    }

    return {
        borrowerLiabilityDetail,
        loanGuid,
        validation,
        isCoBorrowerPresent,
        isLoanCompleted,
        isEncompassDown,
        interimLoanStagingId
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    addLiabilityToState: (liabilityDetail: BorrowerLiabilityDetail) => {
        dispatch(borrowerLiabilityActionCreators.addLiabilityToState(liabilityDetail));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    removeLiabilityFromState: (liabilityId: string) => {
        dispatch(borrowerLiabilityActionCreators.removeLiabilityFromState(liabilityId));
    },
    updateCache: (interimLoanStagingId: number, liability: BorrowerLiabilityDetail) => {
        dispatch(borrowerLiabilityActionCreators.updateCache(interimLoanStagingId, liability));
        dispatch(TaskMenuCreators.setLoanIsDirty(false));
    },
    getBorrowerLiabilityInformation: (interimLoanStagingId: number) => {
        dispatch(borrowerLiabilityActionCreators.getBorrowerLiabilityInformation(interimLoanStagingId));
    },
    setLoanIsDirty: (isDirty: boolean) => {
        dispatch(TaskMenuCreators.setLoanIsDirty(isDirty));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BorrowerLiability as any);