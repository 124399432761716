import * as React from "react";
import CreditAuthorizationDialog from "./CreditAuthorizationDialog";
import { ConsumerCreditAuthorizationStep } from "./ConsumerCreditAuthorization";
import * as ConsumerCreditAuthorizationStore from "../../store/consumerCreditAuthorizationStore";
import { BorrowerType } from "../../store/Enum";
import { ApplicationState } from "../../store";
import * as ConsumerCreditAuthorizationActions from "../../actions/consumerCreditAuthorizationActions";
import { connect, useSelector } from "react-redux";
import styles from "./creditAuthorization.module.scss";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import * as consumerCreditAuthorizationService from "../../services/consumerCreditAuthorizationService";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { accountCreationActionCreators } from "../../actions/accountCreationActions";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type ConsumerCreditAuthorizationLandingProps = {
    borrowerType: BorrowerType,
    changeScreen: React.Dispatch<React.SetStateAction<ConsumerCreditAuthorizationStep>>,
    setShowLoading: React.Dispatch<React.SetStateAction<boolean>>,
    borrowerName: string,
    loanGuid: string,
    isCoBorrowerPresent: boolean,
    isLoanCompleted: boolean,
    interimLoanStagingId: number,
} & ConsumerCreditAuthorizationStore.ConsumerCreditAuthorizationState & typeof
    ConsumerCreditAuthorizationActions.consumerCreditAuthorizationActionCreators & typeof loanUpdateResponseActionCreators
    & typeof accountCreationActionCreators;

const CreditAuthorizationDetail = (props: ConsumerCreditAuthorizationLandingProps) => {
    const [showDialog, setShowDialog] = React.useState(false);    
    let currentBorrower = props.borrowerType == BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower;
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    React.useEffect(() => {
        if (props.borrowerType === BorrowerType.PrimaryBorrower && props.interimLoanStagingId !== undefined && props.interimLoanStagingId > 0) {
            props.getConsumerCreditAuthorization(BorrowerTypeEnum.Borrower, props.interimLoanStagingId)
        }
        else if (props.borrowerType === BorrowerType.CoBorrower && props.interimLoanStagingId !== undefined && props.interimLoanStagingId > 0) {
            props.getConsumerCreditAuthorization(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
        }
    }, [props.borrowerType]);

    const dialogResponse = (verificationResult: boolean) => {

        if (verificationResult) {
            if (!props.isLoanCompleted) {
                saveCreditAuthorization(props.borrowerType == BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower);
            }
        }
        else {
            if (props.borrowerType === BorrowerType.PrimaryBorrower) {
                props.changeScreen(ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage);
            }
            else {
                props.changeScreen(ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage);
            }
        }
        setShowDialog(false);
    };

    const saveCreditAuthorization = async (data: ConsumerCreditAuthorizationStore.ConsumerCreditAuthorization) => {
        if (!isEncompassDown) {
            props.setShowLoading(true);
            const response = await consumerCreditAuthorizationService.updateConsumerCreditAuthorization(data);
            if (!response.ok) {
                console.error("consumer credit authorization failed to save for " + data.borrowerType);
            }
            let result = response.parsedBody as boolean;
            props.setShowLoading(false);
            if (result) {
                Promise.all([
                    consumerCreditAuthorizationService.updateConsumerCreditAuthLoanDetailData(data).then(() => {
                        if (data.interimLoanStagingId !== undefined && data.interimLoanStagingId >0) {
                            props.getBorrowerInformation( BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                        }
                    }),
                    consumerCreditAuthorizationService.createAndSubmitAuthorizationFile(data),
                    consumerCreditAuthorizationService.updateProgressTracker(data.loanGuid ?? "")
                ]);
                props.updateCreditAuthorizationProperty(false, props.borrowerType, props.loanGuid, props.interimLoanStagingId);
                props.changeScreen(ConsumerCreditAuthorizationStep.Finished);
            }
        }
    }

    const verifyCreditInformation = async (value: boolean) => {
        if (value) {
            if (props.isCoBorrowerPresent && props.borrowerType === BorrowerType.PrimaryBorrower) {
                props.updateCreditAuthorizationProperty(value, props.borrowerType, props.loanGuid, props.interimLoanStagingId);
            }
            else {
                props.updateCreditAuthorizationProperty(value, props.borrowerType, props.loanGuid, props.interimLoanStagingId);
            }
        }
        else {
            props.updateCreditReportIndicator(false, props.borrowerType);
            setShowDialog(true);
        }
    };
        let landingTitle =
        props.borrowerType === BorrowerType.PrimaryBorrower
            ? "Consumer Credit Authorization for Borrower"
            : "Consumer Credit Authorization for Co-Borrower";
    return (
        <div>
            {showDialog && <CreditAuthorizationDialog hasClosedDialog={dialogResponse}></CreditAuthorizationDialog>}
            <div className="infographic-side-layout-container">
                <div className={styles.creditAuth}>
                    <div className={styles.content}>
                        <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(landingTitle)}>{landingTitle}</h1>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("To continue with the mortgage process, we need your approval to perform a credit check.")}>To continue with the mortgage process, we need your approval to perform a credit check.</h2>
                        <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("During your mortgage loan application process, this institution performs a credit check that requires us to obtain" +
                        "and confirm information regarding your personal and financial background.This credit check includes, but is not" +
                        "limited to, your marital status, number of dependents, current and past employers, current deposit accounts," +
                        "current and past consumer credit accounts, and your mortgage and / or rental history")}>
                        During your mortgage loan application process, this institution performs a credit check that requires us to obtain
                        and confirm information regarding your personal and financial background. This credit check includes, but is not
                        limited to, your marital status, number of dependents, current and past employers, current deposit accounts,
                    current and past consumer credit accounts, and your mortgage and/or rental history.</p>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('I authorize this Credit Check')}>I authorize this Credit Check</h2>
                    </div>
                    <div className={styles.answerButton}>
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to I agree')}disabled={props.isLoanCompleted} className={`common-button ${(currentBorrower?.authorizedCreditReportIndicator === true) ? "active" : ""}`}
                            onClick={() => { verifyCreditInformation(true) }}>I agree</button>
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to I do not agree')} disabled={props.isLoanCompleted} className={`common-button ${(currentBorrower.authorizedCreditReportIndicator === false) ? "active" : ""}`}
                            onClick={() => verifyCreditInformation(false)} >I do not agree</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState, ownProps: { borrowerType: BorrowerType, changeScreen: React.Dispatch<React.SetStateAction<ConsumerCreditAuthorizationStep>>, setShowLoading: React.Dispatch<React.SetStateAction<boolean>> }): any => {

    return state.accountInformation && state.accountCreation ?
        {
            ...state.consumerCreditAuthorization,
            borrowerType: ownProps.borrowerType,
            changeScreen: ownProps.changeScreen,
            setShowLoading: ownProps.setShowLoading,
            borrowerName:
                ownProps.borrowerType == BorrowerType.PrimaryBorrower ?
                    state.accountCreation?.borrower?.firstName + " " + state.accountCreation?.borrower?.lastName :
                    state.accountCreation?.coBorrower?.firstName + " " + state.accountCreation?.coBorrower?.lastName,
            isCoBorrowerPresent: state.accountCreation?.coBorrower?.firstName != null ? true : false,
            loanGuid: state.loan?.loanGuid,
            isLoanCompleted: state.loan?.isLoanCompleted,
            interimLoanStagingId: state.loan?.interimLoanStagingId
        } :
        state.consumerCreditAuthorization
}

const mapDispatchToProps = (dispatch: any) => ({
    updateCreditAuthorizationProperty: (value: boolean, borrowerType: BorrowerType, loanGuid: string, interimLoanStagingId:number) => {
        dispatch(ConsumerCreditAuthorizationActions.consumerCreditAuthorizationActionCreators.updateCreditAuthorizationProperty(value, borrowerType, loanGuid, interimLoanStagingId));
    },
    getConsumerCreditAuthorization: (borrowerType: BorrowerTypeEnum.Borrower, interimLoanStagingId:number) => {
        dispatch(ConsumerCreditAuthorizationActions.consumerCreditAuthorizationActionCreators.getConsumerCreditAuthorization(borrowerType, interimLoanStagingId));
    },
    updateCreditReportIndicator: (value: boolean, borrowerType: BorrowerType) => {
        dispatch(ConsumerCreditAuthorizationActions.consumerCreditAuthorizationActionCreators.updateCreditReportIndicator(value, borrowerType));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    getBorrowerInformation: (borrowerType: string, interimLoanStagingId:number) => {
        dispatch(accountCreationActionCreators.getBorrowerInformation(borrowerType, interimLoanStagingId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreditAuthorizationDetail as any);