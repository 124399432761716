import React from "react";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
const EConsentAgreementText = () => {
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    } 
  return (
    <div>
      <h5 className="align-center">
              <strong onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Your Consent To Do Business Electronically (the eDisclosure Agreement)")}>
            Your Consent To Do Business Electronically (the eDisclosure Agreement)
        </strong>
      </h5>
          <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Your loan(s) involve various disclosures, records, and documents ('Loan Documents'), including this eBusiness Agreement. This eBusiness Agreement allows (1) Union Home Mortgage Corp. ('UHM', 'Our', 'Us', or 'We') to send Your Loan Documents in electronic rather than in paper form, (2) You to sign and authorize Your Loan Documents electronically, rather than on paper, and (3) Us and Our affiliated companies ('Partner Providers') to communicate, advertise and Market Our product and/or servicing offerings with You (collectively, 'Share Products')")}>
        Your loan(s) involve various disclosures, records, and documents ("Loan
        Documents"), including this eBusiness Agreement. This eBusiness Agreement allows
        (1) Union Home Mortgage Corp. ("UHM", "Our", "Us", or "We") to send Your Loan
        Documents in electronic rather than in paper form, (2) You to sign and authorize
        Your Loan Documents electronically, rather than on paper, and (3) Us and Our
        affiliated companies ("Partner Providers") to communicate, advertise and Market
        Our product and/or servicing offerings with You (collectively, "Share Products").
      </p>
          <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("For purposes of this eBusiness Agreement, 'eDocuments' means the Loan Documents related to this transaction that We may provide electronically and at all stages of Your mortgage loan, including pre - approval, pre - qualification, application, origination, closing and servicing. 'You' and 'Your' means any and all individuals who are financially liable on the mortgage loan, including applicants and those who can revoke consent")}>
        For purposes of this eBusiness Agreement, "eDocuments" means the Loan Documents
        related to this transaction that We may provide electronically and at all stages
        of Your mortgage loan, including pre-approval, pre-qualification, application,
        origination, closing and servicing. "You" and "Your" means any and all individuals
        who are financially liable on the mortgage loan, including applicants and those
        who can revoke consent.
      </p>
      <br/>
          <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("YOUR CONSENT")}>YOUR CONSENT</div>
      <ul>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("'Market' means You expressly allow Us and / or Partner Providers to contact You  (1) about Your mortgage loan(s) and(2) to share information about products and services offered by Partner Providers.You agree that UHM and Partner Providers may provide such information through and including, but not limited to, mail, email, and phone(including calls or text messages to a cellular or mobile device)")}>
          "Market" means You expressly allow Us and/or Partner Providers to contact You
          (1) about Your mortgage loan(s) and (2) to share information about products and
          services offered by Partner Providers. You agree that UHM and Partner Providers
          may provide such information through and including, but not limited to, mail,
          email, and phone (including calls or text messages to a cellular or mobile
          device).
        </li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Your Consent applies to all loans for which You made an application with Us. Your Consent (1) extends to all individuals on this loan and account ('All Users'), (2) affirms you can consent for All Users, and (3) allows All Users to access each other’s related loan information")}>
          Your Consent applies to all loans for which You made an application with Us.
          Your Consent (1) extends to all individuals on this loan and account ("All
          Users"), (2) affirms you can consent for All Users, and (3) allows All Users to
          access each other’s related loan information.
        </li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("If a document related to Your loan is not available in electronic form, a paper copy will be provided to You free of charge")}>
          If a document related to Your loan is not available in electronic form, a paper
          copy will be provided to You free of charge.
        </li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("If You do not consent to this eBusiness Agreement, or wish to Withdraw Your Consent, UHM will provide Your future Loan Documents in paper form")}>
          If You do not consent to this eBusiness Agreement, or wish to Withdraw Your
          Consent, UHM will provide Your future Loan Documents in paper form. 
        </li>
      </ul>
          <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("PAPER COPIES, HOW TO WITHDRAW YOUR CONSENT, CHANGE OF CONTACT INFORMATION")}>PAPER COPIES, HOW TO WITHDRAW YOUR CONSENT, CHANGE OF CONTACT INFORMATION</div>
      <ul>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("You can withdraw Your Consent, request paper copies, or change Your Contact Information by notifying Us. Your request to withdraw Your Consent should identify you and your account, and should let us know which Contact Methods you would prefer Us to stop using")}>
          You can withdraw Your Consent, request paper copies, or change Your Contact
          Information by notifying Us. Your request to withdraw Your Consent should
          identify you and your account, and should let us know which Contact Methods you
          would prefer Us to stop using:
          <ul>
            <li>
                          <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Phone: 440-234-4300")}>Phone: 440-234-4300</div>
                          <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Address: 8241 Dow Circle W, Strongsville, OH 44136")}>Address: 8241 Dow Circle W, Strongsville, OH 44136</div>
            </li>
            <li>
                          <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Telephone: 440-234-4300; or")}>Telephone: 440-234-4300; or </div>
                          <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("In Writing: 8241 Dow Circle W, Strongsville, OH 44136")}>In Writing: 8241 Dow Circle W, Strongsville, OH 44136</div>
            </li>
          </ul>
        </li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Please be sure to notify Us right away if Your Contact Information has changed. We are not responsible for Your inability to access Your Loan Documents unless you ensure that Your Contact Information is accurate at all times")}>
          Please be sure to notify Us right away if Your Contact Information has changed.
          We are not responsible for Your inability to access Your Loan Documents unless
          you ensure that Your Contact Information is accurate at all times.
        </li>
      </ul>
          <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("You will not be required to pay a fee for withdrawing consent and receiving paper copies of the Loan Documents")}>
        You will not be required to pay a fee for withdrawing consent and receiving paper
        copies of the Loan Documents.
      </p>
          <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Phone: 440-234-4300,  Address: 8241 Dow Circle W, Strongsville, OH 44136")}>
         Phone: 440-234-4300<br/>
         Address: 8241 Dow Circle W, Strongsville, OH 44136
      </p>
          <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("You will not be required to pay a fee for receiving paper copies of the Loan Documents")}>You will not be required to pay a fee for receiving paper copies of the Loan Documents.</p>
      <br/>
          <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("SOFTWARE OR HARDWARE REQUIREMENTS")}>SOFTWARE OR HARDWARE REQUIREMENTS</div>
      <ul>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("A computer with Internet access")}>A computer with Internet access</li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("An email account")}>An email account</li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("An Internet browser using 128-bit encryption or higher")}>An Internet browser using 128-bit encryption or higher</li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("A PDF reader (such as Adobe Acrobat DC, available for free)")}>A PDF reader (such as Adobe Acrobat DC, available for free)</li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("SSL encryption")}>SSL encryption</li>
              <li onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Printer access")}>Printer access</li>
      </ul>
          <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("If Software or Hardware Requirements change, We will notify You")}>If Software or Hardware Requirements change, We will notify You.</p>
          <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("If you click 'I agree', You Consent to do business with Us electronically and accept this eBusiness Agreement.Otherwise, click 'I Decline'")}>
        If you click "I agree", You Consent to do business with Us electronically and
        accept this eBusiness Agreement. Otherwise, click "I Decline"
      </p>
    </div>
  );
};

export default EConsentAgreementText;
