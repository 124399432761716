import { post, get } from "../common/dataFetch";
import { PropertyInformationState } from "../store/propertyInformationStore";
import { ResponseDto } from "../common/models/ResponseDto";

export const save = (state: PropertyInformationState, purposeType: string, loanGuid?: string) => {
    return post<ResponseDto<any>>("api/PropertyInformation/UpdateAsync", {
        ...state,
        loanGuid: loanGuid,
        purposeType: purposeType,
    });
};

export const getAll = (interimLoanStagingId:number) => {
    return get<any>(`api/PropertyInformation/ReadByInterimIdAsync?interimLoanStagingId=${interimLoanStagingId}`);
};