import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { ApplicationState } from "../store";
import { useInitialMount } from "hooks/useInitialMount";
import Loading from "../components/common/Loader";
import * as accountInformationService from "../services/accountInformationService";
import { useNavigation } from "../hooks/useNavigation";

const Invitation = () => {
    const [isLoading, setisloading] = React.useState(true);
    const [loanGuid, setloanGuid] = React.useState("");
    const { search } = useLocation();
    const values = queryString.parse(search);
    const [error, seterror] = React.useState("");
    const isInitialMount = useInitialMount();
    const history = useHistory();
    const navigation = useNavigation(() => {
        navigation.HideNavigation();
    });

    useEffect(() => {
        if (isInitialMount && values) {
            setloanGuid(values.loanGuid?.toString() ?? "");

            if (values.loanGuid?.toString() == undefined) {
                seterror("Unauthorized access!!");
                setisloading(false);
            }
        }
    }, [isInitialMount, values]);

    useEffect(() => {
        if (loanGuid != "" && loanGuid != undefined) {
            validateLoanGuid(loanGuid);
        }
    }, [loanGuid]);

    const login = async () => {
        history.push({ pathname: "/account", state: { action: "invitation", borrowerType: "CoBorrower", "loanGuid": loanGuid } });
    }

    const validateLoanGuid = async (loanGuid: string) => {
        let response = await accountInformationService.validateLoanGuid(loanGuid);
        
        if (response.ok) {
            if (response.parsedBody) {
                login();
            }
            else {
                seterror("Invalid Invitation!!");
                setisloading(false);
            }
        }
    }

    return (
        <div className="infographic-side-layout-container">
            <div className="content">
                <div className="align-center">
                    {isLoading ? <Loading /> : <h1>
                        {error}
                    </h1>}
                </div>
            </div>
        </div>
    )
};

export default connect((state: ApplicationState) => state.userAccountInformation)(Invitation as any);