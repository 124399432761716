import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { ZipCodeLookUpState, KnownAction } from "../store/ZipCodeLookUpStore";

const unloadedState: ZipCodeLookUpState = {
    data: undefined,
    isLoading: false,
    errorMessage: ""
}

export const zipCodeLookupReducer: Reducer<ZipCodeLookUpState> = (state: ZipCodeLookUpState | undefined, incomingAction: Action): ZipCodeLookUpState => {
     
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.RequestZipCodeLookup:
             
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ReceiveZipCodeLookup:
             
            return {
                ...state,
                data: [...action.data],
                isLoading: false
            };
        case ActionTypes.HandleErrorZipCodeLookup:
             
            return {
                ...state,
                isLoading: false,
                errorMessage: action.errorMessage
            };
        case ActionTypes.ClearExceptionZipCodeLookup:
             
            return {
                ...state,
                errorMessage: ""
            };
        case ActionTypes.ClearZipDetails:
             
            return {
                ...state,
                data: undefined
            };
        default:
            return state;
    }
}
