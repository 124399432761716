import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { loanState } from "../../store/loanStore";
import * as userAcountActions from "../../actions/userAcount";
import { accountInformationActionCreators } from "../../actions/accountInformationActions";
import { signIn, checkIsEncompassDown, createUserLoanDetailInformationEntry, readIsLoanFromEncompass } from '../../services/accountInformationService';
import { updateNavigationStatus } from '../../services/taskMenuService';
import { useNavigation } from "hooks/useNavigation";
import * as AccountInformationActions from "../../actions/accountInformationActions";
import { loanActionCreators } from "../../actions/loanActions";
import * as AccountInformationStore from "../../store/accountInformationStore";
import { Cookies } from 'react-cookie';
import { useOktaAuth } from "@okta/okta-react";
import Loading from "../common/Loader";
import { getLoanCompletedStatus } from '../../services/taskMenuService';
import { useExitPrompt } from 'components/common/useExitPrompt';
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import * as LoanUpdateResponseActions from "../../actions/loanUpdateResponseActions";
import * as accountInformationService from "../../services/accountInformationService";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { appConfigAsync } from "../../authConfig";
import { TaskItem, SubTaskItem, TaskStatusId } from "../common/progressTracker/TaskStatus";
import { BackgroundEngine } from "../common/BackgroundTaskTimer";
import { IAccountInformationProps } from "../../store/accountInformationStore";
import { isNullOrUndefined } from "util";
import * as loanSummaryService from "../../services/interimLoanSummaryService";
import { InterimLoanSummary, LoanOfficerDetail, LoanSummaryState } from "../../store/interimLoanSummaryStore";
import { interimLoanSummaryActionCreators } from "../../actions/interimLoanSummaryActions";


interface IRegistrationProps {
    updateUserBorrowerType: (value: string) => any,
    updateUserConsent: (value: boolean) => any,
    updateUserEmail: (value: string) => any;
    updateUserFirstName: (value: string) => any;
    updateUserLastName: (value: string) => any;
    setLoanGuid: (value: any) => any;
    setIsLoanCompleted: (value: any) => any;
    setIsLoanFromEncompassFlag: (value: any) => any;
}

type SignInProps = AccountInformationStore.AccountInformationState
    & typeof AccountInformationActions.accountInformationActionCreators & typeof LoanUpdateResponseActions.loanUpdateResponseActionCreators
    & typeof loanActionCreators & typeof userAcountActions.userAcountActionCreators & typeof interimLoanSummaryActionCreators
    & IRegistrationProps;

const SignIn = (props: SignInProps) => {
    const { authState, oktaAuth } = useOktaAuth();
    const navigation = useNavigation(() => { navigation.HideNavigation(); });
    const history = useHistory();
    const location = useLocation();
    const [signInError, setSignInError] = React.useState(false);
    const [signInErrorMsg, setSignInErrorMsg] = React.useState({});
    const loan = useSelector((state: ApplicationState) => state.loan as loanState);

    const [isEncompassDown, setEncompassDown] = React.useState(false);

    const [isLoading, setLoading] = React.useState(false);
    const account = useSelector((state: ApplicationState) => state.accountInformation as AccountInformationStore.AccountInformationState);
    let prompt = useExitPrompt();

    const signOut = () => {
        setSignInError(false);
        prompt.enablePrompt(false);
        oktaAuth.signOut();
    }

    React.useEffect(() => {
        return () => {
            prompt.enablePrompt(true);
        }
    });
    const navigateToeConsent = () => {
        history.push({ pathname: "/econsent" });
    }

    const returnBorrowerTypeEnum = (param: any) => {
        switch (param) {
            case BorrowerTypeEnum.CoBorrower:
                return 'Co-Borrower';
            default:
                return param;
        }
    }

    const setCookie = (key: string, value: any) => {
        const cookies = new Cookies();
        var endOfTime = "Fri, 31 Dec 9999 23:59:59 GMT";
        var endOfTimeDate = new Date(endOfTime);
        cookies.set(key, value, { expires: endOfTimeDate });
    }

    const loanCreation = async () => {
        let accountInformation: IAccountInformationProps = {
            noOfUnits: account.howManyUnitsChoice,
            borrowerEmailAddress: account.borrowerEmail,
            loanPurpose: account.loanPurposeChoice,
            userId: account.userId,
            occupancyType: account.occupancyTypeChoice,
            propertyType: account.propertyTypeChoice,
            purchaseClosingDate: account.closingDateChoice,
            refinancePriority: account.priorityChoice,
            borrowerFirstName: account.borrowerFirstName,
            borrowerLastName: account.borrowerLastName,
            borrowerPhoneNumber: account.borrowerPhoneNumber,
            borrowerMiddleName: account.borrowerMiddleName,
            borrowerNameSuffix: account.borrowerNameSuffix
        };

        props.IsLoading(true);
        await accountInformationService.create(accountInformation, account.loanGuid)
            .then((response: any) => {
                if (response.status != 200) {
                    return;
                }
                let result = response.parsedBody as LoanUpdateResponseState;
                if (result.isEncompassDown || result.loanGuid === null || result.loanGuid === "") {
                    console.log("Encompass service is down or loanGuid not present");
                    history.push({ pathname: "/system-unavailable" });
                    return;
                }

                if (accountInformation.userId == undefined) {
                    accountInformation.userId = "";
                }

                props.IsLoading(false);

                if (result.loanGuid === "Email id already registered" || result.isEncompassDown) {
                    props.submitInitialAccountInformation(false);
                }
                else {
                    props.setLoanGuid(result.loanGuid);
                    props.setInterimLoanStagingId(result.interimLoanStagingId);
                }

                return result;
            })
            .catch((error: Error) => {
                console.error("Error occurred during loan creation: ", error);
            });
    }

    const signInAndNavigateToNextStep = async (payload: any) => {
        const userInfo = payload.userInfo as any;
        const email = userInfo["email"];
        if (email) {
            const firstname = userInfo["given_name"];
            const lastname = userInfo["family_name"];
            props.updateUserEmail(email);

            props.updateBorrowerEmail(email);

            props.updateUserFirstName(firstname);
            props.updateUserLastName(lastname);
            props.updateUserBorrowerType(payload.borrowerType);

            //Save borrower info for live-chat
            setCookie("BorrowerFirstName", firstname);
            setCookie("BorrowerLastName", lastname);

            let loanGuid = loan.loanGuid ?? "";
            let interimLoanStagingId = loan.interimLoanStagingId ?? 0;
            if ((payload.action === "invitation" ||payload.invitation === "invitation") && payload.borrowerType === BorrowerTypeEnum.CoBorrower) {
                await createUserLoanDetailInformationEntry(email, firstname, lastname, payload.loanGuid, payload.borrowerType);
            }

            if (payload.action === "invitation" || !isNullOrWhiteSpace(payload.loanGuid)) {
                loanGuid = payload.loanGuid;
                props.setLoanGuid(loanGuid);
                props.setInterimLoanStagingId(interimLoanStagingId);
            }
            else if (payload.action === "SSO") {
                history.push({ pathname: "/ecc", search: location.state.qs, state: location.state });
                return;
            }

            let isMultipleLoanExist = false;
            await loanSummaryService.readLoanSummary(email).then((response: any) => {
                if (response.status != 200) {
                    return;
                }
                return response.parsedBody as InterimLoanSummary[];
            }).then(async (result: any) => {

                if (result.length < 1) {

                    const msg = <div>
                        <h1>No loan found for the signed in account</h1>
                        <h3>Click <a onClick={registerNewLoan}>HERE</a> to apply for a Loan Application.</h3>
                    </div>;
                    setSignInErrorMsg(msg);
                    setSignInError(true);

                    return;
                }


                if (result.length > 1) {
                    isMultipleLoanExist = true;
                }

                let stateData: LoanSummaryState = {
                    loanSummary: result,
                    currentLoanSummary: result[0],
                    loanOfficerDetail: {}
                }
                props.setInterimLoanSummary(stateData);

            }).catch((error: Error) => {
                console.error("Error occurred while reading InterimLoanSummary data", error);
            });


            checkIsEncompassDown().then(async res => {
                if (res.ok) {

                    let result = res.parsedBody as boolean;
                    if (!result) {
                        await appConfigAsync().then(async (config: any) => {
                            if (config?.PosConstant?.IsLoginLoanCreation && account.accountInformationCheck && !props.isLoancreated) {
                                await loanCreation();
                            }
                        });

                        let loanId;
                        let consentStatus: any;
                        let isLoanCompleted: any;

                        await signIn(email, payload.borrowerType, firstname, lastname, loanGuid).then(async res => {
                            setLoading(false);
                            if (res.ok && res.body) {
                                const userInfoFromDb = res.parsedBody as any;
                                loanId = userInfoFromDb.loanGuid;
                                consentStatus = userInfoFromDb.consentStatus;

                                BackgroundEngine.InitializeAndRun(userInfoFromDb.loanGuid);
                                updateNavigationStatus(TaskItem.BorrowerInformation, SubTaskItem.BorrowerInformation, TaskStatusId.InProgress, TaskStatusId.InProgress, userInfoFromDb.loanGuid);


                                prompt.setPromptValue(userInfoFromDb.borrowerType === 2 ? BorrowerTypeEnum.CoBorrower : BorrowerTypeEnum.Borrower, userInfoFromDb.loanGuid, userInfoFromDb.interimLoanStagingId);
                                if (userInfoFromDb.isLoanLocked) {
                                    const msg = <div>
                                        <h2>**Loan locked: {returnBorrowerTypeEnum(userInfoFromDb.lockedBy)} is already working on the loan. Please wait a few minutes before trying again.</h2>
                                        <h3>Click <a onClick={() => {
                                            retrySignIn();
                                            setLoading(true);
                                        }}>HERE</a> to retry.</h3>

                                    </div>;
                                    setSignInErrorMsg(msg);
                                    setSignInError(true);
                                    return;
                                }

                                if (payload.action === "create-account") {
                                    history.push({ pathname: "/econsent" });
                                    return;
                                }

                                if (payload.action === "sign-in" || payload.action === "invitation") {
                                    if (userInfoFromDb.loanGuid) {
                                        props.setLoanGuid(userInfoFromDb.loanGuid);
                                        props.setInterimLoanStagingId(userInfoFromDb.interimLoanStagingId);
                                        props.updateUserEmail(userInfoFromDb.email);
                                        props.updateUserFirstName(userInfoFromDb.firstName);
                                        props.updateUserLastName(userInfoFromDb.lastName);
                                        props.updateUserBorrowerType(userInfoFromDb.borrowerType === 2 ? "CoBorrower" : "Borrower");
                                        props.updateUserConsent(userInfoFromDb.consentStatus === "Accepted");

                                        if (isMultipleLoanExist) {
                                            history.push({ pathname: "/loan-view" });
                                            return;
                                        }

                                        //IN case of single loan
                                        if (userInfoFromDb.consentStatus === "Rejected") {
                                            const msg = <div>
                                                <h2>You have rejected the eConsent, you cannot continue applying for a Loan Application until you have agreed to the eConsent.  Please contact your loan officer for further assistance</h2>
                                                <h3>Click <a onClick={navigateToeConsent}>HERE</a> to continue for eConsent.</h3>
                                            </div>;
                                            setSignInErrorMsg(msg);
                                            setSignInError(true);
                                            return;
                                        }

                                        if (userInfoFromDb.consentStatus === "PrimaryRejected") {
                                            const msg = <div>
                                                <h2>The primary borrower has rejected the eConsent, you cannot continue applying for a Loan Application until the primary borrower has agreed to the eConsent.  Please contact your loan officer for further assistance</h2>
                                                <h3>Click <a onClick={signOut}>HERE</a> to continue.</h3>
                                            </div>;
                                            setSignInErrorMsg(msg);
                                            setSignInError(true);
                                            return;
                                        }

                                       

                                        //getLoanCompletedStatus(userInfoFromDb.loanGuid).then(response => {
                                        //    isLoanCompleted = response.parsedBody;
                                        //    props.setIsLoanCompleted(response.parsedBody);
                                        //});

                                        if (userInfoFromDb.consentStatus === "Accepted") {
                                            history.push({ pathname: "/dashboard" });
                                            return;
                                        }

                                        history.push({ pathname: "/econsent" });
                                        return;
                                    }

                                    const msg = <div>
                                        <h1>No loan found for the signed in account</h1>
                                        <h3>Click <a onClick={registerNewLoan}>HERE</a> to apply for a Loan Application.</h3>
                                    </div>;
                                    setSignInErrorMsg(msg);
                                    setSignInError(true);
                                }
                            } else {
                                console.error(`An unexpected error has occurred: ${res}`);
                                const msg = <div>
                                    <h2>An unexpected error has occurred</h2>
                                    <h3>Click <a onClick={retrySignIn}>HERE</a> to retry.</h3>
                                </div>;
                                setSignInErrorMsg(msg);
                                setSignInError(true);
                            }
                        })

                        //To check whether loan is already created from the encompass
                        if (!isNullOrUndefined(loanId) && !isMultipleLoanExist) {
                            readIsLoanFromEncompass(loanId)
                                .then((response) => {
                                    if (!response.ok) {
                                        throw new Error("Unable to fetch loan from encompass flag.");
                                    }
                                    return response.parsedBody as any;
                                })
                                .then(data => {
                                    props.setIsLoanFromEncompassFlag(data);

                                    if (!isNullOrUndefined(isLoanCompleted) && data) {
                                        if (!isNullOrUndefined(consentStatus) && consentStatus !== "Accepted") {
                                            history.push({ pathname: "/econsent" });
                                        }
                                        else if (!isLoanCompleted) {
                                            history.push({ pathname: "/contact-preference" });
                                        }
                                        else if (isLoanCompleted) {
                                            history.push({ pathname: "/dashboard" });
                                        }
                                    }
                                })
                                .catch((error: Error) => {
                                    console.log(error.message);
                                });
                        }
                    }
                    else {
                        console.error("SignIn.tsx -> Encompass is down");
                        props.setIsEncompassDown(true);
                        history.push({ pathname: "/system-unavailable" });
                    }
                }
            });
        }
    };

    const isUserAuthenticated = authState && authState.isAuthenticated;

    const processLogIn = () => {
        if (isUserAuthenticated) {
            //Clear borrower info for live-chat
            setCookie("BorrowerFirstName", "");
            setCookie("BorrowerLastName", "");

            oktaAuth.getUser().then((user) => {
                const payload = { ...location.state, userInfo: user };
                signInAndNavigateToNextStep(payload);
            }).catch((error: Error) => {
                console.error("getUser", error);
                signOut();
            });
        }
    }

    const retrySignIn = () => {
        processLogIn();
    }

    const registerNewLoan = () => {
        setSignInError(false);
        history.push({ pathname: "/account-information" });
    }

    React.useEffect(() => {
        if (location.state == undefined) {
            signOut();
            return;
        }

        if (location.state.action === "password-reset") {
            const msg = <div>
                <h2>A password reset instruction was sent to your email address.</h2>
                <h3>Click <a onClick={signOut}>HERE</a> to continue.</h3>
            </div>;
            setSignInErrorMsg(msg);
            setSignInError(true);
            return;
        }

        processLogIn();
    }, [authState]);

    return (
        <div>
            {isUserAuthenticated && !signInError && !isEncompassDown &&
                <Loading />
            }

            {signInError &&
                <div className="align-center">
                    {signInErrorMsg}
                    {isLoading && <div className="loader small" />}
                </div>
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    updateUserBorrowerType: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserBorrowerType(value));
    },
    updateUserConsent: (value?: boolean) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserConsent(value));
    },
    updateUserEmail: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserEmail(value));
    },
    updateUserFirstName: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserFirstName(value));
    },
    updateUserLastName: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserLastName(value));
    },
    setLoanGuid: (value?: string) => {
        dispatch(accountInformationActionCreators.setLoanGuid(value));
    },
    setInterimLoanStagingId: (value?: number) => {
        dispatch(loanActionCreators.setInterimLoanStagingId(value));
    },
    setIsLoanCompleted: (value?: boolean) => {
        dispatch(loanActionCreators.setIsLoanCompleted(value));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(LoanUpdateResponseActions.loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    submitInitialAccountInformation: (value: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.submitInitialAccountInformation(value));
    },
    IsLoading: (value?: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.IsLoading(value));
    },
    setIsLoanFromEncompassFlag: (value?: boolean) => {
        dispatch(loanActionCreators.setIsLoanFromEncompassFlag(value));
    },
    setInterimLoanSummary: async (data: LoanSummaryState) => {
        await dispatch(interimLoanSummaryActionCreators.setInterimLoanSummary(data));
    },
    updateBorrowerEmail: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerEmail(value));
    },
});


export default connect((state: ApplicationState) => state.userAccountInformation, mapDispatchToProps)(SignIn as any);
