import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, { IInputProps, defaultClassNames } from 'react-dropzone-uploader';
import styles from "./DocumentUpload.module.scss";
import borrowerDocumentStyles from "./BorrowerDocument.module.scss";
import { uploadAttachment } from "../../../services/documentService";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface ModalProps {
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading?: boolean;
    onSubmit: () => void;
    isOpen: boolean;
    sectionId: number | undefined;
    loanGuid: string;
};

const DocumentUpload = (props: ModalProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [showSpinner, setShowSpinner] = useState(false);

    const handleSubmit = async (files: any[]) => {
        setShowSpinner(true);
        let newFormData = new FormData();
        if (files.length > 0) {
            files.map(fileData => {
                newFormData.append('files', fileData.file, fileData.name)
            })
            await uploadAttachment(props.loanGuid, props.sectionId ?? 0, newFormData);
            setShowSpinner(false);
            props.onSubmit();
        }
    }

    const Preview = (metaData: any) => {
        let data = metaData.meta;
        let fileData = metaData.fileWithMeta;
        return (
            <>
                <span>
                    {data.name}
                    <i className="fa fa-times" aria-hidden="true" onClick={() => fileData.remove()}></i>
                </span>
            </>
        )
    }

    const Layout = (data: any) => {
        let input = data.input;
        let previews = data.previews;
        let dropzoneProps = data.dropzoneProps;
        let files = data.files;
        let submitButton = data.submitButton;
        let extra = data.extra;
        defaultClassNames.submitButton = styles.buttonDisable;
        if (files.length > 0) {
            defaultClassNames.submitButton = styles.buttonActive;
        }
        return (
            <div>
                <div className={styles.filePreview}>
                    {previews}
                </div>
                <div {...dropzoneProps}>
                    {files.length < extra.maxFiles && input}
                </div>
                {submitButton}

            </div>
        )
    }

    const Input = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
        return (
            <>
                <label className={styles.fileSelected}>
                    <span>
                        <p>Drag 'n' drop some files here, or click to select files</p>
                        <em>(Only JPEG, PNG, PDF, and WORD files are accepted. Maximum upload size not to exceed 25 MB total)</em>
                    </span>
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        accept={accept}
                        multiple
                        onChange={async e => {
                            const chosenFiles = await getFilesFromEvent(e)
                            onFiles(chosenFiles as File[])
                        }}
                    />
                </label>
            </>
        )
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (

        <Modal isOpen={props.isOpen} className={styles.modalDialog}>
            {showSpinner &&
                <div className={borrowerDocumentStyles.loaderBg}>
                    {showSpinner && <span className={`${borrowerDocumentStyles.loader} loader`}></span>}
                </div>
            }
            <ModalHeader className={styles.modalHeader} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Document Upload')}>
                Document Upload
                <i className={`fa fa-times ${styles.closeButton}`} aria-hidden="true" onClick={() => props.onCancel(false)}></i>
            </ModalHeader>
            <ModalBody>
                <div className={styles.dropzonebg}>
                    <Dropzone
                        accept="image/jpeg,image/jpg,image/png,.pdf,.doc,.docx"
                        LayoutComponent={Layout}
                        PreviewComponent={Preview}
                        onSubmit={handleSubmit}
                        canRemove={true}
                        submitButtonContent='Upload'
                        maxSizeBytes={26214400}
                        InputComponent={Input}
                        disabled={files =>
                            files.some(f => ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status))
                        }
                    />
                </div>
            </ModalBody>
        </Modal>
    );
};

export default DocumentUpload;