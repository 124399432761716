import * as React from "react";
import { Choice } from "../../interfaces/Question";
import Infographic from "./Infographic";
import ChoiceQuestionButton from "./ChoiceQuestionButton";
import styles from "./ChoiceQuestion.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface ChoiceQuestionProps {
    intro: string;
    question: string;
    choices: Choice[];
    infographic?: string;
    isIntroRequired?: boolean;
    isQuesRequired?: boolean;
    disabled: boolean | undefined;
}

const ChoiceQuestion = (props: ChoiceQuestionProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.choices]);
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
    return (
        <div className="infographic-side-layout-container">
            <div className={`content ${props.infographic ? "" : "align-center"}`}>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.intro)}>{props.intro} {props.isIntroRequired && <span className={styles.requiredMsg}> * </span>}</h1>
                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.question)}>{props.question}{props.isQuesRequired && <span className={styles.requiredMsg}> * </span>}</h2>
                <div
                    className={`${styles.choices} ${props.infographic ? "" : styles.full} ${
                        props.choices.length > 0 &&
                            props.choices[0].icon === undefined &&
                            props.infographic
                            ? styles.noIcon
                            : ""
                        }`}
                >
                    {props.choices.map((choice, index) => (
                        <ChoiceQuestionButton
                            disabled={props.disabled}
                            choice={choice}
                            key={index}
                        />
                    ))}
                </div>
            </div>
            {props.infographic && (
                <div className="infographic-side-panel">
                    <Infographic src={props.infographic} />
                </div>
            )}
        </div>
    );
};

export default ChoiceQuestion;
