import React from "react";
import NavigationButton, { ArrowDirection } from "./NavigationButton";
import styles from "./Buttons.module.scss";
import {  useLocation } from "react-router";

interface NavigationProps {
    back: ButtonProps;
    continue: ButtonProps;
    isMultipleLoan?: boolean;
}

interface ButtonProps {
  show?: boolean;
  disabled?: boolean;
  message?: string;
  onClick?: () => void;
}

const Navigation = (props: NavigationProps) => {
    const location = useLocation();
    const isDashboardPage = location.pathname.indexOf("dashboard") > -1 ? true : false;

    const getBackText = (): string => {
        
        let backMsg = props.back.message ?? "Back";
        if (props.isMultipleLoan && isDashboardPage) {
            backMsg = "BACK TO ALL LOANS";
        } 
        return backMsg;
    }


  return (
    <div className={styles.navigation}>
          {
              (props.back.show === undefined ? true : props.back.show) && (
        <NavigationButton
          onClick={props.back.onClick ? props.back.onClick : () => {}}
          text={getBackText()}
          disabled={props.back.disabled || false}
          arrowDirection={ArrowDirection.Left}
        />
      )}
      <div className={styles.spacer}></div>
      {(props.continue.show === undefined ? true : props.continue.show) && (
        <NavigationButton
          onClick={props.continue.onClick ? props.continue.onClick : () => {}}
                  text={props.continue.message ?? "Save/Continue"}
          disabled={props.continue.disabled || false}
          arrowDirection={ArrowDirection.Right}
        />
      )}
    </div>
  );
};

export default Navigation;
