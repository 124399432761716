import * as React from "react";
import { TaskMenuItem, SubTaskItem } from "../../../store/taskMenuStore";
import TaskItem from "./TaskItem";
import { animated } from "react-spring";
import styles from "./TaskMenu.module.scss";

type SubTaskListProps = {
    taskItem: TaskMenuItem;
};

const SubTaskList = (props: SubTaskListProps) => {
    return (
        <animated.div className={styles.subtaskContainer}>
            <div className={styles.subtaskList}>
                {props.taskItem.subTasks.map((subTaskItem: SubTaskItem, index: number) => {
                    return (
                        <TaskItem
                            key={`${props.taskItem.path}-${subTaskItem.path}-${index}`}
                            name={subTaskItem.subTaskName}
                            path={`/${subTaskItem.path}`}
                            args={`${subTaskItem.queryParameter}`}
                            isEnabled={subTaskItem.isEnabled}
                            isSubtask={true}
                            taskStatus={props.taskItem.taskStatusId}
                            subTaskStatus={subTaskItem.subTaskStatusId}
                        />
                    );
                })}
            </div>
        </animated.div>
    );
};

export default SubTaskList;
