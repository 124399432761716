import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction, PropertyInformationState } from '../store/propertyInformationStore';
import * as propertyInformationService from "../services/propertyInformationService";

const receive = (data: PropertyInformationState): KnownAction => {
    return { type: ActionTypes.ReceivePropertyInformation, data };
}

export const propertyInformationActionCreators = {
    updatePropertyInformationField: (value: any, propertyName: any, isNumericalValue = false): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UPDATE_PROPERTY_INFORMATION_FIELD, value: value, propertyName: propertyName, isNumericalValue: isNumericalValue });
    },

    updateAddressFields: (streetValue: string, cityValue: string, stateValue: any, zipValue: any): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: ActionTypes.UpdateAddressFields, streetValue: streetValue, cityValue: cityValue,
            stateValue: stateValue, zipValue: zipValue
        });
    },

    calculateEstimatedLoanAmount: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({
            type: ActionTypes.CalculateEstimatedLoanAmount
        });
    },

    getPropertyInformation: (interimLoanStagingId:number): AppThunkAction<KnownAction> => (dispatch) => {
        propertyInformationService.getAll(interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    console.error(response);
                }
                return response.parsedBody as PropertyInformationState;
            })
            .then((data: any) => {
                if (data?.body) {
                    dispatch(receive(data.body));
                }
            })
            .catch((error: Error) => {
                throw new Error(error.message);
            });
    },

    updatePropertyInformationState: (data: PropertyInformationState): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(receive(data));
    }
}
