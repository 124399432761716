import React from "react";
import styles from "./Buttons.module.scss";

interface ToolTipToggleProps {
  isToggled?: boolean;
  isActive: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ToolTipToggle = (props: ToolTipToggleProps) => (
  <div className={styles.toggleContainer}>
    <i
      className={`fa ${
        props.isToggled ? "fa-times-circle" : "fa-info-circle"
      } ${styles.tooltipToggle}  ${props.isActive ? "" : styles.noTooltip}`}
      onClick={props.onClick}
    ></i>
  </div>
);

export default ToolTipToggle;
