import * as React from "react";
import { withReadOnlyWrapper } from "./withReadOnlyWrapper";
import { useForm } from "react-hook-form";
import styles from "./SelectDropDown.module.scss";

interface SelectDropDownProps {
    children?: React.ReactNode;
    label?: string;
    noArrow?: boolean;
    rule: {};
    onChange: any;
    name: string;
    invalidclass: string;
    dropdownclass: string;
    handleValidation?: (error: any, field: string) => {};
}

const SelectDropDownComponent = (props: SelectDropDownProps) => {
    const { onChange, rule, handleValidation, noArrow, ...rest } = props;
    const { register, errors, trigger } = useForm();

    const triggerChange = (e: any) => {
        props.onChange(e);
        trigger(props.name);
    };
    const error = errors[props.name];

    if (props.handleValidation) {
        if (error) {
            error.message = error.message.replace('field', props.name);

            const { ref, ...errorInfo } = error;
            props.handleValidation(errorInfo, props.name);
        } else {
            props.handleValidation(null, props.name);
        }
    }

    return (
        <div className={styles.selectDropDown + (props.noArrow ? "" : " select")}>
            {props.label && (<label>{props.label}</label>)}
            <select {...rest}
                onChange={triggerChange}
                ref={register(props.rule)}>
                {props.children}
            </select>
            {error && (<span style={{ color: "red" }} className={props.invalidclass}>{error.message}</span>)}
        </div>
    );
};

const SelectDropDown = withReadOnlyWrapper(SelectDropDownComponent) as any;

export default SelectDropDown;
