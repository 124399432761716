import React, { useState, useEffect } from "react";
import ChoiceQuestion from "../common/ChoiceQuestion";
import closingDateGraphic from "assets/infographics/accountInformation/closing-date.svg";
import { Choice } from "../../interfaces/Question";
import styles from "./AccountInformation.module.scss";

const ClosingDate = (props: {
    updateClosingDateChoice: (value: string) => any, data: any, closingDate: any
}) => {
    const [closingDateChoices, setClosingDateChoices] = useState([]);
    useEffect(() => {
        if (props.data.length > 0) {
            setClosingDateChoices(props.data);
        }
    }, [props.data]);

    const choices: Choice[] = [];
    closingDateChoices.map((res: any) => {
        let choice: Choice = {
            text: res.name,
            onClick: () => props.updateClosingDateChoice(res.name),
            toolTipText: res.description,
            className: props.closingDate === res.name ? styles.selected : ""
        };
        choices.push(choice);
    })

    return (
        <ChoiceQuestion
            disabled={false}
            intro="Closing Date"
            question="When would you like to purchase your home?"
            choices={choices}
            infographic={closingDateGraphic}
        />
    );
}

export default ClosingDate;
