import * as React from "react";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RouteComponentProps, useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheckCircle, faExclamationCircle, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../store";
import styles from "./TaskMenu.module.scss";
import * as TaskMenuActions from "../../../actions/taskMenuAction";
import { TaskStatusId, ProgressNavigationEnum } from "./TaskStatus";
import { loanState } from "../../../store/loanStore";


interface TaskItemInterface {
    key: string;
    name: string;
    path: string;
    args: string;
    subTaskStatus: any;
    taskStatus: any;
    isEnabled: boolean;
    isSubtask: boolean;
}

type TaskItemProps = {
    isDirty: boolean,
    loanGuid: any,
    isMultipleSubtasks: boolean;
} & TaskItemInterface & RouteComponentProps<{}> & typeof TaskMenuActions.TaskMenuCreators;

const TaskItem = (props: TaskItemProps) => {
    const location = useLocation();
    const history = useHistory();
    const { isLoanFromEncompassFlag } = useSelector((state: ApplicationState) => state.loan as loanState);

    const getStatusConst = (status: number) => {
        switch (status) {
            case TaskStatusId.NotStarted:
                return <FontAwesomeIcon icon={faExclamationCircle} />;
            case TaskStatusId.InProgress:
                return <FontAwesomeIcon icon={faSpinner} />;
            case TaskStatusId.Completed:
                return <FontAwesomeIcon icon={faCheckCircle} />;
            case TaskStatusId.Failed:
                return <FontAwesomeIcon icon={faExclamation} />;
            default:
                return "";
        }
    }

    const assignClass = (status: number) => {
        switch (status) {
            case TaskStatusId.NotStarted:
                return `${styles.notStated}`;
            case TaskStatusId.InProgress:
                return `${styles.inProgress}`;
            case TaskStatusId.Completed:
                return `${styles.completed}`;
            case TaskStatusId.Failed:
                return `${styles.failed}`;
            default:
                return "";
        }
    }

    const currentRootNav = location.pathname.split("/")[1];
    const rootNav = props.path.split("/")[1];
    const currentQueryString = location.state?.args ?? "";
    const rootQueryString = props.args;

    let isTaskSelected = false;
    let isSubTaskSelected = false;

    if (props.isMultipleSubtasks || props.isSubtask) {
        isTaskSelected = currentRootNav === rootNav;
        isSubTaskSelected = currentRootNav === rootNav && currentQueryString === rootQueryString;
    } else {
        isTaskSelected = (currentRootNav === rootNav && currentQueryString === rootQueryString);
    }

    if ((currentRootNav === ProgressNavigationEnum.ManualAssetInformation || currentRootNav === ProgressNavigationEnum.AssetDetails) && rootNav === ProgressNavigationEnum.Asset) {
        isTaskSelected = true;
    } else if ((currentRootNav === ProgressNavigationEnum.AddReoProperty || currentRootNav === ProgressNavigationEnum.ReoPropertiesSummary) && rootNav === ProgressNavigationEnum.ReoProperties) {
        isTaskSelected = true;
    } else if ((currentRootNav === ProgressNavigationEnum.CreditReport || currentRootNav === ProgressNavigationEnum.CreditAuthorization) && rootNav === ProgressNavigationEnum.CreditInformation) {
        isTaskSelected = true;
    }

    const navigateRoute = (routeName: string, args: string) => {
        history.push({ pathname: routeName, state: { args: args, isClicked: true } })
    }
    
    return (
        <>
            {props.isEnabled && !isLoanFromEncompassFlag ? (!props.isSubtask ?
                <a className={isTaskSelected ? `${styles.active}` : `${styles.inactive}`}
                    onClick={() => navigateRoute(props.path, props.args)} >

                    <span className={isTaskSelected ? assignClass(TaskStatusId.InProgress) : assignClass(props.taskStatus)}>
                        {isTaskSelected ? getStatusConst(TaskStatusId.InProgress) : getStatusConst(props.taskStatus)}
                        {props.name}
                    </span>
                </a>
                :
                <a className={isSubTaskSelected ? `${styles.active}` : `${styles.inactive}`}
                    onClick={() => navigateRoute(props.path, props.args)}>

                    <span className={isSubTaskSelected ? assignClass(TaskStatusId.InProgress) : assignClass(props.subTaskStatus)}>
                        {isSubTaskSelected ? getStatusConst(TaskStatusId.InProgress) : getStatusConst(props.subTaskStatus)}
                        {props.name}
                    </span>
                </a>
            ) : <span className="subtask-disabled">{props.name}</span>}
        </>
    );
};

function mapStateToProps(state: ApplicationState) {
    const { taskMenu, accountInformation } = state;
    const isDirty = taskMenu?.isDirty;
    const loanGuid = accountInformation?.loanGuid;

    return {
        loanGuid,
        isDirty
    };
}

export default connect((state: ApplicationState) => mapStateToProps(state), TaskMenuActions.TaskMenuCreators)(TaskItem as any) as any;