import React from "react";
import styles from "./documentViewers.module.scss";

const ImageViewer = (props: { data: string }) => {
  return (
    <>
      <img className={styles.image} src={`data:image/png;base64,${props.data}`} />
    </>
  );
};

export default ImageViewer;
