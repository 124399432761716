export enum TaskStatus {
    NotStarted = "Not Started",
    InProgress = "In Progress",
    Completed = "Completed",
    Failed = "Failed"
}

export enum TaskStatusId {
    NotStarted = 1,
    InProgress = 2,
    Completed = 3,
    Failed = 4
}

export enum TaskItem {
    AccountInformation = 1,
    BorrowerInformation = 2,
    CoBorrowerInformation = 3,
    EmploymentAndIncomeInformation = 4,
    AdditionalIncome = 5,
    AssetInformation = 6,
    BorrowerREO = 7,
    Liability = 8,
    PropertyInformation = 9,
    GovernmentQuestions = 10,
    DemographicQuestions = 11,
    CreditInformation = 12,
    ContactPreference = 13,
    PreQualReviewScreen = 14
}

export enum SubTaskItem {
    AccountInformation = 1,
    BorrowerInformation = 2,
    CoBorrowerInformation = 3,
    BorrowerEmployment = 4,
    CoBorrowerEmployment = 5,
    BorrowerAdditionalIncome = 6,
    CoBorrowerAdditionalIncome = 7,
    AssetInformation = 8,
    BorrowerREO = 9,
    Liability = 10,
    PropertyInformation = 11,
    BorrowerGovernmentQuestions = 12,
    CoBorrowerGovernmentQuestions = 13,
    BorrowerDemographicQuestions = 14,
    CoBorrowerDemographicQuestions = 15,
    CreditInformation = 16,
    ContactPreference = 17,
    PreQualReviewScreen = 18,
    CoBorrowerAssetInformation = 19
}

export enum ProgressNavigationEnum {
    ManualAssetInformation = "manual-asset-information",
    AssetDetails = "asset-details",
    Asset = "asset",
    ReoProperties = "reo-properties",
    ReoPropertiesSummary = "reo-properties-summary",
    AddReoProperty = "add-reo-property",
    CreditInformation = "credit-information",
    CreditAuthorization = "credit-authorization",
    CreditReport = "credit-report",
    MilestoneProgress = "milestone-progress",
    SystematicLetter = "systematic-letter",
    BorrowerDocuments = "borrower-document-view",
    LoanOfficerDocumentView = "loan-officer-documents",
    BorrowerPreApprovalLetter = "Borrower-PreApproval-Letter"
}