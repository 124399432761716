import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { CountyDetail } from '../../../store/CountyLookUpStore';
import styles from "../../common/shared/zipCode.module.scss";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface CountyModelProps {
    countyDetails: CountyDetail[];
    cityName: string;
    stateCode: string;
    county: string;
    isOpen: boolean;
    onSelection: (countyDetail: CountyDetail) => void;
    onCancel: () => void;
    isLoading: boolean;
};

interface selectedCity {
    countyDetail: CountyDetail;
    index: number;
}

const CountySelection = (props: CountyModelProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [selection, changeSelection] = React.useState<selectedCity>();
    const [isOpen] = React.useState(props.isOpen);
    const rows: any[] = [];
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
    props.countyDetails.map((c, i) => {
        rows.push(
            <tr key={i} onClick={() => changeSelection({ ...selection, countyDetail: c, index: i })}
                className={selection && selection.index === i ? `${styles.cityTable} ${styles.activeCity}` : styles.cityTable}>
                <td onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(c.countyCity)}> {c.countyCity}</td>
                <td onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(c.countyName)}> {c.countyName}</td>
                <td onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(c.countyStateCode)}> {c.countyStateCode}</td>
            </tr>);
    });
    const handleShow = () => {
        props.onCancel()
    }
    const onSelect = () => {
        if (selection !== undefined) {
            props.onSelection(selection.countyDetail);
        }
    }
    return (
        <Modal isOpen={isOpen} className={styles.zipSelectionBox}>
            <ModalHeader onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Select a City')}>{"Select a City"}</ModalHeader>
            <ModalBody>
                <><p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Several cities are located in this Zip Code.Select a city from the list')}>Several cities are located in this Zip Code.Select a city from the list.</p>
                </>
                <div className={styles.tableContainer}>
                    <Table>
                        <thead>
                            <tr>
                                <th onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City Name')}>City Name</th>
                                <th onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('County')}>County</th>
                                <th onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <button color="primary" className={`common-button ${styles.saveButton}`} onClick={onSelect} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to select')}>Select</button>
                    <button color="secondary" className={`common-green-button ${styles.saveButton}`} onClick={handleShow} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Cancel')}>Cancel</button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
export default CountySelection as any;