import * as React from "react";
import { BorrowerType, Employment } from "../../store/employmentInformationStore";
import { EmploymentScreensNew, EmploymentInformationFields, ScreenDetails } from "./EmploymentInformationFields";
import styles from "./employmentInformation.module.scss";
import { useNavigation } from "hooks/useNavigation";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { BorrowerTypeEnum } from "../../common/BorrowerTypeEnum";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

export interface QuestionCardInterface {
    info: ScreenDetails
    changeScreen: React.Dispatch<React.SetStateAction<EmploymentScreensNew>>,
    UpdateEmploymentInformationProperty: (value: any, fieldName: string, borrowerType?: BorrowerType, itemId?: string) => {},
    updateBorrowerType: any,
    addNewEmployment: (data?: any, borrowerType?: BorrowerType) => {},
    getPreviousEmployment: any,
    UpdateBackClick: any,
    currentEmploymentList: any,
    employmentData: Employment,
    disabled: boolean,
    isEncompassDown: boolean,
    borrowerType: BorrowerType
}

type QuestionCardProps = QuestionCardInterface;

const QuestionCard = (props: QuestionCardProps) => {
    const navigation = useNavigation();
    const actions = { ...bindActionCreators({ ...TaskMenuCreators }, useDispatch()) };
    let disableContinueButton = true;
    if (props.info.value != undefined && !props.isEncompassDown) {
        disableContinueButton = false
    }

    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    React.useEffect(() => {
        if (props.info.value != undefined && !props.isEncompassDown) {
            disableContinueButton = false
        }
    }, [props.info]);

    const updateProperty = async (value: any) => {
        actions.setLoanIsDirty(true);
        props.info.fieldName.forEach(async function (item) {
            if (item == EmploymentInformationFields.CurrentEmploymentIndicator) {
                if (props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment1) {
                    props.UpdateEmploymentInformationProperty(false, item, props.info.borrowerType, props.info.id);
                }
                else {
                    props.UpdateEmploymentInformationProperty(true, item, props.info.borrowerType, props.info.id);
                }
            }
            else if (item == EmploymentInformationFields.Owner) {
                let keyword = props.info.borrowerType == BorrowerType.PrimaryBorrower ? BorrowerTypeEnum.Borrower : BorrowerTypeEnum.CoBorrower;
                props.UpdateEmploymentInformationProperty(keyword, item, props.info.borrowerType, props.info.id);
            }
            else if (item == EmploymentInformationFields.OwnershipInterestType) {
                if (value) {
                    props.UpdateEmploymentInformationProperty("GreaterThanOrEqualTo25Percent", item, props.info.borrowerType, props.info.id);
                } else {
                    props.UpdateEmploymentInformationProperty("LessThan25Percent", item, props.info.borrowerType, props.info.id);
                }
            }
            else if (item == EmploymentInformationFields.SelfEmployedIndicator) {
                if (!value) {
                    await props.UpdateEmploymentInformationProperty("", EmploymentInformationFields.OwnershipInterestType, props.info.borrowerType, props.info.id);
                    await props.UpdateEmploymentInformationProperty("", EmploymentInformationFields.EmploymentMonthlyIncomeAmount, props.info.borrowerType, props.info.id);
                    await props.UpdateEmploymentInformationProperty("", EmploymentInformationFields.BasePayAmount, props.info.borrowerType, props.info.id);
                }
                else if (props.info.currentScreen == EmploymentScreensNew.SelfEmployed && value == true) {
                    await props.UpdateEmploymentInformationProperty(null, EmploymentInformationFields.PreviousEmploymentDoesNotApply, props.info.borrowerType, props.info.id);
                }
                props.UpdateEmploymentInformationProperty(value, item, props.info.borrowerType, props.info.id);
            }
            else {
                props.UpdateEmploymentInformationProperty(value, item, props.info.borrowerType, props.info.id);
            }
        });
    }

    const moveFrwdBackwrd = (back: boolean) => {
        props.getPreviousEmployment(false);
        props.UpdateBackClick(false);
        if (back) {
            props.UpdateBackClick(true);
            if (props.info.currentScreen === EmploymentScreensNew.Veteran ||
                props.info.currentScreen === EmploymentScreensNew.ActiveMilitaryDuty ||
                props.info.currentScreen === EmploymentScreensNew.CurrentlyEmployed ||
                props.info.currentScreen === EmploymentScreensNew.SelfEmployed ||
                props.info.currentScreen === EmploymentScreensNew.OwnerShipShare ||
                props.info.currentScreen === EmploymentScreensNew.MonthlyIncome) {
                props.changeScreen(EmploymentScreensNew.Landing)
                return
            }
            else if ((
                props.info.currentScreen === EmploymentScreensNew.AdditionalGrossMonthlyIncome ||
                props.info.currentScreen === EmploymentScreensNew.PreviousGrossMonthlyIncome1) &&
                !props.info.value) {
                if (props.info.skipScreen) {
                    props.changeScreen(props.info.skipScreen);
                }
                if (props.info.prevScreen) {
                    props.changeScreen(props.info.prevScreen);
                }
            }
            else if (
                props.info.currentScreen === EmploymentScreensNew.AdditionalEmployment ||
                props.info.currentScreen === EmploymentScreensNew.AdditonalSelfEmployment ||
                props.info.currentScreen === EmploymentScreensNew.AdditonalSelfEmployment1
            ) {
                setTimeout(() => {
                    props.getPreviousEmployment(true);
                }, 100)

                let index = props.currentEmploymentList.findIndex((x: any) => x.employmentId === props.employmentData?.employmentId);
                let perviousEmployer = props.currentEmploymentList.length > 0 && props.currentEmploymentList[index - 1];
                if ((index - 1) > 1) {
                    props.changeScreen(EmploymentScreensNew.PastIncomeDetail1);
                }
                else if (props.info.currentScreen === EmploymentScreensNew.AdditonalSelfEmployment1 && perviousEmployer && perviousEmployer.currentEmploymentIndicator == true) {
                    props.changeScreen(EmploymentScreensNew.AdditionalEmployment);
                }
                else if (props.info.prevScreen) {
                    props.changeScreen(props.info.prevScreen);
                }
                if (props.info.currentScreen === EmploymentScreensNew.AdditionalEmployment) {
                    props.changeScreen(EmploymentScreensNew.IncomeDetail);
                }
            }
            else {
                if (props.info.prevScreen) {
                    props.changeScreen(props.info.prevScreen);
                }
            }
            if (props.info.currentScreen === EmploymentScreensNew.AdditionalGrossMonthlyIncome || props.info.currentScreen === EmploymentScreensNew.AdditonalSelfEmployment) {
                props.changeScreen(EmploymentScreensNew.AdditionalEmployment);
            }
            const employerIndex = (props.currentEmploymentList.findIndex((x: any) => x.employmentId == props.employmentData?.employmentId));
            const perviousEmployer1 = props.currentEmploymentList.length > 0 && props.currentEmploymentList[employerIndex - 1];

            if (props.info.currentScreen === EmploymentScreensNew.AdditionalGrossMonthlyIncome ||
                props.info.currentScreen === EmploymentScreensNew.PreviousGrossMonthlyIncome1) {
                setTimeout(() => {
                    props.getPreviousEmployment(true);
                }, 100)
                if ((employerIndex - 1) > 1) {
                    props.changeScreen(EmploymentScreensNew.PastIncomeDetail1);
                }
            }
            if (props.info.currentScreen === EmploymentScreensNew.AdditonalSelfEmployment1 &&
                perviousEmployer1 && perviousEmployer1.currentEmploymentIndicator == true) {
                props.changeScreen(EmploymentScreensNew.AdditionalEmployment);
            }
            else if (props.info.currentScreen === EmploymentScreensNew.AdditonalSelfEmployment || props.info.currentScreen === EmploymentScreensNew.AdditionalGrossMonthlyIncome && employerIndex <= 0) {
                props.changeScreen(EmploymentScreensNew.AdditionalEmployment);
            }
            else if (props.info.prevScreen) {
                props.changeScreen(EmploymentScreensNew.PastIncomeDetail1);
            }
            if (props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment1 && employerIndex == -1
            ) {
                props.changeScreen(EmploymentScreensNew.AdditionalEmployment);
            }
            else if (props.info.currentScreen == EmploymentScreensNew.AdditionalEmployment && employerIndex == -1) {
                props.changeScreen(EmploymentScreensNew.Veteran);
            }
            else if (props.info.currentScreen == EmploymentScreensNew.EmployerDetail) {
                props.changeScreen(EmploymentScreensNew.Landing);
            }
            else if ((props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment1 || props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment) && employerIndex <= 0) {

            }
        }
        else {
            if (!props.info.value && (props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment1 || props.info.currentScreen == EmploymentScreensNew.PreviousGrossMonthlyIncome1)) {
                if (props.info.nextScreen) {
                    props.changeScreen(EmploymentScreensNew.PastEmployerDetail1);
                    return;
                }

            } else
                if (props.info.value && (props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment1 || props.info.currentScreen == EmploymentScreensNew.PreviousGrossMonthlyIncome1)) {
                    props.changeScreen(EmploymentScreensNew.PastEmployerDetail1);
                    return;
                }
            if (!props.info.value && (props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment)) {
                if (props.info.nextScreen) {
                    props.changeScreen(EmploymentScreensNew.PastEmployerDetail);
                }
            }
            else if (props.info.value && (props.info.currentScreen == EmploymentScreensNew.AdditionalGrossMonthlyIncome || props.info.currentScreen === EmploymentScreensNew.PreviousGrossMonthlyIncome1)) {
                if (props.info.nextScreen) {
                    props.changeScreen(EmploymentScreensNew.PastEmployerDetail);
                }
            }
            else if (props.info.value && props.info.currentScreen == EmploymentScreensNew.PreviousGrossMonthlyIncome1) {
                if (props.info.nextScreen) {
                    props.changeScreen(EmploymentScreensNew.PastEmployerDetail);
                }
            }
            else if ((props.info.currentScreen == EmploymentScreensNew.SelfEmployed ||
                props.info.currentScreen == EmploymentScreensNew.AdditonalSelfEmployment1 ||
                props.info.currentScreen == EmploymentScreensNew.CurrentlyEmployed ||
                props.info.currentScreen == EmploymentScreensNew.AdditionalEmployment)
                && !props.info.value) {
                if (props.info.skipScreen) {
                    props.changeScreen(props.info.skipScreen);
                }
            }
            else {
                if (props.info.nextScreen) {
                    props.changeScreen(props.info.nextScreen);
                }
            }
            if (props.info.currentScreen === EmploymentScreensNew.IncomeDetail || props.info.currentScreen === EmploymentScreensNew.MonthlyIncome) {
                props.changeScreen(EmploymentScreensNew.Finished);
            }
        }
    }

    const onBack = () => {
        moveFrwdBackwrd(true);
    };

    const onContinue = () => {
        moveFrwdBackwrd(false)
    };

    return (
        <div className={styles.employementinfo}>
            <div className="question-row">
                <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.info.header)}>{props.info.header}</div>
                {
                    (props.info.currentScreen == EmploymentScreensNew.OwnerShipShare) &&
                    <div className="answerItem">
                        <button disabled={props.disabled} className={`option-button ${props.info.value == "GreaterThanOrEqualTo25Percent" ? "active" : ""}`} onClick={(e: any) => {
                            updateProperty(true)
                        }} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to yes')}>Yes</button>
                        <button disabled={props.disabled} className={`option-button ${props.info.value == "LessThan25Percent" ? "active" : ""}`} onClick={(e: any) => {
                            updateProperty(false)
                        }} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to no')}>No</button>
                    </div>
                }
                {
                    !(
                        props.info.currentScreen == EmploymentScreensNew.MonthlyIncome ||
                        props.info.currentScreen == EmploymentScreensNew.AdditionalGrossMonthlyIncome ||
                    
                        props.info.currentScreen == EmploymentScreensNew.PreviousGrossMonthlyIncome1 ||
                        props.info.currentScreen == EmploymentScreensNew.OwnerShipShare) &&
                    <div className="answerItem">
                        <button disabled={props.disabled} className={`option-button ${props.info.value == true ? "active" : ""}`} onClick={(e: any) => { updateProperty(true) }} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to yes')}>Yes</button>
                        <button disabled={props.disabled} className={`option-button ${props.info.value == false ? "active" : ""}`} onClick={(e: any) => { updateProperty(false) }} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to no')}>No</button>
                    </div>
                }
                {
                        (props.info.currentScreen == EmploymentScreensNew.MonthlyIncome ||
                        props.info.currentScreen == EmploymentScreensNew.AdditionalGrossMonthlyIncome ||
                        props.info.currentScreen == EmploymentScreensNew.PreviousGrossMonthlyIncome1) &&
                    <div className="answerItem">
                        <NumberFormatInput
                            disabled={props.disabled}
                            name={props.info.fieldName[0]}
                            className={styles.incomeInput}
                            value={props.info.value ?? ""}
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                updateProperty(values.value);
                            }}
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                        />
                    </div>
                }
            </div>

            {navigation.SetBackOnClick(
                () => onBack()
            )}
            {navigation.ShowContinue(
                () => onContinue(),
                disableContinueButton
            )}
        </div>
    )
}

export default QuestionCard as any;