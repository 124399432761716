import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./employmentInformation.module.scss";
import { EmploymentInformationState, Borrower, BorrowerType, Employment } from "../../store/employmentInformationStore";
import * as EmploymentInformationActions from "../../actions/employmentInformationActions";
import { ApplicationState } from "../../store";
import QuestionCard from "./QuestionCardNew";
import EmployerDetail from "./EmployerDetail";
import IncomeDetail from "./IncomeDetail";
import LandingScreen from "./LandingScreen";
import CurrentEmployerDetail from "./CurrentEmployerDetail";
import CurrentIncomeDetail from "./CurrentIncomeDetail";
import AdditionalInformationLanding from "./AdditionalInformationLanding";
import { EmploymentScreensNew, borrowerField, DummyData, EmploymentInformationFields } from "./EmploymentInformationFields";
import { GetScreenDetail } from "./ScreenDetails";
import { get } from "lodash";
import { isNullOrWhiteSpace, toDate, dateDiff, isValidDate } from "../../common/helper/formatHelper";
import { useNavigation } from "../../hooks/useNavigation";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type EmploymentItemProps = EmploymentInformationState &
{
    borrowerType: BorrowerType,
    updateCurrentBorrower: React.Dispatch<React.SetStateAction<BorrowerType>>,
    borrowerData: Borrower,
    updateBorrowerComplete: React.Dispatch<React.SetStateAction<boolean>>,
    updateCoBorrowerComplete: React.Dispatch<React.SetStateAction<boolean>>,
    disabled: boolean,
    isEncompassDown: boolean
} & typeof EmploymentInformationActions.employmentInformationActionCreators

const EmploymentItem = (props: EmploymentItemProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [currentScreen, changeScreen] = useState(EmploymentScreensNew.Landing);
    const [employmentItemId, updateEmploymentItemId] = useState("");
    const [employmentIndex, setEmploymentIndex] = useState(0);
    const [header, updateHeader] = useState(props.borrowerData.name + " Employment Information");
    const [previousEmployment, getPreviousEmployment] = useState(false);
    const [isBackClick, UpdateBackClick] = useState(false);
    let currentEmploymentList = props.borrowerData.employments;
    let currentEmployment: Employment;
    let info = GetScreenDetail(currentScreen);
    let veteranInfo = GetScreenDetail(EmploymentScreensNew.Veteran);
    let activeMilitaryDutyInfo = GetScreenDetail(EmploymentScreensNew.ActiveMilitaryDuty);
    let currentlyEmployedInfo = GetScreenDetail(EmploymentScreensNew.CurrentlyEmployed);
    let selfEmployedInfo = GetScreenDetail(EmploymentScreensNew.SelfEmployed);
    let ownerShipShareInfo = GetScreenDetail(EmploymentScreensNew.OwnerShipShare);
    let monthlyIncomeInfo = GetScreenDetail(EmploymentScreensNew.MonthlyIncome);
    let additonalSelfEmploymentInfo = GetScreenDetail(EmploymentScreensNew.AdditonalSelfEmployment);
    let additionalGrossMonthlyIncomeInfo = GetScreenDetail(EmploymentScreensNew.AdditionalGrossMonthlyIncome);
    let additonalSelfEmployment1Info = GetScreenDetail(EmploymentScreensNew.AdditonalSelfEmployment1);
    let previousGrossMonthlyIncome1Info = GetScreenDetail(EmploymentScreensNew.PreviousGrossMonthlyIncome1);
    const navigation = useNavigation();
    const [disabled, setDisabled] = useState(false);
    const [message, setValidationMessage] = React.useState(false);

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    useEffect(() => {
        currentEmploymentList = props.borrowerData.employments;
        if (isNullOrWhiteSpace(employmentItemId) && currentEmploymentList && currentEmploymentList?.length > 0 &&
            currentScreen !== EmploymentScreensNew.Finished) {
            // get 0th index
            currentEmployment = currentEmploymentList[0];
            if (currentEmployment?.id) {
                updateEmploymentItemId(currentEmployment.id);
            }
            updateHeader(props.borrowerData.name + " Employment Information");
        }
        else if (employmentItemId !== "" && currentEmploymentList && currentEmploymentList?.length > 0) {
            // get particular
            const index = currentEmploymentList.findIndex(i => i.id === employmentItemId);
            currentEmployment = currentEmploymentList[index];
            updateEmploymentItemId(currentEmployment?.id);
            index == (0 || 1) ? updateHeader(props.borrowerData.name + " Employment Information")
                : updateHeader(props.borrowerData.name + " Additional Employment Information");
            setEmploymentIndex(index);
        }
        else if (currentEmploymentList?.length === 0) {
            updateEmploymentItemId("");
            updateHeader(props.borrowerData.name + " Employment Information")
        }
    }, [props.borrowerData]);

    useEffect(() => {
        changeScreen(EmploymentScreensNew.Landing);
    }, [props.borrowerType]);

    useEffect(() => {
        if (currentScreen === EmploymentScreensNew.Finished) {
            updateEmploymentItemId("");
            if (props.borrowerType === BorrowerType.PrimaryBorrower) {
                props.updateBorrowerComplete(true);
            }
            else if (props.borrowerType === BorrowerType.CoBorrower) {
                props.updateCoBorrowerComplete(true);
            }
        }
        else if (currentScreen === EmploymentScreensNew.AdditonalSelfEmployment1) {
            updateEmploymentItemId("");
            let index = employmentIndex;
            if (!isBackClick) {
                index = employmentIndex + 1;
            }

            let emp = currentEmploymentList[index];
            if (emp === undefined) {
                DummyData.currentEmploymentIndicator = false;
                props.AddEmploymentItem("", DummyData, props.borrowerType);
                index = currentEmploymentList?.findIndex(i => i.id == DummyData.id);
            }
            setEmploymentIndex(index);

            // update currentemployment
            if (currentEmploymentList && index) {
                currentEmployment = currentEmploymentList[index];
                if (currentEmployment?.id) {
                    updateEmploymentItemId(currentEmployment.id);
                }
                index == (0 || 1) ? updateHeader(props.borrowerData.name + " Employment Information")
                    : updateHeader(props.borrowerData.name + " Additional Employment Information");
            }
        }
        else if (currentScreen === EmploymentScreensNew.AdditonalSelfEmployment) {
            let index = employmentIndex;
            updateEmploymentItemId("");
            if (!isBackClick) {
                index = employmentIndex + 1;
                setEmploymentIndex(index);
            }
            let emp = currentEmploymentList[index];
            if (emp === undefined) {
                DummyData.currentEmploymentIndicator = false;
                DummyData.selfEmployedIndicator = undefined;
                props.AddEmploymentItem("", DummyData, props.borrowerType);
                index = currentEmploymentList?.findIndex(i => i.id == DummyData.id);
            }

            setEmploymentIndex(index); // update currentemployment
            if (currentEmploymentList && index) {
                currentEmployment = currentEmploymentList[index];
                if (currentEmployment?.id) {
                    updateEmploymentItemId(currentEmployment.id);
                }
                index == (0 || 1) ? updateHeader(props.borrowerData.name + " Employment Information")
                    : updateHeader(props.borrowerData.name + " Additional Employment Information");
            }
        }
        else if ((isNullOrWhiteSpace(employmentItemId)) && currentEmploymentList && currentEmploymentList?.length === 0 && currentScreen === EmploymentScreensNew.SelfEmployed) {
            props.AddEmploymentItem("", DummyData, props.borrowerType);
            // update currentemployment
            const index = currentEmploymentList.findIndex(i => i.id == DummyData.id);
            currentEmployment = currentEmploymentList[index];
            if (currentEmployment?.id) {
                updateEmploymentItemId(currentEmployment.id);
            }
            setEmploymentIndex(index);
            index == (0 || 1) ? updateHeader(props.borrowerData.name + " Employment Information")
                : updateHeader(props.borrowerData.name + " Additional Employment Information");
        }
        else if (currentScreen == EmploymentScreensNew.EmployerDetail || currentScreen == EmploymentScreensNew.IncomeDetail) {
            if (props.borrowerType == BorrowerType.PrimaryBorrower) {
                const currentEmployerData = props.borrower.employments.filter(x => x.currentEmploymentIndicator == true && x.owner == "Borrower");
                if (currentEmployerData.length == 2) {
                    setEmploymentIndex(1);
                    currentEmployment = currentEmployerData[1];
                    updateEmploymentItemId(currentEmployment.id);
                }
                else {
                    setEmploymentIndex(0);
                    currentEmployment = currentEmployerData[0];
                    updateEmploymentItemId(currentEmployment.id);
                }
            }
            else {
                const currentEmployerData = props.coBorrower.employments.filter(x => x.currentEmploymentIndicator == true && x.owner == "CoBorrower");
                if (currentEmployerData.length == 2) {
                    setEmploymentIndex(currentEmployerData[1].employmentId);
                    currentEmployment = currentEmployerData[1];
                    updateEmploymentItemId(currentEmployment.id);
                }
                else {
                    setEmploymentIndex(currentEmployerData[0].employmentId);
                    currentEmployment = currentEmployerData[0];
                    updateEmploymentItemId(currentEmployment.id);
                }
            }

            if (!isRequiredPriorEmployment()) {
                props.UpdateEmploymentInformationProperty(null, EmploymentInformationFields.PreviousEmploymentDoesNotApply, props.borrowerType, currentEmployment.id);
            }
        }
    }, [currentScreen]);

    useEffect(() => {
        if (previousEmployment) {
            if (currentEmployment && currentEmployment.employmentId > 0 && currentEmploymentList && currentEmploymentList?.length > 1) {
                if (currentEmployment) {
                    let index = currentEmploymentList.findIndex(x => x.employmentId === currentEmployment.employmentId);
                    currentEmployment = currentEmploymentList[index - 1];
                    if (currentEmployment && currentEmployment?.id) {
                        updateEmploymentItemId(currentEmployment.id);
                    }
                    if (isBackClick) {
                        setEmploymentIndex(index - 1);
                    }
                }
            }
        }
    }, [previousEmployment]);

    const GetDetails = (screen: EmploymentScreensNew) => {       

        SetInfo(info);
        SetInfo(veteranInfo);
        SetInfo(activeMilitaryDutyInfo);
        SetInfo(currentlyEmployedInfo);
        SetInfo(selfEmployedInfo);
        SetInfo(ownerShipShareInfo);
        SetInfo(monthlyIncomeInfo);
        SetInfo(additonalSelfEmploymentInfo);
        SetInfo(additionalGrossMonthlyIncomeInfo);
        SetInfo(additonalSelfEmployment1Info);
        SetInfo(previousGrossMonthlyIncome1Info);

        if (screen === EmploymentScreensNew.Landing) {
            return <>
                <LandingScreen
                    borrowerName={props.borrowerData.name}
                    borrowerType={props.borrowerData.borrowerType}
                    info={info}
                    changeScreen={changeScreen}
                    isEncompassDown={props.isEncompassDown}
                >
                </LandingScreen>
            </>;
        }

        if (screen === EmploymentScreensNew.PreviousAdditionalInformationLanding) {
            return <div>
                <AdditionalInformationLanding
                    info={info}
                    changeScreen={changeScreen}
                    isEncompassDown={props.isEncompassDown}
                >
                </AdditionalInformationLanding>
            </div>;
        }

        if (screen === EmploymentScreensNew.EmployerDetail) {
            const currentEmploymentDetails = props.borrowerData.employments.filter(employment => employment.currentEmploymentIndicator === true);
            const rows = <>
                {currentEmploymentDetails.length <= 2 &&
                    currentEmploymentDetails?.map((item, i) => {
                        return (
                            <div className={styles.employerFormbox} key={i}>
                                <h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                                {
                                    (currentEmploymentDetails.length > 1 && i == 1) &&
                                    <span className={styles.deleteButton}>
                                        <i className="fa fa-times-circle" onClick={() => deleteCurrentEmployer(item.id)}></i>
                                    </span>
                                }
                                <CurrentEmployerDetail
                                    disabled={props.disabled}
                                    employmentData={item}
                                    info={info}
                                    changeScreen={changeScreen}
                                    UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                                    UpdateBackClick={UpdateBackClick}
                                    currentEmploymentList={currentEmploymentList}
                                    employmentItemId={employmentItemId}
                                    isEncompassDown={props.isEncompassDown}
                                    setDisabled={setDisabled}
                                    key={i}
                                >
                                </CurrentEmployerDetail>
                            </div>
                        );
                    })
                }
            </>
            let additionalIncomePage =
                <div className="infographic-side-layout-container employerDetails">
                    <div className={styles.employerFormContainer}>
                        {rows}
                        {
                            currentEmploymentDetails.length < 2 && <div className={styles.addCurrentEmployer} >
                                <button className={styles.addMoreBtn} onClick={() => addNewItem()} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to Add current employer')}
>
                                    <i className="fa fa-plus-circle"> </i>
                                    <span>Add current employer</span>
                                </button>
                                {message &&
                                    <div className={styles.invalidField}>Please fill the required fields.</div>
                                }
                            </div>
                        }
                    </div>
                    {navigation.SetBackOnClick(
                        () => onBack()
                    )}
                    {navigation.ShowContinue(
                        () => onContinue(),
                        disabled
                    )}
                </div>
            return (
                additionalIncomePage
            );
        }
        if (screen === EmploymentScreensNew.PastEmployerDetail ||
            screen === EmploymentScreensNew.PastEmployerDetail1) {
            return (
                <div>
                    <h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                    <EmployerDetail
                        disabled={props.disabled}
                        employmentData={currentEmployment}
                        info={info}
                        changeScreen={changeScreen}
                        UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                        UpdateBackClick={UpdateBackClick}
                        currentEmploymentList={currentEmploymentList}
                        employmentItemId={employmentItemId}
                        isEncompassDown={props.isEncompassDown}
                    >
                    </EmployerDetail>
                </div>);
        }

        if (screen === EmploymentScreensNew.IncomeDetail) {
            const currentEmploymentIncomeDetails = props.borrowerData.employments.filter(employment => employment.currentEmploymentIndicator === true);
            const rows = <>
                {
                    currentEmploymentIncomeDetails.length <= 2 &&
                    currentEmploymentIncomeDetails?.map((item, i) => {
                        return (<div className={styles.infographicSideLayoutContainer} key={i}>
                            <h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                            <CurrentIncomeDetail
                                disabled={props.disabled}
                                employmentData={item}
                                info={info}
                                changeScreen={changeScreen}
                                UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                                UpdateBackClick={UpdateBackClick}
                                isEncompassDown={props.isEncompassDown}
                                key={i}
                                empList={currentEmploymentIncomeDetails}
                            >
                            </CurrentIncomeDetail>
                        </div>
                        );
                    })
                }
            </>
            let additionalIncomePage =
                <div className={styles.incomeDetailBg}>
                    {rows}
                </div>
            return (
                additionalIncomePage
            );
        }
        else if (
            screen === EmploymentScreensNew.PastIncomeDetail ||
            screen === EmploymentScreensNew.PastIncomeDetail1) {
            return (
                <div><h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                    <IncomeDetail
                        disabled={props.disabled}
                        employmentData={currentEmployment}
                        info={info}
                        changeScreen={changeScreen}
                        UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                        UpdateBackClick={UpdateBackClick}
                        isEncompassDown={props.isEncompassDown}
                    >
                    </IncomeDetail>
                </div>);
        }
        else if (
            currentScreen == EmploymentScreensNew.AdditonalSelfEmployment ||
            currentScreen == EmploymentScreensNew.AdditionalGrossMonthlyIncome) {
            return (
                <div>
                    <h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                    <QuestionCard
                        disabled={props.disabled}
                        info={additonalSelfEmploymentInfo}
                        changeScreen={changeScreen}
                        UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                        updateBorrowerType={props.updateCurrentBorrower}
                        addNewEmployment={props.AddEmploymentItem}
                        getPreviousEmployment={getPreviousEmployment}
                        UpdateBackClick={UpdateBackClick}
                        currentEmploymentList={currentEmploymentList}
                        employmentData={currentEmployment}
                        isEncompassDown={props.isEncompassDown}
                        borrowerType={props.borrowerType} />
                    {
                        (additonalSelfEmploymentInfo != undefined && additonalSelfEmploymentInfo.value == true) &&
                        <QuestionCard
                            disabled={props.disabled}
                            info={additionalGrossMonthlyIncomeInfo}
                            changeScreen={changeScreen}
                            UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                            updateBorrowerType={props.updateCurrentBorrower}
                            addNewEmployment={props.AddEmploymentItem}
                            getPreviousEmployment={getPreviousEmployment}
                            UpdateBackClick={UpdateBackClick}
                            currentEmploymentList={currentEmploymentList}
                            employmentData={currentEmployment}
                            isEncompassDown={props.isEncompassDown}
                            borrowerType={props.borrowerType}
                        ></QuestionCard>
                    }
                </div>
            );
        }
        else if (currentScreen == EmploymentScreensNew.AdditonalSelfEmployment1 ||
            currentScreen == EmploymentScreensNew.PreviousGrossMonthlyIncome1) {
            return (
                <div>
                    <h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                    <QuestionCard
                        disabled={props.disabled}
                        info={additonalSelfEmployment1Info}
                        changeScreen={changeScreen}
                        UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                        updateBorrowerType={props.updateCurrentBorrower}
                        addNewEmployment={props.AddEmploymentItem}
                        getPreviousEmployment={getPreviousEmployment}
                        UpdateBackClick={UpdateBackClick}
                        currentEmploymentList={currentEmploymentList}
                        employmentData={currentEmployment}
                        isEncompassDown={props.isEncompassDown}
                        borrowerType={props.borrowerType} />

                    {
                        (additonalSelfEmployment1Info != undefined && additonalSelfEmployment1Info.value == true) &&
                        <QuestionCard
                            disabled={props.disabled}
                            info={previousGrossMonthlyIncome1Info}
                            changeScreen={changeScreen}
                            UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                            updateBorrowerType={props.updateCurrentBorrower}
                            addNewEmployment={props.AddEmploymentItem}
                            getPreviousEmployment={getPreviousEmployment}
                            UpdateBackClick={UpdateBackClick}
                            currentEmploymentList={currentEmploymentList}
                            employmentData={currentEmployment}
                            isEncompassDown={props.isEncompassDown}
                            borrowerType={props.borrowerType}
                        ></QuestionCard>
                    }
                </div>
            );
        }
        else if (currentScreen == EmploymentScreensNew.Veteran ||
            currentScreen == EmploymentScreensNew.ActiveMilitaryDuty ||
            currentScreen == EmploymentScreensNew.CurrentlyEmployed ||
            currentScreen == EmploymentScreensNew.SelfEmployed ||
            currentScreen == EmploymentScreensNew.OwnerShipShare ||
            currentScreen == EmploymentScreensNew.MonthlyIncome) {
            return (
                <div>
                    <h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                    <QuestionCard
                        disabled={props.disabled}
                        info={veteranInfo}
                        changeScreen={changeScreen}
                        UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                        updateBorrowerType={props.updateCurrentBorrower}
                        addNewEmployment={props.AddEmploymentItem}
                        getPreviousEmployment={getPreviousEmployment}
                        UpdateBackClick={UpdateBackClick}
                        currentEmploymentList={currentEmploymentList}
                        employmentData={currentEmployment}
                        isEncompassDown={props.isEncompassDown}
                        borrowerType={props.borrowerType} />
                    {
                        (veteranInfo != undefined && (veteranInfo.value == true || veteranInfo.value == false)) &&
                        <QuestionCard
                            disabled={props.disabled}
                            info={activeMilitaryDutyInfo}
                            changeScreen={changeScreen}
                            UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                            updateBorrowerType={props.updateCurrentBorrower}
                            addNewEmployment={props.AddEmploymentItem}
                            getPreviousEmployment={getPreviousEmployment}
                            UpdateBackClick={UpdateBackClick}
                            currentEmploymentList={currentEmploymentList}
                            employmentData={currentEmployment}
                            isEncompassDown={props.isEncompassDown}
                            borrowerType={props.borrowerType} />
                    }
                    {
                        (activeMilitaryDutyInfo != undefined && (activeMilitaryDutyInfo.value == true || activeMilitaryDutyInfo.value == false)) &&
                        <QuestionCard
                            disabled={props.disabled}
                            info={currentlyEmployedInfo}
                            changeScreen={changeScreen}
                            UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                            updateBorrowerType={props.updateCurrentBorrower}
                            addNewEmployment={props.AddEmploymentItem}
                            getPreviousEmployment={getPreviousEmployment}
                            UpdateBackClick={UpdateBackClick}
                            currentEmploymentList={currentEmploymentList}
                            employmentData={currentEmployment}
                            isEncompassDown={props.isEncompassDown}
                            borrowerType={props.borrowerType} />
                    }
                    { (currentlyEmployedInfo != undefined && currentlyEmployedInfo.value == true) &&
                        <QuestionCard
                            disabled={props.disabled}
                            info={selfEmployedInfo}
                            changeScreen={changeScreen}
                            UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                            updateBorrowerType={props.updateCurrentBorrower}
                            addNewEmployment={props.AddEmploymentItem}
                            getPreviousEmployment={getPreviousEmployment}
                            UpdateBackClick={UpdateBackClick}
                            currentEmploymentList={currentEmploymentList}
                            employmentData={currentEmployment}
                            isEncompassDown={props.isEncompassDown}
                            borrowerType={props.borrowerType} />
                    }
                    { (currentlyEmployedInfo != undefined && currentlyEmployedInfo.value == true) && (selfEmployedInfo != undefined && selfEmployedInfo.value == true) &&
                        <QuestionCard
                            disabled={props.disabled}
                            info={ownerShipShareInfo}
                            changeScreen={changeScreen}
                            UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                            updateBorrowerType={props.updateCurrentBorrower}
                            addNewEmployment={props.AddEmploymentItem}
                            getPreviousEmployment={getPreviousEmployment}
                            UpdateBackClick={UpdateBackClick}
                            currentEmploymentList={currentEmploymentList}
                            employmentData={currentEmployment}
                            isEncompassDown={props.isEncompassDown}
                            borrowerType={props.borrowerType} />
                    }
                    { (currentlyEmployedInfo != undefined && currentlyEmployedInfo.value == true) && (selfEmployedInfo != undefined && selfEmployedInfo.value == true) &&
                        (ownerShipShareInfo != undefined && (ownerShipShareInfo.value == "GreaterThanOrEqualTo25Percent" || ownerShipShareInfo.value == "LessThan25Percent")) &&
                        <QuestionCard
                            disabled={props.disabled}
                            info={monthlyIncomeInfo}
                            changeScreen={changeScreen}
                            UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                            updateBorrowerType={props.updateCurrentBorrower}
                            addNewEmployment={props.AddEmploymentItem}
                            getPreviousEmployment={getPreviousEmployment}
                            UpdateBackClick={UpdateBackClick}
                            currentEmploymentList={currentEmploymentList}
                            employmentData={currentEmployment}
                            isEncompassDown={props.isEncompassDown}
                            borrowerType={props.borrowerType} />
                    }
                </div>
            );
        }
        else if (currentScreen !== EmploymentScreensNew.Finished) {
            return (
                <div>
                    <h1 className={styles.hide} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(header)}>{header}</h1>
                    <QuestionCard
                        disabled={props.disabled}
                        info={info}
                        changeScreen={changeScreen}
                        UpdateEmploymentInformationProperty={props.UpdateEmploymentInformationProperty}
                        updateBorrowerType={props.updateCurrentBorrower}
                        addNewEmployment={props.AddEmploymentItem}
                        getPreviousEmployment={getPreviousEmployment}
                        UpdateBackClick={UpdateBackClick}
                        currentEmploymentList={currentEmploymentList}
                        employmentData={currentEmployment}
                        isEncompassDown={props.isEncompassDown}
                        borrowerType={props.borrowerType}
                    ></QuestionCard>
                </div>
            );
        }
    }

    const onBack = () => {
        moveFrwdBackwrd(true)
    };

    const onContinue = () => {
        moveFrwdBackwrd(false)
    };

    const isRequiredPriorEmployment = () => {
        let isRequired = false;
        let currentEmployers = currentEmploymentList.filter(x => x.currentEmploymentIndicator == true).slice()
            .sort((a, b) => Date.parse(a.employmentStartDate) - Date.parse(b.employmentStartDate));
        if (currentEmployers.length > 0) {
            let months = dateDiff(currentEmployers[0].employmentStartDate);
            if (months < 24) {
                isRequired = true;
            }
        }
        return isRequired;
    }

    const moveFrwdBackwrd = (back: boolean) => {
        UpdateBackClick(false);
        if (back) {
            UpdateBackClick(true);
            if (info?.prevScreen === EmploymentScreensNew.SelfEmployed) {
                changeScreen(info?.prevScreen);
            } else if (info?.prevScreen && currentEmployment.selfEmployedIndicator) {
                changeScreen(info?.prevScreen);
            } else {
                changeScreen(EmploymentScreensNew.AdditonalSelfEmployment);
            }
        }
        else {
            if (info?.nextScreen) {
                changeScreen(info?.nextScreen);
            }
        }
    }

    const validateCurrentEmploymentData = (currentEmployerData: Employment[]): boolean => {
        let disabled = true;
        if (currentEmployerData && currentEmployerData.length > 0) {
            const currentEmployment1 = currentEmployerData[0];
            if (
                isValidDate(toDate(currentEmployment1?.employmentStartDate)) &&
                !isNullOrWhiteSpace(currentEmployment1?.employerName) &&
                !isNullOrWhiteSpace(currentEmployment1?.positionDescription) &&
                !isNullOrWhiteSpace(currentEmployment1?.urlA2020StreetAddress) &&
                !isNullOrWhiteSpace(currentEmployment1?.addressState) &&
                !isNullOrWhiteSpace(currentEmployment1?.addressCity) &&
                !isNullOrWhiteSpace(currentEmployment1?.addressPostalCode) &&
                !(currentEmployment1?.addressPostalCode && (currentEmployment1?.addressPostalCode.length < 5))
            ) {
                disabled = false;
            }
        }
        return disabled;
    }

    const addNewItem = () => {
        let currentEmployerData: Employment[];

        if (props.borrowerType === BorrowerType.PrimaryBorrower) {
            currentEmployerData = props.borrower.employments.filter(x => x.currentEmploymentIndicator == true);
        } else {
            currentEmployerData = props.coBorrower.employments.filter(x => x.currentEmploymentIndicator == true);
        }

        if (!validateCurrentEmploymentData(currentEmployerData)) {
            setValidationMessage(false);
            updateEmploymentItemId("");
            let index = props.borrowerType === BorrowerType.PrimaryBorrower ? props.borrower.employments.length : props.coBorrower.employments.length;
            let emp = currentEmploymentList[index];
            if (emp === undefined) {
                DummyData.currentEmploymentIndicator = true;
                DummyData.selfEmployedIndicator = currentEmployerData[0].selfEmployedIndicator;
                props.AddEmploymentItem("", DummyData, props.borrowerType);
                props.ReIndexingEmployment(props.borrowerType === BorrowerType.PrimaryBorrower ? props.borrower.employments : props.coBorrower.employments, props.borrowerType);
                index = currentEmploymentList?.findIndex(i => i.id == DummyData.id);
            }
            setEmploymentIndex(index);
            currentEmployment = currentEmploymentList[index];
            updateEmploymentItemId(currentEmployment?.id);
        } else {
            setValidationMessage(true);
        }
    }

    const deleteCurrentEmployer = (id: string) => {
        let currentEmployerData: Employment[];

        if (props.borrowerType === BorrowerType.PrimaryBorrower) {
            currentEmployerData = props.borrower.employments.filter(x => x.currentEmploymentIndicator == true);
        } else {
            currentEmployerData = props.coBorrower.employments.filter(x => x.currentEmploymentIndicator == true);
        }

        setDisabled(false);
        props.DeleteEmploymentItem(id, props.borrowerType);
        currentEmployment = currentEmployerData[0];
        props.ReIndexingEmployment(props.borrowerType === BorrowerType.PrimaryBorrower ? props.borrower.employments : props.coBorrower.employments, props.borrowerType);
        setEmploymentIndex(0);
        currentEmployment = currentEmploymentList[0];
        updateEmploymentItemId(currentEmployment?.id);
    }

    const SetInfo = (info: any) => {
        if (info) {
            if ((isNullOrWhiteSpace(employmentItemId)) && currentEmploymentList && currentEmploymentList?.length === 0 && info.currentScreen === EmploymentScreensNew.SelfEmployed) {
                props.AddEmploymentItem("", DummyData, props.borrowerType);
                // update currentemployment
                const index = currentEmploymentList.findIndex(i => i.id == DummyData.id);
                currentEmployment = currentEmploymentList[index];
                if (currentEmployment?.id) {
                    updateEmploymentItemId(currentEmployment.id);
                }
                setEmploymentIndex(index);
                index == (0 || 1) ? updateHeader(props.borrowerData.name + " Employment Information")
                    : updateHeader(props.borrowerData.name + " Additional Employment Information");
            }

            const currentEmploymentDetails = props.borrowerData.employments.filter(employment => employment.currentEmploymentIndicator === true);
            if (employmentItemId && currentEmploymentList && !currentEmployment) {
                const index = currentEmploymentList.findIndex(i => i.id == employmentItemId);
                currentEmployment = currentEmploymentList[index];
            }

            info.borrowerType = props.borrowerType;
            info.id = employmentItemId;
            let fieldName = info?.fieldName[0];
            if (fieldName && borrowerField.includes(fieldName)) {
                info.value = get(props.borrowerData, fieldName);
            }
            else {
                info.value = get(currentEmployment, fieldName);
            }
            if (currentScreen === EmploymentScreensNew.IncomeDetail) {
                info.value = (props.borrowerData.veteran || props.borrowerData.activeMilitaryDuty);
                if (currentEmploymentDetails.length > 1) {
                    if (isRequiredPriorEmployment()) {
                        info.nextScreen = EmploymentScreensNew.PreviousAdditionalInformationLanding;
                    }
                    else {
                        info.nextScreen = EmploymentScreensNew.Finished;
                    }
                }
                else {
                    if (currentEmployment?.needAdditionalEmployment === true) {
                        info.nextScreen = EmploymentScreensNew.PreviousAdditionalInformationLanding;
                    }
                    else {
                        info.nextScreen = EmploymentScreensNew.Finished;
                    }
                }
            }
            else if (currentScreen == EmploymentScreensNew.PastIncomeDetail) {
                info.value = (props.borrowerData.veteran || props.borrowerData.activeMilitaryDuty);
                if (currentEmployment?.needAdditionalEmployment === true) {
                    info.nextScreen = EmploymentScreensNew.AdditonalSelfEmployment1;
                } else {
                    info.nextScreen = EmploymentScreensNew.Finished;
                }
            }
            else if (currentScreen === EmploymentScreensNew.Finished) {
                if (props.borrowerData.currentEmploymentDoesNotApply) {
                    if (currentEmployment?.currentEmploymentIndicator) {
                        if (!currentEmployment?.selfEmployedIndicator) {
                            if (currentEmploymentDetails.length > 1) {
                                if (isRequiredPriorEmployment() && props.borrowerData.previousEmploymentDoesNotApply === true) {
                                    changeScreen(EmploymentScreensNew.PreviousAdditionalInformationLanding);
                                }
                            }
                            else if (currentEmployment?.needAdditionalEmployment === true && props.borrowerData.previousEmploymentDoesNotApply === true) {
                                changeScreen(EmploymentScreensNew.PreviousAdditionalInformationLanding);
                            }
                        }
                    }
                    else if (currentEmployment?.needAdditionalEmployment === true && props.borrowerData.previousEmploymentDoesNotApply === true) {
                        changeScreen(EmploymentScreensNew.AdditonalSelfEmployment1);
                    }
                }
            }
        }
    }

    return (
        <div className={styles.vquestionContainer}>
            {
                GetDetails(currentScreen)
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    UpdateEmploymentInformationProperty: (value: any, propertyName: string, borrowerType: BorrowerType, employmentItemId: string) => {
        dispatch(EmploymentInformationActions.employmentInformationActionCreators.UpdateEmploymentInformationProperty(value, propertyName, borrowerType, employmentItemId));
    },
    AddEmploymentItem: (id: string, data: Employment, borrowerType: BorrowerType) => {
        dispatch(EmploymentInformationActions.employmentInformationActionCreators.AddEmploymentItem(id, data, borrowerType));
    },
    DeleteEmploymentItem: (id: string, borrowerType: BorrowerType) => {
        dispatch(EmploymentInformationActions.employmentInformationActionCreators.DeleteEmploymentItem(id, borrowerType));
    },
    ResetEmploymentItem: (employmentItemId: string, borrowerType: BorrowerType) => {
        dispatch(EmploymentInformationActions.employmentInformationActionCreators.ResetEmploymentItem(employmentItemId, borrowerType));
    }
    ,
    ReIndexingEmployment: (data: Employment[], borrowerType: BorrowerType) => {
        dispatch(EmploymentInformationActions.employmentInformationActionCreators.ReIndexingEmployment(data, borrowerType));
    }
});

export default connect((state: ApplicationState) => state.employmentInformation, mapDispatchToProps)(React.memo(EmploymentItem) as any) as any;