import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as GovernmentQuestionActions from "../../actions/governmentQuestionActions";
import * as GovernmentQuestionStore from "../../store/GovernmentQuestionStore";
import BorrowerGovernmentQuestionSection5a from "components/governmentQuestions/BorrowerGovernmentQuestionsSection5a";
import BorrowerGovernmentQuestionSection5b from "components/governmentQuestions/BorrowerGovernmentQuestionsSection5b";
import styles from "./GovernmentQuestions.module.scss";
import { useHistory } from "react-router-dom";
import { useNavigation } from "hooks/useNavigation";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { bindActionCreators } from "redux";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import * as governmentQuestionService from "../../services/governmentQuestionService";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";

type GovernmentQuestionsSection5aProps = GovernmentQuestionStore.GovernmentQuestionState
    & {
        isCoBorrowerPresent: boolean,
        borrower: GovernmentQuestionStore.GovernmentQuestion,
        coBorrower: GovernmentQuestionStore.GovernmentQuestion,
        borrowerComplete: boolean,
        coBorrowerComplete: boolean,
        history: any;
        loanGuid: string;
    isLoanCompleted: boolean;
        interimLoanStagingId:number
    }
    & typeof GovernmentQuestionActions.governmentQuestionActionCreators
    & typeof TaskMenuCreators & typeof loanUpdateResponseActionCreators;

export enum GovernmentQuestionsStep {
    BorrowerGovermentQuestionSection5a = 1,
    BorrowerGovermentQuestionSection5b = 2,
    CoBorrowerGovermentQuestionSection5a = 3,
    CoBorrowerGovermentQuestionSection5b = 4,
}

const GovernmentQuestion = (props: GovernmentQuestionsSection5aProps) => {
    const [currentStep, changeStep] = React.useState(GovernmentQuestionsStep.BorrowerGovermentQuestionSection5a);
    const [borrowerValid, setBorrowerValid] = React.useState(false);
    const [coBorrowerValid, setCoBorrowerValid] = React.useState(false);
    const [borrowerSection5bValid, setBorrowerSection5bValid] = React.useState(false);
    const [coBorrowerSection5bValid, setCoBorrowerSection5bValid] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const history = useHistory();

    const actions = {
        ...bindActionCreators(
            {
                ...TaskMenuCreators,
                ...loanUpdateResponseActionCreators
            },
            useDispatch()
        ),
    };

    const navigation = useNavigation(() => {
        navigation.ShowContinue(() => onContinue(), isEncompassDown ?? false);
    });

    React.useEffect(() => {
        navigation.SetBackOnClick(() => onBack());
    });

    React.useEffect(() => {
        if (history.location.state?.args === "coborrower") {
            changeStep(GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5a)
        }
        else if (history.location.state?.args === "borrower") {
            changeStep(GovernmentQuestionsStep.BorrowerGovermentQuestionSection5a)
        }
    }, [history.location]);

    React.useEffect(() => {
        navigation.SetBackOnClick(() => onBack());
        navigation.ShowContinue(() => onContinue(), isEncompassDown ?? false);
         if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5a) {
            navigation.SetContinueDisabled(!borrowerValid || (isEncompassDown ?? false));
        }
        else if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5b) {
            navigation.SetContinueDisabled(!borrowerSection5bValid || (isEncompassDown ?? false));
        }
        else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5a) {
            navigation.SetContinueDisabled(!coBorrowerValid || (isEncompassDown ?? false));
        }
        else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5b) {
            navigation.SetContinueDisabled(!coBorrowerSection5bValid || (isEncompassDown ?? false));
        }
    }, [currentStep, borrowerValid, borrowerSection5bValid, coBorrowerValid, coBorrowerSection5bValid, isEncompassDown])

    const setBorrowerCompleted = (borrowerType: GovernmentQuestionStore.BorrowerType) => {
        props.updateBorrowerCompleted(borrowerType, true);

    }

    React.useEffect(() => {
        if (props.borrowerComplete && currentStep == GovernmentQuestionsStep.BorrowerGovermentQuestionSection5b) {
            saveBorrowerQuestions();
        }
    }, [props.borrowerComplete]);

    React.useEffect(() => {
        if (props.coBorrowerComplete && currentStep == GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5b) {
            saveCoBorrowerQuestions();
        }
    }, [props.coBorrowerComplete]);

    const saveBorrowerQuestions = async () => {
        if (props.borrowerComplete && currentStep == GovernmentQuestionsStep.BorrowerGovermentQuestionSection5b) {
            props.borrower.loanGuid = props.loanGuid;
            props.borrower.interimLoanStagingId = props.interimLoanStagingId;
            if (props.isLoanCompleted) {
                changeStepAfterSaveBorrowerQuestion();
                return;
            }

            setLoading(true);
            governmentQuestionService.updateGovernmentQuestion(props.borrower)
                .then((response: any) => {
                    if (!response.ok) {
                        throw new Error("There was a problem updating government information.");
                    }
                    let result = response.parsedBody as boolean;
                    setLoading(false);
                    if (result) {
                        actions.setLoanIsDirty(false);
                        changeStepAfterSaveBorrowerQuestion();
                    }
                    else {
                        throw new Error("There was a problem updating government information.");
                    }
                })
                .catch((error: Error) => {
                    setLoading(false);
                    throw new Error(`Following error message occurred ${error.message}`);
                });
        }
    }
    const changeStepAfterSaveBorrowerQuestion = () => {
        if (props.isCoBorrowerPresent) {
            history.replace({ state: { args: "coborrower" } });
        } else {
            history.push({ pathname: "/demographic-questions", state: { args: "borrower" } });
        }
        props.updateBorrowerCompleted(GovernmentQuestionStore.BorrowerType.PrimaryBorrower, false);
    }
    const saveCoBorrowerQuestions = async () => {
        if (props.coBorrowerComplete && currentStep == GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5b) {
            props.coBorrower.loanGuid = props.loanGuid;
            props.coBorrower.interimLoanStagingId = props.interimLoanStagingId;
            if (!props.isLoanCompleted) {
                setLoading(true);
                governmentQuestionService.updateGovernmentQuestion(props?.coBorrower)
                    .then((response: any) => {
                        if (!response.ok) {
                            throw new Error("There was a problem updating government information for coborrower.");
                        }
                        let result = response.parsedBody as boolean;
                        setLoading(false);
                        if (result) {
                            actions.setLoanIsDirty(false);
                            if (result !== null) {
                                history.push({ pathname: "/demographic-questions", state: { args: "borrower" } });
                                props.updateBorrowerCompleted(GovernmentQuestionStore.BorrowerType.CoBorrower, false);
                            }
                        }
                        else {
                            throw new Error("There was a problem updating government information for coborrower.");
                        }
                    })
                    .catch((error: Error) => {
                        setLoading(false);
                        throw new Error(`Following error message occurred ${error.message}`);
                    });
            }
            else {
                history.push({ pathname: "/demographic-questions", state: { args: "borrower" } });
                props.updateBorrowerCompleted(GovernmentQuestionStore.BorrowerType.CoBorrower, false);
            }
        }
    }

    const updateGovernmentQuestionInterim = (borrowerType: GovernmentQuestionStore.BorrowerType) => {
        actions.setLoanIsDirty(false);
        if (borrowerType == GovernmentQuestionStore.BorrowerType.PrimaryBorrower) {
            if (!props.isLoanCompleted) {
                props.updateToInterim(props.borrower);
            }
            changeStep(GovernmentQuestionsStep.BorrowerGovermentQuestionSection5b)
        }
        else {
            if (!props.isLoanCompleted) {
                props.updateToInterim(props.coBorrower);
            }
            changeStep(GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5b)
        }
    }

    const onBack = () => {
         if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5a) {
            actions.setLoanIsDirty(false);
            const { history } = props;
            if (history) history.push('/property-information');
        }
        else if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5b) {
            changeStep(GovernmentQuestionsStep.BorrowerGovermentQuestionSection5a);
        }
        else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5a) {
            history.replace({ state: { args: "borrower" } });
        }
        else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5b) {
            changeStep(GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5a);
        }
    };

    const onContinue = () => {
         if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5a) {
            updateGovernmentQuestionInterim(GovernmentQuestionStore.BorrowerType.PrimaryBorrower)
        }
        else if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5b) {
            setBorrowerCompleted(GovernmentQuestionStore.BorrowerType.PrimaryBorrower);
        }
        else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5a) {
            updateGovernmentQuestionInterim(GovernmentQuestionStore.BorrowerType.CoBorrower);
        }
        else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5b) {
            setBorrowerCompleted(GovernmentQuestionStore.BorrowerType.CoBorrower);
        }
    };

    let governmentQuestionsPage = <></>;

     if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5a) {
        governmentQuestionsPage = (
            <>
                <BorrowerGovernmentQuestionSection5a
                    borrowerType={GovernmentQuestionStore.BorrowerType.PrimaryBorrower}
                    setBorrowerValid={setBorrowerValid}
                    isLoanCompleted={props.isLoanCompleted}
                />

            </>
        );
    }
    else if (currentStep === GovernmentQuestionsStep.BorrowerGovermentQuestionSection5b) {
        governmentQuestionsPage = (
            <>
                <BorrowerGovernmentQuestionSection5b
                    borrowerType={GovernmentQuestionStore.BorrowerType.PrimaryBorrower}
                    setBorrowerSection5bValid={setBorrowerSection5bValid}
                    isLoanCompleted={props.isLoanCompleted}
                />
            </>
        );
    }
    else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5a) {
        governmentQuestionsPage =
            <>
                <BorrowerGovernmentQuestionSection5a
                    borrowerType={GovernmentQuestionStore.BorrowerType.CoBorrower}
                    setBorrowerValid={setCoBorrowerValid}
                    isLoanCompleted={props.isLoanCompleted}
                />
            </>;
    }
    else if (currentStep === GovernmentQuestionsStep.CoBorrowerGovermentQuestionSection5b) {
        governmentQuestionsPage =
            <>
                <BorrowerGovernmentQuestionSection5b
                    borrowerType={GovernmentQuestionStore.BorrowerType.CoBorrower}
                    setBorrowerSection5bValid={setCoBorrowerSection5bValid}
                    isLoanCompleted={props.isLoanCompleted} />
            </>;
    }
   
    return <div className="w-100-p">{governmentQuestionsPage}
        <>
            {isLoading && <div className="screenLoader"><span className="loader medium"></span></div>}
        </>
    </div>;
};

const mapStateToProps = (state: ApplicationState): any => {
    const { accountInformation, accountCreation, governmentQuestions } = state;
    let isCoBorrowerPresent;
    let borrower;
    let coBorrower;
    let borrowerComplete;
    let loanGuid;
    let coBorrowerComplete;
    let isLoanCompleted;
    let interimLoanStagingId;
    if (accountInformation && accountCreation) {
        isCoBorrowerPresent = state.accountCreation?.coBorrower?.firstName != null ? true : false;
        borrower = governmentQuestions?.borrower;
        coBorrower = governmentQuestions?.coBorrower;
        borrowerComplete = governmentQuestions?.borrowerComplete;
        coBorrowerComplete = governmentQuestions?.coBorrowerComplete;
        loanGuid = accountInformation.loanGuid;
        isLoanCompleted = state.loan?.isLoanCompleted;
        interimLoanStagingId = state.loan?.interimLoanStagingId;
    }
    return {
        isCoBorrowerPresent,
        borrower,
        coBorrower,
        borrowerComplete,
        coBorrowerComplete,
        loanGuid,
        isLoanCompleted,
        interimLoanStagingId
    }
}

export default connect(
    mapStateToProps,
    GovernmentQuestionActions.governmentQuestionActionCreators
)(GovernmentQuestion as any);