import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import * as EmploymentInformationStore from "../../store/employmentInformationStore";
import styles from "./employmentInformation.module.scss";
import { BorrowerType } from "../../store/employmentInformationStore";
import LocationSearch from "components/common/Input/LocationSearch";
import { EmploymentInformationFields, ScreenDetails, EmploymentScreensNew } from "./EmploymentInformationFields";
import { isNullOrWhiteSpace, isValidDate, toDate } from "../../common/helper/formatHelper";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import TextInput from "../common/Input/TextInput";
import { bindActionCreators } from "redux";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import CustomDatePicker from "../common/CustomDatePicker";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type EmployerDetailProps = {
    employmentData: EmploymentInformationStore.Employment,
    changeScreen: React.Dispatch<React.SetStateAction<EmploymentScreensNew>>,
    UpdateEmploymentInformationProperty: (value: any, fieldName: string, borrowerType?: BorrowerType, itemId?: string) => {},
    info: ScreenDetails,
    UpdateBackClick: any,
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
    currentEmploymentList: EmploymentInformationStore.Employment[],
    employmentItemId: any,
    isEncompassDown: boolean
}

const CurrentEmployerDetail = (props: EmployerDetailProps) => {
    const [zipFormat, setZipFormat] = React.useState("#########");
    const [maxDate, setMaxDate] = React.useState(new Date());
    const [invalidDate, setInvalidDate] = React.useState(false);

    const actions = { ...bindActionCreators({ ...TaskMenuCreators }, useDispatch()) };

    React.useEffect(() => {
        if (props.employmentData?.addressPostalCode?.length > 5) {
            setZipFormat("#####-####");
            return;
        }
        setZipFormat("#########");
    }, [props.employmentData?.addressPostalCode]);

    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    React.useEffect(() => {
        props.setDisabled(isDisabled);
        setMaxDate(new Date());
    }, [props.employmentData, props.employmentData?.employmentStartDate]);

    const isDisabled = (): boolean => {
        let disabled = true;
        if (
            !invalidDate &&
            !isNullOrWhiteSpace(props.employmentData?.employerName) &&
            !isNullOrWhiteSpace(props.employmentData?.positionDescription) &&
            isValidDate(toDate(props.employmentData?.employmentStartDate)) &&
            !isNullOrWhiteSpace(props.employmentData?.urlA2020StreetAddress) &&
            !isNullOrWhiteSpace(props.employmentData?.addressState) &&
            !isNullOrWhiteSpace(props.employmentData?.addressCity) &&
            !isNullOrWhiteSpace(props.employmentData?.addressPostalCode) &&
            !(props.employmentData?.addressPostalCode && (props.employmentData?.addressPostalCode.length < 5) && !props.isEncompassDown)
        ) {
            disabled = false;
        }
        return disabled;
    }

    const updateProperty = (value: any, fieldname: string) => {
        actions.setLoanIsDirty(true);
        props.UpdateEmploymentInformationProperty(value, fieldname, props.info.borrowerType, props.employmentData.id);
    }

    return (
        <div>

            <div className={`content ${styles.form}`}>
                <div className={`input-group ${styles.inputGroup}`}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.info.currentScreen === EmploymentScreensNew.EmployerDetail ? "Employer" : "Past Employer")}>
                        {props.info.currentScreen === EmploymentScreensNew.EmployerDetail ? "Employer" : "Past Employer"}
                        <span className={styles.requiredMsg}> * </span>
                    </label>
                    <TextInput
                        disabled={props.disabled}
                        type="text"
                        name={EmploymentInformationFields.EmployerName}
                        value={props.employmentData?.employerName ?? ""}
                        className={styles.input}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            updateProperty(e.target.value, e.target.name);
                        }}
                    />
                </div>

                <div className={`input-group ${styles.inputGroup}`}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.info.currentScreen === EmploymentScreensNew.EmployerDetail ? "Position" : "Past Position")}>
                        {props.info.currentScreen === EmploymentScreensNew.EmployerDetail ? "Position" : "Past Position"}
                        <span className={styles.requiredMsg}> * </span>
                    </label>
                    <TextInput
                        disabled={props.disabled}
                        type="text"
                        name={EmploymentInformationFields.PositionDescription}
                        value={props.employmentData?.positionDescription ?? ""}
                        className={styles.input}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            updateProperty(e.target.value, e.target.name);
                        }}
                    />
                </div>

                <div className={`input-group ${styles.inputGroup} ${styles.startDate}`}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Start Date")}>Start Date <span className={styles.requiredMsg}> * </span></label>
                    <CustomDatePicker
                        disabled={props.disabled}
                        fieldValue={props.employmentData?.employmentStartDate ? (isValidDate(props.employmentData?.employmentStartDate) ? new Date(props.employmentData?.employmentStartDate) : undefined) : undefined}
                        onDateChange={(date: any) => {
                            let convertedDate = toDate(date);
                            if (isValidDate(convertedDate)) {
                                if (maxDate <= new Date(convertedDate)) {
                                    setInvalidDate(true);
                                }
                                else {
                                    setInvalidDate(false);
                                }
                            } else {
                                setInvalidDate(true);
                            }
                            props.UpdateEmploymentInformationProperty(convertedDate,
                                EmploymentInformationFields.StartDate, props.info.borrowerType, props.employmentData.id);
                        }}
                        className={"employer-date"}
                        popperPlacement={false}
                        maxDate={maxDate}
                    />
                    {invalidDate &&
                        <span style={{ color: "red" }} className={styles.invalidclass} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Please enter a valid employment start date.")}>
                            Please enter a valid employment start date.</span>}
                </div>


                <div className={`input-group ${styles.inputGroup} ${styles.address}`}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Employer Address")}>Employer Address <span className={styles.requiredMsg}> * </span></label>
                    <LocationSearch
                        isDisabled={props.disabled}
                        addressStreetLine1={props.employmentData?.urlA2020StreetAddress ?? ""}
                        addresssFieldName={EmploymentInformationFields.StreetAddress}
                        borrowerType={props.info.borrowerType}
                        customType={props.employmentData?.id}
                        setStoreAddress={props.UpdateEmploymentInformationProperty}
                        styles={styles.input}
                    />
                </div>

                <div className={styles.cityStateZip}>
                    <div className={`input-group ${styles.inputGroup} ${styles.state}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("State")}>State <span className={styles.requiredMsg}> * </span></label>
                        <TextInput
                            disabled={props.disabled}
                            type="text"
                            maxLength="2"
                            name={EmploymentInformationFields.AddressState}
                            value={props.employmentData?.addressState ?? ""}
                            className={styles.input}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateProperty(e.target.value, e.target.name);
                            }}
                        />
                    </div>



                    <div className={`input-group ${styles.inputGroup} ${styles.city}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("City")}>City <span className={styles.requiredMsg}> * </span></label>
                        <TextInput
                            disabled={props.disabled}
                            type="text"
                            name={EmploymentInformationFields.AddressCity}
                            value={props.employmentData?.addressCity ?? ""}
                            className={styles.input}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                updateProperty(e.target.value, e.target.name);
                            }}
                        />
                    </div>

                    <div className={`input-group ${styles.inputGroup} ${styles.zip}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Zip Code")}>Zip Code <span className={styles.requiredMsg}> * </span></label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            className={styles.numberFormat}
                            value={props.employmentData?.addressPostalCode ?? ""}
                            format={zipFormat}
                            name={EmploymentInformationFields.AddressPostalCode}
                            id="#zip"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                updateProperty(values.value, EmploymentInformationFields.AddressPostalCode);
                            }}
                        />
                    </div>
                </div>

            </div>
        </div>

    );
}

export default connect(
    (state: ApplicationState) => state.employmentInformation,
    null
)(React.memo(CurrentEmployerDetail) as any) as any;