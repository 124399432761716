import React, { useState, useEffect } from "react";
import { appConfigAsync } from "../../authConfig";
import { useHistory, useLocation } from "react-router-dom";

export default function EmConsumerConnect() {
    const [siteId, setSiteId] = useState("");
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.state == undefined) {
            history.push({ pathname: "/document", search: location.search, state: { action: "SSO" } });
            return;
        }

        appConfigAsync().then(config => {
            setSiteId(config.ConsumerConnectSiteId);

            //Load the EMLauncher widget
            const script = document.createElement("script");
            script.async = false;
            script.src = "https://widget.ellieservices.com/latest/launcher.js";
            document.head.appendChild(script);
        });
    }, []);

    return (
        <div className="em-widget"
             data-width="100%"
             data-height="800px"
             data-min-height="800px"
             data-site-id={siteId}
             data-name="ecc-borrower-portal">
        </div>
    );
}