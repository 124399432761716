import { get, post } from "../common/dataFetch";

export const saveContactPreference = (preference1: string, preference2: string, preference3: string, loanGuid: string) => {
    return post<any>(`api/Preferences/SaveContactPreferenceAsync`, {
        loanGuid: loanGuid,
        preference1: preference1,
        preference2: preference2,
        preference3: preference3
    });
};

export const getContactPreference = (loanGuid: string) => {
    return get<any>(`api/Preferences/ReadContactPreferenceAsync?loanGuid=${loanGuid}`);
};