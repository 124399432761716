export const ValidationMessage = {
    Required: "Field is required.",
    EmailFormat: "Invalid Email",
    Number: "Invalid Number.",
    MinLength: "The value entered does not meet the minimum length requirement.",
    MaxLength: "The value entered exceeds the maximum length requirement.",
    Max: "The value entered is greater than the maximum value allowed.",
    Min: "The value entered is less than the minimum value allowed.",
    PasswordFormat: "Passwords must contain at least eight characters, including uppercase, lowercase,symbol and numbers",
    SpaceFormat: "Spaces are not allowed",
    DecimalFormat: "Invalid input provided",
    SsnFormat: "Invalid SSN provided"
}

export const Rule = {
    EmailValidation: {
        value: /^[0-9=?A-Z^a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
        message: ValidationMessage.EmailFormat
    },
    NumberValidation: {
        value: /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/,
        message: ValidationMessage.Number
    },
    PasswordValidation: {
        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/,
        message: ValidationMessage.PasswordFormat
    },
    SpaceValidation: {
        value: /[^\s]/,
        message: ValidationMessage.SpaceFormat
    },
    DecimalValidation: {
        value: /^\d{1,}(\.\d{0,4})?$/,
        message: ValidationMessage.DecimalFormat
    },
    SsnValidation: {
        //https://www.geeksforgeeks.org/how-to-validate-ssn-social-security-number-using-regular-expression/
        value: /^(?!666|000|9\d{2})\d{3}[-]*(?!00)\d{2}[-]*(?!0{4})\d{4}$/,
        message: ValidationMessage.SsnFormat
    }
}
