import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as SystematicLetterActions from "../../actions/systematicLetterActions";
import * as SystematicLetterStore from "../../store/systematicLetterStore";
import styles from "./LoanOfficer.module.scss";
import UhmLogo from "assets/logo/uhm-logo-stacked.png";

type SystematicLetterContentProps = {
    ref: object,
    data: SystematicLetterStore.SystematicLetter
}

export class LoanOfficerPreQualLetterContent extends React.PureComponent<SystematicLetterContentProps, {}> {
    constructor(props: SystematicLetterContentProps) {
        super(props);
    }
    private canvasEl!: HTMLCanvasElement;
    public componentDidMount() {
        const ctx = this.canvasEl.getContext("2d");
        if (ctx) {
            ctx.beginPath();
            ctx.stroke();
        }
    }

    private setRef = (ref: HTMLCanvasElement) => this.canvasEl = ref;
    public render() {
        return (
            <>
                <div className="infographic-side-layout-container">
                    <div className={styles.borrowerQueForm}>
                        <div className={styles.pageScroll}>
                            <div className={styles.content}>
                                <div className={styles.logoContainer}><img src={UhmLogo} alt="Union Home Mortgage" /></div>
                                <p>{this.props.data.currentDate ? this.props.data.currentDate : ""}</p>
                                <p>
                                    {this.props.data.firstName ?? ""} {this.props.data.lastName ?? ""} <br />
                                    {this.props.data.addressStreetLine ?? ""}<br />
                                    {this.props.data.addressCity ?? ""},  {this.props.data.addressState ?? ""} {this.props.data.addressPostalCode ?? ""}
                                </p>
                                <p>
                                    Dear {this.props.data.firstName ?? ""} {this.props.data.lastName ?? ""},
                            </p>
                                <p>
                                    Congratulations! Based on the information you provided and a review of your credit report, we are giving you preliminary qualification for a mortgage loan with the following parameters:
                            </p>
                                <ul>
                                    <li>Subject Property Address: TBD, {this.props.data.subjectPropertyAddress ?? ""}</li>
                                    <li>Loan Type: Conventional </li>
                                    <li>Property Type: {this.props.data.propertyType ?? ""}</li>
                                    <li>Occupancy: {this.props.data.occupancy ?? ""}</li>
                                    <li>Loan Amount: {this.props.data.loanAmount ?? "$0"}</li>
                                </ul>
                                <p>This preliminary qualification will expire in 90 days and we will not give further consideration to this request.</p>
                                <p>This preliminary qualification is based on the information you provided and your credit report. This is not a commitment to lend. A formal loan commitment can only be obtained after consulting with an assigned loan officer. Your application must meet our lending standards at the time of final approval, including an acceptable appraisal and validation of credit and underwriting information, including information received from independent third parties regarding your credit history. Until your rate is locked, the interest rate and other loan terms and conditions may change without notice.</p>
                                <p>Thank you for choosing Union Home Mortgage and we look forward to helping you with your mortgage needs. Please feel free to call if you have any questions and a loan officer will be in contact with you soon to move forward.</p>

                                <p> Sincerely, </p>

                                <br />
                                <address>
                                    Union Home Mortgage Corp. <br />
                                8241 Dow Circle West <br />
                                Strongsville, OH 44136 <br />
                                440-234-4300 <br />
                                    <a>encompasstest@unionhomemortgage.com </a>
                                </address>
                                <br />
                                <p className={styles.maybe}>*You may be contacted by a Union Home Mortgage affiliated company regarding other products and services related to home financing*</p>
                                <br />
                                <address className={styles.additionalAddress}>
                                    8241 Dow Circle, Strongsville, OH 44136 | UnionHomeMortgage.com | Union Home Mortgage Corp. NMLS 2229
                            </address>
                            </div>
                        </div>
                    </div>

                </div>

                <canvas id="myCanvas" ref={this.setRef} >
                </canvas>
            </>

        );
    }
}
const mapStateToProps = (appState: ApplicationState, ownProps: { data: SystematicLetterStore.SystematicLetter }): any => {
    let data;
    data = ownProps.data;
    return {
        data
    };
};
export default connect(
    mapStateToProps,
    SystematicLetterActions.systematicLetterActionCreators
)(LoanOfficerPreQualLetterContent as any);