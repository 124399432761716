import React, { useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import styles from "./Dashboard.module.scss";
import loanOfficerInfographic from "assets/infographics/dashboard/LoanOfficer.svg";
import Infographic from "../common/Infographic";
import { UserAccountState } from "../../store/userAccountStore";
import { LoanOfficerDetail } from "../../store/interimLoanSummaryStore";
import Tooltip from "../common/InfoIconTooltip";
import { appConfigAsync } from "../../authConfig";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type LoanViewHeaderProps = {
    userInfo: UserAccountState;
    signOut?: () => void
    isResponsive?: (isResponsive: boolean) => void;
    loanOfficerDetailInfo?: LoanOfficerDetail;
    showLoanOfficerDetail?: boolean;
};

export default function LoanViewHeader(props: LoanViewHeaderProps) {

    const location = useLocation();
    const [responsive, setResponsive] = React.useState(false);
    const [url, setUrl] = React.useState("");
    const isMultipleLoanPage = location.pathname.indexOf("loan-view") > -1 ? true : false;
    const history = useHistory();
    const { speak, cancel } = useSpeechSynthesis();

    const isResponsiveLayout = (val: boolean) => {
        setResponsive(val);
        if (props.isResponsive) {
            props.isResponsive(val);
        }
    }
    useEffect(() => {
        appConfigAsync().then(async (config: any) => {
            setUrl(config?.PosConstant?.OpenRefinanceCalculator)
        });
    }, []);

    const redirectToCreateLoan = () => {
        history.push({ pathname: "/account-information" });
    }
    const openUhmWebsiteUrl = () => {
        var urls = url.split('/').slice(0, 3).join('/')
        window.open(urls + "/" + `${props.loanOfficerDetailInfo?.slugId}`);
    }
    const redirectEmailUrl = () => {
        window.location.href = `mailto:${props.loanOfficerDetailInfo?.email}`;
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    return (
        <>
            <div className={`${styles.welcome} ${isMultipleLoanPage ? styles.loanViewWelcome : ""}`}>
                <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Welcome Back')}>Welcome Back</h6>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.userInfo.firstName+""+ props.userInfo.lastName)}>{props.userInfo.firstName} {props.userInfo.lastName}</h1>
            </div>
            { !isMultipleLoanPage && props.showLoanOfficerDetail && props.loanOfficerDetailInfo &&
                <div className={`${styles.LoanOfficerDetails} ${responsive ? styles.displayFlex : ""}`}>
                    <div className={styles.profilePic}>
                        {props.loanOfficerDetailInfo.loanOfficerHeadshot === null ?
                            <Infographic src={loanOfficerInfographic} /> :
                            <img src={props.loanOfficerDetailInfo.loanOfficerHeadshot} />
                        }
                    </div>
                    <div className={styles.profileDetails}>
                    <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Officer')}>Loan Officer</h6>
                        <div className={styles.namePhoneEmail}>
                        <h5 className={styles.loName} onClick={openUhmWebsiteUrl}>{props.loanOfficerDetailInfo.firstName} {props.loanOfficerDetailInfo.lastName}</h5>

                            <div className={styles.dashboardTooltipText}>
                                <a href={`tel:` + `${props.loanOfficerDetailInfo.phone}`}> <Tooltip iconClass="fa fa-phone">{props.loanOfficerDetailInfo.phone}</Tooltip></a>
                            </div>
                            <div className={styles.dashboardTooltipText} onClick={redirectEmailUrl}>
                                <Tooltip iconClass="fa fa-envelope"> {props.loanOfficerDetailInfo.email}</Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={`${styles.stratNewApp} ${responsive ? styles.displayFlex : ""}`}>
                <button className="common-green-button" onClick={redirectToCreateLoan} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('START A NEW APPLICATION')}>START A NEW APPLICATION</button>
            </div>
            {!isMultipleLoanPage && <div className={styles.accordionDetails} onClick={() => isResponsiveLayout(!responsive)}>
                DETAILS <i className="fa fa-caret-down"></i>
            </div>
            }
        </>
    );
}