import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import { LoanSummaryState, KnownAction, InterimLoanSummary, LoanOfficerDetail } from "../store/interimLoanSummaryStore";
import * as loanSummaryService from "../services/interimLoanSummaryService";

export const interimLoanSummaryActionCreators = {


    setInterimLoanSummary: (data: LoanSummaryState): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.ReceiveInterimLoanSummary, data: data });
    },

    setCurrentInterimLoanSummary: (data: InterimLoanSummary): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetCurrentInterimLoanSummary, data: data });
    },

    getLoanOfficerDetail: (id: number): AppThunkAction<KnownAction> => async (dispatch) => {
        await loanSummaryService.ReadLoanOfficerDetailByInterimLoanStagingAsync(id)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting interimLoanSummary .");
                }
                return response.parsedBody as LoanOfficerDetail;
            })
            .then((data: any) => {
                dispatch({ type: ActionTypes.ReceiveLoanOfficerDetail, data: data });
            });
    },


    getInterimLoanSummary: (email: string): AppThunkAction<KnownAction> => async (dispatch) => {

        await loanSummaryService.readLoanSummary(email).then((response: any) => {
            if (response.status != 200) {
                return;
            }
            return response.parsedBody as InterimLoanSummary[];
        }).then((data: any) => {

            let stateData: LoanSummaryState = {
                loanSummary: data,
                currentLoanSummary: data[0],
            }
            dispatch({ type: ActionTypes.ReceiveUpdatedInterimLoanSummary, data: stateData });
                          
        });
        
    }

};