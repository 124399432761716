import React from "react";
import styles from "./borrowerReo.module.scss";
import { ApplicationState } from "../../store";
import { ReoProperty, BorrowerReoState } from "../../store/borrowerReoStore";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Currency from "../common/Currency";
import { bindActionCreators } from "redux";
import { BorrowerReoActionCreators } from "../../actions/borrowerReoActions";
import { remove } from "../../services/borrowerReoService";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { ResponseDto } from "../../common/models/ResponseDto";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const ReoPropertiesSummary = () => {
    const state: BorrowerReoState = useSelector(
        (state: ApplicationState) => state.borrowerReo as BorrowerReoState
    );

    const isLoanCompleted = useSelector(
        (state: ApplicationState) => state.loan?.isLoanCompleted
    );

    return (
        <div className={styles.propertySummaryCardRow}>           
            {state.reoProperties.length > 0 &&
                state.reoProperties.map((property, index) => (
                    <PropertyCard key={index} property={property} index={index} isLoanCompleted={isLoanCompleted}></PropertyCard>
                ))}                    
                          
            {state.isLoading && <div className="screenLoader"><span className="loader medium"></span></div>}
            
        </div>
    );
};

const LineItem = (props: { name: string; value: any }) => {
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    return (
        <div>
            <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.name)}>{props.name}</h6>
            {props.name === "Market Value:" ? <div className={styles.propertyDetails} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.value)}>
                <Currency value={props.value}></Currency></div>
                : <div className={styles.propertyDetails} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.value)}>{props.value}</div>}
        </div>
    );
};

const PropertyCard = (props: { property: ReoProperty; index: number; isLoanCompleted: boolean | undefined }) => {
    const history = useHistory();

    const loanGuid = useSelector(
        (state: ApplicationState) => state.accountInformation?.loanGuid
    );
    const interimLoanStagingId = useSelector((state: ApplicationState) => state.loan?.interimLoanStagingId);

    const property = props.property;
    const isLoanCompleted = props.isLoanCompleted;

    const actions = { ...bindActionCreators({ ...BorrowerReoActionCreators, ...TaskMenuCreators, ...loanUpdateResponseActionCreators }, useDispatch()) };

    let formatAddress = (property: ReoProperty) =>
        `${property.street} ${property.city} ${property.state}, ${property.zip}`;

    const returnOccupency = (occupancyType: any) => {
        switch (occupancyType) {
            case "PrimaryResidence":
            case "Primary Residence":
                return "Primary Residence";
            case "SecondHome":
            case "Second Home":
                return "Second Home";
            case "Investor":
            case "Investment":
                return "Investment";
            default:
                return "";
        }
    }

    const returnProperty = (propertyType: any) => {
        switch (propertyType) {
            case "SingleFamily":
            case "Single Family":
                return "Single Family";
            case "Condominium":
                return "Condominium";
            case "Multi-Unit":
            case "TwoToFourUnitProperty":
                return "Multi-Unit";
            default:
                return "";
        }
    }

    const removeProperty = async (property: ReoProperty) => {
        if (!isLoanCompleted) {
            actions.isLoading(true);
            if (property.id.startsWith("ReoProperty")) {
                if (loanGuid && interimLoanStagingId != undefined && interimLoanStagingId>0) {
                    let response = await remove(property,interimLoanStagingId);
                    if (response.ok) {
                        let result = response.parsedBody as ResponseDto<boolean | null>;
                        if(result) {
                            actions.removeProperty(property.id);
                        }
                    } else {
                        console.error("Couldn't delete reo data!");
                    }
                } else {
                    alert("invalid loan guid!");
                }
            } else {
                actions.setLoanIsDirty(true);
                actions.removeProperty(property.id);
            }
            actions.isLoading(false);
        }
    }

    const updateProperty = (id: string) => {
        history.push({ pathname: "/add-reo-property", state: { id: id } })
    }

    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    return (
        <>
            <div className={styles.summaryCard} key={property.id}>
                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Property" + (props.index + 1))}>{`Property ${props.index + 1}`}</h2>
                <div className={styles.closeButton} onClick={() => !isLoanCompleted ? removeProperty(property) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to remove property")}>
                    <i className="fa fa-times"> </i>
                </div>

                <div className={styles.twoColsGrid} key={props.index} onClick={() => !isLoanCompleted ? updateProperty(property.id) : ""}>
                        
                    <LineItem name="Occupancy Type:" value={returnOccupency(property.occupancyType)}></LineItem>
                    <LineItem name="Owner:" value={property.owner}></LineItem>
                    <LineItem name="Property Type:" value={returnProperty(property.propertyType)}></LineItem>
                    {property.noOfUnits !== undefined && property.noOfUnits !== 0 && (
                        <LineItem name="Units:" value={property.noOfUnits?.toString()}></LineItem>
                    )}
                    {property.street && property.city &&
                        <LineItem name="Address:" value={formatAddress(property)}></LineItem>
                    }
                    {property.marketValue !== "" &&
                        <LineItem name="Market Value:" value={property.marketValue.toString()}></LineItem>
                    }
                </div>
            </div>
        </>
    );
};

export default ReoPropertiesSummary;
