import { post, get } from "../common/dataFetch";
import { ConsumerCreditAuthorization } from "../store/consumerCreditAuthorizationStore";

export const getConsumerCreditAuthorization = (borrowerType: string, interimLoanStagingId:number) => {
    return get<ConsumerCreditAuthorization>(`ConsumerCreditAuthorization/ReadByInterimIdAsync?borrowerType=${borrowerType}&interimLoanStagingId=${interimLoanStagingId}`);
};
export const updateConsumerCreditAuthorization = (creditAuthorizationDto: ConsumerCreditAuthorization) => {
    return post<any>("ConsumerCreditAuthorization/UpsertAsync", creditAuthorizationDto);
};
export const updateConsumerCreditAuthLoanDetailData = (creditAuthorizationDto: ConsumerCreditAuthorization) => {
    return post<any>("ConsumerCreditAuthorization/UpdateConsumerCreditAuthLoanDetailData", creditAuthorizationDto);
};
export const createAndSubmitAuthorizationFile = (creditAuthorizationDto: ConsumerCreditAuthorization) => {
    return post<any>("ConsumerCreditAuthorization/CreateAndSubmitAuthorizationFile", creditAuthorizationDto);
};
export const updateProgressTracker = (loanGuid: string) => {
    return post<any>("ConsumerCreditAuthorization/UpdateProgressTracker", loanGuid);
};
export const getConsumerCreditAuthorizationStatus = (interimLoanStagingId: number) => {
    return get<boolean>(`ConsumerCreditAuthorization/ReadConsumerCreditAuthorizationStatusAsync?interimLoanStagingId=${interimLoanStagingId}`);
};