import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccountCreationActions from "../../actions/accountCreationActions";
import * as AccountCreationStore from "../../store/accountCreationStore";
import Infographic from "../common/Infographic";
import currentAddressInfographic from "../../assets/infographics/accountCreation/current-address.svg";
import CustomYearMonthPicker from "../common/CustomYearMonthPicker";
import styles from "./accountCreation.module.scss";
import { dateDiff } from "../../common/helper/formatHelper";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type AddressConfirmationProps = AccountCreationStore.AccountCreationState & {
    currentAddress: AccountCreationStore.Address,
    borrowerType: AccountCreationStore.BorrowerType,
    setMonthValid: React.Dispatch<React.SetStateAction<boolean>>,
    birhtDay: Date,
    disabled: boolean
} & typeof AccountCreationActions.accountCreationActionCreators;
const AddressConfirmation = (props: AddressConfirmationProps) => {
    const [errorMessage, updateErrorMessage] = React.useState("");
    const [date, setDate] = React.useState(new Date());
    const handleDateChange = (month: number, year: number) => {
        let selectedDate = new Date(year ?? 0, month - 1);
        setDate(new Date(year ?? 0, month + 1, 0));
        let todaysDate = new Date();
        if (selectedDate > todaysDate || (new Date(props.birhtDay) > selectedDate)) {
            updateErrorMessage("Please select valid date.");
        }
        else {
            updateErrorMessage("");
        }

        if (year && month) {
            let months = dateDiff(selectedDate);
            props.updateBorrowerProperty(months < 24 ? true : false, "needAdditionalAddress", props.borrowerType);
        }
        props.updateAddressMoveInDate(year, month, props.borrowerType, AccountCreationStore.AddressType.CurrentAddress);
    }
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        props.setMonthValid(false);
        let selectedDate = new Date(props.currentAddress?.moveInYear ?? 0, props.currentAddress?.moveInMonth ?? 0 - 1);
        if ((props.currentAddress?.moveInYear !== undefined && props.currentAddress?.moveInYear !== 0) &&
            (props.currentAddress?.moveInMonth !== undefined) && (!(new Date(props.birhtDay) > selectedDate) || (new Date(props.birhtDay).getMonth() === props.currentAddress?.moveInMonth))) {
            props.setMonthValid(true);
        }
    }, [props.currentAddress?.moveInMonth, props.currentAddress?.moveInYear]);
    React.useEffect(() => {
        if (props.currentAddress?.moveInYear !== undefined && (new Date(props.birhtDay) > date)) {
            updateErrorMessage("Move in month and year should not be less than Birth date.");
            props.setMonthValid(false)
        }
        else {
            updateErrorMessage("");
        }
    }, [props.currentAddress?.moveInMonth, props.currentAddress?.moveInYear])

    return (
        <>
            <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? "Address Confirmation" : "Co-Borrower Address Confirmation")} className={styles.heading}>
                {props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
                    "Address Confirmation" : "Co-Borrower Address Confirmation"}
            </h1>
            <div className={`infographic-side-layout-container ${styles.addressConfirmation}`}>
                <div className="content">
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? "You've entered the following as your current address:" : "You've entered the following as your Co-Borrower's current address:")}>
                        {props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
                            "You've entered the following as your current address:" :
                            "You've entered the following as your Co-Borrower's current address:"}
                    </h2>
                    <h3 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.currentAddress?.addressStreetLine1 + " " + props.currentAddress?.addressCity + " " + props.currentAddress?.addressState)}>{`${props.currentAddress?.addressStreetLine1} ${props.currentAddress?.addressCity} ${props.currentAddress?.addressState}`}</h3>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? "When did you move into this address?" : "When did your Co-Borrower move into this address?")}>
                        {props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
                            "When did you move into this address?" :
                            "When did your Co-Borrower move into this address?"
                        }
                        <span style={{ color: "red" }}> * </span>
                    </h2>
                    <CustomYearMonthPicker
                        format="MMMM yyyy"
                        placeholderText="select date"
                        disabled={props.disabled}
                        fieldValue={props.currentAddress?.moveInYear !== undefined && props.currentAddress?.moveInYear > 0 && props.currentAddress?.moveInMonth !== undefined ? new Date(props.currentAddress?.moveInYear, props?.currentAddress.moveInMonth) : undefined}
                        handleChange={handleDateChange}
                        popperPlacement={false}
                        maxDate={new Date()}
                        defaultYear={props.currentAddress?.moveInYear}
                        defaultMonth={props.currentAddress?.moveInMonth}
                    />
                    <h4 style={{ clear: "both" }}> <span style={{ color: "red" }}> {errorMessage}</span></h4>
                </div>
                <div className="infographic-side-panel">
                    <Infographic src={currentAddressInfographic} />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: ApplicationState, ownProps: { borrowerType: AccountCreationStore.BorrowerType, setMonthValid: React.Dispatch<React.SetStateAction<boolean>>, disabled: boolean }): any => {
    return state.accountCreation ? {
        currentAddress: ownProps.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? state.accountCreation.borrower.currentAddress : state.accountCreation.coBorrower?.currentAddress,
        borrowerType: ownProps.borrowerType,
        setMonthValid: ownProps.setMonthValid,
        birhtDay: ownProps.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? state.accountCreation.borrower.birthDate : state.accountCreation.coBorrower.birthDate,
        disabled: ownProps.disabled
    } : state;
}

export default connect(
    mapStateToProps,
    AccountCreationActions.accountCreationActionCreators
)(AddressConfirmation as any);