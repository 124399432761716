import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { DashboardState, KnownAction } from "../store/dashboardStore";

const unloadedState: DashboardState = {
    dashboardInformation: {
        loanOfficerFirstName: undefined,
        loanOfficerLastName: undefined,
        loanOfficerEmail: undefined,
        loanOfficerPhone: undefined,
        loanOfficerHeadshot: undefined,
        applicationDate: undefined,
        isPreQualInitiate: undefined,
        borrowerIntendToContinueDate: undefined,
        appFeeComplete: undefined,
        isPreQualComplete: undefined,
        isLoanComplete: undefined,
        documentSubmitted: undefined,
        documentRequested: undefined,
        loanPurpose: undefined,
        firstName: undefined,
        lastName: undefined,
        isEncompassDown: undefined,
        loanOriginationDate: undefined,
        preApprovalLetterFlag: undefined,
        propertyEstimatedValueAmount: undefined,
    },
    isLoading: false,
    errorMessage: '',
    data: []
}

export const dashboardReducer: Reducer<DashboardState> = (state: DashboardState | undefined, incomingAction: Action): DashboardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case ActionTypes.IsLoading: {
            return { ...state, isLoading: action.loading };
        };

        case ActionTypes.GetDashboardDetails: {
            if (action.data) {

                return {
                    ...state,
                    isLoading: false,
                    dashboardInformation: action.data
                }
            }
        };
        case ActionTypes.ReceiveNotificationMessages: {
            return {
                ...state,
                data: action.data as string[]
            }
        }
        default:
            return state;
    }
}