import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import styles from "./Dashboard.module.scss";
import { InterimLoanSummary, LoanOfficerDetail } from "../../store/interimLoanSummaryStore";
import { useNavigation } from "../../hooks/useNavigation";
import { useHistory } from "react-router";
import * as AccountInformationActions from "../../actions/accountInformationActions";
import { interimLoanSummaryActionCreators } from "../../actions/interimLoanSummaryActions";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import * as accountInformationService from "../../services/accountInformationService";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import LoanViewHeader from "./LoanViewHeader";
import { UserAccountState } from "../../store/userAccountStore";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type MultipleLoanViewProps = {
    userAccountInformationObj: UserAccountState;

    userEmail: string;
    loanGuid: string;
    firstName: string;
    lastName: string;
    loanSummary: InterimLoanSummary[];
    loanOfficerDetail: LoanOfficerDetail;
    defaultLoanSummary: InterimLoanSummary;
} & typeof AccountInformationActions.accountInformationActionCreators
    & typeof interimLoanSummaryActionCreators;

const MultipleLoanView = (props: MultipleLoanViewProps) => {
    const { speak, cancel } = useSpeechSynthesis();

    const navigation = useNavigation(() => {
        navigation.HideNavigation();
    });

    const validLoanStatus = ["Active Loan", "Active"];

    const history = useHistory();

    React.useEffect(() => {
        if (props.userEmail) {
            props.getInterimLoanSummary(props.userEmail);
        }
    }, [])

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    const moveOnToDashboard = (index: number, staggingId: number) => {
        props.setLoanGuid("");
        history.push({ pathname: "/dashboard", state: { staggingId: staggingId } });
    }

    const loanTemplate = (loan: InterimLoanSummary, index: number) => {
        return (
            <div key={loan.interimLoanStagingId}
                onClick={() => (loan.loanStatus === "Active") && moveOnToDashboard(index, loan.interimLoanStagingId)}
                className={`${styles.card} ${loan.loanStatus !== "Active" ? styles.closedLoancard : ""}`}>
                <div className={styles.cardTop}>
                    <div className={styles.cardLeft}>
                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Property')}>Property</h6>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(loan.address)}>{loan.address}</h4>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(loan?.zip !== undefined && loan?.zip > 0 ? loan?.city + "" + loan?.state + "" + loan?.zip : "")}>{loan.city}, {loan.state} {loan.zip}</h4>
                    </div>
                    {loan.loanStatus == "Active" && <div className={styles.cardRight}>

                        <svg
                            className={styles.progressRing}
                            width="60"
                            height="60">
                            <CircularProgressbar
                                styles={buildStyles({
                                    pathColor: "#7F4896",
                                    trailColor: "transparent",
                                    textColor: "#7F4896",
                                    textSize: '28px'
                                })}
                                value={loan.loanPercentage}
                                text={`${loan.loanPercentage}%`}
                            />;
                        </svg>


                    </div>}
                </div>

                <div className={styles.twoColGrid}>
                    <div>
                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Number')}>Loan Number:</h6>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(loan.loanNumber)}>{loan.loanNumber}</h4>
                    </div>
                    <div>
                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Amount')}>Loan Amount:</h6>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(loan.loanAmount)}>{loan.loanAmount}</h4>
                    </div>
                    <div>
                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Term')}>Loan Term:</h6>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(loan.loanTerm > 0 ? loan.loanTerm : "")}>{loan.loanTerm > 0 ? loan.loanTerm: "" }</h4>
                    </div>
                    <div>
                        <h6 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Loan Type')}>Loan Type:</h6>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(loan.loanType)}>{loan.loanType}</h4>
                    </div>
                </div>

                { loan.loanStatus === "Active" &&
                    <button className={styles.gotoDashboard} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Go to my dashboard')}>
                    Go to my dashboard
                </button>}

            </div>
        );
    }

    const signOut = async () => {
        if (!isNullOrWhiteSpace(props.loanGuid)) {
            await accountInformationService.signOut("", props.loanGuid, props.defaultLoanSummary.interimLoanStagingId);
        }
        history.push("/");
        window.location.reload();
    }


    return (
        <>

            <div className={styles.dashboardPage}>
                <div className={styles.dashboardBorrowerDetails}>
                    <LoanViewHeader
                        userInfo={props.userAccountInformationObj}
                        signOut={signOut}
                        showLoanOfficerDetail={false}
                    />
                </div>

                <div className={styles.dashboardCardbg}>
                    {
                        props.loanSummary?.length && props.loanSummary.filter(loan => validLoanStatus.indexOf(loan.loanStatus) > -1).map((loan, index) => loanTemplate(loan, index))
                    }
                </div>

                {(props.loanSummary.filter(loan => validLoanStatus.indexOf(loan.loanStatus) < 0).length > 0) &&
                    <>
                        <div className={styles.closedLoan}><h6>CLOSED LOANS</h6></div>
                        <div className={styles.dashboardCardbg}>
                            {
                            props.loanSummary?.length && props.loanSummary.filter(loan => validLoanStatus.indexOf(loan.loanStatus) < 0).map((loan, index) => loanTemplate(loan, index))
                            }
                        </div>
                    </>
                }
            </div>

        </>
    )
}

const mapStateToProps = (state: ApplicationState): any => {
    const { accountInformation, interimLoanSummary, userAccountInformation } = state;
    let loanGuid;
    let userAccountInformationObj: UserAccountState | undefined;

    userAccountInformationObj = userAccountInformation;
    const defaultLoanSummary = interimLoanSummary?.currentLoanSummary;
    const userEmail = userAccountInformation?.email;
    const firstName = userAccountInformation?.firstName;
    const lastName = userAccountInformation?.lastName;
    const loanSummary = interimLoanSummary?.loanSummary;
    const loanOfficerDetail = interimLoanSummary?.loanOfficerDetail;
    if (accountInformation?.loanGuid) {
        loanGuid = accountInformation.loanGuid;
    }

    return {
        userAccountInformationObj,
        loanGuid,
        userEmail,
        firstName,
        lastName,
        loanSummary,
        loanOfficerDetail,
        defaultLoanSummary
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    setLoanGuid: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.setLoanGuid(value));
    },
    getInterimLoanSummary: (email: string) => {
        dispatch(interimLoanSummaryActionCreators.getInterimLoanSummary(email));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleLoanView as any);