import * as React from "react";
import { connect } from "react-redux";
import * as AdditionalIncomeStore from "../../store/additionalIncomeStore";
import { ApplicationState } from "../../store";
import { AdditionalIncomeActionCreators } from "../../actions/additionalIncomeActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { OtherIncomeSourceDetail } from "../../store/additionalIncomeStore";
import SelectDropDown from "../common/Input/SelectDropDown";
import styles from "./reviewApplication.module.scss";
import { OtherIncomeSourceFields, DummyOtherIncomeSourceId } from "../additionalIncome/AdditionalIncomeFields";
import { IncomeSource } from "../common/Enums/IncomeSource";
import { useForm } from "react-hook-form";
import * as ReviewApplicationActions from "../../actions/reviewApplicationActions";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import Currency from "../common/Currency";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type ReviewAdditionalIncomeProps = {
    otherIncomeSourceDetail: OtherIncomeSourceDetail;
    borrowerType: any;
    isEdit: boolean;
    section: string;
    setAdditionalInformationValid: (isValid: any, borrowerType: number) => void;
    disabled: boolean;
}
    & typeof AdditionalIncomeActionCreators
    & typeof ReviewApplicationActions.reviewApplicationActionCreators
    & typeof TaskMenuCreators

const ReviewAdditionalIncome = (props: ReviewAdditionalIncomeProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const { trigger } = useForm();
    const handleInputChange = (value: any, field: OtherIncomeSourceFields) => {
        trigger(field);
        var otherIncomeSourceDetail = { ...props.otherIncomeSourceDetail }
        let isNew = props.otherIncomeSourceDetail.id === DummyOtherIncomeSourceId ? true : false;
        props.handleAdditionalIncomeFieldChange(value, field, otherIncomeSourceDetail.id, isNew);
    }

    const returnAdditionalIncome = (value: string) => {
        switch (value) {
            case "Alimony":
                return IncomeSource.Alimony;
            case "ChildSupport":
                return IncomeSource.ChildSupport;
            case "Disability":
                return IncomeSource.Disability;
            case "Other":
                return IncomeSource.Other;
            case "Pension":
                return IncomeSource.Pension;
            case "Retirement":
                return IncomeSource.Retirement;
            case "SocialSecurity":
                return IncomeSource.SocialSecurity;
            case "Trust":
                return IncomeSource.Trust;
            default:
                return "";
        }
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        let isValid =
            !isNullOrWhiteSpace(props.otherIncomeSourceDetail?.description) &&
            !isNullOrWhiteSpace(props.otherIncomeSourceDetail?.monthlyAmount);

        props.setAdditionalInformationValid(isValid, props.borrowerType);

    }, [props.otherIncomeSourceDetail?.description, props.otherIncomeSourceDetail?.monthlyAmount]);

    return <>
        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Income Source')}>Income Source <span className={styles.requiredMsg}>*</span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>
                    <SelectDropDown
                        disabled={props.disabled}
                        className={styles.selectDdl}
                        defaultValue={props.otherIncomeSourceDetail?.description || ""}
                        name="Description"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            handleInputChange(e.target.value, OtherIncomeSourceFields.Description)
                        }}
                        noArrow={false}
                        dropdownclass={styles.select}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.otherIncomeSourceDetail?.description || "")}>
                        <option value="" disabled>Select...</option>

                        {Object.entries(IncomeSource).map(([key, value]) => (
                            <option key={value} value={key}>
                                {value}
                            </option>
                        ))}
                    </SelectDropDown>
                </div>
                : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(returnAdditionalIncome(props.otherIncomeSourceDetail?.description))}>{returnAdditionalIncome(props.otherIncomeSourceDetail?.description)}</label>
            }
        </div>
        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Monthly Income')}>Monthly Income <span className={styles.requiredMsg}>*</span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        name="monthlyAmount"
                        className={styles.numberFormat}
                        value={props.otherIncomeSourceDetail?.monthlyAmount ?? ""}
                        id="#monthlyAmount"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            handleInputChange(values.value, OtherIncomeSourceFields.MonthlyAmount)
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                    />
                </div>
                :
                <label className={styles.inputLabel}>
                    <Currency value={props.otherIncomeSourceDetail?.monthlyAmount ?
                        Number(props.otherIncomeSourceDetail?.monthlyAmount) : 0} decimalScale={2}></Currency>
                </label>
            }

        </div>
    </>;
};

const mapStateToProps = (state: ApplicationState, ownProps: {
    borrowerType: any,
    otherIncomeSourceDetail: AdditionalIncomeStore.OtherIncomeSourceDetail,
    isEdit: boolean,
    section: string,
    setAdditionalInformationValid: (isValid: any, borrowerType: number) => void;
    isLoanCompleted: boolean;
}): any => {

    return state.additionalIncome ?
        {
            otherIncomeSourceDetail: ownProps.otherIncomeSourceDetail,
            borrowerType: ownProps.borrowerType,
            isEdit: ownProps.isEdit,
            section: ownProps.section,
            setAdditionalInformationValid: ownProps.setAdditionalInformationValid,
            disabled: ownProps.isLoanCompleted
        } : state;
}

const mapDispatchToProps = (dispatch: any) => ({
    handleAdditionalIncomeFieldChange: (value: any, field: string, OtherIncomeSourceId: string, isNew: boolean) => {
        dispatch(AdditionalIncomeActionCreators.handleAdditionalIncomeFieldChange(value, field, OtherIncomeSourceId, isNew));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAdditionalIncome as any);