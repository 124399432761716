import React, { useState } from "react";
import { updateDocumentStatus } from "services/documentService";
import styles from "./documentViewers.module.scss";
import { DocumentStatus } from "../models/DocumentStatus";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
export interface DocumentViewerActionsProps {
    loanGuid: string;
    documentId: number;
    onStatusUpdate: () => void;
    documentList: any;
}

const DocumentViewerActions = (props: DocumentViewerActionsProps) => {
    const [notes, setNotes] = useState("");
    const [hasBeenRejected, setHasBeenRejected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setisOpen] = React.useState(false);

    const updateStatus = async (status: DocumentStatus) => {
        if (checkEligibilityToMarkDocumentAsReplace(status)) {
            setIsLoading(true);
            var result = await updateDocumentStatus({
                comment: notes,
                documentId: props.documentId,
                loanGuid: props.loanGuid,
                status: status,
            });
            setIsLoading(false);
            if (result.ok && !result.parsedBody?.hasError) {
                props.onStatusUpdate();
            }
        }
    };
    const checkEligibilityToMarkDocumentAsReplace = (status: any) => {
        const len = props.documentList.length || 0;
        let countReplace = 0;
        {
            props.documentList?.map((value: any) => {
                if (value.status != DocumentStatus.Submitted) {
                    if (value.status === DocumentStatus.Replaced) {
                        countReplace += 1;
                    }
                }
            })
            if ((countReplace === len - 1 && len >= 1) && status === DocumentStatus.Replaced) {
                toggleModle()
                return false
            }
            return true
        }
    }

    const toggleModle = () => {
        setisOpen(!isOpen)
    }

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggleModle} className={styles.commonPopup}>
                <ModalHeader toggle={toggleModle} className={styles.modalHeader}>
                </ModalHeader>
                <ModalBody className={styles.modalBody}>
                    <h4>Atleast one document should be accepted or rejected</h4>
                </ModalBody>
            </Modal>

            <div className={styles.btnContainer}>
                <button
                    className={styles.basicBtn}
                    disabled={isLoading}
                    onClick={() => updateStatus(DocumentStatus.Accepted)}
                >
                    Accept
        </button>
                <button
                    className={styles.warningBtn}
                    onClick={() => {
                        setHasBeenRejected(true);
                    }}
                >
                    Reject
        </button>
                <button
                    className={styles.grayBtn}
                    disabled={isLoading}
                    onClick={() => updateStatus(DocumentStatus.Replaced)}
                >
                    Replace
        </button>
            </div>
            {hasBeenRejected && (
                <div className={styles.notesContainer}>
                    <label>Notes:</label>
                    <textarea placeholder="Please make a comment on why this document is being rejected" onChange={(e) => setNotes(e.target.value)} ></textarea>
                    <button
                        disabled={notes === "" ? true : false}
                        className={styles.warningBtn}
                        onClick={() => updateStatus(DocumentStatus.Rejected)}
                    >
                        Save Rejection
          </button>
                </div>
            )}
            <>
                {isLoading && (
                    <span className={`loader medium ${styles.loadingAnimation}`}></span>
                )}
            </>
        </>
    );
};

export default DocumentViewerActions;
