import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../store";
import { getAssetFinicityInformation } from "../../services/assetInformationService";
import * as ManualAssetCreators from "../../actions/manualAssetInformationActions";
import { EnumManualAsset } from "../common/Enums/EnumManualAsset";
import { from } from "linq";
import styles from "./propertyInformation.module.scss";
import { useHistory } from "react-router";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface PropertyInformationFundSourceComparisonProps {
    selectedType: string;
    onContinue: () => void;
    disabled: boolean | undefined;
}
interface AssetFinicityInformation {
    borrowerUsedFinicity: boolean;
    coBorrowerUsedFinicity: boolean;
}

const PropertyInformationFundSourceComparison = (
    props: PropertyInformationFundSourceComparisonProps
) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [showMessage, setShowMessage] = useState(false);
    const [finicityInfo, setFinicityInfo] = useState({} as AssetFinicityInformation);
    const [assetTypes, setAssetTypes] = useState<string[]>([]);
    const actions = {
        ...bindActionCreators(
            {
                ...ManualAssetCreators.ManualAssetInformationActionCreators,
            },
            useDispatch()
        ),
    };
    const history = useHistory();

    const loanGuid = useSelector(
        (state: ApplicationState) => state.accountInformation?.loanGuid ?? undefined
    );
    const interimLoanStagingId = useSelector(
        (state: ApplicationState) => state.loan?.interimLoanStagingId ?? undefined
    );
    const assets = useSelector(
        (state: ApplicationState) => state.manualAssetInformation?.details
    );

    const getFinicity = async () => {
        var result = await getAssetFinicityInformation(loanGuid ?? "");
        if (result.ok && !result.parsedBody?.hasError) {
            setFinicityInfo(result.parsedBody?.body);
        }
    };


    useEffect(() => {
        setAssetTypes(
            from(assets ?? [])
                .select((x) => x.assetType ?? "")
                .distinct()
                .toArray()
        );
    }, [assets]);

    useEffect(() => {
        if (interimLoanStagingId && interimLoanStagingId >0) {
            actions.getAssetInformation("Both", interimLoanStagingId);
            getFinicity();
        }
    }, [loanGuid]);

    const noValidAssetsForDownPaymentSource = (source: string): boolean => {
        let applicableTypes = getApplicableAssetTypesForSource(source);

        const usedFinicity =
            finicityInfo?.borrowerUsedFinicity || finicityInfo?.coBorrowerUsedFinicity;
        if (!usedFinicity && applicableTypes.length > 0) {
            applicableTypes = from(applicableTypes)
                .select((x) => x.replace(/\s/g, ""))
                .toArray();
            return !from(assetTypes).any((x) => applicableTypes.includes(x));
        }

        return false;
    };

    const returnAssetTypes = (selectedType: string) => {
        switch (selectedType) {
            case "CheckingSavings":
                return EnumManualAsset.CheckingSavings;
            case "RetirementFunds":
                return EnumManualAsset.RetirementFund;
            case "EquityOnPendingSale":
                return EnumManualAsset.EquityOnPendingSale;
            case "SecuredBorrowedFunds":
                return EnumManualAsset.SecuredBorrowedFunds;
            case "StocksAndBonds":
                return EnumManualAsset.StocksAndBonds;
            case "GiftFunds":
                return EnumManualAsset.GiftFunds;
            default:
                return selectedType;
        }
    };

    const getApplicableAssetTypesForSource = (source: string): string[] => {
        switch (source) {
            case "StocksAndBonds":
                return ["Money Market Fund", "Mutual Fund", "Stock", "Stock Options", "Bond"];

            case "CheckingSavings":
                return ["Checking Account", "Savings Account"];

            case "RetirementFunds":
                return ["Retirement Fund"];
        }

        return [];
    };

    const onModalClose = () => {
        setShowMessage(false);
    };

    const navigateToManualAssets = () => {
        history.push("/manual-asset-information");
    };

    useEffect(() => {
        setShowMessage(false);
        if (props.selectedType) {
            const shouldShowMessage = noValidAssetsForDownPaymentSource(props.selectedType);
            if (shouldShowMessage) {
                setShowMessage(true);
            } else {
                props.onContinue();
            }
        }
    }, [props.selectedType]);
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div>
            <Modal className={styles.modal} isOpen={showMessage}>
                <ModalHeader className={styles.modalHeader} toggle={onModalClose} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Asset Type Mismatch')}>
                    Asset Type Mismatch
                </ModalHeader>
                <ModalBody className={styles.modalBody}>
                    <div className="content align-center">
                        <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('The Source of down payment you selected was' + returnAssetTypes(props.selectedType))}>The Source of down payment you selected was {returnAssetTypes(props.selectedType)} .</div>
                        <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(' One of your manual Asset Types needs to be')}>
                            One of your manual Asset Types needs to be {getApplicableAssetTypesForSource(props.selectedType)?.map((item) => (
                                <span key={item} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(item)}> {item}, </span>
                        ))}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className={styles.modalFooter}>
                    <div className="content align-center">
                        <button className="common-green-button" onClick={() => {
                            props.onContinue();
                            onModalClose();
                        }} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to ignore mismatch')}>Ignore mismatch</button>
                        <button disabled={props.disabled} className="common-button" onClick={navigateToManualAssets} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to Manual Assets')}>Manual Assets</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default PropertyInformationFundSourceComparison;