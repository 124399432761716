import * as React from "react";
import { EmploymentScreensNew, ScreenDetails } from "./EmploymentInformationFields";
import styles from "./employmentInformation.module.scss";
import { useNavigation } from "hooks/useNavigation";
import Infographic from "../common/Infographic";
import StartScreenSvg from "assets/infographics/employmentInformation/StartScreem.svg";
import { useHistory } from "react-router";
import { BorrowerType } from "../../store/employmentInformationStore";
import { bindActionCreators } from "redux";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { useDispatch } from "react-redux";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type LandingScreenProps =
    {
        info: ScreenDetails,
        borrowerName: string,
        borrowerType: BorrowerType,
        changeScreen: React.Dispatch<React.SetStateAction<EmploymentScreensNew>>,
        isEncompassDown: boolean
    }
const LandingScreen = (props: LandingScreenProps) => {
    const actions = {
        ...bindActionCreators(
            {
                ...TaskMenuCreators
            },
            useDispatch()
        ),
    };

    const navigation = useNavigation();
    const history = useHistory();
    const onBack = () => {
        moveFrwdBackwrd(true)
    };
    const onContinue = () => {
        moveFrwdBackwrd(false)
    };

    const moveFrwdBackwrd = (back: boolean) => {
        if (back) {
            actions.setLoanIsDirty(false);
            if (props.borrowerType === BorrowerType.PrimaryBorrower) {
                history.push({ pathname: "/credit-authorization", state: { args: "borrower" } });
            } else {
                history.replace({ state: { args: "borrower" } });
            }
        }
        else {
            if (props.info.nextScreen) {
                props.changeScreen(props.info.nextScreen)
            }
        }
    }
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className="">
            <div className={`infographic-side-layout-container ${styles.landingContainer}`}>
                <div className="content">
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Tell Us About " + (props.borrowerType === BorrowerType.PrimaryBorrower ? "Your" : props.borrowerName) + " Employment History")}>Tell Us About {props.borrowerType === BorrowerType.PrimaryBorrower ? "Your" : props.borrowerName} Employment History!</h1>

                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === BorrowerType.PrimaryBorrower ? "Please provide at least a two year history to allow us to find the perfect loan for you." : "Providing at least a two year history to allow us to find the perfect loan for you.")}>
                        {props.borrowerType === BorrowerType.PrimaryBorrower ?
                            "Please provide at least a two year history to allow us to find the perfect loan for you." :
                            "Providing at least a two year history to allow us to find the perfect loan for you."}
                    </h2>
                </div>
                <div className="infographic-side-panel">
                    <Infographic src={StartScreenSvg} />
                </div>
            </div>


            {navigation.SetBackOnClick(
                () => onBack()
            )}
            {navigation.ShowContinue(
                () => onContinue(),
                props.isEncompassDown
            )}
        </div>
    );
}

export default React.memo(LandingScreen) as any;