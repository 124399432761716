import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Layout from "./components/common/Layout/MainLayout";
import LoanOfficerLayout from "./components/common/Layout/LoanOfficerViewLayout";
import Home from "./components/Home";
import AccountInformation from "./components/accountInformation/AccountInformation";
import eConsent from "./components/eConsent/main/eConsent";
import SignIn from "./components/userAccount/SignIn";
import AccountCreation from "./components/accountCreation/AccountCreation";
import PropertyInformation from "./components/propertyInformation/PropertyInformation";
import EmploymentInformation from "./components/employmentInformation/EmploymentInformation";
import AdditionalIncome from "./components/additionalIncome/AdditionalIncomeInformation";
import GovernmentQuestion from "./components/governmentQuestions/GovernmentQuestion";
import DemographicQuestionDetails from "./components/demographicQuestions/DemographicQuestionDetails";
import AssetInformation from "./components/assetInformation/AssetInformation";
import ConsumerCreditAuthorization from "./components/consumerCreditAuthorization/ConsumerCreditAuthorization";
import "../node_modules/uhm-unity-theme-core/dist/unity-theme-core.min.css";
import "scss/index.css";
import ManualAssetInformation from "./components/manualAssetInformation/ManualAssetInformation";
import BorrowerLiability from "./components/borrowerLiability/BorrowerLiability";
import ContactPreference from "./components/contactPreference/ContactPreference";
import AddReoProperty from "./components/borrowerReo/AddReoProperty";
import ReoPropertiesSummary from "./components/borrowerReo/ReoPropertiesSummary";
import ReoPropertyLanding from "./components/borrowerReo/ReoPropertyLanding";
import AssetDetails from "./components/eVerificationFinicity/AssetDetails";
import ReviewApplication from "./components/preQualReview/ReviewApplication";
import CreditReport from "./components/creditReport/CreditReport";
import SystematicLetter from "./components/systematicLetter/SystematicLetter";
import LoanOfficerDocuments from "./components/documents/loanOfficer/LoanOfficerDocuments";
import AddDocumentSection from "./components/documents/addDocumentSection/AddDocumentSection";
import DocumentViewer from "./components/documents/documentViewers/DocumentViewer";
import DashboardDetails from "./components/loanView/DashboardDetails";
import MilestoneNavigation from "./components/milestoneProgressNavigation/MilestoneNavigation";
import Invitation from "./components/Invitation";
import LoanOfficerMainTab from "./components/loanOfficerView/LoanOfficerMainTab";
import LoanOfficerLauncher from "./components/loanOfficerView/LoanOfficerView";
import PreApprovalLetter from "./components/loanOfficerView/PreApprovalLetter";
import BorrowerPreApprovalLetter from "./components/preApprovalLetter/BorrowerPreApprovalLetter";
import BorrowerDocumentView from "./components/documents/borrowerView/BorrowerDocuments";
import Ecc from "./components/documents/EmConsumerConnect";
import SignDocument from "./components/documents/SignDocument";
import UserSignIn from "./components/userAccount/UserSignIn";
import UserSignUp from "./components/userAccount/UserSignUp";
import EncompassDown from "./components/EncompassDown";
import LoanOfficerInvite from "./components/loanOfficerInvite/LoanOfficerInvite";
import MultipleLoanView from "./components/loanView/MultipleLoanView";

const AppRouting = () => {
    const SignInContainer = () => (
        <Layout secured={false}>
            <Route path="/sign-in" component={SignIn} />
            <Route path="/invitation" component={Invitation} />
        </Layout>
    );

    const DefaultContainer = () => (
        <Layout secured={false}>
            <Route exact path="/" component={Home} />
            <Route path="/account-information" component={AccountInformation} />
            <Route path="/system-unavailable" component={EncompassDown} />
        </Layout>
    );

    const LoanOfficerContainer = () => (
        <LoanOfficerLayout>
            <Route path="/loan-officer-view" component={LoanOfficerMainTab} />
            <Route path="/pre-approval-Letter" component={PreApprovalLetter} />
            <Route path="/add-document" component={AddDocumentSection} />
            <Route path="/view-document" component={DocumentViewer} />
        </LoanOfficerLayout>
    );
    const SecuredContainer = () => (
        <Layout secured={true}>
            <Route path="/create-account" component={AccountCreation} />
            <Route path="/econsent" component={eConsent} />
            <Route path="/employment-information" component={EmploymentInformation} />
            <Route path="/property-information" component={PropertyInformation} />
            <Route path="/additional-income" component={AdditionalIncome} />
            <Route path="/asset" component={AssetInformation} />
            <Route path="/manual-asset-information" component={ManualAssetInformation} />
            <Route path="/government-questions" component={GovernmentQuestion} />
            <Route path="/liability" component={BorrowerLiability} />
            <Route path="/contact-preference" component={ContactPreference} />
            <Route path="/add-reo-property" component={AddReoProperty} />
            <Route path="/reo-properties" component={ReoPropertyLanding} />
            <Route path="/reo-properties-summary" component={ReoPropertiesSummary} />
            <Route path="/credit-authorization" component={ConsumerCreditAuthorization} />
            <Route path="/demographic-questions" component={DemographicQuestionDetails} />
            <Route path="/asset-details" component={AssetDetails} />
            <Route path="/review-application" component={ReviewApplication} />
            <Route path="/credit-report" component={CreditReport} />
            <Route path="/systematic-letter" component={SystematicLetter} />
            <Route path="/loan-officer-documents" component={LoanOfficerDocuments} />
            <Route path="/borrower-document-view" component={BorrowerDocumentView} />
            <Route path="/add-document-section" component={AddDocumentSection} />
            <Route path="/document-viewer" component={DocumentViewer} />
            <Route path="/dashboard" component={DashboardDetails} />
            <Route path="/milestone-progress" component={MilestoneNavigation} />
            <Route path="/Borrower-PreApproval-Letter" component={BorrowerPreApprovalLetter} />
            <Route path="/loan-view" component={MultipleLoanView} />
        </Layout>
    );

    const NoLayoutContainer = () => (
        <>
            <Route path="/lo-view" component={LoanOfficerLauncher} />
            <Route path="/account" component={UserSignUp} />
            <Route path="/id" component={UserSignIn} />
            <Route path="/ecc" component={Ecc} />
            <Route path="/document" component={SignDocument} />
        </>
    );

    return (
        <Switch>
            <Route exact path="/" component={DefaultContainer} />
            <Route exact path="/account-information" component={DefaultContainer} />
            <Route exact path="/invitation" component={SignInContainer} />
            <Route exact path="/lo-view" component={NoLayoutContainer} />
            <Route exact path="/loan-officer-view" component={LoanOfficerContainer} />
            <Route exact path="/add-document" component={LoanOfficerContainer} />
            <Route exact path="/view-document" component={LoanOfficerContainer} />
            <Route exact path="/pre-approval-Letter" component={LoanOfficerContainer} />
            <Route exact path="/sign-in" component={SignInContainer} />
            <Route exact path="/account" component={NoLayoutContainer} />
            <Route exact path="/id" component={NoLayoutContainer} />
            <Route exact path="/ecc" component={NoLayoutContainer} />
            <Route exact path="/document" component={NoLayoutContainer} />
            <Route path="/system-unavailable" component={DefaultContainer} />
            <Route path="/loan-officer-invite" component={LoanOfficerInvite} />
            <Route component={SecuredContainer} />
        </Switch>
    );
}

export default function App() {
    return (<AppRouting />);
}