import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as AccountCreationStore from "../store/accountCreationStore";
import { KnownAction } from '../store/accountCreationStore';
import * as accountCreationService from "../services/accountCreationService";

const setValidation = (validationError: any, field: string): AccountCreationStore.KnownAction => {
    return { type: ActionTypes.SetBorrowerValidation, validationError, field };
}

export const accountCreationActionCreators = {
    updateAddressMoveInDate: (year: number, month: number, borrowerType: AccountCreationStore.BorrowerType, addressType: AccountCreationStore.AddressType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateAddressMoveInDate, moveInYear: year, moveInMonth: month, borrowerType: borrowerType, addressType: addressType });
    },
    updateBorrowerProperty: (value: any, propertyName: string, borrowerType: AccountCreationStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerProperty, value: value, propertyName: propertyName, borrowerType: borrowerType });
    },
    updateBorrowerAddressProperty: (value: string, propertyName: string, borrowerType: AccountCreationStore.BorrowerType, addressType: AccountCreationStore.AddressType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerAddressProperty, value: value, propertyName: propertyName, borrowerType: borrowerType, addressType: addressType });
    },
    AddAddressProperty: (address: AccountCreationStore.Address, borrowerType: AccountCreationStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.AddAddressProperty, address: address, borrowerType: borrowerType });
    },
    EditAddressProperty: (address: AccountCreationStore.Address, borrowerType: AccountCreationStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.EditAddressProperty, address: address, borrowerType: borrowerType });
    },
    DeleteAddressProperty: (id: string, borrowerType: AccountCreationStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.DeleteAddressProperty, id: id, borrowerType: borrowerType });
    },
    UpdateOktaResult: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateOktaResult, oktaNameResult: value});
    },
    SetAddressSameAsBorrower: (addressType: AccountCreationStore.AddressType, value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetAddressSameAsBorrower, addressType: addressType, value: value });
    },
    getBorrowerInformation: (borrowerType: string, interimLoanStagingId:number): AppThunkAction<KnownAction> => (dispatch) => {
        accountCreationService.getBorrowerInformation(borrowerType, interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem.");
                }
                return response.parsedBody as AccountCreationStore.Borrower;
            }).then((data: any) => {
                dispatch({ type: ActionTypes.GetBorrowerInformation, data: data, borrowerType: borrowerType });
            })
            .catch((error: Error) => {
                console.error(error);
            });
    },

    UpdateBorrowerInformation: (borrowerInformation: AccountCreationStore.Borrower, userType: any): AppThunkAction<KnownAction> => (dispatch) => {
        accountCreationService.create(borrowerInformation, userType)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem.");
                }
            })
            .catch((error: Error) => {
                console.error(error);
            });
    },

    setValidation: (error: any, field: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(setValidation(error, field));
    },

    ResetFormerAddress: (borrowerType: AccountCreationStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.ResetFormerAddress, borrowerType: borrowerType });
    },

    updateBorrowerCurrentState: (borrowerInformation: AccountCreationStore.Borrower, borrowerType: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.GetBorrowerInformation, data: borrowerInformation, borrowerType });
    },
}