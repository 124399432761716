import * as React from "react";
import styles from "./NotificationMessages.module.scss";

const NotificationMessages = (props: { messages: string[] }) => {
    const notificationMessages = props.messages?.map((msg, i) => (
        <div key={i}>
            <br />
            <h6>{msg}</h6>
        </div>
    ));

    return (
        <div className="align-center">
            <h5>Notifications</h5>
            {notificationMessages?.length > 0
                ? <div className={`align-left ${styles.notification}`}>{notificationMessages}</div>
                : <h6>You don't have any notifications right now</h6>
            }
        </div>
    );
};

export default NotificationMessages as any;

