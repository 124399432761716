import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Preview from "../preview/eConsentPreview";
import DocumentForm from "../document/eConsentDocument";
import styles from "./eConsent.module.scss";
import { useNavigation } from "hooks/useNavigation";
import { ApplicationState } from "store";
import * as TaskMenuActions from "../../../actions/taskMenuAction";
import { LoanSummaryDto } from "../../../store/taskMenuStore";
import { dashboardActionCreators } from "../../../actions/dashboardActions";
import * as accountCreationActions from "../../../actions/accountCreationActions";
import { connect, useSelector } from "react-redux";
import { DashboardInformation } from "../../../store/dashboardStore";
import * as userAcountActions from "../../../actions/userAcount";
import { UserAccountState } from "../../../store/userAccountStore";
import { loanState } from "../../../store/loanStore";
import { interimLoanSummaryActionCreators } from "../../../actions/interimLoanSummaryActions";
import { InterimLoanSummary } from "../../../store/interimLoanSummaryStore";

type EConsentProps = {
    loanGuid: string;
    loanSummary: LoanSummaryDto;
    dashboardInformation: DashboardInformation;
    userAccountInformation: UserAccountState;
    updateUserConsent: (value: boolean) => any;
    borrowerType: any;
    isLoading: false;
    interimLoanStagingId: number;
    interimLoanSummaryList: InterimLoanSummary[];
    oktaNameResult: any;
    borrowerInformation: any;
    coborrowerInformation: any;
} & typeof TaskMenuActions.TaskMenuCreators & typeof dashboardActionCreators & typeof interimLoanSummaryActionCreators
    & typeof userAcountActions.userAcountActionCreators & typeof accountCreationActions.accountCreationActionCreators;

const EConsent = (props: EConsentProps) => {
    const [step, setStep] = useState(0);
    const [showPreview, setShowPreview] = useState(false);
    const [showDoc, setShowDoc] = useState(false);
    const history = useHistory();
    const { isLoanFromEncompassFlag } = useSelector((state: ApplicationState) => state.loan as loanState);

    const navigation = useNavigation(() => {
        navigation.HideNavigation();
    });

    React.useEffect(() => {
        if (props.loanGuid && props.interimLoanStagingId && props.interimLoanStagingId > 0) {
            props.getDashboardDetails(props.loanGuid, props.borrowerType);
            props.borrowerLoanSummary(props.loanGuid, props.interimLoanStagingId);
        }
        if (props.oktaNameResult) {
            if (props.borrowerType === "Borrower") {
                props.updateUserFirstName(props.borrowerInformation.firstName)
                props.updateUserLastName(props.borrowerInformation.lastName)
            } else {
                props.updateUserFirstName(props.coborrowerInformation.firstName);
                props.updateUserLastName(props.coborrowerInformation.lastName);
            }
            props.UpdateOktaResult(false);
        }
    }, []);

    useEffect(() => {
        if (step === 1) {
            navigation.SetBackOnClick(() => {
                setStep(0);
                navigation.ResetNavigation();
            });
        }
        setShowPreview(step === 0);
        setShowDoc(step === 1);
    }, [step]);

    const navigateToNextStep = async (nextStep: number) => {

        //Update loan summary data first
        if (props.userAccountInformation.email) {
            await props.getInterimLoanSummary(props.userAccountInformation.email);
        }

        if (nextStep === 2 && props.borrowerType === "CoBorrower" && !isLoanFromEncompassFlag) {
            history.push({ pathname: "/dashboard" });
        }
        else if (nextStep == 2 && props.borrowerType === "Borrower" && !isLoanFromEncompassFlag) {
            if (props.interimLoanSummaryList.length > 0) {
                console.log("IN eConsent.tsx for step 2 and Borrower");
                props.setCurrentInterimLoanSummary(props.interimLoanSummaryList[0]);
            }
            history.push({ pathname: "/create-account", state: { args: "borrower" } });
        }
        else {
            setStep(nextStep);
        }
    }

    return (
        <div className="w-100-p">
            <div className={styles.eConsentContainer}>
                {(showPreview && !props.isLoading) && (
                    <Preview
                        borrowerFirstName={props.dashboardInformation?.firstName ?? ""}
                        advance={() => { navigateToNextStep(1); }} />
                )}
                {showDoc && (
                    <DocumentForm
                        advance={() => { navigateToNextStep(2); }}
                        setUserConsent={props.updateUserConsent}
                    />
                )}
            </div>
            {props.isLoading && <div className="align-center"> <div className="loader" /></div>}

        </div>
    );
};


function mapStateToProps(state: ApplicationState) {
    const { accountInformation, taskMenu, dashboard, userAccountInformation, interimLoanSummary, accountCreation } = state;
    const loanGuid = accountInformation?.loanGuid;
    const dashboardInformation = dashboard?.dashboardInformation;
    const borrowerInformation = accountCreation?.borrower;
    const coborrowerInformation = accountCreation?.coBorrower;
    const loanSummary = taskMenu?.loanSummary;
    const borrowerType = userAccountInformation?.borrowerType;
    const isLoading = dashboard?.isLoading;
    const interimLoanStagingId = state.loan?.interimLoanStagingId;
    const interimLoanSummaryList = interimLoanSummary?.loanSummary;
    const oktaNameResult = state.accountCreation?.oktaNameResult;
    return {
        loanGuid,
        loanSummary,
        dashboardInformation,
        userAccountInformation,
        borrowerType,
        isLoading,
        interimLoanStagingId,
        interimLoanSummaryList,
        oktaNameResult, borrowerInformation,
        coborrowerInformation
    };
}
const mapDispatchToProps = (dispatch: any) => ({
    getDashboardDetails: (loanGuid: string, borrowerType: string) => {
        dispatch(dashboardActionCreators.getDashboardDetails(loanGuid, borrowerType));
    },
    borrowerLoanSummary: (loanGuid: string, interimLoanStagingId: number) => {
        dispatch(TaskMenuActions.TaskMenuCreators.borrowerLoanSummary(loanGuid, interimLoanStagingId));
    },
    updateUserConsent: (value?: boolean) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserConsent(value));
    },
    getInterimLoanSummary: async (email: string) => {
        dispatch(interimLoanSummaryActionCreators.getInterimLoanSummary(email));
    },
    setCurrentInterimLoanSummary: (data: InterimLoanSummary) => {
        dispatch(interimLoanSummaryActionCreators.setCurrentInterimLoanSummary(data));
    },
    updateUserFirstName: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserFirstName(value));
    },
    updateUserLastName: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserLastName(value));
    },
    UpdateOktaResult: (value: boolean) => {
        dispatch(accountCreationActions.accountCreationActionCreators.UpdateOktaResult(value));
    }
});

export default connect((state: ApplicationState) => mapStateToProps(state),
    mapDispatchToProps)(EConsent as any) as any;
