import * as React from "react";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { ManualAssetInformation as ManualAsset, ManualAssetInformationState } from "../../store/ManualAssetInformationStore";
import { ManualAssetInformationActionCreators } from "../../actions/manualAssetInformationActions";
import styles from "./manualAssets.module.scss";
import Currency from "../../components/common/Currency";
import { AccountTypeEnum } from "../../common/AccountTypeEnum";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ManualAssetDetailsProps = {
    manualAsset: ManualAsset[];
    editAsset: React.Dispatch<React.SetStateAction<string>>;
    deleteAsset: React.Dispatch<React.SetStateAction<string>>;
    systematicAsset: ManualAsset[];
    loanGuid: string;
    disabled: boolean;
}
    & typeof ManualAssetInformationActionCreators
    & ManualAssetInformationState;

const ManualAssetSummary = (props: ManualAssetDetailsProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    React.useEffect(() => {
        props.getSystematicAsset(props.loanGuid)
    }, []);
    let manualasset = 0.00;
    if (props?.manualAsset?.length !== undefined && props.manualAsset.length > 0) {
        for (let i = 0; i < props.manualAsset.length; i++) {
            var Temp = props.manualAsset[i].urla2020CashOrMarketValueAmount ? parseFloat(props.manualAsset[i].urla2020CashOrMarketValueAmount) : 0
            manualasset += Temp;
        }
    }
    let systematicAsset = 0.00;
    let total = 0.00;
    if (props?.systematicAsset?.length !== undefined && props.systematicAsset?.length > 0) {
        for (let i = 0; i < props.systematicAsset.length; i++) {
            let temp = props.systematicAsset[i].urla2020CashOrMarketValueAmount ? parseFloat(props.systematicAsset[i].urla2020CashOrMarketValueAmount) : 0
            systematicAsset += temp;
        }
    }
    total = manualasset + systematicAsset;
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className={`${styles.summaryContainer} ${styles.w100}`}>
            <div className={`${styles.content} ${styles.w100}`}>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Summary of Assets')}>Summary of Assets</h1>
                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Total Asset')}> Total Assets <Currency value={total} decimalScale={2}></Currency></h2>
                <div className={styles.basicInfo}>
                    <div className={`${styles.summaryRow} ${styles.summaryRowAsset}`}>
                        <div className={styles.entryData}>
                            <div className={styles.entryContainer}>
                                {(props?.manualAsset.length === 0 && props?.systematicAsset.length === 0) ?
                                    <div onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('No records found')}>
                                        No records found!
                                    </div> :
                                    <>
                                        {props?.manualAsset?.filter(x => x.assetType === "Other" || x.urla2020CashOrMarketValueAmount !== null).map((asset, index) => (
                                            <div className={styles.inputGroup} key={index}>
                                                <div>
                                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(asset.holderName)}>{asset.holderName}</label>
                                                    {Object.entries(AccountTypeEnum).map(([key, values], index) => (
                                                        <React.Fragment key={index}>
                                                            {
                                                                key === asset.assetType ? <h5 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(values)}>{values}</h5> : ""
                                                        }
                                                        </React.Fragment>
                                                    ))}
                                                </div>

                                                <div className={styles.editIcons}>
                                                    <label><Currency value={asset.urla2020CashOrMarketValueAmount ? parseFloat(asset.urla2020CashOrMarketValueAmount) : parseFloat('0')} decimalScale={2}></Currency></label>
                                                    {((asset.depositoryRequestDate != undefined && asset.depositoryRequestDate != new Date(1901, 2, 1)) && (asset.accountIdentifier === null || (asset.accountIdentifier != null &&
                                                        !asset.accountIdentifier.includes("".padEnd(18)))))
                                                        &&
                                                        <>
                                                            <span className={`${styles.icons} ${styles.faEdit}`} >
                                                            <i className="fa fa-edit" onClick={() => !props.disabled ? props.editAsset(asset.id) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press buttton to Edit')}>  </i>
                                                            </span>
                                                            <span className={`${styles.icons} ${styles.faTrash}`}>
                                                            <i className="fa fa-trash" onClick={() => !props.disabled ? props.deleteAsset(asset.id) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press buttton to Delete')}> </i>
                                                            </span>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                        {props?.systematicAsset?.map((systematicasset, index) => (
                                            <div className={styles.inputGroup} key={index}>
                                                <div>
                                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(systematicasset.holderName)}>{systematicasset.holderName}</label>
                                                    <h5 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(systematicasset.assetType)}>{systematicasset.assetType}</h5>
                                                </div>

                                                <div className={styles.editIcons}>
                                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to Edit')}><Currency value={systematicasset.urla2020CashOrMarketValueAmount ? parseFloat(systematicasset.urla2020CashOrMarketValueAmount) : parseFloat('0')} decimalScale={2}></Currency></label>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

const mapStateToProps = (appState: ApplicationState, ownProps: {
    editAsset: React.Dispatch<React.SetStateAction<string>>,
    deleteAsset: React.Dispatch<React.SetStateAction<string>>,
    disabled: boolean
}): any => {
    const { manualAssetInformation, accountInformation } = appState;
    let manualAsset;
    let editAsset;
    let deleteAsset;
    let systematicAsset;
    let loanGuid;
    let disabled;
    if (manualAssetInformation && manualAssetInformation.details) {
        manualAsset = manualAssetInformation.details;
        editAsset = ownProps.editAsset;
        deleteAsset = ownProps.deleteAsset;
        systematicAsset = manualAssetInformation.systematicAssets;
        disabled = ownProps.disabled
    }
    if (accountInformation !== undefined) {
        loanGuid = accountInformation?.loanGuid
    }
    return {
        manualAsset,
        editAsset,
        deleteAsset,
        systematicAsset,
        loanGuid,
        disabled
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    removeManualAssetFromState: (id: string) => {
        dispatch(ManualAssetInformationActionCreators.removeManualAssetFromState(id));
    },
    getSystematicAsset: (loanGuid: string) => {
        dispatch(ManualAssetInformationActionCreators.getSystematicAsset(loanGuid));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(ManualAssetSummary as any);