import React, { useEffect, useRef, useState } from "react";
import styles from "./documentViewers.module.scss";

const WordDocumentViewer = (props: { data: string; fileName: string }) => {
    const [blobString, setBlobString] = useState("");
    const linkRef = useRef(null);

    useEffect(() => {
        if (props.data) {
            setBlobString(
                getBlobUrl(
                    props.data,
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                )
            );
        }
    }, [props.data]);

    const getBlobUrl = (encodedByteString: string, documentContentType: string) => {
        let byteString = atob(encodedByteString);
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let byteArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }

        let blob = new Blob([byteArray], { type: documentContentType });
        return URL.createObjectURL(blob);
    };

    return (
        <>
            <span className={styles.wordDownloadButton}>
                <button
                    className={`common-green-button`}
                    onClick={() => (linkRef.current as any).click()}
                ><i className={`fa fa-download ${styles.icon}`}></i>
                    Download Attachment
        </button>
                <a href={blobString} download={props.fileName} target="_blank" ref={linkRef}></a>
            </span>
        </>
    );
};

export default WordDocumentViewer;
