import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { DemographicQuestionsState, KnownAction } from "../store/demographicQuestionsStore";
import { DemographicFields } from "components/demographicQuestions/DemographicFields";
import { BorrowerType } from "../store/Enum";

const unloadedState: DemographicQuestionsState = {
    borrower: {
        loanGuid: '',
        borrowerType: undefined,
        hmdaEthnicityHispanicLatinoIndicator: undefined,
        hmdaEthnicityNotHispanicLatinoIndicator: undefined,
        hmdaEthnicityDoNotWishIndicator: undefined,
        hmdaAmericanIndianIndicator: undefined,
        hmdaAfricanAmericanIndicator: undefined,
        hmdaWhiteIndicator: undefined,
        hmdaRaceDoNotWishProvideIndicator: undefined,
        hmdaGendertypeFemaleIndicator: undefined,
        hmdaGendertypeMaleIndicator: undefined,
        hmdaGendertypeDoNotWishIndicator: undefined,
        interimLoanStagingId: undefined
    },
    coBorrower: { loanGuid: '' },
    isLoading: false,
    errorMessage: '',
    borrowerloading: undefined,
    coborrowerloading: undefined
}

export const demographicQuestionsStateReducer: Reducer<DemographicQuestionsState> = (state: DemographicQuestionsState | undefined, incomingAction: Action): DemographicQuestionsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case ActionTypes.RequestDemographicQuestions:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.RecieveDemographicQuestions:
            return action.borrowerType === BorrowerType.PrimaryBorrower ?
                {
                    ...state,
                    borrowerloading: true,
                    borrower: action.data,
                    isLoading: false
                } :
                {
                    ...state,
                    coborrowerloading: true,
                    coBorrower: action.data,
                    isLoading: false
                }

        case ActionTypes.UpdateDemographicQuestions: {
            let result;
            if (action.section === DemographicFields.SectionEthnicity) {
                if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                    state.borrower.hmdaEthnicityHispanicLatinoIndicator = false;
                    state.borrower.hmdaEthnicityNotHispanicLatinoIndicator = false;
                    state.borrower.hmdaEthnicityDoNotWishIndicator = false;
                    result = {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            [action.propertyName]: action.value,
                        }
                    }
                }
                else {
                    state.coBorrower.hmdaEthnicityHispanicLatinoIndicator = false;
                    state.coBorrower.hmdaEthnicityNotHispanicLatinoIndicator = false;
                    state.coBorrower.hmdaEthnicityDoNotWishIndicator = false;
                    result = {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            [action.propertyName]: action.value,
                        }
                    }
                }
                return result;
            }
            else if (action.section === DemographicFields.SectionRace) {
                if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                    state.borrower.hmdaAmericanIndianIndicator = false;
                    state.borrower.hmdaAfricanAmericanIndicator = false;
                    state.borrower.hmdaPacificIslanderIndicator = false;
                    state.borrower.hmdaWhiteIndicator = false;
                    state.borrower.hmdaRaceDoNotWishProvideIndicator = false;

                    result = {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            [action.propertyName]: action.value,
                        }
                    }
                }
                else {
                    state.coBorrower.hmdaAmericanIndianIndicator = false;
                    state.coBorrower.hmdaAfricanAmericanIndicator = false;
                    state.coBorrower.hmdaPacificIslanderIndicator = false;
                    state.coBorrower.hmdaWhiteIndicator = false;
                    state.coBorrower.hmdaRaceDoNotWishProvideIndicator = false;
                    result = {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            [action.propertyName]: action.value,
                        }
                    }
                }
                return result;
            }
            else if (action.section === DemographicFields.SectionSex) {
                if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                    state.borrower.hmdaGendertypeFemaleIndicator = false;
                    state.borrower.hmdaGendertypeMaleIndicator = false;
                    state.borrower.hmdaGendertypeDoNotWishIndicator = false;
                    result = {
                        ...state,
                        borrower: {
                            ...state.borrower,
                            [action.propertyName]: action.value,
                        }
                    }
                }
                else {
                    state.coBorrower.hmdaGendertypeFemaleIndicator = false;
                    state.coBorrower.hmdaGendertypeMaleIndicator = false;
                    state.coBorrower.hmdaGendertypeDoNotWishIndicator = false;
                    result = {
                        ...state,
                        coBorrower: {
                            ...state.coBorrower,
                            [action.propertyName]: action.value,
                        }
                    }
                }
                return result;
            }
        }

        
        default:
            return state;
    }
}