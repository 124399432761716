import InfographicSideLayout from "components/common/Layout/InfographicSideLayout";
import React from "react";
import styles from "./propertyInformation.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
export interface TwoChoiceQuestionProps {
    optionOne: ButtonProps;
    optionTwo: ButtonProps;
    children?: React.ReactNode;
    infographic?: string;
    value?: any;
    disabled: boolean | undefined;
}

interface ButtonProps {
    message?: string;
    onClick?: () => void;
}

const TwoChoiceQuestion = (props: TwoChoiceQuestionProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <>
            <InfographicSideLayout infographic={props.infographic}>
                {props.children}
                <div className={styles.twoChoiceButtonContainer}>
                    <button disabled={props.disabled} className={`common-button ${props.value === true ? "active" : ""}`} onClick={props.optionOne.onClick} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.optionOne.message ?? "Yes")}>
                        {props.optionOne.message ?? "Yes"}
                    </button>
                    <button disabled={props.disabled} className={`common-button ${props.value === false ? "active" : ""}`} onClick={props.optionTwo.onClick} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.optionTwo.message ?? "No")}>
                        {props.optionTwo.message ?? "No"}
                    </button>
                </div>
            </InfographicSideLayout>
        </>
    );
};

export default TwoChoiceQuestion;
