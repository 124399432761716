import React, { useState, useReducer } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import UhmLogo from "assets/logo/uhm-logo.png";
import { useForm } from "react-hook-form";
import { UserAccountFields } from "../../store/Enum";
import styles from "./SignUp.module.scss";
import PasswordChecklist from "react-password-checklist";
import * as Validation from "../constants/validation";
import * as accountInformationService from "../../services/accountInformationService";
import MessageBox from "../common/MessageBox";
import { ApplicationState } from "store";
import SelectDropDown from "../common/Input/SelectDropDown";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { AccountInformationState } from "../../store/accountInformationStore";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import * as AccountInformationActions from "../../actions/accountInformationActions";
import * as UserAcountActions from "../../actions/userAcount";
import * as userAccountStore from "../../store/userAccountStore";
import Loading from "../common/Loader";
import { userAcountActionCreators } from "../../actions/userAcount";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";
import * as AccountInformationInterfaces from "../../interfaces/accountInformationInterfaces";
import * as LoanUpdateResponseActions from "../../actions/loanUpdateResponseActions";
import { IAccountInformationProps } from "../../store/accountInformationStore";
import { loanActionCreators } from "../../actions/loanActions";


type UserSignUpProps = userAccountStore.UserAccountState
    & typeof AccountInformationActions.accountInformationActionCreators & typeof userAcountActionCreators
    & typeof loanActionCreators;

function reducer(state: any, action: any) {
    switch (action.type) {
        case "firstName":
            return { ...state, firstName: action.value }
        case "lastName":
            return { ...state, lastName: action.value }
        case "email":
            return { ...state, email: action.value }
        case "mobile":
            return { ...state, mobile: action.value }
        case "password":
            return { ...state, password: action.value }
        case "confirmPassword":
            return { ...state, confirmPassword: action.value }
        case "verificationCode":
            return { ...state, verificationCode: action.value }
        case "resetQuestion":
            return { ...state, resetQuestion: action.value }
        case "resetAnswer":
            return { ...state, resetAnswer: action.value }
        case "deliveryMethod":
            return { ...state, deliveryMethod: action.value }
        case "loanGuid":
            return { ...state, loanGuid: action.value }
        default:
            throw new Error("Reducer: Invalid action type");
    }
}

const UserSignUp = (props: UserSignUpProps) => {
    const account = useSelector((state: ApplicationState) => state.accountInformation as AccountInformationState);
    const [state, dispatch] = useReducer(reducer, {
        firstName: account.borrowerFirstName,
        lastName: account.borrowerLastName,
        email: account.borrowerEmail,
        mobile: account.borrowerPhoneNumber,
        verificationCode: "",
        password: "",
        confirmPassword: "",
        resetQuestion: "",
        resetAnswer: "",
        deliveryMethod: "Email",
        loanGuid: account.loanGuid,
    });
    const { register, handleSubmit, errors } = useForm({
        mode: "onTouched",
        reValidateMode: 'onBlur',
    });
    const location = useLocation();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState({ display: false, title: "", text: "" });
    const [codeVerified, setCodeVerified] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordMatched, setPasswordMatched] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [resetQuestions, setResetQuestions] = useState([]);
    const [result, setResult] = useState("");
    const [isValidMobile, setValidMobile] = useState(true);
    const [userAlreadyVerified, setUserAlreadyVerified] = useState(false);
    const emailRegExp = new RegExp(Validation.Rule.EmailValidation.value);

    if (location.state == undefined && (account.loanGuid == undefined || account.loanGuid === "") && location.search.indexOf('userKey') < 0) {
        history.push({ pathname: "/" });
    }

    const onSubmit = async () => {
        let result;
        if (passwordValid) {
            await accountInformationService.accountSignUp(state)
                .then(async (response: any) => {

                    result = response.parsedBody.body;
                    setResult(result);
                    if (result === "Account Created") {
                        let requestState = location.state;
                        if (requestState == undefined) {
                            requestState = { action: "sign-in", borrowerType: "Borrower" };
                        }
                        if (account.accountInformationCheck && result === "Account Created" && result !== "An account already exists with this email address. Please go back to login page and try to sign in with your credentials.") {
                            await loanCreation();
                        }
                        history.push({ pathname: "id", state: requestState });
                    } else {
                        setMessage({ ...message, display: true, title: "Create Account", text: result });
                    }
                }
                );
        }
    };
    const loanCreation = async () => {
        let accountInformation: IAccountInformationProps = {
            noOfUnits: account.howManyUnitsChoice,
            borrowerEmailAddress: account.borrowerEmail,
            loanPurpose: account.loanPurposeChoice,
            userId: account.userId,
            occupancyType: account.occupancyTypeChoice,
            propertyType: account.propertyTypeChoice,
            purchaseClosingDate: account.closingDateChoice,
            refinancePriority: account.priorityChoice,
            borrowerFirstName: account.borrowerFirstName,
            borrowerLastName: account.borrowerLastName,
            borrowerPhoneNumber: account.borrowerPhoneNumber,
            borrowerMiddleName: account.borrowerMiddleName,
            borrowerNameSuffix: account.borrowerNameSuffix,
            borrowerType: account.borrowerType ?? ""
        };

        props.IsLoading(true);
        props.isLoancreated(true);
        await accountInformationService.create(accountInformation, account.loanGuid)
            .then((response: any) => {
                if (response.status != 200) {
                    return;
                }
                console.log("loan createed in Encompass with loanGuid:", response);
                let result = response.parsedBody as LoanUpdateResponseState;


                if (result.isEncompassDown || result.loanGuid === null || result.loanGuid === "") {
                    console.log("Encompass service is down or loanGuid not present");
                    history.push({ pathname: "/system-unavailable" });
                    return;
                }

                if (accountInformation.userId == undefined) {
                    accountInformation.userId = "";
                }

                props.IsLoading(false);

                if (result.loanGuid === "Email id already registered" || result.isEncompassDown) {
                    props.submitInitialAccountInformation(false);
                }
                else {
                    props.setLoanGuid(result.loanGuid);
                    props.setInterimLoanStagingId(result.interimLoanStagingId);
                }

                return result;
            })
            .catch((error: Error) => {
                console.error("Error occurred during loan creation: ", error);
            });
    }

    const login = async () => {
        let requestState = location.state;
        if (requestState == undefined) {
            requestState = { action: "sign-in", borrowerType: "Unknown" };
        }
        if (requestState &&
            requestState?.action === "invitation" &&
            requestState?.borrowerType === BorrowerTypeEnum.CoBorrower &&
            !isNullOrWhiteSpace(requestState?.loanGuid)) {
            requestState = { action: "sign-in", borrowerType: BorrowerTypeEnum.CoBorrower, loanGuid: requestState?.loanGuid, invitation: requestState?.action };
        }
        history.push({ pathname: "/id", state: requestState });
    }

    const readVerificationData = (userKey: string) => {
        props.IsLoading(true);
        accountInformationService.readVerificationData(userKey)
            .then((response: any) => {
                if (!response.ok) {
                    console.error(response);
                }
                return response.parsedBody as any;
            })
            .then((data: any) => {

                props.IsLoading(false);
                let emailVerificationData = data?.emailVerification;
                let accountInformationData = data?.accountInformationData;

                if (!data.emailVerification) {
                    dispatch({ type: "verificationCode", value: "" });
                    setMessage({ ...message, display: true, title: "Verification Code", text: "Your Verification Link has expired." });
                    return true;
                }

                if (emailVerificationData.verified) {
                    setUserAlreadyVerified(true);
                    return true;
                }

                dispatch({ type: "firstName", value: accountInformationData.borrowerFirstName });
                dispatch({ type: "lastName", value: accountInformationData.borrowerLastName });
                dispatch({ type: "email", value: accountInformationData.borrowerEmailAddress });
                dispatch({ type: "mobile", value: accountInformationData.borrowerPhoneNumber });

                dispatch({ type: "deliveryMethod", value: emailVerificationData.deliveryMethod });

                //Update Redux store
                props.updateBorrowerEmail(accountInformationData.borrowerEmailAddress);
                props.updateBorrowerFirstName(accountInformationData.borrowerFirstName);
                props.updateBorrowerLastName(accountInformationData.borrowerLastName);
                props.updateBorrowerMiddleName(accountInformationData.borrowerMiddleName);
                props.updateBorrowerNameSuffix(accountInformationData.borrowerNameSuffix);
                props.updateBorrowerPhoneNumber(accountInformationData.borrowerPhoneNumber);
                props.updateHowManyUnitsChoice(accountInformationData.noOfUnits);
                props.updatePriorityChoice(accountInformationData.refinancePriority);
                props.updateClosingDateChoice(accountInformationData.purchaseClosingDate);
                props.updateLoanPurposeChoice(accountInformationData.loanPurpose);
                props.updateOccupancyTypeChoice(accountInformationData.occupancyType);
                props.updatePropertyTypeChoice(accountInformationData.propertyType);
                props.updateUserId(accountInformationData.userId);
                props.setLoanGuid(accountInformationData?.loanGuid);

                if (!emailVerificationData.verificationCode) {
                    dispatch({ type: "verificationCode", value: "" });
                    setMessage({ ...message, display: true, title: "Verification Code", text: "Your Verification Code has expired. Please choose Send Verification Code to receive a new one and continue creating your account." });
                    return true;
                }

                dispatch({ type: "verificationCode", value: emailVerificationData.verificationCode });
                setCodeVerified(true);

            })
            .catch((error: Error) => {
                console.error(error);
            });
    }

    const sendVerificationCode = () => {
        let accountInformation: IAccountInformationProps = {
            noOfUnits: account.howManyUnitsChoice,
            borrowerEmailAddress: state.email,
            loanPurpose: account.loanPurposeChoice,
            userId: account.userId,
            occupancyType: account.occupancyTypeChoice,
            propertyType: account.propertyTypeChoice,
            purchaseClosingDate: account.closingDateChoice,
            refinancePriority: account.priorityChoice,
            borrowerFirstName: state.firstName,
            borrowerLastName: state.lastName,
            borrowerPhoneNumber: state.mobile,
            borrowerMiddleName: account.borrowerMiddleName,
            borrowerNameSuffix: account.borrowerNameSuffix
        };
        props.IsLoading(true);
        accountInformationService.sendEmailVerificationCode(state.deliveryMethod, accountInformation, account.loanGuid)
            .then((response: any) => {
                props.IsLoading(false);
                setMessage({ ...message, display: true, title: "Verification Code", text: response.parsedBody });
            });
    }

    const verifyEmailCode = (email: string, verificationCode: string) => {
        const isValidEmail = (email) ? emailRegExp.test(email) : false;

        console.log(email, "in verifyEmailCode:", isValidEmail);

        if (isValidEmail && verificationCode.length === 6) {
            accountInformationService.verifyEmailVerificationCode(email)
                .then((response: any) => {
                    const emailVerificationData = response.parsedBody
                    const isVerificationCodeValid = (emailVerificationData.verificationCode == verificationCode) ? true : false;

                    //User Already Verified
                    if (emailVerificationData.verified) {
                        setMessage({ ...message, display: true, title: "Verification Code", text: "User already verified. Please go back to login page and try to sign in with your credentials." });
                        return true;
                    }
                    setCodeVerified(isVerificationCodeValid);
                    if (!isVerificationCodeValid) {
                        setMessage({
                            ...message,
                            display: true,
                            title: `${state.deliveryMethod} Verification`,
                            text: `The verification code for email ${email} is invalid.`
                        });
                    }
                }
                );
        } else {
            setCodeVerified(false);
        }
    }

    const showBackToLogin = (): boolean => {
        const baseUrl = window.location.href;
        return baseUrl.includes("//uat.uhmnow") ||
            baseUrl.includes("//qa.uhmnow") ||
            baseUrl.includes("//localhost");
    }

    const isVerificationButtonDisabled = (): boolean | undefined => {
        let isValid = true;
        if ((state.firstName?.trim().length === 0 || state.firstName?.trim().length > 30) || (state.lastName?.trim().length === 0 || state.lastName?.trim().length > 30)) {
            isValid = false;
        } else if ((!state.email || !emailRegExp.test(state.email)) || (!state.mobile || state.mobile.length < 10)) {
            isValid = false
        }
        return isValid;
    }

    React.useEffect(() => {
        accountInformationService.passwordResetQuestions().then((response: any) => {
            var questions = response.parsedBody;
            setResetQuestions(questions);
        });

        let userKey: any;
        if (location.search.indexOf("userKey") > -1) {
            userKey = location.search.split("=").pop();
        }
        if (userKey) {
            readVerificationData(userKey);
            props.accountInformationCheck(true);
        } else {
            setUserAlreadyVerified(false);
            verifyEmailCode(state.email, state.verificationCode);
        }
    }, []);

    const showContent = () => {
        if (!userAlreadyVerified) {
            return (<><div className={styles.signUp}>
                <div className={`${styles.logoContainer} align-center`}>
                    <img src={UhmLogo} alt="Union Home Mortgage" />
                </div>
                <div className={styles.formContainer}>
                    <h2>Create Account</h2>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className={styles.inputGroup}>
                            <input
                                type="text"
                                placeholder="First Name *"
                                autoComplete="off"
                                name={UserAccountFields.FirstName}
                                defaultValue={state.firstName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    dispatch({ type: "firstName", value: e.target.value })
                                }
                                ref={register({
                                    required: Validation.ValidationMessage.Required,
                                    maxLength: { value: 30, message: "The value entered exceeds the maximum length requirement." },
                                })} />
                            <span className={styles.invalidField}> <ErrorMessage message={errors.FirstName?.message}></ErrorMessage></span>
                        </div>

                        <div className={styles.inputGroup}>

                            <input
                                type="text"
                                placeholder="Last Name *"
                                autoComplete="off"
                                name={UserAccountFields.LastName}
                                defaultValue={state.lastName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    dispatch({ type: "lastName", value: e.target.value })
                                }
                                ref={register({
                                    required: Validation.ValidationMessage.Required,
                                    maxLength: { value: 30, message: "The value entered exceeds the maximum length requirement." },
                                })} />
                            <span className={styles.invalidField}><ErrorMessage message={errors.LastName?.message}></ErrorMessage></span>
                        </div>

                        <div className={styles.inputGroup}>
                            <input
                                type="text"
                                name={UserAccountFields.Email}
                                placeholder="Email Address *"
                                autoComplete="off"
                                className="input-long"
                                defaultValue={state.email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch({ type: "email", value: e.target.value });
                                    verifyEmailCode(e.target.value, state.verificationCode);
                                    setEmailCheck(!emailRegExp.test(e.target.value));
                                }} />
                            {emailCheck && <span className={styles.invalidField}>Please enter a valid email address.</span>}
                            <span className={styles.invalidField}> <ErrorMessage message={errors.Email?.message}></ErrorMessage></span>
                        </div>

                        <div className={styles.inputGroup}>
                            <>
                                <NumberFormatInput
                                    placeholder="Mobile Number *"
                                    className="input-long"
                                    format="(###) ###-####"
                                    value={state.mobile ?? ""}
                                    name={UserAccountFields.MobileNumber}

                                    onValueChange={(values: NumberFormatValues) => {
                                        if (values) {
                                            if (values.value.trim().length == 0) {
                                                setValidMobile(false);
                                            } else {
                                                setValidMobile(true);
                                            }
                                        }
                                        dispatch({ type: "mobile", value: values.value.trim() });
                                    }}
                                    onBlur={(values: any) => {
                                        if (values.target.value.length == 0) {
                                            setValidMobile(false);
                                        } else {
                                            setValidMobile(true);
                                        }
                                    }}
                                />
                                <>
                                    {
                                        !isValidMobile &&
                                        <span className={styles.invalidField}> <ErrorMessage message={"Field is required."}></ErrorMessage></span>
                                    }

                                    {
                                        (state.mobile && state.mobile.length < 10) &&
                                        <span className={styles.invalidField}> <ErrorMessage message={"Please enter 10 digits mobile number here."}></ErrorMessage></span>
                                    }
                                </>
                            </>
                        </div>

                        <div className={styles.deliveryMethodRow}>
                            <div className={styles.deliveryMethod}>
                                <input
                                    value={"Email"}
                                    id="email"
                                    name="deliverymethod"
                                    type="radio"
                                    checked={state.deliveryMethod === "Email"}
                                    onChange={() => {
                                        dispatch({ type: "deliveryMethod", value: "Email" });
                                    }}
                                />
                                <label>
                                    Email me with the code
                                    </label>

                            </div>

                            <div className={styles.deliveryMethod}>
                                <input
                                    type="radio"
                                    checked={state.deliveryMethod === "Text"}
                                    value={"Text"}
                                    id="mobile"
                                    name="deliverymethod"
                                    onChange={() => {
                                        dispatch({ type: "deliveryMethod", value: "Text" });
                                    }}
                                />
                                <label>
                                    Text me the code
                                    </label>

                            </div>
                        </div>

                        <div>
                            <button type="button" disabled={!isVerificationButtonDisabled()}
                                onClick={sendVerificationCode}>Send Verification Code</button>
                        </div>

                        <div className={styles.inputGroup}>
                            <input
                                type="text"
                                name={UserAccountFields.VerificationCode}
                                placeholder="Verification Code *"
                                autoComplete="off"
                                className="input-long"
                                defaultValue={state.verificationCode}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch({ type: "verificationCode", value: e.target.value });
                                    verifyEmailCode(state.email, e.target.value);
                                }}
                                ref={register({
                                    required: Validation.ValidationMessage.Required,
                                    maxLength: { value: 6, message: "The value entered exceeds the maximum length requirement." }
                                })} />
                            <span className={styles.invalidField}> <ErrorMessage message={errors.VerificationCode?.message}></ErrorMessage></span>
                        </div>

                        <div className={styles.inputGroup}>
                            <input disabled={!codeVerified}
                                type={!showPassword ? "password" : "text"}
                                name={UserAccountFields.Password}
                                placeholder="Password *"
                                autoComplete="off"
                                className={codeVerified ? "input-long" : styles.disabledInput}
                                defaultValue={state.password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    dispatch({ type: "password", value: e.target.value })
                                }
                                ref={register({
                                    required: Validation.ValidationMessage.Required
                                })} />
                            <span className={styles.passwordShow}>
                                <i className={!showPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setShowPassword(prevState => !prevState)}></i>
                            </span>
                            <span className={styles.invalidField}> <ErrorMessage message={errors.Password?.message}></ErrorMessage></span>
                            {codeVerified &&
                                <div className={styles.passwordFormat}>
                                    <PasswordChecklist
                                        rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                        minLength={8}
                                        value={state.password}
                                        onChange={(isValid) => { setPasswordValid(isValid); }}
                                        messages={{
                                            minLength: "At least 8 character(s)",
                                            specialChar: "At least 1 special character(s)",
                                            number: "At least 1 number(s)",
                                            capital: "At least 1 uppercase letter(s)",
                                            lowercase: "At least 1 lowercase letter(s)"
                                        }}
                                    />
                                </div>}
                        </div>

                        <div className={styles.inputGroup}>
                            <input disabled={!codeVerified}
                                type={!showConfirmPassword ? "password" : "text"}
                                name={UserAccountFields.ConfirmPassword}
                                placeholder="Confirm Password *"
                                autoComplete="off"
                                className={codeVerified ? "input-long" : styles.disabledInput}
                                defaultValue={state.confirmPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    dispatch({ type: "confirmPassword", value: e.target.value })
                                }
                                ref={register({
                                    required: Validation.ValidationMessage.Required
                                })} />
                            <span className={styles.passwordShow}>
                                <i className={!showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setShowConfirmPassword(prevState => !prevState)}></i>
                            </span>
                            {codeVerified &&
                                <div className={styles.passwordFormat}>
                                    <PasswordChecklist
                                        rules={["match"]}
                                        minLength={8}
                                        value={state.password}
                                        valueAgain={state.confirmPassword}
                                        onChange={(isValid) => { setPasswordMatched(isValid); }}
                                        messages={{
                                            match: "",
                                        }}
                                    />
                                </div>}
                        </div>

                        <div className={styles.inputGroup}>
                            <SelectDropDown disabled={!passwordMatched}
                                className={passwordMatched ? `input-long ${styles.resetQuestion}` : `${styles.disabledInput} ${styles.resetQuestion}`}
                                name={UserAccountFields.ResetQuestion}
                                value={state.resetQuestion}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    dispatch({ type: "resetQuestion", value: e.target.value })
                                }
                                rule={{ required: Validation.ValidationMessage.Required }}
                                invalidclass={styles.invalidField}
                                noArrow={false}
                            >
                                <option disabled value="">Password recovery question *</option>
                                {resetQuestions.map((m, idx) => <option value={m} key={idx}>{m}</option>)}
                            </SelectDropDown>
                            <span className={styles.invalidField}> <ErrorMessage message={errors.ResetQuestion?.message}></ErrorMessage></span>
                        </div>

                        <div className={styles.inputGroup}>
                            <input disabled={!passwordMatched}
                                type="text"
                                name={UserAccountFields.ResetAnswer}
                                placeholder="Password recovery answer *"
                                autoComplete="off"
                                className={passwordMatched ? "input-long" : styles.disabledInput}
                                defaultValue={state.resetAnswer}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch({ type: "resetAnswer", value: e.target.value });
                                }}
                                ref={register({
                                    required: Validation.ValidationMessage.Required
                                })} />
                            <span className={styles.invalidField}> <ErrorMessage message={errors.ResetAnswer?.message}></ErrorMessage></span>
                        </div>

                        <button type="submit"
                            disabled={!passwordValid || !passwordMatched || !codeVerified || state.resetQuestion === "" || !isVerificationButtonDisabled()}
                            className={styles.actionButton}>
                            Register
                                    </button>
                    </form>

                    {showBackToLogin() &&
                        <a className={styles.backSignIn} onClick={login}>Back to sign in.</a>
                    }
                </div>

                {message.display &&
                    <MessageBox title={message.title}
                        messages={[message.text]}
                        show={message.display}
                        close={() => setMessage({ ...message, display: false })}
                        login={login}
                    ></MessageBox>
                }
            </div></>)
        } else {
            return (<>
                <div className={styles.sessionBox}>
                    <div className={styles.sessionExpired}>
                        <i className="fa fa-clock-o"></i>
                        <h1>Session Expired!</h1>
                        <a className={styles.backSignIn} onClick={login}>Back to sign in.</a>
                    </div>
                </div>
            </>);
        }
    }

    return (
        <>
            {
                account.isLoading ? < Loading /> : showContent()
            }

        </>
    );
};

const ErrorMessage = (props: { message: string | undefined | null }) => {
    return (
        <>
            {props.message && <div className={styles.invalidField}><i className="fa fa-exclamation-circle"></i>{props.message}</div>}
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    submitInitialAccountInformation: (value: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.submitInitialAccountInformation(value));
    },
    setLoanGuid: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.setLoanGuid(value));
    },
    setInterimLoanStagingId: (value?: number) => {
        dispatch(loanActionCreators.setInterimLoanStagingId(value));
},
    isLoancreated: (value: boolean) => {
        dispatch(userAcountActionCreators.isLoancreated(value));
    },
    IsLoading: (value?: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.IsLoading(value));
    },
    //These are also using in AccountInformation.tsx file
    updateLoanPurposeChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateLoanPurposeChoice(value));
    },
    updateUserId: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateUserId(value));
    },
    updateOccupancyTypeChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateOccupancyTypeChoice(value));
    },
    updatePropertyTypeChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updatePropertyTypeChoice(value));
    },
    updateClosingDateChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateClosingDateChoice(value));
    },
    updatePriorityChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updatePriorityChoice(value));
    },
    updateHowManyUnitsChoice: (value?: AccountInformationInterfaces.HowManyUnitsChoices) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateHowManyUnitsChoice(value));
    },
    updateBorrowerFirstName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerFirstName(value));
    },
    updateBorrowerMiddleName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerMiddleName(value));
    },
    updateBorrowerLastName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerLastName(value));
    },
    updateBorrowerNameSuffix: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerNameSuffix(value));
    },
    updateBorrowerEmail: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerEmail(value));
    },
    updateBorrowerPhoneNumber: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerPhoneNumber(value));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(LoanUpdateResponseActions.loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    accountInformationCheck: (value: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.accountInformationCheck(value));
    }
});


function mapStateToProps(state: ApplicationState) {
    const loanGuid = state.accountInformation?.loanGuid;
    const firstName = state.accountInformation?.borrowerFirstName;
    const lastName = state.accountInformation?.borrowerLastName;
    const email = state.accountInformation?.borrowerEmail;

    return {
        loanGuid,
        firstName,
        lastName,
        email
    };
}

export default connect((state: ApplicationState) => mapStateToProps(state), mapDispatchToProps)(UserSignUp as any) as any;