import React, { useEffect, useState } from "react";
import styles from "./Checkbox.module.scss";

interface CheckboxProps {
    onChange?: (checked: boolean) => void;
    onClick?: (checked: boolean) => any;
    name?: string;
    checked?: boolean;
    className?: string;
    reference?: any;
    label?: any;
    disabled?: boolean;
}

const Checkbox = (props: CheckboxProps) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (props.onChange)
            props.onChange(checked);
    }, [checked]);

    useEffect(() => {
        if (props.checked !== undefined) {
            setChecked(props.checked);
        }
    }, [props.checked]);

    return (
        <label className={styles.checkBoxLabel}>
            <div className={styles.customCheckbox + " " + props.className ?? ""}>
                <input
                    name={props.name}
                    type="checkbox"
                    onChange={(e) => {
                        setChecked(e.target.checked);
                    }}
                    onClick={(e: any) => {
                        if (props.onClick)
                            props.onClick(e.target.checked)
                    }}
                    checked={checked}
                    ref={props.reference}
                    disabled={props.disabled}
                />
            </div>
            {props.label && <span>{props.label}</span>}
        </label>
    );
};

export default Checkbox;
