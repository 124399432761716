import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ApplicationState } from "../../store";
import { AccountCreationState } from "../../store/accountCreationStore";
import { loanState } from "../../store/loanStore";
import { AssetInformationState } from "../../store/assetInformationStore";
import * as assetInformationAction from "../../actions/assetInformationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import * as assetInformationStore from "../../store/assetInformationStore";
import FinicityMessage from './FinicityMessage'
import { useNavigation } from "../../hooks/useNavigation";
import SubmitVoaModal from "./SubmitVoaModal";
import MessageBox from "../common/MessageBox";
import Infographic from "../common/Infographic";
import styles from "./assetInformation.module.scss"
import borrowerInfoGraphic from "../../assets/infographics/assetInformation/SelectFinicityorManual.svg";
import TrainingVideos from "./TrainingVideos";
import { TaskItem, SubTaskItem, TaskStatusId } from "../common/progressTracker/TaskStatus";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import * as assetInformationService from "../../services/assetInformationService";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type AssetInformationProps = assetInformationStore.AssetInformationState
    & typeof assetInformationAction.assetInformationActionCreators
    & typeof TaskMenuCreators & typeof loanUpdateResponseActionCreators;

export enum AssetInformationStep {
    SystematicOrManualEntry = 1,
    CoBorrowerSystematicOrManualEntry = 2,
    BorrowerWishQuestion = 3,
}

const AssetInformation = (props: AssetInformationProps) => {
    const [step, setStep] = React.useState(AssetInformationStep.SystematicOrManualEntry);
    const [borrowerType, setBorrowerType] = React.useState(assetInformationStore.BorrowerType.PrimaryBorrower);
    const [modal, setModal] = React.useState(false);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState([""]);
    const { loanGuid } = useSelector((state: ApplicationState) => state.accountInformation as loanState);
    const assetInfo = useSelector((state: ApplicationState) => state.assetInformation as AssetInformationState);
    const accountInfo = useSelector((state: ApplicationState) => state.accountCreation as AccountCreationState);
    const loanInfo = useSelector((state: ApplicationState) => state.loan as loanState);
    const [showLoader, setLoader] = React.useState(false);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    const { interimLoanStagingId } = useSelector((state: ApplicationState) => state.loan as loanState);

    
    const navigation = useNavigation(() => {
        navigation.ShowContinue(() => onContinue(), isEncompassDown);
    });

    const history = useHistory();

    React.useEffect(() => {
        if (history.location.state?.args === "coborrower") {
            setStep(AssetInformationStep.BorrowerWishQuestion)
        }
        else if (history.location.state?.args === "borrower") {
            setStep(AssetInformationStep.SystematicOrManualEntry);
        }
    }, [history.location]);

    React.useEffect(() => {
        switch (step) {
            case AssetInformationStep.SystematicOrManualEntry:
                setBorrowerType(assetInformationStore.BorrowerType.PrimaryBorrower);
                navigation.SetBackOnClick(() => onBack());
                navigation.ResetContinue();
                break;
            case AssetInformationStep.CoBorrowerSystematicOrManualEntry:
                setBorrowerType(assetInformationStore.BorrowerType.CoBorrower);
                navigation.SetBackOnClick(() => onBack());
                navigation.ResetContinue();
                break;
            case AssetInformationStep.BorrowerWishQuestion:
                setBorrowerType(assetInformationStore.BorrowerType.CoBorrower);
                navigation.SetBackOnClick(() => onBack());
                navigation.ShowContinue(() => {
                    updateCoBorrowerAssetStatusByBorrower();
                }, (((assetInfo.coBorrowerAssetStatus == true || assetInfo.coBorrowerAssetStatus == false) ? false : true) || isEncompassDown));
                break;
            default:
                navigation.ResetNavigation();
                break;
        }
    });

    React.useEffect(() => {
        if (accountInfo.borrower.printOnAdditionalBorrowerPage) {
            assetInformationService.readCoBorrowerAssetsStatus(interimLoanStagingId ?? 0).then((res: any) => {
                if (res) {
                    props.updateCoBorrowerAssetStatus(res.parsedBody);
                }
            })
        }
    }, []);

    const onContinue = async () => {
    };

    const onBack = () => {
        if (step === AssetInformationStep.SystematicOrManualEntry) {
            props.setLoanIsDirty(false);
            history.push({ pathname: "/additional-income", state: { args: accountInfo.borrower?.printOnAdditionalBorrowerPage ? "coborrower" : "borrower" } });
        }
        else if (step === AssetInformationStep.CoBorrowerSystematicOrManualEntry) {
            setStep(AssetInformationStep.BorrowerWishQuestion);
        }
        else if (step === AssetInformationStep.BorrowerWishQuestion) {
            history.replace({ state: { args: "borrower" } });
        }
    };

    const updateCoBorrowerAssetStatusByBorrower = () => {
        if (!loanInfo.isLoanCompleted && interimLoanStagingId !== undefined && interimLoanStagingId > 0 && loanGuid) {
            setLoader(true);
            assetInformationService.updateCoBorrowerAssetStatus(interimLoanStagingId, assetInfo.coBorrowerAssetStatus)
                .then((response: any) => {
                    if (!response.ok) {
                        throw new Error("There was a problem submitting your Asset verification request.");
                    }
                    else {
                        setLoader(false);
                        if (response.parsedBody == null) {
                            props.setIsEncompassDown(true);
                            history.push("/dashboard");
                        }
                        else {
                            if (assetInfo.coBorrowerAssetStatus) {
                                setStep(AssetInformationStep.CoBorrowerSystematicOrManualEntry);
                            }
                            else {
                                props.updateNavigationStatus(TaskItem.BorrowerREO, SubTaskItem.BorrowerREO, TaskStatusId.InProgress, TaskStatusId.InProgress, loanGuid);//update next in nav bar
                                props.updateNavigationStatus(TaskItem.AssetInformation, SubTaskItem.CoBorrowerAssetInformation, TaskStatusId.Completed, TaskStatusId.Completed, loanGuid);
                                history.push({ pathname: "/reo-properties" });
                            }
                        }
                    }
                })
                .catch((error: Error) => {
                    setLoader(false);
                    console.log(error);
                });
        }
        else {
            if (assetInfo.coBorrowerAssetStatus) {
                setStep(AssetInformationStep.CoBorrowerSystematicOrManualEntry);
            }
            else {
                history.push({ pathname: "/reo-properties" });
            }
        }
    }
    const submittedVoa = (connectUrl: string) => {
        setModal(false);
        setShowConfirmation(true);
        if (!isNullOrWhiteSpace(connectUrl)) {
            window.open(connectUrl, "_blank");
        }
       
    }
    const fincityConfirmation = () => {
        setShowConfirmation(false)
        if (step === AssetInformationStep.SystematicOrManualEntry && borrowerType === assetInformationStore.BorrowerType.PrimaryBorrower
            && !accountInfo.borrower.printOnAdditionalBorrowerPage) {
            if (!loanInfo.isLoanCompleted) {
                props.updateNavigationStatus(TaskItem.BorrowerREO, SubTaskItem.BorrowerREO, TaskStatusId.InProgress, TaskStatusId.InProgress, loanGuid);//update next in nav bar
                props.updateNavigationStatus(TaskItem.AssetInformation, SubTaskItem.AssetInformation, TaskStatusId.Completed, TaskStatusId.Completed, loanGuid);
            }
            history.push({ pathname: "/reo-properties" });
        }
        else if (step === AssetInformationStep.SystematicOrManualEntry && borrowerType === assetInformationStore.BorrowerType.PrimaryBorrower
            && accountInfo.borrower.printOnAdditionalBorrowerPage) {

            props.updateNavigationStatus(TaskItem.AssetInformation, SubTaskItem.AssetInformation, TaskStatusId.Completed, TaskStatusId.Completed, loanGuid);
            props.updateNavigationStatus(TaskItem.AssetInformation, SubTaskItem.CoBorrowerAssetInformation, TaskStatusId.InProgress, TaskStatusId.InProgress, loanGuid);
            history.replace({ state: { args: "coborrower" } });
            setStep(AssetInformationStep.BorrowerWishQuestion);
        }
        else if (step === AssetInformationStep.CoBorrowerSystematicOrManualEntry) {
            if (!loanInfo.isLoanCompleted) {
                props.updateNavigationStatus(TaskItem.BorrowerREO, SubTaskItem.BorrowerREO, TaskStatusId.InProgress, TaskStatusId.InProgress, loanGuid);//update next in nav bar
                props.updateNavigationStatus(TaskItem.AssetInformation, SubTaskItem.CoBorrowerAssetInformation, TaskStatusId.Completed, TaskStatusId.Completed, loanGuid);
            }
            history.push({ pathname: "/reo-properties" });
        }
    };
    const getBorrowerAssetData = () => {
        const borrowerAssetData: assetInformationStore.BorrowerAsset = {
            birthDate: borrowerType === assetInformationStore.BorrowerType.PrimaryBorrower
                ? assetInfo.borrower.birthDate
                : assetInfo.coBorrower.birthDate,
            taxIdentificationIdentifier: borrowerType === assetInformationStore.BorrowerType.PrimaryBorrower
                ? assetInfo.borrower.taxIdentificationIdentifier
                : assetInfo.coBorrower.taxIdentificationIdentifier,
            borrowerType: borrowerType,
            loanGuid: loanGuid
        };
        return borrowerAssetData;
    }

    const moveToManualAsset = () => {
        if (step === AssetInformationStep.SystematicOrManualEntry && borrowerType === assetInformationStore.BorrowerType.PrimaryBorrower) {
            history.push({ pathname: "/manual-asset-information", state: { args:"borrower"}});
        }
        else {
            history.push({ pathname: "/manual-asset-information", state: { args: "coborrower" } });
        }
    };

    let assetInformationPage = <div />;

    if (step === AssetInformationStep.SystematicOrManualEntry || step === AssetInformationStep.CoBorrowerSystematicOrManualEntry) {
        assetInformationPage =
            <div>
                <div className="infographic-side-layout-container">
                    <div className="content">
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Verification Of Assets For " + (borrowerType === assetInformationStore.BorrowerType.PrimaryBorrower ? accountInfo.borrower.firstName + " " + accountInfo.borrower.lastName : accountInfo.coBorrower.firstName + " " + accountInfo.coBorrower.lastName))}>Verification Of Assets For
                            {borrowerType === assetInformationStore.BorrowerType.PrimaryBorrower ?
                                ` ${accountInfo.borrower.firstName} ${accountInfo.borrower.lastName}` :
                                ` ${accountInfo.coBorrower.firstName} ${accountInfo.coBorrower.lastName}`}
                        </h1>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Now, let's see how much home you can afford. Would you like to submit your asset information using your financial accounts")}>Now, let's see how much home you can afford.  Would you like to submit your asset information using your financial accounts?</h2>
                        <div className={styles.manualInfo}>
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to Yes')} disabled={loanInfo.isLoanCompleted} className={`common-green-button ${styles.button}`} onClick={() => setModal(true)}>Yes!</button>
                            <TrainingVideos />
                        </div>
                        <FinicityMessage></FinicityMessage>
                    <a onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("No, I'll enter the info manually")} onClick={() => moveToManualAsset()} className={styles.entertheInfo}>No, I'll enter the info manually</a>
                    </div>
                    <div className="infographic-side-panel">
                        <Infographic src={borrowerInfoGraphic} />
                    </div>

                </div>

            </div>
    }
    else if (step === AssetInformationStep.BorrowerWishQuestion) {
        assetInformationPage =
            <div>
                <div className="infographic-side-layout-container">
                    <div className="content">
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Verification Of Assets For" + accountInfo.coBorrower.firstName + " " + accountInfo.coBorrower.lastName)}>Verification Of Assets For
                                {` ${accountInfo.coBorrower.firstName} ${accountInfo.coBorrower.lastName}`}
                        </h1>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Do you wish to proceed with entering in the Co-Borrowers asset information using their financial accounts')}>Do you wish to proceed with entering in the Co-Borrower's asset information using their financial accounts?</h2>
                    <div className={styles.manualInfo}>
                        <button disabled={loanInfo.isLoanCompleted} className={`common-button ${(assetInfo.coBorrowerAssetStatus === true) ? "active" : ""}`}
                            onClick={() => !loanInfo.isLoanCompleted ? props.updateCoBorrowerAssetStatus(true) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to yes')}>Yes</button>
                        <button disabled={loanInfo.isLoanCompleted} className={`common-button ${(assetInfo.coBorrowerAssetStatus === false) ? "active" : ""}`}
                            onClick={() => !loanInfo.isLoanCompleted ? props.updateCoBorrowerAssetStatus(false) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to no')}>No</button>
                        </div>
                    </div>
                    <div className="infographic-side-panel">
                        <Infographic src={borrowerInfoGraphic} />
                    </div>

                </div>

            </div>
    }

    return <div className="w-100-p">
        {assetInformationPage}
        {showLoader &&
            <div className="screenLoader"><div className="loader medium"></div></div>
        }
        {modal && (
            <SubmitVoaModal
                modal={modal}
                close={() => setModal(false)}
                submitted={submittedVoa}
                errors={(errMsgs) => { setShowError(true); setErrorMessages(errMsgs); setModal(false); }}
                borrowerAsset={getBorrowerAssetData()}
            ></SubmitVoaModal>
        )}
        {showConfirmation && (
            <MessageBox
                title="Verification of Asset"
                messages={["The verification of assets was requested successfully."]}
                show={showConfirmation}
                close={() => fincityConfirmation()}
            ></MessageBox>
        )}
        {showError && (
            <MessageBox
                title="Error Submitting Request"
                messages={errorMessages}
                show={showError}
                close={() => setShowError(false)}
            ></MessageBox>
        )}
        
    </div>
};


const mapDispatchToProps = (dispatch: any) => ({
    updateBorrowerAssetProperty: (value: string, propertyName: string, borrowerType: assetInformationStore.BorrowerType) => {
        dispatch(assetInformationAction.assetInformationActionCreators.updateBorrowerAssetProperty(value, propertyName, borrowerType));
    },
    SubmitBorrowerAssetInformation: (borrowerType: number) => {
        dispatch(assetInformationAction.assetInformationActionCreators.SubmitBorrowerAssetInformation(borrowerType));
    },
    updateNavigationStatus: (taskItemId: any, subTaskItemId: any, taskStatusId: any, subTaskStatusId: any, loanGuid: any) => {
        dispatch(TaskMenuCreators.updateNavigationStatus(taskItemId, subTaskItemId, taskStatusId, subTaskStatusId, loanGuid));
    },
    UpsertAsyncsystematicInformation: (data: assetInformationStore.BorrowerAsset) => {
        dispatch(assetInformationAction.assetInformationActionCreators.UpsertAsyncsystematicInformation(data));
        dispatch(TaskMenuCreators.setLoanIsDirty(false));
    },
    setLoanIsDirty: (isDirty: boolean) => {
        dispatch(TaskMenuCreators.setLoanIsDirty(isDirty));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    updateCoBorrowerAssetStatus: (value: boolean) => {
        dispatch(assetInformationAction.assetInformationActionCreators.updateCoBorrowerAssetStatus(value));
    },
});

export default connect((state: ApplicationState) => state.accountCreation, mapDispatchToProps)(AssetInformation as any);