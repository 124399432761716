import React, { useRef, useState, useEffect } from "react";

interface BottomScrollValidatorProps {
  hasReachedBottom: () => any;
  className?: string;
  children?: any;
}

const BottomScrollValidator = (props: BottomScrollValidatorProps) => {
  const listInnerRef = useRef(null);

  const [reachedBottom, setReachedBottom] = useState(false);

  const hasReachedBottom = () => setReachedBottom(true);

  useEffect(() => {
    if (reachedBottom) {
      props.hasReachedBottom();
    }
  }, [reachedBottom]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current as any;
      if (scrollTop + clientHeight > scrollHeight - 1) {
        hasReachedBottom();
      }
    }
  };

  return (
    <div className={props.className} onScroll={() => onScroll()} ref={listInnerRef}>
      {props.children}
    </div>
  );
};

export default BottomScrollValidator;
