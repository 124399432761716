import React, { } from "react";
import { useDispatch } from "react-redux";
import * as PropertyInformationActions from "../../actions/propertyInformationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import styles from "./propertyInformation.module.scss";
import { bindActionCreators } from "redux";
import { NumberFormatInput } from "components/common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";

export interface PropertyInformationCurrencyInputProps {
    value: any | undefined;
    parameterName: string;
    disabled: boolean | undefined;
}

const PropertyInformationCurrencyInput = (
    props: PropertyInformationCurrencyInputProps
) => {
    const actions = {
        ...bindActionCreators(
            {
                ...PropertyInformationActions.propertyInformationActionCreators, ...TaskMenuCreators
            },
            useDispatch()
        ),
    };
    return (
        <div className={`${styles.inputGroup} ${styles.largerInputText}`}>
            <NumberFormatInput
                disabled={props.disabled}
                value={props.value ?? ""}
                onValueChange={(values: NumberFormatValues) => {

                    actions.updatePropertyInformationField(
                        values.value,
                        props.parameterName,
                        false
                    );
                    actions.setLoanIsDirty(true);
                }}
                placeholder="$00.00"
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={0}
            />
        </div>
    );
};

export default PropertyInformationCurrencyInput;
