import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { UserAccountState } from "../../store/userAccountStore";
import { AccountInformationState, IAccountInformationProps } from "../../store/accountInformationStore";
import * as AccountInformationActions from "../../actions/accountInformationActions";
import * as LoanUpdateResponseActions from "../../actions/loanUpdateResponseActions";
import LoanPurpose from "./LoanPurpose";
import OccupancyType from "./OccupancyType";
import PropertyType from "./PropertyType";
import Priority from "./Priority";
import HowManyUnits from "./HowManyUnits";
import ClosingDate from "./ClosingDate";
import BorrowerInformation from "./BorrowerInformation";
import * as accountInformationService from "../../services/accountInformationService";
import Infographic from "../common/Infographic";
import accountCreationInfographic from "assets/infographics/accountInformation/account-creation.svg";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./AccountInformation.module.scss";
import { useNavigation } from "hooks/useNavigation";
import { Modal, ModalBody } from "reactstrap";
import queryString from 'query-string';
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import * as AccountInformationInterfaces from "../../interfaces/accountInformationInterfaces";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanActionCreators } from "../../actions/loanActions";
import { BackgroundEngine } from "../common/BackgroundTaskTimer";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

export enum AccountInformationStep {
    LoanPurpose = 1,
    OccupancyType = 2,
    PropertyType = 3,
    HowManyUnits = 4,
    ClosingDate = 5,
    PriorityChoice = 6,
    BorrowerInformation = 7
}

type AccountInformationProps =
    {
        accountInformation: any,
        userAccountInformation: UserAccountState
    }
    & typeof AccountInformationActions.accountInformationActionCreators
    & typeof LoanUpdateResponseActions.loanUpdateResponseActionCreators
    & typeof loanActionCreators;

export interface AccountInformation {
    noOfUnits?: number;
    loanPurpose?: string;
    userId?: string;
    occupancyType?: string;
    propertyType?: string;
    purchaseClosingDate?: string;
    refinancePriority?: string;
    borrowerFirstName?: string;
    borrowerMiddleName?: string;
    borrowerLastName?: string;
    borrowerNameSuffix?: string;
    borrowerEmailAddress?: string;
    borrowerPhoneNumber?: string;
}

const AccountInformation = (props: AccountInformationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [loanPurposeChoices, setLoanPurposeChoices] = useState([]);
    const [occupancyTypeChoices, setOccupancyTypeChoices] = useState([]);
    const [propertyTypeChoices, setPropertyTypeChoices] = useState([]);
    const [closingDateChoices, setClosingDateChoices] = useState([]);
    const [priorityChoices, setPriorityChoices] = useState([]);
    const [isSuccessfullySubmitted, setSuccessfullySubmitted] = useState(false);
    const [loanPurposeParam, setLoanPurposeParam] = React.useState(false);
    const [currentStep, changeStep] = React.useState(AccountInformationStep.LoanPurpose);
    const navigation = useNavigation();
    const { search } = useLocation();
    const history = useHistory();
    const [isError, setIsError] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    const accountInfo = useSelector((state: ApplicationState) => state.accountInformation as AccountInformationState);
    const signUp = () => {
        history.push({ pathname: "/account", state: { action: "create-account", borrowerType: "Borrower", accountInfo: accountInfo } });
    }

    useEffect(() => {
        const values = queryString.parse(search);
        if (!isNullOrWhiteSpace(values.userId as string)) {
            props.updateUserId(values.userId as string);
            getLookups(values.userId as string);
        }
        else {
            getLookups();
        }

    }, []);

    const change = (step: string) => {
        if (step === 'updateLoanPurposeChoice')
            changeStep(AccountInformationStep.LoanPurpose);
        else if (step === 'updateOccupancyTypeChoice')
            changeStep(AccountInformationStep.OccupancyType);
        else if (step === 'updatePropertyTypeChoice')
            changeStep(AccountInformationStep.PropertyType);
        else if (step === 'updateHowManyUnitsChoice')
            changeStep(AccountInformationStep.HowManyUnits);
        else if (step === 'updateClosingDateChoice')
            changeStep(AccountInformationStep.ClosingDate);
        else if (step === 'updatePriorityChoice')
            changeStep(AccountInformationStep.PriorityChoice);
    };

    const onContinue = async () => {
        if (currentStep === AccountInformationStep.LoanPurpose) {
            changeStep(AccountInformationStep.OccupancyType)
        }
        else if (currentStep === AccountInformationStep.OccupancyType) {
            changeStep(AccountInformationStep.PropertyType)
        }
        else if (currentStep === AccountInformationStep.PropertyType) {
            if (props.accountInformation.propertyTypeChoice === "Multi-Unit") {
                changeStep(AccountInformationStep.HowManyUnits)
            }
            if (props.accountInformation.loanPurposeChoice === "Purchase" && props.accountInformation.propertyTypeChoice !== "Multi-Unit") {
                changeStep(AccountInformationStep.ClosingDate)
            }
            else if (props.accountInformation.loanPurposeChoice === "Refinance" && props.accountInformation.propertyTypeChoice !== "Multi-Unit") {
                changeStep(AccountInformationStep.PriorityChoice)
            }
        }
        else if (currentStep === AccountInformationStep.HowManyUnits) {
            if (props.accountInformation.loanPurposeChoice === "Purchase") {
                changeStep(AccountInformationStep.ClosingDate)
            }
            else if (props.accountInformation.loanPurposeChoice === "Refinance") {
                changeStep(AccountInformationStep.PriorityChoice)
            }
        }
        else if (currentStep === AccountInformationStep.ClosingDate) {
            console.log("IN CLOSING DATE", props)
            if (!props.accountInformation.borrowerEmail) {
                changeStep(AccountInformationStep.BorrowerInformation)
            } else {
                escapeBorrowerInfoAndCreateLoan();
            }
        }
        else if (currentStep === AccountInformationStep.PriorityChoice && !props.accountInformation.borrowerEmail) {
            changeStep(AccountInformationStep.BorrowerInformation)
        } else {
            escapeBorrowerInfoAndCreateLoan();
        }
    }

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    const escapeBorrowerInfoAndCreateLoan = async () => {
       
        props.updateBorrowerEmail(props.userAccountInformation.email);
        props.updateBorrowerFirstName(props.userAccountInformation.firstName);
        props.updateBorrowerLastName(props.userAccountInformation.lastName);

        //Create Loan for existing user

        let accountInformation: IAccountInformationProps = {
            noOfUnits: props.accountInformation.howManyUnitsChoice,
            borrowerEmailAddress: props.accountInformation.borrowerEmail,
            loanPurpose: props.accountInformation.loanPurposeChoice,
            userId: props.accountInformation.userId,
            occupancyType: props.accountInformation.occupancyTypeChoice,
            propertyType: props.accountInformation.propertyTypeChoice,
            purchaseClosingDate: props.accountInformation.closingDateChoice,
            refinancePriority: props.accountInformation.priorityChoice,
            borrowerFirstName: props.userAccountInformation.firstName,
            borrowerLastName: props.userAccountInformation.lastName,
            borrowerPhoneNumber: "",
            borrowerMiddleName: "",
            borrowerNameSuffix: "",
            borrowerType: props.accountInformation.borrowerType ?? ""
        };

        props.IsLoading(true);
        setIsLoading(true);

        props.setLoanGuid("");
        props.setIsLoanCompleted(false);
        props.setIsLoanFromEncompassFlag(false);
        props.setInterimLoanStagingId(0);

        await accountInformationService.create(accountInformation, "")
            .then((response: any) => {
                if (response.status != 200) {
                    return;
                }              

                let result = response.parsedBody as LoanUpdateResponseState;

                if (result.isEncompassDown || result.loanGuid === null || result.loanGuid === "") {
                    console.log("Encompass service is down or loanGuid not present");
                    history.push({ pathname: "/system-unavailable" });
                    return;
                }

                if (accountInformation.userId == undefined) {
                    accountInformation.userId = "";
                }

                props.IsLoading(false);

                setIsLoading(false);

                props.setLoanGuid(result.loanGuid);
                props.setInterimLoanStagingId(result.interimLoanStagingId);

                if (result.loanGuid) {
                    BackgroundEngine.InitializeAndRun(result.loanGuid);
                }

                history.push({ pathname: "/econsent", state: { isUserLoggedIn: true } });

                return result;
            })
            .catch((error: Error) => {
                console.error("Error occurred during loan creation: ", error);
            });
    }

    const getLookups = async (userId?: string) => {
        await accountInformationService
            .getAll(userId ?? "")
            .then((response) => {
                return response.parsedBody as any;
            })
            .then((data: any) => {
                if (data !== undefined) {
                    setLoanPurposeChoices(data.loanPurposes);
                    setOccupancyTypeChoices(data.occupancyTypes);
                    setPropertyTypeChoices(data.propertyTypes);
                    setClosingDateChoices(data.purchaseClosingDates);
                    setPriorityChoices(data.refinancePriorities);
                }
            });
    };

    useEffect(() => {
        if (props.accountInformation.initialAccountInformationSubmitted) {

            setSuccessfullySubmitted(true);
            props.accountInformationCheck(true);
        }
    }, [props.accountInformation.initialAccountInformationSubmitted])

    const redirectToHome = () => {
        setIsError(false);
        history.push({ pathname: "/" });
        window.location.reload();
    };

    let renderTemplate = <></>;
    let accountInfoPage = <></>;

        if (currentStep === AccountInformationStep.LoanPurpose) {
            accountInfoPage = (<>
                <LoanPurpose data={loanPurposeChoices} updateLoanPurposeChoice={props.updateLoanPurposeChoice}
                    loanPurpose={props.accountInformation.loanPurposeChoice} loanPurposeParam={loanPurposeParam} />
                {navigation.ShowContinue(
                    () => onContinue(),
                    !props.accountInformation.loanPurposeChoice
                )}
            </>);
        } else if (currentStep === AccountInformationStep.OccupancyType) {
            accountInfoPage = (
                <>
                    <OccupancyType data={occupancyTypeChoices} updateOccupancyTypeChoice={props.updateOccupancyTypeChoice}
                        occupancyType={props.accountInformation.occupancyTypeChoice} />
                    {navigation.SetBackOnClick(() => change('updateLoanPurposeChoice'))}
                    {navigation.ShowContinue(
                        () => onContinue(),
                        !props.accountInformation.occupancyTypeChoice
                    )}
                </>
            );
        } else if (currentStep === AccountInformationStep.PropertyType) {
            accountInfoPage = (
                <>
                    <PropertyType data={propertyTypeChoices} updatePropertyTypeChoice={props.updatePropertyTypeChoice}
                        propertyType={props.accountInformation.propertyTypeChoice} loanPurpose={props.accountInformation.loanPurposeChoice} />
                    {navigation.SetBackOnClick(() => change('updateOccupancyTypeChoice'))}
                    {navigation.ShowContinue(
                        () => onContinue(),
                        !props.accountInformation.propertyTypeChoice
                    )}
                </>
            );
        } else if (currentStep === AccountInformationStep.HowManyUnits && props.accountInformation.propertyTypeChoice === "Multi-Unit") {
            accountInfoPage = (
                <>
                    <HowManyUnits updateHowManyUnitsChoice={props.updateHowManyUnitsChoice}
                        howManyUnits={props.accountInformation.howManyUnitsChoice} loanPurpose={props.accountInformation.loanPurposeChoice}
                    />
                    {navigation.SetBackOnClick(() => change('updatePropertyTypeChoice'))}
                    {navigation.ShowContinue(
                        () => onContinue(),
                        !props.accountInformation.howManyUnitsChoice
                    )}
                </>
            );
        } else if (props.accountInformation.loanPurposeChoice === "Purchase" && currentStep === AccountInformationStep.ClosingDate) {
            var backPage =
                props.accountInformation.howManyUnitsChoice === undefined ? (
                    navigation.SetBackOnClick(() => change('updatePropertyTypeChoice'))) :
                    (navigation.SetBackOnClick(() => change('updateHowManyUnitsChoice')));
            accountInfoPage = (
                <>
                    <ClosingDate data={closingDateChoices} updateClosingDateChoice={props.updateClosingDateChoice}
                        closingDate={props.accountInformation.closingDateChoice}
                    />
                    {backPage}
                    {navigation.ShowContinue(
                        () => onContinue(),
                        !props.accountInformation.closingDateChoice
                    )}
                </>
            );
        } else if (props.accountInformation.loanPurposeChoice === "Refinance" && currentStep === AccountInformationStep.PriorityChoice) {
            var backPage =
                props.accountInformation.howManyUnitsChoice === undefined ? (
                    navigation.SetBackOnClick(() => change('updatePropertyTypeChoice'))) :
                    (navigation.SetBackOnClick(() => change('updateHowManyUnitsChoice')));
            accountInfoPage = (
                <>
                    <Priority data={priorityChoices} updatePriorityChoice={props.updatePriorityChoice}
                        priorityChoice={props.accountInformation.priorityChoice}
                    />
                    {backPage}
                    {navigation.ShowContinue(
                        () => onContinue(),
                        !props.accountInformation.priorityChoice
                    )}
                </>
            );
        } else if (!props.accountInformation.initialAccountInformationSubmitted || (props.accountInformation.initialAccountInformationSubmitted && !isSuccessfullySubmitted)) {
            let backPage = props.accountInformation.priorityChoice === undefined ? (navigation.SetBackOnClick(() => change('updateClosingDateChoice'))) : (navigation.SetBackOnClick(() => change('updatePriorityChoice')));
            accountInfoPage = (
                <>
                    {isError &&
                        <Modal isOpen={isError} centered={true} returnFocusAfterClose={false}>
                            <ModalBody className={`${styles.modalBody}`}>
                                <div className="align-center">
                                    <h3 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Error")}>Error</h3>
                                    <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("An unexpected error occurred.")}>An unexpected error occurred.</p>
                                    <div>
                                    <button className="common-button" onClick={() => redirectToHome()} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("press button to close.")}> Close </button>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    }
                    <BorrowerInformation
                        updateBorrowerFirstName={props.updateBorrowerFirstName}
                        updateBorrowerMiddleName={props.updateBorrowerMiddleName}
                        updateBorrowerLastName={props.updateBorrowerLastName}
                        updateBorrowerNameSuffix={props.updateBorrowerNameSuffix}
                        updateBorrowerEmail={props.updateBorrowerEmail}
                        updateBorrowerPhoneNumber={props.updateBorrowerPhoneNumber}
                        submitInitialAccountInformation={props.submitInitialAccountInformation}
                        borrowerFirstName={props.accountInformation.borrowerFirstName}
                        borrowerMiddleName={props.accountInformation.borrowerMiddleName}
                        borrowerLastName={props.accountInformation.borrowerLastName}
                        borrowerNameSuffix={props.accountInformation.borrowerNameSuffix}
                        borrowerEmail={props.accountInformation.borrowerEmail}
                        borrowerPhoneNumber={props.accountInformation.borrowerPhoneNumber}
                    />
                    {props.accountInformation.isLoading &&
                        (<div className="align-center screenLoader">
                            <div className="loader"></div>
                        </div>
                        )}
                    {backPage}
                </>
            );
        } else if (props.accountInformation.initialAccountInformationSubmitted && isSuccessfullySubmitted) {
            accountInfoPage = (
                <div className="infographic-side-layout-container">
                    <div className="content">
                        <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Account Creation")}>Account Creation</h1>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Thank you! Please follow the link below to finish setting up your account.")}>Thank you! Please follow the link below to finish setting up your account.</h2>
                        <button className="common-green-button" onClick={signUp} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Create an Account")}>Create an Account</button>
                    </div>
                    <div className="infographic-side-panel">
                        <Infographic src={accountCreationInfographic} />
                    </div>
                    {navigation.HideNavigation()}
                </div>
            );
        }

        renderTemplate = <div className={styles.accountInformation}>{accountInfoPage}</div>;
    
    return (
        <>
            {isLoading ? <div className="align-center"> <div className="loader" /></div> : renderTemplate}
        </>);
};

const mapDispatchToProps = (dispatch: any) => ({

    updateLoanPurposeChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateLoanPurposeChoice(value));
    },
    updateUserId: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateUserId(value));
    },
    updateOccupancyTypeChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateOccupancyTypeChoice(value));
    },
    updatePropertyTypeChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updatePropertyTypeChoice(value));
    },
    updateClosingDateChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateClosingDateChoice(value));
    },
    updatePriorityChoice: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updatePriorityChoice(value));
    },
    updateHowManyUnitsChoice: (value?: AccountInformationInterfaces.HowManyUnitsChoices) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateHowManyUnitsChoice(value));
    },
    updateBorrowerFirstName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerFirstName(value));
    },
    updateBorrowerMiddleName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerMiddleName(value));
    },
    updateBorrowerLastName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerLastName(value));
    },
    updateBorrowerNameSuffix: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerNameSuffix(value));
    },
    updateBorrowerEmail: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerEmail(value));
    },
    updateBorrowerPhoneNumber: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerPhoneNumber(value));
    },
    submitInitialAccountInformation: (value: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.submitInitialAccountInformation(value));
    },
    setLoanGuid: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.setLoanGuid(value));
    },
    IsLoading: (value?: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.IsLoading(value));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(LoanUpdateResponseActions.loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    accountInformationCheck: (value: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.accountInformationCheck(value));
    },
    setInterimLoanStagingId: (value?: number) => {
        dispatch(loanActionCreators.setInterimLoanStagingId(value));
    },
    setIsLoanFromEncompassFlag: (value?: boolean) => {
        dispatch(loanActionCreators.setIsLoanFromEncompassFlag(value));
    },
    setIsLoanCompleted: (value?: boolean) => {
        dispatch(loanActionCreators.setIsLoanCompleted(value));
    }
});

function mapStateToProps(state: ApplicationState) {

    const userAccountInformation: UserAccountState | undefined = state.userAccountInformation;

    const accountInformation: AccountInformationState | undefined = state.accountInformation;

    return {
        accountInformation,
        userAccountInformation
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountInformation as any);