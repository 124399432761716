import { useEffect, useRef } from "react";

/**
 * The useInterval hook enables use to cleanly use the setInterval method without having to deal with any of the React weirdness.
 *
 * Original Source: [Making setInterval Declarative with React Hooks - Dan Abramov](https://overreacted.io/making-setinterval-declarative-with-react-hooks/)
 *
 * @param callback Method to be run on the interval
 * @param delay Time delay between exectutions. Set to null to pause the interval
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback as any;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        (savedCallback.current as any)();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
