import * as React from "react";
import { ApplicationState } from "store";
import { connect } from "react-redux";
import styles from "./TaskMenu.module.scss";
import * as TaskMenuActions from "../../../actions/taskMenuAction";
import Currency from "../Currency";
import { LoanSummaryDto } from "../../../store/taskMenuStore";
import { InterimLoanSummary } from "../../../store/interimLoanSummaryStore";
import { interimLoanSummaryActionCreators } from "../../../actions/interimLoanSummaryActions";

type TaskMenuHeaderProps = {
    loanGuid: string;
    isLoading: boolean;
    loanSummary: LoanSummaryDto;
    interimLoanStagingId: number;
    currentLoanSummary: InterimLoanSummary;
} & typeof TaskMenuActions.TaskMenuCreators
    & typeof interimLoanSummaryActionCreators;

const TaskMenuHeaders = (props: TaskMenuHeaderProps) => {

    React.useEffect(() => {
        if (props.loanGuid ) {
            props.borrowerLoanSummary(props.loanGuid, props.interimLoanStagingId);
        }
    }, []);

    return (
        <>
            <div className={styles.menuBorrowerDetails}>
                {props.isLoading ?
                    <div className="align-center">
                        <div className="loader"></div>
                    </div> :
                    <>
                        <div className={styles.application}>My <span>{props.loanSummary?.loanPurpose}</span> Application</div>
                        <div>{props.loanSummary?.firstName} {props.loanSummary?.lastName}</div>
                        <div className={styles.listDetails}>
                            <div>
                                <span>Loan Number:</span> <span>{props.currentLoanSummary.loanNumber}</span>
                            </div>
                            <div>
                                <span>Loan Amount:</span>
                                <span>
                                    {props.currentLoanSummary.loanAmount ?
                                        <Currency value={props.currentLoanSummary.loanAmount} decimalScale={0}></Currency>
                                        : "TBD"
                                    }
                                </span>
                            </div>
                            <div>
                                <span>Subject Property:</span>
                                <span>
                                    {
                                        (props.currentLoanSummary?.city && props.currentLoanSummary?.state && props.currentLoanSummary?.zip) ?
                                            props.currentLoanSummary?.city + ", " + props.currentLoanSummary?.state + " " + props.currentLoanSummary?.zip
                                            : "TBD"
                                    }
                                </span>
                            </div>
                        </div>
                    </>
                }
            </div>

        </>
    );
};

function mapStateToProps(state: ApplicationState) {
    let { accountInformation, taskMenu, interimLoanSummary } = state;
    let loanGuid;
    let isLoading;
    let loanSummary;
    let interimLoanStagingId;

    let currentLoanSummary;

    if (accountInformation?.loanGuid) {
        loanGuid = accountInformation?.loanGuid;
    }

    if (interimLoanSummary?.currentLoanSummary) {
        interimLoanStagingId = interimLoanSummary.currentLoanSummary.interimLoanStagingId;
        currentLoanSummary = { ...interimLoanSummary.currentLoanSummary }
    }

    if (taskMenu?.loanSummary) {
        loanSummary = taskMenu?.loanSummary;
        isLoading = taskMenu?.loanSummary?.isLoading;
    }

    //if (state.loan) {
    //    interimLoanStagingId = state.loan?.interimLoanStagingId;
    //}
    return {
        loanGuid,
        isLoading,
        loanSummary,
        interimLoanStagingId,
        currentLoanSummary
    };
}

export default connect((state: ApplicationState) => mapStateToProps(state), TaskMenuActions.TaskMenuCreators)(TaskMenuHeaders as any) as any;

