import InfographicSideLayout from "components/common/Layout/InfographicSideLayout";
import pendingInfographic from "../../assets/infographics/creditReport/pending.svg";
import styles from "./CreditReport.module.scss";
import React, { useEffect } from "react";
import { useNavigation } from "hooks/useNavigation";
import { useHistory } from "react-router";
import { useInitialMount } from "hooks/useInitialMount";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const CreditReportPending = (props: { isCoBorrowerPresent: boolean }) => {
    const history = useHistory();
    const navigation = useNavigation();
    const isInitialMount = useInitialMount();
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    useEffect(() => {
        if (isInitialMount) {
            navigation.SetBackOnClick(() => {
                history.push({ pathname: "/create-account", state: { args: props.isCoBorrowerPresent ? "coborrower" : "borrower", isBack: true } });
            });
        }
    }, [isInitialMount]);
    return (
        <InfographicSideLayout infographic={pendingInfographic}>
            <div className={styles.pendingContainer}>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please select Save/Continue button to proceed to the next step')}>Please select Save/Continue button to proceed to the next step</h1>
            </div>
        </InfographicSideLayout>
    );
};

export default CreditReportPending;
