// ACCOUNT INFORMATION
export const UpdateLoanPurposeChoice = "UPDATE_LOAN_PURPOSE_CHOICE";
export const UpdateUserId = "UPDATE_USER_ID";
export const UpdateOccupancyTypeChoice = "UPDATE_OCCUPANCY_TYPE_CHOICE";
export const UpdatePropertyTypeChoice = "UPDATE_PROPERTY_TYPE_CHOICE";
export const UpdateClosingDateChoice = "UPDATE_CLOSING_DATE_CHOICE";
export const UpdatePriorityChoice = "UPDATE_PRIORITY_CHOICE";
export const UpdateHowManyUnitsChoice = "UPDATE_HOW_MANY_UNITS_CHOICE";
export const UpdateBorrowerFirstName = "UPDATE_BORROWER_FIRST_NAME";
export const UpdateBorrowerMiddleName = "UPDATE_BORROWER_MIDDLE_NAME";
export const UpdateBorrowerLastName = "UPDATE_BORROWER_LAST_NAME";
export const UpdateBorrowerNameSuffix = "UPDATE_BORROWER_NAME_SUFFIX";
export const UpdateBorrowerPhoneNumber = "UPDATE_BORROWER_PHONE_NUMBER";
export const UpdateBorrowerEmail = "UPDATE_BORROWER_EMAIL";
export const SubmitInitialAccountInformation = "SUBMIT_INITIAL_ACCOUNT_INFORMATION";
export const AccountInformationCheck = "ACCOUNT_INFORMATION_CHECK";
export const SetBorrowerType = "SET_BORROWER_TYPE";

// LOAN INFORMATION
export const SetLoanGuid = "SET_LOAN_GUID";
export const SetIsLoanCompleted = "SET_IS_LOAN_COMPLETED";
export const SetIsEncompassDown = "SET_IS_ENCOMPASS_DOWN";
export const SetIsLoanFromEncompassFlag = "SET_IS_LOAN_FROM_ENCOMPASS_FLAG";
export const SetInterimLoanStagingId = "SET_INTERIM_LOAN_STAGING_ID";

// USER ACCOUNT INFORMATION
export const UpdateUserFirstName = "UPDATE_USER_FIRST_NAME";
export const UpdateUserLastName = "UPDATE_USER_LAST_NAME";
export const UpdateUserEmail = "UPDATE_USER_EMAIL";
export const UpdateUserBorrowerType = "UPDATE_USER_BORROWER_TYPE";
export const UpdateUserConsent = "UPDATE_USER_CONSENT";
export const IsLoancreated = "IS_LOAN_CREATED";

// LOAN ACCOUNT CREATION
export const UpdateAddressMoveInDate = "UPDATE_ADDRESS_MOVE_IN_DATE";
export const UpdateBorrowerProperty = "UPDATE_BORROWER_PROPERTY";
export const UpdateBorrowerAddressProperty = "UPDATE_BORROWER_ADDRESS_PROPERTY";
export const SetAddressSameAsBorrower = "SET_ADDRESS_SAME_AS_BORROWER";
export const ResetFormerAddress = "RESET_FORMER_ADDRESS";
export const SetBorrowerValidation = "SET_BORROWER_VALIDATION"
export const AddAddressProperty = "ADD_ADDRESS_PROPERTY";
export const EditAddressProperty = "EDIT_ADDRESS_PROPERTY";
export const DeleteAddressProperty = "DELETE_ADDRESS_PROPERTY";
export const GetBorrowerInformation = "GET_BORROWER_INFORMATION";
export const UpdateOktaResult = "UPDATE_OKTA_RESULT";

// PROPERTY INFORMATION
export const UPDATE_PROPERTY_INFORMATION_FIELD = "UPDATE_PROPERTY_INFORMATION_FIELD";
export const ReceivePropertyInformation = "RECEIVE_PROPERTY_INFORMATION";


// EMPLOYMENT INFORMATION
export const UpdateEmploymentInformationProperty = "UPDATE_EMPLOYMENT_INFORMATION_PROPERTY";
export const SubmitEmploymentInformation = "SUBMIT_EMPLOYMENT_INFORMATION";
export const SubmittedEmploymentInformation = "SUBMITTED_EMPLOYMENT_INFORMATION";
export const AddEmploymentItem = "ADD_EMPLOYMENT_ITEM";
export const DeleteEmploymentItem = "DELETE_EMPLOYMENT_ITEM";
export const ResetEmploymentItem = "RESET_EMPLOYMENT_ITEM";
export const GetEmploymentInformation = "GET_EMPLOYMENT_IFORMATION";
export const ReIndexingEmployment = "RE_INDEXING_EMPLOYMENT";

// ASSET INFORMATION
export const SUBMIT_BORROWER_ASSET_INFORMATION = "SUBMIT_BORROWER_ASSET_INFORMATION";
export const UPDATE_BORROWER_ASSET_PROPERTY = "UPDATE_BORROWER_ASSET_PROPERTY";
export const UpdateSystematicAsset = "UPDATE_Systematic_ASSET";
export const ReadSystematicAsset = "READ_SYSTEMATIC_ASSET";
export const UpdateCoBorrowerAssetStatus = "UPDATE_COBORROWER_ASSET_STATUS";

// NAVIGATION
export const ResetNavigation = "RESET_NAVIGATION";
export const ResetNavigationFromRoute = "RESET_NAVIGATION_FROM_ROUTE";
export const SetContinue = "SET_CONTINUE";
export const SetBackOnClick = "SET_BACK_ONCLICK";
export const ShowContinue = "SHOW_CONTINUE";
export const SetContinueDisabled = "SET_CONTINUE_DISABLED";
export const ResetContinue = "RESET_CONTINUE";
export const HideNavigation = "HIDE_NAVIGATION";
export const RenameNavigation = "RENAME_NAVIGATION";

// ADDITIONALINCOME INFORMATION
export const CreateOtherIncomeSourceItem = "CREATE_OTHER_INCOME_SOURCE_ITEM";
export const DeleteOtherIncomeSourceItem = "DELETE_OTHER_INCOME_SOURCE_ITEM";
export const RemoveOtherIncomeSourceItemList = "REMOVE_OTHER_INCOME_SOURCE_ITEM_LIST";
export const UpdateOtherIncomeSourceInformation = "UPDATE_OTHER_INCOME_SOURCE_INFORMATION";
export const UpdateAdditionalIncomeInfo = "UPDATE_ADDITIONAL_INCOME_INFO";
export const SetOtherIncomeSourceValidation = "SET_OTHER_INCOME_SOURCE_VALIDATION";
export const HandleErrorAdditional_incomeSource = "HANDLE_ERROR_ADDITIONAL_INCOME_SOURCE";
export const ClearExceptionAdditionalIncomeSource = "CLEAR_EXCEPTION_ADDITIONAL_INCOME_SOURCE";
export const RequestAdditionalIncomeSource = "REQUEST_ADDITIONAL_INCOME_SOURCE";
export const ReceiveAdditionalIncomeSource = "RECEIVE_ADDITIONAL_INCOME_SOURCE";
export const UpdateIncomeState = "UPDATE_INCOME_STATE";

//Zip code lookup
export const RequestZipCodeLookup = "REQUEST_ZIP_CODE_LOOKUP";
export const ReceiveZipCodeLookup = "RECEIVE_ZIP_CODE_LOOKUP";
export const HandleErrorZipCodeLookup = "HANDLE_ERROR_ZIP_CODE_LOOKUP";
export const ClearExceptionZipCodeLookup = "CLEAR_EXCEPTION_ZIP_CODE_LOOKUP";
export const ClearZipDetails = "CLEAR_ZIP_DETAILS";
// Asset
export const CreateManualAssetInformation = "CREATE_MANUAL_ASSET_INFORMATION";
export const UpdateManualAssetProperty = "UPDATE_MANUAL_ASSET_PROPERTY";
export const RemoveManualAssetItem = "REMOVE_MANUAL_ASSET_ITEM";
export const GetManualAssetItem = "GET_MANUAL_ASSET_ITEM";
export const SetManualAssetValidation = "SET_MANUAL_ASSET_VALIDATION";
export const RequestAsset = "REQUEST_ASSET";
export const ReceiveAsset = "RECEIVE_ASSET";
export const HandleErrorAsset = "HANDLE_ERROR_ASSET";
export const RemoveManualAssetItemFromInterim = "REMOVE_MANUAL_ASSET_ITEM_From_INTERIM";
export const ReceiveEncompassAsset = "RECEIVE_ENCOMPASS_ASSET";
export const ReceiveSystematicAsset = "RECEIVE_SYSTEMATIC_ASSET";
export const RequestEncompassAsset = "REQUEST_ENCOMPASS_ASSET";


// GOVERNMENT QUESTIONS
export const UpdateGovernmentQuestions = "UPDATE_GOVERNMENT_QUESTIONS";
export const SubmitGovernmentQuestions = "SUBMIT_GOVERNMENT_QUESTIONS";
export const UpdateGovernmentAdditionalQuestions = "UPDATE_GOVERNMENT_ADDITIONAL_QUESTIONS";
export const UpdateBorrowerCompleted = "UPDATE_BORROWER_COMPLETED";
export const SubmittedGovernmentQuestions = "SUBMITTED_GOVERNMENT_QUESTIONS";
export const RequestGovernmentQuestions = "REQUEST_GOVERNMENT_QUESTIONS";
export const ReceiveGovernmentQuestions = "RECEIVE_GOVERNMENT_QUESTIONS";
export const UpdateToInterim = "UPDATE_TO_INTERIM";

// BORROWER REO
export const UpdateHasAdditionalProperties = "UPDATE_HAS_ADDITIONAL_PROPERTIES";
export const AddReoProperty = "ADD_REO_PROPERTY";
export const UpdateToCache = "UPDATE_TO_CACHE";
export const GetReoDetails = "GET_REO_DETAILS";
export const UpdateReoInformationField = "UPDATE_REO_FIELD";
export const RemoveProperty = "REMOVE_PROPERTY";

//BORROWER LIABILITY
export const CreateBorrowerLiability = "CREATE_BORROWER_LIABILITY";
export const DeleteBorrowerLiability = "DELETE_BORROWER_LIABILITY";
export const UpdateBorrowerLiabilityInformation = "UPDATE_BORROWER_LIABILITY_INFORMATION";
export const SetBorrowerLiabilityValidation = "SET_BORROWER_LIABILITY_VALIDATION";
export const ReceiveBorrowerLiability = "RECEIVE_BORROWER_LIABILITY";

// DEMOGRAPHIC QUESTIONS
export const GetDemographicQuestions = "GetDemographicQuestions";
export const UpdateDemographicQuestions = "UPDATE_DEMOGRAPHIC_QUESTIONS";
export const SubmitDemographicQuestions = "SUBMIT_DEMOGRAPHIC_QUESTIONS";
export const RequestDemographicQuestions = "REQUEST_DEMOGRAPHIC_QUESTIONS";
export const RecieveDemographicQuestions = "RECEIVE_DEMOGRAPHIC_QUESTIONS";

// CREDIT AUTHORIZATION
export const UpdateCreditAuthorizationProperty = "UPDATE_CREDIT_AUTHORIZATION_PROPERTY";
export const SubmitConsumerCreditAuthorization = "SUBMIT_CONSUMER_CREDIT_AUTHORIZATION";
export const RequestConsumerCreditAuthorization = "REQUEST_CONSUMER_CREDIT_AUTHORIZATION";
export const ReceiveConsumerCreditAuthorization = "RECEIVE_CONSUMER_CREDIT_AUTHORIZATION";
export const UpdateCreditReportIndicator = "UPDATE_CREDIT_REPORT_INDICATOR";

// Credit Information
export const SetCreditInformationModel = "SET_CREDIT_INFORMATION_MODEL";
export const SetBorrowerDetail = "SET_CREDIT_INFORMATION_BORROWER_DETAIL";
export const SetCreditInformationValid = "SET_CREDIT_INFORMATION_VALID";


// REVIEW SCREEN
export const SetReviewScreenValidation = "SET_REVIEW_SCREEN_VALIDATION";
export const UpdateAddressFields = "UPDATE_ADDRESS_FIELD";
export const CalculateEstimatedLoanAmount = "CALCULATE_ESTIMATED_LOANAMOUNT";
export const UpdateReoAddressField = "UPDATE_REO_ADDRESS_FIELD";
export const SubmitCheckFlag = "SUBMIT_CHECK_Flag";
export const SetLoanFromEncompassMessageFlag = "SET_LOAN_FROM_ENCOMPASS_MESSAGE_Flag";

// Credit Report
export const CompleteCreditReport = "COMPLETE_CREDIT_REPORT";
export const StartCreditReport = "START_CREDIT_REPORT";
export const SetCreditReportStatus = "SET_CREDIT_REPORT_STATUS";

// Pre-Qual Systematic Letter
export const RequestSystematicLetter = "REQUEST_SYSTEMATIC_LETTER";
export const ReceiveSystematicLetter = "RECEIVE_SYSTEMATIC_LETTER";

//Loader 
export const IsLoading = "ISLOADING";

// Documents
export const AlterSectionExpandedStatus = "ALTER_SECTION_EXPANDED_STATUS";
export const AlterSelectedTab = "ALTER_SELECTED_TAB";


// Progress Tracker
export const GetSubTaskItemLoanStatus = "GET_SUB_TASK_ITEM_LOAN_STATUS";
export const ReceiveTaskMenu = "RECEIVE_TASK_MENU";
export const RequestTaskMenu = "REQUEST_TASK_MENU";
export const ReceiveBorrowerInformation = "RECEIVE_BORROWER_INFO";
export const SetLoanIsDirty = "SET_LOAN_IS_DIRTY";
export const SetPopupFlag = "SET_POPUP_FLAG";

//Dashboard
export const GetDashboardDetails = "GET_DASHBOARD_DETAILS";
export const HandleErrorDashboard = "HANDLE_ERROR_DASHBOARD";
export const ReceiveNotificationMessages = "RECEIVE_NOTIFICATION_MESSAGES";

//Milestone navigation
export const ReceiveMilestoneDetails = "RECEIVE_MILESTONE_DETAILS";

// Loan Officer Main Tab 
export const RequestApplicationFee = "REQUEST_APPLICATION_FEE";
export const ReceiveApplicationFee = "RECEIVE_APPLICATION_FEE";
export const RequestCreditReports = "REQUEST_CREDIT_REPORTS";
export const ReceiveCreditReports = "RECEIVE_CREDIT_REPORTS";
export const SetLoanOfficerViewLoanGuid = "SET_LOAN_OFFICER_VIEW_LOAN_GUID";
export const SetSelectedTab = "SET_SELECTED_TAB";
export const UpdatePreApproval = "UPDATE_PREAPPROVAL";
export const SetPreApprovalValidation = "SET_PREAPPROVAL_VALIDATION"

//County lookup
export const RequestCountyLookup = "REQUEST_County_LOOKUP";
export const ReceiveCountyLookup = "RECEIVE_County_LOOKUP";
export const HandleErrorCountyLookup = "HANDLE_ERROR_County_LOOKUP";
export const ClearExceptionCountyLookup = "CLEAR_EXCEPTION_County_LOOKUP";
export const ClearCountyDetails = "CLEAR_County_DETAILS";

//InterimLoanSummary
export const ReceiveLoanOfficerDetail = "RECEIVE_LOAN_OFFICER_DETAIL";
export const GetInterimLoanSummary = "GET_INTERIM_LOAN_SUMMARY";
export const ReceiveInterimLoanSummary = "RECEIVE_INTERIM_LOAN_SUMMARY";
export const ReceiveUpdatedInterimLoanSummary = "RECEIVE_UPDATED_INTERIM_LOAN_SUMMARY";
export const ReadLoanOfficerDetail = "READ_LOAN_OFFICER_DETAIL";
export const SetCurrentInterimLoanSummary = "SET_CURRENT_INTERIM_LOAN_SUMMARY";
export const ReadActiveLoanUserDetail = "RECEIVE_ACTIVE_LOAN_USER_DETAIL";



