import * as React from "react";
import { connect } from "react-redux";
import * as EmploymentInformationStore from "../../store/employmentInformationStore";
import styles from "./reviewApplication.module.scss";
import * as EmploymentInformationActions from "../../actions/employmentInformationActions";
import { ApplicationState } from "../../store";
import { EmploymentInformationFields } from "../employmentInformation/EmploymentInformationFields";
import TextInput from "../common/Input/TextInput";
import moment from 'moment';
import * as ReviewApplicationActions from "../../actions/reviewApplicationActions";
import LocationSearch from "components/common/Input/LocationSearch";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";
import { isNullOrWhiteSpace, isValidDate, toDate } from "../../common/helper/formatHelper";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import Currency from "../common/Currency";
import CustomDatePicker from "../common/CustomDatePicker";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type ReviewEmploymentInformationProps = {
    employmentInformation: EmploymentInformationStore.Employment;
    employmentList: EmploymentInformationStore.Employment[];
    borrowerType: EmploymentInformationStore.BorrowerType;
    isEdit: boolean;
    section: string;
    militryIndicator: boolean;
    setEmploymentInformationValid: (isValid: any, borrowerType: number) => void;
    employerIndex: number;
    disabled: boolean;
}
    & typeof EmploymentInformationActions.employmentInformationActionCreators
    & typeof ReviewApplicationActions.reviewApplicationActionCreators
    & typeof TaskMenuCreators;

const ReviewEmploymentInformation = (props: ReviewEmploymentInformationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [total, setTotal] = React.useState(0);
    const [zipFormat, setZipFormat] = React.useState("#########");

    const updateProperty = (value: any, fieldname: string) => {
        props.UpdateEmploymentInformationProperty(value, fieldname, props.borrowerType, props.employmentInformation.id);
    }

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    React.useEffect(() => {
        if (props.employmentInformation?.addressPostalCode &&
            props.employmentInformation?.addressPostalCode?.length > 5) {
            setZipFormat("#####-####");
            return;
        }
        setZipFormat("#########");
    }, [props.employmentInformation?.addressPostalCode]);

    React.useEffect(() => {
        props.UpdateEmploymentInformationProperty(total, EmploymentInformationFields.TotalAmount, props.borrowerType, props.employmentInformation.id);
    }, [total])

    React.useEffect(() => {
        setTotal((isNullOrWhiteSpace(props.employmentInformation?.otherAmount) ? 0 : +props.employmentInformation?.otherAmount) +
            (isNullOrWhiteSpace(props.employmentInformation?.commissionsAmount) ? 0 : +props.employmentInformation?.commissionsAmount) +
            (isNullOrWhiteSpace(props.employmentInformation?.bonusAmount) ? 0 : +props.employmentInformation?.bonusAmount) +
            (isNullOrWhiteSpace(props.employmentInformation?.basePayAmount) ? 0 : +props.employmentInformation?.basePayAmount) +
            (isNullOrWhiteSpace(props.employmentInformation?.overtimeAmount) ? 0 : +props.employmentInformation?.overtimeAmount));
    }, [props.employmentInformation?.otherAmount, props.employmentInformation?.commissionsAmount,
    props.employmentInformation?.bonusAmount, props.employmentInformation?.basePayAmount,
    props.employmentInformation?.overtimeAmount])

    React.useEffect(() => {
        let isValid = true;
        props.employmentList.forEach(x => {
            if (isValid) {
                isValid = !isNullOrWhiteSpace(x.employerName) &&
                    !isNullOrWhiteSpace(x.positionDescription) &&
                    !isNullOrWhiteSpace(x.urlA2020StreetAddress) &&
                    !isNullOrWhiteSpace(x.addressCity) &&
                    !isNullOrWhiteSpace(x.addressState) &&
                    !isNullOrWhiteSpace(x.addressPostalCode) &&
                    !isNullOrWhiteSpace(props.employmentInformation.basePayAmount);

                if (!isValidDate(toDate(x.employmentStartDate))) {
                    isValid = false;
                }

                if (x.addressPostalCode && x.addressPostalCode.length < 5) {
                    isValid = false;
                }
            }
        });
        props.setEmploymentInformationValid(isValid, props.borrowerType);
    }, [props.employmentInformation?.employerName, props.employmentInformation?.positionDescription,
    props.employmentInformation?.urlA2020StreetAddress, props.employmentInformation?.addressCity,
    props.employmentInformation?.addressState, props.employmentInformation?.addressPostalCode,
    props.employmentInformation?.employmentStartDate, props.employmentInformation?.basePayAmount]);

    const setAddress = (streetValue: string, cityValue: any, stateValue: any, zipValue: any) => {
        props.UpdateEmploymentInformationProperty(streetValue, EmploymentInformationFields.StreetAddress, props.borrowerType, props.employmentInformation.id);
        props.UpdateEmploymentInformationProperty(cityValue, EmploymentInformationFields.AddressCity, props.borrowerType, props.employmentInformation.id);
        props.UpdateEmploymentInformationProperty(stateValue, EmploymentInformationFields.AddressState, props.borrowerType, props.employmentInformation.id);
        props.UpdateEmploymentInformationProperty(zipValue, EmploymentInformationFields.AddressPostalCode, props.borrowerType, props.employmentInformation.id);
    };

    return <>
        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation.currentEmploymentIndicator ? "Current Employer" : "Past Employer")}>{props.employmentInformation.currentEmploymentIndicator ? "Current" : "Past"} Employer <span className={styles.requiredMsg}> * </span>:</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#employerName"
                name="Employer Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateProperty(e.target.value, EmploymentInformationFields.EmployerName)
                }}
                value={props.employmentInformation?.employerName || ""}
                readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.employerName || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Position/Title')}>Position/Title <span className={styles.requiredMsg}> * </span>:</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#positionDescription"
                name="Position"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateProperty(e.target.value, EmploymentInformationFields.PositionDescription)
                }}
                value={props.employmentInformation?.positionDescription || ""}
                readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.positionDescription || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Start Date')}>Start Date <span className={styles.requiredMsg}> * </span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>
                    <CustomDatePicker
                        disabled={props.disabled}
                        fieldValue={props.employmentInformation?.employmentStartDate ? new Date(props.employmentInformation?.employmentStartDate) : undefined}
                        onDateChange={(date: any) => {
                            updateProperty(toDate(date), EmploymentInformationFields.StartDate);
                        }}
                        popperPlacement={false}
                        maxDate={new Date()}
                        />
                </div>
                : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(moment(props.employmentInformation?.employmentStartDate).format('MM/DD/YYYY'))}>{moment(props.employmentInformation?.employmentStartDate).format('MM/DD/YYYY')}</label>
            }
        </div>

        <div className={`${styles.inputGroup} ${styles.addressBox}`}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Employer Address')}>Employer Address <span className={styles.requiredMsg}> * </span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>
                    <LocationSearch
                        isDisabled={props.disabled}
                        addressStreetLine1={props.employmentInformation?.urlA2020StreetAddress || ""}
                        addresssFieldName="urlA2020StreetAddress"
                        borrowerType={props.borrowerType}
                        customType={props.employmentInformation?.id}
                        setFullAddress={setAddress}
                        styles={""}
                    />
                </div>
                :
                <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.urlA2020StreetAddress)}>{props.employmentInformation?.urlA2020StreetAddress}</label>
            }

        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City')}>City <span className={styles.requiredMsg}> * </span>:</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#addressCity"
                name="City"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateProperty(e.target.value, EmploymentInformationFields.AddressCity)
                }}
                value={props.employmentInformation?.addressCity || ""}
                maxLength="20"
                minLength="2"
                readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.addressCity || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State <span className={styles.requiredMsg}> * </span>:</label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#addressState"
                name="State"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateProperty(e.target.value, EmploymentInformationFields.AddressState)
                }}
                value={props.employmentInformation?.addressState || ""}
                maxLength="2"
                minLength="2"
                readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.addressState || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Zip')}>Zip <span className={styles.requiredMsg}> * </span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>
                    <NumberFormatInput
                        disabled={props.disabled}
                        className={styles.numberFormat}
                        value={props.employmentInformation?.addressPostalCode || ''}
                        format={zipFormat}
                        name="addressPostalCode"
                        id="#addressPostalCode"
                        onValueChange={(values: NumberFormatValues) => {
                            updateProperty(values.value, EmploymentInformationFields.AddressPostalCode)
                        }}
                        readOnly={props.isEdit}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.addressPostalCode || '')}/>
                </div>
                : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.addressPostalCode)}>{props.employmentInformation?.addressPostalCode}</label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Years on job')}>Years on job:</label>
            <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.timeOnJobTermYears + 'Years' + props.employmentInformation?.timeOnJobTermMonths + 'Months')}>{props.employmentInformation?.timeOnJobTermYears} Years,
            {" " + props.employmentInformation?.timeOnJobTermMonths} Months</label>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Base Income per month')}>Base Income per month <span className={styles.requiredMsg}> * </span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        name="basePayAmount"
                        className={styles.numberFormat}
                        value={props.employmentInformation?.basePayAmount ?? ""}
                        id="#basePayAmount"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            updateProperty(values.value, EmploymentInformationFields.BasePayAmount)
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.basePayAmount ?? "")}/>
                </div>
                :
                <label className={styles.inputLabel}>
                    <Currency value={Number(props.employmentInformation?.basePayAmount)} decimalScale={2}></Currency>
                </label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Overtime Pay per month (Optional)')}>Overtime Pay per month <span className={styles.optionalMsg}> (Optional) </span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        name="overtimeAmount"
                        className={styles.numberFormat}
                        value={props.employmentInformation?.overtimeAmount ?? ""}
                        id="#overtimeAmount"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            updateProperty(values.value, EmploymentInformationFields.OvertimeAmount)
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.overtimeAmount ?? "")} />
                </div>
                :
                <label className={styles.inputLabel}>
                    <Currency value={Number(props.employmentInformation?.overtimeAmount)} decimalScale={2}></Currency>
                </label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Bonus per month (Optional)')}>Bonus per month <span className={styles.optionalMsg}> (Optional) </span>:</label>

            {!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        name="bonusAmount"
                        className={styles.numberFormat}
                        value={props.employmentInformation?.bonusAmount ?? ""}
                        id="#bonusAmount"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            updateProperty(values.value, EmploymentInformationFields.BonusAmount)
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.bonusAmount ?? "")}/>
                </div>
                :
                <label className={styles.inputLabel}>
                    <Currency value={Number(props.employmentInformation?.bonusAmount)} decimalScale={2}></Currency>
                </label>
            }

        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Commission per month (Optional)')}>Commission per month <span className={styles.optionalMsg}> (Optional) </span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        name="commissionsAmount"
                        className={styles.numberFormat}
                        value={props.employmentInformation?.commissionsAmount ?? ""}
                        id="#commissionsAmount"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            updateProperty(values.value, EmploymentInformationFields.CommissionsAmount)
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.commissionsAmount ?? "")}/>
                </div>
                :
                <label className={styles.inputLabel}>
                    <Currency value={Number(props.employmentInformation?.commissionsAmount)} decimalScale={2}></Currency>
                </label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Other Income per month (Optional)')}>Other Income per month <span className={styles.optionalMsg}> (Optional) </span>:</label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        name="otherAmount"
                        className={styles.numberFormat}
                        value={props.employmentInformation?.otherAmount ?? ""}
                        id="#otherAmount"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            updateProperty(values.value, EmploymentInformationFields.OtherAmount)
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                        decimalScale={2}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.employmentInformation?.otherAmount ?? "")}/>
                </div>
                : <label className={styles.inputLabel}>
                    <Currency value={Number(props.employmentInformation?.otherAmount)} decimalScale={2} ></Currency>
                </label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Total')}>Total:</label>
            <label className={styles.inputLabel}>
                <Currency value={Number(total)} decimalScale={2}></Currency>
            </label>
        </div>
    </>;
};

const mapStateToProps = (state: ApplicationState, ownProps: {
    borrowerType: EmploymentInformationStore.BorrowerType,
    employmentInformation: EmploymentInformationStore.Employment,
    employmentList: EmploymentInformationStore.Employment[],
    isEdit: boolean,
    section: string,
    militryIndicator: boolean,
    setEmploymentInformationValid: (isValid: any, borrowerType: number) => void;
    employerIndex: number;
    isLoanCompleted: boolean
}): any => {
    return state.employmentInformation ?
        {
            employmentInformation: ownProps.employmentInformation,
            borrowerType: ownProps.borrowerType,
            isEdit: ownProps.isEdit,
            section: ownProps.section,
            militryIndicator: ownProps.militryIndicator,
            setEmploymentInformationValid: ownProps.setEmploymentInformationValid,
            employerIndex: ownProps.employerIndex,
            employmentList: ownProps.employmentList,
            disabled: ownProps.isLoanCompleted
        } : state;
}

const mapDispatchToProps = (dispatch: any) => ({
    UpdateEmploymentInformationProperty: (value: any, propertyName: string, borrowerType: EmploymentInformationStore.BorrowerType, employmentItemId: string) => {
        dispatch(EmploymentInformationActions.employmentInformationActionCreators.UpdateEmploymentInformationProperty(value, propertyName, borrowerType, employmentItemId));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewEmploymentInformation as any);