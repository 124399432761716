import { AppThunkAction } from "../store";
import * as ActionTypes from "../common/ActionTypes";
import * as CountyLookup from "../store/CountyLookUpStore";
import { KnownAction } from "../store/CountyLookUpStore";
import * as CountyLookupService from "../services/countyLookupService";

const request = (loanGuid: string): KnownAction => {
    return {
        type: ActionTypes.RequestCountyLookup
    };
};

const clear = (): KnownAction => {
    return { type: ActionTypes.ClearExceptionCountyLookup };
};

const handleError = (errorMessage: string): KnownAction => {
    return { type: ActionTypes.HandleErrorCountyLookup, errorMessage: errorMessage };
};

const receive = (data: CountyLookup.CountyDetail[]): KnownAction => {
    return { type: ActionTypes.ReceiveCountyLookup, data };
};

const clearCountyDetail = (): KnownAction => {
    return { type: ActionTypes.ClearCountyDetails };
};

export const countyLookupActionCreators = {
    getCountyDetail: (zipCode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var state = getState();
        if (state.countyLookUp) {
            state.countyLookUp.isLoading = true;
        }
        dispatch(clear());
        dispatch(request(zipCode));

        CountyLookupService.getCountyDetail(zipCode)
            .then((response) => {
                if (!response.ok) {
                    console.log(`There was a problem getting County details.`);
                }
                return response.parsedBody as CountyLookup.CountyDetail[];
            })
            .then((data) => {
                dispatch(receive(data));
            })
            .catch((error: Error) => {
                dispatch(handleError(error.message));

            });
        if (state.countyLookUp) {
            state.countyLookUp.isLoading = false;
        }
    },
    handleError: (errorMessage: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(handleError(errorMessage));
    },
    clearCountyDetails: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(clearCountyDetail());
    }
};