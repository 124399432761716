import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as DemographicQuestionsStore from "../store/demographicQuestionsStore";
import { KnownAction } from '../store/demographicQuestionsStore';
import * as demographicQuestionsService from "../services/demographicQuestionsService";
import { BorrowerType } from "../store/Enum";

const request = (borrowerType: string, interimLoanStagingId:number): KnownAction => {
    return {
        type: ActionTypes.RequestDemographicQuestions,
        borrowerType,
        interimLoanStagingId
    };
}

const receive = (data: DemographicQuestionsStore.DemographicQuestions, borrowerType: BorrowerType): DemographicQuestionsStore.KnownAction => {
    return {
        type: ActionTypes.RecieveDemographicQuestions,
        borrowerType: borrowerType,
        data,
    };
}

export const demographicQuestionsActionCreators = {

    getDemographicQuestions: (borrowerType: string, interimLoanStagingId:number): AppThunkAction<DemographicQuestionsStore.KnownAction> => (dispatch) => {
        dispatch(request(borrowerType, interimLoanStagingId));
        demographicQuestionsService.getDemographicQuestions(borrowerType, interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + interimLoanStagingId + " .");
                }
                return response.parsedBody as DemographicQuestionsStore.DemographicQuestions;
            })
            .then((data: any) => {
                dispatch(receive(data, data.borrowerType));
            })
            .catch((error: Error) => {
                console.error(error);
            });
    },

    updateDemographicQuestions: (value: boolean, propertyName: string, section: string, borrowerType: BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateDemographicQuestions, value: value, propertyName: propertyName, section: section, borrowerType: borrowerType });
    },

    submitDemographicQuestions: (data: DemographicQuestionsStore.DemographicQuestions): AppThunkAction<KnownAction> => async (dispatch) => {
        await demographicQuestionsService.updateDemographicQuestions(data)
            .then((response) => {
                if (!response.ok) {
                    console.error(response);
                }
                return true;
            })
            .then(isSuccessful => {
                if (!isSuccessful) {
                    console.error("Demographic question details failed to saved.");
                }
            });
    }
}
