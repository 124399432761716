import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import PropertyInformationPurchase from "./PropertyInformationPurchase";
import PropertyInformationRefinance from "./PropertyInformationRefinance";
import { useNavigationWithHistory } from "hooks/useNavigationWithHistory";
import { save } from "../../services/propertyInformationService";
import { useHistory } from "react-router";
import { PropertyInformationState } from "store/propertyInformationStore";
import { bindActionCreators } from "redux";
import { propertyInformationActionCreators } from "../../actions/propertyInformationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";

export enum PropertyInformationStep {
    // Purchase
    PropertyLocation = 1,
    RealtorQuestion = 2,
    DownPaymentSource = 3,
    LoanValueAndTerm = 4,

    // Refinance
    Address = 5,
    RefinanceQuestions = 6,
    Finished = 7
}

const PropertyInformation = () => {
    const [showLoading, setShowLoading] = useState(false);
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [isReadyToSave, setIsReadyToSave] = useState(false);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const history = useHistory();

    const actions = { ...bindActionCreators({ ...propertyInformationActionCreators, ...TaskMenuCreators, ...loanUpdateResponseActionCreators }, useDispatch()), };
    const state: PropertyInformationState = useSelector((state: ApplicationState) => state.propertyInformation as PropertyInformationState);
    const loanGuid = useSelector((state: ApplicationState) => state.accountInformation?.loanGuid ?? undefined);
    const isLoanCompleted = useSelector((state: ApplicationState) => state.loan?.isLoanCompleted ?? undefined);
    const interimLoanStagingId = useSelector((state: ApplicationState) => state.loan?.interimLoanStagingId ?? undefined);

    useEffect(() => {
        if (interimLoanStagingId) {
            actions.getPropertyInformation(interimLoanStagingId);
        }
    }, []);
  

    const { CurrentStep, ChangeStep,StepHistory, Navigation, IsAtInitialStep } =
        useNavigationWithHistory<PropertyInformationStep>(state.purposeType === "Purchase" ? PropertyInformationStep.PropertyLocation
            : PropertyInformationStep.Address);

    useEffect(() => {
        if (IsAtInitialStep) {
            Navigation.SetBackOnClick(() => {
                actions.setLoanIsDirty(false);
                history.push("liability");
            })
        }
    }, [IsAtInitialStep]);

    useEffect(() => {
        if (state.purposeType === "Refinance") {
            ChangeStep(PropertyInformationStep.Address);
        } else if (state.purposeType === "Purchase") {
            ChangeStep(PropertyInformationStep.PropertyLocation);
        }
    }, [state.purposeType]);

    // To ensure that the final redux action has finished, we need to
    //   monitor the values associated with the last step in each process and once they
    //   are set, then we actually call the API
    useEffect(() => {
        if (state.purposeType === "Refinance") {
            setIsReadyToSave(state.currentMortgageBalance !== undefined || state.estimatedCurrentValue !== undefined || state.yearPropertyBuilt !== undefined ||
                            state.taxesPerYear !== undefined || state.homeOwnersInsurancePerYear !== undefined);
        } else {
            setIsReadyToSave(state.loanTermInYears !== undefined);
        }
    }, [state.purposeType, state.loanTermInYears, state.currentMortgageBalance]);

    useEffect(() => {
        if (isReadyToSave && saveTriggered) {
            setSaveTriggered(false);
            if (!isLoanCompleted) {
                savePropertyInformation();
            }
            else {
                history.push({ pathname: "/government-questions", state: { args: "borrower" } });
            }
        }
    }, [isReadyToSave, saveTriggered]);

    useEffect(() => {
        Navigation.SetContinueDisabled(showLoading);
    }, [showLoading]);

    const startSaveProcess = async () => {
        if (!isLoanCompleted) {
            setShowLoading(true);
        }
        setSaveTriggered(true);
    };

    const savePropertyInformation = async () => {
        if (state.purposeType) {
            setShowLoading(true);
            var response = await save(state, state.purposeType, loanGuid);
            if (!response.ok) {
                console.log("error");
            }
            else {
                let result = response.parsedBody as LoanUpdateResponseState;
                if (response.ok && result.isEncompassDown) {
                    actions.setIsEncompassDown(true);
                    history.push("/dashboard");
                }
                else {
                    actions.setLoanIsDirty(false);
                    history.push({ pathname: "/government-questions", state: { args: "borrower" } });
                }
            }

            setShowLoading(false);
        }
    };

    return (
        <div className="w-100-p">

            {StepHistory.includes(PropertyInformationStep.PropertyLocation) && (
                <PropertyInformationPurchase
                    changeStep={ChangeStep}
                    showContinue={(nextStep, isDisabled) => {
                        Navigation.ShowContinue(() => {
                            if (nextStep === PropertyInformationStep.Finished) {
                                startSaveProcess();
                            } else {
                                ChangeStep(nextStep);
                            }
                        }, isDisabled || showLoading || isEncompassDown);
                    }}
                    currentStep={CurrentStep}
                    disabled={isLoanCompleted}
                />
            )}
            {StepHistory.includes(PropertyInformationStep.Address) && (
                <PropertyInformationRefinance
                    changeStep={ChangeStep}
                    resetContinue={() => Navigation.ResetContinue()}
                    setContinueDisabled={(isDisabled) => {
                        Navigation.SetContinueDisabled(isDisabled);
                    }}
                    showContinue={(nextStep, isDisabled) => {
                        Navigation.ShowContinue(() => ChangeStep(nextStep), isDisabled || isEncompassDown);
                    }}
                    setSaveOnContinue={() => {
                        Navigation.ShowContinue(() => startSaveProcess());
                    }}
                    currentStep={CurrentStep}
                    disabled={isLoanCompleted}
                />
            )}
            {showLoading && (
                <div className="align-center screenLoader">
                    <div className="loader medium"></div>
                </div>
            )}
        </div>
    );
};

export default PropertyInformation;
