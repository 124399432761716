import * as React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import Infographic from "components/common/Infographic";
import preQualLetternfographic from "assets/infographics/systematicLetter/prequal-letter.svg";
import * as SystematicLetterActions from "../../actions/systematicLetterActions";
import * as SystematicLetterStore from "../../store/systematicLetterStore";
import styles from "./LoanOfficer.module.scss";
import { useReactToPrint } from 'react-to-print';
import { LoanOfficerPreQualLetterContent } from "components/loanOfficerView/LoanOfficerPreQualLetterContent";
import * as systematicLetterService from "../../services/systematicLetterService";

type PreQualLetterLetterDisplayProps =
    SystematicLetterStore.SystematicLetterState & {
        loanGuid: string;
        data: SystematicLetterStore.SystematicLetter;
        interimLoanStagingId: number;
    }
    & typeof SystematicLetterActions.systematicLetterActionCreators;

const LoanOfficerPreQualLetterDisplay = (props: PreQualLetterLetterDisplayProps) => {
    const componentRef = React.useRef(null);
    const [showLoader, setShowLoader] = React.useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleDownload = () => {
        setShowLoader(true);
        if (props.interimLoanStagingId && props.interimLoanStagingId > 0) {
            systematicLetterService.generateSystematicInvoicePdf(props.interimLoanStagingId)
                .then((response: any) => {
                    if (response.status == 200 && response.data.size > 0) {
                        const file = new Blob([response.data], { type: "application/pdf" });
                        const fileURL = URL.createObjectURL(file);
                        const fileName = response.headers["x-file-name"];
                        downloadData(fileURL, fileName);
                    }
                    else {
                        console.error("there is some problem while generating pre-qual systematic letter pdf file in loan officer view");
                    }
                    setShowLoader(false);
                })
                .catch((error: Error) => {
                    setShowLoader(false);
                    console.error(error);
                });
        }
    }

    const downloadData = (fileURL: string, fileName: string) => {
        const save = document.createElement('a');
        if (typeof save.download !== 'undefined') {
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName;
            save.dispatchEvent(new MouseEvent('click'));
        } else {
            window.location.href = fileURL;
        }
    }


    return (
        <>
            <div className="infographic-side-layout-container">
                <div className="content">
                    <div className={styles.headingAlign}>
                        <h2 >Your Prequalification Letter</h2>
                    </div>
                    <LoanOfficerPreQualLetterContent ref={componentRef} data={props.data} />
                    <div className={styles.printDownloadBTN}>
                        <button className="common-green-button" onClick={handlePrint}>
                            Print  </button>
                        <button className="common-button" onClick={handleDownload}>
                            Download  </button>
                    </div>
                    <div className={styles.loaderBg}>
                        {showLoader && (<div className="loader"></div>)}
                    </div>

                    </div> 
                <div className="infographic-side-panel">
                    <Infographic src={preQualLetternfographic} />
                </div>
            </div>
        </>
    );

};

const mapStateToProps = (state: ApplicationState): any => {
    const { accountInformation, systematicLetter } = state;
    let loanGuid = accountInformation?.loanGuid;
    let data;
    if (systematicLetter && systematicLetter.data) {
        data = systematicLetter.data;
    }
    let interimLoanStagingId = state.loan?.interimLoanStagingId;
    return {
        loanGuid,
        data,
        interimLoanStagingId
    }
}

export default connect(
    mapStateToProps,
    SystematicLetterActions.systematicLetterActionCreators
)(LoanOfficerPreQualLetterDisplay as any);