import React, { useEffect, useState } from "react";
import CreditReportScores from "./CreditReportScores";
import CreditReportPending from "./CreditReportPending";
import CreditReportError from "./CreditReportError";
import { useInterval } from "hooks/useInterval";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { ReportStatus } from "store/Enum";
import { useCreditReportActions } from "actions/creditReportActions";
import { useInitialMount } from "hooks/useInitialMount";
import styles from "./CreditReport.module.scss";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { AccountCreationState } from "../../store/accountCreationStore";

const CreditReport = () => {
    const [isPolling, setIsPolling] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isError, setIsError] = useState(false);
    const accountInfo = useSelector((state: ApplicationState) => state.accountCreation as AccountCreationState);

    const state = useSelector((state: ApplicationState) => state.creditReport);
    const loanGuid = useSelector((state: ApplicationState) => state.loan?.loanGuid);
    const borrowerName = useSelector(
        (state: ApplicationState) =>
            state.accountCreation?.borrower?.firstName +
            " " +
            state.accountCreation?.borrower?.lastName
    );
    const coBorrowerName = useSelector(
        (state: ApplicationState) =>
            state.accountCreation?.coBorrower?.firstName +
            " " +
            state.accountCreation?.coBorrower?.lastName
    );
    const actions = useCreditReportActions();
    const isInitialMount = useInitialMount();

    useEffect(() => {
        if (isInitialMount && loanGuid) {
            actions.StartCreditReport();
        }
    }, [isInitialMount, loanGuid]);

    useInterval(
        () => {
            actions.QueryCreditReportStatus();
        },
        isPolling ? 10000 : null
    );

    useEffect(() => {
        setIsPolling(
            state?.status != null &&
            state.status != ReportStatus.Complete &&
            state.status != ReportStatus.ValuesProcessed &&
            state.status != ReportStatus.Error
        );

        setIsError(state?.status === ReportStatus.Error);

        const valuesShouldExist =
            state?.status === ReportStatus.Complete ||
            state?.status === ReportStatus.ValuesProcessed;

        if (valuesShouldExist && state?.borrowerScore == null) {
            actions.QueryCreditValues();
        }
       
    }, [state?.status]);

    useEffect(() => {
        setIsComplete((state?.borrowerScore ?? 0) > 0);
    }, [state?.borrowerScore]);

    return (
        <div className={`w-100-p ${styles.creditAuth}`}>
            {isError && <CreditReportError isCoBorrowerPresent={isNullOrWhiteSpace(accountInfo.coBorrower?.firstName) ? false : true}></CreditReportError>}
            {!isError && (
                <>
                    {!isComplete && <CreditReportPending isCoBorrowerPresent={isNullOrWhiteSpace(accountInfo.coBorrower?.firstName) ? false : true}></CreditReportPending>}
                    {isComplete && (
                        <CreditReportScores
                            borrowerName={borrowerName}
                            borrowerScore={state?.borrowerScore ?? 0}
                            coBorrowerName={coBorrowerName}
                            coBorrowerScore={state?.coBorrowerScore ?? undefined}
                        ></CreditReportScores>
                    )}
                </>
            )}
        </div>
    );
};

export default CreditReport;
