import React, { useEffect, useState } from "react";
import styles from "./AddDocumentSection.module.scss";

interface AddDocumentSectionOptionListProps {
  documentList: string[];
  document: string;
  onSelectionMade?: (value: string) => void;
}

const AddDocumentSectionOptionList = (props: AddDocumentSectionOptionListProps) => {
  const [anythingSet, setAnythingSet] = useState(false);

  useEffect(() => {
    setAnythingSet(false);
  }, [props.documentList]);

  const optionClick = (value: string) => {
    if (value && props.onSelectionMade) {
      props.onSelectionMade(value);
      setAnythingSet(true);
    }
  };

  return (
    <div className={`card ${styles.addDocumentSectionOptionContainer}`}>
      <div className={`${styles.addDocumentSectionOptionList}`}>
        {props.documentList?.map((value) => {
          return (
            <ListItem key={value}>
              {!anythingSet && (
                <i className="fa fa-plus-circle" onClick={() => optionClick(value)} />
              )}
              {props.document === value && <i className="fa fa-check" />}
              <p onClick={() => optionClick(value)}>{value}</p>
            </ListItem>
          );
        })}
      </div>
    </div>
  );
};

const ListItem = (props: { children?: React.ReactNode }) => {
  return <>{props.children}</>;
};

export default AddDocumentSectionOptionList;
