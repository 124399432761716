import { Action, Reducer } from "redux";
import * as ActionTypes from "../common/ActionTypes";
import { NavigationState, KnownAction } from "../store/navigationStore";

const unloadedState: NavigationState = {
    back: {
        disabled: false,
        message: "Back",
        show: true,
        onClick: null,
    },
    continue: {
        disabled: true,
        message: "Save/Continue",
        show: false,
        onClick: null,
    },
    lastResetFromRoute: new Date()
};

export const navigationReducer: Reducer<NavigationState> = (
    state: NavigationState | undefined,
    incomingAction: Action
): NavigationState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.ResetNavigation: {
            return {
                ...state,
                back: {
                    disabled: false,
                    message: "Back",
                    show: true,
                    onClick: null,
                },
                continue: {
                    disabled: true,
                    message: "Save/Continue",
                    show: false,
                    onClick: null,
                }
            };
        }
        case ActionTypes.ResetNavigationFromRoute: {
            return {
                ...state,
                back: {
                    disabled: false,
                    message: "Back",
                    show: true,
                    onClick: null,
                },
                continue: {
                    disabled: true,
                    message: "Save/Continue",
                    show: false,
                    onClick: null,
                },
                lastResetFromRoute: new Date()
            };
        }
        case ActionTypes.SetContinue: {
            var newState = {
                ...state,
            };
            newState.continue.show = action.show;
            return newState;
        }
        case ActionTypes.SetBackOnClick: {
            var newState = {
                ...state,
            };
            newState.back.onClick = action.onBackClick;
            return newState;
        }
        case ActionTypes.ShowContinue: {
            var newState = {
                ...state,
            };
            newState.continue.onClick = action.onClick;
            newState.continue.disabled = action.isDisabled ?? false;
            newState.continue.show = true;
            return newState;
        }
        case ActionTypes.SetContinueDisabled: {
            var newState = {
                ...state,
            };
            newState.continue.disabled = action.isDisabled;
            return newState;
        }
        case ActionTypes.ResetContinue: {
            var newState = {
                ...state,
            };
            newState.continue.onClick = null;
            newState.continue.disabled = false;
            newState.continue.show = false;
            return newState;
        }
        case ActionTypes.HideNavigation: {
            return {
                ...state,
                back: {
                    disabled: false,
                    message: "Back",
                    show: false,
                    onClick: null,
                },
                continue: {
                    disabled: false,
                    message: "Save/Continue",
                    show: false,
                    onClick: null,
                }
            };
        }
        case ActionTypes.RenameNavigation: {
            return {
                ...state,
                back: {
                    disabled: false,
                    message: action.backwardString,
                    show: true,
                    onClick: null,
                },
                continue: {
                    disabled: false,
                    message: action.forwardString,
                    show: false,
                    onClick: null,
                }
            };
        }
        default:
            return state;
    }
};
