import * as React from "react";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as DemographicQuestionsActions from "../../actions/demographicQuestionsActions";
import * as DemographicQuestionsStore from "../../store/demographicQuestionsStore";
import DemographicQuestion from "components/demographicQuestions/DemographicQuestion";
import { useHistory } from "react-router";
import { BorrowerType } from "../../store/Enum";
import { useNavigation } from "hooks/useNavigation";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import * as demographicQuestionService from "../../services/demographicQuestionsService";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";

type DemographicQuestionProps = DemographicQuestionsStore.DemographicQuestionsState
    & {
        isCoBorrowerPresent: boolean,
        borrower: DemographicQuestionsStore.DemographicQuestions,
        coBorrower: DemographicQuestionsStore.DemographicQuestions,
        loanGuid: string,
        borrowerType?: number;
        isLoanCompleted: boolean;
    }
    & typeof DemographicQuestionsActions.demographicQuestionsActionCreators
    & typeof TaskMenuCreators & typeof loanUpdateResponseActionCreators;

export enum DemographicQuestionStep {
    BorrowerDemographicQuestion = 1,
    CoBorrowerDemographicQuestion = 2,
    Finished = 3
}

const DemographicQuestionDetails = (props: DemographicQuestionProps) => {
    const history = useHistory();
    const [currentStep, changeStep] = React.useState(0);
    const [borrowerValid, setBorrowerValid] = React.useState(false);
    const [coBorrowerValid, setCoBorrowerValid] = React.useState(false);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const [isLoading, setLoading] = React.useState(false);
    const navigation = useNavigation();

    React.useEffect(() => {
        if (history.location.state?.args === "coborrower") {
            changeStep(DemographicQuestionStep.CoBorrowerDemographicQuestion);
        }
        else if (history.location.state?.args === "borrower") {
            changeStep(DemographicQuestionStep.BorrowerDemographicQuestion);
        }
    }, [history.location]);

    const onBack = () => {
        if (currentStep === DemographicQuestionStep.BorrowerDemographicQuestion) {
            props.setLoanIsDirty(false);
            history.push({ pathname: "/government-questions", state: { args: props.isCoBorrowerPresent ? "coborrower" : "borrower" } });
        }
        else if (currentStep === DemographicQuestionStep.CoBorrowerDemographicQuestion) {
            history.replace({ state: { args: "borrower" } });
            changeStep(DemographicQuestionStep.BorrowerDemographicQuestion);
        }
        else if (currentStep === DemographicQuestionStep.Finished) {
            changeStep(props.isCoBorrowerPresent ? DemographicQuestionStep.CoBorrowerDemographicQuestion : DemographicQuestionStep.BorrowerDemographicQuestion)
        }
    };
    const onContinue = () => {
        if (currentStep === DemographicQuestionStep.BorrowerDemographicQuestion) {
            saveDemographicQuestionDetails(props.isCoBorrowerPresent ? DemographicQuestionStep.CoBorrowerDemographicQuestion : DemographicQuestionStep.Finished)
        }
        else if (currentStep === DemographicQuestionStep.CoBorrowerDemographicQuestion) {
            saveDemographicQuestionDetails(DemographicQuestionStep.Finished);

        }
    };

    let demographicQuestionsPage = <></>;

    const saveDemographicQuestionDetails = (Step: number) => {
        if (currentStep === DemographicQuestionStep.BorrowerDemographicQuestion && borrowerValid) {
            if (!props.isLoanCompleted) {
                setLoading(true);
                demographicQuestionService.updateDemographicQuestions(props.borrower)
                    .then((response: any) => {
                        if (!response.ok) {
                            throw new Error("There was a problem updating demographic information.");
                        }
                        setLoading(false);
                        let result = response.parsedBody as boolean;
                        if (result) {
                            if (Step == DemographicQuestionStep.CoBorrowerDemographicQuestion) {
                                changeStep(Step);
                            }
                            else {
                                history.push("/contact-preference");
                            }
                        }
                        else {
                            throw new Error("There was a problem updating demographic information.");
                        }
                    })
                    .catch((error: Error) => {
                        throw new Error(`Following error message occurred while updating demographic information ${error.message}`);
                    });
            }
            else {
                if (Step == DemographicQuestionStep.CoBorrowerDemographicQuestion) {
                    changeStep(Step);
                }
                else {
                    history.push("/contact-preference");
                }
            }
        }
        else if (currentStep === DemographicQuestionStep.CoBorrowerDemographicQuestion && coBorrowerValid) {
            if (!props.isLoanCompleted) {
                setLoading(true);
                demographicQuestionService.updateDemographicQuestions(props.coBorrower)
                    .then((response: any) => {
                        if (!response.ok) {
                            throw new Error("There was a problem updating demographic information for coborrower.");
                        }
                        setLoading(false);
                        let result = response.parsedBody as boolean;
                        if (result) {
                            history.push("/contact-preference");
                        }
                        else {
                            throw new Error("There was a problem updating demographic information for coborrower.");
                        }
                    })
                    .catch((error: Error) => {
                        throw new Error(`Following error message occurred ${error.message}`);
                    });
            }
            else {
                history.push("/contact-preference");
            }

        }
    }


    if (currentStep === DemographicQuestionStep.BorrowerDemographicQuestion) {
        demographicQuestionsPage = (
            <>
                <DemographicQuestion
                    borrowerType={BorrowerType.PrimaryBorrower}
                    setValid={setBorrowerValid}
                    isLoanCompleted={props.isLoanCompleted}
                />
                {navigation.SetBackOnClick(
                    () => onBack()
                )}
                {navigation.ShowContinue(
                    () => onContinue(),
                    !borrowerValid || isEncompassDown
                )}
            </>
        );
    }
    else if (currentStep === DemographicQuestionStep.CoBorrowerDemographicQuestion) {
        demographicQuestionsPage = (
            <>
                <DemographicQuestion
                    borrowerType={BorrowerType.CoBorrower}
                    setValid={setCoBorrowerValid}
                    isLoanCompleted={props.isLoanCompleted}
                />
                {navigation.SetBackOnClick(
                    () => onBack()
                )}
                {navigation.ShowContinue(
                    () => onContinue(),
                    !coBorrowerValid || isEncompassDown
                )}
            </>
        );
    }
    return <div className="w-100-p">​​​​​​​{demographicQuestionsPage}
        {isLoading && <div className="screenLoader"><span className="loader medium"></span></div>}
    </div>;
};

const mapStateToProps = (state: ApplicationState): any => {
    const { accountInformation, accountCreation, demographicQuestions } = state;
    let isCoBorrowerPresent;
    let loanGuid;
    let borrower;
    let coBorrower;
    let isLoanCompleted;

    if (accountInformation && accountCreation) {

        if (accountCreation?.coBorrower?.firstName != undefined) {
            isCoBorrowerPresent = true;
        }
        loanGuid = state.loan?.loanGuid;
        borrower = demographicQuestions?.borrower;
        coBorrower = demographicQuestions?.coBorrower;
        isLoanCompleted = state.loan?.isLoanCompleted;
    }
    return {
        isCoBorrowerPresent,
        borrower,
        coBorrower,
        loanGuid,
        isLoanCompleted
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    updateDemographicQuestions: (value: boolean, propertyName: string, section: string, borrowerType: BorrowerType) => {
        dispatch(DemographicQuestionsActions.demographicQuestionsActionCreators.updateDemographicQuestions(value, propertyName, section, borrowerType));
    },
    submitDemographicQuestions: (data: DemographicQuestionsStore.DemographicQuestions) => {
        dispatch(DemographicQuestionsActions.demographicQuestionsActionCreators.submitDemographicQuestions(data));
        dispatch(TaskMenuCreators.setLoanIsDirty(false));
    },
    setLoanIsDirty: (isDirty: boolean) => {
        dispatch(TaskMenuCreators.setLoanIsDirty(isDirty));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemographicQuestionDetails as any);