import { get } from "./common/dataFetch";

export async function oktaConfigAsync() {
    const cfg = await appConfigAsync();
    return cfg.OktaWidget;
};

export async function appConfigAsync() {
    const response = await get("api/config");
    const cfg = response.parsedBody as any;
    return cfg;
};