import { useState, useEffect } from 'react';
import * as accountInformationService from "../../services/accountInformationService";

const initBeforeUnLoad = (showExitPrompt: boolean) => {
    window.onbeforeunload = (event: any) => {
        const shouldPrompt =
            window.location.pathname !== "/" &&
            window.location.pathname !== "/account-information" &&
            window.location.pathname !== "/sign-in";

        if (showExitPrompt && shouldPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = '';
            }
            return '';
        }
    };
};

export interface PromptActions {
    setPromptValue: (borrowerType: any, loanGuid: any,interimLoanStagingId:number) => void;
    enablePrompt: (value: boolean) => void;
}

export function useExitPrompt(): PromptActions {
    const [showExitPrompt, setShowExitPrompt] = useState(false);
    const [value, setValue] = useState({ loanGuid: "", borrowerType: "", interimLoanStagingId:0 });

    var actions: PromptActions = {
        setPromptValue: (borrowerType: any, loanGuid: any, interimLoanStagingId:number) => {
            setValue({ borrowerType: borrowerType, loanGuid: loanGuid, interimLoanStagingId: interimLoanStagingId });
        },
        enablePrompt: (value: boolean) => {
            setShowExitPrompt(value);
        }
    }
    window.onload = function () {
        initBeforeUnLoad(showExitPrompt);
    };

    window.onunload = function () {
        const response = accountInformationService.signOut(value.borrowerType, value.loanGuid, value.interimLoanStagingId);
        sleep(5000);
        if (response) {
            console.log("Logged out from system!");
        }
    };

    useEffect(() => {
        initBeforeUnLoad(showExitPrompt);
    }, [showExitPrompt]);

    return actions;
}

function sleep(delay: any) {
    var start = new Date().getTime();
    while (new Date().getTime() < start + delay);
}
