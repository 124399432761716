import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { LoanSummaryState, KnownAction, InterimLoanSummary, LoanOfficerDetail } from "../store/interimLoanSummaryStore";

const unloadedState: LoanSummaryState = {
    loanSummary: [] as InterimLoanSummary[],
    isLoading: false,
    currentLoanSummary: {
        interimLoanStagingId: 0,
        address: "",
        city: "",
        state: "",
        zip: 0,
        loanNumber: "",
        loanAmount: 0,
        loanTerm: 0,
        loanType: "",
        loanOfficerId: 0,
        loanPercentage: 0,
        loanStatus: ""
    },
    loanOfficerDetail: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        loanOfficerHeadshot: "",
        slugId: "",
    },
}

export const interimLoanSummaryReducer: Reducer<LoanSummaryState> = (state: LoanSummaryState | undefined, incomingAction: Action): LoanSummaryState => {

    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;

    switch (action.type) {


        case ActionTypes.ReceiveInterimLoanSummary: {
            if (action.data) {

                return {
                    ...state,
                    isLoading: false,
                    loanSummary: action.data.loanSummary,
                    currentLoanSummary: action.data.loanSummary[0],
                    loanOfficerDetail: action.data.loanOfficerDetail
                }
            }
        };

        case ActionTypes.ReceiveUpdatedInterimLoanSummary: {
            if (action.data) {
                return {
                    ...state,
                    isLoading: false,
                    loanSummary: action.data.loanSummary,
                    loanOfficerDetail: action.data.loanOfficerDetail
                }
            }
        };

        case ActionTypes.SetCurrentInterimLoanSummary: {
            if (action.data) {
                return {
                    ...state,
                    currentLoanSummary: action.data as InterimLoanSummary
                }
            }

        };
        case ActionTypes.ReceiveLoanOfficerDetail: {
            if (action.data) {

                return {
                    ...state,
                    loanOfficerDetail: action.data as LoanOfficerDetail
                }
            }
        };

        case ActionTypes.GetInterimLoanSummary: {
            if (action.data) {

                return {
                    ...state,
                    loanSummary: action.data as InterimLoanSummary[]
                }
            }
        };

        default:
            return state;
    }
}