import * as React from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Infographic from "components/common/Infographic";
import homeIllustration from "assets/infographics/home.svg";
import { useNavigation } from "hooks/useNavigation";
import { useOktaAuth } from "@okta/okta-react";
import SessionInfo from "../SessionDataTracker";
import queryString from "query-string";
import * as AccountInformationActions from "../actions/accountInformationActions";
import { isNullOrUndefined } from "util";
import { ApplicationState } from "../store";
import { isNullOrWhiteSpace } from "../common/helper/formatHelper";
import { isUndefined } from "lodash";
import { IsNarrator } from "../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
// @ts-ignore
import Toggle from 'react-toggle'

type HomeProps = {
    updateBorrowerEmail: (value: string) => any;
    updateBorrowerFirstName: (value: string) => any;
    updateBorrowerLastName: (value: string) => any;
    setLoanGuid: (value: string) => any;
    accountInformationCheck: (value: boolean) => any;
    setBorrowerType: (value: string) => any;
};

const Home = (props: HomeProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [toggleValue, setToggleValue] = React.useState(false);
    const { authState, oktaAuth } = useOktaAuth();
    const history = useHistory();
    const navigation = useNavigation(() => {
        navigation.HideNavigation();
    });

    const { search } = useLocation();
    const queryStringValues = queryString.parse(search);
    const loanGuid = useSelector(
        (state: ApplicationState) => state?.accountInformation?.loanGuid ?? null
    );
    const borrowerType = useSelector(
        (state: ApplicationState) => state?.accountInformation?.borrowerType ?? ""
    );

    React.useEffect(() => {
        const values = queryStringValues as any;
        if (values && values.loanNumber) {
            const email = values.email as string;
            const firstName = values.firstName as string;
            const lastName = values.lastName as string;
            const loanGuid = values.loanGuid as string;
            const borrowerType = values.borrowerType as string;

            props.updateBorrowerEmail(email);
            props.updateBorrowerFirstName(firstName);
            props.updateBorrowerLastName(lastName);
            props.setLoanGuid(loanGuid);
            props.setBorrowerType(borrowerType ?? "");

            if (!isNullOrWhiteSpace(loanGuid) && !isUndefined(loanGuid)) {
                props.accountInformationCheck(true);
            }
            history.push({ pathname: "/" });
            return;
        }
    }, [queryStringValues]);

    const login = async () => {
        history.push({ pathname: "/id", state: { action: "sign-in", borrowerType: "Unknown" } });
    }

    React.useEffect(() => {
        localStorage.setItem('isNarrator', "false");
        SessionInfo.clear();

        if (authState?.isAuthenticated) {
            oktaAuth.signOut({ postLogoutRedirectUri: "" });
        }
    }, []);

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }

    const handleChange = (event: any) => {
        setToggleValue(event.target.checked);
        localStorage.setItem('isNarrator', event.target.checked);
    }

    return (
        <div className="infographic-side-layout-container">
            <div className="content">
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Welcome')}>Welcome!</h1>
                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Let's walk you through the steps of getting you started with your loan.")}>
                    Let's walk you through the steps of getting you started with your
                    loan.
                </h2>
                {isNullOrUndefined(loanGuid) || loanGuid == "" ? <div className="button-list">
                    <Link to="/account-information">
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press Button to Continue')} className="common-green-button">Continue</button>
                    </Link>
                </div> :
                    <div className="button-list">

                        <button className="common-green-button"
                            onClick={() => { history.push({ pathname: "/account", state: { action: "invitation", borrowerType: borrowerType, "loanGuid": loanGuid } }) }} > Continue</button>

                    </div>}
                <p>
                    <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Returning Customer?')}>Returning Customer?</span>
                    <a className="bold" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Click here to sign in')} onClick={login}> Click here to sign in.</a>
                </p>
                <div className="toggle">
                    If you have difficulty in reading the content, start the narration on the website
                     <Toggle defaultChecked={toggleValue} icons={false}
                        onChange={(e: any) => handleChange(e)} />
                </div>
            </div>
            <div className="infographic-side-panel">
                <Infographic src={homeIllustration} />
            </div>
        </div>
    )
};

const mapDispatchToProps = (dispatch: any) => ({
    updateBorrowerFirstName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerFirstName(value));
    },
    updateBorrowerLastName: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerLastName(value));
    },
    updateBorrowerEmail: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.updateBorrowerEmail(value));
    },
    setLoanGuid: (value?: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.setLoanGuid(value));
    },
    accountInformationCheck: (value: boolean) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.accountInformationCheck(value));
    },
    setBorrowerType: (value: string) => {
        dispatch(AccountInformationActions.accountInformationActionCreators.setBorrowerType(value));
    }
});

export default connect(null, mapDispatchToProps)(Home);