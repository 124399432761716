import * as React from "react";
import { connect, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import styles from "./LoanOfficer.module.scss";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Infographic from "../common/Infographic";
import applicationFeeStatusInfographic from "../.././assets/infographics/loanOfficerView/ApplicationFeeStatus.svg";
import itemSentInfographic from "../.././assets/infographics/loanOfficerView/ItemSent.svg";
import preApproval from "assets/infographics/dashboard/pre-approval.svg";
import viewCreditReportInfographic from "../.././assets/infographics/loanOfficerView/ViewCreditReport.svg";
import appFeeCollected from "../.././assets/infographics/loanOfficerView/AppFeeCollected.svg";
import * as LoanOfficerMainTabActions from "../../actions/loanOfficerMainTabActions";
import * as LoanOfficerMainTabStore from "../../store/LoanOfficerMainTabStore";
import * as loanOfficerMainTabService from "../.././services/loanOfficerMainTabService";
import LOMainTabDocumentViewer from "./LOMainTabDocumentViewer";
import { useNavigation } from "hooks/useNavigation";
import { LoanOfficerMainTabMessage } from "./LoanOfficerMainTabMessage";
import * as systematicLetterService from "../../services/systematicLetterService";
import * as SystematicLetterStore from "../../store/systematicLetterStore";
import * as SystematicLetterActions from "../../actions/systematicLetterActions";
import LoanOfficerPreQualLetterDisplay from "./LoanOfficerPreQualLetterDisplay";
import * as consumerCreditAuthorizationService from "../.././services/consumerCreditAuthorizationService";
import MissingAuthorizationAlert from "./MissingAuthorizationAlert";
import MissingPreQualLetter from "./MissingPreQualLetter";
import { useHistory } from 'react-router-dom';
import LoanOfficerDocuments from "../documents/loanOfficer/LoanOfficerDocuments";
import { appConfigAsync } from "../../authConfig";
import Loading from "../common/Loader";
import * as userAcountActions from "../../actions/userAcount";
import * as accountInformationActions from "../../actions/accountInformationActions";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import * as accountInformationService from "../../services/accountInformationService";
import moment from "moment";
import { loanState } from "../../store/loanStore";

type LoanOfficerMainTabProps = LoanOfficerMainTabStore.LoanOfficerMainTabState &
{
    appFeeData: LoanOfficerMainTabStore.LoanOfficerMainTab;
    preQualData: SystematicLetterStore.SystematicLetter;
    history: any;
    isLoading: boolean;
    selectedTab: number;
    loanGuid: string;
    userEmail: string;
    loanType: string;
    propertyEstimatedValueAmount: number;
    interimLoanStagingId: number;
} &
    typeof LoanOfficerMainTabActions.loanOfficerMainTabActionCreators &
    typeof SystematicLetterActions.systematicLetterActionCreators &
    typeof userAcountActions.userAcountActionCreators &
    typeof accountInformationActions.accountInformationActionCreators;

export enum LoanOfficerMainTabStep {
    LoanOfficerMainTab = 1,
    LoanOfficerCreditReportViewer = 2,
    LoanOfficerPreQualLetter = 3,
    MissingAuthorization = 4,
    MissingPreQualLetter = 5,
    DocumentTab = 6,
    LiveApplicationTab = 7
}
const LoanOfficerMainTab = (props: LoanOfficerMainTabProps) => {
    const [currentStep, changeStep] = React.useState(LoanOfficerMainTabStep.LoanOfficerMainTab);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isCreditLoading, setCreditLoading] = React.useState(false);
    const [viewCreditReport, setViewCreditReport] = React.useState(false);
    const [viewPreQual, setPreQualLetter] = React.useState(false);
    const navigation = useNavigation();
    const [liveChatUrl, setLiveChatUrl] = React.useState('');
    const [showLoader, setLoader] = React.useState(false);
    const [isEncompassDown, setEncompassDown] = React.useState(false);
    const [date, setDate] = React.useState("");
    const [releaseDate, setReleaseDate] = React.useState("");
    const history = useHistory();
    const { isLoanFromEncompassFlag } = useSelector((state: ApplicationState) => state.loan as loanState);

    React.useEffect(() => {
        appConfigAsync().then((config: any) => {
            setLiveChatUrl(config.LiveChatDashboardUrl);
        });
    }, []);

    React.useEffect(() => {
        if (props.loanGuid && props.userEmail) {
            props.setLoanOfficerViewLoanGuid(props.loanGuid);
            getApplicationFee();
        } else {
            history.push({ pathname: "/" });
        }
    }, [props.loanGuid]);
       
    React.useEffect(() => {
        if (props.appFeeData.loanOriginationDate) {
            setDate(props.appFeeData.loanOriginationDate);
            setReleaseDate('05/23/2022')
        }
    }, [props.appFeeData.loanOriginationDate])

    const getApplicationFee = async () => {
        if (props.loanGuid) {
            setLoader(true);
            await accountInformationService.checkIsEncompassDown().then(async res => {
                if (res.ok) {
                    let result = res.parsedBody as boolean;
                    setLoader(false);
                    if (!result) {
                        setLoader(true);
                        await loanOfficerMainTabService.getApplicationFee(props.loanGuid).then((result: any) => {
                            if (result.ok && result.parsedBody) {
                                const appFeeData = result.parsedBody as LoanOfficerMainTabStore.LoanOfficerMainTab;
                                props.setApplicationFee(appFeeData);
                            }
                            setLoader(false);
                        });
                    }
                    else {
                        setEncompassDown(true);
                    }
                }
            })
                .catch((error: Error) => {
                    setLoader(false);
                    throw new Error(`Following error message occurred ${error.message}`);
                });
            ;
        }
    }

    const getCreditAuthorizationStatus = async () => {
        if (props.interimLoanStagingId && props.interimLoanStagingId >0) {
            setCreditLoading(true);
            const result = await consumerCreditAuthorizationService.getConsumerCreditAuthorizationStatus(props.interimLoanStagingId);
            if (result.ok) {
                const status = result.parsedBody as boolean;
                setViewCreditReport(false);
                setCreditLoading(false);
                changeStep(LoanOfficerMainTabStep.MissingAuthorization);
            } else {
                setCreditLoading(false);
            }
        }
    }

    const getCreditReports = async () => {
        if (props.loanGuid) {
            setCreditLoading(true);
            var result = await loanOfficerMainTabService.getCreditReportAttachment(props.loanGuid ?? "");
            if (result.ok && result.parsedBody != undefined && result.parsedBody.length > 0) {
                setCreditLoading(false);
                const creditReports = result.parsedBody as LoanOfficerMainTabStore.DocumentAttachment[];
                if (creditReports != undefined && creditReports.length > 0) {
                    props.setCreditReports(creditReports);
                    setViewCreditReport(true);
                    changeStep(LoanOfficerMainTabStep.LoanOfficerCreditReportViewer);
                }
            } else {
                getCreditAuthorizationStatus();
            }
        }
    }

    const viewPreQualLetter = () => {
        if (props.loanGuid) {
            setIsLoading(true);
            systematicLetterService.getSystematicLetterData(props.loanGuid ?? "")
                .then((response: any) => {
                    if (!response.ok) {
                        throw new Error("There was a problem getting loan ");
                    }
                    return response.parsedBody as SystematicLetterStore.SystematicLetter;
                })
                .then((data: SystematicLetterStore.SystematicLetter) => {
                    if (isNullOrWhiteSpace(data.firstName) ||
                        isNullOrWhiteSpace(data.lastName) ||
                        isNullOrWhiteSpace(data.loanAmount) ||
                        isNullOrWhiteSpace(data.subjectPropertyAddress) ||
                        isNullOrWhiteSpace(data.propertyType)) {
                        changeStep(LoanOfficerMainTabStep.MissingPreQualLetter);
                    }
                    else {
                        props.getData(data);
                        setPreQualLetter(true);

                        changeStep(LoanOfficerMainTabStep.LoanOfficerPreQualLetter);
                    }
                    setIsLoading(false);
                })
                .catch((error: Error) => {
                    setIsLoading(false);
                    console.error(error);
                });
        }
    };

    const openLiveChatWindow = () => {
        window.open(liveChatUrl, "_blank"); //to open new page
    }

    const navigateRoute = () => {
        history.push({ pathname: "/pre-approval-Letter", state: { args: "loanOfficer" } });
    }

    const onBack = () => {
        if (currentStep === LoanOfficerMainTabStep.LoanOfficerCreditReportViewer) {
            setViewCreditReport(false);
            changeStep(LoanOfficerMainTabStep.LoanOfficerMainTab);
        }
        if (currentStep === LoanOfficerMainTabStep.LoanOfficerPreQualLetter) {
            setPreQualLetter(false);
            changeStep(LoanOfficerMainTabStep.LoanOfficerMainTab);
        }
        if (currentStep === LoanOfficerMainTabStep.MissingAuthorization) {
            setViewCreditReport(false);
            changeStep(LoanOfficerMainTabStep.LoanOfficerMainTab);
        }
        if (currentStep === LoanOfficerMainTabStep.MissingPreQualLetter) {
            setPreQualLetter(false);
            changeStep(LoanOfficerMainTabStep.LoanOfficerMainTab);
        }
    };

    const setSelectedTab = (index: number) => {
        props.setSelectedTab(index);
    }

    const backToTab = (tab: number) => {
        navigation.SetBackOnClick(
            () => onBack()
        );
        if (tab === 0) {
            getApplicationFee();
            setViewCreditReport(false);
            setPreQualLetter(false);
            changeStep(LoanOfficerMainTabStep.LoanOfficerMainTab);
            setSelectedTab(0);
        }
        else if (tab === 1) {
            setSelectedTab(1);
        }
    };

    return (
        <>
            <div className="w-100-p">
                <div className={styles.loanOfficerMainPage}>
                    <Tabs className={styles.textcolor} selectedIndex={props.selectedTab} onSelect={index => setSelectedTab(index)}>
                        <TabList>
                            <Tab onClick={() => backToTab(0)} >Main Page</Tab>
                            <Tab onClick={() => backToTab(1)}>Documents</Tab>
                        </TabList>

                        {showLoader ? <Loading /> :
                            <div>
                                <TabPanel>
                                    <div>
                                        <>
                                            {(viewCreditReport && currentStep == LoanOfficerMainTabStep.LoanOfficerCreditReportViewer) &&
                                                <>
                                                    <LOMainTabDocumentViewer />
                                                    {navigation.ResetContinue()}
                                                    {navigation.SetBackOnClick(
                                                        () => onBack()
                                                    )}
                                                </>
                                            }
                                            {(!viewCreditReport && currentStep == LoanOfficerMainTabStep.MissingAuthorization) &&
                                                <>
                                                    <MissingAuthorizationAlert />
                                                    {navigation.ResetContinue()}
                                                    {navigation.SetBackOnClick(
                                                        () => onBack()
                                                    )}
                                                </>
                                            }
                                            {(viewPreQual && currentStep == LoanOfficerMainTabStep.LoanOfficerPreQualLetter) &&
                                                <>
                                                    <LoanOfficerPreQualLetterDisplay />
                                                    {navigation.ResetContinue()}
                                                    {navigation.SetBackOnClick(
                                                        () => onBack()
                                                    )}
                                                </>
                                            }
                                            {(!viewPreQual && currentStep == LoanOfficerMainTabStep.MissingPreQualLetter) &&
                                                <>
                                                    <MissingPreQualLetter />
                                                    {navigation.ResetContinue()}
                                                    {navigation.SetBackOnClick(
                                                        () => onBack()
                                                    )}
                                                </>
                                            }
                                            {(!viewCreditReport && !viewPreQual && currentStep == LoanOfficerMainTabStep.LoanOfficerMainTab) &&
                                                <div className={styles.commonGrid2Cols}>
                                                    <div className={styles.leftCols}>
                                                        <div className={styles.loanIdentifier}>
                                                            <>
                                                                <span className={styles.customCheckBox}>
                                                                    <input name="loanIdentifier" checked={props.appFeeData.posLoanIdentifier} type="checkbox" disabled />
                                                                </span>
                                                                <h4>POS Loan Identifier</h4>
                                                            </>
                                                        </div>
                                                        <div className={styles.feePendingBG}>
                                                            <h4>Application Fee Status</h4>
                                                            <div>
                                                                <div className={styles.imageBox}>
                                                                    {((!props.appFeeData.appFeeCollectedStatus && props.appFeeData.appFeeCollectedStatus != undefined) &&
                                                                        (!props.appFeeData.appFeePendingStatus && props.appFeeData.appFeePendingStatus != undefined)) ?
                                                                        <Infographic src={applicationFeeStatusInfographic} /> :
                                                                        (props.appFeeData.appFeeCollectedStatus) ? <Infographic src={appFeeCollected} /> :
                                                                            (props.appFeeData.appFeePendingStatus) ? <Infographic src={applicationFeeStatusInfographic} />
                                                                                : <><Infographic src={applicationFeeStatusInfographic} /> </>}

                                                                </div>

                                                            {((!props.appFeeData.appFeeCollectedStatus && props.appFeeData.appFeeCollectedStatus != undefined) &&
                                                                (!props.appFeeData.appFeePendingStatus && props.appFeeData.appFeePendingStatus != undefined)) ?
                                                                <h3>{LoanOfficerMainTabMessage.ApplicationFeeNotCollected}</h3> :
                                                                (props.appFeeData.appFeeCollectedStatus) ? <h3>Application Fee Collected</h3> :
                                                                    (props.appFeeData.appFeePendingStatus) ? <h3>Application Fee Pending</h3>
                                                                        : <></>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.rightCols}>
                                                    <div className={styles.welcomeBox}>
                                                        <div className={styles.flexRow}>
                                                            {isLoanFromEncompassFlag && <div>
                                                                <div className={styles.imageBox} onClick={() => getCreditReports()}>
                                                                    {isCreditLoading ? <div className="loader medium"></div> :
                                                                        <Infographic src={viewCreditReportInfographic} />}
                                                                </div>
                                                                <h5>View Borrower Credit Report</h5>
                                                            </div>
                                                            }
                                                            {isLoanFromEncompassFlag && props.appFeeData.loanPurpose === "Purchase" &&
                                                                (Date.parse(date) < Date.parse(moment(new Date()).format(releaseDate)))
                                                                &&
                                                                <div>
                                                                    <div className={styles.imageBox} onClick={() => viewPreQualLetter()}>
                                                                        {isLoading ? <div className="loader medium"></div> :
                                                                            <Infographic src={itemSentInfographic} />
                                                                        }
                                                                    </div>

                                                                    <h5>View Pre-Qual Letter</h5>
                                                                </div>
                                                            }

                                                            {props.appFeeData.loanPurpose === "Purchase" &&
                                                                <div>
                                                                    <div className={styles.imageBox} onClick={() => navigateRoute()}>
                                                                        <Infographic src={preApproval} />
                                                                    </div>
                                                                    <h5>Pre-Approval Letter</h5>
                                                                </div>
                                                            }

                                                            <div className={props.appFeeData.contactChoices?.some(x => x.choiceType === "Email") ? styles.contactChoices : `${styles.contactChoices} ${styles.contactChoices1}`}>
                                                                {props.appFeeData.contactChoices != undefined && props.appFeeData.contactChoices.length > 0 ? props?.appFeeData?.contactChoices?.filter(x => x.choiceValue !== null).map((choice, index) => (
                                                                    <p><i className={choice.choiceType === "Email" ? 'fa fa-envelope-o' : choice.choiceType === "Text" ? 'fa fa-commenting-o' : 'fa fa-phone'} />
                                                                        {choice.choiceValue}</p>
                                                                )) : <p>No data found</p>}
                                                                <h5>Preferred Borrower Contact</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                </div>
                                            }
                                        </>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <>
                                        <LoanOfficerDocuments />
                                    </>
                                </TabPanel>
                            </div>
                        }
                    </Tabs>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state: ApplicationState): any => {
    const { loanOfficerMainTab, systematicLetter } = state;
    let appFeeData: any;
    let preQualData: any;
    if (loanOfficerMainTab && loanOfficerMainTab.data) {
        appFeeData = loanOfficerMainTab.data;
    }
    if (systematicLetter && systematicLetter.data) {
        preQualData = systematicLetter.data;
    }
    const interimLoanStagingId = state.loan?.interimLoanStagingId;
    const selectedTab: any = loanOfficerMainTab?.selectedTab;
    const loanGuid = state.accountInformation?.loanGuid;
    const userEmail = state.userAccountInformation?.email;

    return {
        appFeeData,
        preQualData,
        selectedTab,
        loanGuid,
        userEmail,
        interimLoanStagingId
    }
}

const mapDispatchToProps = (dispatch: any) => ({

    getApplicationFee: (loanGuid: string) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.getApplicationFee(loanGuid));
    },
    sendSecureInvitation: (loanGuid: string) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.sendSecureInvitation(loanGuid));
    },
    setCreditReports: (creditReports: LoanOfficerMainTabStore.DocumentAttachment[]) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.setCreditReports(creditReports));
    },
    setApplicationFee: (data: LoanOfficerMainTabStore.LoanOfficerMainTab) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.setApplicationFee(data));
    },
    getData: (data: SystematicLetterStore.SystematicLetter) => {
        dispatch(SystematicLetterActions.systematicLetterActionCreators.getData(data));
    },
    setLoanOfficerViewLoanGuid: (loanGuid: string) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.setLoanOfficerViewLoanGuid(loanGuid));
    },
    setSelectedTab: (selectedTab: number) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.setSelectedTab(selectedTab));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoanOfficerMainTab as any);
