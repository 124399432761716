import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SessionInfo from "./SessionDataTracker";
import { useHistory } from "react-router"
import { useIdleTimer } from "react-idle-timer"
import { useOktaAuth } from "@okta/okta-react";
import styles from "./SessionWatcher.module.scss";
import * as accountInformationService from "./services/accountInformationService";

export default function SessionWatcher(props: { borrowerType: string, loanGuid: string,interimLoanStagingId :number}) {
    const idleTimeOut = 10; //Minutes
    const secondsToWarning = 120;
    const [displayWarning, setDisplayWarning] = React.useState(false);
    const [timer, setTimer] = React.useState(0);
    const history = useHistory();
    const { authState, oktaAuth } = useOktaAuth();
    const displayWarningRef = React.useRef(false);
    displayWarningRef.current = displayWarning;

    const refreshTokens = () => {
        SessionInfo.stopTimer();
        oktaAuth.tokenManager.setTokens(SessionInfo.tokens);
        oktaAuth.tokenManager.renew("accessToken").then((accessToken: any) => {
            oktaAuth.tokenManager.renew("idToken");
        });
    }

    const setAuthStateSessionData = ((tokens: any) => {
        SessionInfo.tokens = tokens;
        SessionInfo.startTimer(tokens?.accessToken?.expiresAt);
    });

    const logout = async () => {
        //Unlock loan from db and log out user.
        var response = await accountInformationService.signOut(props.borrowerType, props.loanGuid, props.interimLoanStagingId);
        if (response.body && response.parsedBody) {
            console.log("Lock has been released from loan by {0}.", props.borrowerType);
        }

        SessionInfo.stopTimer();
        oktaAuth.authStateManager.unsubscribe(setAuthStateSessionData);
        setDisplayWarning(false);

        //redirect to home page.
        history.push("/");
        window.location.reload();
    }

    const stayLoggedIn = () => {
        setDisplayWarning(false);
        refreshTokens();
    }

    const handleSessionTimeout = (timeRemain: number) => {
        if (timeRemain <= secondsToWarning) {
            const secondsSinceActive = Math.round(((new Date()).getTime() - getLastActiveTime()) / 1000);
            if (!displayWarningRef.current && secondsSinceActive < 60 * idleTimeOut) {
                refreshTokens();
            } else {
                setTimer(timeRemain);
                setDisplayWarning(true);
            }
        }

        if (timeRemain === 0) {
            logout();
        }
    }

    React.useEffect(() => {
        oktaAuth.authStateManager.subscribe(setAuthStateSessionData);

        if (authState?.isAuthenticated) {
            SessionInfo.setTimerCallback(handleSessionTimeout);
            setAuthStateSessionData(authState);
        }
    }, []);

    const handleOnIdle = (event: any) => {
        console.log("user is idle", getLastActiveTime());
    }

    const handleOnActive = (event: any) => {
        console.log("user is active");
    }

    const handleOnAction = (event: any) => {
        //console.log("user did something", getRemainingTime());
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: idleTimeOut * 1000 * 60,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    });

    return (
        <div>
            {displayWarning &&
                <Modal isOpen={displayWarning} centered={true}>
                    <ModalHeader>
                        Your session is about to expire
                    </ModalHeader>
                    <ModalBody className={`${styles.modalBody}`}>
                        Your session will expire in {timer} seconds<br />
                        To keep working, select Continue to stay logged in.
                    </ModalBody>
                    <ModalFooter>
                        <div className="content align-center">
                            <button className={`common-green-button ${styles.button}`} onClick={() => logout()}> Log Out </button>
                            <button className={`common-green-button ${styles.button}`} onClick={() => stayLoggedIn()}> Continue </button>
                        </div>
                    </ModalFooter>
                </Modal>
            }
        </div>
    );
}