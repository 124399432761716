import React, { useEffect, useState } from "react";
import styles from "./LoanOfficerDocument.module.scss";
import Document from "./Document";
import Tooltip from "components/common/InfoIconTooltip";
import { DocumentSectionDto } from "../models/DocumentSectionDto";
import { DocumentSectionStatus } from "../models/DocumentSectionStatus";
import { useDocumentActions } from "actions/documentActions";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import ModalPopup from "../../common/ModalPopup";

interface DocumentSectionProps {
    section: DocumentSectionDto;
    onRemoveClick: (section: DocumentSectionDto) => void;
}

const DocumentSection = (props: DocumentSectionProps) => {
    const [showDocuments, setShowDocuments] = useState(false);
    const [hasDocuments, setHasDocuments] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [openConfirmationModalPopup, setOpenConfirmationModalPopup] = useState(false);
    const icon = "fa-caret-" + (showDocuments ? "up" : "down");

    const flipShow = () => {
        if (hasDocuments) {
            actions.AlterSectionExpandedStatus(
                props.section?.documentSectionId ?? -1,
                !showDocuments
            );
        }
    };

    const actions = useDocumentActions();
    const expandedSections = useSelector(
        (state: ApplicationState) => state.document?.expandedSections
    );

    useEffect(() => {
        const sectionInExpandedArray =
            expandedSections?.includes(props.section?.documentSectionId ?? -1) ?? false;

        setShowDocuments(sectionInExpandedArray && hasDocuments);
    }, [expandedSections, hasDocuments]);

    useEffect(() => {
        let uploaded = false;
        setHasDocuments((props.section?.documents?.length ?? 0) > 0);
        setCanDelete(props.section.status == DocumentSectionStatus.Requested || props.section.status == DocumentSectionStatus.Outstanding);
        props.section.documents?.forEach((document) => {
            if (document.isUploadedToEncompass) {
                uploaded = true;
            }
            else {
                uploaded = false;
            }
        });
    }, [props.section]);

    const deleteDocumentSection = async () => {
        setOpenConfirmationModalPopup(true);
    }

    const confirmDelete = async () => {
        setOpenConfirmationModalPopup(false);
        props.onRemoveClick(props.section);
    }

    return (
        <div className={styles.section}>
            <div className={styles.sectionLabel}>
                <span className={styles.customCheckBox} >
                    <input name="status"
                        checked={props.section?.status === DocumentSectionStatus.Accepted || props.section?.status === DocumentSectionStatus.Submitted ?
                            true : false} type="checkbox" disabled />
                </span>
                <h5 onClick={flipShow}>{props.section.title}</h5>
                {props.section?.description && <Tooltip>{props.section.description}</Tooltip>}
                <div className={styles.icon}>
                    {canDelete && (
                        <i className={`fa fa-trash ${styles.deletIcon}`} onClick={() => deleteDocumentSection()} />
                    )}
                    {hasDocuments && <i className={`fa ${icon}`} onClick={flipShow} />}
                </div>
            </div>

            <h6 className={props.section.status === DocumentSectionStatus.Rejected ? styles.rejectedStatus : styles.status} onClick={flipShow}>
                {DocumentSectionStatus[props.section.status as any]}
            </h6>


            <div className={styles.documentContainer}>
                {showDocuments && (
                    <>
                        {props.section.documents?.map((value) => {
                            return (
                                <Document
                                    key={value.documentId}
                                    fileName={value.fileName}
                                    status={value.status}
                                    documentDto={value}
                                    documentList={props.section.documents}
                                ></Document>
                            );
                        })}
                    </>
                )}
            </div>
            <hr></hr>
            <ModalPopup text="Are you sure you want to delete the document?"
                onCancel={() => setOpenConfirmationModalPopup(false)}
                isOpen={openConfirmationModalPopup} onSubmit={confirmDelete} />
        </div>
    );
};

export default DocumentSection;