import React from "react";
import NumberFormat from "react-number-format";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const Currency = (props: { value: number; decimalScale?: number }) => {
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
  return (
    <>
      <NumberFormat
        value={props.value}
        displayType={"text"}
        thousandSeparator={true}
        decimalSeparator="."
        decimalScale={props.decimalScale ?? 2}
        fixedDecimalScale={true}
        prefix={"$"}
              onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('$' + props.value)}/>
    </>
  );
};

export default Currency;
