import { post, get, getBlob } from "../common/dataFetch";
import { SystematicLetter } from "../store/systematicLetterStore";

export const getSystematicLetterData = (loanGuid: string) => {
    return get<SystematicLetter>(`SystematicLetter/ReadEncompassLoanByGuidAsync?loanGuid=${loanGuid}`);
};

export const generateSystematicInvoicePdf = (interimLoanStagingId: number) => {
    return getBlob<any>(`SystematicLetter/GenerateSystematicInvoicePdf?interimLoanStagingId=${interimLoanStagingId}`);
};

export const updatePreQualInitiateStatus = (loanGuid: string) => {
    return post<any>(`SystematicLetter/UpdatePreQualInitiateStatus?loanGuid=${loanGuid}`,null);
};