import * as React from "react";
import { connect } from "react-redux";
import { ReactMultiEmail } from 'react-multi-email';
import "react-multi-email/style.css";
import { ApplicationState } from "../../store";
import Infographic from "components/common/Infographic";
import preApproval from "assets/infographics/dashboard/pre-approval.svg";
import styles from "./preApprovalLetter.module.scss";
import * as LoanOfficerMainTabStore from "../../store/LoanOfficerMainTabStore";
import * as LoanOfficerMainTabActions from "../../actions/loanOfficerMainTabActions";
import * as userAcountActions from "../../actions/userAcount";
import * as accountInformationActions from "../../actions/accountInformationActions";
import * as loanOfficerMainTabService from "../.././services/loanOfficerMainTabService";
import { dashboardActionCreators } from "../../actions/dashboardActions";
import { isNullOrUndefined } from "util";
import Loading from "../common/Loader";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Rule } from "../constants/validation";

type PreApprovalLetterProps =
    {
        loanGuid: string;
        posPurchasePrice: string;
        propertyEstimatedValueAmount: string;
        posPreApprovalLetterComments: string;
        borrowerLastName: string;
        isRequestedPreApproval: boolean;
    } & typeof dashboardActionCreators &
    typeof LoanOfficerMainTabActions.loanOfficerMainTabActionCreators &
    typeof userAcountActions.userAcountActionCreators &
    typeof accountInformationActions.accountInformationActionCreators;

const BorrowerPreApprovalLetter = (props: PreApprovalLetterProps) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [change, setIschange] = React.useState(false);
    const [show, setShowComment] = React.useState(false);
    const [posPreApprovalLetterTo, setPosPreApprovalLetterTo] = React.useState<string[]>([]);
    const [posPreApprovalLetterCc, setPosPreApprovalLetterCc] = React.useState<string[]>([]);
    const [additionalNotes, setAdditionalNotes] = React.useState<string>();
    const [viewButtonDisabled, setViewButtonDisabled] = React.useState(false);
    const [isOpen, setisOpen] = React.useState(true);
    const [unSuccessfully, setUnsuccessfully] = React.useState(false);
    const [list, setList] = React.useState<LoanOfficerMainTabStore.PreApproval>();
    const currentDate = new Date();
    const [preApprovalIssueDate, setPreApprovalIssueDate] = React.useState(new Date());
    const [successfully, setSuccessfully] = React.useState(true);
    const [notification, setNotification] = React.useState(false);
    const [sendButtonDisabled, setSendButtonDisabled] = React.useState(true);
    const [isValidEmail, setIsValidEmail] = React.useState(true);
    const [isCcValidEmail, setIsCcValidEmail] = React.useState(true);
    const [invalidEmails, setInvalidEmails] = React.useState<string[] | []>([]);
    let _invalidEmails: string[] = [];

    React.useEffect(() => {
        ReadPreApprovalLetters();
    }, []);


    React.useEffect(() => {
        if (list?.timeStamp !== undefined) {
            let date = new Date(list?.timeStamp);
            date.setDate(date.getDate() + 30);
            setPreApprovalIssueDate(date);
        }

    }, [list?.timeStamp]);

    const ReadPreApprovalLetters = () => {
        loanOfficerMainTabService.ReadActivePreApprovalLetterByLoanGuidAsync(props.loanGuid ?? "").then((res: any) => {
            if (res.status == 200) {
                if (isNullOrUndefined(res.parsedBody.posPurchasePrice) || res.parsedBody.posPurchasePrice === "" || res.parsedBody.posPurchasePrice === 0) {
                    props.updatePreApproval(res.parsedBody.posMaxPurchasePrice, "posPurchasePrice", "posPurchasePrice");
                }
                if (isNullOrUndefined(res.parsedBody.posMaxPurchasePrice) || res.parsedBody.posMaxPurchasePrice === "" || res.parsedBody.posMaxPurchasePrice === 0) {
                    props.updatePreApproval(props.propertyEstimatedValueAmount, "posMaxPurchasePrice", "posMaxPurchasePrice");
                }
                setList(res.parsedBody);
            }
        })
    }

    React.useEffect(() => {
        if ((props.posPurchasePrice === undefined || list?.posPurchasePrice === undefined) || !change ||
            (isNullOrUndefined(list?.posMaxPurchasePrice) || props.posPurchasePrice > list?.posMaxPurchasePrice) || props.posPurchasePrice === ""
            || Number(props.posPurchasePrice) <= 0) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [props.posPurchasePrice, list?.posMaxPurchasePrice, change]);

    React.useEffect(() => {
        let isDisabled = true;
        if (posPreApprovalLetterTo.length > 0) {
            isDisabled = !isValidEmail ? true : false;
        }
        if (invalidEmails.length > 0) {
            isDisabled = true;
        }
        else {
            setIsValidEmail(true);
            setIsCcValidEmail(true);
        }

        setSendButtonDisabled(isDisabled);        

    }, [posPreApprovalLetterTo, posPreApprovalLetterCc, isValidEmail, invalidEmails]);

    const handleSubmit = async () => {
        let result: any = {
            letterStyle: list?.letterStyle,
            loanType: list?.loanType,
            posMaxPurchasePrice: list?.posMaxPurchasePrice,
            letterName: list?.letterName,
            posAlterPurchasePriceIndicator: list?.posAlterPurchasePriceIndicator,
            posPurchasePrice: props.posPurchasePrice
        }

        setIsLoading(true);
        setViewButtonDisabled(false);
        await loanOfficerMainTabService.UncheckInactivePreApprovalLettersIntoEncompass(props.loanGuid);
        await loanOfficerMainTabService.CreatePreApprovalLetterAsync(result, props.loanGuid).then((response: any) => {
            setIsLoading(false)
            if (!response.ok) {
                throw new Error("There was a problem updating CreatePreApprovalLetterAsync.");
            }
            let result = response.parsedBody;
            return result
        }).then(async (result: any) => {
            setIsLoading(true)
            await loanOfficerMainTabService.GeneratePreApprovalLetterAndUploadToEncompassAsync
                (list?.letterStyle ?? "", props.loanGuid, result).then((res: any) => {
                    setIsLoading(false)
                    if (res.parsedBody) {
                        console.log("true");
                    }
                });
        })
        ReadPreApprovalLetters();
    }

    const viewPreApprovalLetter = () => {
        setIsLoading(true)
        loanOfficerMainTabService.GeneratePreApprovalInvoicePdf(props.loanGuid, list?.preApprovalLetterId)
            .then((response: any) => {
                if (response.status == 200 && response.data.size > 0) {
                    setIsLoading(false)
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            })
    }

    const PreApprovalLetterBaseTemplate = async () => {
        setIsLoading(true);
        let preApprovalLetterEmailTemplate: any = {
            preApprovalLetterTo: posPreApprovalLetterTo,
            preApprovalLetterCc: posPreApprovalLetterCc,
            preApprovalLetterAdditionalNotes: additionalNotes
        }
        loanOfficerMainTabService.PreApprovalLetterBaseTemplate(props.loanGuid, preApprovalLetterEmailTemplate)
            .then((response: any) => {
                if (response.status == 200) {
                    setIsLoading(false);
                    setNotification(true);
                }
                else {
                    console.error("there is some problem while generating download Pre-Approval Letter Document");
                    setNotification(true);
                    setSuccessfully(false);
                }
                setIsLoading(false);
            })
            .catch((error: Error) => {
                setIsLoading(false);
                console.error(error);
            });
    }

    const downloadPreApprovalLetter = () => {
        setIsLoading(true);
        loanOfficerMainTabService.GeneratePreApprovalInvoicePdf(props.loanGuid, list?.preApprovalLetterId)
            .then((response: any) => {
                if (response.status == 200 && response.data.size > 0) {
                    const file = new Blob([response.data], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);
                    const fileName = `Pre-Approval Letter - ${props.borrowerLastName.trim()}`
                    downloadData(fileURL, fileName);
                }
                else {
                    console.error("there is some problem while generating download Pre-Approval Letter Document");
                }
                setIsLoading(false);
            })
            .catch((error: Error) => {
                setIsLoading(false);
                console.error(error);
            });
    }

    const downloadData = (fileURL: string, fileName: string) => {
        const save = document.createElement('a');
        if (typeof save.download !== 'undefined') {
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName;
            save.dispatchEvent(new MouseEvent('click'));
        } else {
            window.location.href = fileURL;
        }
    }

    const sendRequestNewPreApprovalLetterEmail = async () => {
        let result: any = {
            letterStyle: list?.letterStyle,
            loanType: list?.loanType,
            posMaxPurchasePrice: list?.posMaxPurchasePrice,
            letterName: list?.letterName,
            posAlterPurchasePriceIndicator: list?.posAlterPurchasePriceIndicator,
            posPurchasePrice: props.posPurchasePrice,
            posPreApprovalLetterComments: props.posPreApprovalLetterComments,
            preApprovalLetterId: list?.preApprovalLetterId
        }
        setIsLoading(true)

        await loanOfficerMainTabService.UpdateAsync(result).then((response: any) => {
            setIsLoading(false)
            if (!response.ok) {
                throw new Error("There was a problem updating CreatePreApprovalLetterAsync.");
            }
            let result = response.parsedBody;
            return result
        }).then(async (result: any) => {
            setIsLoading(true)
            if (result) {
                await loanOfficerMainTabService.SendPreApprovalLetterRequestNotificationToLoanOfficer
                    (props.loanGuid).then(async (res: any) => {
                        setIsLoading(false)
                        if (res.parsedBody) {
                            console.log("true");
                            props.getUserNotificationMessages(props.loanGuid)
                            setUnsuccessfully(false);
                            props.updatePreApproval(true, "isRequestedPreApproval", "isRequestedPreApproval");

                        }
                        else {
                            setUnsuccessfully(true);
                            await loanOfficerMainTabService.UpdateRequestedPreApprovalFlag(list?.preApprovalLetterId);
                        }
                    });
            }
        })
    }
    const toggleModle = () => {
        setisOpen(!isOpen);
        setUnsuccessfully(false);
        setShowComment(false);
    }

    return (
        <div className="w-100-p">{isLoading && (<div className="screenLoader"><Loading /></div>)}
            <div className="infographic-side-layout-container">
                <div className="content">
                    {unSuccessfully &&
                        <Modal isOpen={true} centered={true} returnFocusAfterClose={false}>
                            <ModalBody className={`${styles.modalBody}`}>
                                <div className="align-center">
                                    <span>Your email could not be sent. Please contact your Loan Officer by phone.</span>
                                    <div>
                                        <button className="common-button" onClick={toggleModle}> Ok </button>
                                    </div>
                                </div>
                            </ModalBody>
                        </Modal>
                    }
                    {
                        currentDate > preApprovalIssueDate ?
                            props.isRequestedPreApproval || list?.isRequestedPreApproval ? <h3>Pre-Approval Letter request complete. Your Loan Officer will be in touch soon!</h3> :
                                <div>
                                    <h1>Pre-Approval Letter Expired</h1>
                                    <p>We're sorry, your pre-approval letter has expired. You can request a new one from your Loan Officer.</p>
                                    <div className={styles.buttonrow}>
                                        <button className={`common-green-button`} onClick={() => setShowComment(true)}>REQUEST NEW PRE-APPROVAL</button>
                                    </div>

                                    {show && <div>
                                        <label>Comments</label>
                                        <textarea placeholder="I'd like a new letter please"
                                            onChange={(e) => props.updatePreApproval(e.target.value, "posPreApprovalLetterComments", "posPreApprovalLetterComments")}
                                        ></textarea>

                                        <div className={styles.buttonrow}>
                                            <button className={`common-green-button`} onClick={() => sendRequestNewPreApprovalLetterEmail()}>SEND</button>
                                        </div>
                                    </div>
                                    }
                                </div>

                            :
                            <>
                                {!notification ?
                                    <>
                                        <h1>Pre-Approval Letter</h1>

                                        {(!isNullOrUndefined(list) && list.posAlterPurchasePriceIndicator) ?
                                            <> <p>You may adjust your pre-approval letter amount for your offer, but you cannot exceed your purchase price of <span className={styles.posMaxPurchasePriceText}> ${Number(list?.posMaxPurchasePrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span>
                                            </p>
                                                <div className={styles.purchasePrice}>
                                                    <div className={styles.purchasePriceText}>Purchase Price</div>
                                                    <div className={styles.flexrow}>
                                                        <div className={styles.pricePrefix}>
                                                            <NumberFormatInput
                                                                value={props?.posPurchasePrice ?? list?.posPurchasePrice}
                                                                onValueChange={(values: NumberFormatValues) => {
                                                                    props.updatePreApproval(values.value, "posPurchasePrice", "posPurchasePrice");
                                                                    setIschange(true);
                                                                    setViewButtonDisabled(true);

                                                                }}
                                                                placeholder="$00.00"
                                                                thousandSeparator={true}
                                                                prefix={"$"}
                                                                decimalScale={0}
                                                            />
                                                        </div>
                                                        <button className={`common-green-button`} disabled={disabled} onClick={() => { handleSubmit(); setIschange(false) }}>save</button>
                                                    </div>
                                                    {
                                                        !isNullOrUndefined(list?.posMaxPurchasePrice) && props.posPurchasePrice > list?.posMaxPurchasePrice &&
                                                        <span className={styles.errmsg}>You cannot exceed the Purchase Price established by your Loan Officer</span>
                                                    }
                                                </div>
                                                <p>Selecting a new purchase price will not alter your total
                                                    allowable amount. Once you select your purchase price, you
                                                    can view/print, email or download your copy as well.
                                                </p>

                                            </>
                                            :
                                            <p>Congratulations! You're pre-approved for a maximum
                                                loan value of ${(!isNullOrUndefined(props.propertyEstimatedValueAmount) && props.propertyEstimatedValueAmount !== "") ? props.propertyEstimatedValueAmount : "0"}.You can view/print,email or
                                                download your letter below
                                            </p>
                                        }
                                        <div className={styles.buttonrow}>
                                            <button className={`common-green-button`} onClick={viewPreApprovalLetter} disabled={viewButtonDisabled}>View</button>
                                            <button className={`common-green-button`} onClick={downloadPreApprovalLetter} disabled={viewButtonDisabled}>Download</button>
                                            <button className={`common-green-button`} onClick={() => setShowComment(true)} disabled={viewButtonDisabled}>Email</button>
                                        </div>

                                        {show &&
                                            <Modal isOpen={true} centered={true} returnFocusAfterClose={false}>
                                                <ModalBody className={`${styles.modalBody}`}>
                                                    <div className={styles.closeButton}>
                                                        <span onClick={toggleModle}>
                                                            <i className="fa fa-times"></i>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className={styles.emailFormRow}>
                                                            <label>To <span className={styles.requiredMsg}>*</span></label>
                                                            <ReactMultiEmail
                                                                emails={posPreApprovalLetterTo}
                                                                onChange={(_emails: string[]) => {
                                                                    setPosPreApprovalLetterTo(_emails);
                                                                    setInvalidEmails(_invalidEmails);
                                                                }}
                                                                validateEmail={email => {
                                                                    console.log("validating email: ", email);

                                                                    // using this as an "onChange" event and getting rid of old values
                                                                    if (invalidEmails !== []) {
                                                                        setInvalidEmails([]);
                                                                    }

                                                                    if (email === "undefined") {
                                                                        return false;
                                                                    }

                                                                    const isValid = Rule.EmailValidation.value.test(String(email).toLowerCase());

                                                                    if (!isValid && _invalidEmails.indexOf(email) === -1) {
                                                                        _invalidEmails.push(email);
                                                                        console.log("_invalidEmails: ", _invalidEmails.slice(0));
                                                                        setInvalidEmails(_invalidEmails);
                                                                    } 
                                                                    setIsValidEmail(isValid);
                                                                    return isValid;
                                                                }}
                                                                getLabel={(
                                                                    email: string,
                                                                    index: number,
                                                                    removeEmail: (index: number) => void,
                                                                ) => {
                                                                        return (
                                                                            <div data-tag key={index}>
                                                                                {email}
                                                                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                                                                    ×
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    }}
                                                            />
                                                            {!isValidEmail && (<span style={{ color: "red" }}>Invalid Email</span>)}
                                                        </div>
                                                        <div className={styles.emailFormRow}>
                                                            <label>Cc <span className={styles.optionalMsg}>(Optional)</span></label>
                                                            <ReactMultiEmail
                                                                emails={posPreApprovalLetterCc}
                                                                onChange={(_emails: any) => {
                                                                    setPosPreApprovalLetterCc(_emails);
                                                                    setInvalidEmails(_invalidEmails);
                                                                }}
                                                                validateEmail={email => {
                                                                    console.log("validating email: ", email);

                                                                    // using this as an "onChange" event and getting rid of old values
                                                                    if (invalidEmails !== []) {
                                                                        setInvalidEmails([]);
                                                                    }

                                                                    if (email === "undefined") {
                                                                        return false;
                                                                    }

                                                                    const isValid = Rule.EmailValidation.value.test(String(email).toLowerCase());

                                                                    if (!isValid && _invalidEmails.indexOf(email) === -1) {
                                                                        _invalidEmails.push(email);
                                                                        console.log("_invalidEmails: ", _invalidEmails.slice(0));
                                                                        setInvalidEmails(_invalidEmails);
                                                                    }
                                                                    setIsCcValidEmail(isValid);
                                                                    return isValid;
                                                                }}
                                                                getLabel={(
                                                                    email: string,
                                                                    index: number,
                                                                    removeEmail: (index: number) => void,
                                                                ) => {
                                                                        return (
                                                                            <div data-tag key={index}>
                                                                                {email}
                                                                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                                                                    ×
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                }}
                                                            />
                                                            {!isCcValidEmail && (<span style={{ color: "red" }}>Invalid Email</span>)}
                                                        </div>
                                                        <div className={styles.emailFormRow}>
                                                            <label>Additional Body Text <span className={styles.optionalMsg}>(Optional)</span></label>
                                                            <textarea onChange={(e) => setAdditionalNotes(e.target.value)} maxLength={4000}></textarea>
                                                        </div>
                                                    <div className={styles.buttonrow}>
                                                        <button disabled={sendButtonDisabled} className={`common-green-button`} onClick={() => PreApprovalLetterBaseTemplate()}>SEND</button>
                                                        </div>
                                                    </div>
                                                    {isLoading && (<div className="screenLoader"><Loading /></div>)}
                                                </ModalBody>
                                            </Modal>
                                        }
                                    </>
                                    :
                                    <>
                                        {successfully ? <h3>Pre-Approval letter sent!</h3> : <h3>Email could not be sent. Please check your sender email address and try again.</h3>}
                                    </>

                                }

                            </>
                    }
                </div>
                <div className="infographic-side-panel">
                    <Infographic src={preApproval} />
                </div>
            </div>
        </div>)
};
const mapStateToProps = (state: ApplicationState): any => {
    const loanGuid = state.accountInformation?.loanGuid;
    const posPurchasePrice = state.loanOfficerMainTab?.preApproval.posPurchasePrice;
    const propertyEstimatedValueAmount = state.dashboard?.dashboardInformation.propertyEstimatedValueAmount;
    const posPreApprovalLetterComments = state.loanOfficerMainTab?.preApproval.posPreApprovalLetterComments;
    const isRequestedPreApproval = state.loanOfficerMainTab?.preApproval.isRequestedPreApproval;
    const borrowerLastName = state.userAccountInformation?.lastName;
    return {
        loanGuid,
        posPurchasePrice,
        propertyEstimatedValueAmount,
        posPreApprovalLetterComments,
        isRequestedPreApproval,
        borrowerLastName
    };
}
const mapDispatchToProps = (dispatch: any) => ({
    updatePreApproval: (value: any, propertyName: string, section: string) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.updatePreApproval(value, propertyName, section))
    },
    getUserNotificationMessages: (loanGuid: string) => {
        dispatch(dashboardActionCreators.getUserNotificationMessages(loanGuid));
    }
});
export default connect((state: ApplicationState) => mapStateToProps, mapDispatchToProps)(BorrowerPreApprovalLetter as any);