import * as React from "react";
import styles from "./BorrowerLiability.module.scss";
import { BorrowerLiabilityDetail } from "../../store/borrowerLiabilityStore";
import Currency from "../common/Currency";
import { EnumLiability } from "../common/Enums/EnumLiability";
import { BorrowerTypeEnum } from "../../common/BorrowerTypeEnum";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface borrowerLiabilityListProps {
    borrowerLiability: BorrowerLiabilityDetail,
    loanGuid: string;
    editLiability: (liabilityId: string) => void;
    deleteLiability: (liabilityId: string) => void;
    borrowerLiabilityList: BorrowerLiabilityDetail[];
    disabled: boolean
};

const BorrowerLiabilityList = (props: borrowerLiabilityListProps) => {

    const returnLiabilityType = (liability: string) => {
        switch (liability) {
            case "ChildCare":
                return EnumLiability.ChildCare;
            case "ChildSupport":
                return EnumLiability.ChildSupport;
            case "HomeEquityLineofCredit":
                return EnumLiability.HomeEquityLineofCredit;
            case "Installment":
                return EnumLiability.Installment;
            case "LeasePayments":
                return EnumLiability.LeasePayments;
            case "MortgageLoan":
                return EnumLiability.MortgageLoan;
            case "Open30DayChargeAccount":
                return EnumLiability.Open30DayChargeAccount;
            case "OtherLiability":
                return EnumLiability.OtherLiability;
            case "Revolving":
                return EnumLiability.Revolving;
            default:
                return "";
        }
    }

    const returnBorrowerTypeEnum = (param: any) => {
        switch (param) {
            case BorrowerTypeEnum.CoBorrower:
                return 'Co-Borrower';
            default:
                return param;
        }
    }
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }

    return (
        <>
            <div className={styles.cardTile}>
                {
                    props.borrowerLiabilityList?.length > 1 &&
                    <div className={styles.closeTile} onClick={() => !props.disabled ? props.deleteLiability(props.borrowerLiability.id) : ""}>
                        <i className="fa fa-times"> </i>
                    </div>
                }

                <div className={styles.tileInfo} onClick={() => !props.disabled ? props.editLiability(props.borrowerLiability.id) : ""}>
                    <h3 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech( !isNullOrWhiteSpace(props.borrowerLiability?.liabilityType) ? returnLiabilityType(props.borrowerLiability.liabilityType) : "(Click to edit expense)" )}> {!isNullOrWhiteSpace(props.borrowerLiability?.liabilityType) ? returnLiabilityType(props.borrowerLiability.liabilityType) : "(Click to edit expense)"} </h3>
                    <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(!isNullOrWhiteSpace(props.borrowerLiability?.accountIdentifier) ?
                        `Account - ${props.borrowerLiability?.accountIdentifier} | ` : "" + !isNullOrWhiteSpace(props.borrowerLiability?.owner) ? returnBorrowerTypeEnum(props.borrowerLiability?.owner) : "TBD")}>

                        {!isNullOrWhiteSpace(props.borrowerLiability?.accountIdentifier) ?
                        `Account - ${props.borrowerLiability?.accountIdentifier} | ` : ""}
                        {!isNullOrWhiteSpace(props.borrowerLiability?.owner) ? returnBorrowerTypeEnum(props.borrowerLiability?.owner) : "TBD"}
                    </p>
                </div>

                <div className={styles.tilePriceInfo}>
                    <h3>
                        <Currency value={Number(props.borrowerLiability?.monthlyPaymentAmount)} decimalScale={2}></Currency>
                    </h3>
                    <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("per month")}> per month </p>
                </div>
            </div>
        </>
    );
};

export default BorrowerLiabilityList;
