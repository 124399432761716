import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { CountyLookUpState, KnownAction } from "../store/CountyLookUpStore";

const unloadedState: CountyLookUpState = {
    data: undefined,
    isLoading: false,
    errorMessage: ""
}

export const countyLookupReducer: Reducer<CountyLookUpState> = (state: CountyLookUpState | undefined, incomingAction: Action): CountyLookUpState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.RequestCountyLookup:

            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ReceiveCountyLookup:

            return {
                ...state,
                data: [...action.data],
                isLoading: false
            };
        case ActionTypes.HandleErrorCountyLookup:

            return {
                ...state,
                isLoading: false,
                errorMessage: action.errorMessage
            };
        case ActionTypes.ClearExceptionCountyLookup:

            return {
                ...state,
                errorMessage: ""
            };
        case ActionTypes.ClearCountyDetails:

            return {
                ...state,
                data: undefined
            };
        default:
            return state;
    }
}