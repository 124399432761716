import { AppThunkAction } from '../store/index';
import * as ActionTypes from "../common/ActionTypes";
import * as  additionalIncomeInfo from "../store/additionalIncomeStore";
import { OtherIncomeSourceFields } from '../components/additionalIncome/AdditionalIncomeFields';
import * as additionalIncomeService from "services/additionalIncomeService";
import _uniqueId from 'lodash/uniqueId';

const addOtherIncomeSourceToState = (data: additionalIncomeInfo.OtherIncomeSourceDetail): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.CreateOtherIncomeSourceItem, data };
};

const removeOtherIncomeSource = (id: string): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.DeleteOtherIncomeSourceItem, id };
};

const removeOtherIncomeSourceItemList = (owner: string): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.RemoveOtherIncomeSourceItemList, owner };
};

const updateOtherIncomeSourceField = (value: string | boolean, field: OtherIncomeSourceFields, otherIncomeSourceId: string, isNew: boolean): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.UpdateOtherIncomeSourceInformation, field, value, otherIncomeSourceId, isNew };
};

const setValidation = (validationError: any, field: string): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.SetOtherIncomeSourceValidation, validationError, field };
}

const handleError = (errorMessage: string): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.HandleErrorAdditional_incomeSource, errorMessage: errorMessage };
}

const clear = (): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.ClearExceptionAdditionalIncomeSource };
}

const request = (interimLoanStagingId: number): additionalIncomeInfo.KnownAction => {
    return {
        type: ActionTypes.RequestAdditionalIncomeSource,
        interimLoanStagingId
    };
}

const isLoading = (loading: boolean): additionalIncomeInfo.KnownAction => {
    return {
        type: ActionTypes.IsLoading,
        isLoading: loading
    };
}

const receive = (data: additionalIncomeInfo.OtherIncomeSourceDetail[]): additionalIncomeInfo.KnownAction => {
    return { type: ActionTypes.ReceiveAdditionalIncomeSource, data };
}

export const AdditionalIncomeActionCreators = {

    getAdditionalIncomeInformation: (interimLoanStagingId: number): AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => {
        dispatch(clear());
        dispatch(request(interimLoanStagingId));
        additionalIncomeService.getAll(interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    console.error(response);
                }
                return response.parsedBody as additionalIncomeInfo.OtherIncomeSourceDetail[];
            })
            .then((data: any) => {
                if (data.length > 0) {
                    data.forEach((e: any) => {
                        e.id = _uniqueId('uniqueId-');
                        e.isNew = false;
                    });
                }
                dispatch(receive(data));
            })
            .catch((error: Error) => {
                dispatch(handleError(error.message));
            });
    },

    addOtherIncomeSourceToState: (data: additionalIncomeInfo.OtherIncomeSourceDetail): AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => { //to add in state only
        dispatch(addOtherIncomeSourceToState(data));
    },

    removeOtherIncomeSourceFromState: (otherIncomeSourceId: string): AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => { //to remove from state only
        dispatch(removeOtherIncomeSource(otherIncomeSourceId));
    },

    handleAdditionalIncomeFieldChange: (value: any, type: any, otherIncomeSourceId: string, isNew: boolean): AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => {
        dispatch(updateOtherIncomeSourceField(value, type, otherIncomeSourceId, isNew));
    },

    setValidation: (error: any, field: string): AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => {
        dispatch(setValidation(error, field));
    },

    removeOtherIncomeSourceItemListFromState: (owner: string): AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => { //to remove List from state only
        dispatch(removeOtherIncomeSourceItemList(owner));
    },

    updateIncomeState: (data: additionalIncomeInfo.OtherIncomeSourceDetail[], borrowerType: any):
        AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => {
            dispatch({ type: ActionTypes.UpdateIncomeState, data: data, borrowerType: borrowerType });
        },

    isLoadingAdditionalIncome: (loading: boolean): AppThunkAction<additionalIncomeInfo.KnownAction> => (dispatch) => {
        dispatch(isLoading(loading));
    },
}