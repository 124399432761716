import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { PropertyInformationState, KnownAction } from "../store/propertyInformationStore";

const unloadedState: PropertyInformationState = {
    loanGuid: undefined,

    purposeType: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    county: undefined,

    workingWithRealtor: undefined,
    realtorCompany: undefined,
    realtorName: undefined,
    realtorPhoneNumber: undefined,
    realtorEmailAddress: undefined,

    buyExistingHome: undefined,

    ownLand: undefined,
    landValue: undefined,
    securedConstructionFinancing: undefined,
    totalAvailableToSpendOnNewHome: undefined,

    downPayment: undefined,
    downPaymentSource: undefined,
    downPaymentPercentage: undefined,
    estimatedLoanAmount: undefined,
    loanTermInYears: undefined,

    // Refinance
    address: undefined,
    unitNumber: undefined,
    estimatedCurrentValue: undefined,
    yearPropertyBuilt: undefined,
    taxesPerYear: undefined,
    homeOwnersInsurancePerYear: undefined,
    currentMortgageBalance: undefined,
    estimatedClosingDate: undefined,
    propertyUsageType: undefined,
    monthlyTax: undefined
}

export const propertyInformationReducer: Reducer<PropertyInformationState> = (state: PropertyInformationState | undefined, incomingAction: Action): PropertyInformationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UPDATE_PROPERTY_INFORMATION_FIELD: {
            if (action.propertyName === "downPaymentPercentage") {
                let downPaymentPercentage = parseFloat(action.value);
                let downPaymentAmount = (Number(state.totalAvailableToSpendOnNewHome) * (downPaymentPercentage / 100)).toFixed(2);

                return {
                    ...state,
                    downPaymentPercentage: downPaymentPercentage,
                    downPayment: downPaymentAmount
                };
            }

            if (action.propertyName === "downPayment") {
                let downPaymentPercentage = (Number(action.value) * 100) / (Number(state.totalAvailableToSpendOnNewHome));
                return {
                    ...state,
                    downPaymentPercentage: parseFloat(downPaymentPercentage.toFixed(0)),
                    downPayment: action.value
                };
            }

            if (action.propertyName === "totalAvailableToSpendOnNewHome") {
                let downPaymentPercentage = (Number(state.downPayment) * 100) / (Number(action.value));
                return {
                    ...state,
                    downPaymentPercentage: parseFloat(downPaymentPercentage.toFixed(0)),
                    totalAvailableToSpendOnNewHome: action.value
                };
            }

            let fieldValue = action.value;

            if (action.isNumericalValue) {
                if (typeof action.value === "number") {
                    fieldValue = action.value;
                } else {
                    fieldValue = action.value.trim() !== "" ? parseFloat(action.value) : undefined;
                }
            }

            return {
                ...state,
                [action.propertyName]: fieldValue
            };
        };
        case ActionTypes.UpdateAddressFields: {
            return {
                ...state,
                address: action.streetValue,
                city: action.cityValue,
                state: action.stateValue,
                zip: action.zipValue
            }
        };
        case ActionTypes.CalculateEstimatedLoanAmount: {
            let estimatedLoanValue = 0;
            if (state.totalAvailableToSpendOnNewHome && state.downPayment) {
                estimatedLoanValue = Number(state.totalAvailableToSpendOnNewHome) - Number(state.downPayment);
            }
            return {
                ...state,
                estimatedLoanAmount: estimatedLoanValue.toString()
            }
        };
        case ActionTypes.ReceivePropertyInformation: {
            let data = { ...state };
            data = action.data;
            let downPaymentPercentage = 0;
            if (data.downPayment && data.totalAvailableToSpendOnNewHome) {
                downPaymentPercentage = (Number(data.downPayment) / Number(data.totalAvailableToSpendOnNewHome)) * 100;
            }
            return {
                ...data,
                downPaymentPercentage: downPaymentPercentage
            }
        };
        default:
            return state;
    }
}
