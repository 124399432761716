import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { BorrowerLiabilityState, KnownAction } from '../store/borrowerLiabilityStore';

const unloadedState: BorrowerLiabilityState = {
    data: undefined,
    validation: undefined
}

export const borrowerLiabilityReducer: Reducer<BorrowerLiabilityState> = (state: BorrowerLiabilityState | undefined, incomingAction: Action): BorrowerLiabilityState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.ReceiveBorrowerLiability:
            return {
                ...state,
                data: action.data,
            };
        
        case ActionTypes.CreateBorrowerLiability: {
            if (state.data) {
                const copyItems = [...state.data];
                let newLiability = action.data;
                newLiability.isNew = true;
                copyItems.splice(copyItems.length, 0, newLiability);

                let borrowerLiability = { ...state.data };
                borrowerLiability = copyItems;

                return {
                    ...state, data: borrowerLiability
                }
            }
            return { ...state };
        };

        case ActionTypes.UpdateBorrowerLiabilityInformation: {
            if (state.data) {
                const liabilityDetails = [...state.data];
                liabilityDetails.map((liability: any) => {
                    if (action.liabilityId === liability.id) {
                        liability[action.field] = action.value;
                        liability.isNew = action.isNew;
                    }
                    return liability;
                });
                let borrowerLiability = { ...state.data };
                borrowerLiability = liabilityDetails;
                return {
                    ...state,
                    data: borrowerLiability
                }
            }
            return state;
        };

        case ActionTypes.DeleteBorrowerLiability: {
            if (state.data) {
                const copyItems = [...state.data];
                const index = copyItems.findIndex(i => i.id === action.id);
                copyItems.splice(index, 1);
                return {
                    ...state, data: copyItems
                }
            }
            return { ...state };
        };

        case ActionTypes.SetBorrowerLiabilityValidation: {
            let copyValidation = { ...state.validation };
            if (action.validationError) {
                copyValidation[action.field] = action.validationError;
            }
            else {
                if (copyValidation[action.field]) {
                    delete copyValidation[action.field];
                }
            }
            return {
                ...state,
                validation: copyValidation
            };
        };
        default:
            return state;
    };
}
