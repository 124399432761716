import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import Infographic from "components/common/Infographic";
import borrowerInfoGraphic from "assets/infographics/accountInformation/borrower-info.svg";
import styles from "./AccountInformation.module.scss";
import { useForm } from "react-hook-form";
import Checkbox from "components/common/Input/Checkbox";
import { useNavigation } from "hooks/useNavigation";
import { isNullOrUndefined } from "util";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { AccountInformationState } from "../../store/accountInformationStore";
import * as accountInformationService from "../../services/accountInformationService";
import { Modal, ModalBody } from "reactstrap";
import { AsYouType } from "libphonenumber-js";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

interface BorrowerInformationProps {
    updateBorrowerFirstName: (value: string) => any;
    updateBorrowerMiddleName: (value: string) => any;
    updateBorrowerLastName: (value: string) => any;
    updateBorrowerNameSuffix: (value: string) => any;
    updateBorrowerEmail: (value: string) => any;
    updateBorrowerPhoneNumber: (value: string) => any;
    submitInitialAccountInformation: (value: boolean) => any;
    borrowerFirstName?: string;
    borrowerMiddleName?: string;
    borrowerLastName?: string;
    borrowerNameSuffix?: string;
    borrowerEmail?: string;
    borrowerPhoneNumber?: string;
}

const BorrowerInformation = (props: BorrowerInformationProps) => {
    const { register, handleSubmit, errors } = useForm();
    const { speak, cancel } = useSpeechSynthesis();
    const navigation = useNavigation();
    const [checked, setChecked] = useState(false);
    const [isEmailDuplicate, setEmailDuplicate] = useState(false);
    const [numbers, setNumbers] = React.useState("");
    const [modal, setModal] = React.useState(false);
    const accountInfo = useSelector((state: ApplicationState) => state.accountInformation as AccountInformationState);
    let pattern = /^[0-9=?A-Z^a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    let phoneNumberPattern = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;

    const returnDisabled = () => {
        let isDisabled = false;
        if (isNullOrUndefined(props.borrowerFirstName) || props.borrowerFirstName == "" || isEmailDuplicate ||
            isNullOrUndefined(props.borrowerLastName) || props.borrowerLastName == "" ||
            isNullOrUndefined(props.borrowerPhoneNumber) || props.borrowerPhoneNumber == "" ||
            !phoneNumberPattern.test(props.borrowerPhoneNumber) ||
            isNullOrUndefined(props.borrowerEmail) || props.borrowerEmail == "" || !pattern.test(props.borrowerEmail) || !checked || accountInfo.isLoading
        ) {
            isDisabled = true;
        }
        return isDisabled;
    }
    useEffect(() => {
        navigation.ShowContinue(() => {
            inputField.current?.click();
        }, returnDisabled());
    }, [props.borrowerFirstName, props.borrowerLastName, props.borrowerEmail, props.borrowerPhoneNumber, checked, accountInfo.isLoading]);

    const inputField = useRef<HTMLButtonElement>(null);

    const updateFirstName = debounce(
        (firstName: string) => props.updateBorrowerFirstName(firstName),
        1000
    );
    const updateMiddleName = debounce(
        (middleName: string) => props.updateBorrowerMiddleName(middleName),
        1000
    );
    const updateLastName = debounce(
        (lastName: string) => props.updateBorrowerLastName(lastName),
        1000
    );
    const updateNameSuffix = debounce(
        (nameSuffix: string) => props.updateBorrowerNameSuffix(nameSuffix),
        1000
    );
    const updateBorrowerPhoneNumber = (phoneNumber: string) => {
        setNumbers(phoneNumber.replace("(", "").replace(")", "").replace("-", "").replace(" ", ""))
        props.updateBorrowerPhoneNumber(phoneNumber)
    }


    const updateEmail = (email: string) => {
        props.updateBorrowerEmail(email)
        accountInformationService.duplicateEmailCheck(email ?? "").then((res) => {
            setEmailDuplicate(res.parsedBody ? true : false);
            setModal(res.parsedBody ? true : false);
        });
    };

    const onSubmit = (data: any) => {
        props.submitInitialAccountInformation(true);
    };
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    return (
        <>
            { isEmailDuplicate &&
                <Modal isOpen={modal} centered={true} returnFocusAfterClose={false}>
                    <ModalBody className={`${styles.modalBody}`}>
                        <div className="align-center">
                            <h3>Duplicate email found..</h3>
                            <p> This email is already registered with us, Please try to login or register with another different email.</p>
                            <div>
                                <button className="common-button" onClick={() => setModal(false)}> Close </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            }
            <div className="infographic-side-layout-container">
                <div className="content">
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Borrower Information')}>Borrower Information</h1>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Tell us a little more about yourself')}>Tell us a little more about yourself.</h2>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className={styles.basicInfo}>
                            <div className={styles.formGg + ' ' + styles.splitCol}>
                                <section>
                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('First Name')}>
                                        First Name<span className={styles.requiredMsg}> * </span>
                                    </label>
                                    <input
                                        type="text"
                                        defaultValue={props.borrowerFirstName ?? ""}
                                        onChange={(e) => updateFirstName(e.target.value)}
                                        name="firstName"
                                        ref={register({
                                            required: "First name is required.",
                                            maxLength: { value: 30, message: "The value entered exceeds the maximum length requirement." },
                                        })}
                                    />
                                    <ErrorMessage message={errors.firstName?.message}></ErrorMessage>
                                </section>
                                <section>
                                    <label className={styles.labelWithSubText}>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Middle Name (Optional)')}>Middle Name</span>
                                        <span className={styles.subText}>(Optional)</span>
                                    </label>
                                    <input
                                        type="text"
                                        defaultValue={props.borrowerMiddleName ?? ""}
                                        onChange={(e) => updateMiddleName(e.target.value)}
                                        name="middleName"
                                    />
                                </section>
                            </div>
                            <div className={styles.formGg + ' ' + styles.splitCol}>
                                <section>
                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Last Name')}>
                                        Last Name<span className={styles.requiredMsg}> * </span>
                                    </label>
                                    <input
                                        type="text"
                                        defaultValue={props.borrowerLastName ?? ""}
                                        onChange={(e) => updateLastName(e.target.value)}
                                        name="lastName"
                                        ref={register({
                                            required: "Last name is required.",
                                            maxLength: { value: 30, message: "The value entered exceeds the maximum length requirement." },
                                        })}
                                    />
                                    <ErrorMessage message={errors.lastName?.message}></ErrorMessage>
                                </section>
                                <section>
                                    <label className={styles.labelWithSubText}>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Suffix (Optional)')}>Suffix</span>
                                        <span className={styles.subText}>(Optional)</span>
                                    </label>
                                    <input
                                        type="text"
                                        defaultValue={props.borrowerNameSuffix ?? ""}
                                        onChange={(e) => updateNameSuffix(e.target.value)}
                                        name="nameSuffix"
                                    />
                                </section>
                            </div>
                            <div className={styles.formGg + ' ' + styles.splitCol}>
                                <section>
                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Phone Number')}>
                                        Phone Number<span className={styles.requiredMsg}> * </span>
                                    </label>
                                    <NumberFormatInput
                                        format="(###) ###-####"
                                        value={props.borrowerPhoneNumber ?? ""}
                                        name="phoneNumber"
                                        className={styles.input}
                                        onValueChange={(values: NumberFormatValues) => { updateBorrowerPhoneNumber(values.formattedValue.trim()) }}
                                    />
                                    {
                                        (props.borrowerPhoneNumber === "" || numbers.length > 1 && numbers.length < 10) && <span className={styles.errmsg}>Invalid number.
                                </span>
                                    }
                                </section>
                                <section>
                                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Email')}>
                                        Email<span className={styles.requiredMsg}> * </span>
                                    </label>
                                    <input
                                        type="email"
                                        defaultValue={props.borrowerEmail ?? ""}
                                        onChange={(e) => updateEmail(e.target.value)}
                                        name="email"
                                        ref={register({
                                            required: "Email is required.",
                                            pattern: {
                                                value: /^[0-9=?A-Z^a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/,
                                                message: "The email address is invalid. Please enter a valid email address.",
                                            },
                                        })}
                                    />
                                    <ErrorMessage message={errors.email?.message}></ErrorMessage>
                                </section>
                            </div>
                            <div className={styles.consentContainer}>
                                <div className={styles.consentFlexContainer}>
                                    <Checkbox
                                        onChange={(e: boolean) => {
                                            setChecked(e);
                                        }}
                                        checked={checked}
                                        name="authCheck"
                                        reference={register({
                                            required:
                                                "You must select the authorization check box before you can continue.",
                                        })}
                                    />
                                    <span className={styles.consentText} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('By clicking CONTINUE, you are authorizing Union Home Mortgage Corp and its affiliates to deliver to you telemarketing promotions for products and /or services in addition to those for which you are applying. Such telemarketing may include automatic telephone dialing system or an artificial or prerecorded voice and text messages to the phone numbers you provided above. You are not required to sign this agreement as a condition of purchasing any property, goods or services')}>
                                        By clicking “CONTINUE”, you are authorizing Union Home Mortgage Corp and
                                        its affiliates to deliver to you telemarketing promotions for products
                                        and/or services in addition to those for which you are applying. Such
                                        telemarketing may include automatic telephone dialing system or an
                                        artificial or prerecorded voice and text messages to the phone numbers
                                        you provided above. You are not required to sign this agreement as a
                                        condition of purchasing any property, goods or services.
                </span>
                                </div>
                                <ErrorMessage message={errors.authCheck?.message}></ErrorMessage>
                            </div>

                            {/* Hidden button for form submission on continue click */}
                            <button type="submit" ref={inputField}>
                                Submit
</button>
                        </div>
                    </form>
                </div>

                <div className="infographic-side-panel">
                    <Infographic src={borrowerInfoGraphic} />
                </div>
            </div>
        </>
    );
};

interface ErrorMessageProps {
    message: string | undefined | null;
}

const ErrorMessage = (props: ErrorMessageProps) => {
    return (
        <>{props.message && <div className={styles.invalidField}>{props.message}</div>}</>
    );
};

export default BorrowerInformation;