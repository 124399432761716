import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { ConsumerCreditAuthorizationState, KnownAction } from "../store/consumerCreditAuthorizationStore";
import { BorrowerType } from "../store/Enum";

const unloadedState: ConsumerCreditAuthorizationState = {
    borrower: {
        loanGuid: undefined,
        borrowerType: undefined,
        authorizedCreditReportIndicator: undefined,
        dateAuthorizedCreditReport: undefined,
        creditReportAuthorizationMethod: undefined,
        interimLoanStagingId: undefined
    },
    isLoading: false,
    errorMessage: '',
    coBorrower: {},
}

export const consumerCreditAuthorizationReducer: Reducer<ConsumerCreditAuthorizationState> = (state: ConsumerCreditAuthorizationState | undefined, incomingAction: Action): ConsumerCreditAuthorizationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
       
        case ActionTypes.UpdateCreditAuthorizationProperty: {
            let authorizedMethod;
            authorizedMethod = action.value ? "Internet" : "";
            return action.borrowerType === BorrowerType.PrimaryBorrower ?
                {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        loanGuid: action.loanGuid,
                        borrowerType: action.borrowerType,
                        authorizedCreditReportIndicator: action.value,
                        dateAuthorizedCreditReport: new Date(),
                        creditReportAuthorizationMethod: authorizedMethod,
                        interimLoanStagingId: action.interimLoanStagingId
                    },
                } :
                {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        loanGuid: action.loanGuid,
                        borrowerType: action.borrowerType,
                        authorizedCreditReportIndicator: action.value,
                        dateAuthorizedCreditReport: new Date(),
                        creditReportAuthorizationMethod: authorizedMethod,
                        interimLoanStagingId: action.interimLoanStagingId
                    },
                };
        }
        case ActionTypes.UpdateCreditReportIndicator: {
            return action.borrowerType === BorrowerType.PrimaryBorrower ?
                {
                    ...state,
                    borrower: {
                        ...state.borrower,
                        authorizedCreditReportIndicator: action.value,
                    },
                } :
                {
                    ...state,
                    coBorrower: {
                        ...state.coBorrower,
                        authorizedCreditReportIndicator: action.value,
                    },
                };
        }
        case ActionTypes.RequestConsumerCreditAuthorization:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ReceiveConsumerCreditAuthorization:
            {
                let result;
                let data = action.data;
                if (action.borrowerType === BorrowerType.PrimaryBorrower) {
                    result = {

                        ...state,
                        borrower: data,
                        isLoading: false
                    }
                    return result;
                }
                else if (action.borrowerType === BorrowerType.CoBorrower) {
                    result = {

                        ...state,
                        coBorrower: data,
                        isLoading: false
                    }
                    return result;
                }
            }
        default:
            return state;
    }
}