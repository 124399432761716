import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as TaskMenuStore from "../store/taskMenuStore";
import * as taskMenuService from "../services/taskMenuService";
import * as loanSummaryService from "../services/LoanSummaryService";

const receive = (data: TaskMenuStore.TaskMenuItem[]): TaskMenuStore.KnownAction => {
    return { type: ActionTypes.ReceiveTaskMenu, data };
};

const receiveBorrowerInfo = (data: TaskMenuStore.LoanSummaryDto): TaskMenuStore.KnownAction => {
    return { type: ActionTypes.ReceiveBorrowerInformation, data };
};

const request = (isLoading: boolean, type: string): TaskMenuStore.KnownAction => {
    return { type: ActionTypes.RequestTaskMenu, isLoading: isLoading, loadingType: type };
};

export const TaskMenuCreators = {
    GetSubTaskItemLoanStatus: (loanGuid: string): AppThunkAction<TaskMenuStore.KnownAction> => (dispatch) => {
        dispatch(request(true, "menu"));
        taskMenuService.getSubTaskMenuLoanStatus(loanGuid)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("There was a problem while fetching progress tracker information");
                }
                return response.parsedBody as TaskMenuStore.TaskMenuItem[];
            })
            .then((data) => {
                dispatch(receive(data));
            })
    },

    borrowerLoanSummary: (loanGuid: string,interimLoanStagingId:number): AppThunkAction<TaskMenuStore.KnownAction> => (dispatch) => {
        dispatch(request(true, "loanInfo"));
        loanSummaryService.LoanSummary(loanGuid, interimLoanStagingId)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("There was a problem while fetching borrower details");
                }
                return response.parsedBody as any;
            })
            .then((data: any) => {
                if (data.body) {
                    dispatch(receiveBorrowerInfo(data?.body));
                }
            }).catch(() => {
                throw new Error("There was a problem.");
            })
    },

    updateNavigationStatus: (taskItemId: any, subTaskItemId: any, taskStatusId: any, subTaskStatusId: any, loanGuid: any): AppThunkAction<TaskMenuStore.KnownAction> => (dispatch) => {
        taskMenuService.updateNavigationStatus(taskItemId, subTaskItemId, taskStatusId, subTaskStatusId, loanGuid)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Something went wrong!");
                }
                return response.parsedBody as any;
            })
    },

    setLoanIsDirty: (isDirty: boolean): AppThunkAction<TaskMenuStore.KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetLoanIsDirty, isDirty: isDirty });
    },
    setDocumentPopupFlag: (value: boolean): AppThunkAction<TaskMenuStore.KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetPopupFlag, isPopupFlag: value });
    },
    deleteNavigationStatus: (subTaskItemId: any, loanGuid: any): AppThunkAction<TaskMenuStore.KnownAction> => (dispatch) => {
        taskMenuService.deleteNavigationStatus(subTaskItemId, loanGuid)
            .then((response) => {
                if (!response.ok) {
                    console.error("Something went wrong while deleteNavigationStatus method");
                }
                return response.parsedBody as any;
            })
    },
}