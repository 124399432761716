import * as React from "react";
import { connect } from "react-redux";
import AccountAddition from "assets/infographics/manualAssetInformation/AccountAddition.svg";
import Infographic from "../../components/common/Infographic";
import { ApplicationState } from "../../store";
import { ManualAssetInformationActionCreators } from "../../actions/manualAssetInformationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { ManualAssetInformation as ManualAsset, ManualAssetInformationState } from "../../store/ManualAssetInformationStore";
import styles from "./manualAssets.module.scss";
import TextInput from "../common/Input/TextInput";
import { ValidationMessage, Rule } from "../constants/validation";
import SelectDropDown from "../common/Input/SelectDropDown";
import { ManualAssetFields } from "../../components/manualAssetInformation/ManualAssetFields";
import { BorrowerTypeEnum } from "../../common/BorrowerTypeEnum";
import { AccountTypeEnum } from "../../common/AccountTypeEnum";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ManualAssetDetailsProps = {
    manualAssetInfo: ManualAsset;
    validation?: undefined;
    isError: any;
    isCoBorrowerPresent: boolean;
    assetType: "";
    holderName: "";
    accountIdentifier: "";
    urla2020CashOrMarketValueAmount: "";
    owner: "";
    isDisable: React.Dispatch<React.SetStateAction<boolean>>;
    isLoanComplated: React.Dispatch<React.SetStateAction<boolean>>;
    borrowerType: string;
}
    & typeof ManualAssetInformationActionCreators
    & typeof TaskMenuCreators
    & ManualAssetInformationState;

const ManualAssetDetails = (props: ManualAssetDetailsProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [disabled, setDisabled] = React.useState(false);
    React.useEffect(() => {
        if (props?.assetType !== "" && props.assetType === "Other") {
            props.isDisable(true);
            setDisabled(true);
        }
        else {
            props.isDisable(false);
            setDisabled(false);
        }
    });

    React.useEffect(() => {
        if (disabled) {
            props.updateManualAssetProprty("", ManualAssetFields.HolderName);
            props.updateManualAssetProprty("", ManualAssetFields.AccountIdentifier);
            props.updateManualAssetProprty("", ManualAssetFields.Urla2020CashOrMarketValueAmount);
        }
    });

    const returnBorrowerTypeEnum = (param: any) => {
        switch (param) {
            case BorrowerTypeEnum.CoBorrower:
                return ' Co-Borrower';
            default:
                return param;
        }
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className={styles.manualAssetsDetails}>
            <div className={styles.content}>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Tell Us About This Account')}>Tell Us About This Account</h1>
                <div className={styles.basicInfo}>
                    <div className={styles.inputRow}>
                        <div className={styles.inputGroup}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Account Type')}>Account Type<span className={styles.requiredMsg}> * </span></label>
                            <SelectDropDown
                                disabled={props.isLoanComplated}
                                value={props?.assetType ?? ""}
                                name={ManualAssetFields.AssetType}
                                rule={{ required: ValidationMessage.Required, }}
                                handleValidation={props.setValidation}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.updateManualAssetProprty(e.target.value, e.target.name)}
                                noArrow={false}
                                invalidclass={styles.invalidInput}
                                dropdownclass={styles.select}>
                                <option disabled value="">Select..</option>
                                {Object.entries(AccountTypeEnum).map(([key, values]) => (
                                    <option key={values} value={key}>
                                        {values}
                                    </option>
                                ))}
                            </SelectDropDown>
                        </div>

                        <div className={styles.inputGroup}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Financial Institution')}>Financial Institution<span className={styles.requiredMsg}> * </span></label>
                            <TextInput
                                id={ManualAssetFields.HolderName}
                                name="Financial Institution"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.updateManualAssetProprty(e.target.value, e.target.id)
                                }}
                                value={props?.holderName}
                                rule={{ required: ValidationMessage.Required, pattern: Rule.SpaceValidation }}
                                handleValidation={props.setValidation}
                                invalidclass={styles.invalidInput}
                                disabled={props.isLoanComplated || disabled}
                                className={props?.assetType && (props?.assetType === AccountTypeEnum.Other ? styles.disabledInput : "")}
                            />
                        </div>
                    </div>

                    <div className={styles.inputRow}>
                        <div className={styles.inputGroup}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Account Number (Optional)')}>Account Number <span className={styles.optionalMsg}>(Optional)</span></label>
                            <TextInput
                                id={ManualAssetFields.AccountIdentifier}
                                name="Account number"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.updateManualAssetProprty(e.target.value, e.target.id)
                                }}
                                value={props?.accountIdentifier}
                                rule={{ pattern: Rule.SpaceValidation }}
                                handleValidation={props.setValidation}
                                invalidclass={styles.invalidInput}
                                disabled={props.isLoanComplated || disabled}
                                className={props?.assetType && (props?.assetType === AccountTypeEnum.Other ? styles.disabledInput : "")}
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Cash or Market Value')}>Cash or Market Value<span className={styles.requiredMsg}> * </span></label>
                            <TextInput
                                id={ManualAssetFields.Urla2020CashOrMarketValueAmount}
                                name="Cash or Market Value"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    props.updateManualAssetProprty(e.target.value, e.target.id)
                                }}
                                value={props?.urla2020CashOrMarketValueAmount}
                                rule={{ required: ValidationMessage.Required, pattern: Rule.DecimalValidation }}
                                handleValidation={props.setValidation}
                                invalidclass={styles.invalidInput}
                                placeholder="$0.00"
                                maxLength="10"
                                disabled={props.isLoanComplated || disabled}
                                className={props?.assetType && (props?.assetType === AccountTypeEnum.Other ? styles.disabledInput : "")}
                            />
                        </div>
                    </div>
                    {props.isCoBorrowerPresent ? <div className={styles.inputRow}>
                        <div className={`${styles.inputGroup} ${styles.marginAuto}`}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Who does this asset belong to')}>Who does this asset belong to?<span className={styles.requiredMsg}> * </span></label>
                            <SelectDropDown
                                disabled={props.isLoanComplated}
                                value={props?.owner}
                                name={ManualAssetFields.Owner}
                                rule={{ required: ValidationMessage.Required }}
                                handleValidation={props.setValidation}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.updateManualAssetProprty(e.target.value, e.target.name)}
                                noArrow={false}
                                invalidclass={styles.invalidInput}
                                dropdownclass={styles.select}>
                                <option disabled value="">Select..</option>
                                {
                                    props.borrowerType === "Borrower" && props.isCoBorrowerPresent ?
                                            Object.keys(BorrowerTypeEnum).map(key => (
                                                <>
                                                    {
                                                        (key === "Borrower" || key === "Both") &&
                                                        <option key={key} value={key}>
                                                            {returnBorrowerTypeEnum(key)}
                                                        </option>
                                                    }
                                                </>
                                            ))
                                       
                                        : props.borrowerType === "CoBorrower" ?

                                            Object.keys(BorrowerTypeEnum).map(key => (
                                                <>
                                                    {
                                                        (key === "CoBorrower" || key === "Both") &&
                                                        <option key={key} value={key}>
                                                            {returnBorrowerTypeEnum(key)}
                                                        </option>
                                                    }
                                                </>
                                            ))
                                            : ""
                                }
                            </SelectDropDown>
                        </div>
                    </div> :
                        <div className={styles.inputRow}>
                            <div className={`${styles.inputGroup} ${styles.marginAuto}`}>
                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Who does this asset belong to')}>Who does this asset belong to?<span className={styles.requiredMsg}> * </span></label>
                                <select name={ManualAssetFields.Owner}
                                    value={props.owner}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.updateManualAssetProprty(e.target.value, e.target.name)} required>
                                    <option value="Borrower">Borrower</option>

                                </select>
                            </div>
                        </div>
                    }
                    {disabled &&
                        <p className={styles.otherAssset} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Your Loan Officer may need to get in contact with you to go over this asset')}> Your Loan Officer may need to get in contact with you to go over this asset.</p>
                    }
                </div>

                <div className={styles.manualAssetsImg}>
                    <Infographic src={AccountAddition} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState, ownProps: { isDisable: React.Dispatch<React.SetStateAction<boolean>>, isLoanComplated: boolean, borrowerType: string }): any => {
    let isCoBorrowerPresent;
    let assetType;
    let holderName;
    let accountIdentifier;
    let urla2020CashOrMarketValueAmount;
    let owner;
    let isDisable;
    let isLoanComplated;
    let borrowerType;
    if (state.accountCreation !== undefined) {
        if (state.accountCreation?.coBorrower?.firstName != undefined) {
            isCoBorrowerPresent = true;
        }
    }
    if (state.manualAssetInformation !== undefined) {
        assetType = state.manualAssetInformation.data?.assetType ?? "";
        holderName = state.manualAssetInformation.data?.holderName ?? "";
        accountIdentifier = state.manualAssetInformation.data?.accountIdentifier ?? "";
        urla2020CashOrMarketValueAmount = state.manualAssetInformation.data?.urla2020CashOrMarketValueAmount ?? "";
        owner = state.manualAssetInformation.data?.owner ?? "";
    }
    isDisable = ownProps.isDisable;
    isLoanComplated = ownProps.isLoanComplated;
    borrowerType = ownProps.borrowerType;
    return {
        assetType,
        holderName,
        accountIdentifier,
        urla2020CashOrMarketValueAmount,
        owner,
        isCoBorrowerPresent,
        isDisable,
        isLoanComplated,
        borrowerType
    }

};

const mapDispatchToProps = (dispatch: any) => ({
    updateManualAssetProprty: (value: string, propertyName: string) => {
        dispatch(ManualAssetInformationActionCreators.updateManualAssetProprty(value, propertyName));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    setValidation: (error: any, field: string) => {
        dispatch(ManualAssetInformationActionCreators.setValidation(error, field));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualAssetDetails as any);