import { get, post } from "../common/dataFetch";

export const getSubTaskMenuLoanStatus = (loanGuid: string) => {
    return get<any>(`SubTaskItemLoanStatus/ReadSubTaskItemLoanStatus?loanGuid=${loanGuid}`);
};

export const updateNavigationStatus = (taskItemId: any, subTaskItemId: any, taskStatusId: any, subTaskStatusId: any, loanGuid: any) => {
    return post<any>(`SubTaskItemLoanStatus/UpdateSubTaskItemLoanStatus?taskItemId=${taskItemId}&subTaskItemId=${subTaskItemId}&taskStatusId=${taskStatusId}&subTaskStatusId=${subTaskStatusId}&loanGuid=${loanGuid}`, null);
};

export const getLoanCompletedStatus = (loanGuid: string) => {
    return get<any>(`SubTaskItemLoanStatus/ReadLoanFileSubmittedStatus?loanGuid=${loanGuid}`);
};

export const deleteNavigationStatus = (subTaskItemId: any, loanGuid: any) => {
    return post<any>(`SubTaskItemLoanStatus/DeleteAsync?subTaskItemId=${subTaskItemId}&loanGuid=${loanGuid}`, null);
};