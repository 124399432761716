import { get, post } from "../common/dataFetch";
import { ResponseDto } from "common/models/ResponseDto";
import { ReoProperty } from "../store/borrowerReoStore";

export const getAll = (interimLoanStagingId:number) => {
    return get<any>(`BorrowerReo/ReadByInterimIdAsync?interimLoanStagingId=${interimLoanStagingId}`);
};

export const update = (data: ReoProperty[], hasAdditionalProperties: boolean, loanGuid: string, interimLoanStagingId:number) => {
    return post<any>("BorrowerReo/SaveBorrowerReoInformation", {
        HasAdditionalProperties: hasAdditionalProperties,
        LoanGuid: loanGuid,
        AdditionalProperties: data,
        InterimLoanStagingId: interimLoanStagingId
    });
};


export const remove = (borrowerReo: ReoProperty, interimLoanStagingId:number) => {
    return post<ResponseDto<boolean>>(`BorrowerReo/DeleteAsync?interimLoanStagingId=${interimLoanStagingId}`, borrowerReo);
};