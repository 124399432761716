import { useDispatch } from "react-redux";
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction } from "../store/documentStore";

export interface DocumentActions {
  AlterSectionExpandedStatus: (sectionId: number, isOpen: boolean) => void;
  AlterSelectedTab: (tabIndex: number) => void;
}

export function useDocumentActions(): DocumentActions {
  const dispatch = useDispatch();
  var actions: DocumentActions = {
    AlterSectionExpandedStatus: (sectionId: number, isOpen: boolean) => {
      dispatch({
        type: ActionTypes.AlterSectionExpandedStatus,
        sectionId: sectionId,
        isOpen: isOpen,
      } as KnownAction);
    },
    AlterSelectedTab: (tabIndex: number) => {
      dispatch({
        type: ActionTypes.AlterSelectedTab,
        tabIndex: tabIndex,
      } as KnownAction);
    },
  };
  return actions;
}
