import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styles from "./MessageBox.module.scss";

interface MessageBoxProps {
    title: any;
    messages: string[];
    show: boolean;
    close: () => void;
    login?: () => void;
}

const MessageBox = (props: MessageBoxProps) => {
    const closeMsgBox = () => {
        props.close();
    };

    return (
        <Modal className={styles.modal} isOpen={props.show}>
            <ModalHeader className={styles.modalHeader} toggle={closeMsgBox}>
                <div className={styles.title}>{props.title}</div>
            </ModalHeader>
            <ModalBody className={styles.modalBody}>
                <ul>
                    {props.messages.map(m => <li key={Date.now()}>{m}</li>)}
                </ul>
            </ModalBody>
            <ModalFooter>
                <div className="content align-center">
                    {
                        props.messages.includes("An account already exists with this email address. Please go back to login page and try to sign in with your credentials.")
                        || props.messages.includes("User already verified. Please go back to login page and try to sign in with your credentials.") &&
                        <>
                            <button className={`common-green-button ${styles.button}`} onClick={props.login}> Back to Login </button>
                        </>
                    }

                    &nbsp;
                    <button className={`common-button ${styles.button}`} onClick={closeMsgBox}> CLOSE </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default MessageBox;
