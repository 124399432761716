import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction, MilestoneNavigationState } from '../store/MilestoneNavigationStore';
import * as milestoneNavigationService from "../services/milestoneNavigationService";

const receive = (estimatedClosingDate: string, actualClosingDate: string, closed: string, completingTheApplication: string,
    creditAnalysis: string, processingTheApplication: string, readyForClosing: string): KnownAction => {
    return {
        type: ActionTypes.ReceiveMilestoneDetails, estimatedClosingDate: estimatedClosingDate, actualClosingDate: actualClosingDate,
        readyForClosing: readyForClosing, processingTheApplication: processingTheApplication,
        creditAnalysis: creditAnalysis, completingTheApplication: completingTheApplication,
        closed: closed
    };
}

export const MilestoneNavigationServiceActionCreators = {
    getMilestoneDetails: (loanGuid: string): AppThunkAction<KnownAction> => (dispatch) => {
        milestoneNavigationService.getMilestoneDetails(loanGuid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem fetching Milestone Details for loanGuid " + loanGuid + " .");
                }
                return response.parsedBody as MilestoneNavigationState;
            })
            .then((data: MilestoneNavigationState) => {
                if (data) {
                    dispatch(receive(data.estimatedClosingDate, data.actualClosingDate, data.closed, data.completingTheApplication,
                        data.creditAnalysis, data.processingTheApplication, data.readyForClosing));
                }
            })
    }
}