import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccountCreationActions from "../../actions/accountCreationActions";
import * as UserAcountActions from "../../actions/userAcount";
import * as AccountCreationStore from "../../store/accountCreationStore";
import Infographic from "components/common/Infographic";
import AddressConfirmation from "components/accountCreation/AddressConfirmation";
import additionalAddressInfographic from "../../assets/infographics/accountCreation/2YearResidency.svg";
import coBorrowerLandingInfographic from "../../assets/infographics/accountCreation/coborrowerinformationPrompt.svg";
import BorrowerInformation from "components/accountCreation/BorrowerInformation";
import AdditionalAddress from "./AdditionalAddressNew";
import * as accountCreationService from "../../services/accountCreationService";
import { UpdateUserOktaAccount } from "../../services/accountInformationService";
import { useNavigation } from "hooks/useNavigation";
import styles from "./accountCreation.module.scss";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { bindActionCreators } from "redux";
import { TaskItem, TaskStatusId, SubTaskItem } from "../common/progressTracker/TaskStatus";
import { dateDiff } from "../../common/helper/formatHelper";
import MessageBox from "../common/MessageBox";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { BorrowerTypeEnum } from "../../common/BorrowerTypeEnum";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type AccountCreationProps = AccountCreationStore.AccountCreationState & {
    loanGuid?: string,
    validation: any;
    history: any;
    isLoanCompleted: boolean;
    userType: any;
    interimLoanStagingId: number;
} & typeof AccountCreationActions.accountCreationActionCreators & typeof UserAcountActions.userAcountActionCreators;

export enum AccountCreationStep {
    BorrowerInformation = 1,
    AddressConfirmation = 2,
    AskAdditionalAddress = 3,
    AdditionalAddress = 4,
    CoBorrowerPrompt = 5,
    CoBorrowerInformation = 6,
    CoBorrowerAddressConfirmation = 7,
    AskCoBorrowerAdditionalAddress = 8,
    CoBorrowerAdditionalAddress = 9,
    Finished = 10,
    CoborrowerFinished = 11
}
const AccountCreation = (props: AccountCreationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
  const [currentStep, changeStep] = React.useState(AccountCreationStep.BorrowerInformation);
    const [borrowerValid, setBorrowerValid] = React.useState(false);
    const [isborrowerComplete, setborrowerComplete] = React.useState(false);
    const [isCoborrowerComplete, setCoborrowerComplete] = React.useState(false);
    const [monthValid, setMonthValid] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [borrowerType, setBorrowerType] = React.useState("");
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const navigation = useNavigation();
    const { history } = props;
    const [showResetEConsentMessage, setShowResetEConsentMessage] = React.useState(false);

    const actions = {
        ...bindActionCreators({ ...TaskMenuCreators, ...loanUpdateResponseActionCreators }, useDispatch())
    };
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        if (history.location.state?.args == "coborrower") {
            if (!history.location.state?.isBack) {
                changeStep(AccountCreationStep.CoBorrowerInformation);
            } else {
                changeStep(needAddtionalCoBorrowerAddress() ?
                    AccountCreationStep.CoBorrowerAdditionalAddress : AccountCreationStep.CoBorrowerAddressConfirmation);
            }
            setBorrowerType(BorrowerTypeEnum.CoBorrower);
        }
        else if (history.location.state?.args == "borrower") {
            if (!history.location.state?.isBack) {
                changeStep(AccountCreationStep.BorrowerInformation);
            } else {
                changeStep(props.borrower.isCoBorrowerSelectionAllowed == true ? AccountCreationStep.CoBorrowerPrompt :
                    (needAddtionalAddress() ? AccountCreationStep.AdditionalAddress : AccountCreationStep.AddressConfirmation));
            }
            setBorrowerType(BorrowerTypeEnum.Borrower);
        }
    }, [history.location]);

    React.useEffect(() => {
        if (props.loanGuid && borrowerType && props.interimLoanStagingId) {
            props.getBorrowerInformation(borrowerType, props.interimLoanStagingId);
        }
    }, [borrowerType]);

    const needAddtionalAddress = (): boolean => {
        let needAdditionalAddress = false;
        if (props.borrower.currentAddress &&
            props.borrower.currentAddress.moveInYear &&
            props.borrower.currentAddress.moveInMonth != undefined) {
            const currentAddressMoveIn = new Date(props.borrower.currentAddress.moveInYear, props.borrower.currentAddress.moveInMonth);
            const months = dateDiff(currentAddressMoveIn);

            if (months < 24) {
                needAdditionalAddress = true;
            }
        }
        return needAdditionalAddress;
    }

    const needAddtionalCoBorrowerAddress = (): boolean => {
        let needAdditionalAddress = false;
        if (props.coBorrower &&
            props.coBorrower?.currentAddress &&
            props.coBorrower.currentAddress.moveInYear &&
            props.coBorrower.currentAddress.moveInMonth != undefined) {
            let currentAddressMoveIn = new Date(props.coBorrower.currentAddress.moveInYear, props.coBorrower.currentAddress.moveInMonth);
            let months = dateDiff(currentAddressMoveIn);

            if (months < 24) {
                needAdditionalAddress = true;
            }
        }
        return needAdditionalAddress;
    }

    const resetFormerAddress = (borrType: AccountCreationStore.BorrowerType) => {
        props.ResetFormerAddress(borrType);
        changeStep(AccountCreationStep.BorrowerInformation);
    }

    const durationCalculation = (address: AccountCreationStore.Address): (AccountCreationStore.Address) => {
        if (address.moveInMonth != undefined && address.moveInYear) {
            let currentAddressMoveIn = new Date(address.moveInYear, address.moveInMonth - 1);
            let totalMonths = dateDiff(currentAddressMoveIn);

            address.durationTermYears = Math.floor(totalMonths / 12);
            address.durationTermMonths = totalMonths % 12;
        }
        return address;
    }

    const updateNavigation = (nav: string) => {
        if (!props.isLoanCompleted) {
            if (nav === BorrowerTypeEnum.CoBorrower) {
                actions.updateNavigationStatus(TaskItem.BorrowerInformation, SubTaskItem.BorrowerInformation, TaskStatusId.Completed,
                    TaskStatusId.Completed, props.loanGuid);
                actions.updateNavigationStatus(TaskItem.CoBorrowerInformation, SubTaskItem.CoBorrowerInformation, TaskStatusId.InProgress,
                    TaskStatusId.InProgress, props.loanGuid);
            } else if (nav === "CreditInformation") {
                actions.updateNavigationStatus(TaskItem.CoBorrowerInformation, SubTaskItem.CoBorrowerInformation, TaskStatusId.Completed,
                    TaskStatusId.Completed, props.loanGuid);
                actions.updateNavigationStatus(TaskItem.CreditInformation, SubTaskItem.CreditInformation, TaskStatusId.InProgress,
                    TaskStatusId.InProgress, props.loanGuid);
            }
            else if (nav === "No") {
                actions.updateNavigationStatus(TaskItem.BorrowerInformation, SubTaskItem.BorrowerInformation, TaskStatusId.Completed,
                    TaskStatusId.Completed, props.loanGuid);
                actions.updateNavigationStatus(TaskItem.CreditInformation, SubTaskItem.CreditInformation, TaskStatusId.InProgress,
                    TaskStatusId.InProgress, props.loanGuid);
            }
        }
    }

    const saveBorrowerInformation = (step: AccountCreationStep) => {
        if (props.isLoanCompleted) {
            if (props.borrower.printOnAdditionalBorrowerPage !== true && step == AccountCreationStep.Finished) {
                history.push({ pathname: "/credit-authorization" });
            }
            else if (step == AccountCreationStep.CoborrowerFinished) {
                history.push({ pathname: "/credit-authorization" });
            }
            changeStep(step);
            if (step === AccountCreationStep.CoBorrowerInformation) {
                history.replace({ state: { args: "coborrower" } });
            }
            return true;
        }

        setLoading(true);
        let borrowerData: AccountCreationStore.Borrower;
        actions.setLoanIsDirty(false);
        if (borrowerType === BorrowerTypeEnum.Borrower) {
            borrowerData = props.borrower;
            borrowerData.loanGuid = props.loanGuid;
            borrowerData.interimLoanStagingId = props.interimLoanStagingId;
            borrowerData.addresses = borrowerData.addresses?.filter(x => x.residencyType == "Prior");
            if (props.borrower?.currentAddress) {
                borrowerData.addresses?.push(durationCalculation(props.borrower?.currentAddress))
            }
        }
        else {
            borrowerData = props.coBorrower;
            borrowerData.loanGuid = props.loanGuid;
            borrowerData.interimLoanStagingId = props.interimLoanStagingId;
            borrowerData.addresses = borrowerData.addresses?.filter(x => x.residencyType == "Prior");
            if (props.coBorrower?.currentAddress) {
                borrowerData.addresses?.push(durationCalculation(props.coBorrower?.currentAddress))
            }
        }
        accountCreationService.create(borrowerData, props.userType)
            .then(async (response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem updating user information.");
                }

                let result = response.parsedBody as boolean;

                if (borrowerType === BorrowerTypeEnum.CoBorrower) {
                    accountCreationService.createBorrowerLoanDetailAsync(borrowerData);
                } else {
                    if (props.borrower.isCoBorrowerSelectionAllowed == true) {
                        if (props.borrower.printOnAdditionalBorrowerPage == true) {
                            actions.deleteNavigationStatus(SubTaskItem.CreditInformation, props.loanGuid);
                        } else if (props.borrower.printOnAdditionalBorrowerPage == false) {
                            actions.deleteNavigationStatus(SubTaskItem.CoBorrowerInformation, props.loanGuid);
                        }
                    }
                }

                accountCreationService.updateBorrowerInformationAsync(borrowerData);
                return result;
            })
            .then((result: any) => {
                if (result != undefined && result != false && !result.isEncompassDown && borrowerData.loanGuid != undefined) {
                    accountCreationService.checkIsEConsentResetRequired(borrowerData.loanGuid, borrowerType).then((eConsentResponse: any) => {
                        if (eConsentResponse.parsedBody) {
                            console.log(`eConsentResponse to loanGuid: ${result.loanGuid} and response: ${eConsentResponse.parsedBody}`);
                        }

                        if (step === AccountCreationStep.AddressConfirmation ||
                            step === AccountCreationStep.CoBorrowerAddressConfirmation) {
                            if (eConsentResponse.parsedBody === true && borrowerType == props.userType) {
                                setShowResetEConsentMessage(prev => true);
                                setLoading(false);
                                return true;
                            }
                        }

                        const currentBorrowerType = borrowerData.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? BorrowerTypeEnum.Borrower : BorrowerTypeEnum.CoBorrower;
                        if (props.interimLoanStagingId) {
                            props.getBorrowerInformation(currentBorrowerType, props.interimLoanStagingId);
                            if (borrowerType == BorrowerTypeEnum.CoBorrower) {
                                props.getBorrowerInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                            }
                        }

                        if (step == AccountCreationStep.Finished || step == AccountCreationStep.CoborrowerFinished) {
                            if (step == AccountCreationStep.CoborrowerFinished) {
                                updateNavigation("CreditInformation");
                            }
                            history.push({ pathname: "/credit-authorization" });
                        }
                        else {
                            changeStep(step);
                        }
                        if (step === AccountCreationStep.CoBorrowerInformation) {
                            history.replace({ state: { args: "coborrower" } });
                        }
                        setLoading(false);
                        return result;
                    })
                }
            })
            .catch((error: Error) => {
                setLoading(false);
                throw new Error(`Following error message occurred ${error.message}`);
            });

        return false;
    }

    const onBack = (currentStep: any, previousStep: any) => {
        if (currentStep === AccountCreationStep.BorrowerInformation) {
            resetFormerAddress(AccountCreationStore.BorrowerType.PrimaryBorrower);
            resetFormerAddress(AccountCreationStore.BorrowerType.CoBorrower);
            actions.setLoanIsDirty(false);
            history.push('/dashboard');
        } else if (previousStep === AccountCreationStep.CoBorrowerPrompt || currentStep === AccountCreationStep.CoBorrowerInformation) {
            history.replace({ state: { args: "borrower", isBack: true } });
        }
        changeStep(previousStep);
    };

    const showContinue = (currentStep: any) => {
        if (currentStep === AccountCreationStep.AddressConfirmation) {
            if ((props.borrower?.totalMonths ?? -1) < 0) {
                return true;
            } return false;
        }
        else if (currentStep === AccountCreationStep.CoBorrowerAddressConfirmation) {
            if ((props.coBorrower?.totalMonths ?? -1) < 0) {
                return true;
            } return false;
        }
    }

    const onContinue = (currentStep: any, nextStep: any) => {
        let callNextStep = true;
        if (nextStep === AccountCreationStep.CoborrowerFinished) {
            callNextStep = false;
            updateNavigation("CreditInformation");
            saveBorrowerInformation(AccountCreationStep.CoborrowerFinished);
        }
        else if (currentStep === AccountCreationStep.BorrowerInformation && nextStep === AccountCreationStep.AddressConfirmation) {
            callNextStep = false;
            saveBorrowerInformation(AccountCreationStep.AddressConfirmation);
        }
        else if (currentStep === AccountCreationStep.AdditionalAddress) {
            if (props.borrower.isCoBorrowerSelectionAllowed == true) {
                nextStep = AccountCreationStep.CoBorrowerPrompt;
            } else {
                callNextStep = false;
                saveBorrowerInformation(props.borrower.printOnAdditionalBorrowerPage === true ?
                    AccountCreationStep.CoBorrowerInformation : AccountCreationStep.Finished);
            }
        }
        else if (currentStep === AccountCreationStep.AddressConfirmation) {
            if (!needAddtionalAddress()) {
                if (props.borrower.isCoBorrowerSelectionAllowed == true) {
                    nextStep = AccountCreationStep.CoBorrowerPrompt;
                } else {
                    callNextStep = false;
                    saveBorrowerInformation(props.borrower.printOnAdditionalBorrowerPage === true ?
                        AccountCreationStep.CoBorrowerInformation : AccountCreationStep.Finished);
                }
            }
            else {
                nextStep = AccountCreationStep.AskAdditionalAddress;
            }
        }
        else if (currentStep === AccountCreationStep.CoBorrowerInformation && nextStep === AccountCreationStep.CoBorrowerAddressConfirmation) {
            saveBorrowerInformation(AccountCreationStep.CoBorrowerAddressConfirmation);
            callNextStep = false;
        }
        else if (currentStep == AccountCreationStep.CoBorrowerAddressConfirmation) {
            if (!needAddtionalCoBorrowerAddress()) {
                callNextStep = false;
                nextStep = AccountCreationStep.CoborrowerFinished;
                saveBorrowerInformation(AccountCreationStep.CoborrowerFinished);
                updateNavigation("CreditInformation");
            }
            else
                nextStep = AccountCreationStep.AskCoBorrowerAdditionalAddress;
        }
        else if (currentStep === AccountCreationStep.Finished || currentStep === AccountCreationStep.CoborrowerFinished) {
            history.push({ pathname: "/credit-authorization" });
        }
        if (callNextStep) {
            changeStep(nextStep);
        }
    };

    const gotoEConsent = async () => {
        setShowResetEConsentMessage(false);
        if (props.userType === BorrowerTypeEnum.Borrower) {
            var result = await UpdateUserOktaAccount(props.borrower.emailAddressText ?? "", props.borrower.firstName ?? "", props.borrower.lastName ?? "")
            if (result.parsedBody) {
                props.UpdateOktaResult(result.parsedBody);
            }
        }
        else if (props.userType === BorrowerTypeEnum.CoBorrower) {
            var result = await UpdateUserOktaAccount(props.coBorrower.emailAddressText ?? "", props.coBorrower.firstName ?? "", props.coBorrower.lastName ?? "")
            if (result.parsedBody) {
                props.UpdateOktaResult(result.parsedBody);
            }
        }  
       
        history.push({ pathname: "/econsent" });
    }

    let accountCreationPage = <></>;

    if (currentStep === AccountCreationStep.BorrowerInformation) {
        accountCreationPage =
            <>
                <BorrowerInformation borrowerType={AccountCreationStore.BorrowerType.PrimaryBorrower} setBorrowerValid={setBorrowerValid} disabled={props.isLoanCompleted} />
                {navigation.ShowContinue(
                    () => onContinue(AccountCreationStep.BorrowerInformation, AccountCreationStep.AddressConfirmation),
                    (props?.validation && Object.keys(props?.validation).length > 0) || !borrowerValid || isLoading || isEncompassDown
                )}
                {navigation.SetBackOnClick(
                    () => onBack(AccountCreationStep.BorrowerInformation, null)
                )}
            </>;
    }
    else if (currentStep === AccountCreationStep.AddressConfirmation) {
        accountCreationPage =
            <>

                <AddressConfirmation borrowerType={AccountCreationStore.BorrowerType.PrimaryBorrower} setMonthValid={setMonthValid} disabled={props.isLoanCompleted} />
                {navigation.SetBackOnClick(
                    () => onBack(AccountCreationStep.AddressConfirmation, AccountCreationStep.BorrowerInformation)
                )}
                {navigation.ShowContinue(
                    () => onContinue(AccountCreationStep.AddressConfirmation, AccountCreationStep.AddressConfirmation),
                    (showContinue(AccountCreationStep.AddressConfirmation) || !monthValid || isLoading || isEncompassDown)
                )}
            </>;
    }
    else if (currentStep === AccountCreationStep.AskAdditionalAddress) {
        accountCreationPage =
            <>

                <div className="">
                    <div className={`infographic-side-layout-container ${styles.landingContainer}`}>
                        <div className="content">
                        <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('We Need A Little More Information')}>We Need A Little More Information.</h1>

                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('We need information from the past two years. Please take the time to fill out a few more fields.')}>We need information
                            from the past two
                            years. Please take the
                            time to fill out a few
                            more fields.
                            </h2>
                        </div>
                        <div className="infographic-side-panel">
                            <Infographic src={additionalAddressInfographic} />
                        </div>
                    </div>
                    {navigation.SetBackOnClick(
                        () => onBack(AccountCreationStep.AskAdditionalAddress, AccountCreationStep.AddressConfirmation)
                    )}
                    {navigation.ShowContinue(
                        () => onContinue(AccountCreationStep.AskAdditionalAddress, AccountCreationStep.AdditionalAddress),
                        isEncompassDown
                    )}
                </div>
            </>;
    }
    else if (currentStep === AccountCreationStep.AdditionalAddress) {
        accountCreationPage =
            <>

                <AdditionalAddress borrowerType={AccountCreationStore.BorrowerType.PrimaryBorrower} setComplete={setborrowerComplete} disabled={props.isLoanCompleted} />
                {navigation.SetBackOnClick(
                    () => onBack(AccountCreationStep.AdditionalAddress, AccountCreationStep.AskAdditionalAddress)
                )}
                {navigation.ShowContinue(
                    () => onContinue(AccountCreationStep.AdditionalAddress,
                        (props.borrower.printOnAdditionalBorrowerPage === undefined || props.borrower.printOnAdditionalBorrowerPage === null) ?
                            AccountCreationStep.CoBorrowerPrompt : AccountCreationStep.CoBorrowerInformation),
                    !isborrowerComplete || isLoading || isEncompassDown
                )}
            </>;
    }
    else if (currentStep === AccountCreationStep.CoBorrowerPrompt) {
        accountCreationPage =
            <>

                <div className="infographic-side-layout-container">
                    <div className="content">
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Thank you for the information')}>Thank you for the information!</h1>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Will you have a Co-Borrower on this loan')}>Will you have a Co-Borrower on this loan?</h2>
                        <div className="button-list">
                            <button disabled={props.isLoanCompleted} className={`common-button ${props.borrower.printOnAdditionalBorrowerPage === true ? "active" : ""}`}
                                onClick={() =>
                                    props.updateBorrowerProperty(true, "printOnAdditionalBorrowerPage", AccountCreationStore.BorrowerType.PrimaryBorrower)
                                } onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to yes')}>Yes</button>
                            <button disabled={props.isLoanCompleted} className={`common-button ${props.borrower.printOnAdditionalBorrowerPage === false ? "active" : ""}`}
                                onClick={() =>
                                    props.updateBorrowerProperty(false, "printOnAdditionalBorrowerPage", AccountCreationStore.BorrowerType.PrimaryBorrower)
                                } onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to no')}>No</button>
                        </div>
                    </div>
                    <div className="infographic-side-panel">
                        <Infographic src={coBorrowerLandingInfographic} />
                    </div>
                </div>
            </>;
        {
            navigation.SetBackOnClick(
                () => {
                    onBack(AccountCreationStep.AddressConfirmation,
                        needAddtionalAddress() ? AccountCreationStep.AdditionalAddress : AccountCreationStep.AddressConfirmation)
                }
            )
            navigation.ShowContinue(() => {
                saveBorrowerInformation(props.borrower.printOnAdditionalBorrowerPage ? AccountCreationStep.CoBorrowerInformation :
                    AccountCreationStep.Finished);
                updateNavigation(props.borrower.printOnAdditionalBorrowerPage ? "CoBorrower" : "No");
            }, props.borrower?.printOnAdditionalBorrowerPage === undefined || props.borrower?.printOnAdditionalBorrowerPage === null || isLoading || isEncompassDown)
        }
    }
    else if (currentStep === AccountCreationStep.CoBorrowerInformation) {
        accountCreationPage =
            <>
                <BorrowerInformation borrowerType={AccountCreationStore.BorrowerType.CoBorrower} setBorrowerValid={setBorrowerValid} disabled={props.isLoanCompleted} />
                {navigation.SetBackOnClick(
                    () => onBack(AccountCreationStep.CoBorrowerInformation, props.borrower.isCoBorrowerSelectionAllowed == true ? AccountCreationStep.CoBorrowerPrompt :
                        (needAddtionalAddress() ? AccountCreationStep.AdditionalAddress : AccountCreationStep.AddressConfirmation))
                )}
                {navigation.ShowContinue(
                    () => onContinue(AccountCreationStep.CoBorrowerInformation, AccountCreationStep.CoBorrowerAddressConfirmation),
                    (props?.validation && Object.keys(props?.validation).length > 0) || !borrowerValid || isLoading || isEncompassDown
                )}
            </>;
    }
    else if (currentStep === AccountCreationStep.CoBorrowerAddressConfirmation) {
        accountCreationPage =
            <>

                <AddressConfirmation borrowerType={AccountCreationStore.BorrowerType.CoBorrower} setMonthValid={setMonthValid} disabled={props.isLoanCompleted} />
                {navigation.SetBackOnClick(
                    () => onBack(AccountCreationStep.CoBorrowerAddressConfirmation, AccountCreationStep.CoBorrowerInformation),
                )}
                {navigation.ShowContinue(
                    () => onContinue(AccountCreationStep.CoBorrowerAddressConfirmation, AccountCreationStep.CoBorrowerAddressConfirmation),
                    (showContinue(AccountCreationStep.CoBorrowerAddressConfirmation) ||
                        !monthValid || isLoading || isEncompassDown)
                )}
            </>;
    }
    else if (currentStep === AccountCreationStep.AskCoBorrowerAdditionalAddress) {
        accountCreationPage =
            <>

                <div className="">
                    <div className={`infographic-side-layout-container ${styles.landingContainer}`}>
                        <div className="content">
                        <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('We Need A Little More Information')}>We Need A Little More Information.</h1>

                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('We need information from the past two years.Please take the time to fill out a few more fields')}>We need information
                                from the past two
                                years. Please take the
                                time to fill out a few
                                more fields.
                            </h2>
                        </div>
                        <div className="infographic-side-panel">
                            <Infographic src={additionalAddressInfographic} />
                        </div>
                    </div>
                    {navigation.SetBackOnClick(
                        () => onBack(AccountCreationStep.AskCoBorrowerAdditionalAddress, AccountCreationStep.CoBorrowerInformation)
                    )}
                    {navigation.ShowContinue(
                        () => onContinue(AccountCreationStep.AskCoBorrowerAdditionalAddress, AccountCreationStep.CoBorrowerAdditionalAddress),
                        isEncompassDown
                    )}
                </div>
            </>;
    }
    else if (currentStep === AccountCreationStep.CoBorrowerAdditionalAddress) {
        accountCreationPage =
            <>

                <AdditionalAddress borrowerType={AccountCreationStore.BorrowerType.CoBorrower} setComplete={setCoborrowerComplete} disabled={props.isLoanCompleted} />
                {navigation.SetBackOnClick(
                    () => onBack(AccountCreationStep.CoBorrowerAdditionalAddress, AccountCreationStep.CoBorrowerAddressConfirmation),
                )}
                {navigation.ShowContinue(
                    () => onContinue(AccountCreationStep.CoBorrowerAdditionalAddress, AccountCreationStep.CoborrowerFinished),
                    !isCoborrowerComplete || isLoading || isEncompassDown
                )}
            </>;
    }

    return <div className="w-100-p">
        {accountCreationPage}
        {isLoading && <div className="screenLoader"><div className="loader medium" /></div>}
        {showResetEConsentMessage &&
            <MessageBox title="Electronic Consent"
                messages={["One of the following information has changed; Name or Email Address and you must provide eConsent again."]}
                show={true}
                close={() => gotoEConsent()}
            ></MessageBox>
        }

    </div>;
};

const mapStateToProps = (state: ApplicationState): any => {
    const isLoanCompleted = state.loan?.isLoanCompleted;
    const userType = state.userAccountInformation?.borrowerType;
    let validation = state.accountCreation?.validation;
    if (state && state.accountCreation?.borrower) {
        validation = state.accountCreation?.validation;
    }
    return state.accountInformation ?
        {
            ...state.accountCreation,
            loanGuid: state.accountInformation.loanGuid ?? "",
            interimLoanStagingId: state.loan?.interimLoanStagingId ?? 0,
            validation,
            isLoanCompleted,
            userType
        } :
        state.accountCreation;
}

export default connect(
    mapStateToProps,
    (AccountCreationActions.accountCreationActionCreators)
)(AccountCreation as any);