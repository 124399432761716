import { get, post } from "../common/dataFetch";
import { AccountInformation } from "../components/accountInformation/AccountInformation";
import { IAccountInformationProps } from "../store/accountInformationStore";

export const getAccountinfoData = (loanGuid: string) => {
    return get<any>(`AccountInformation/ReadByLoanGuid?loanGuid=${loanGuid}`);
};

export const getAll = (loanOfficerLoginId: string) => {
    return get<any>(`AccountInformation/GetDropdownAsync?loanOfficerLoginId=${loanOfficerLoginId}`);
};

export const getSingle = (id: number) => {
    return get<any>(`AccountInformation/${id}`);
};

export const create = (account: AccountInformation, loanGuid?: string) => {
    return post<any>(`AccountInformation/UpsertAsync?loanGuid=${loanGuid}`, account);
};

export const assignLoanOfficer = (loanGuid: string, loUserId?: string) => {
    return get<any>(`AccountInformation/assignLoanOfficer?loanGuid=${loanGuid}&loUserId=${loUserId}`);
};

export const updateTemplate = (loanGuid: string, loanPurpose?: string) => {
    return get<any>(`AccountInformation/UpdateTemplate?loanGuid=${loanGuid}&loanPurpose=${loanPurpose}`);
};

export const createLeadEntry = (loanGuid: string) => {
    return get<any>(`AccountInformation/CreateLeadEntry?loanGuid=${loanGuid}`);
};

export const createInterimLoanStagingData = (loanGuid: string) => {
    return get<any>(`AccountInformation/CreateInterimLoanStagingData?loanGuid=${loanGuid}`);
};

export const createLoanDetailInformationEntry = (loanGuid: string) => {
    return get<any>(`AccountInformation/CreateLoanDetailInformationEntry?loanGuid=${loanGuid}`);
};

export const sendNewLeadNotificationToLoanOfficer = (loanGuid: string) => {
    return get<any>(`AccountInformation/SendNewLeadNotificationToLoanOfficer?loanGuid=${loanGuid}`);
};

export const updateProgressTracker = (loanGuid: string) => {
    return get<any>(`AccountInformation/UpdateProgressTracker?loanGuid=${loanGuid}`);
};


export const signIn = (email: string, borrowerType: string, firstName: string, lastName: string, loanGuid: string) => {
    const borrowerTypeEnumVal = borrowerType === "Borrower"
        ? 1
        : borrowerType === "CoBorrower"
            ? 2
            : 0;    // Unknown (borrowerType will be determined from DB)
    return get<any>(`AccountInformation/SignIn?email=${email}&borrowerType=${borrowerTypeEnumVal}&firstName=${firstName}&lastName=${lastName}&loanGuid=${loanGuid}`);
};
export const createUserLoanDetailInformationEntry = (email: any, firstName: any, lastName: any, loanGuid: string, borrowerType: string) => {
    return post<any>(`AccountInformation/CreateUserLoanDetailInformationEntry?email=${email}&firstName=${firstName}&lastName=${lastName}&loanGuid=${loanGuid}&borrowerType=${borrowerType}`, null);
};

export const validateLoanGuid = (loanGuid: string) => {
    return get<any>(`AccountInformation/ValidateLoanGuid?loanGuid=${loanGuid}`);
};

export const sendEmailVerificationCode = (deliveryMethod: string, userData: IAccountInformationProps, loanGuid?: string) => {
    return post<any>(`AccountInformation/SendEmailVerificationCode?deliveryMethod=${deliveryMethod}&loanGuid=${loanGuid}`, userData);
}

export const readVerificationData = (userKey: string) => {
    return get<any>(`AccountInformation/ReadVerificationData?userKey=${userKey}`);
}

export const verifyEmailVerificationCode = (emailAddress: string) => {
    return get<any>(`AccountInformation/VerifyEmailVerificationCode?emailAddress=${emailAddress}`);
}

export const accountSignUp = (signUp: any) => {
    return post<any>(`AccountInformation/SignUp`, signUp);
}

export const passwordResetQuestions = () => {
    return get<any>("AccountInformation/PasswordResetQuestions");
}

export const checkIsEncompassDown = () => {
    return get<any>("AccountInformation/CheckIsEncompassDown");
}


export const duplicateEmail = (emailAddress: string) => {
    return get<any>(`AccountInformation/VerifySharedEmail?emailAddress=${emailAddress}`);
}

export const validateSocialSecurityNumber = (ssn: string, email: string) => {
    return get<any>(`AccountInformation/ValidateSocialSecurityNumber?ssn=${ssn}&emailAddress=${email}`);
}

export const signOut = (borrowerType: string, loanGuid: string,interimLoanStagingId:number) => {
    return get<any>(`AccountInformation/SignOut?borrowerType=${borrowerType}&loanGuid=${loanGuid}&interimLoanStagingId=${interimLoanStagingId}`);
};

export const duplicateEmailCheck = (emailAddress: string) => {
    return get<any>(`AccountInformation/duplicateEmailCheck?emailAddress=${emailAddress}`);
}

export const updatePosIdentifier = (loanGuid: string) => {
    return post<any>("AccountInformation/updatePosIdentifier", loanGuid);
};

export const loanOfficerIdentifier = (loanGuid: string) => {
    return get<any>(`AccountInformation/LoanOfficerIdentifier?loanGuid=${loanGuid}`);
};

export const updateInterimDataFromEncompass = (loanGuid: string) => {
    return post<any>(`InterimLoanStaging/UpdateInterimDataFromEncompass?loanGuid=${loanGuid}`, null);
};


export const readIsLoanFromEncompass = (loanGuid: string) => {
    return get<any>(`AccountInformation/ReadIsLoanFromEncompass?loanGuid=${loanGuid}`);
};


export const UpdateUserOktaAccount = (emailAddress: string, firstName: string, lastName: string) => {
    return get<any>(`AccountInformation/UpdateUserOktaAccount?emailAddress=${emailAddress}&firstName=${firstName}&lastName=${lastName}`);
};
