import InfographicSideLayout from "components/common/Layout/InfographicSideLayout";
import pendingInfographic from "../../assets/infographics/creditReport/pending.svg";
import styles from "./CreditReport.module.scss";
import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { useNavigation } from "hooks/useNavigation";
import { useHistory } from "react-router";
import { useInitialMount } from "hooks/useInitialMount";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const CreditReportPending = (props: { isCoBorrowerPresent: boolean }) => {
    const showSpinner = useSelector(
        (state: ApplicationState) => state.creditReport?.showSpinner
    );

    const history = useHistory();
    const isInitialMount = useInitialMount();

    const navigation = useNavigation(() => {
        navigation.ShowContinue(() => onContinue(), false);
    });

    const onContinue = async () => {
        history.push({ pathname: "/employment-information", state: { args: "borrower" } });
    };

    const onBack = async () => {
        history.push({ pathname: "/create-account", state: { args: props.isCoBorrowerPresent ? "coborrower" : "borrower" } });
    };
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    React.useEffect(() => {
        navigation.ShowContinue(() => onContinue(), false);
        navigation.SetBackOnClick(() => onBack());
    }, [isInitialMount]);

    return (
        <InfographicSideLayout infographic={pendingInfographic}>
            <div className={styles.pendingContainer}>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Thank you for providing authorization')}>Thank you for providing authorization</h1>
                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please wait while we verify your credit information')}>Please wait while we verify your credit information.</h2>
                <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('This could take a couple of minutes')}>This could take a couple of minutes.</span>
                <div>{showSpinner && <div className="screenLoader"><span className="loader medium"></span></div>}</div>
            </div>
        </InfographicSideLayout>
    );
};

export default CreditReportPending;
