import React, { useState, useEffect } from "react";
import ChoiceQuestion from "../common/ChoiceQuestion";
import lowerMonthlyPaymentGraphic from "assets/infographics/accountInformation/lower-monthly-payment.svg";
import payOffMortgageFasterGraphic from "assets/infographics/accountInformation/pay-mortgage.svg";
import takeCashOutOfMyHomeGraphic from "assets/infographics/accountInformation/cash-out.svg";
import { Choice } from "../../interfaces/Question";
import styles from "./AccountInformation.module.scss";

const Priority = (props: {
    updatePriorityChoice: (value: string) => any, data: any, priorityChoice: any
}) => {
    const [priorityChoices, setPriorityChoices] = useState([]);
    useEffect(() => {
        if (props.data.length > 0) {
            setPriorityChoices(props.data);
        }
    }, [props.data]);

    const loadSvg = (name: string) => {

        switch (name) {
            case 'lower-monthly-payment':
                return lowerMonthlyPaymentGraphic
            case 'pay-mortgage':
                return payOffMortgageFasterGraphic
            case 'cash-out':
                return takeCashOutOfMyHomeGraphic
        }
    };
    const choices: Choice[] = [];
    priorityChoices.map((res: any) => {
        let choice: Choice = {
            text: res.name,
            icon: loadSvg(res.icon),
            onClick: () => props.updatePriorityChoice(res.name),
            toolTipText: res.description,
            className: props.priorityChoice === res.name ? styles.selected : ""
        };
        choices.push(choice);
    })

    return (
        <ChoiceQuestion
            disabled={false}
            intro="Priority"
            question="What do you want to accomplish with this Refinance?"
            choices={choices}
        />
    );
}

export default Priority;
