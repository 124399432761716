import * as React from "react";
import { Button, Popover, PopoverBody } from "reactstrap";
import styles from "./assetInformation.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
const videos = [
    { id: 1, url: 'https://vimeo.com/535159179', name: "Digital Verification of Assets Basics" },
    { id: 2, url: 'https://vimeo.com/535162480', name: "Best Practices" },
    { id: 3, url: 'https://vimeo.com/535161067', name: "Selling Strategies and Overcoming Objections" },

];
const TrainingVideos = () => {

    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (<>

        <Button id={"Popover"} className={`common-button`} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to LEARN MORE ABOUT FINICITY")}>LEARN MORE ABOUT FINICITY</Button>
        <Popover
            placement="bottom"
            isOpen={popoverOpen}
            target={"Popover"}
            toggle={toggle}
            className={styles.popover}
        >
            <PopoverBody className={styles.popoverbody}>
                <i className={`fa fa-close ${styles.closButton}`} onClick={toggle}></i>
                <p className={styles.list} onClick={() => window.open(videos[0].url)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Digital Verification of Assets Basics')}>Digital Verification of Assets Basics</p>
                <p className={styles.list} onClick={() => window.open(videos[1].url)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Best Practices')}>Best Practices</p>
                <p className={styles.list} onClick={() => window.open(videos[2].url)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Selling Strategies and Overcoming Objections')}>Selling Strategies and Overcoming Objections</p>
            </PopoverBody>
        </Popover>

    </>);
}

export default TrainingVideos;