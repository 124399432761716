import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as AssetInformationStore from "../store/assetInformationStore";
import { KnownAction } from '../store/assetInformationStore';
import * as assetInformationService from "../services/assetInformationService";

const receive = (data: AssetInformationStore.BorrowerAsset, borrowerType: AssetInformationStore.BorrowerType): KnownAction => {
    return { type: ActionTypes.ReadSystematicAsset, data, borrowerType };
};

export const assetInformationActionCreators = {
    
    updateBorrowerAssetProperty: (value: string, propertyName: string, borrowerType: AssetInformationStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UPDATE_BORROWER_ASSET_PROPERTY, value: value, propertyName: propertyName, borrowerType: borrowerType });
    },

    updateCoBorrowerAssetStatus: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateCoBorrowerAssetStatus, value: value});
    },

    SubmitBorrowerAssetInformation: (borrowerType: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SUBMIT_BORROWER_ASSET_INFORMATION, borrowerType: borrowerType });
    },

    UpsertAsyncsystematicInformation: (borrowerData: AssetInformationStore.BorrowerAsset): AppThunkAction<KnownAction> => (dispatch) => {
        assetInformationService.UpsertAsyncsystematicInformation(borrowerData)
            .then((response) => {
                if (!response.ok) {
                    console.error(response);
                }
                return response.ok;
            });
    },

    getSytematicAssetInformation: (loanGuid: string, borrowerType: string): AppThunkAction<AssetInformationStore.KnownAction> => (dispatch) => {
        assetInformationService.getsystematicAssetInformation(loanGuid, borrowerType)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + loanGuid + " .");
                }
                return response.parsedBody as AssetInformationStore.BorrowerAsset;
            })
            .then((data: any) => {
                dispatch(receive(data, data.borrowerType));
            })
            .catch((error: Error) => {
            });
    },
}