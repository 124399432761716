import { EmploymentScreensNew, EmploymentInformationQuestions, EmploymentInformationFields, ScreenDetails, } from "./EmploymentInformationFields";
import AreYouAVeteran from "assets/infographics/employmentInformation/AreYouAVeteran.svg";
import ActiveMilitaryDutySvg from "assets/infographics/employmentInformation/AreYouActiveDutyMilitary.svg";
import CurrentlyEmployedSvg from "assets/infographics/employmentInformation/AreYouCurrentlyEmployed.svg";
import SelfEmployedSvg from "assets/infographics/employmentInformation/AreYouSelfEmployed.svg";
import OwnerShipShareSvg from "assets/infographics/employmentInformation/OwnMoreThan25.svg";
import MonthlyIncomeSvg from "assets/infographics/employmentInformation/MonthlyLossOrIncome.svg";
import EmployerDetailSvg from "assets/infographics/employmentInformation/EmployerInformation.svg";
import MoreInformationRequiredSvg from "assets/infographics/employmentInformation/MoreInformationRequiered.svg";

const Veteran: ScreenDetails = {
    infographics: AreYouAVeteran,
    header: EmploymentInformationQuestions.Veteran,
    fieldName: [EmploymentInformationFields.Veteran, EmploymentInformationFields.SelfDeclaredMilitaryServiceIndicator],
    nextScreen: EmploymentScreensNew.ActiveMilitaryDuty,
    currentScreen: EmploymentScreensNew.Veteran,
    prevScreen: EmploymentScreensNew.Landing,
};
const ActiveMilitaryDuty: ScreenDetails = {
    header: EmploymentInformationQuestions.ActiveMilitaryDuty,
    infographics: ActiveMilitaryDutySvg,
    fieldName: [EmploymentInformationFields.ActiveMilitaryDuty, EmploymentInformationFields.SelfDeclaredMilitaryServiceIndicator],
    nextScreen: EmploymentScreensNew.CurrentlyEmployed,
    prevScreen: EmploymentScreensNew.Veteran,
    currentScreen: EmploymentScreensNew.ActiveMilitaryDuty,
};
const CurrentlyEmployed: ScreenDetails = {
    header: EmploymentInformationQuestions.CurrentEmployment,
    infographics: CurrentlyEmployedSvg,
    fieldName: [EmploymentInformationFields.CurrentEmploymentDoesNotApply],
    nextScreen: EmploymentScreensNew.SelfEmployed,
    prevScreen: EmploymentScreensNew.ActiveMilitaryDuty,
    skipScreen: EmploymentScreensNew.Finished,
    currentScreen: EmploymentScreensNew.CurrentlyEmployed,
}
const SelfEmployed: ScreenDetails = {
    header: EmploymentInformationQuestions.SelfEmployed,
    infographics: SelfEmployedSvg,
    fieldName: [
        EmploymentInformationFields.SelfEmployedIndicator,
        EmploymentInformationFields.CurrentEmploymentIndicator,
        EmploymentInformationFields.Owner
    ],
    nextScreen: EmploymentScreensNew.OwnerShipShare,
    skipScreen: EmploymentScreensNew.EmployerDetail,
    prevScreen: EmploymentScreensNew.CurrentlyEmployed,
    currentScreen: EmploymentScreensNew.SelfEmployed,
};
const OwnerShipShare: ScreenDetails = {
    header: EmploymentInformationQuestions.OwnershipInterestType,
    infographics: OwnerShipShareSvg,
    fieldName: [EmploymentInformationFields.OwnershipInterestType],
    nextScreen: EmploymentScreensNew.MonthlyIncome,
    prevScreen: EmploymentScreensNew.SelfEmployed,
    currentScreen: EmploymentScreensNew.OwnerShipShare,
};
const MonthlyIncome: ScreenDetails = {
    currentScreen: EmploymentScreensNew.MonthlyIncome,
    header: EmploymentInformationQuestions.MonthlyIncomeAmount,
    infographics: MonthlyIncomeSvg,
    fieldName: [EmploymentInformationFields.EmploymentMonthlyIncomeAmount],
    nextScreen: EmploymentScreensNew.Finished,
    prevScreen: EmploymentScreensNew.OwnerShipShare,
};
const EmployerDetail: ScreenDetails = {
    header: "Employer Details",
    infographics: EmployerDetailSvg,
    fieldName: [],
    nextScreen: EmploymentScreensNew.IncomeDetail,
    prevScreen: EmploymentScreensNew.SelfEmployed,
    currentScreen: EmploymentScreensNew.EmployerDetail
};
const IncomeDetail: ScreenDetails = {
    header: "Income Details",
    infographics: EmployerDetailSvg,
    fieldName: [EmploymentInformationFields.IncomeDetail],
    nextScreen: EmploymentScreensNew.Finished,
    prevScreen: EmploymentScreensNew.EmployerDetail,
    currentScreen: EmploymentScreensNew.IncomeDetail
};
const AdditionalEmployment: ScreenDetails = {
    header: EmploymentInformationQuestions.AdditionalEmployment,
    infographics: CurrentlyEmployedSvg,
    fieldName: [EmploymentInformationFields.PreviousEmploymentDoesNotApply],
    nextScreen: EmploymentScreensNew.AdditonalSelfEmployment,
    skipScreen: EmploymentScreensNew.Finished,
    currentScreen: EmploymentScreensNew.AdditionalEmployment,
    prevScreen: EmploymentScreensNew.PreviousAdditionalInformationLanding,
};
const AdditonalSelfEmployment: ScreenDetails = {
    header: EmploymentInformationQuestions.AdditionalSelfEmployment,
    infographics: SelfEmployedSvg,
    fieldName: [
        EmploymentInformationFields.SelfEmployedIndicator,
        EmploymentInformationFields.Owner
    ],
    nextScreen: EmploymentScreensNew.AdditionalGrossMonthlyIncome,
    prevScreen: EmploymentScreensNew.AdditionalEmployment,
    currentScreen: EmploymentScreensNew.AdditonalSelfEmployment,
};
const AdditionalGrossMonthlyIncome: ScreenDetails = {
    header: EmploymentInformationQuestions.MonthlyIncomeAmount,
    infographics: MonthlyIncomeSvg,
    fieldName: [EmploymentInformationFields.EmploymentMonthlyIncomeAmount],
    nextScreen: EmploymentScreensNew.PastEmployerDetail,
    prevScreen: EmploymentScreensNew.AdditonalSelfEmployment,
    currentScreen: EmploymentScreensNew.AdditionalGrossMonthlyIncome,
};
const PastEmployerDetail: ScreenDetails = {
    header: "Employer Details",
    infographics: EmployerDetailSvg,
    fieldName: [],
    nextScreen: EmploymentScreensNew.PastIncomeDetail,
    prevScreen: EmploymentScreensNew.AdditionalGrossMonthlyIncome,
    currentScreen: EmploymentScreensNew.PastEmployerDetail
};
const PastIncomeDetail: ScreenDetails = {
    header: "Income Details",
    fieldName: [EmploymentInformationFields.IncomeDetail],
    nextScreen: EmploymentScreensNew.Finished,
    prevScreen: EmploymentScreensNew.PastEmployerDetail,
    currentScreen: EmploymentScreensNew.PastIncomeDetail
};
const AdditonalSelfEmployment1: ScreenDetails = {
    header: EmploymentInformationQuestions.AdditionalSelfEmployment,
    infographics: SelfEmployedSvg,
    fieldName: [
        EmploymentInformationFields.SelfEmployedIndicator,
        EmploymentInformationFields.CurrentEmploymentIndicator,
        EmploymentInformationFields.Owner
    ],
    currentScreen: EmploymentScreensNew.AdditonalSelfEmployment1,
    nextScreen: EmploymentScreensNew.PreviousGrossMonthlyIncome1,
    skipScreen: EmploymentScreensNew.Finished,
    prevScreen: EmploymentScreensNew.PastIncomeDetail,
};
const PreviousGrossMonthlyIncome1: ScreenDetails = {
    currentScreen: EmploymentScreensNew.PreviousGrossMonthlyIncome1,
    header: EmploymentInformationQuestions.MonthlyIncomeAmount,
    infographics: MonthlyIncomeSvg,
    fieldName: [EmploymentInformationFields.EmploymentMonthlyIncomeAmount],
    nextScreen: EmploymentScreensNew.PastEmployerDetail1,
    skipScreen: EmploymentScreensNew.SelfEmployed,
    prevScreen: EmploymentScreensNew.AdditonalSelfEmployment1,
};
const PastEmployerDetail1: ScreenDetails = {
    header: "Employer Details",
    infographics: EmployerDetailSvg,
    fieldName: [],
    nextScreen: EmploymentScreensNew.PastIncomeDetail1,
    prevScreen: EmploymentScreensNew.PreviousGrossMonthlyIncome1,
    currentScreen: EmploymentScreensNew.PastEmployerDetail1
};

const PastIncomeDetail1: ScreenDetails = {
    header: "Income Details",
    fieldName: [EmploymentInformationFields.IncomeDetail],
    nextScreen: EmploymentScreensNew.Finished,
    prevScreen: EmploymentScreensNew.PastEmployerDetail1,
    currentScreen: EmploymentScreensNew.PastIncomeDetail1
};

const Finished: ScreenDetails = {
    header: EmploymentInformationQuestions.Finished,
    infographics: MoreInformationRequiredSvg,
    fieldName: [],
};

const Landing: ScreenDetails = {
    fieldName: [],
    nextScreen: EmploymentScreensNew.Veteran,
    currentScreen: EmploymentScreensNew.Landing
};

const AdditionalInformationLanding: ScreenDetails = {
    fieldName: [],
    nextScreen: EmploymentScreensNew.AdditionalEmployment,
    prevScreen: EmploymentScreensNew.IncomeDetail,
    currentScreen: EmploymentScreensNew.PreviousAdditionalInformationLanding
};

export const GetScreenDetail = (screen: EmploymentScreensNew) => {
    switch (screen) {
        case EmploymentScreensNew.PreviousAdditionalInformationLanding: return AdditionalInformationLanding;

        case EmploymentScreensNew.Landing: return Landing;

        case EmploymentScreensNew.Veteran: return Veteran;

        case EmploymentScreensNew.ActiveMilitaryDuty: return ActiveMilitaryDuty;

        case EmploymentScreensNew.CurrentlyEmployed: return CurrentlyEmployed;

        case EmploymentScreensNew.SelfEmployed: return SelfEmployed;

        case EmploymentScreensNew.OwnerShipShare: return OwnerShipShare;

        case EmploymentScreensNew.MonthlyIncome: return MonthlyIncome;

        case EmploymentScreensNew.EmployerDetail: return EmployerDetail;

        case EmploymentScreensNew.IncomeDetail: return IncomeDetail;

        case EmploymentScreensNew.AdditionalEmployment: return AdditionalEmployment;

        case EmploymentScreensNew.AdditonalSelfEmployment: return AdditonalSelfEmployment;

        case EmploymentScreensNew.AdditionalGrossMonthlyIncome: return AdditionalGrossMonthlyIncome;

        case EmploymentScreensNew.PastEmployerDetail: return PastEmployerDetail;

        case EmploymentScreensNew.PastIncomeDetail: return PastIncomeDetail;

        case EmploymentScreensNew.AdditonalSelfEmployment1: return AdditonalSelfEmployment1;

        case EmploymentScreensNew.PreviousGrossMonthlyIncome1: return PreviousGrossMonthlyIncome1;

        case EmploymentScreensNew.PastEmployerDetail1: return PastEmployerDetail1;

        case EmploymentScreensNew.PastIncomeDetail1: return PastIncomeDetail1;

        case EmploymentScreensNew.Finished: return Finished;
    }

}