import { AppThunkAction } from '../store/index';
import * as ActionTypes from '../common/ActionTypes';
import { BorrowerLiabilityFields } from '../components/borrowerLiability/BorrowerLiabilityFields';
import * as borrowerLiabilityInfo from "../store/borrowerLiabilityStore";
import * as borrowerLiabilityService from "../services/borrowerLiabilityService";
import _uniqueId from 'lodash/uniqueId';
import { isNullOrWhiteSpace } from '../common/helper/formatHelper';

const addLiabilityToState = (data: borrowerLiabilityInfo.BorrowerLiabilityDetail): borrowerLiabilityInfo.KnownAction => {
    return { type: ActionTypes.CreateBorrowerLiability, data };
};

const removeLiability = (id: string): borrowerLiabilityInfo.KnownAction => {
    return { type: ActionTypes.DeleteBorrowerLiability, id };
};

const updateBorrowerLiabilityField = (value: string | boolean, field: BorrowerLiabilityFields, liabilityId: string, isNew: boolean): borrowerLiabilityInfo.KnownAction => {
    return { type: ActionTypes.UpdateBorrowerLiabilityInformation, field, value, liabilityId, isNew };
};

const setValidation = (validationError: any, field: string): borrowerLiabilityInfo.KnownAction => {
    return { type: ActionTypes.SetBorrowerLiabilityValidation, validationError, field };
}


const receive = (data: borrowerLiabilityInfo.BorrowerLiabilityDetail[]): borrowerLiabilityInfo.KnownAction => {
    return { type: ActionTypes.ReceiveBorrowerLiability, data };
}

export const borrowerLiabilityActionCreators = {
    getBorrowerLiabilityInformation: (interimLoanStagingId: number): AppThunkAction<borrowerLiabilityInfo.KnownAction> => (dispatch) => {
        borrowerLiabilityService.getAll(interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem while fetching liabilities " + interimLoanStagingId + " .");
                }
                return response.parsedBody as borrowerLiabilityInfo.BorrowerLiabilityDetail[];
            })
            .then((data: any) => {
                if (data.length > 0) {
                    dispatch(receive(data));
                } else {
                    const emptyliabilityDetail: borrowerLiabilityInfo.BorrowerLiabilityDetail = {
                        id: _uniqueId('uniqid-'),
                        accountIdentifier: "",
                        holderName: "",
                        liabilityType: "",
                        monthlyPaymentAmount: "",
                        owner: "",
                        unpaidBalanceAmount: "",
                        isNew: true,
                    };
                    data = [];
                    data.push(emptyliabilityDetail);
                    dispatch(receive(data));
                }
            })
            .catch((error: Error) => {
                throw new Error("There was a problem while fetching liabilities " + error.message + " .");
            });
    },

    updateCache: (interimLoanStagingId: number, detail: borrowerLiabilityInfo.BorrowerLiabilityDetail): AppThunkAction<borrowerLiabilityInfo.KnownAction> => (dispatch) => {
        if (detail != null) {
            borrowerLiabilityService.create(interimLoanStagingId, detail)
                .then((response: any) => {
                    if (!response.ok) {
                        console.log(`There was a problem updating Borrower Liability for loan #${interimLoanStagingId}.`);
                    }
                    return response.parsedBody as string;
                });
        }
    },

    
    addLiabilityToState: (data: borrowerLiabilityInfo.BorrowerLiabilityDetail): AppThunkAction<borrowerLiabilityInfo.KnownAction> => (dispatch) => {
        dispatch(addLiabilityToState(data));
    },

    removeLiabilityFromState: (liabilityId: string): AppThunkAction<borrowerLiabilityInfo.KnownAction> => (dispatch) => { //to remove from state only
        dispatch(removeLiability(liabilityId));
    },

    handleLiabilityFieldChange: (value: any, type: any, liabilityId: string, isNew: boolean): AppThunkAction<borrowerLiabilityInfo.KnownAction> => (dispatch) => {
        dispatch(updateBorrowerLiabilityField(value, type, liabilityId, isNew));
    },

    setValidation: (error: any, field: string): AppThunkAction<borrowerLiabilityInfo.KnownAction> => (dispatch) => {
        dispatch(setValidation(error, field));
    },
}