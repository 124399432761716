import * as React from "react";

const LoadScripts = (props: { html: any }) => {
    React.useEffect(() => {
        let js = document.getElementsByTagName('script')[0];
        let sfjs = document.getElementsByTagName('script')[0];
        js = document.createElement('script');
        js.id = 'surefire-interactives';
        js.src = 'https://sf3.tomnx.com/interactives/embed-interactives.js';
        if (sfjs && sfjs.parentNode) {
            sfjs.parentNode.insertBefore(js, sfjs);
        }
    }, []);

    return (<>
        {props.html}
    </>)
}
export default LoadScripts;
