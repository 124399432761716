import { Modal, ModalBody } from "reactstrap";
import styles from "./eConsentDocument.module.scss";
import React, { useState } from "react";
import Infographic from "components/common/Infographic";
import largeIcon from "assets/infographics/eConsent/girlWithPurpleDressAndSpiralsAroundHer.svg";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface ConsentDialogProps {
    hasClosedDialog: (value: boolean) => any;
}

const ConsentDialog = (props: ConsentDialogProps) => {
    const [modal, setModal] = useState(true);
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }

    const closeModal = (doNotWantToConsent: boolean) => {
        setModal(false);
        props.hasClosedDialog(doNotWantToConsent);
    };

    return (
        <Modal
            isOpen={modal}
            toggle={() => closeModal(false)}
            centered={true}
            className={styles.eConsentModal}
            contentClassName={styles.eConsentModalContent}
            returnFocusAfterClose={false}
        >
            <ModalBody className={`${styles.modalBody}`}>
                <div className="align-center">
                    <div className={styles.modalIcon}>
                        <Infographic src={largeIcon} />
                    </div>

                    <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Are you sure')}>Are you sure?</h4>
                    <h5 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('If you do not provide eConsent, you will not be able to continue applying for a Loan Application.Are you sure you do not want to eConsent')}>
                        If you do not provide eConsent, you will not be able to continue applying for
                        a Loan Application. Are you sure you do not want to eConsent?
          </h5>
                    <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to yes')} className={`common-button small ${styles.Button}`} onClick={() => closeModal(true)}>
                        Yes
          </button>
                    <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to no')} className={`common-button small ${styles.NoButton} ${styles.Button}`} onClick={() => closeModal(false)}>
                        No
          </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ConsentDialog;
