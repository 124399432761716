import * as React from "react";
import { EnumLiability } from "../common/Enums/EnumLiability";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import { BorrowerLiabilityDetail, BorrowerLiabilityState } from "../../store/borrowerLiabilityStore";
import { BorrowerLiabilityFields } from "../borrowerLiability/BorrowerLiabilityFields";
import { borrowerLiabilityActionCreators } from "../../actions/borrowerLiabilityActions";
import { connect } from "react-redux";
import coBorrowerInfographic from "../../assets/infographics/borrowerLiability/CoBorrower.svg";
import borrowerInfographic from "../../assets/infographics/borrowerLiability/Borrower.svg";
import bothInfographic from "../../assets/infographics/borrowerLiability/Both.svg";
import Infographic from "../common/Infographic";
import styles from "./BorrowerLiability.module.scss";
import TextInput from "../common/Input/TextInput";
import SelectDropDown from "../common/Input/SelectDropDown";
import { ValidationMessage, Rule } from "../constants/validation";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type BorrowerLiabilityEditProps = {
    liabilityDetail: BorrowerLiabilityDetail,
    validation?: undefined;
    isError: any;
    setValid: (e: any) => {};
    isCoBorrowerPresent: any;
    disabled: boolean;
}
    & typeof borrowerLiabilityActionCreators
    & typeof TaskMenuCreators
    & BorrowerLiabilityState;

const BorrowerLiabilityItem = (props: BorrowerLiabilityEditProps) => {
    const [isChildSupport, setChildSupport] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState("");
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        if (props.liabilityDetail?.liabilityType === "ChildSupport") {
            setChildSupport(true);
        }

        if (!props.isCoBorrowerPresent) {
            props.handleLiabilityFieldChange(BorrowerTypeEnum.Borrower, BorrowerLiabilityFields.Owner, props.liabilityDetail?.id, props.liabilityDetail.isNew);
        }

        setActiveIndex(props.liabilityDetail?.owner);
        checkValid(props.liabilityDetail);
    })

    const handleInputChange = (value: any, field: BorrowerLiabilityFields) => {
        var liabilityDetail = { ...props.liabilityDetail };
        props.handleLiabilityFieldChange(value, field, liabilityDetail.id, props.liabilityDetail.isNew);

        if (field === BorrowerLiabilityFields.LiabilityType && value === "ChildSupport") {
            props.handleLiabilityFieldChange("", BorrowerLiabilityFields.HolderName, liabilityDetail.id, props.liabilityDetail.isNew);
            props.handleLiabilityFieldChange("", BorrowerLiabilityFields.AccountIdentifier, liabilityDetail.id, props.liabilityDetail.isNew);
        }

        checkValid(props.liabilityDetail);
    }

    const returnInfographic = (param: any) => {
        switch (param) {
            case BorrowerTypeEnum.Borrower:
                return borrowerInfographic;
            case BorrowerTypeEnum.CoBorrower:
                return coBorrowerInfographic;
            case BorrowerTypeEnum.Both:
                return bothInfographic;
            default:
                return '';
        }
    }

    const returnBorrowerTypeEnum = (param: any) => {
        switch (param) {
            case BorrowerTypeEnum.CoBorrower:
                return 'Co-Borrower';
            default:
                return param;
        }
    }

    const handleChangeActive = (newActiveIndex: any) => {
        setActiveIndex(newActiveIndex);
    }

    const checkValid = (liabilityDetail: BorrowerLiabilityDetail) => {
        let isValid =
            !isNullOrWhiteSpace(liabilityDetail.liabilityType) &&
            !isNullOrWhiteSpace(liabilityDetail.monthlyPaymentAmount) &&
            !isNullOrWhiteSpace(liabilityDetail.unpaidBalanceAmount);

        if (props.isCoBorrowerPresent) {
            if (isNullOrWhiteSpace(liabilityDetail.owner)) {
                isValid = false;
            }
        }

        if (!isChildSupport) {
            if (isNullOrWhiteSpace(liabilityDetail.holderName)) {
                isValid = false;
            }
        }

        props.setValid(isValid);
    }

    return (
        <div className="column">
            <div className={styles.newLiabilitycard}>
                <div className="input-group ">
                    <div className={styles.newLiabilityRow}>
                        <div className={styles.rowColumn}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Account Type")}>Account Type  <pre> * </pre> </label>
                            <SelectDropDown
                                disabled={props.disabled}
                                defaultValue={props.liabilityDetail.liabilityType || ""}
                                name="Account Type"
                                rule={{ required: ValidationMessage.Required }}
                                handleValidation={props.setValidation}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    handleInputChange(e.target.value, BorrowerLiabilityFields.LiabilityType);
                                    if (e.target.value === "ChildSupport") {
                                        setChildSupport(!isChildSupport);
                                    } else {
                                        setChildSupport(false);
                                    }
                                }}
                                noArrow={false}
                                invalidclass={styles.invalidInput}
                                dropdownclass={styles.select}
                            >
                                <option value="" disabled> --Select-- </option>
                                {Object.entries(EnumLiability).map(([key, value]) => (
                                    <option key={value} value={key}>
                                        {value}
                                    </option>
                                )
                                )}
                            </SelectDropDown>
                        </div>

                        {!isChildSupport && <div className={styles.rowColumn}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Financial Institution")}>Financial Institution <pre> * </pre></label>
                            <TextInput
                                disabled={props.disabled}
                                id={BorrowerLiabilityFields.HolderName}
                                name="Financial Institution"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange(e.target.value, BorrowerLiabilityFields.HolderName) }}
                                defaultValue={props.liabilityDetail.holderName || ""}
                                handleValidation={props.setValidation}
                                invalidclass={styles.invalidInput}
                            />
                        </div>}

                        <div className={styles.rowColumn}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Balance")}>Balance  <pre> * </pre> </label>
                            <NumberFormatInput
                                disabled={props.disabled}
                                name="Balance"
                                className={styles.marketValueInput}
                                value={props.liabilityDetail.unpaidBalanceAmount || ""}
                                id={BorrowerLiabilityFields.UnpaidBalanceAmount}
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    handleInputChange(values.value.replace(/^0+/, ''), BorrowerLiabilityFields.UnpaidBalanceAmount);
                                }}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                            />
                        </div>

                        {!isChildSupport && <div className={styles.rowColumn}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Account number (Optional)")}>Account number<span className={styles.optionalMsg}>(Optional)</span></label>
                            <TextInput
                                disabled={props.disabled}
                                id={BorrowerLiabilityFields.AccountIdentifier}
                                name="Account number"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange(e.target.value, BorrowerLiabilityFields.AccountIdentifier) }}
                                defaultValue={props.liabilityDetail.accountIdentifier || ""}
                                handleValidation={props.setValidation}
                                invalidclass={styles.invalidInput}
                            />
                        </div>}

                        <div className={styles.rowColumn}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Monthly Payment")}>Monthly Payment  <pre> * </pre> </label>
                            <NumberFormatInput
                                disabled={props.disabled}
                                id={BorrowerLiabilityFields.MonthlyPaymentAmount}
                                name="Monthly Payment"
                                className={styles.marketValueInput}
                                value={props.liabilityDetail.monthlyPaymentAmount || ""}
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    handleInputChange(values.value.replace(/^0+/, ''), BorrowerLiabilityFields.MonthlyPaymentAmount);
                                }}
                                thousandSeparator={true}
                                prefix={"$"}
                                decimalScale={2}
                            />
                        </div>
                    </div>

                    {props.isCoBorrowerPresent &&
                        <div>
                        <h4 className={styles.textCenter} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Who is Responsible for this Monthly Payment")}> Who is Responsible for this Monthly Payment? </h4>
                            <div className={styles.newLiabilityRow}>
                                {Object.keys(BorrowerTypeEnum).map(key => (
                                    <div key={key}
                                        className={`${styles.rowColumn} ${styles.verticalAlignMiddle} ${styles.cursorPointer}` + ((key === activeIndex) ? ` ${styles.boxActive}` : '')}>
                                        <div className={styles.box} onClick={() => {
                                            handleInputChange(key, BorrowerLiabilityFields.Owner);
                                            handleChangeActive(key);
                                        }}>
                                            <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(returnBorrowerTypeEnum(key))}> {returnBorrowerTypeEnum(key)}</p>
                                            <Infographic src={returnInfographic(key)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    handleLiabilityFieldChange: (value: any, field: string, liabilityId: string, isNew: boolean) => {
        dispatch(borrowerLiabilityActionCreators.handleLiabilityFieldChange(value, field, liabilityId, isNew));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    setValidation: (error: any, field: string) => {
        dispatch(borrowerLiabilityActionCreators.setValidation(error, field));
    }
});

export default connect(null, mapDispatchToProps)(BorrowerLiabilityItem as any) as any;