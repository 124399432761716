import { get, post } from "../common/dataFetch";
import { oktaConfigAsync } from "../authConfig";

export const getDashboardDetails = (loanGuid: string, borrowerType: string) => {
    return get<any>(`Dashboard/ReadByLoanGuidAsync?loanGuid=${loanGuid}&borrowerType=${borrowerType}`);
};

export const getUserNotificationMessages = (loanGuid: string) => {
    return get<any>(`Dashboard/ReadUserNotificationMessages?loanGuid=${loanGuid}`);
};

export const isUserPasswordRecoveryAvailable = (emailAddress: string) => {
    return get<any>(`AccountInformation/passwordRecoveryAvailable?emailAddress=${emailAddress}`);
}