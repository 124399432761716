import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./Buttons.module.scss";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type NavigationButtonProps = {
  onClick: () => void;
  text: string;
  disabled: boolean;
  arrowDirection?: ArrowDirection;
  className?: string;
};

export enum ArrowDirection {
  Left = "Left",
  Right = "Right",
}

const NavigationButton = (props: NavigationButtonProps) => {
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
  return (
    <button
      className={`${styles.navigationButton} ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.arrowDirection && props.arrowDirection === ArrowDirection.Left && (
        <FontAwesomeIcon className={styles.icon} icon={faArrowCircleLeft} />
      )}
          <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to ' + props.text)}>{props.text}</span>
      {props.arrowDirection && props.arrowDirection === ArrowDirection.Right && (
        <FontAwesomeIcon className={styles.icon} icon={faArrowCircleRight} />
      )}
    </button>
  );
};

export default NavigationButton;
