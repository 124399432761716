import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { useLocation, useHistory } from 'react-router-dom';
import Loading from "../common/Loader";
import queryString from "query-string";
import * as userAcountActions from "../../actions/userAcount";
import * as accountInformationActions from "../../actions/accountInformationActions";
import SessionInfo from "../../SessionDataTracker";

type LoanOfficerViewProps = {
        loanGuid: string;
        userEmail: string;
        updateUserEmail: (value: string) => any;
        updateUserFirstName: (value: string) => any;
        updateUserLastName: (value: string) => any;
        setLoanGuid: (value: any) => any;
    }

const LoanOfficerView = (props: LoanOfficerViewProps) => {
    const history = useHistory();
    const { search } = useLocation();
    const queryStringValues = queryString.parse(search);

    React.useEffect(() => {
        const values = queryStringValues as any;
        if (values && values.id && values.ts) {
            const loanGuid = values.id as string;
            const userEmail = values.em as string;
            const ePochTime = values.ts as number;
            const userFirstName = values.fn as string;
            const userLastName = values.ln as string;
            const loEmail = values.lo as string;
            if (loanGuid && ePochTime) {
                const currentTime = new Date();
                const timeSpan = ePochTime - Math.round(currentTime.getTime()/1000.0);
                //Check for expired querystring - valid for 1 minute
                if (timeSpan <= 0 && timeSpan >= -60) {
                    props.setLoanGuid(loanGuid);
                    props.updateUserEmail(userEmail);
                    props.updateUserFirstName(userFirstName);
                    props.updateUserLastName(userLastName);
                    SessionInfo.loEmail = loEmail ?? "";

                    //Redirect to loan officer view main page
                    history.push({ pathname: "/loan-officer-view" });
                    return;
                }
            }
        }

        // Invalid or expired querystring, take user to home page
        history.push({ pathname: "/" });
    }, [queryStringValues]);

    return (
        <div className="w-100-p">
            <Loading />
        </div>
    );
}
const mapStateToProps = (state: ApplicationState): any => {
    const loanGuid = state.accountInformation?.loanGuid;
    const userEmail = state.userAccountInformation?.email;

    return {
        loanGuid,
        userEmail
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    updateUserEmail: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserEmail(value));
    },
    updateUserFirstName: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserFirstName(value));
    },
    updateUserLastName: (value?: string) => {
        dispatch(userAcountActions.userAcountActionCreators.updateUserLastName(value));
    },
    setLoanGuid: (value?: string) => {
        dispatch(accountInformationActions.accountInformationActionCreators.setLoanGuid(value));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanOfficerView as any);
