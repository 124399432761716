const SessionDataTracker = (() => {
    const leadTime = 60;   //Seconds
    let expireTime = 0;
    let countDownTime = 0;
    let oktaTokens: any = {};
    let oktaConfig: any = {};
    let loEmail = "";
    let intervalId: any = null;
    let callBackFunction: (timer: number) => void = () => {};

    const stopTimer = () => {
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
    }

    const startTimer = () => {
        stopTimer();
        intervalId = setInterval(() => {
            if (countDownTime > 0) {
                countDownTime = countDownTime - 1;
                if (callBackFunction) {
                    callBackFunction(countDownTime);
                }
            }
        }, 1000);
    }

    const setExpireTime = (nextExpireTime: number | null) => {
        if (nextExpireTime) {
            expireTime = nextExpireTime;

            let currentTime = (new Date()).getTime();
            if (expireTime.toString().length < currentTime.toString().length) {
                currentTime = Number(currentTime.toString().substr(0, expireTime.toString().length));
                countDownTime = Math.round((expireTime - currentTime));
            } else {
                countDownTime = Math.round((expireTime - currentTime) / 1000);
            }
            countDownTime = countDownTime - leadTime;
            startTimer();
        }
    };

    const setTimerCallbackFunction = ((callBack: any) => {
        callBackFunction = callBack;
    });

    const clearInfo = () => {
        stopTimer();
        expireTime = 0;
        oktaTokens = {};
        loEmail = "";
    }

    return {
        config: oktaConfig,
        tokens: oktaTokens,
        startTimer: setExpireTime,
        stopTimer: stopTimer,
        setTimerCallback: setTimerCallbackFunction,
        loEmail: loEmail,
        clear: clearInfo
    };
})();
export default SessionDataTracker;