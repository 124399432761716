import * as React from "react";
import { connect } from 'react-redux';
import { ZipDetail } from '../../store/ZipCodeLookUpStore';
import { zipCodeLookupActionCreators } from '../../actions/zipCodeLookupActions';
import { ApplicationState } from '../../store';
import { Borrower, Address } from "../../store/accountCreationStore";
import { FormGroup, Label } from "reactstrap";
import TextInput from "../common/Input/TextInput";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";
import ZipCodeSelection from "../../components/common/shared/ZipCodeSelectionBox";
import styles from "./accountCreation.module.scss";
import { setTimeout } from "timers";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type AddressFieldProps = AddressFieldModel & typeof zipCodeLookupActionCreators

interface AddressFieldModel {
    zipDetails: ZipDetail[];
    borrowerInformation: Borrower;
    addressType: any,
    borrowerType: any,
    isLoading: any,
    onChange: (value: any, name: string, borrowerType: any, addressType: any) => void;
    isLoanComplted: boolean;
    disabled: boolean;
}

const AddressFields = (props: AddressFieldProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [showCitySelectionBox, cityBoxToggle] = React.useState(false);
    const [showZipSelectionBox, zipBoxToggle] = React.useState(false);
    const [zipCode, setZipCode] = React.useState(props.borrowerInformation?.currentAddress?.addressPostalCode || "");
    const [city, setCity] = React.useState(props.borrowerInformation?.currentAddress?.addressCity || "");
    const [state, setState] = React.useState(props.borrowerInformation?.currentAddress?.addressState || "");
    const [enteredField, setFieldName] = React.useState(undefined || "");
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {

        if (props.borrowerInformation.currentAddress?.addressCity != "" && props.borrowerInformation.currentAddress?.addressState != "") {
            zipBoxToggle(true);
            onCityBlur(props.borrowerInformation.currentAddress?.addressCity);

        }

    }, [props.borrowerInformation.currentAddress?.addressCity, props.borrowerInformation.currentAddress?.addressState])

    const zipFormat = (value: string) => {
        if (value.length > 5) {
            value = [value.slice(0, 5), '-', value.slice(5)].join('');

            if (value.length >= 10) {
                return value.slice(0, 10);
            }
        }
        return value;
    };

    const onSelection = (data: ZipDetail) => {

        if (showZipSelectionBox) {
            zipBoxToggle(false);
            props.onChange(data.zipCode, "addressPostalCode", props.borrowerType, props.addressType);
            props.onChange(data.stateCode, "addressState", props.borrowerType, props.addressType);
            setZipCode(data.zipCode);
            setState(data.stateCode);
        }

        if (showCitySelectionBox) {
            cityBoxToggle(false);
            props.onChange(data.city, "addressCity", props.borrowerType, props.addressType);
            props.onChange(data.stateCode, "addressState", props.borrowerType, props.addressType);
            setCity(data.city);
            setState(data.stateCode);
        }

        setTimeout(() => { props.onChange(true, "isValid", props.borrowerType, props.addressType); }, 50);
        props.clearZipDetails();
    };

    const onZipBlur = (value: any) => {

        if (zipCode && zipCode.length > 4 && props.borrowerInformation?.currentAddress?.addressPostalCode !== zipCode) {
            cityBoxToggle(true);
            props.onChange(value, "addressPostalCode", props.borrowerType, props.addressType);
            props.getZipCodeDetail(zipCode);
            setFieldName("addressPostalCode");
        }
        if (value === "") {
            props.onChange(value, "addressPostalCode", props.borrowerType, props.addressType);
        }
    }

    const onCityBlur = (value: any) => {

        if (city.toUpperCase() !== "" && props.borrowerInformation?.currentAddress?.addressCity?.toUpperCase() !== city.toUpperCase()) {//props.borrowerInformation.city
            zipBoxToggle(true);
            let zipDetail: ZipDetail = {
                city: city,
                zipCode: props.borrowerInformation?.currentAddress?.addressPostalCode ?? "",
                stateCode: JSON.stringify(props.borrowerInformation?.currentAddress?.addressState)
            }
            props.onChange(city, "addressCity", props.borrowerType, props.addressType);

            props.getZipCodeByCityState(zipDetail);
            setFieldName("addressCity");
        }
        else if (city.toUpperCase() !== "" && props.borrowerInformation?.currentAddress?.addressCity?.toUpperCase() === city.toUpperCase()) {

            let zipDetail: ZipDetail = {
                city: city,
                zipCode: props.borrowerInformation?.currentAddress?.addressPostalCode ?? "",
                stateCode: JSON.stringify(props.borrowerInformation?.currentAddress?.addressState)
            }

            props.onChange(city, "addressCity", props.borrowerType, props.addressType);
            props.getZipCodeByCityState(zipDetail);
            setFieldName("addressCity");
        }

        if (value === "") {
            props.onChange(value, "addressCity", props.borrowerType, props.addressType);
        }
    }

    const onStateBlur = (value: any) => {

        if (state && props.borrowerInformation?.currentAddress?.addressState !== state) {
            zipBoxToggle(true);
            let zipDetail: ZipDetail = {
                city: city,
                zipCode: JSON.stringify(props.borrowerInformation?.currentAddress?.addressPostalCode),
                stateCode: state
            }
            props.onChange(state, "addressState", props.borrowerType, props.addressType);

            props.getZipCodeByCityState(zipDetail);
            setFieldName("addressState");
        }
        if (value === "") {
            props.onChange(value, "addressState", props.borrowerType, props.addressType);
        }
    }

    if (props.zipDetails && props.zipDetails?.length === 1 && enteredField !== undefined) {
        let zipDetail: ZipDetail = {
            city: props.zipDetails[0].city,
            zipCode: props.zipDetails[0].zipCode,
            stateCode: props.zipDetails[0].stateCode
        }
        onSelection(zipDetail);
    }

    else if (enteredField !== undefined && props.zipDetails?.length === 0 && props.borrowerInformation?.currentAddress?.isValid) {

        props.onChange(false, "isValid", props.borrowerType, props.addressType);
    }


    return (
        <>
            <div className={styles.inputGroupMedium}>
                <FormGroup>
                    <Label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City')}>City <span className={styles.requiredMsg}> * </span></Label>
                    <TextInput
                        disabled={ props.disabled}
                        name="addressCity"
                        className={styles.input}
                        onChange={(e: any) => { setCity(e.target.value) }}
                        value={city || ''}
                        onBlur={(e: any) => onCityBlur(e.target.value)}
                    />

                    {enteredField === "addressCity" && props.zipDetails?.length === 0
                        && <span style={{ color: "red" }}>Invalid city '{city}' for state '{state}'.</span>}
                </FormGroup>
            </div>
            <div className={styles.inputGroupSmall}>
                <FormGroup>
                    <Label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State <span className={styles.requiredMsg}> * </span> </Label>
                    <TextInput
                        disabled={props.disabled}
                        maxLength="2"
                        className={styles.input}
                        name="addressState"
                        onBlur={(e: any) => onStateBlur(e.target.value.toUpperCase())}
                        onChange={(e: any) => { setState(e.target.value.toUpperCase()) }}
                        value={state || ''}
                    />
                    {enteredField === "addressState" && props.zipDetails?.length === 0
                        && <span style={{ color: "red" }}>Invalid state '{state}' for city '{city}'.</span>}
                </FormGroup>
            </div>
            <div className={styles.inputGroupSmall}>
                <FormGroup >
                    <Label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Zip')}>Zip <span className={styles.requiredMsg}> * </span></Label>
                    <NumberFormatInput
                        disabled={props.disabled}
                        value={zipCode || ''}
                        format={zipFormat}
                        name="addressPostalCode"
                        className={styles.input}
                        onBlur={(value: any) => { onZipBlur(value.target.value); }}
                        onValueChange={(values: NumberFormatValues) => { setZipCode(values.formattedValue.trim()); }}

                    />
                    {enteredField === "addressPostalCode" && props.zipDetails?.length === 0
                        && <span className="invalid-field">Invalid Zip code.</span>}
                </FormGroup>

            </div>

            {showCitySelectionBox && zipCode && props.zipDetails?.length > 1 && enteredField === "addressPostalCode" &&
                <ZipCodeSelection
                zipDetails={props.zipDetails}
                    zipCode={zipCode}
                    isOpen={showCitySelectionBox}
                    onSelection={(data: any) => onSelection(data)}
                    onCancel={() => cityBoxToggle(false)}
                />
            }
            {
                showZipSelectionBox && city && props.zipDetails?.length > 1 &&
                (enteredField === "addressCity" || enteredField === "addressState") &&
                <ZipCodeSelection
                    zipDetails={props.zipDetails}
                    cityName={city}
                    stateCode={props.borrowerInformation?.currentAddress?.addressState}
                    isOpen={showZipSelectionBox}
                    onSelection={(data: any) => onSelection(data)}
                    onCancel={() => zipBoxToggle(false)}
                />
            }
            {(props.isLoading && <div className={styles.inputGroupMedium}>
                <div className="align-center accountCreation-loader">
                    <div className="loader"></div>
                </div> </div>
            )}
        </>

    );


}

const mapStateToProps = (state: ApplicationState): any => {

    let isLoading = state.zipCodeLookUp?.isLoading
    let zipDetails;

    if (state && state.zipCodeLookUp?.data !== undefined) {
        zipDetails = state.zipCodeLookUp?.data;

    }
    return {
        zipDetails,
        isLoading
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    getZipCodeDetail: (zipCode: string) => {
        dispatch(zipCodeLookupActionCreators.getZipCodeDetail(zipCode));
    },
    getZipCodeByCityState: (zipDetail: ZipDetail) => {
        dispatch(zipCodeLookupActionCreators.getZipCodeByCityState(zipDetail));
    },
    clearZipDetails: () => {
        dispatch(zipCodeLookupActionCreators.clearZipDetails());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressFields as any) as any;