import * as React from "react";
import { useForm } from "react-hook-form";
import { withReadOnlyWrapper } from "../Input/withReadOnlyWrapper";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

interface TextInputProps {
    rule: {};
    onChange: any;
    name: string;
    handleValidation?: (error: any, field: string) => {};
    invalidclass: string;
}

const TextInputComponent = (props: TextInputProps) => {
    const { onChange, rule, handleValidation, ...rest } = props;
    const { register, errors, trigger } = useForm();
    const triggerChange = (e: any) => {
        props.onChange(e);
        trigger(props.name);
    };
    const error = errors[props.name];

    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    if (props.handleValidation) {
        if (error) {
            error.message = error.message.replace('field', props.name);
            const { ref, ...errorInfo } = error;
            props.handleValidation(errorInfo, props.name);
        } else {
            props.handleValidation(null, props.name);
        }
    }
    return (<>
        <input
            type="text"
            onBlur={triggerChange}
            onChange={triggerChange}
            ref={register(props.rule)}
            {...rest}

        />
        {error && (<span style={{ color: "red" }} className={props.invalidclass} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(error.message)}>{error.message}</span>)}
    </>);
};

const TextInput = withReadOnlyWrapper(TextInputComponent);

export default TextInput as any;