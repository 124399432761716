import { format } from "date-fns";

export const dollarFormat = (value?: string): string => {
    if (value) {
        return `$${value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
    }
    return "$0.00";
};

export const threeDecimalFormat = (value?: string): string => {
    if (value) {
        const num = parseFloat(value).toFixed(3);
        return num.toString();
    }
    return "0.000";
};

export const percentageFormat = (value?: string): string => {
    return `${threeDecimalFormat(value)}%`;
};

export const isExpired = (lockExpiration?: string): boolean => {
    if (lockExpiration) {
        const currentDate = new Date();
        const lockExpirationDate = new Date(lockExpiration);
        if (lockExpirationDate <= currentDate) {
            return true;
        }
    }
    return false;
};

export const wordSpace = (value?: string): string => {
    if (value) {
        return value.replace(/([A-Z]+)/g, " $1").trim();
    }
    return "";
};

export const inputDateFormat = (value: string | Date | null): string => {
    if (value == null) {
        return "";
    }

    if (value instanceof Date) {
        if (isNaN(value.getTime())) {
            return "";
        }
        return value.toISOString().substring(0, 10)
    }

    if (Date.parse(value)) {
        return new Date(value).toISOString().substring(0, 10);
    }
    return "";
};


export const standardDateFormat = (value: string): string => {
    if (Date.parse(value)) {
        return new Date(value).toLocaleDateString();
    }
    return "";
};

export const isNullOrWhiteSpace = (value: string | undefined): boolean => {
    return !value || !value.trim();
};

export const validateBirthDate = (dateString: any) => {
    var today = new Date();
    if (Object.prototype.toString.call(dateString) === "[object Date]") {
        if (!isNaN(dateString.getTime())) {
            dateString = format(dateString, "MM/dd/yyyy");
        }
    } else {
        let isValid = isValidDate(dateString);
        if (!isValid) {
            return isValid;
        }
    }

    var birthDate = new Date(dateString);

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if (age >= 18 && age < 100) {
        return true;
    }
    else {
        return false;
    }
}

export const isValidDate = (date: any) => {
    // Assumes date is "mm/dd/yyyy"
    if (! /^\d\d\/\d\d\/\d\d\d\d$/.test(date)) {
        return false;
    }
    if (isNullOrWhiteSpace(date)) {
        return false;
    }
    const parts = date.split('/').map((p: any) => parseInt(p, 10));
    parts[0] -= 1;
    const d = new Date(parts[2], parts[0], parts[1]);
    return d.getMonth() === parts[0] && d.getDate() === parts[1] && d.getFullYear() === parts[2];
}

export const toDate = (date: any) => {
    if (Object.prototype.toString.call(date) === "[object Date]") {
        if (!isNaN(date.getTime())) {
            date = format(date, "MM/dd/yyyy");
        }
    }
    return date;
}

export const dateDiff = (startingDate: any, endingDate?: any) => {
    if (startingDate) {
        var startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
        if (!endingDate) {
            endingDate = new Date().toISOString().substr(0, 10);    // need date in YYYY-MM-DD format
        }
        var endDate = new Date(endingDate);
        if (startDate > endDate) {
            var swap = startDate;
            startDate = endDate;
            endDate = swap;
        }
        var startYear = startDate.getFullYear();
        var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
        var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        var yearDiff = endDate.getFullYear() - startYear;
        var monthDiff = endDate.getMonth() - startDate.getMonth();
        if (monthDiff < 0) {
            yearDiff--;
            monthDiff += 12;
        }
        var dayDiff = endDate.getDate() - startDate.getDate();
        if (dayDiff < 0) {
            if (monthDiff > 0) {
                monthDiff--;
            } else {
                yearDiff--;
                monthDiff = 11;
            }
            dayDiff += daysInMonth[startDate.getMonth()];
        }

        let totalMonths = yearDiff * 12 + monthDiff;
        return totalMonths;
    }
    return 0;
}

