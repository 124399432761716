import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { AccountInformationState, KnownAction } from "../store/accountInformationStore";

const unloadedState: AccountInformationState = {
    loanPurposeChoice: undefined,
    userId: undefined,
    occupancyTypeChoice: undefined,
    propertyTypeChoice: undefined,
    closingDateChoice: undefined,
    priorityChoice: undefined,
    howManyUnitsChoice: undefined,
    borrowerFirstName: undefined,
    borrowerMiddleName: undefined,
    borrowerLastName: undefined,
    borrowerNameSuffix: undefined,
    borrowerEmail: undefined,
    borrowerPhoneNumber: undefined,
    initialAccountInformationSubmitted: false,
    loanGuid: "",
    isLoading: false,
    accountInformationCheck: false,
    borrowerType: ""
}

export const accountInformationReducer: Reducer<AccountInformationState> = (state: AccountInformationState | undefined, incomingAction: Action): AccountInformationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UpdateLoanPurposeChoice:
            return {
                ...state,
                loanPurposeChoice: action.loanPurpose
            };
        case ActionTypes.UpdateUserId:
            return {
                ...state,
                userId: action.userId
            };
        case ActionTypes.UpdateOccupancyTypeChoice:
            return {
                ...state,
                occupancyTypeChoice: action.occupancyType
            };
        case ActionTypes.UpdatePropertyTypeChoice:
            return {
                ...state,
                propertyTypeChoice: action.propertyType
            };
        case ActionTypes.UpdateClosingDateChoice:
            return {
                ...state,
                closingDateChoice: action.closingDate
            };
        case ActionTypes.UpdatePriorityChoice:
            return {
                ...state,
                priorityChoice: action.priority
            };
        case ActionTypes.UpdateHowManyUnitsChoice:
            return {
                ...state,
                howManyUnitsChoice: action.howManyUnits
            };
        case ActionTypes.UpdateBorrowerFirstName:
            return {
                ...state,
                borrowerFirstName: action.firstName
            };
        case ActionTypes.UpdateBorrowerMiddleName:
            return {
                ...state,
                borrowerMiddleName: action.middleName
            };
        case ActionTypes.UpdateBorrowerLastName:
            return {
                ...state,
                borrowerLastName: action.lastName
            };
        case ActionTypes.UpdateBorrowerNameSuffix:
            return {
                ...state,
                borrowerNameSuffix: action.nameSuffix
            };
        case ActionTypes.UpdateBorrowerEmail:
            return {
                ...state,
                borrowerEmail: action.email
            };
        case ActionTypes.UpdateBorrowerPhoneNumber:
            return {
                ...state,
                borrowerPhoneNumber: action.phoneNumber
            };
        case ActionTypes.SubmitInitialAccountInformation:
            return {
                ...state,
                initialAccountInformationSubmitted: action.value
            };
        case ActionTypes.SetLoanGuid:
            return {
                ...state,
                loanGuid: action.loanGuid
            };
        case ActionTypes.IsLoading:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case ActionTypes.AccountInformationCheck: {
            return { ...state, accountInformationCheck: action.accountInformationCheck };
        };
        case ActionTypes.SetBorrowerType: {
            return { ...state, borrowerType: action.borrowerType };
        }
        default:
            return state;
    }
}
