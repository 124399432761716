import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction } from '../store/userAccountStore';

export const userAcountActionCreators = {
    updateUserFirstName: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateUserFirstName, firstName: value });
    },
    updateUserLastName: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateUserLastName, lastName: value });
    },
    updateUserEmail: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateUserEmail, email: value });
    },
    updateUserBorrowerType: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateUserBorrowerType, borrowerType: value });
    },
    updateUserConsent: (value?: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateUserConsent, hasConsent: value });
    },
    isLoancreated: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.IsLoancreated, isLoancreated: value });
    }
}