import InfographicSideLayout from "components/common/Layout/InfographicSideLayout";
import scoreInfographic from "../../assets/infographics/creditReport/score.svg";
import React, { useEffect } from "react";
import { useNavigation } from "hooks/useNavigation";
import { useHistory } from "react-router";
import { useInitialMount } from "hooks/useInitialMount";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

interface CreditReportScoresProps {
    borrowerName: string;
    borrowerScore: number;
    coBorrowerName?: string;
    coBorrowerScore?: number;
}

const CreditReportScores = (props: CreditReportScoresProps) => {
    const history = useHistory();
    const navigation = useNavigation();
    const isInitialMount = useInitialMount();

    const GetText = () => {
        if (HasCoBorrower()) {
            return "Your credit scores have arrived!";
        }
        return "Your credit score has arrived!";
    };

    const HasCoBorrower = () => {
        return props.coBorrowerName && props.coBorrowerScore;
    };

    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    useEffect(() => {
        if (isInitialMount) {
            navigation.SetBackOnClick(() => {
                history.push({ pathname: "/create-account", state: { args: "borrower" } });
            });
            navigation.ShowContinue(() => {
                history.push({ pathname: "/employment-information", state: { args: "borrower" } });
            });
        }
    }, [isInitialMount]);

    return (
        <div>
            <InfographicSideLayout infographic={scoreInfographic}>
                <div>
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(GetText)}>{GetText()}</h1>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerName)}>{props.borrowerName}</h2>
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerScore)}>{props.borrowerScore}</h1>

                    {HasCoBorrower() && (
                        <>
                            <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.coBorrowerName)}>{props.coBorrowerName}</h2>
                            <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.coBorrowerScore)}>{props.coBorrowerScore}</h1>
                        </>
                    )}
                </div>
            </InfographicSideLayout>
        </div>
    );
};

export default CreditReportScores;