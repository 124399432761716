import * as React from "react";
import { OtherIncomeSourceDetail, AdditionaIIncome } from "../../store/additionalIncomeStore";
import { OtherIncomeSourceFields, DummyOtherIncomeSourceId } from "./AdditionalIncomeFields";
import { IncomeSource } from "../common/Enums/IncomeSource";
import { connect } from "react-redux";
import { AdditionalIncomeActionCreators } from "../../actions/additionalIncomeActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { ApplicationState } from "../../store";
import styles from "./additionalIncome.module.scss";
import TextInput from "../common/Input/TextInput";
import SelectDropDown from "../common/Input/SelectDropDown";
import { ValidationMessage, Rule } from "../constants/validation";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type otherIncomeSourceInformationItemProps = {
    additionaIIncomeInfo: AdditionaIIncome;
    otherIncomeSourceDetail: OtherIncomeSourceDetail;
    loanGuid: string,
    validation?: undefined;
    isError: any;
    owner: string;
    disabled: boolean;
}
    & typeof AdditionalIncomeActionCreators
    & typeof TaskMenuCreators
    & AdditionaIIncome;

const OtherIncomeSourceInformationItem = (props: otherIncomeSourceInformationItemProps) => {
    const handleInputChange = (value: any, field: OtherIncomeSourceFields) => {
        var otherIncomeSourceDetail = { ...props.otherIncomeSourceDetail }
        let isNew = props.otherIncomeSourceDetail.id === DummyOtherIncomeSourceId ? true : false;
        props.handleAdditionalIncomeFieldChange(value, field, otherIncomeSourceDetail.id, isNew);
    }
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    return (
        <>
            <div className={styles.incomeSourceCard}>
                {
                    props.additionaIIncomeInfo?.otherIncomeSourceDetails && props.additionaIIncomeInfo?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === props.owner).length > 1 &&
                    <div className={styles.closeButton}>
                        <span onClick={() => !props.disabled ? props.removeOtherIncomeSourceFromState(props.otherIncomeSourceDetail.id) : ""}
                        ><i className="fa fa-times"></i></span>
                    </div>
                }
                <div className={styles.twoColsGrid}>
                    <div>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Income Source")}>Income Source <pre> * </pre> </label>
                        <SelectDropDown
                            disabled={props.disabled}
                            value={props.otherIncomeSourceDetail.description || ""}
                            name="Description"
                            rule={{ required: ValidationMessage.Required }}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { handleInputChange(e.target.value, OtherIncomeSourceFields.Description) }}
                            noArrow={false}
                            invalidclass={styles.invalidInput}
                            dropdownclass={styles.select}
                            handleValidation={props.setValidation}>
                            <option value="">--Select--</option>
                            {Object.entries(IncomeSource).map(([key, values]) => (
                                <option key={values} value={key}>
                                    {values}
                                </option>
                            ))}

                        </SelectDropDown>
                    </div>
                    
                    <div>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Monthly Income")}>Monthly Income <pre> * </pre></label>
                        <TextInput
                            disabled={props.disabled}
                            id={OtherIncomeSourceFields.MonthlyAmount}
                            name="Monthly amount"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange(e.target.value, OtherIncomeSourceFields.MonthlyAmount) }}
                            value={props.otherIncomeSourceDetail.monthlyAmount || ""}
                            placeholder="$0.00"
                            maxLength="10"
                            rule={{ required: ValidationMessage.Required, pattern: Rule.DecimalValidation }}
                            invalidclass={styles.invalidInput}
                            handleValidation={props.setValidation} />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (appState: ApplicationState): any => {
    let loanGuid = undefined;
    if (appState.loan) {
        loanGuid = appState.loan.loanGuid;
    }
    return { loanGuid };
};

const mapDispatchToProps = (dispatch: any) => ({
    handleAdditionalIncomeFieldChange: (value: any, field: string, OtherIncomeSourceId: string, isNew: boolean) => {
        dispatch(AdditionalIncomeActionCreators.handleAdditionalIncomeFieldChange(value, field, OtherIncomeSourceId, isNew));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    setValidation: (error: any, field: string) => {
        dispatch(AdditionalIncomeActionCreators.setValidation(error, field));
    },
    removeOtherIncomeSourceFromState: (OtherIncomeSourceId: string) => {
        dispatch(AdditionalIncomeActionCreators.removeOtherIncomeSourceFromState(OtherIncomeSourceId));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherIncomeSourceInformationItem as any) as any;