import * as ActionTypes from "../common/ActionTypes";

// STATE
export interface AssetInformationState {
    coBorrowerAssetStatus?: boolean;
    borrower: BorrowerAsset;
    borrowerComplete: boolean;
    submitBorrower: boolean;
    coBorrower: BorrowerAsset;
    coBorrowerComplete: boolean;
    submitCoBorrower: boolean;
}

// MODEL
export interface BorrowerAsset {
    loanGuid?: string;
    borrowerType?: number;
    birthDate?: string;
    taxIdentificationIdentifier?: string;
}

export enum BorrowerType {
    PrimaryBorrower = 0,
    CoBorrower = 1
}

// ACTIONS
interface SubmitBorrowerAssetInformation {
    type: typeof ActionTypes.SUBMIT_BORROWER_ASSET_INFORMATION,
    borrowerType: number,
}

interface UpdateBorrowerAssetProperty {
    type: typeof ActionTypes.UPDATE_BORROWER_ASSET_PROPERTY,
    value: string,
    propertyName: string,
    borrowerType: BorrowerType
}

interface UpdateSystematicAsset {
    type: typeof ActionTypes.UpdateSystematicAsset,
    borrowerData: BorrowerAsset
}

interface ReadSystematicAsset {
    type: typeof ActionTypes.ReadSystematicAsset;
    data: BorrowerAsset;
    borrowerType: BorrowerType;
}

interface UpdateCoBorrowerAssetStatus {
    type: typeof ActionTypes.UpdateCoBorrowerAssetStatus,
    value: boolean,
}

export type KnownAction =
    UpdateCoBorrowerAssetStatus
    | SubmitBorrowerAssetInformation |
    UpdateBorrowerAssetProperty |
    UpdateSystematicAsset |
    ReadSystematicAsset;