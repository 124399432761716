import * as React from "react";
import { Choice } from "../../interfaces/Question";
import ToolTipToggle from "./Buttons/ToolTipToggle";
import { useSpring, animated } from "react-spring";
import styles from "./ChoiceQuestionButton.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface ChoiceQuestionButtonProps {
    choice: Choice;
    disabled: boolean | undefined;
}

const ChoiceQuestionButton = (props: ChoiceQuestionButtonProps) => {
    const [toggle, setToggle] = React.useState(false);
    const [showFront, setShowFront] = React.useState(true);
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
    const { transform, opacity } = useSpring({
        opacity: toggle ? 1 : 0,
        transform: `perspective(600px) rotateY(${toggle ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 },
    });

    const toggleToolTip = (event: React.MouseEvent) => {
        setToggle(!toggle);
        setTimeout(() => setShowFront(!showFront), 600);
        event.stopPropagation();
    };

    const cardStyle = (val: ChoiceQuestionButtonProps) => {
        return (
            styles.card +
            " " +
            (val.choice.icon ? "" : styles.noIcon) +
            " " +
            (val.choice.toolTipText !== undefined ? "" : styles.hasNoTooltip) +
            " " +
            (val.choice.className ? val.choice.className : "")
        );
    };

    let cardMain = (
        <div className={styles.cardContent}>

            {props.choice.icon && (
                <div className={styles.iconContainer}>
                    <img className={styles.icon} src={props.choice.icon} alt={props.choice.text} />
                </div>
            )}

            <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.choice.text.replace('/', '/\n'))} className={styles.cardContTitle}>{props.choice.text.replace('/','/\n')}</span>
        </div>
    );

    let cardBack = (
        <div className={styles.cardContent}>
            {props.choice.toolTipText && <p>{props.choice.toolTipText}</p>}
        </div>
    );

    let card = <></>;
    if (props.choice.toolTipText) {
        card = (
            <div>
                <animated.button
                    disabled={props.disabled}
                    className={cardStyle(props)}
                    style={{
                        display: showFront ? "block" : "none",
                        opacity: opacity.interpolate((o: any) => 1 - o),
                        transform,
                    }}
                    onClick={props.choice.onClick}
                >
                    {/*<ToolTipToggle isActive={true} onClick={(event) => toggleToolTip(event)} />*/}
                    {cardMain}
                </animated.button>
                <animated.button
                    disabled={props.disabled}
                    className={cardStyle(props)}
                    style={{
                        display: showFront ? "none" : "block",
                        opacity,
                        transform: transform.interpolate((t) => `${t} rotateY(180deg)`),
                    }}
                    onClick={(event) => toggleToolTip(event)}
                >
                    <ToolTipToggle isActive={true} onClick={() => { }} isToggled />
                    {cardBack}
                </animated.button>
            </div>
        );
    } else {
        card = (
            <button disabled={props.disabled} onClick={props.choice.onClick} className={cardStyle(props)}>
                {cardMain}
            </button>
        );
    }

    return card;
};

export default ChoiceQuestionButton;
