import * as React from "react";
import { TaskMenuItem } from "../../../store/taskMenuStore";
import SubTaskList from "./SubTaskList";
import { ApplicationState } from "store";
import { connect } from "react-redux";
import TaskItem from "./TaskItem";
import * as TaskMenuActions from "../../../actions/taskMenuAction";
import styles from "./TaskMenu.module.scss";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { TaskItem as TaskItemEnum } from "./TaskStatus";

type TaskListProps = {
    taskMenuItems: TaskMenuItem[];
    loanGuid: string;
    isLoading: boolean;
    open: string;
} & typeof TaskMenuActions.TaskMenuCreators;

const TaskList = (props: TaskListProps) => {
    const location = useLocation();

    React.useEffect(() => {
        if (props.loanGuid && props.open) {
            props.GetSubTaskItemLoanStatus(props.loanGuid);
        }
    }, [props.open, location, location.state?.args]);

    const singleTaskItem = (taskItem: TaskMenuItem, index: number) => {

        var args = taskItem.subTasks[0].queryParameter ? taskItem.subTasks[0].queryParameter : "";
        return (
            <li key={index}>
                <TaskItem
                    key={`${taskItem.path}-${index}`}
                    name={taskItem.name}
                    path={`/${taskItem.path}`}
                    args={`${args}`}
                    isEnabled={taskItem.isEnabled}
                    isSubtask={false}
                    taskStatus={taskItem.taskStatusId}
                    isMultipleSubtasks={taskItem.subTasks.length > 1 ? true : false}
                />
            </li>
        );
    };

    const multiTaskItem = (taskItem: TaskMenuItem, index: number) => {
        return (
            <li key={index} className={styles.parentli}>
                <div key={index}>
                    <ul>
                        {singleTaskItem(taskItem, index)}
                    </ul>
                    <SubTaskList taskItem={taskItem} />
                </div>
            </li>
        );
    };

    return (
        <>
            <h4>Where Am I?</h4>
            {!props.isLoading ?
                <ul className={styles.tasklist}>
                    <li className={location.pathname?.split("/")[1] === "dashboard" ? `${styles.active}` : `${styles.backtodashboard}`}>
                        <Link onClick={(event) => {
                            if (location.pathname === "/dashboard") {
                                event.preventDefault();
                            }
                        }
                        } className="Nav_dashboard_link" to="/dashboard"><span>Back to dashboard</span></Link>
                    </li>
                    {props.taskMenuItems.map((taskItem: TaskMenuItem, index: number) => {
                        if (!taskItem?.hasCoBorrower) {
                            return singleTaskItem(taskItem, index);
                        } else {
                            if (taskItem.subTasks.length > 1) {
                                return multiTaskItem(taskItem, index);
                            }
                            else {
                                return singleTaskItem(taskItem, index);
                            }
                        }
                    })}
                </ul> :
                <div className="align-center">
                    <div className="loader"></div>
                </div>
            }
        </>)
};


function mapStateToProps(state: ApplicationState) {
    let { accountInformation, taskMenu } = state;
    let loanGuid;
    let taskMenuItems;
    let isLoading;

    if (accountInformation?.loanGuid) {
        loanGuid = accountInformation?.loanGuid;
    }

    if (taskMenu?.data) {
        taskMenuItems = taskMenu?.data.filter(x => x.taskItemId !== TaskItemEnum.AccountInformation);
    }

    if (taskMenu) {
        isLoading = taskMenu?.isLoading;
    }

    return {
        loanGuid,
        taskMenuItems,
        isLoading
    };
}

export default connect((state: ApplicationState) => mapStateToProps(state), TaskMenuActions.TaskMenuCreators)(TaskList as any) as any;