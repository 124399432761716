import * as React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { ZipDetail } from '../../../store/ZipCodeLookUpStore';
import styles from "../../common/shared/zipCode.module.scss";

interface ZipCodeModelProps {
    zipDetails: ZipDetail[];
    zipCode: string;
    cityName: string;
    stateCode: string;
    isOpen: boolean;
    onSelection: (zipDetail: ZipDetail) => void;
    onCancel: () => void;
    isLoading: boolean;
};

interface selectedCity {
    zipDetail: ZipDetail;
    index: number;
}

const ZipCodeSelection = (props: ZipCodeModelProps) => {
    const [selection, changeSelection] = React.useState<selectedCity>();
    const [isOpen] = React.useState(props.isOpen);
    const rows: any[] = [];

    props.zipDetails.map((c, i) => {
        rows.push(
            <tr key={i} onClick={() => changeSelection({ ...selection, zipDetail: c, index: i })}
                className={selection && selection.index === i ? `${styles.cityTable} ${styles.activeCity}` : styles.cityTable}>
                {props.zipCode && < td > {c.city}</td>}
                {props.cityName && < td > {c.zipCode}</td>}
                <td>{c.stateCode}</td>
            </tr>);
    });
    const handleShow = () => {
        props.onCancel()
    }
    const onSelect = () => {
        if (selection !== undefined) {
            props.onSelection(selection.zipDetail);
        }
    }
    return (
        <Modal isOpen={isOpen} className={styles.zipSelectionBox}>
            <ModalHeader >{props.zipCode ? "SELECT A CITY" : "SELECT A ZIP CODE"}</ModalHeader>
            <ModalBody>
                {props.zipCode
                    ? <><p>Several cities are located in this Zip Code.</p>
                        <p> Select a city from the list.</p></>
                    : <p>Select a Zip code from the list.</p>}
                <div className={styles.tableContainer}>
                    <Table>
                        <thead>
                            <tr>
                                {props.zipCode && <th>City Name</th>}
                                {props.cityName && <th>Zip Code</th>}
                                <th>State</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="row">
                    <button color="primary" className={`common-button ${styles.saveButton}`} onClick={onSelect}>Select</button>
                    <button color="secondary" className={`common-green-button ${styles.saveButton}`} onClick={handleShow}>Cancel</button>

                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ZipCodeSelection as any;