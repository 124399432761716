import React, { useEffect, useState } from "react";
import CreditAuthorizationDetail from "./CreditAuthorizationDetail";
import * as ConsumerCreditAuthorizationStore from "../../store/consumerCreditAuthorizationStore";
import { BorrowerType } from "../../store/Enum";
import Infographic from "components/common/Infographic";
import yesNotAgreeGraphic from "assets/infographics/consumerCreditAuthorization/yes-do-not-agree.svg";
import { ApplicationState } from "../../store";
import { connect, useSelector } from "react-redux";
import * as CreditAuthorizationActions from "../../actions/consumerCreditAuthorizationActions";
import { useNavigation } from "hooks/useNavigation";
import * as ConsumerCreditAuthorizationActions from "../../actions/consumerCreditAuthorizationActions";
import { Link, useHistory } from "react-router-dom";
import * as consumerCreditAuthorizationService from "../../services/consumerCreditAuthorizationService";
import { isNullOrUndefined } from "util";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { accountCreationActionCreators } from "../../actions/accountCreationActions";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ConsumerCreditAuthorizationProps = ConsumerCreditAuthorizationStore.ConsumerCreditAuthorizationState
    & {
        isCoBorrowerPresent: boolean,
        borrower: ConsumerCreditAuthorizationStore.ConsumerCreditAuthorization,
        coBorrower: ConsumerCreditAuthorizationStore.ConsumerCreditAuthorization,
        history: any;
        loanGuid: string;
        interimLoanStagingId: number;
        isLoading: boolean;
        isLoanCompleted: boolean;
    } & typeof CreditAuthorizationActions.consumerCreditAuthorizationActionCreators & typeof loanUpdateResponseActionCreators
    & typeof accountCreationActionCreators;

export enum ConsumerCreditAuthorizationStep {
    BorrowerAuthorizationPage = 1,
    CoBorrowerAuthorizationPage = 2,
    VerifyCreditAuthorization = 3,
    Finished = 4
}

const ConsumerCreditAuthorization = (props: ConsumerCreditAuthorizationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [showLoading, setShowLoading] = useState(false);
    const [currentStep, changeStep] = React.useState(0);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);

    const navigation = useNavigation(() => {
        navigation.ShowContinue(() => onContinue(), false);
    });

    const onContinue = async () => {
        if (currentStep == ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage) {

            if (!props.isLoanCompleted) {
                await saveCreditAuthorization(props.borrower)
            }
            else {
                if (props.borrower.authorizedCreditReportIndicator) {
                    if (props.isCoBorrowerPresent) {
                        changeStep(ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage);
                    }
                    else {
                        changeStep(ConsumerCreditAuthorizationStep.VerifyCreditAuthorization);
                    }
                }
                else if (!props.borrower.authorizedCreditReportIndicator) {
                    changeStep(ConsumerCreditAuthorizationStep.Finished);
                }
            }
        }
        else if (currentStep == ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage) {
            if (!props.isLoanCompleted) {
                await saveCreditAuthorization(props.coBorrower)
            }
            else {
                if (props.coBorrower.authorizedCreditReportIndicator) {
                    changeStep(ConsumerCreditAuthorizationStep.VerifyCreditAuthorization);
                }
                else {
                    changeStep(ConsumerCreditAuthorizationStep.Finished);
                }
            }
        }
        else if (currentStep == ConsumerCreditAuthorizationStep.Finished) {
            const { history } = props;
            if (history) history.push({ pathname: "/employment-information", state: { args: "borrower" } });
        }
    };

    const onBack = () => {
        if (currentStep == ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage) {
            const { history } = props;
            history.push({ pathname: "/create-account", state: { args: props.isCoBorrowerPresent ? "coborrower" : "borrower", isBack: true } });
        }
        else if (currentStep == ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage) {
            changeStep(ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage);
        }
        else if (currentStep == ConsumerCreditAuthorizationStep.Finished) {
            changeStep(ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage);
        }
        else if (currentStep == ConsumerCreditAuthorizationStep.VerifyCreditAuthorization) {
            changeStep(ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage);
        }
    };

    const returnDisabled = () => {
        let isDisabled = false;
        if (currentStep == ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage) {
            if (isNullOrUndefined(props.borrower.authorizedCreditReportIndicator)) {
                isDisabled = true;
            }
        } else if (currentStep == ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage) {
            if (isNullOrUndefined(props.coBorrower.authorizedCreditReportIndicator)) {
                isDisabled = true;
            }
        }
        return isDisabled;
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    useEffect(() => {
        if (props.interimLoanStagingId && props.interimLoanStagingId > 0) {
            setShowLoading(true);
            consumerCreditAuthorizationService.getConsumerCreditAuthorizationStatus(props.interimLoanStagingId)
                .then((response) => {
                    if (!response.ok) {
                        console.error(response);
                    }
                    return response.parsedBody as boolean;
                })
                .then(status => {
                    if (status) {
                        history.push("/credit-report");
                    }
                    else {
                        changeStep(ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage);
                    }
                })
                .catch((error: Error) => {
                    console.error(error);
                });
            setShowLoading(false);
        }
        else {
            console.error("consumer credit authorization loanGuid missing");
        }
    }, []);

    const history = useHistory();
    React.useEffect(() => {
        navigation.ShowContinue(() => onContinue(), returnDisabled() || showLoading || isEncompassDown
        );
        navigation.SetBackOnClick(() => onBack());

        if (currentStep === ConsumerCreditAuthorizationStep.VerifyCreditAuthorization) {
            history.push("/credit-report");
        }
    }, [currentStep, props.borrower, props.coBorrower, showLoading, isEncompassDown]);


    const saveCreditAuthorization = async (data: ConsumerCreditAuthorizationStore.ConsumerCreditAuthorization) => {
        setShowLoading(true);
        const response = await consumerCreditAuthorizationService.updateConsumerCreditAuthorization(data);
        setShowLoading(false);
        if (!response.ok) {
            console.error("consumer credit authorization failed to save for " + data.borrowerType);
        }
        let result = response.parsedBody as boolean;
        if (result) {
            Promise.all([
                consumerCreditAuthorizationService.updateConsumerCreditAuthLoanDetailData(data).then(() => {
                    if (data.interimLoanStagingId !== undefined && data.interimLoanStagingId > 0) {
                        props.getBorrowerInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                    }
                }),
                consumerCreditAuthorizationService.createAndSubmitAuthorizationFile(data),
                consumerCreditAuthorizationService.updateProgressTracker(data.loanGuid ?? "")
            ]);

            if (props.borrower.authorizedCreditReportIndicator) {
                if (props.isCoBorrowerPresent && currentStep == ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage) {
                    changeStep(ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage);
                }
                else {
                    changeStep(ConsumerCreditAuthorizationStep.VerifyCreditAuthorization);
                }
            }
            else if (props.coBorrower.authorizedCreditReportIndicator && currentStep == ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage) {
                changeStep(ConsumerCreditAuthorizationStep.VerifyCreditAuthorization);
            }
            else {
                changeStep(ConsumerCreditAuthorizationStep.Finished);
            }
        }
    }

    let creditAuthorizationPage = <></>;

    switch (currentStep) {
        case ConsumerCreditAuthorizationStep.BorrowerAuthorizationPage:
            {
                creditAuthorizationPage = (
                    <CreditAuthorizationDetail
                        borrowerType={BorrowerType.PrimaryBorrower}
                        changeScreen={changeStep}
                        setShowLoading={setShowLoading}
                    />
                );
                break;
            }
        case ConsumerCreditAuthorizationStep.CoBorrowerAuthorizationPage:
            {
                creditAuthorizationPage = (
                    <CreditAuthorizationDetail
                        borrowerType={BorrowerType.CoBorrower}
                        changeScreen={changeStep}
                        setShowLoading={setShowLoading}
                    />
                );
                break;
            }
        case ConsumerCreditAuthorizationStep.Finished:
            {
                creditAuthorizationPage = (
                    <div className="infographic-side-layout-container">
                        <div className="content">
                            <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Your Loan Officer will be in touch soon to go over options')}>Your Loan Officer will be in touch soon to go over options.</h1>
                        </div>
                        <div className="infographic-side-panel">
                            <Infographic src={yesNotAgreeGraphic} />
                        </div>
                    </div>
                );
                break;
            }
    }
    return (
        <>
            <div className="w-100-p" > {creditAuthorizationPage}
                {showLoading && <div className="screenLoader" >
                    <div className="loader medium"></div></div>}
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    getConsumerCreditAuthorizationStatus: (interimLoanStagingId: number) => {
        dispatch(ConsumerCreditAuthorizationActions.consumerCreditAuthorizationActionCreators.getConsumerCreditAuthorizationStatus(interimLoanStagingId));
    },
    setIsLoading: (value: boolean) => {
        dispatch(ConsumerCreditAuthorizationActions.consumerCreditAuthorizationActionCreators.setIsLoading(value));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    getBorrowerInformation: ( borrowerType: string, interimLoanStagingId: number) => {
        dispatch(accountCreationActionCreators.getBorrowerInformation(borrowerType, interimLoanStagingId));
    }
});

const mapStateToProps = (state: ApplicationState): any => {
    const { accountCreation, consumerCreditAuthorization, loan } = state;
    let isCoBorrowerPresent;
    let borrower;
    let coBorrower;
    let loanGuid = loan?.loanGuid;
    let isLoading;
    let isLoanCompleted = loan?.isLoanCompleted;
    let interimLoanStagingId = loan?.interimLoanStagingId;

    if (accountCreation) {
        isCoBorrowerPresent = state.accountCreation?.coBorrower?.firstName != null ? true : false;
        borrower = consumerCreditAuthorization?.borrower;
        coBorrower = consumerCreditAuthorization?.coBorrower;
        isLoading = consumerCreditAuthorization?.isLoading;
    }
    return {
        isCoBorrowerPresent,
        borrower,
        coBorrower,
        loanGuid,
        isLoading,
        isLoanCompleted,
        interimLoanStagingId
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsumerCreditAuthorization as any);