import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction, ManualAssetInformation } from '../store/ManualAssetInformationStore';
import * as assetInformationService from "../services/assetInformationService";

const setValidation = (validationError: any, field: string): KnownAction => {
    return { type: ActionTypes.SetManualAssetValidation, validationError, field };
}
const request = (interimLoanStagingid: number): KnownAction => {
    return {
        type: ActionTypes.RequestAsset,
        interimLoanStagingid
    };
}
const requestEncompassAsset = (loanGuid: string): KnownAction => {
    return {
        type: ActionTypes.RequestEncompassAsset,
        loanGuid
    };
}
const receive = (details: ManualAssetInformation[]): KnownAction => {
    return { type: ActionTypes.ReceiveAsset, details };
}
const receiveEncompassAsset = (assets: ManualAssetInformation[]): KnownAction => {
    return { type: ActionTypes.ReceiveEncompassAsset, assets };
}
const handleError = (errorMessage: string): KnownAction => {
    return { type: ActionTypes.HandleErrorAsset, errorMessage: errorMessage };
}
const createAsset = (details: ManualAssetInformation[]): KnownAction => {
    return { type: ActionTypes.CreateManualAssetInformation, details: details };
}
const removeAsset = (id: string): KnownAction => {
    return { type: ActionTypes.RemoveManualAssetItemFromInterim, id };
}
const receiveSystematicAsset = (systematicAssets: ManualAssetInformation[]): KnownAction => {
    return { type: ActionTypes.ReceiveSystematicAsset, systematicAssets };
};

export const ManualAssetInformationActionCreators = {
    updateManualAssetProprty: (value: string, propertyName: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateManualAssetProperty, value: value, propertyName: propertyName });
    },
    removeManualAssetFromState: (id: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.RemoveManualAssetItem, id });
    },
    GetManualAssetFromState: (id: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.GetManualAssetItem, id });
    },
    setValidation: (error: any, field: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(setValidation(error, field));
    },
    getAssetInformation: (borrowerType:string,interimLoanStagingid:number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(request(interimLoanStagingid));
        dispatch(handleError(""));
        assetInformationService.getAll(borrowerType, interimLoanStagingid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem fetching loan information for loanGuid" + interimLoanStagingid + " .");
                }
                return response.parsedBody as ManualAssetInformation;
            })
            .then((data: any) => {
                dispatch(receive(data));
            })
            .catch((error: Error) => {
                dispatch(handleError(error.message));
            });
    },
    setAssetsInformation: (assets: ManualAssetInformation[]): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(receive(assets));
    },

    getAssetInformationFromEncompass: (loanGuid: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(requestEncompassAsset(loanGuid));
        dispatch(handleError(""));
        assetInformationService.getAssetDetailsFromEncompass(loanGuid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem fetching loan information for loanGuid" + loanGuid + ".");
                }
                return response.parsedBody as ManualAssetInformation[];
            })
            .then((data: any) => {
                dispatch(receiveEncompassAsset(data));
            })
            .catch((error: Error) => {
                dispatch(handleError(error.message));
            });
    },

    createManualAssetInformation: (interimLoanStagingId:number,data: ManualAssetInformation,borrowerType:string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(handleError(""));
        if (interimLoanStagingId != null) {
            assetInformationService.updateAsset(data, interimLoanStagingId)
                .then((response: any) => {
                    if (response.parsedBody === "") {
                        throw new Error("There was a problem on saving loan information data..");
                    }
                    else {
                        assetInformationService.getAll(borrowerType, interimLoanStagingId)
                            .then((responseValue: any) => {
                                if (!responseValue.ok) {
                                    throw new Error("There was a problem fetching loan information for interimLoanStagingId" + interimLoanStagingId + ".");
                                }
                                return responseValue.parsedBody as ManualAssetInformation;
                            })
                            .then((data: any) => {
                                if (data.length > 0) {
                                    dispatch(createAsset(data));
                                }
                            })
                    }
                    return response.parsedBody as boolean;
                })
                .catch((error: Error) => {
                    dispatch(handleError(error.message));
                });
        }
    },

    removeAssetFromInterim: (id: string, interimLoanStagingid: number): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(handleError(""));
        if (interimLoanStagingid != undefined && interimLoanStagingid >0) {
            assetInformationService.deleteAssetFromInterim(id, interimLoanStagingid)
                .then((response: any) => {
                    if (!response.ok) {
                        throw new Error("There was a problem updating Asset for interimLoanStagingid" + interimLoanStagingid + ".");
                    }
                    dispatch(removeAsset(id));
                    return response.parsedBody as boolean;
                })
                .then((isSuccessful: any) => {
                    if (!isSuccessful) {
                        dispatch(handleError("There was a problem updating Asset for interimLoanStagingid" + interimLoanStagingid + "."));
                    }
                });
        }
    },

    getSystematicAsset: (loanGuid: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(requestEncompassAsset(loanGuid));
        dispatch(handleError(""));
        assetInformationService.getSystematicAsset(loanGuid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem fetching loan information for loanGuid" + loanGuid + " .");
                }
                return response.parsedBody as ManualAssetInformation[];
            })
            .then((data: any) => {
                dispatch(receiveSystematicAsset(data));
            })
            .catch((error: Error) => {
                dispatch(handleError(error.message));
            });
    },

    createAsset: (borrowerType: string, interimLoanStagingid:number): AppThunkAction<KnownAction> => (dispatch) => {
        assetInformationService.getAll(borrowerType, interimLoanStagingid)
            .then((responseValue: any) => {
                if (!responseValue.ok) {
                    throw new Error("There was a problem fetching loan information for interimLoanStagingid" + interimLoanStagingid + ".");
                }
                return responseValue.parsedBody as ManualAssetInformation;
            })
            .then((data: any) => {
                if (data.length > 0) {
                    dispatch(createAsset(data));
                }
            })
    }   
}