import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as DemographicQuestionsActions from "../../actions/demographicQuestionsActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import * as DemographicQuestionsStore from "../../store/demographicQuestionsStore";
import styles from "./DemographicQuestions.module.scss";
import { DemographicFields } from "./DemographicFields";
import { BorrowerTypeEnum } from "components/common/Enums/BorrowerTypeEnum";
import { BorrowerType } from "../../store/Enum";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type DemographicQuestionsProps = DemographicQuestionsStore.DemographicQuestionsState & {
    borrowerName: string,
    borrowerType: BorrowerType;
    setValid: React.Dispatch<React.SetStateAction<boolean>>;
    loanGuid: string;
    disabled: boolean;
    interimLoanStagingId: number;
} & typeof DemographicQuestionsActions.demographicQuestionsActionCreators & typeof TaskMenuCreators;

const DemographicQuestion = (props: DemographicQuestionsProps) => {
    const setCheckbox = (checked: boolean, name: string, section: string) => {
        props.updateDemographicQuestions(checked, name, section, props.borrowerType);
    }
    const { speak, cancel } = useSpeechSynthesis();
    let currentBorrower = props.borrowerType === BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower;

    React.useEffect(() => {
        props.setValid(validateData(props.borrowerType === BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower));
    }, [props.borrower, props.coBorrower]);

    React.useEffect(() => {
        if (props.borrowerType === BorrowerType.PrimaryBorrower && props.loanGuid) {
            props.getDemographicQuestions(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
        }
        else if (props.borrowerType === BorrowerType.CoBorrower && props.loanGuid) {
            props.getDemographicQuestions(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
        }
        props.setValid(validateData(props.borrowerType === BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower));
    }, [props.borrowerType]);

    const validateData = (demographicQuestions: DemographicQuestionsStore.DemographicQuestions) => {
        let valid = false;
        if ((demographicQuestions.hmdaEthnicityHispanicLatinoIndicator
            || demographicQuestions.hmdaEthnicityNotHispanicLatinoIndicator
            || demographicQuestions.hmdaEthnicityDoNotWishIndicator)
            && (demographicQuestions.hmdaAmericanIndianIndicator
                || demographicQuestions.hmdaAfricanAmericanIndicator
                || demographicQuestions.hmdaPacificIslanderIndicator
                || demographicQuestions.hmdaWhiteIndicator
                || demographicQuestions.hmdaRaceDoNotWishProvideIndicator)
            && (demographicQuestions.hmdaGendertypeFemaleIndicator
                || demographicQuestions.hmdaGendertypeMaleIndicator
                || demographicQuestions.hmdaGendertypeDoNotWishIndicator)) {
            valid = true;
        }

        return valid;
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }

    return (
        <>
            <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Demographic Questions')} className={styles.textCenter}>
                Demographic Questions
            </h1>
            <h2 className={`${styles.textCenter} ${styles.mb0}`} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please answer the additional questions for' + props.borrowerName)}>
                Please answer the additional questions for {props.borrowerName}
            </h2>

            <div className={styles.demographicQuestionForm}>
                <div>
                    <label className={styles.greenlable} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Ethnicity')}>{DemographicFields.SectionEthnicity}</label>
                    <div className={styles.questionContainer}>
                        <div className={styles.inputGroup}>
                            <div className={styles.questionCheck}>
                                <div className={styles.answerButton}>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.HispanicLatino}
                                                type="checkbox"
                                                checked={!!currentBorrower.hmdaEthnicityHispanicLatinoIndicator}
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionEthnicity)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Hispanic or Latino')}> Hispanic or Latino </span>
                                    </label>

                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.NotHispanicLatino}
                                                type="checkbox"
                                                checked={!!currentBorrower.hmdaEthnicityNotHispanicLatinoIndicator}
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionEthnicity)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Not Hispanic or Latino')}> Not Hispanic or Latino </span>
                                    </label>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.ethnicityDoNotWishProvide}
                                                type="checkbox"
                                                checked={!!currentBorrower.hmdaEthnicityDoNotWishIndicator}
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionEthnicity)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('I Do Not Wish to Provide This Information')}> I Do Not Wish to Provide This Information </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label className={styles.greenlable} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Race')}>{DemographicFields.SectionRace}</label>
                    <div className={styles.questionContainer}>
                        <div className={styles.inputGroup}>
                            <div className={styles.questionCheck}>
                                <div className={styles.answerButton}>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.AmericanIndianIndicator}
                                                checked={!!currentBorrower.hmdaAmericanIndianIndicator}
                                                type="checkbox"
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionRace)} />

                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('American Indian or Alaska Native')}> American Indian or Alaska Native</span>
                                    </label>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.AfricanAmericanIndicator}
                                                checked={!!currentBorrower.hmdaAfricanAmericanIndicator}
                                                type="checkbox"
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionRace)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Black or African American')}>Black or African American </span>
                                    </label>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.PacificIslanderIndicator}
                                                checked={!!currentBorrower.hmdaPacificIslanderIndicator}
                                                type="checkbox"
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionRace)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Native Hawaiian or Other Pacific Islander')}>Native Hawaiian or Other Pacific Islander</span>
                                    </label>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.WhiteIndicator}
                                                checked={!!currentBorrower.hmdaWhiteIndicator}
                                                type="checkbox"
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionRace)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('White')}>White</span>
                                    </label>


                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.RaceDoNotWishProvideIndicator}
                                                checked={!!currentBorrower.hmdaRaceDoNotWishProvideIndicator}
                                                type="checkbox"
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionRace)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('I Do Not Wish to Provide This Information')}> I Do Not Wish to Provide This Information</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label className={styles.greenlable} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Sex')}>{DemographicFields.SectionSex}</label>
                    <div className={styles.questionContainer}>
                        <div className={styles.inputGroup}>
                            <div className={styles.questionCheck}>
                                <div className={styles.answerButton}>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.GendertypeFemale}
                                                type="checkbox"
                                                checked={!!currentBorrower.hmdaGendertypeFemaleIndicator}
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionSex)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Female')}> Female </span>
                                    </label>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.GendertypeMale}
                                                type="checkbox"
                                                checked={!!currentBorrower.hmdaGendertypeMaleIndicator}
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionSex)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Male')}>Male </span>
                                    </label>
                                    <label>
                                        <div className={styles.customCheckBox}>
                                            <input
                                                disabled={props.disabled}
                                                name={DemographicFields.GendertypeDoNotWishProvide}
                                                type="checkbox"
                                                checked={!!currentBorrower.hmdaGendertypeDoNotWishIndicator}
                                                onChange={(e) => setCheckbox(e.target.checked, e.target.name, DemographicFields.SectionSex)} />
                                        </div>
                                        <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('I Do Not Wish to Provide This Information')}> I Do Not Wish to Provide This Information </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: ApplicationState, ownProps: { borrowerType: BorrowerType, setValid: React.Dispatch<React.SetStateAction<boolean>>, isLoanCompleted: boolean }): any => {
    return state.accountInformation && state.accountCreation ?
        {
            ...state.demographicQuestions,
            borrowerName:
                ownProps.borrowerType == BorrowerType.PrimaryBorrower ?
                    state.accountCreation?.borrower?.firstName + " " + state.accountCreation?.borrower?.lastName :
                    state.accountCreation?.coBorrower?.firstName + " " + state.accountCreation?.coBorrower?.lastName,
            borrowerType: ownProps.borrowerType,
            setValid: ownProps.setValid,
            loanGuid: state.loan?.loanGuid,
            disabled: ownProps.isLoanCompleted,
            interimLoanStagingId: state.loan?.interimLoanStagingId
        } :
        state.demographicQuestions;
}

const mapDispatchToProps = (dispatch: any) => ({
    updateDemographicQuestions: (value: boolean, propertyName: string, section: string, borrowerType: BorrowerType) => {
        dispatch(DemographicQuestionsActions.demographicQuestionsActionCreators.updateDemographicQuestions(value, propertyName, section, borrowerType));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    getDemographicQuestions: (borrowerType: string, interimLoanStagingId:number) => {
        dispatch(DemographicQuestionsActions.demographicQuestionsActionCreators.getDemographicQuestions(borrowerType, interimLoanStagingId));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DemographicQuestion as any);