import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as AccountInformationInterfaces from "../interfaces/accountInformationInterfaces";
import * as AccountInformationStore from "../store/accountInformationStore";
import { KnownAction } from '../store/accountInformationStore';
import * as accountInformationService from '../services/accountInformationService';

export const accountInformationActionCreators = {
    updateLoanPurposeChoice: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateLoanPurposeChoice, loanPurpose: value });
    },
    updateUserId: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateUserId, userId: value });
    },
    updateOccupancyTypeChoice: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateOccupancyTypeChoice, occupancyType: value });
    },
    updatePropertyTypeChoice: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdatePropertyTypeChoice, propertyType: value });
    },
    updateClosingDateChoice: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateClosingDateChoice, closingDate: value });
    },
    updatePriorityChoice: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdatePriorityChoice, priority: value });
    },
    updateHowManyUnitsChoice: (value?: AccountInformationInterfaces.HowManyUnitsChoices): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateHowManyUnitsChoice, howManyUnits: value });
    },
    updateBorrowerFirstName: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerFirstName, firstName: value });
    },
    updateBorrowerMiddleName: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerMiddleName, middleName: value });
    },
    updateBorrowerLastName: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerLastName, lastName: value });
    },
    updateBorrowerNameSuffix: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerNameSuffix, nameSuffix: value });
        dispatch({ type: ActionTypes.UpdateBorrowerNameSuffix, nameSuffix: value });
    },
    updateBorrowerEmail: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerEmail, email: value });
    },
    updateBorrowerPhoneNumber: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerPhoneNumber, phoneNumber: value });
    },

    submitInitialAccountInformation: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SubmitInitialAccountInformation, value: value });
    },
    setLoanGuid: (value?: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetLoanGuid, loanGuid: value });
    },
    IsLoading: (value?: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.IsLoading, isLoading: value });
    },
    accountInformationCheck: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.AccountInformationCheck, accountInformationCheck: value });
    },
    setBorrowerType: (value: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetBorrowerType, borrowerType: value });
    }
}