import * as React from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "./creditAuthorization.module.scss";
import Infographic from "components/common/Infographic";
import doNotAgreeInfoGraphic from "assets/infographics/consumerCreditAuthorization/do-not-agree.svg";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

interface CreditAuthorizationDialogProps {
    hasClosedDialog: (value: boolean) => any;
}

const CreditAuthorizationDialog = (props: CreditAuthorizationDialogProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [modal, setModal] = React.useState(true);
    const closeModal = (val: boolean) => {
        setModal(false);
        props.hasClosedDialog(val);
    };

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    return (
        <Modal
            isOpen={modal}
            centered={true}
            className={styles.eConsumerCreditAuthModal}
            contentClassName={styles.eConsumerCreditAuthModalContent}
            returnFocusAfterClose={false}
        >
            <ModalBody className={`${styles.modalBody}`}>
                <div className="align-center">
                    <div className={styles.modalIcon}>
                        {<Infographic src={doNotAgreeInfoGraphic} />}
                    </div>
                    <h3 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Are you sure?')}>Are you sure?</h3>
                    <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('A credit check is necessary to continue with the mortgage process, you will have to complete this later.')}>A credit check is necessary to continue with the mortgage process, you will have to complete this later.</p>
                    <div className={`${styles.answerButton} ${styles.buttonWidth}`}>
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to yes')} className="common-button" onClick={() => closeModal(true)}>
                            Yes
                        </button>
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to no')}
                            className={`common-button small`}
                            onClick={() => closeModal(false)}>
                            No
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default CreditAuthorizationDialog;
