import * as ActionTypes from "../common/ActionTypes";
import { KnownAction, ReviewApplicationState } from "../store/reviewApplicationStore"
import { Action, Reducer } from 'redux';

const unloadedState: any = {
    validation: undefined,
    section: undefined,
    submitCheckFlag: false,
    loanFromEncompassMessageFlag: false
}

export const reviewApplicationReducer: Reducer<ReviewApplicationState> =
    (state: ReviewApplicationState | undefined, incomingAction: Action): ReviewApplicationState => {
        if (state === undefined) {
            return unloadedState;
        }
        const action = incomingAction as KnownAction;
        switch (action.type) {
            case ActionTypes.SetReviewScreenValidation: {

                let copyValidation = { ...state.validation };
                let copySection = { ...state.section };
                if (action.validationError) {
                    copyValidation[action.field] = action.validationError;
                    copySection[action.section] = action.section;
                }
                else {
                    if (copyValidation[action.field]) {
                        delete copyValidation[action.field];
                        delete copySection[action.section];
                    }
                }
                return {
                    ...state,
                    validation: copyValidation,
                    section: copySection
                };
            };
            case ActionTypes.SubmitCheckFlag: {
                return { ...state, submitCheckFlag: action.submitCheckFlag };
            };
            case ActionTypes.SetLoanFromEncompassMessageFlag: {
                return { ...state, loanFromEncompassMessageFlag: action.loanFromEncompassMessageFlag };
            };
            default:
                return state;
        }
    }