import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction, ReoProperty, BorrowerReoState } from '../store/borrowerReoStore';
import * as borrowerReoService from "../services/borrowerReoService";

export const BorrowerReoActionCreators = {
    updateHasAdditionalProperties: (value: boolean, type: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateHasAdditionalProperties, value: value, reoType: type });
    },
    addReoProperty: (data: ReoProperty): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.AddReoProperty, data: data });
    },

    isLoading: (loading: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.IsLoading, loading: loading });
    },

    updateReoInformationField: (value: any, field: string, id: string): AppThunkAction<KnownAction> =>
        (dispatch) => {
            dispatch({
                type: ActionTypes.UpdateReoInformationField,
                value: value,
                field: field,
                id: id
            });
        },

    removeProperty: (id: string): AppThunkAction<KnownAction> =>
        (dispatch) => {
            dispatch({
                type: ActionTypes.RemoveProperty,
                id: id
            });
        },

    updateReoAddressField: (streetValue: string, cityValue: string, stateValue: any, zipValue: any, id: string): AppThunkAction<KnownAction> =>
        (dispatch) => {
            dispatch({
                type: ActionTypes.UpdateReoAddressField,
                streetValue: streetValue,
                cityValue: cityValue,
                stateValue: stateValue,
                zipValue: zipValue,
                id: id
            });
        },

    getBorrowerReo: (interimLoanStagingId: number): AppThunkAction<KnownAction> => (dispatch) => {
        if (interimLoanStagingId > 0) {
            borrowerReoService.getAll(interimLoanStagingId)
                .then((response: any) => {
                    if (!response.ok) {
                        throw new Error("Error occurred.");
                    }
                    return response.parsedBody;
                })
                .then((data: any) => {
                    if (data?.body) {
                        let reos = [] as ReoProperty[];

                        data.body?.additionalProperties.forEach((reoProperty: ReoProperty) => {
                            let reo: ReoProperty = reoProperty;
                            reo.id = reoProperty.id;
                            reo.owner = reoProperty.owner;
                            reo.marketValue = reoProperty.marketValue;
                            reos.push(reo);
                        });
                        let reoState: BorrowerReoState = {
                            hasAdditionalProperties: data.body?.hasAdditionalProperties,
                            reoProperties: reos,
                            currentReoProperty: {} as ReoProperty,
                            realEstateDoesNotApply: data.body?.realEstateDoesNotApply
                        }
                        dispatch({
                            type: ActionTypes.GetReoDetails, data: reoState
                        });
                    }
                });
        }
    },
    updateReoDataToEncompass: (data: BorrowerReoState, loanGuid: string, interimLoanStagingId: number): AppThunkAction<KnownAction> => (dispatch) => {
        if (data.hasAdditionalProperties !== undefined) {
            borrowerReoService.update(data.reoProperties, data.hasAdditionalProperties, loanGuid, interimLoanStagingId)
                .then((response: any) => {
                    if (!response.ok) {

                    }
                    return response.parsedBody as string;
                })
                .then(() => {

                });
        }
    },

    updateReoToInitialState: (initialReoInfo: BorrowerReoState): AppThunkAction<KnownAction> =>
        (dispatch) => {
            dispatch({
                type: ActionTypes.GetReoDetails,
                data: initialReoInfo
            });
        },
}