import { AppThunkAction } from '../store/index';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction } from '../store/reviewApplicationStore';

const setReviewScreenValidation = (validationError: any, field: string, section: string): KnownAction => {
    return { type: ActionTypes.SetReviewScreenValidation, validationError, field, section };
}

export const reviewApplicationActionCreators = {
    //setReviewScreenValidation: (error: any, field: string, section: string): AppThunkAction<KnownAction> => (dispatch) => {
    //    dispatch(setReviewScreenValidation(error, field, section));
    //}
    submitCheckFlag: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SubmitCheckFlag, submitCheckFlag: value });
    },
    setLoanFromEncompassMessageFlag: (value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetLoanFromEncompassMessageFlag, loanFromEncompassMessageFlag: value });
    },
}