import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as DashboardStore from "../store/dashboardStore";
import { KnownAction } from '../store/dashboardStore';
import * as dashboardService from "../services/dashboardService";

const receive = (data: DashboardStore.DashboardInformation): KnownAction => {
    return { type: ActionTypes.GetDashboardDetails, data };
}
const handleError = (errorMessage: string): KnownAction => {
    return { type: ActionTypes.HandleErrorDashboard, errorMessage: errorMessage };
}
const receiveNotificationMessages = (data: string[]): DashboardStore.KnownAction => {
    return { type: ActionTypes.ReceiveNotificationMessages, data };
}

export const dashboardActionCreators = {

    setIsLoading: (loading: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.IsLoading, loading: loading });
    },
    getDashboardDetails: (loanGuid: string, borrowerType: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var state = getState();
        if (state.dashboard) {
            state.dashboard.isLoading = true;
        }
        dashboardService.getDashboardDetails(loanGuid, borrowerType)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem while getting information.");
                }
                return response.parsedBody as DashboardStore.DashboardInformation;
            }).then((data: any) => {
                dispatch(receive(data));
                if (state.dashboard) {
                    state.dashboard.isLoading = false;
                }
            })
            .catch((error: Error) => {
                if (state.dashboard) {
                    state.dashboard.isLoading = false;
                }
                dispatch(handleError(error.message));
            });
    },
    getUserNotificationMessages: (loanGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var state = getState();
        dashboardService.getUserNotificationMessages(loanGuid).then((res: any) => {
            if (!res.ok) {
                throw new Error("There was a problem while getting information.");
            }
            return res.parsedBody as DashboardStore.DashboardInformation;
        }).then((data: any) => {
            dispatch(receiveNotificationMessages(data));
            if (state.dashboard) {
                state.dashboard.isLoading = false;
            }
        })
    },
}