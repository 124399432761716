import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";

interface ReadOnlyProps {
    disabled: boolean;
    isReadOnly: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dispatch: undefined;
}

function withReadOnlyWrapperComponent<T extends ReadOnlyProps>(Component: React.ComponentType<T>) {

    return (props: T) => {
        let disabled = props.isReadOnly;
        if (props.disabled === true) {
            disabled = true;
        }
        let onChange = (event: React.ChangeEvent<HTMLInputElement>) => { };
        if (!disabled) {
            onChange = props.onChange;
        }
        const { isReadOnly, dispatch, ...rest } = props;
        return (
            <>
                <Component {...rest as T} disabled={disabled} onChange={onChange} />
            </>);
    };
}

const mapStateToProps = (state: ApplicationState): any => {
    let isReadOnly = false;
    return {
        isReadOnly
    };
};

export const withReadOnlyWrapper = compose(
    connect(mapStateToProps, null),
    withReadOnlyWrapperComponent
);