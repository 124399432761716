export enum PreApprovalFields {
    ConventionalPreApproval = "Conventional",
    FhaPreApproval = "FHA",
    VaPreApproval = "VA",
    UsdaPreApproval = "USDA",
    PosMaxPurchasePrice = "posMaxPurchasePrice",
    PosAlterPurchasePriceIndicator = "posAlterPurchasePriceIndicator",
    LetterStyle = "letterStyle",
    LoanType = "loanType",
    isRequestedPreApproval = "isRequestedPreApproval",
    LoPreApprovalLetterComments = "loPreApprovalLetterComments"
};