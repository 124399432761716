import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccountCreationActions from "../../actions/accountCreationActions";
import * as AccountCreationStore from "../../store/accountCreationStore";
import Infographic from "../common/Infographic";
import formerAddressInfographic from "../../assets/infographics/accountCreation/former-address.svg";
import CustomYearMonthPicker from "../common/CustomYearMonthPicker";
import LocationSearch from "../common/Input/LocationSearch";
import styles from "./accountCreation.module.scss";
import _uniqueId from 'lodash/uniqueId';
import { bindActionCreators } from "redux";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { dateDiff, isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type AdditionalAddressProps =
    AccountCreationStore.AccountCreationState &
    {
        currentAddress: AccountCreationStore.Address,
        borrowerType: AccountCreationStore.BorrowerType,
        setComplete: React.Dispatch<React.SetStateAction<boolean>>,
        disabled: boolean
    } &
    typeof AccountCreationActions.accountCreationActionCreators;

const AdditionalAddress = (props: AdditionalAddressProps) => {
    let currentBorrower = props.borrowerType == AccountCreationStore.BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower;
    let additionalAddressList = currentBorrower?.addresses;

    const getAddressChecked = () => {
        let priorAddresses = props.coBorrower?.addresses?.filter(x => x.residencyType === "Prior");
        if (priorAddresses && priorAddresses?.length > 0) {
            if (priorAddresses[0].coBorrowerAddressChecked !== undefined) {
                return priorAddresses[0].coBorrowerAddressChecked;
            }
        }
        return false;
    }

    const [coBorrowerAddressChecked, toggle] = React.useState(getAddressChecked());
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        if (props.borrowerType == AccountCreationStore.BorrowerType.PrimaryBorrower) {
            props.setComplete((props.borrower && props.borrower.totalMonths && props.borrower.totalMonths > 23) ? true : false);
        }
        else {
            props.setComplete((props.coBorrower && props.coBorrower.totalMonths && props.coBorrower.totalMonths > 23) ? true : false);
        }
    }, [props.borrower.totalMonths, props.coBorrower.totalMonths]);

    const selectData = () => {
        let id = _uniqueId('Residence/');
        let data: AccountCreationStore.Address = {
            id: id,
            indexId: id,
            residencyType: "Prior",
            editMode: undefined,
            addressStreetLine1: "",
            addressCity: undefined,
            addressState: undefined,
            addressPostalCode: undefined,
            moveInYear: 0,
            moveInMonth: 0,
            durationTermYears: 0,
            durationTermMonths: 0,
            applicantType: "",
            mailingAddressIndicator: false
        };
        return data;
    }

    const selectPriorData = () => {
        let data = props.currentAddress;
        if (additionalAddressList && additionalAddressList.length == 0 && currentFormerAddress && currentFormerAddress.addressStreetLine1) {
            data = currentFormerAddress;
        }
        else if (additionalAddressList && additionalAddressList.length > 0) {
            let addresses = additionalAddressList.filter(x => x.residencyType !== null && x.addressStreetLine1 !== null);
            if (addresses.length > 0) {
                data = addresses[addresses.length - 1];
            }
        }
        return data;
    }

    const [errorMessage, updateErrorMessage] = React.useState("");
    const [editMode, updateEditMode] = React.useState(false);
    const [currentFormerAddress, updateCurrentFormerAddress] = React.useState(() => selectData());
    const [priorFormerAddress, updatePriorFormerAddress] = React.useState(() => selectPriorData());
    const coBorrowerAddressCheckedChange = (checked: any) => {
        toggle(checked);
        actions.setLoanIsDirty(true);
        props.SetAddressSameAsBorrower(AccountCreationStore.AddressType.FormerAddress, checked);
        resetAddress();
    }
    const [month, setMonth] = React.useState(0);
    const [year, setYear] = React.useState(0);

    const actions = {
        ...bindActionCreators({ ...TaskMenuCreators }, useDispatch())
    };

    React.useEffect(() => {
        updateCurrentFormerAddress(selectData());
    }, [props.borrowerType]);

    if (currentFormerAddress === undefined) {
        updateCurrentFormerAddress(selectData());
    }
    if (priorFormerAddress === undefined) {
        updatePriorFormerAddress(selectPriorData());
    }

    const handleDateChange = (month: number, year: number) => {
        currentFormerAddress.moveInMonth = month + 1;
        currentFormerAddress.moveInYear = year;
        setMonth(month);
        setYear(year);
    }

    const validateAdditionalAddress = (): boolean => {
        let isValid = true;
        if (currentFormerAddress === undefined ||
            isNullOrWhiteSpace(currentFormerAddress?.addressStreetLine1) ||
            isNullOrWhiteSpace(currentFormerAddress?.addressCity) ||
            isNullOrWhiteSpace(currentFormerAddress?.addressState) ||
            isNullOrWhiteSpace(currentFormerAddress?.addressPostalCode)
            || currentFormerAddress?.addressPostalCode === undefined || currentFormerAddress?.addressPostalCode?.length < 5) {
            isValid = false;
        }
        return isValid;
    };

    const addNewAddress = () => {
        let priorDate = new Date();
        let currentDate = new Date();
        if (priorFormerAddress && priorFormerAddress.moveInYear && priorFormerAddress.moveInMonth != undefined) {
            priorDate = new Date(priorFormerAddress.moveInYear ?? 0, priorFormerAddress.moveInMonth - 1);
        }
        if (currentFormerAddress && currentFormerAddress.moveInYear && currentFormerAddress.moveInMonth != undefined) {
            currentDate = new Date(currentFormerAddress.moveInYear ?? 0, currentFormerAddress.moveInMonth - 1);
        }
        if (!validateAdditionalAddress()) {
            updateErrorMessage("Please enter valid adddress");
        }
        else if ((currentFormerAddress.moveInYear == 0 && currentFormerAddress.moveInMonth == 0)
            || (currentFormerAddress.moveInYear == undefined && currentFormerAddress.moveInMonth == undefined)
            || currentDate >= priorDate) {
            updateErrorMessage("Please select valid date");
        }
        else {
            actions.setLoanIsDirty(true);
            updateErrorMessage("");
            updateEditMode(false);
            let durationTermYears = 0;
            let durationTermMonths = 0;

            if (currentFormerAddress &&
                priorFormerAddress &&
                currentFormerAddress.moveInMonth != undefined &&
                currentFormerAddress.moveInYear &&
                priorFormerAddress.moveInMonth != undefined &&
                priorFormerAddress.moveInYear
            ) {
                let currentAddressMoveIn = new Date(priorFormerAddress.moveInYear, priorFormerAddress.moveInMonth - 1);
                let previousAddressMoveIn = new Date(currentFormerAddress.moveInYear, currentFormerAddress.moveInMonth - 1);
                let totalMonths = dateDiff(previousAddressMoveIn, currentAddressMoveIn);
                durationTermYears = Math.floor(totalMonths / 12);
                durationTermMonths = totalMonths % 12;
            }

            let id = _uniqueId('Residence/');
            let data: AccountCreationStore.Address = {
                id: currentFormerAddress.editMode ? currentFormerAddress.id : id,
                indexId: currentFormerAddress.editMode ? currentFormerAddress.id : id,
                residencyType: "Prior",
                editMode: currentFormerAddress.editMode,
                addressStreetLine1: currentFormerAddress.addressStreetLine1,
                addressCity: currentFormerAddress.addressCity,
                addressState: currentFormerAddress.addressState,
                addressPostalCode: currentFormerAddress.addressPostalCode,
                moveInYear: currentFormerAddress.moveInYear,
                moveInMonth: currentFormerAddress.moveInMonth,
                durationTermYears: durationTermYears,
                durationTermMonths: durationTermMonths,
                applicantType: props.borrowerType == AccountCreationStore.BorrowerType.PrimaryBorrower ? "Borrower" : "CoBorrower",
                mailingAddressIndicator: false
            };
            if (data.editMode) {
                data.editMode = false;
                data.addressStreetLine1 = data.addressStreetLine1?.replace(data.addressPostalCode ?? '', '')
                props.EditAddressProperty(data, props.borrowerType);
                resetAddress();
            }
            else {
                data.editMode = false;
                data.addressStreetLine1 = data.addressStreetLine1?.replace(data.addressPostalCode ?? '', '')
                props.AddAddressProperty(data, props.borrowerType);
                resetAddress();
            }
        }
    }

    const deleteAddress = (id: string | undefined) => {
        actions.setLoanIsDirty(true);
        updateErrorMessage("");
        if (id !== undefined) {
            props.DeleteAddressProperty(id, props.borrowerType);
            if (priorFormerAddress.id == id && additionalAddressList) {
                const index = additionalAddressList?.findIndex(x => x.id == id);
                if (index ?? 0 > 0) {
                    updatePriorFormerAddress(additionalAddressList[index - 1]);
                }
                else {
                    updatePriorFormerAddress(props.currentAddress);
                }
            }
        }
        else {
            alert("something went wrong!");
        }
    }

    const editAddress = (id: string | undefined) => {
        actions.setLoanIsDirty(true);
        updateErrorMessage("");
        updateEditMode(true);
        if (id != undefined && additionalAddressList) {
            const index = additionalAddressList.findIndex(x => x.id == id);
            additionalAddressList[index].editMode = true;
            updateCurrentFormerAddress(additionalAddressList[index]);
            if (index ?? 0 > 0) {
                updatePriorFormerAddress(additionalAddressList[index - 1])
            }
            else {
                updatePriorFormerAddress(props.currentAddress)
            }
        }
        else {
            alert("something went wrong.")
        }
    }

    const addAddressFields = (value: string, name: string, borrowerType: any, customType: any) => {
        actions.setLoanIsDirty(true);
        if (name == "addressCity") {
            currentFormerAddress.addressCity = value;
        }
        if (name == "addressStreetLine1") {
            currentFormerAddress.addressStreetLine1 = value;
        }
        if (name == "addressPostalCode") {
            currentFormerAddress.addressStreetLine1 = currentFormerAddress.addressStreetLine1 + " " + value;
            currentFormerAddress.addressPostalCode = value;
        }
        if (name == "addressState") {
            currentFormerAddress.addressState = value;
        }
    }

    const resetAddressClick = () => {
        updateErrorMessage("");
        updateEditMode(false);
        updatePriorFormerAddress(selectPriorData());
        let id = _uniqueId('Residence/');
        let data1: AccountCreationStore.Address = {
            id: id,
            indexId: id,
            residencyType: "Prior",
            editMode: false,
            addressStreetLine1: "undefined",
            addressCity: "",
            addressState: "",
            addressPostalCode: "",
            moveInYear: 0,
            moveInMonth: 0,
            durationTermYears: undefined,
            durationTermMonths: undefined,
            applicantType: props.borrowerType == AccountCreationStore.BorrowerType.PrimaryBorrower ? "Borrower" : "CoBorrower",
            mailingAddressIndicator: false
        }
        updateCurrentFormerAddress(data1);
        setMonth(0);
        setYear(0);
    }


    const resetAddress = () => {
        updateErrorMessage("");
        updateEditMode(false);
        setTimeout(() => {
            updatePriorFormerAddress(selectPriorData());
            updateCurrentFormerAddress({ ...selectData });
        }, 100);
        setYear(0);
        setMonth(0);
    }

    const getMonth = (month: number) => {
        switch (month) {
            case 1:
                return 'January';
            case 2:
                return 'February';
            case 3:
                return 'March';
            case 4:
                return 'April';
            case 5:
                return 'May';
            case 6:
                return 'June';
            case 7:
                return 'July';
            case 8:
                return 'August';
            case 9:
                return 'September';
            case 10:
                return 'October';
            case 11:
                return 'November';
            case 12:
                return 'December';
            default:
                return '';
        }
    }

    return (
        <>
            <h1 className={styles.heading} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? "Additional Address Information" : "Co-Borrower Additional Address Information")}>
                {props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
                    "Additional Address Information" :
                    "Co-Borrower Additional Address Information"}
            </h1>
            <div className={`infographic-side-layout-container ${styles.addressConfirmation}`}>
                <div className="content">
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? "What is your former address?" : "What is your co-borrower's former address?")}>
                        {props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
                            "What is your former address?" :
                            "What is your co-borrower's former address?"}
                    </h2>

                    <div className={`${styles.inputGroup} ${styles.locationSearch} ${styles.AdditionalAddressLocation}`}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Prior to " + priorFormerAddress?.addressStreetLine1)}>{`(Prior to ${priorFormerAddress?.addressStreetLine1})`}</label>
                        <LocationSearch isDisabled={props.disabled} addressStreetLine1={currentFormerAddress?.addressStreetLine1 ?? ""}
                            addresssFieldName={"addressStreetLine1"}
                            borrowerType={props.borrowerType}
                            customType={"AdditionalAddress"}
                            setStoreAddress={addAddressFields}
                            styles={styles.input}
                        />
                    </div>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? "When did you move in?" : "When did your co-borrower move in?")}>
                        {props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
                            "When did you move in?" :
                            "When did your co-borrower move in?"}
                    </h2>
                    <div className={styles.addressMove}>
                    <CustomYearMonthPicker
                        format="MMM yyyy"
                        placeholderText="select date"
                        disabled={props.disabled}
                        fieldValue={month !== undefined && year !== undefined && year > 0 ? new Date(year, month) : undefined}
                        handleChange={handleDateChange}
                        popperPlacement={false}
                        maxDate={new Date()}
                        defaultYear={currentFormerAddress.moveInYear ?? 0}
                        defaultMonth={currentFormerAddress.moveInMonth ?? 0}
                        className={styles.addResetMonthYear}
                    />
                    <div className={styles.addResetBG}>
                        {
                            !editMode &&
                                <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to add address")} disabled={props.disabled} className="common-green-button" title="Add Address" onClick={(e: any) => { addNewAddress() }}><i className="fa fa-plus"></i></button>
                        }
                        {
                            editMode &&
                                <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to update address")} disabled={props.disabled} className="common-green-button" title="Update Address" onClick={(e: any) => { addNewAddress() }}><i className="fa fa-pencil-square-o"></i></button>
                        }
                            <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to reset address")} disabled={props.disabled} className="common-button" title="Reset Address" onClick={(e: any) => { resetAddressClick() }}><i className="fa fa-undo"></i></button>
                        </div>
                        </div>
                    <span style={{ color: "red" }}> {errorMessage}</span>
                </div>
                <div className={styles.infographicSidePanel}>
                    <div className={styles.formerAddressBG}>
                        {
                            props.borrowerType && props.borrowerType === AccountCreationStore.BorrowerType.CoBorrower && props.borrower.addresses &&
                            <div className={`${styles.inputGroupSmall} ${styles.coBorrowerCheckbox} ${styles.customCheckBoxAdditionalDiv}`}>
                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Same as borrower")} className={styles.customlabelAdditionalDiv}>Same as Borrower</label>
                                <label className={styles.customCheckBox + " " + styles.customCheckBoxAdditional}>
                                    <input disabled={props.disabled} type="checkbox"
                                        checked={coBorrowerAddressChecked}
                                        onChange={(event) => coBorrowerAddressCheckedChange(event.target.checked)} />
                                </label>
                            </div>
                        }
                        <div className={styles.totalMonth} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Total months")}>
                            Total months:
                            <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(currentBorrower.totalMonths)}>{currentBorrower.totalMonths}</span>
                        </div>
                        <div className={styles.tableBG}>

                            <div className={styles.tableRow}>
                                <div className={styles.addressColumn} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Address")}>Address</div>
                                <div className={styles.dateColumn} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Date")}>Date</div>
                                <div className={styles.buttonColumn}></div>
                                <div className={styles.buttonColumn}></div>
                            </div>
                            {
                                additionalAddressList?.filter(x => x.residencyType === "Prior")?.map(item => (
                                    <div className={styles.tableRow} key={item.id}>
                                        <div className={styles.addressColumn} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(item.addressStreetLine1 + " " + item.addressPostalCode)}> {item.addressStreetLine1 + " " + item.addressPostalCode}</div>
                                        <div className={styles.dateColumn} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(getMonth(item.moveInMonth ?? 0) + " " + item.moveInYear ?? "")}>{getMonth(item.moveInMonth ?? 0) + " " + item.moveInYear ?? ""}</div>
                                        <div className={styles.buttonColumn}>
                                            <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to edit")} disabled={props.disabled} title="Edit" onClick={(e: any) => { editAddress(item.id) }}><i className="fa fa-pencil-square-o"></i></button></div>
                                        <div className={styles.buttonColumn}>
                                            <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to delete")} disabled={props.disabled} title="Delete" onClick={(e: any) => { deleteAddress(item.id) }}><i className="fa fa-trash"></i></button></div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className={styles.formerAddressImages}>
                        <Infographic src={formerAddressInfographic} />
                    </div>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state: ApplicationState, ownProps: { borrowerType: AccountCreationStore.BorrowerType, setComplete: React.Dispatch<React.SetStateAction<boolean>>, disabled: React.Dispatch<React.SetStateAction<boolean>> }): any => {
    return state.accountCreation ? {
        ...state.accountCreation,
        currentAddress: ownProps.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? state.accountCreation.borrower.currentAddress : state.accountCreation.coBorrower?.currentAddress,
        borrowerType: ownProps.borrowerType,
        setComplete: ownProps.setComplete,
        disabled: ownProps.disabled
    } : state;
}

export default connect(
    mapStateToProps,
    AccountCreationActions.accountCreationActionCreators
)(React.memo(AdditionalAddress) as any) as any;