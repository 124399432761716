import * as React from "react";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as EmploymentInformationStore from "../../store/employmentInformationStore";
import * as EmploymentInformationActions from "../../actions/employmentInformationActions";
import { BorrowerType } from "../../store/employmentInformationStore";
import { ApplicationState } from "../../store";
import EmploymentItem from "./EmploymentItem";
import styles from "./employmentInformation.module.scss";
import { useHistory } from "react-router-dom";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import Loading from "../common/Loader";
import { bindActionCreators } from "redux";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { useInitialMount } from "../../hooks/useInitialMount";
import * as employmentInformationService from "../../services/employmentInformationService";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";

type EmploymentInformationProps = EmploymentInformationStore.EmploymentInformationState & {
    isLoanCompleted: boolean;
} & typeof EmploymentInformationActions.employmentInformationActionCreators;

const EmploymentInformation = (props: EmploymentInformationProps) => {
    const [currentBorrower, updateCurrentBorrower] = useState(BorrowerType.PrimaryBorrower);
    const [borrowerComplete, updateBorrowerComplete] = useState(false);
    const [coBorrowerComplete, updateCoBorrowerComplete] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);

    let history = useHistory();
    const isInitialMount = useInitialMount();

    const loanGuid = useSelector(
        (state: ApplicationState) => state.accountInformation?.loanGuid
    );
    const interimLoanStagingId = useSelector((state: ApplicationState) => state.loan?.interimLoanStagingId);

    React.useEffect(() => {
        if (isInitialMount && loanGuid) {

            props.getEmploymentInformation(BorrowerTypeEnum.CoBorrower, interimLoanStagingId??0);
            props.getEmploymentInformation(BorrowerTypeEnum.Borrower, interimLoanStagingId??0);
        }
    }, [isInitialMount]);

    const actions = {
        ...bindActionCreators({ ...TaskMenuCreators, ...loanUpdateResponseActionCreators }, useDispatch())
    };

    React.useEffect(() => {
        async function fetchEmpData() {
            if (history.location.state?.args === "coborrower") {
                updateCurrentBorrower(BorrowerType.CoBorrower);
                if (loanGuid && !isInitialMount) {
                    updateBorrowerComplete(false);
                    await props.getEmploymentInformation(BorrowerTypeEnum.CoBorrower, interimLoanStagingId??0);
                }
            }
            else if (history.location.state?.args === "borrower") {
                updateCurrentBorrower(BorrowerType.PrimaryBorrower);
                if (loanGuid && !isInitialMount) {
                    updateCoBorrowerComplete(false);
                    await props.getEmploymentInformation(BorrowerTypeEnum.Borrower, interimLoanStagingId??0);
                }
            }
        }
        fetchEmpData()
    }, [history.location]);

    useEffect(() => {
        if (borrowerComplete) {
            saveBorrowerEmployment();
        }
    }, [borrowerComplete]);

    useEffect(() => {
        if (coBorrowerComplete) {
            saveCoBorrowerEmployment();
        }
    }, [coBorrowerComplete]);

    const saveBorrowerEmployment = async () => {
        let response;
        if (!props.isLoanCompleted) {
            setLoading(true);
            response = await employmentInformationService.updateEmploymentInformation(props.borrower);
            let result = response.parsedBody as boolean;

            if (result) {
                    employmentInformationService.updateEmploymentQuestionnaireToInterimLoanStaging(props.borrower);
                    employmentInformationService.updateProgressTracker(props.borrower, props.hasCoBorrower);

                    actions.setLoanIsDirty(false);
                    setLoading(false);
                    if (!props.hasCoBorrower) {
                        history.push({ pathname: "/additional-income", state: { args: "borrower" } });
                    } else if (props.hasCoBorrower) {
                        history.replace({ state: { args: "coborrower" } });
                        updateCurrentBorrower(borrowerComplete && props.hasCoBorrower ? BorrowerType.CoBorrower : BorrowerType.PrimaryBorrower);
                    }
            }
        }
        else {
            props.SubmittedEmploymentInformation(BorrowerType.PrimaryBorrower);
            updateBorrowerComplete(false);
            actions.setLoanIsDirty(false);
            if (!props.hasCoBorrower) {
                history.push({ pathname: "/additional-income", state: { args: "borrower" } });
            } else if (props.hasCoBorrower) {
                history.replace({ state: { args: "coborrower" } });
                updateCurrentBorrower(borrowerComplete && props.hasCoBorrower ? BorrowerType.CoBorrower : BorrowerType.PrimaryBorrower);
            }
        }
    }

    const saveCoBorrowerEmployment = async () => {
        let response;
        if (!props.isLoanCompleted) {
            setLoading(true);
            response = await employmentInformationService.updateEmploymentInformation(props.coBorrower);
            let result = response.parsedBody as boolean;

            if (result) {
                employmentInformationService.updateEmploymentQuestionnaireToInterimLoanStaging(props.coBorrower);
                employmentInformationService.updateProgressTracker(props.coBorrower, props.hasCoBorrower);
                setLoading(false);
                props.SubmittedEmploymentInformation(BorrowerType.CoBorrower);
                updateCoBorrowerComplete(false);
                actions.setLoanIsDirty(false);
                history.push({ pathname: "/additional-income", state: { args: "borrower" } });
            }
        }
        else {
            setLoading(false);
            props.SubmittedEmploymentInformation(BorrowerType.CoBorrower);
            updateCoBorrowerComplete(false);
            actions.setLoanIsDirty(false);
            history.push({ pathname: "/additional-income", state: { args: "borrower" } });
        }
    }
    return (
        <>
            <div className={styles.w100}>
                {
                    !coBorrowerComplete &&
                    <EmploymentItem
                        borrowerType={currentBorrower}
                        borrowerData={currentBorrower == BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower}
                        updateCurrentBorrower={updateCurrentBorrower}
                        updateBorrowerComplete={updateBorrowerComplete}
                        updateCoBorrowerComplete={updateCoBorrowerComplete}
                        disabled={props.isLoanCompleted}
                        isEncompassDown={isEncompassDown}
                    >
                    </EmploymentItem>
                }
                {isLoading && <div className="screenLoader"><Loading /></div>}

            </div>
        </>
    );
}

const mapStateToProps = (state: ApplicationState): any => {
    let accountCreation = state.accountCreation;
    let employmentInformation = state.employmentInformation;
    let borrower = state.employmentInformation?.borrower;
    let hasCoBorrower = false;
    let coBorrower = state.employmentInformation?.coBorrower;
    let accountInformation = state.accountInformation;
    let isLoanCompleted = state.loan?.isLoanCompleted;

    if (borrower && coBorrower && accountInformation) {
        borrower.loanGuid = accountInformation?.loanGuid;
        borrower.borrowerType = BorrowerType.PrimaryBorrower;
        coBorrower.loanGuid = accountInformation?.loanGuid;
        coBorrower.borrowerType = BorrowerType.CoBorrower;
        if (accountCreation && accountCreation.borrower && accountCreation.borrower.firstName) {
            borrower.name = accountCreation.borrower.firstName + ' ' + accountCreation?.borrower?.lastName ?? '';
        }
        if (accountCreation && accountCreation.coBorrower && accountCreation.coBorrower.firstName) {
            coBorrower.name = accountCreation.coBorrower.firstName + ' ' + accountCreation?.coBorrower?.lastName ?? '';
            hasCoBorrower = accountCreation?.coBorrower?.firstName != undefined ? true : false;
        }
        state.employmentInformation = employmentInformation;
    }
    return {
        borrower,
        coBorrower,
        hasCoBorrower,
        isLoanCompleted
    };
}

export default connect(
    mapStateToProps,
    EmploymentInformationActions.employmentInformationActionCreators
)(React.memo(EmploymentInformation) as any);