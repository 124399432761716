import React, { useEffect, useState } from "react";
import styles from "./LoanOfficerDocument.module.scss";
import DocumentGroup from "./DocumentGroup";
import { DocumentSectionDto } from "../models/DocumentSectionDto";
import { DocumentSectionStatus } from "../models/DocumentSectionStatus";
import { DocumentStatus } from "../models/DocumentStatus";
import { from, IGrouping } from "linq";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { getSectionsByLoanguid, removeSection, updateDocumentStatus } from "services/documentService";
import { Link, useHistory } from "react-router-dom";
import { useDocumentActions } from "actions/documentActions";
import { useNavigation } from "hooks/useNavigation";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { isNullOrUndefined } from "util";

const LoanOfficerDocuments = () => {
    const [sections, setSections] = useState<DocumentSectionDto[]>([]);
    const [selectedSections, setSelectedSections] = useState<DocumentSectionDto[]>([]);
    const [groupings, setGroupings] = useState<IGrouping<string | null | undefined, DocumentSectionDto>[]>([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isDisable, setIsDisable] = React.useState(true);
    const actions = useDocumentActions();
    const history = useHistory();
    const navigation = useNavigation(() => {
        navigation.HideNavigation()
    });
    const [splitUpSections, setSplitUpSections] = useState({
        requested: [] as DocumentSectionDto[],
        outstanding: [] as DocumentSectionDto[],
    });

    const loanGuid = useSelector(
        (state: ApplicationState) => state.loanOfficerMainTab?.loanGuid ?? ""
    );

    const selectedTab = useSelector(
        (state: ApplicationState) => state.document?.selectedTab
    );

    const navigateRoute = () => {
        history.push({ pathname: "/add-document", state: { args: "loanOfficer" } });
    }
    useEffect(() => {
        getData();
    }, [loanGuid]);

    const getData = async () => {
        if (loanGuid) {
            setShowSpinner(true);
            setSections([]);
            var result = await getSectionsByLoanguid(loanGuid);
            setShowSpinner(false);
            if (result.ok && !result.parsedBody?.hasError) {
                setSections(result.parsedBody?.body ?? []);
            }
        }
    };

    const callRemoveSection = async (section: DocumentSectionDto) => {
        setShowSpinner(true);
        setSections([]);
        await removeSection({
            loanGuid: loanGuid,
            sectionId: section.documentSectionId ?? -1,
        });
        getData();
    };

    useEffect(() => {
        setSplitUpSections({
            requested: from(sections)
                .where((x) => !x.isOutstanding && (x.status === DocumentSectionStatus.Accepted ||
                    x.status === DocumentSectionStatus.Requested ||
                    x.status === DocumentSectionStatus.Submitted))
                .toArray(),
            outstanding: from(sections)
                .where((x) => x.isOutstanding ||
                    x.status === DocumentSectionStatus.Rejected ||
                    x.status === DocumentSectionStatus.Outstanding)
                .toArray()
        });
    }, [sections]);

    useEffect(() => {
        if (splitUpSections) {
            if (selectedTab === 0) {
                setSelectedSections(splitUpSections.requested);
            } else if (selectedTab === 1) {
                setSelectedSections(splitUpSections.outstanding);
            }
        }
    }, [splitUpSections, selectedTab]);

    useEffect(() => {
        const categoryGroupings = from(selectedSections)
            .groupBy((x) => x.category)
            .toArray();
        setGroupings(categoryGroupings);
    }, [selectedSections]);

    const isActiveStyle = (index: number): string => {
        return selectedTab == index ? styles.activeTab : "";
    };

    const toggleModle = () => {
        setIsOpen(!isOpen);
    }

    const approveAllDocument = async () => {
        if (sections && sections.length > 0) {
            setShowSpinner(true);
            let i = 0;
            sections.map(async (section, i) => {
                i++; //increment i until loop completed.
                section.documents?.filter(y => y.status == DocumentStatus.Submitted).map(async (document, j) => {
                    document.status = DocumentStatus.Accepted;
                    await updateStatus(document);
                });
                if (i === sections.length) {
                    setShowSpinner(false);
                    setIsOpen(false);
                    setIsDisable(true);
                    setTimeout(async () => {
                        await getData();
                    }, 1000);
                }
            })
        }
    }

    const updateStatus = async (doc: any) => {
        if (!isNullOrUndefined(doc)) {
            var result = await updateDocumentStatus({
                comment: "",
                documentId: doc.documentId,
                loanGuid: loanGuid,
                status: doc.status,
            });
        }
    };
    return (
        <div>
            <>
                <Modal className={styles.modal} isOpen={isOpen} >
                    <ModalHeader className={styles.modalHeader} toggle={toggleModle}>
                        <div>
                            <h2>Are you sure you want to accept all documents?</h2>
                        </div>
                    </ModalHeader>
                    <ModalBody className={styles.modalBody}>
                        <div className={styles.content}>
                            <button className={styles.button}
                                onClick={approveAllDocument}>Ok</button>
                            <button className={styles.button}
                                onClick={toggleModle}>Cancel </button>
                        </div>
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </Modal>

            </>
            <div className={styles.tabList}>
                <a className={isActiveStyle(0)} onClick={() => actions.AlterSelectedTab(0)}>
                    Requested ({splitUpSections.requested.length})
                </a>
                <a className={isActiveStyle(1)} onClick={() => actions.AlterSelectedTab(1)}>
                    Outstanding ({splitUpSections.outstanding.length})
                </a>
                {
                    selectedTab === 0 && (sections.filter(x => x.title === "Credit Authorization").length > 0 ?
                        sections.filter(x => x.status == DocumentSectionStatus.Submitted).length > 1 :
                        sections.filter(x => x.status == DocumentSectionStatus.Submitted).length > 0) ?
                        <a className={isActiveStyle(1)} onClick={() => setIsOpen(isDisable)} >
                            Accept All Documents
                    </a> :
                        <a className={isDisable ? styles.disabledLink : ""} onClick={() => setIsOpen(isDisable)} >
                            Accept All Documents
                    </a>
                }

                <a onClick={() => navigateRoute()} className={styles.link}>
                    <i className={`fa fa-plus-circle`} /> Add Another Document
                </a>
            </div>
            <>{showSpinner && <span className="loader medium"></span>}</>
            <div className={styles.documentGroupingContainer}>
                {groupings?.map((value) => {
                    return (
                        <DocumentGroup
                            key={value.key()}
                            category={value.key()}
                            sections={value.toArray()}
                            onRemoveSectionClick={(section) => callRemoveSection(section)}
                        ></DocumentGroup>
                    );
                })}
            </div>
        </div>
    );
};

export default LoanOfficerDocuments;