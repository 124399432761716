import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { useInitialMount } from "./useInitialMount";
import * as ActionTypes from "../common/ActionTypes";
import { useState, useEffect } from "react";

export interface NavigationActions {
    /**
     * Removes the back button onClick method. This will restore the back button logic to execute the browser back button action when clicked
     */
    ClearBackOnClick: () => void;
    /**
     * Sets the Continue button back to its initial hidden state
     */
    ResetContinue: () => void;
    /**
     * Resets both the back and continue button back to their inital states. This includes removing the back button onclick method
     */
    ResetNavigation: () => void;
    /**
     * Makes the Continue button visible, binds its onClick method, and optionally sets its disabled property. By default, the button will be enabled.
     */
    ShowContinue: (onClick: () => any, isDisabled?: boolean) => void;
    /**
     * Binds the method passed as a paramter to the back button onClick event. Calling this will prevent the back button from performing the browser back button action unless no parameter is passed. If no parameter is passed, then the default brower action will be performed when back clicked.
     */
    SetBackOnClick: (onClick?: () => any) => void;
    /**
     * Alters the disabled property of the continue button
     */
    SetContinueDisabled: (isDisabled: boolean) => void;
    /**
     * This value will be updated after reset occurs because of a route change. This value will be `undefined` from when the component first renders until that reset occurs
     */
    OnResetFromRoute: any;
    /**
     * Removes click handlers and hides both the back and continue button
     */
    HideNavigation: () => void;
    /**
     * Rename back and continue button
     */
    RenameNavigation: (forwardString: string, backwardString: string) => void;
}
/**
 * Hook to enable modification of global Navigation buttons
 * @param onInitialized A Function that will only be executed the first time a route changes occurs
 */

export function useNavigation(onInitialized?: () => void): NavigationActions {
    const dispatch = useDispatch();
    const isInitialMount = useInitialMount();
    const [onResetFromRoute, setOnReset] = useState<Date | undefined>(undefined);
    const [hasCalledInit, setHasCalledInit] = useState(false);

    const lastResetFromRoute = useSelector(
        (state: ApplicationState) => state.navigation?.lastResetFromRoute
    );
    useEffect(() => {
        // Prevents emitting a value change with the previous last reset time when the component first renders
        if (!isInitialMount) {
            setOnReset(lastResetFromRoute);
        }
    }, [isInitialMount, lastResetFromRoute]);

    useEffect(() => {
        if (onInitialized && onResetFromRoute && !hasCalledInit) {
            onInitialized();
            setHasCalledInit(true);
        }
    }, [onResetFromRoute]);

    var actions: NavigationActions = {
        ClearBackOnClick: () => {
            dispatch({
                type: ActionTypes.SetBackOnClick,
                onBackClick: null,
            });
        },
        ResetContinue: () => {
            dispatch({
                type: ActionTypes.ResetContinue,
            });
        },
        ResetNavigation: () => {
            dispatch({
                type: ActionTypes.ResetNavigation,
            });
        },
        SetBackOnClick: (onClick?: () => any) => {
            dispatch({
                type: ActionTypes.SetBackOnClick,
                onBackClick: onClick,
            });
        },
        SetContinueDisabled: (isDisabled: boolean) => {
            dispatch({
                type: ActionTypes.SetContinueDisabled,
                isDisabled: isDisabled,
            });
        },
        ShowContinue: (onClick: () => any, isDisabled?: boolean) => {
            dispatch({
                type: ActionTypes.ShowContinue,
                onClick: onClick,
                isDisabled: isDisabled,
            });
        },
        OnResetFromRoute: onResetFromRoute,
        HideNavigation: () => {
            dispatch({
                type: ActionTypes.HideNavigation,
            });
        },
        RenameNavigation: (forwardString: string, backwardString: string) => {
            dispatch({
                type: ActionTypes.RenameNavigation,
                forwardString: forwardString,
                backwardString: backwardString
            });
        },
    };
    return actions;
}