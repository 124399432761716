import * as React from "react";
import { connect } from "react-redux";
import TextInput from "../common/Input/TextInput";
import styles from "./reviewApplication.module.scss";
import { ApplicationState } from "../../store/index";
import * as BorrowerReoStore from "../../store/borrowerReoStore";
import * as ReviewApplicationActions from "../../actions/reviewApplicationActions";
import * as BorrowerReoActions from "../../actions/borrowerReoActions";
import SelectDropDown from "../common/Input/SelectDropDown";
import LocationSearch from "components/common/Input/LocationSearch";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import Currency from "../common/Currency";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ReviewReoInformationProps = {
    isEdit: boolean;
    reoData: BorrowerReoStore.ReoProperty;
    setReoValid: () => any;
    disabled: boolean;
}
    & BorrowerReoStore.BorrowerReoState
    & typeof ReviewApplicationActions.reviewApplicationActionCreators
    & typeof BorrowerReoActions.BorrowerReoActionCreators
    & typeof TaskMenuCreators;

const ReviewReoInformation = (props: ReviewReoInformationProps) => {
    const [zipFormat, setZipFormat] = React.useState("#########");
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        if (props.reoData?.zip &&
            props.reoData?.zip?.length > 5) {
            setZipFormat("#####-####");
            return;
        }
        setZipFormat("#########");
    }, [props.reoData.zip]);

    const setAddress = (streetValue: string, cityValue: string, stateValue: any, zipValue: any, id: string) => {
        props.updateReoAddressField(streetValue, cityValue, stateValue, zipValue, id);
    };

    const returnBorrowerTypeEnum = (param: any) => {
        switch (param) {
            case BorrowerTypeEnum.CoBorrower:
                return 'Co-Borrower';
            default:
                return param;
        }
    }

    return (
        <span className={styles.Seprate} style={{ width: "100%" }}>
            <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Property Occupancy')}>Property Occupancy <span className={styles.requiredMsg}> * </span>:</label>
                {!props.isEdit ?
                    <div className={styles.inputContainer}>

                        <SelectDropDown
                            disabled={props.disabled}
                            defaultValue={props.reoData.occupancyType || ""}
                            name="Occupancy"
                            id="#occupancyType"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                props.updateReoInformationField(e.target.value, "occupancyType", props.reoData.id);
                                props.setReoValid();
                            }}
                            noArrow={false}
                            dropdownclass={styles.select}
                            readOnly={props.isEdit}
                            className={styles.selectDdl}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.occupancyType || "")}>
                            <option value="" disabled> Select.. </option>
                            <option value="Primary Residence">Primary Residence</option>
                            <option value="Second Home">Second Home</option>
                            <option value="Investment">Investment</option>
                        </SelectDropDown>
                    </div>
                    : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.occupancyType)}>{props.reoData.occupancyType}</label>
                }
            </div>

            <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Owned by')}>Owned by <span className={styles.requiredMsg}> * </span>:</label>
                {!props.isEdit ?
                    <div className={styles.inputContainer}>

                        <SelectDropDown
                            disabled={props.disabled}
                            defaultValue={props.reoData.owner || ""}
                            name="Owner"
                            id="owner"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                props.updateReoInformationField(e.target.value, "owner", props.reoData.id);
                                props.setReoValid();
                            }}
                            noArrow={false}
                            dropdownclass={styles.select}
                            readOnly={props.isEdit}
                            className={styles.selectDdl}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.owner || "")}
                        >
                            <option value="" disabled>Select..</option>
                            {Object.keys(BorrowerTypeEnum).map(key => (
                                <option key={key} value={key}>
                                    {returnBorrowerTypeEnum(key)}
                                </option>
                            ))}
                        </SelectDropDown>
                    </div>
                    : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(returnBorrowerTypeEnum(props.reoData.owner))}>{returnBorrowerTypeEnum(props.reoData.owner)}</label>}
            </div>

            <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Type of Property')}>Type of Property<span className={styles.requiredMsg}> * </span>:</label>
                {!props.isEdit ?
                    <div className={styles.inputContainer}>

                        <SelectDropDown
                            disabled={props.disabled}
                            className={styles.selectDdl}
                            defaultValue={props.reoData.propertyType || ""}
                            name="Property"
                            id="propertyType"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value !== "Multi-Unit") {
                                    props.reoData.noOfUnits = 0;
                                }
                                props.updateReoInformationField(e.target.value, "propertyType", props.reoData.id);
                                props.setReoValid();
                            }}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.propertyType || "")}
                            noArrow={false}
                            invalidclass={styles.invalidInput}
                            dropdownclass={styles.select}
                            readOnly={props.isEdit}
                        >
                            <option value="" disabled> Select.. </option>
                            <option value="Single Family">Single Family</option>
                            <option value="Condominium">Condominium</option>
                            <option value="Multi-Unit">Multi-Unit</option>
                        </SelectDropDown>
                    </div>
                    : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.propertyType)}>{props.reoData.propertyType}</label>
                }

            </div>

            {props.reoData.propertyType === "Multi-Unit" &&
                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Unit')}>Unit <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <NumberFormatInput
                                disabled={props.disabled}
                                name="noOfUnits"
                                className={styles.numberFormat}
                                value={props.reoData.noOfUnits ?? 0}
                                id="#noOfUnits"
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updateReoInformationField(Number(values.value), "noOfUnits", props.reoData.id);
                                    props.setReoValid();
                                }}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.noOfUnits ?? 0)} />
                        </div>
                    : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.noOfUnits)}>{props.reoData.noOfUnits}</label>
                    }
                </div>
            }

            {props.reoData.willContinueToOwnPropertyAfterTransaction &&
                <>
                    <div className={`${styles.inputGroup} ${styles.addressBox}`}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Address')}>Address <span className={styles.requiredMsg}> * </span>:</label>
                        {!props.isEdit ?
                            <div className={styles.inputContainer}>
                                <LocationSearch
                                    isDisabled={props.disabled}
                                    addressStreetLine1={props.reoData.street ?? ""}
                                    addresssFieldName={"street"}
                                    borrowerType={"Primary"}
                                    customType={"Custom"}
                                    styles={""}
                                    setFullAddress={(street, city, state, zip) => {
                                        setAddress(street, city, state, zip, props.reoData.id);
                                        props.setReoValid();
                                }}
                                />
                            </div>
                            :
                        <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.street)}>{props.reoData.street}</label>
                        }
                    </div>

                    <div className={styles.inputGroup}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City')}>City <span className={styles.requiredMsg}> * </span>:</label>
                        <TextInput
                            disabled={props.disabled}
                            className={styles.input}
                            name="City"
                            onChange={(e: any) => {
                                props.updateReoInformationField(e.target.value, "city", props.reoData.id);
                                props.setReoValid();
                            }}
                            value={props.reoData.city || ""}
                            minLength="2"
                            maxLength="20"
                        readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.city || "")}/>
                    </div>

                    <div className={styles.inputGroup}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State <span className={styles.requiredMsg}> * </span>:</label>
                        <TextInput
                            disabled={props.disabled}
                            className={styles.input}
                            name="State"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                props.updateReoInformationField(e.target.value, "state", props.reoData.id);
                                props.setReoValid();
                            }}
                            value={props.reoData.state || ""}
                            minLength="2"
                            maxLength="2"
                        readOnly={props.isEdit} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.state || "")}/>
                    </div>

                    <div className={styles.inputGroup}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Zip')}>Zip <span className={styles.requiredMsg}> * </span>:</label>
                        {!props.isEdit ?
                            <div className={styles.inputContainer}>

                                <NumberFormatInput
                                    disabled={props.disabled}
                                    value={props.reoData.zip ?? ""}
                                    format={zipFormat}
                                    onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                        props.updateReoInformationField(values.value, "zip", props.reoData.id);
                                        props.setReoValid();
                                    }}
                                    className={styles.numberFormat}
                                    readOnly={props.isEdit}
                                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.zip ?? "")}/>
                            </div>
                        : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.zip)}>
                                {props.reoData.zip}
                            </label>
                        }
                    </div>
                </>
            }

            {props.reoData.willContinueToOwnPropertyAfterTransaction &&
                <div className={styles.inputGroup}>
                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Market Value')}>Market Value <span className={styles.requiredMsg}> * </span>:</label>
                    {!props.isEdit ?
                        <div className={styles.inputContainer}>

                            <NumberFormatInput
                                disabled={props.disabled}
                                name="presentMarketValue"
                                className={styles.numberFormat}
                                value={props.reoData.marketValue ?? ""}
                                id="#presentMarketValue"
                                onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                    props.updateReoInformationField(values.value, "marketValue", props.reoData.id);
                                    props.setReoValid();
                                }}
                                thousandSeparator={true}
                                prefix={"$"}
                            onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.reoData.marketValue ?? "")}/>
                        </div>
                        : <label className={styles.inputLabel}>
                            <Currency value={props.reoData.marketValue ? +props.reoData.marketValue : 0}></Currency>
                        </label>
                    }
                </div>
            }
        </span>
    );
};

const mapStateToProps = (state: ApplicationState, ownProps: {
    isEdit: boolean,
    reoData: BorrowerReoStore.ReoProperty, setReoValid: (isValid: boolean) => any,
    isLoanCompleted: boolean
}): any => {
    return state.borrowerReo ?
        {
            isEdit: ownProps.isEdit,
            reoData: ownProps.reoData,
            setReoValid: ownProps.setReoValid,
            disabled: ownProps.isLoanCompleted,
            ...state.borrowerReo
        } : state;
}


const mapDispatchToProps = (dispatch: any) => ({
    updateReoInformationField: (value: any, field: string, id: string) => {
        dispatch(BorrowerReoActions.BorrowerReoActionCreators.updateReoInformationField(value, field, id));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    updateReoAddressField: (streetValue: string, cityValue: string, stateValue: any, zipValue: any, id: string) => {
        dispatch(BorrowerReoActions.BorrowerReoActionCreators.updateReoAddressField(streetValue, cityValue, stateValue, zipValue, id));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewReoInformation as any);