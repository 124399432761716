import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import FooterText from "./FooterText";
import UhmLogo from "assets/logo/uhm-logo.png";
import styles from "./MainLayout.module.scss";
import Navigation from "../Buttons/Navigation";
import { ApplicationState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { NavigationState } from "store/navigationStore";
import * as ActionTypes from "common/ActionTypes";
import TaskMenu from "../progressTracker/TaskMenu";
import NotificationMessages from "components/common/progressTracker/NotificationMessages";
import { bindActionCreators } from "redux";
import { TaskMenuCreators } from "../../../actions/taskMenuAction";
import * as accountInformationService from "../../../services/accountInformationService";
import "react-circular-progressbar/dist/styles.css";
import * as taskMenuService from "../../../services/taskMenuService";
import { TaskStatusId, TaskItem } from "../progressTracker/TaskStatus";
import { isNullOrWhiteSpace } from "../../../common/helper/formatHelper";
import { useOktaAuth } from "@okta/okta-react";
import { appConfigAsync, oktaConfigAsync } from "authConfig";
import SessionWatcher from "../../../SessionWatcher";
import { loanState } from "../../../store/loanStore";
import { dashboardActionCreators } from "../../../actions/dashboardActions";
import CalculatorIcon from "assets/infographics/userAccount/calculator-icon.svg";
import Loading from "../Loader";
import { LoanSummaryState, InterimLoanSummary } from "../../../store/interimLoanSummaryStore";
import { interimLoanSummaryActionCreators } from "../../../actions/interimLoanSummaryActions";

type IMainLayoutProps = {
    children?: React.ReactNode;
    loanGuid: string;
    firstName: string;
    lastName: string;
    hasConsent: boolean;
    secured: boolean;
    borrowerType: string;
    messages: [];
    interimLoanStagingId: number;
    interimLoanSummary: InterimLoanSummary[]
}
    & typeof dashboardActionCreators
    & typeof interimLoanSummaryActionCreators;

const MainLayout = (props: IMainLayoutProps) => {
    const [isNavigationClicked, setNavigationClicked] = React.useState(false);
    const [showUserInfo, setShowUserInfo] = React.useState(false);
    const [showNotifications, setShowNotifications] = React.useState(false);
    const [url, setUrl] = React.useState("");
    const navigation = useSelector((state: ApplicationState) => state.navigation) as NavigationState;
    const [percentage, setPercentage] = React.useState(0);
    const [totalItems, setTotalItems] = React.useState({ completedItems: 0, totalItems: 0 });
    const [userProfileUrl, setUserProfileUrl] = React.useState("");

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { authState, oktaAuth } = useOktaAuth();
    const isUserAuthenticated = authState && authState.isAuthenticated;
    const { isLoanFromEncompassFlag, isLoanCompleted } = useSelector((state: ApplicationState) => state.loan as loanState);
    const [isLoading, setLoading] = React.useState(false);
    const actions = {
        ...bindActionCreators({ ...TaskMenuCreators }, useDispatch())
    };

    const logout = async () => {
        setLoading(true);
        if (!isNullOrWhiteSpace(props.loanGuid)) {
            await accountInformationService.signOut(props.borrowerType, props.loanGuid, props.interimLoanStagingId)
                .then((response: any) => {
                    let result = response as any;
                    setLoading(false);
                }).catch((error: Error) => {
                    console.error("Error occurred during sign out ", error);
                    setLoading(false);
                });
        }
        history.push("/");
        window.location.reload();
    }

    const toggleShowUserInfo = () => {
        setShowUserInfo(!showUserInfo);
    }

    const handleClickOutside = () => {

        if (showUserInfo) {
            setShowUserInfo(false);
        }

        if (showNotifications) {
            setShowNotifications(false);
        }

        if (isNavigationClicked) {
            document.body.classList.remove("menu-open");
            setNavigationClicked(false);
        }
    }

    useEffect(() => {
        oktaConfigAsync().then((config: any) => {
            var profileUrl = config.issuer.replace("/oauth2/default", "/enduser/settings");
            setUserProfileUrl(profileUrl);
        });
        appConfigAsync().then(async (config: any) => {
            setUrl(config?.PosConstant?.OpenRefinanceCalculator)
        });
    }, []);

    const openUserProfile = () => {
        window.open(userProfileUrl, "_blank", "noopener");
    }

    const showNavigationAndHeaders = props.loanGuid !== undefined &&
        props.loanGuid !== "" &&
        location.pathname !== "/" &&
        location.pathname !== "/account-information" &&
        location.pathname !== "/econsent" &&
        location.pathname !== "/sign-in";

    const isMultipleLoanExist = props.loanGuid !== undefined && location.pathname === "/loan-view";


    useEffect(() => {
        dispatch({ type: ActionTypes.ResetNavigationFromRoute });

        //block call for Multiple Loan Application  for 'loan-view' route
        if (showNavigationAndHeaders && !isMultipleLoanExist && props.loanGuid) {
            taskMenuService.getSubTaskMenuLoanStatus(props.loanGuid)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("There was a problem while fetching progress tracker information");
                    }
                    if (response.parsedBody) {
                        const tasks = response.parsedBody.filter((x: { taskItemId: any; }) => x.taskItemId !== TaskItem.AccountInformation);
                        const completedTasks = tasks.filter((x: { taskStatusId: TaskStatusId; }) => x.taskStatusId === TaskStatusId.Completed);
                        const percentageCalc = ((completedTasks.length / tasks.length) * 100);
                        if (percentageCalc) {
                            if (isLoanFromEncompassFlag && isLoanCompleted) {
                                setPercentage(100);
                            }
                            else {
                                let loanPercentage = Math.round(percentageCalc);

                                if (loanPercentage != undefined) {
                                    setPercentage(loanPercentage);
                                } else {
                                    setPercentage(0);
                                }
                            }
                        }
                        else {
                            setPercentage(0);
                        }

                        if (isLoanFromEncompassFlag && isLoanCompleted) {
                            setTotalItems((state) => ({ ...state, completedItems: tasks?.length }));

                        }
                        else {
                            setTotalItems({ completedItems: completedTasks?.length, totalItems: tasks?.length });
                        }
                    }
                });

            props.getUserNotificationMessages(props.loanGuid);
        }
    }, [props.loanGuid, location]);

    const goHome = () => {
        actions.setLoanIsDirty(false);
        if (location.pathname !== "/") {
            history.push({ pathname: "/" });
        }
    }

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    }

    const openModal = () => {
        document.body.classList.add("menu-open");
        setNavigationClicked(true);
    }
    const openRefinanceCalculator = () => {
        window.open(url)
    }

    const isMultipleLoanPage = location.pathname.indexOf("loan-view") > -1 ? true : false;

    useEffect(() => {
        if (props.interimLoanSummary.length < 1 && props.secured) {
            logout();
        }
    }, [props.loanGuid]);

    return (
        <>
            { isLoading ? <Loading /> : <div onClick={handleClickOutside}
                className={`${styles.appContainer} ${showNavigationAndHeaders ? styles.afterLogin : ""}`}
            >

                {!isUserAuthenticated && props.secured && goHome}

                <TaskMenu isNavigationClicked={isNavigationClicked} setNavigationClicked={setNavigationClicked} />

                <div className={`${styles.topHeader} ${isMultipleLoanPage ? styles.loanViewHeader : ""} ${!showNavigationAndHeaders ? styles.defaultTopHeader : ""}`}>
                    {showNavigationAndHeaders &&
                        <>
                            {<button onClick={openModal} className={`${styles.menuButton} ${isMultipleLoanExist ? styles.menuVisibility : ""}`}>
                                <span></span>
                            </button>
                            }
                        </>
                    }

                    <div className={styles.logo}>
                            <img src={UhmLogo} alt="Union Home Mortgage" />
                    </div>

                    {showNavigationAndHeaders &&
                        <>
                            <SessionWatcher borrowerType={props.borrowerType} loanGuid={props.loanGuid} interimLoanStagingId={props.interimLoanStagingId} />

                            {!isMultipleLoanExist && <div className={styles.headerProgress}>
                                <div className={styles.progressBox}>
                                    <h5>{percentage}% Complete</h5>
                                    <p>Completed {totalItems.completedItems} of {totalItems.totalItems}</p>
                                </div>

                                <div className={styles.progressDiv}>
                                    <div style={{ width: `${percentage}%` }} className={styles.progress} />
                                </div>

                            </div>}

                            <ul>
                                {!isMultipleLoanExist && <li>
                                    <img src={CalculatorIcon} style={{ cursor: "pointer" }} onClick={openRefinanceCalculator} title="Mortgage Calculators" />
                                </li>}


                                <li>
                                    <div className={styles.profileDetail}>
                                        {!isMultipleLoanExist && <>
                                            {props.messages?.length > 0
                                                ? <i className={`fa fa-bell ${styles.notificationList}`} onClick={toggleNotifications}></i>
                                                : <i className={`fa fa-bell ${styles.noNotification}`} onClick={toggleNotifications}></i>
                                            }
                                        </>}

                                        <span>{props?.firstName} {props?.lastName}</span>
                                        <i className="fa fa-ellipsis-v" style={{ cursor: "pointer" }} onClick={toggleShowUserInfo}></i>
                                    </div>

                                    {showNotifications &&
                                        <div className={styles.dropDownMenu} >
                                            <NotificationMessages messages={props.messages} />
                                        </div>
                                    }

                                    {showUserInfo &&
                                        <div className={styles.dropDownMenu} >
                                            <div className="align-center">
                                                <a className={styles.signout} onClick={openUserProfile}>User Profile</a>
                                                <a className={styles.signout} onClick={logout}>Sign Out</a>
                                            </div>
                                        </div>
                                    }
                                </li>

                            </ul>
                        </>
                    }
                </div>

                <div className={styles.borderedBackground}>
                    <div className={styles.contentContainer}>
                        {props.children}
                    </div>
                    <Navigation
                        isMultipleLoan={props.interimLoanSummary?.length > 1 ? true : false}
                        back={{
                            show: navigation.back.show,
                            disabled: navigation.back.disabled,
                            message: navigation.back.message,
                            onClick: () => {
                                if (navigation.back.onClick) {
                                    navigation.back.onClick();
                                } else {
                                    history.goBack();
                                }
                            },
                        }}
                        continue={{
                            show: navigation.continue.show,
                            disabled: navigation.continue.disabled,
                            message: navigation.continue.message,
                            onClick: () => {
                                if (navigation.continue.onClick) {
                                    navigation.continue.onClick();
                                }
                            },
                        }} />
                </div>
                <div className={styles.footer}>
                    <FooterText />
                </div>
            </div>
            }
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    getUserNotificationMessages: (loanGuid: string) => {
        dispatch(dashboardActionCreators.getUserNotificationMessages(loanGuid));
    }
});

function mapStateToProps(state: ApplicationState) {
    const loanGuid = state.accountInformation?.loanGuid;
    const firstName = state.userAccountInformation?.firstName;
    const lastName = state.userAccountInformation?.lastName;
    const borrowerType = state.userAccountInformation?.borrowerType;
    const hasConsent = state.userAccountInformation?.hasConsent;
    const messages = state.dashboard?.data;
    const interimLoanStagingId = state.loan?.interimLoanStagingId;
    const interimLoanSummary = state.interimLoanSummary?.loanSummary;
    return {
        loanGuid,
        firstName,
        lastName,
        hasConsent,
        borrowerType,
        messages,
        interimLoanSummary,
        interimLoanStagingId
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout as any) as any;