import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { loanState, KnownAction } from "../store/loanStore";

const unloadedState: loanState = {
    loanGuid: undefined,
    isLoanCompleted: false,
    isLoanFromEncompassFlag: false,
    interimLoanStagingId: undefined

}

export const loanReducer: Reducer<loanState> = (state: loanState | undefined, incomingAction: Action): loanState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.SetLoanGuid:
            return {
                ...state,
                loanGuid: action.loanGuid
            };
        case ActionTypes.SetIsLoanCompleted:
            return {
                ...state,
                isLoanCompleted: action.isLoanCompleted
            };
        case ActionTypes.SetIsLoanFromEncompassFlag:
            return {
                ...state,
                isLoanFromEncompassFlag: action.isLoanFromEncompassFlag
            };
        case ActionTypes.SetInterimLoanStagingId:
            return {
                ...state,
                interimLoanStagingId: action.interimLoanStagingId
            };
        default:
            return state;
    }
}