import * as React from "react";
import SelectDropDown from "../common/Input/SelectDropDown";
import * as LoanOfficerMainTabActions from "../../actions/loanOfficerMainTabActions";
import * as LoanOfficerMainTabStore from "../../store/LoanOfficerMainTabStore";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { ValidationMessage } from "../constants/validation";
import styles from "./LoanOfficer.module.scss";
import * as userAcountActions from "../../actions/userAcount";
import * as accountInformationActions from "../../actions/accountInformationActions";
import { isNullOrUndefined } from "util";
import * as loanOfficerMainTabService from "../.././services/loanOfficerMainTabService";
import { PreApprovalFields } from "./PreApprovalLetterFields";
import Loading from "../common/Loader";
import moment from 'moment';
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { NumberFormatValues } from "react-number-format";

type LoanOfficerMainTabProps = LoanOfficerMainTabStore.LoanOfficerMainTabState &
{
    loanGuid: string;
} &
    typeof LoanOfficerMainTabActions.loanOfficerMainTabActionCreators &
    typeof userAcountActions.userAcountActionCreators &
    typeof accountInformationActions.accountInformationActionCreators;

const PreApprovalLetter = (props: LoanOfficerMainTabProps) => {
    const [disabled, setDisabled] = React.useState(false);
    const [list, setList] = React.useState<LoanOfficerMainTabStore.PreApproval[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [change, setIschange] = React.useState(false);


    const [commentChar, setCommentChar] = React.useState({
        chars_left: 0,
        max_char: 225
    });

    const handleCommentCharCount = (comment: string) => {
        const charCount = comment.length;
        const charLength = charCount;
        setCommentChar({ ...commentChar, chars_left: charLength });
        updateComment(comment);
    }

    const updateComment = (
        (comment: string) => {
            props.updatePreApproval(comment, PreApprovalFields.LoPreApprovalLetterComments, PreApprovalFields.LoPreApprovalLetterComments);
            setIschange(true);
        });

    const loanTypeHandler = (checked: string, name: string, section: string) => {
        props.updatePreApproval(checked, name, section);
        setIschange(true);
    }

    const submit = async () => {
        props.preApproval.isRequestedPreApproval = false;
        props.updatePreApproval(props.preApproval.isRequestedPreApproval, "isRequestedPreApproval", "isRequestedPreApproval")
        setIsLoading(true);
        await loanOfficerMainTabService.UncheckInactivePreApprovalLettersIntoEncompass(props.loanGuid);
        await loanOfficerMainTabService.CreatePreApprovalLetterAsync(props.preApproval, props.loanGuid).then((response: any) => {
            setIsLoading(false)
            if (!response.ok) {
                throw new Error("There was a problem updating CreatePreApprovalLetterAsync.");
            }
            let result = response.parsedBody;
            return result
        }).then(async (result: any) => {
            setIsLoading(true)
            await loanOfficerMainTabService.GeneratePreApprovalLetterAndUploadToEncompassAsync
                (props?.preApproval?.letterStyle ?? "", props.loanGuid, result).then((res: any) => {
                    setIsLoading(false)
                    if (res.parsedBody) {
                        console.log("asas");
                    }
                });

            ReadPreApprovalLetters();
        })
    }
    React.useEffect(() => {
        ReadPreApprovalLetters();
    }, []);

    React.useEffect(() => {
        if (props.preApproval.isRequestedPreApproval) {
            loanOfficerMainTabService.UncheckInactivePreApprovalLettersIntoEncompass(props.loanGuid);
            props.updatePreApproval("", PreApprovalFields.LetterStyle, PreApprovalFields.LetterStyle);
            props.updatePreApproval(undefined, PreApprovalFields.LoanType, PreApprovalFields.LoanType);
            props.updatePreApproval(undefined, PreApprovalFields.PosAlterPurchasePriceIndicator, PreApprovalFields.PosAlterPurchasePriceIndicator);

        }
    }, [props.preApproval.isRequestedPreApproval]);


    const ReadPreApprovalLetters = () => {
        setIschange(false)
        loanOfficerMainTabService.ReadPreApprovalLettersByLoanGuidAsync(props.loanGuid ?? "").then((res: any) => {
            if (res.status == 200) {
                const data = res.parsedBody as LoanOfficerMainTabStore.PreApproval[];
                if (!isNullOrUndefined(data) && data?.length > 0 && Number(data[0]?.posPurchasePrice) > 0) {
                    props.updatePreApproval(data[0]?.posPurchasePrice, PreApprovalFields.PosMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice);
                }
                else {
                    props.updatePreApproval(data[0]?.posMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice);
                }
                props.updatePreApproval(data[0]?.letterStyle, PreApprovalFields.LetterStyle, PreApprovalFields.LetterStyle);
                props.updatePreApproval(data[0]?.loanType, PreApprovalFields.LoanType, PreApprovalFields.LoanType);
                props.updatePreApproval(data[0]?.posAlterPurchasePriceIndicator, PreApprovalFields.PosAlterPurchasePriceIndicator, PreApprovalFields.PosAlterPurchasePriceIndicator);
                props.updatePreApproval(data[0]?.isRequestedPreApproval, PreApprovalFields.isRequestedPreApproval, PreApprovalFields.isRequestedPreApproval);
                props.updatePreApproval(data[0]?.loPreApprovalLetterComments, PreApprovalFields.LoPreApprovalLetterComments, PreApprovalFields.LoPreApprovalLetterComments);
                setList(data as LoanOfficerMainTabStore.PreApproval[]);
            }
        })
    }

    React.useEffect(() => {
        if (!props.preApproval.posAlterPurchasePriceIndicator || props.preApproval.letterStyle === "PreApprovalCertificate1") {
            props.updatePreApproval(props.preApproval.posMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice)
        }
    }, [props.preApproval.posAlterPurchasePriceIndicator])

    React.useEffect(() => {
        if (isNullOrUndefined(props.preApproval.posMaxPurchasePrice)) {
            props.updatePreApproval(props.data.propertyEstimatedValueAmount, PreApprovalFields.PosMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice);
        }
        if (props.data.loanType && props.data.loanType !== "" && isNullOrUndefined(props.preApproval.letterStyle)) {
            props.updatePreApproval(props.data.loanType, PreApprovalFields.LoanType, PreApprovalFields.LoanType);
        }

        if ((!isNullOrUndefined(props?.preApproval?.letterStyle) && props?.preApproval?.letterStyle !== ""
            && !isNullOrUndefined(props?.preApproval?.loanType) && change)) {

            if (props.preApproval.posAlterPurchasePriceIndicator && change && (props.preApproval.posMaxPurchasePrice !== undefined &&
                props.preApproval.posMaxPurchasePrice != "" && props?.preApproval?.posMaxPurchasePrice.toString().length < 12)) {
                setDisabled(false)
            }
            else if (props.preApproval.posAlterPurchasePriceIndicator === undefined || props.preApproval.posAlterPurchasePriceIndicator === false) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }
        else {
            setDisabled(true)
        }

        if (!isNullOrUndefined(props?.preApproval?.letterStyle) && props?.preApproval?.letterStyle === "PreApprovalCertificate1" && !isNullOrUndefined(props?.preApproval?.loanType) && change) {
            setDisabled(false)
        }
        if (!isNullOrUndefined(props?.preApproval?.letterStyle) && props?.preApproval?.letterStyle === "PreApprovalCertificate1" && !isNullOrUndefined(props?.preApproval?.loanType)) {
            props.updatePreApproval((props.data.propertyEstimatedValueAmount !== undefined && props.data.propertyEstimatedValueAmount) > 0 ? props.data.propertyEstimatedValueAmount : 0, PreApprovalFields.PosMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice);
            props.updatePreApproval(undefined, PreApprovalFields.PosAlterPurchasePriceIndicator, PreApprovalFields.PosAlterPurchasePriceIndicator);
        }
        if (!props.preApproval.posAlterPurchasePriceIndicator && change) {
            props.updatePreApproval((props.data.propertyEstimatedValueAmount !== undefined && props.data.propertyEstimatedValueAmount) ? props.data.propertyEstimatedValueAmount : 0, PreApprovalFields.PosMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice);
        }

    }, [props?.preApproval?.letterStyle, props?.preApproval?.loanType, props?.preApproval?.posMaxPurchasePrice, props?.preApproval?.posAlterPurchasePriceIndicator, props?.preApproval?.loPreApprovalLetterComments]);

    return (<>
        {isLoading ? <Loading /> :
            <div className={styles.preApprovalLetter}>
                <div className={styles.flexItem}>
                    <h2>Submit Pre-Approval Letter</h2>
                    {props.preApproval.letterStyle === "PreApprovalCertificate1" &&
                        <p>  <strong>This Pre-Approval letter has no Purchase Price visible to the borrower.</strong></p>}
                    <p>Select what type of pre-approval for borrower</p>
                    <div className={styles.preApprovalFormRow}>
                    <label>Letter Style</label>
                    <SelectDropDown onChange={(e: React.ChangeEvent<HTMLInputElement>) => { props.updatePreApproval(e.target.value, "letterStyle", "letterStyle"); setIschange(true); }}
                        rule={{
                            required: ValidationMessage.Required,
                        }} noArrow={false}
                        value={props.preApproval.letterStyle}
                        name="letterStyle"
                    >
                        <option value="">Select...</option>
                        <option value="PreApprovalCertificate1"> Pre-Approval Certificate 1</option>
                        <option value="PreApprovalCertificate2">Pre-Approval Certificate 2</option>
                        <option value="PreApprovalCertificate3">Pre-Approval Certificate 3</option>
                        <option value="PreApprovalCertificate">Pre-Approval Letter</option>

                        </SelectDropDown>
                        </div>
                    <div className={styles.preApprovalFormRow}>
                        <label>Current Loan Type in Encompass is {props.data.loanType}</label>
                        <SelectDropDown onChange={(e: React.ChangeEvent<HTMLInputElement>) => loanTypeHandler(e.target.value, "loanType", PreApprovalFields.ConventionalPreApproval)}

                            rule={{
                                required: ValidationMessage.Required,
                            }} noArrow={false}
                            value={props?.preApproval?.loanType ?? ""}
                            name="CurrentLoanType"
                        >
                            <option value="">Select...</option>
                            <option value="Conventional">Conventional</option>
                            <option value="FHA">FHA</option>
                            <option value="VA">VA</option>
                            <option value="USDA">USDA</option>

                        </SelectDropDown>
                    </div>

                    

                    <div className={styles.commentsBox}>

                        <div className={styles.commentTopRow}>
                            <label>Comments</label>
                            <span className={styles.charactorMax}>{commentChar.max_char} characters maximum</span>
                        </div>
                        <div className={styles.textareaRow}>
                            <textarea
                                value={props?.preApproval?.loPreApprovalLetterComments ?? ""}
                                spellCheck={true}
                                rows={6}
                                maxLength={225}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    handleCommentCharCount(e.target.value);
                                }}
                            />
                            <div className={styles.charCount}>{commentChar.chars_left} of {commentChar.max_char}</div>
                        </div>
                           
                       
                    </div>


                    {props.preApproval.letterStyle !== "PreApprovalCertificate1" &&
                        <>{
                            props.preApproval.posAlterPurchasePriceIndicator &&
                        <div className={styles.preApprovalFormRow}>
                            <label>Purchase Price</label>
                                <div className={styles.pricePrefix}>
                                    <NumberFormatInput
                                        value={props?.preApproval?.posMaxPurchasePrice ?? ""}
                                        onValueChange={(values: NumberFormatValues) => {
                                            props.updatePreApproval(values.value, PreApprovalFields.PosMaxPurchasePrice, PreApprovalFields.PosMaxPurchasePrice);
                                            setIschange(true);
                                        }}
                                        placeholder="$00.00"
                                        thousandSeparator={true}
                                        prefix={"$"}
                                        decimalScale={0}
                                    />
                                </div>
                                {props.preApproval.posMaxPurchasePrice != undefined && props?.preApproval?.posMaxPurchasePrice?.length > 11 &&
                                    <span>
                                        The value entered exceeds the maximum length requirement.
                            </span>}
                            </div>

                        }
                            <div className={styles.maxPriceEdit} >
                                <div className={styles.UHMRadioCheck} >
                                    <input
                                        name="posAlterPurchasePriceIndicator"
                                        type="checkbox"
                                        checked={!!props?.preApproval?.posAlterPurchasePriceIndicator}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            props.updatePreApproval(e.target.checked, PreApprovalFields.PosAlterPurchasePriceIndicator, PreApprovalFields.PosAlterPurchasePriceIndicator);
                                            setIschange(true)
                                        }} />
                                    <span className={styles.consentText}>
                                        Allow borrower to edit max-price
                                 </span>
                                </div>
                            </div>
                        </>
                    }
                    <div>
                        <button disabled={disabled} onClick={submit} className="common-green-button">SUBMIT TO BORROWER</button>
                    </div>

                </div>

                <div className={styles.flexItem}>
                    <h3> Active Pre-Approvals</h3>
                    {!isNullOrUndefined(list) && list?.length > 0 && !props.preApproval.isRequestedPreApproval && <>
                        <div className={styles.preApprovalList}>{
                            Number(list[0].posPurchasePrice) > 0 ?
                                <div className={styles.price}>${Number(list[0].posPurchasePrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                :
                                <div className={styles.price}>${Number(list[0].posMaxPurchasePrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        }
                            <div className={styles.date}>{moment(new Date(list[0].timeStamp.toString())).format("MM/DD/YYYY")}</div>
                        </div>
                    </>}
                    <br />
                    <h3>Inactive Pre-Approvals</h3>
                    {!isNullOrUndefined(list) && list?.length > 0 && list?.map((m: LoanOfficerMainTabStore.PreApproval, idx: number) => {
                        return (
                            props.preApproval.isRequestedPreApproval ?
                                <div className={styles.preApprovalList}>
                                    {Number(m.posPurchasePrice) > 0 ?
                                        <div key={idx} className={styles.price}>${Number(m.posPurchasePrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
                                        :
                                        <div key={idx} className={styles.price}>{Number(m.posMaxPurchasePrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
                                    }
                                    <div className={styles.date}>{moment(new Date(m.timeStamp.toString())).format("MM/DD/YYYY")}                                        {/* {m.timeStamp.toString().split('T')[0]}*/}
                                    </div>
                                </div>
                                :
                                idx >= 1 && <>
                                    <div className={styles.preApprovalList}>
                                        {Number(m.posPurchasePrice) > 0 ?
                                            <div key={idx} className={styles.price}>${Number(m.posPurchasePrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
                                            :
                                            <div key={idx} className={styles.price}>${Number(m.posMaxPurchasePrice).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </div>
                                        }
                                        <div className={styles.date}>{moment(new Date(m.timeStamp.toString())).format("MM/DD/YYYY")}</div>

                                    </div>
                                </>
                        )
                    })}
                </div>
            </div>
        }</>
    );
}

const mapDispatchToProps = (dispatch: any) => ({
    updatePreApproval: (value: string, propertyName: string, section: string) => {
        dispatch(LoanOfficerMainTabActions.loanOfficerMainTabActionCreators.updatePreApproval(value, propertyName, section));
    },
});
export default connect((state: ApplicationState) => state.loanOfficerMainTab, mapDispatchToProps)(PreApprovalLetter as any);