import React, { useState } from "react";
import styles from "./eConsentDocument.module.scss";
import AgreementText from "./eConsentAgreementText";
import Dialog from "./eConsentDialog";
import BottomScrollValidator from "components/common/BottomScrollValidator";
import { useHistory } from "react-router-dom";
import { eConsent, generateSignedEConsentDocument, UpdateSsnFields } from "services/eConsentService";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { AccountInformationState } from "store/accountInformationStore";
import { UserAccountState } from "store/userAccountStore";
import { BackgroundEngine } from "../../common/BackgroundTaskTimer";
import { loanState } from "../../../store/loanStore";
import { isNullOrUndefined } from "util";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const EConsentDocument = (props: { advance: () => void, setUserConsent: (value: boolean) => void }) => {
    const [isEConsented, setEConsent] = useState<{ value: null | boolean }>({ value: null });

    const [readAgreement, setReadAgreement] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const hasReadAgreement = () => setReadAgreement(true);
    const { loanGuid } = useSelector((state: ApplicationState) => state.accountInformation as AccountInformationState);
    const { borrowerType } = useSelector((state: ApplicationState) => state.userAccountInformation as UserAccountState);
    const { isLoanFromEncompassFlag } = useSelector((state: ApplicationState) => state.loan as loanState);
    const { interimLoanStagingId } = useSelector((state: ApplicationState) => state.loan as loanState);

    const history = useHistory();
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    const saveEConsent = async (hasConsented: boolean) => {
        setShowLoading(true);
          await eConsent(loanGuid, borrowerType, hasConsented, interimLoanStagingId??0).then((response: any) => {
            if (!response.hasError && response.parsedBody != null && response.parsedBody.body != null) {

                setShowLoading(false);
                if (response.parsedBody?.body === null) {
                    history.push("/system-unavailable");
                }
                else if (hasConsented == false || response.parsedBody?.body == null) {
                    history.push("/");
                }
                else {
                    //generateSignedEConsentDocument(loanGuid, borrowerType, response.parsedBody.body?.item2);
                    BackgroundEngine.TriggerOnceImmediate();

                    props.setUserConsent(true);
                    props.advance();
                }
            }
            else {
                console.error("Error in eConsent: ", eConsent);
            }
        });
    };

    const dialogResponse = async (doNotWantToConsent: boolean) => {
        setShowDialog(false);
        if (doNotWantToConsent) {
            await saveEConsent(false);
        }
    };

    const updateSsnFieldInEncomapss = async () => {
        if (!isNullOrUndefined(isLoanFromEncompassFlag) && isLoanFromEncompassFlag) {
            UpdateSsnFields(loanGuid ?? "");
            history.push({ pathname: "/contact-preference" })
        }
    }

    return (
        <div>
            {showDialog && <Dialog hasClosedDialog={dialogResponse}></Dialog>}
            <div className="align-center">
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Thanks! You're Almost Set")}>Thanks! You're Almost Set.</h1>
                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please fully review the eConsent agreement below')} className={styles.consentQuestionLabel}>
                    Please fully review the eConsent agreement below.
                </h2>
            </div>
            <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Electronic Consent Agreement')} className="align-center">Electronic Consent Agreement</h2>
            <div className={styles.docContainer}>
                <BottomScrollValidator className={styles.docText} hasReachedBottom={hasReadAgreement}>
                    <AgreementText></AgreementText>
                </BottomScrollValidator>
            </div>
            <div className={`${styles.buttons} align-center`}>
                <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press Button to I agree')} className={`common-button ${isEConsented.value === true ? "active" : ""} ${styles.Button} small`} onClick={() => {
                    saveEConsent(true);
                    setEConsent({ value: true });
                    updateSsnFieldInEncomapss();
                }} disabled={!(readAgreement && !showLoading)}>
                    I Agree
        </button>
                <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press Button to I decline')} className={`common-button ${isEConsented.value === false ? "active" : ""} ${styles.Button} ${styles.NoButton} small`} onClick={() => {
                    setShowDialog(true);
                    setEConsent({ value: false });
                }} disabled={!(readAgreement && !showLoading)}>
                    I Decline
        </button>
            </div>
            {showLoading && (
                <div className="align-center screenLoader">
                    <div className="loader medium"></div>
                </div>
            )}
        </div>
    );
};

export default EConsentDocument;
