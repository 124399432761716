import React from "react";
import PlacesAutocomplete, { geocodeByPlaceId, } from "react-places-autocomplete";
import styles from "./LocationSearch.module.scss";

type LocationAutofillProps = {
    addressStreetLine1: string,
    addresssFieldName: string,
    borrowerType: any,
    customType?: any,
    isDisabled?: boolean,
    setStoreAddress?: (value: string, name: string, borrowerType: any, customType: any) => void,
    styles: any,
    setFullAddress?: (street: string, city: string, state: string, zip: string) => void
}

const LocationSearchInput = (props: LocationAutofillProps) => {
    const [addressStreetLine1, setAddress] = React.useState(props.addressStreetLine1);

    React.useEffect(() => {
        if (props.addressStreetLine1 === "undefined") {
            setAddress("");
        }
        else {
            setAddress(props.addressStreetLine1);
        }
    }, [props.addressStreetLine1]);

    const handleSelect = (address: string, placeID: string) => {
        geocodeByPlaceId(placeID)
            .then(results => {

                let correctAddress = address;
                let addressComponents = results[0]?.address_components;
                let city = "";
                let state = "";
                let zip = "";
                let street = "";

                if (addressComponents) {
                    let streetNumber = addressComponents.find(a => a.types.includes("street_number"))?.long_name ?? "";
                    let streetName = addressComponents.find(a => a.types.includes("route"))?.long_name ?? "";
                    street = (streetNumber !== "" && streetName !== "") ? `${streetNumber} ${streetName}` : "";

                    city = addressComponents.find(a => a.types.includes("locality"))?.long_name ?? "";
                    state = addressComponents.find(a => a.types.includes("administrative_area_level_1"))?.short_name ?? "";
                    zip = addressComponents.find(a => a.types.includes("postal_code"))?.long_name ?? "";

                    if (props.setStoreAddress) {
                        props.setStoreAddress(street ? street : city, props.addresssFieldName, props.borrowerType, props.customType);
                        props.setStoreAddress(city, "addressCity", props.borrowerType, props.customType);
                        props.setStoreAddress(state, "addressState", props.borrowerType, props.customType);
                        props.setStoreAddress(zip, "addressPostalCode", props.borrowerType, props.customType);
                    }
                    else if (props.setFullAddress) {
                        props.setFullAddress(street ? street : city, city, state, zip);
                    }
                    correctAddress = street ? street : city;
                }
                setAddress(correctAddress);
            })
            .catch(error => {
                console.error(error);
            });

        if (props.setStoreAddress) {
            props.setStoreAddress(addressStreetLine1 ? addressStreetLine1 : address, props.addresssFieldName, props.borrowerType, props.customType);
        } else if (props.setFullAddress) {
            props.setFullAddress(addressStreetLine1 ? addressStreetLine1 : address, "", "", "");
        }
    }

    const handleInput = (event: any) => {
        if (props.setStoreAddress) {
            props.setStoreAddress(event, props.addresssFieldName, props.borrowerType, props.customType);
        } else if (props.setFullAddress) {
            props.setFullAddress(event, "", "", "");
        }
        setAddress(event);
    }

    const handleError = (status: any) => {
        console.error("Error from Google Maps API", status);
    };

    return (
        <div>
            <PlacesAutocomplete value={addressStreetLine1} onChange={handleInput} onSelect={handleSelect} onError={handleError}>
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className={styles.locationSearch}>
                        <input {...getInputProps({ placeholder: "Type address", })} disabled={props.isDisabled} />
                        <div className={props.customType == "" ? styles.sugg : suggestions?.length > 0 ? styles.suggestionBg : ''}>
                            {suggestions.map(suggestion => {
                                suggestion.id = suggestion.index.toString(); //to resolve missing key prop error for undefined id
                                const style = {
                                    backgroundColor: suggestion.active ? "" : ""
                                };

                                return (
                                    <div {...getSuggestionItemProps(suggestion, { style })}>
                                        <strong>
                                            {suggestion.formattedSuggestion.mainText}
                                        </strong>{" "}
                                        <small>
                                            {suggestion.formattedSuggestion.secondaryText}
                                        </small>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div >
    );
}

export default LocationSearchInput;


















