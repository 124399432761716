import React from "react";
import largeIcon from "assets/infographics/eConsent/girlWithPurpleFolderAndGreenPaper.svg";

import Infographic from "components/common/Infographic";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const EConsentPreview = (props: { borrowerFirstName: string; advance: () => void }) => {
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className="infographic-side-layout-container">
            <div className="content">
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Hi' + props.borrowerFirstName + '! Please review and accept the Electronic Consent Agreement to view or sign documents electronically')}>
                    Hi {props.borrowerFirstName}! Please review and accept the Electronic Consent
          Agreement to view or sign documents electronically.
        </h1>
                <button className="common-green-button" onClick={props.advance} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press buttton to Review Electronic Consent')}>Review Electronic Consent</button>
            </div>
            <div className="infographic-side-panel">
                <Infographic src={largeIcon} />
            </div>
        </div>
    );
};

export default EConsentPreview;
