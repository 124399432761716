import * as React from "react";
import creditReportInfographic from "../../assets/infographics/loanOfficerView/CreditReport.svg";
import InfographicTopLayout from "../common/Layout/InfographicTopLayout";
import styles from "./LoanOfficer.module.scss";

const MissingAuthorizationAlert = () => {

    return (
        <div className={styles.missCreditAuth}>
            <InfographicTopLayout className={"align-center"} infographic={creditReportInfographic}>
                <h4>The borrower, co-borrower, or both did not authorize the credit check.
                They will need to authorize the credit check before
                the single merge report can be systematically ordered.</h4>
            </InfographicTopLayout>
        </div>
    );
};

export default MissingAuthorizationAlert;
