import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AccountCreationActions from "../../actions/accountCreationActions";
import * as TaskMenuActions from "../../actions/taskMenuAction";
import * as AccountCreationStore from "../../store/accountCreationStore";
import { AsYouType } from 'libphonenumber-js';
import LocationSearch from "../common/Input/LocationSearch";
import styles from "./accountCreation.module.scss";
import TextInput from "../common/Input/TextInput";
import SelectDropDown from "../common/Input/SelectDropDown";
import { Rule, ValidationMessage } from "../constants/validation";
import AddressFields from "./AddressFields";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../common/CustomDatePicker";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
import { isNullOrWhiteSpace, validateBirthDate, toDate, isValidDate } from "../../common/helper/formatHelper";

type BorrowerInformationProps =
    AccountCreationStore.AccountCreationState &
    {
        validation?: undefined;
        isError: any;
        borrowerFirstName: string,
        borrowerLastName: string,
        borrowerEmail: string,
        borrowerType: AccountCreationStore.BorrowerType,
        setBorrowerValid: React.Dispatch<React.SetStateAction<boolean>>,
        disabled: boolean
    }
    & typeof AccountCreationActions.accountCreationActionCreators
    & typeof TaskMenuActions.TaskMenuCreators;

const BorrowerInformation = (props: BorrowerInformationProps) => {
    let currentBorrower = props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? props.borrower : props.coBorrower;
    const [coBorrowerAddressChecked, toggle] = React.useState(false);
    const [birthdatemsg, setBirthdatemsg] = React.useState(false);
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        if (props.coBorrower?.currentAddress) {
            if (props.coBorrower?.currentAddress?.coBorrowerAddressChecked !== undefined) {
                toggle(props.coBorrower?.currentAddress?.coBorrowerAddressChecked);
            }
        }
    }, [props.coBorrower?.currentAddress]);

    React.useEffect(() => {
        if (!isNullOrWhiteSpace(props.borrowerFirstName) && isNullOrWhiteSpace(props.borrower.firstName)) {
            props.updateBorrowerProperty(props.borrowerFirstName, "firstName", props.borrowerType);
        }
        if (!isNullOrWhiteSpace(props.borrowerLastName) && isNullOrWhiteSpace(props.borrower.lastName)) {
            props.updateBorrowerProperty(props.borrowerLastName, "lastName", props.borrowerType);
        }
        if (!isNullOrWhiteSpace(props.borrowerEmail) && isNullOrWhiteSpace(props.borrower.emailAddressText)) {
            props.updateBorrowerProperty(props.borrowerEmail, "emailAddressText", props.borrowerType);
        }
    }, []);

    React.useEffect(() => {
        if (props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower) {
            props.setBorrowerValid(validateBorrower(props.borrower));
        } else if (props.coBorrower) {
            props.setBorrowerValid(validateBorrower(props.coBorrower));
        }
    }, [props.borrower, props.coBorrower]);

    const coBorrowerAddressCheckedChange = (checked: any) => {
        toggle(checked);
        props.SetAddressSameAsBorrower(AccountCreationStore.AddressType.CurrentAddress, checked);
    }

    const validateBorrower = (borrower: AccountCreationStore.Borrower): boolean => {
        let isValid = true;
        let isValidDate = borrower?.birthDate && validateBirthDate(borrower?.birthDate);
        if (isNullOrWhiteSpace(borrower?.firstName) ||
            isNullOrWhiteSpace(borrower?.lastName) ||
            isNullOrWhiteSpace(borrower?.phoneNumber) ||
            isNullOrWhiteSpace(borrower?.maritalStatusType) ||
            isNullOrWhiteSpace(borrower?.phoneType) ||
            isNullOrWhiteSpace(borrower?.emailAddressText) ||
            !isValidDate ||
            borrower.currentAddress === undefined ||
            isNullOrWhiteSpace(borrower.currentAddress?.addressStreetLine1) ||
            isNullOrWhiteSpace(borrower.currentAddress?.addressCity) ||
            isNullOrWhiteSpace(borrower.currentAddress?.addressState) ||
            isNullOrWhiteSpace(borrower.currentAddress?.addressPostalCode) ||
            (borrower.currentAddress?.addressPostalCode && (borrower.currentAddress?.addressPostalCode.length < 5)) ||
            (borrower?.taxIdentificationIdentifier && borrower?.taxIdentificationIdentifier.length < 9) ||
            isNullOrWhiteSpace(borrower?.taxIdentificationIdentifier) || props.borrower.emailAddressText === props.coBorrower.emailAddressText) {
            isValid = false;
        }
        return isValid;
    };

    const handlePhoneInput = (value: string) => {
        let numbers = value.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
        if (numbers.length <= 3) {
            props.updateBorrowerProperty(numbers, "phoneNumber", props.borrowerType);
        } else {
            let formattedPhoneNumber = new AsYouType("US").input(numbers);
            props.updateBorrowerProperty(formattedPhoneNumber, "phoneNumber", props.borrowerType);
        }
    };

    return (
        <div className={styles.borrowerInformation}>
            <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? "Borrower Information" : "Co-Borrower Information")}>
                {props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
                    "Borrower Information" :
                    "Co-Borrower Information"}
            </h1>
            <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Please fill out the following information')}>Please fill out the following information.</h2>
            <div className={styles.borrowerForm}>
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("First Name")}>
                        First Name<span className={styles.requiredMsg}> * </span>
                    </label>
                    <TextInput
                        placeholder="First Name"
                        name="First Name"
                        className={styles.input}
                        disabled={props.disabled}
                        value={currentBorrower?.firstName ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerProperty(e.target.value, "firstName", props.borrowerType)}
                        rule={{
                            required: ValidationMessage.Required,
                            pattern: Rule.SpaceValidation,
                            minLength: {
                                value: 1,
                                message: ValidationMessage.MinLength,
                            },
                            maxLength: {
                                value: 50,
                                message: ValidationMessage.MaxLength,
                            },
                        }}
                        handleValidation={props.setValidation}
                        invalidclass={styles.invalidInput}
                    />
                </div>
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Middle Name (Optional)")}>Middle Name <span className={styles.optionalMsg}>(Optional)</span></label>
                    <input disabled={props.disabled} type="text" className={styles.input} name="middleName" value={currentBorrower?.middleName ?? ""} onChange={(e) => props.updateBorrowerProperty(e.target.value, e.target.name, props.borrowerType)} />
                </div>
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Last Name")}>Last Name <span className={styles.requiredMsg}> * </span></label>
                    <TextInput
                        disabled={props.disabled}
                        placeholder="Last Name"
                        name="Last Name"
                        className={styles.input}
                        value={currentBorrower?.lastName ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerProperty(e.target.value, "lastName", props.borrowerType)}
                        rule={{
                            required: ValidationMessage.Required,
                            pattern: Rule.SpaceValidation,
                            minLength: {
                                value: 1,
                                message: ValidationMessage.MinLength,
                            },
                            maxLength: {
                                value: 50,
                                message: ValidationMessage.MaxLength,
                            },
                        }}
                        handleValidation={props.setValidation}
                        invalidclass={styles.invalidInput}
                    />
                </div>
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Suffix (Optional)")}>
                        Suffix <span className={styles.optionalMsg}>(Optional)</span>
                    </label>
                    <input disabled={props.disabled} type="text" className={styles.input} name="suffixToName" value={currentBorrower?.suffixToName ?? ""}
                        onChange={(e) => props.updateBorrowerProperty(e.target.value, e.target.name, props.borrowerType)} />
                </div>
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Phone")}>
                        Phone<span className={styles.requiredMsg}> * </span>
                    </label>
                    <TextInput
                        disabled={props.disabled}
                        name="Phone Number"
                        className={styles.input}
                        value={currentBorrower?.phoneNumber ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePhoneInput(e.target.value)}
                        rule={{
                            required: ValidationMessage.Required,
                            pattern: Rule.NumberValidation,
                        }}
                        handleValidation={props.setValidation}
                        invalidclass={styles.invalidInput}
                    />
                </div>
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Phone Type")}>
                        Phone Type<span className={styles.requiredMsg}> * </span>
                    </label>

                    <SelectDropDown disabled={props.disabled} className={styles.input} name="Phone Type" value={currentBorrower?.phoneType ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerProperty(e.target.value, "phoneType", props.borrowerType)}
                        rule={{
                            required: ValidationMessage.Required,
                        }} noArrow={false}
                        handleValidation={() => props.setValidation}
                        invalidclass={styles.invalidInput}
                    >
                        <option disabled value="">Select...</option>
                        <option value="Home">Home</option>
                        <option value="Cell">Mobile</option>
                        <option value="Work">Work</option>
                    </SelectDropDown>
                </div>
                <div className={styles.inputGroupLarge}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Email")}>
                        Email<span className={styles.requiredMsg}> * </span>
                    </label>
                    <TextInput
                        disabled={props.disabled}
                        placeholder="Email Address"
                        name="Email Address"
                        className={styles.input}
                        value={currentBorrower?.emailAddressText ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerProperty(e.target.value, "emailAddressText", props.borrowerType)}
                        rule={{
                            required: ValidationMessage.Required,
                            pattern: Rule.EmailValidation,
                        }}
                        handleValidation={props.setValidation}
                        invalidclass={styles.invalidInput}
                    />
                    {
                        props.borrower.emailAddressText === props.coBorrower.emailAddressText &&
                        <span className={styles.errmsg}>The co-borrower email can not be same as the primary borrower. Please enter a different email address</span>
                    }
                </div>
                <div className={styles.seprator}></div>
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Marital Status")}>Marital Status <span className={styles.requiredMsg}> * </span></label>
                    <SelectDropDown disabled={props.disabled} className={styles.input} name="Marital Status" value={currentBorrower?.maritalStatusType ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerProperty(e.target.value, "maritalStatusType", props.borrowerType)}
                        rule={{
                            required: ValidationMessage.Required,
                        }} noArrow={false}
                        handleValidation={props.setValidation}
                    >
                        <option disabled value="">Select..</option>
                        <option value="Married">Married</option>
                        <option value="Unmarried">Unmarried</option>
                        <option value="Separated">Separated</option>
                    </SelectDropDown>
                </div>

                <div className={props.borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ? (`${styles.inputGroupLarge} ${styles.locationSearch}`)
                    : (`${styles.inputGroupXLarge} ${styles.locationSearch}`)}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Current Address")}>Current Address <span className={styles.requiredMsg}> * </span></label>
                    {
                        props.borrowerType && props.borrowerType === AccountCreationStore.BorrowerType.CoBorrower &&
                        <div className={styles.coBorrowerCheckbox}>
                            <label className={styles.customCheckBox}>
                                <input disabled={props.disabled}
                                    type="checkbox"
                                    checked={coBorrowerAddressChecked}
                                    onChange={(event) => coBorrowerAddressCheckedChange(event.target.checked)} />
                            </label>
                            <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Same as Borrower")}>Same as Borrower</span>
                        </div>
                    }

                    <div className={props.borrowerType === AccountCreationStore.BorrowerType.CoBorrower ? styles.CoBorrlocationSearch : ""}>
                        {!coBorrowerAddressChecked &&
                            <LocationSearch
                                isDisabled={props.disabled}
                                addressStreetLine1={currentBorrower?.currentAddress?.addressStreetLine1 ?? ""}
                                addresssFieldName="addressStreetLine1"
                                borrowerType={props.borrowerType}
                                customType={AccountCreationStore.AddressType.CurrentAddress}
                                setStoreAddress={props.updateBorrowerAddressProperty}
                                styles=""
                            />
                        }
                        {coBorrowerAddressChecked &&
                            <input disabled={coBorrowerAddressChecked} type="text" className={styles.input} name="addressStreetLine1"
                                value={currentBorrower?.currentAddress?.addressStreetLine1 ?? ""} />
                        }
                    </div>
                </div>
                {currentBorrower?.currentAddress?.addressCity === "" || currentBorrower?.currentAddress?.addressPostalCode === ""
                    || currentBorrower?.currentAddress?.addressCity === undefined || currentBorrower?.currentAddress?.addressPostalCode === undefined
                    ?
                    (
                        <AddressFields
                            disabled={props.disabled}
                            borrowerInformation={currentBorrower}
                            addressType={AccountCreationStore.AddressType.CurrentAddress}
                            onChange={props.updateBorrowerAddressProperty}
                            borrowerType={props.borrowerType}
                        />
                    ) :
                    (
                        <>

                            <div className={styles.inputGroupMedium}>
                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("City")}>City <span className={styles.requiredMsg}> * </span></label>
                                <TextInput
                                    disabled={props.disabled || coBorrowerAddressChecked}
                                    name="City"
                                    className={styles.input}
                                    placeholder="City"
                                    value={currentBorrower?.currentAddress?.addressCity ?? ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerAddressProperty(e.target.value, "addressCity", props.borrowerType,
                                        AccountCreationStore.AddressType.CurrentAddress)}
                                    rule={{
                                        required: ValidationMessage.Required,
                                    }}
                                    handleValidation={props.setValidation}
                                    invalidclass={styles.invalidInput}
                                />

                            </div>
                            <div className={styles.inputGroupSmall}>
                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("State")}>State <span className={styles.requiredMsg}> * </span></label>
                                <TextInput
                                    disabled={props.disabled || coBorrowerAddressChecked}
                                    name="State"
                                    className={styles.input}
                                    placeholder="State"
                                    value={currentBorrower?.currentAddress?.addressState ?? ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerAddressProperty(e.target.value, "addressState", props.borrowerType, AccountCreationStore.AddressType.CurrentAddress)}
                                    rule={{
                                        required: ValidationMessage.Required,
                                    }}
                                    handleValidation={props.setValidation}
                                    invalidclass={styles.invalidInput}
                                    maxLength="2"
                                />
                            </div>
                            <div className={styles.inputGroupSmall}>
                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Zip Code")}>Zip Code <span className={styles.requiredMsg}> * </span></label>
                                <TextInput
                                    disabled={props.disabled || coBorrowerAddressChecked}
                                    name="Zip Code"
                                    className={styles.input}
                                    placeholder="Zip Code"
                                    value={currentBorrower?.currentAddress?.addressPostalCode ?? ""}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.updateBorrowerAddressProperty(e.target.value, "addressPostalCode", props.borrowerType, AccountCreationStore.AddressType.CurrentAddress)}
                                    rule={{
                                        required: ValidationMessage.Required,
                                    }}
                                    handleValidation={props.setValidation}
                                    invalidclass={styles.invalidInput}
                                />
                            </div>
                        </>
                    )}
                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Birthday")}>Birthday <span className={styles.requiredMsg}> * </span></label>
                    <CustomDatePicker
                        disabled={props.disabled}
                        fieldValue={currentBorrower?.birthDate ? (isValidDate(currentBorrower?.birthDate) ? new Date(currentBorrower.birthDate) : undefined) : undefined}
                        onDateChange={(date: any) => {
                            let convertedDate = toDate(date);
                            if (isValidDate(convertedDate)) {
                                let isValidBirthDate = validateBirthDate(convertedDate);
                                setBirthdatemsg(isValidBirthDate ? false : true);
                            } else {
                                setBirthdatemsg(true);
                            }
                            props.updateBorrowerProperty(convertedDate, "birthDate", props.borrowerType);
                        }}
                        popperPlacement={false}
                        maxDate={new Date()}
                    />
                    {birthdatemsg ? <span style={{ color: "red" }}>Invalid Birthday Date</span> : <></>}
                </div>

                <div className={styles.inputGroupMedium}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Social Security Number")}>Social Security Number <span className={styles.requiredMsg}> * </span></label>
                    <NumberFormatInput
                        disabled={props.disabled}
                        name="socialSecurityNumber"
                        className={styles.input}
                        value={currentBorrower?.taxIdentificationIdentifier ?? ""}
                        format="###-##-####"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            props.updateBorrowerProperty(values.value, "taxIdentificationIdentifier", props.borrowerType);
                        }}
                    />
                </div>
            </div>
        </div>);
};

const mapStateToProps = (state: ApplicationState, ownProps: { borrowerType: AccountCreationStore.BorrowerType, setBorrowerValid: React.Dispatch<React.SetStateAction<boolean>>, disabled: boolean }): any => {
    let borrowerFirstName = "";
    let borrowerLastName;
    let borrowerEmail;
    let borrowerType;
    let setBorrowerValid;
    let borrower = state.accountCreation?.borrower;
    let coBorrower = state.accountCreation?.coBorrower;
    let disabled;

    if (state.accountInformation !== undefined) {
        borrowerFirstName = state.accountInformation?.borrowerFirstName ?? "";
        borrowerLastName = state.accountInformation?.borrowerLastName ?? "";
        borrowerEmail = state.accountInformation?.borrowerEmail ?? "";
        borrowerType = ownProps.borrowerType;
        setBorrowerValid = ownProps.setBorrowerValid;
        disabled = ownProps.disabled;
    }
    return {
        borrowerFirstName,
        borrowerLastName,
        borrowerEmail,
        borrowerType,
        setBorrowerValid,
        borrower,
        coBorrower,
        disabled
    };
}
const mapDispatchToProps = (dispatch: any) => ({
    updateBorrowerProperty: (value: string, propertyName: string, borrowerType: AccountCreationStore.BorrowerType) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.updateBorrowerProperty(value, propertyName, borrowerType));
        dispatch(TaskMenuActions.TaskMenuCreators.setLoanIsDirty(true));
    },
    updateBorrowerAddressProperty: (value: string, propertyName: string, borrowerType: AccountCreationStore.BorrowerType, addressType: AccountCreationStore.AddressType) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.updateBorrowerAddressProperty(value, propertyName, borrowerType, addressType));
        dispatch(TaskMenuActions.TaskMenuCreators.setLoanIsDirty(true));
    },
    SetAddressSameAsBorrower: (addressType: AccountCreationStore.AddressType, value: boolean) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.SetAddressSameAsBorrower(addressType, value));
        dispatch(TaskMenuActions.TaskMenuCreators.setLoanIsDirty(true));
    },
    setValidation: (error: any, field: string) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.setValidation(error, field));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BorrowerInformation as any);