import * as React from "react";
import AccountsScreen from "assets/infographics/manualAssetInformation/AccountsScreen.svg";
import Infographic from "../../components/common/Infographic";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { ManualAssetInformation, ManualAssetInformationState } from "../../store/ManualAssetInformationStore";
import styles from "./manualAssets.module.scss";
import { ManualAssetInformationActionCreators } from "../../actions/manualAssetInformationActions";
import Currency from "../../components/common/Currency";
import { AccountTypeEnum } from "../../common/AccountTypeEnum";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ManualAssetDetailsProps = {
    manualAssetInformation: ManualAssetInformation;

} & typeof ManualAssetInformationActionCreators
    & ManualAssetInformationState;

const ManualTotalAsset = (props: ManualAssetDetailsProps) => {
    const { speak, cancel } = useSpeechSynthesis();

    let total2 = 0.00;
    if (props?.details?.length !== undefined && props.details.length > 0) {
        for (let i = 0; i < props.details.length; i++) {
            var Temp = props.details[i].urla2020CashOrMarketValueAmount ? parseFloat(props.details[i].urla2020CashOrMarketValueAmount) : 0
            total2 += Temp;
        }
    }

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className={styles.summaryContainer}>
            <div className={`${styles.content} ${styles.allassets}`}>
                <div className={styles.manualtotalContainer}>
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Your Manually Added Accounts')}>Your Manually Added Accounts</h1>
                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Total Assets')}>Total Assets <Currency value={total2} decimalScale={2}></Currency></h2>
                    <div className={styles.basicInfo}>
                        <div className={styles.summaryRow}>
                            <div className={`${styles.entryData} ${styles.entryDataCustomize}`}>
                                <div className={styles.entryContainer}>
                                    {props?.details?.filter(x => x.assetType === "Other" || x.urla2020CashOrMarketValueAmount != null).map((asset, index) => (
                                        <div className={styles.inputGroup} key={index}>
                                            <div>
                                                <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(asset.holderName)}>{asset.holderName}</label>
                                                {Object.entries(AccountTypeEnum).map(([key, values], index) => (
                                                    <React.Fragment key={index}>{
                                                        key === asset.assetType ? <h5 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(values)}>{values}</h5> : ""
                                                    }
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                            <div className={`${styles.editIcons} ${styles.pr0}`}>  <label><Currency value={asset.urla2020CashOrMarketValueAmount ? parseFloat(asset.urla2020CashOrMarketValueAmount) : parseFloat('0')} decimalScale={2}></Currency></label>  </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.manualTotalImg}>
                    <Infographic src={AccountsScreen} />
                </div>

            </div>

        </div>);
}

export default connect(
    (state: ApplicationState) => state.manualAssetInformation,
    null)(ManualTotalAsset as any);