import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "store";
import ChoiceQuestion from "../common/ChoiceQuestion";
import { saveContactPreference, getContactPreference } from "services/contactPreferenceService";
import { useHistory } from "react-router";
import { useNavigation } from "hooks/useNavigation";
import styles from "../../components/borrowerReo/borrowerReo.module.scss";
import preferanceStyles from "../../components/contactPreference/ContactPreference.module.scss";
import { bindActionCreators } from "redux";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import SelectDropDown from "../common/Input/SelectDropDown";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { AccountCreationState } from "../../store/accountCreationStore";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanState } from "../../store/loanStore";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const ContactPreference = () => {
    const { speak, cancel } = useSpeechSynthesis();
    const [showLoading, setShowLoading] = useState(false);
    const history = useHistory();
    const loanGuid = useSelector((state: ApplicationState) => state.loan?.loanGuid);
    const accountInfo = useSelector((state: ApplicationState) => state.accountCreation as AccountCreationState);
    const isLoanCompleted = useSelector((state: ApplicationState) => state.loan?.isLoanCompleted);
    ///selected choices
    const [firstChoice, setFirstChoice] = React.useState("");
    const [secondChoice, setSecondChoice] = React.useState("");
    const [thirdChoice, setThirdChoice] = React.useState("");
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    const actions = { ...bindActionCreators({ ...TaskMenuCreators }, useDispatch()) };
    const { isLoanFromEncompassFlag } = useSelector((state: ApplicationState) => state.loan as loanState);

    const navigation = useNavigation(() => {
        navigation.SetBackOnClick(() => {
            history.push({ pathname: "/demographic-questions", state: { args: accountInfo.borrower?.printOnAdditionalBorrowerPage ? "coborrower" : "borrower" } });
            actions.setLoanIsDirty(false);
        });
        navigation.ShowContinue(() => onContinue(), (isNullOrWhiteSpace(firstChoice) ||
            isNullOrWhiteSpace(secondChoice) ||
            isNullOrWhiteSpace(thirdChoice)));
        isLoanFromEncompassFlag && navigation.HideNavigation()
    });

    const onContinue = () => {
        if (isLoanCompleted) {
            history.push({ pathname: "/review-application" });
            return;
        }
        saveUserPreference(firstChoice, secondChoice, thirdChoice);
    };

    const getUserPreference = async () => {
        if (!loanGuid) {
            return;
        }
        setShowLoading(true);
        var response = await getContactPreference(loanGuid ?? "");
        try {
            if (response.ok && !response.parsedBody.hasError) {
                let choice = response.parsedBody.body;
                if (choice) {
                    setFirstChoice(choice.preference1);
                    setSecondChoice(choice.preference2);
                    setThirdChoice(choice.preference3);
                }
            }
        }
        catch (error) {
            console.error(error);
        }
        finally { setShowLoading(false); }
    };

    const saveUserPreference = async (preference1: string, preference2: string, preference3: string) => {
        if (!loanGuid) {
            return;
        }
        setShowLoading(true);
        var response = await saveContactPreference(preference1, preference2, preference3, loanGuid ?? "");
        setShowLoading(false);

        if (response.ok && !response.parsedBody.hasError) {
            actions.setLoanIsDirty(false);
            history.push({ pathname: "/review-application" });
        }
    };

    useEffect(() => {
        getUserPreference();
    }, []);

    useEffect(() => {
        navigation.ShowContinue(() => onContinue(),
            (isNullOrWhiteSpace(firstChoice) ||
                isNullOrWhiteSpace(secondChoice) ||
                isNullOrWhiteSpace(thirdChoice)
                || isEncompassDown));
    }, [firstChoice, secondChoice, thirdChoice]);
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className="w-100-p">
            <ChoiceQuestion
                disabled={isLoanCompleted}
                intro="Union Home Mortgage will be tracking the progress of your loan to keep you updated!"
                question="How would you like to receive these notifications?"
                choices={[]}
            />
            <div className={preferanceStyles.choiceRow}>
                <div className={preferanceStyles.choicebg}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('1st choice')}>1st choice:<span className={preferanceStyles.requiredMsg}> * </span></label>
                    <SelectDropDown
                        disabled={isLoanCompleted}
                        value={firstChoice ?? ""}
                        name="firstChoice"
                        id="#firstChoice"
                        onChange={(e: any) => {
                            setFirstChoice(e.target.value);
                            actions.setLoanIsDirty(true);
                        }}
                        dropdownclass={styles.select}
                        className={styles.selectDdl}
                    >
                        <option value="">Select..</option>
                        <option disabled={thirdChoice == "Email" || secondChoice == "Email"} value="Email">Email</option>
                        <option disabled={thirdChoice == "Phone" || secondChoice == "Phone"} value="Phone">Phone Calls</option>
                        <option disabled={thirdChoice == "Text" || secondChoice == "Text"} value="Text">Text Messages</option>
                    </SelectDropDown>
                </div>

                <div className={preferanceStyles.choicebg}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('2nd choice')}>2nd choice:<span className={preferanceStyles.requiredMsg}> * </span></label>
                    <SelectDropDown
                        disabled={isLoanCompleted}
                        value={secondChoice ?? ""}
                        name="secondChoice"
                        id="#secondChoice"
                        onChange={(e: any) => {
                            setSecondChoice(e.target.value);
                            actions.setLoanIsDirty(true);
                        }}
                        dropdownclass={styles.select}
                        className={styles.selectDdl}
                    >
                        <option value="">Select..</option>
                        <option disabled={thirdChoice == "Email" || firstChoice == "Email"} value="Email">Email</option>
                        <option disabled={thirdChoice == "Phone" || firstChoice == "Phone"} value="Phone">Phone Calls</option>
                        <option disabled={thirdChoice == "Text" || firstChoice == "Text"} value="Text">Text Messages</option>
                    </SelectDropDown>
                </div>


                <div className={preferanceStyles.choicebg}>
                    <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('3rd choice')}>3rd choice:<span className={preferanceStyles.requiredMsg}> * </span></label>
                    <SelectDropDown
                        disabled={isLoanCompleted}
                        value={thirdChoice ?? ""}
                        name="thirdChoice"
                        id="#thirdChoice"
                        onChange={(e: any) => {
                            setThirdChoice(e.target.value);
                            actions.setLoanIsDirty(true);
                        }}
                        dropdownclass={styles.select}
                        className={styles.selectDdl}
                    >
                        <option value="">Select..</option>
                        <option disabled={secondChoice == "Email" || firstChoice == "Email"} value="Email">Email</option>
                        <option disabled={secondChoice == "Phone" || firstChoice == "Phone"} value="Phone">Phone Calls</option>
                        <option disabled={secondChoice == "Text" || firstChoice == "Text"} value="Text">Text Messages</option>
                    </SelectDropDown>
                </div>
            </div>

            {showLoading && (
                <div className="align-center screenLoader">
                    <div className="loader medium"></div>
                </div>
            )}
        </div>
    );
};

export default ContactPreference;