import { get, post } from "../common/dataFetch";
import { OtherIncomeSourceDetail } from "../store/additionalIncomeStore";

export const getAll = (interimLoanStagingId: number) => {
    return get<any>(`AdditionalIncome/ReadByInterimIdAsync?interimLoanStagingId=${interimLoanStagingId}`);
};

export const readIncomeStatus = (interimLoanStagingId: number) => {
    return get<any>(`AdditionalIncome/ReadIncomeStatus?interimLoanStagingId=${interimLoanStagingId}`);
};

export const upsertAsync = (otherIncomeSource: OtherIncomeSourceDetail[], borrowerType: string, interimLoanStagingId:number) => {
    return post<any>(`AdditionalIncome/UpsertAsync?borrowerType=${borrowerType}&interimLoanStagingId=${interimLoanStagingId}`, otherIncomeSource);
};

export const updateAdditionalIncomeStatus = (interimLoanStagingId: number, otherIncomeSource: OtherIncomeSourceDetail[], borrowerType: string) => {
    return post<any>(`AdditionalIncome/UpdateAdditionalIncomeStatus?interimLoanStagingId=${interimLoanStagingId}&borrowerType=${borrowerType}`, otherIncomeSource);
};

export const updateProgressTracker = (borrowerType: string, loanGuid: string, hasCoBorrower: boolean) => {
    return post<any>(`AdditionalIncome/UpdateProgressTracker?borrowerType=${borrowerType}&loanGuid=${loanGuid}&hasCoBorrower=${hasCoBorrower}`, null);
};
