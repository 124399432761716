import { get, post } from "../common/dataFetch";
import { BorrowerLiabilityDetail } from "../store/borrowerLiabilityStore";

export const getAll = (interimLoanStagingId: number) => {
    return get<any>(`BorrowerLiability/ReadAsync?interimLoanStagingId=${interimLoanStagingId}`);
};

export const create = (interimLoanStagingId: number, liability: BorrowerLiabilityDetail, additionalLiabliltiy?: boolean) => {
    return post<any>(`BorrowerLiability/UpsertAsync?interimLoanStagingId=${interimLoanStagingId}&additionalLiabliltiy=${additionalLiabliltiy}`, liability);
};

export const updateAdditionalLiabilityAsync = (loanGuid: string, interimLoanStagingId: number, additionalLiability?: boolean) => {
    return post<any>(`BorrowerLiability/UpdateAdditionalLiabilityAsync?loanGuid=${loanGuid}&interimLoanStagingId=${interimLoanStagingId}&additionalLiability=${additionalLiability}`,null);
};

export const deleteLiability = (interimLoanStagingId: number, liability: BorrowerLiabilityDetail) => {
    return post<any>(`BorrowerLiability/DeleteLiability?interimLoanStagingId=${interimLoanStagingId}`, liability);
};

export const readAdditionalLiabilityStatus = (interimLoanStagingId: number) => {
    return get<any>(`BorrowerLiability/ReadAdditionalLiabilityStatus?interimLoanStagingId=${interimLoanStagingId}`);
};
