import React, { useEffect, useState } from "react";
import styles from "./BorrowerDocument.module.scss";
import { DocumentSectionDto } from "../models/DocumentSectionDto";
import { from, IGrouping } from "linq";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { getSectionsByLoanguid, removeSection } from "services/documentService";
import { useHistory } from "react-router";
import Infographic from "components/common/Infographic";
import RequiredDocumentInfographics from "../../../assets/infographics/documents/requiredDocs.svg";
import DocumentGroup from "./DocumentGroup";

const BorrowerDocuments = () => {
    const [sections, setSections] = useState<DocumentSectionDto[]>([]);
    const [groupings, setGroupings] = useState<
        IGrouping<string | null | undefined, DocumentSectionDto>[]
    >([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const loanGuid = useSelector(
        (state: ApplicationState) => state.loan?.loanGuid ?? ""
    );

    useEffect(() => {
        getData();
    }, [loanGuid]);

    const getData = async () => {
        if (loanGuid) {
            setShowSpinner(true);
            setSections([]);
            var result = await getSectionsByLoanguid(loanGuid);
            setShowSpinner(false);
            if (result.ok && !result.parsedBody?.hasError) {
                setSections(result.parsedBody?.body ?? []);
            }
        }
    };

    useEffect(() => {
        const categoryGroupings = from(sections)
            .groupBy((x) => x.category)
            .toArray();
        setGroupings(categoryGroupings);
    }, [sections]);

    return (
        <div className={styles.borrowerDocumentBG}>
        <div className={styles.gridContainer}>
            <div className={`${styles.gridItem} ${styles.gridItem1}`}>
                <Infographic src={RequiredDocumentInfographics} />
                </div>
                <>{showSpinner && <div className="screenLoader"><span className="loader medium"></span></div>}</>
            <div className={`${styles.gridItem} ${styles.borrowerDocumentRightBox}`}>
                {groupings?.map((value) => {
                    return (
                        <DocumentGroup
                            key={value.key()}
                            category={value.key()}
                            sections={value.toArray()}
                            getData={getData}
                        ></DocumentGroup>
                    );
                })}
            </div>
            </div>
            </div>
    );
};

export default BorrowerDocuments;
