import * as React from "react";
import Infographic from "../Infographic";

interface InfographicSideLayoutProps {
  children?: React.ReactNode;
  infographic?: string;
}

const InfographicSideLayout = (props: InfographicSideLayoutProps) => {
  return (
    <div className="infographic-side-layout-container">
      <div className="content">{props.children}</div>
      {props.infographic && (
        <div className="infographic-side-panel">
          <Infographic src={props.infographic} />
        </div>
      )}
    </div>
  );
};

export default InfographicSideLayout;
