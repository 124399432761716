import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { ManualAssetInformationState, KnownAction, ManualAssetInformation } from "../store/ManualAssetInformationStore";

const unloadedState: ManualAssetInformationState = {
    data: {
        id: "",
        assetType: "",
        holderName: "",
        accountIdentifier: "",
        urla2020CashOrMarketValueAmount: "",
        owner: ""
    },
    details: [] as ManualAssetInformation[],
    assets: [] as ManualAssetInformation[],
    systematicAssets: [] as ManualAssetInformation[],
    validation: undefined,
    errorMessage: ''
}

export const manualAssetInformationReducer: Reducer<ManualAssetInformationState> = (state: ManualAssetInformationState | undefined, incomingAction: Action): ManualAssetInformationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UpdateManualAssetProperty: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.propertyName]: action.value
                }
            };
        };
        case ActionTypes.CreateManualAssetInformation: {
            if (state.data) {
                let Assets = action.details;
                return {
                    ...state,
                    data: unloadedState.data,
                    details: Assets
                }
            }
            return state;
        };
        case ActionTypes.RemoveManualAssetItem: {
            if (state.details) {
                let copyItems = state.details.slice();
                const index = copyItems.findIndex(i => i.id === action.id);
                copyItems.splice(index, 1);
                return {
                    ...state,
                    details: copyItems
                }
            }
            return { ...state };
        };
        case ActionTypes.GetManualAssetItem: {
            if (state.details) {
                const copyItems = state.details;
                let copyData: any;
                copyData = copyItems.find(i => i.id === action.id);
                return {
                    ...state,
                    details: copyItems,
                    data: copyData
                }
            }
            return { ...state };
        };
        case ActionTypes.SetManualAssetValidation: {
            let copyValidation = { ...state.validation };
            if (action.validationError) {
                copyValidation[action.field] = action.validationError;
            }
            else {
                if (copyValidation[action.field]) {
                    delete copyValidation[action.field];
                }
            }
            return {
                ...state,
                validation: copyValidation
            };
        }
        case ActionTypes.RequestAsset:
            return {
                ...state
            };
        case ActionTypes.ReceiveAsset:
            return {
                ...state,
                details: action.details
            };
        case ActionTypes.ReceiveEncompassAsset:
            return {
                ...state,
                assets: action.assets
            };
        case ActionTypes.HandleErrorAsset:
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        case ActionTypes.ReceiveSystematicAsset:
            return {

                ...state,
                systematicAssets: action.systematicAssets
            };
        default:
            return state;
    }
}