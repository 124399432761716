import { get, post } from "../common/dataFetch";
import { Borrower } from "../store/employmentInformationStore";

export const getStates = () => {
    return get<any>("BorrowerEmploymentInformation/ReadByLoanGuidAsync");
};

export const getEmploymentInformation = (borrowerType: string, interimLoanStagingId:number) => {
    return get<any>(`BorrowerEmploymentInformation/ReadByInterimIdAsync?borrowerType=${borrowerType}&&interimLoanStagingId=${interimLoanStagingId}`);
};

export const updateEmploymentInformation = (borrowerEmploymentInformationDto: Borrower) => {
    return post<any>("BorrowerEmploymentInformation/UpsertAsync", borrowerEmploymentInformationDto);
};

export const updateEmploymentQuestionnaireToInterimLoanStaging = (borrowerEmploymentInformationDto: Borrower) => {
    return post<any>("BorrowerEmploymentInformation/UpdateEmploymentQuestionnaireToInterimLoanStaging", borrowerEmploymentInformationDto);
};

export const updateProgressTracker = (borrowerEmploymentInformationDto: Borrower, hasCoBorrower: boolean) => {
    return post<any>(`BorrowerEmploymentInformation/UpdateProgressTracker?hasCoBorrower=${hasCoBorrower}`, borrowerEmploymentInformationDto);
};


