import React, { useEffect, useState } from "react";
import Document from "./Document";
import { DocumentSectionDto } from "../models/DocumentSectionDto";
import { DocumentSectionStatus } from "../models/DocumentSectionStatus";
import { useDocumentActions } from "actions/documentActions";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import DocumentUpload from "./DocumentUpload";
import { deleteAttachment } from "../../../services/documentService";
import ModalPopup from "../../../components/common/ModalPopup";
import styles from "./BorrowerDocument.module.scss";
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import * as documentService from "../../../services/documentService";
import { IsNarrator } from "../../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
interface DocumentSectionProps {
    section: DocumentSectionDto;
    getData: () => void;
}

const DocumentSection = (props: DocumentSectionProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [showDocuments, setShowDocuments] = useState(false);
    const [hasDocuments, setHasDocuments] = useState(false);
    const [openModel, setModalState] = React.useState(false);
    const [sectionId, setSectionId] = React.useState<number>();
    const [showSpinner, setShowSpinner] = useState(false);
    const [openConfirmationModalPopup, setOpenConfirmationModalPopup] = useState(false);
    const [isDeleteddocument, setDeletedDocument] = useState({ loanGuid: "", dto: {} });

    const flipShow = () => {
        if (hasDocuments) {
            actions.AlterSectionExpandedStatus(
                props.section?.documentSectionId ?? -1,
                !showDocuments
            );
        }
    };
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }

    const downloadGiftLetter = () => {
        setShowSpinner(true);
        documentService.generateGiftLetterPdf(loanGuid)
            .then((response: any) => {
                if (response.status == 200 && response.data.size > 0) {
                    const file = new Blob([response.data], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);
                    const fileName = response.headers["x-file-name"];
                    downloadData(fileURL, fileName);
                }
                else {
                    console.error("there is some problem while generating Gift Letter pdf file");
                }
                setShowSpinner(false);
            })
            .catch((error: Error) => {
                setShowSpinner(false);
                console.error(error);
            });
    }

    const creditLoxDocument = () => {
        setShowSpinner(true);
        documentService.generateCreditLoxLetterPdf(loanGuid)
            .then((response: any) => {
                if (response.status == 200 && response.data.size > 0) {
                    const file = new Blob([response.data], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);
                    const fileName = response.headers["x-file-name"];
                    downloadData(fileURL, fileName);
                }
                else {
                    console.error("there is some problem while generating Credit Lox Document");
                }
                setShowSpinner(false);
            })
            .catch((error: Error) => {
                setShowSpinner(false);
                console.error(error);
            });
    }

    const downloadData = (fileURL: string, fileName: string) => {
        const save = document.createElement('a');
        if (typeof save.download !== 'undefined') {
            save.href = fileURL;
            save.target = '_blank';
            save.download = fileName;
            save.dispatchEvent(new MouseEvent('click'));
        } else {
            window.location.href = fileURL;
        }
    }

    const actions = useDocumentActions();
    const expandedSections = useSelector(
        (state: ApplicationState) => state.document?.expandedSections
    );
    const loanGuid = useSelector(
        (state: ApplicationState) => state.loan?.loanGuid ?? ""
    );

    const isLoanOriginatedFromEnc = useSelector(
        (state: ApplicationState) => state.loan?.isLoanFromEncompassFlag ?? ""
    );

    useEffect(() => {
        const sectionInExpandedArray =
            expandedSections?.includes(props.section?.documentSectionId ?? -1) ?? false;

        setShowDocuments(sectionInExpandedArray && hasDocuments);
    }, [expandedSections, hasDocuments]);

    useEffect(() => {
        setHasDocuments((props.section?.documents?.length ?? 0) > 0);
    }, [props.section]);

    const getStatus = (status: DocumentSectionStatus | null | undefined) => {
        switch (status) {
            case DocumentSectionStatus.Accepted:
                return styles.accepted;
            case DocumentSectionStatus.Requested:
                return styles.requested;
            case DocumentSectionStatus.Rejected:
                return styles.rejected;
            case DocumentSectionStatus.Submitted:
                return styles.submitted;
            case DocumentSectionStatus.Outstanding:
                return styles.outstanding;
            default:
                return <></>;
        }
    }

    const modal = (sectionId: number | undefined) => {
        setModalState(true);
        setSectionId(sectionId);
    }

    const submit = () => {
        setModalState(false);
        props.getData();
    }

    const deleteDocument = async (loanGuid: string, documentDto?: any) => {
        setOpenConfirmationModalPopup(true);
        setDeletedDocument({ loanGuid: loanGuid, dto: documentDto });
    }

    const confirmDelete = async () => {
        setShowSpinner(true);
        setOpenConfirmationModalPopup(false);
        let response = await deleteAttachment(isDeleteddocument.loanGuid, props.section.documentSectionId ?? 0, isDeleteddocument.dto);
        if (response && response.parsedBody && response.parsedBody.body) {
            throw new Error("Exception occurred while deleting the document.")
        }

        setShowSpinner(false);
        props.getData();
    }

    return (
        <>
            {showSpinner && <div className="screenLoader"><span className="loader medium"></span></div
            >}
            <div className={styles.statusTile}>
                <div className={styles.itemOne}>
                    <h6 onClick={flipShow}>
                        <div className={`${styles.statusIcons} ${getStatus(props.section.status)}`}></div>
                    </h6>
                </div>
                <div className={styles.itemTwo}>
                    {(props.section.title === "Fully Executed Gift Letter" || props.section.title === "Credit Authorization")
                        && <h5 className={(props.section.status == DocumentSectionStatus.Requested ? false : isLoanOriginatedFromEnc ? true : false) ? styles.disabledLink : styles.headingTitle} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.section.title)}>{props.section.title}</h5>}
                    {(props.section.title != "Fully Executed Gift Letter" && props.section.title != "Credit Authorization")
                        && <h5 className={(props.section.status == DocumentSectionStatus.Requested ? false : isLoanOriginatedFromEnc ? true : false) ? styles.disabledLink : styles.headingTitle}
                        onClick={(e: any) => modal(props.section.documentSectionId)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.section.title)}>
                            {props.section.title}</h5>}
                    <h5 className={(props.section.status == DocumentSectionStatus.Requested ? false : isLoanOriginatedFromEnc ? true : false) ? styles.disabledLink : `${styles.statusText} ${getStatus(props.section.status)}` }
                        onClick={flipShow}>{DocumentSectionStatus[props.section.status as any]}
                    </h5>
                    <hr />
                    <div className={styles.detailsRow}>
                        {showDocuments && (
                            <>
                                {props.section.documents?.map((value) => {
                                    return (
                                        <Document
                                            key={value.documentId}
                                            fileName={value.fileName}
                                            status={value.status}
                                            documentDto={value}
                                            deleteDocument={deleteDocument}
                                        ></Document>
                                    );
                                })}
                            </>
                        )}

                    </div>
                </div>
                <div className={styles.itemThree}>
                    <div className={styles.tooltip}>
                        <button><i className="fa fa-question-circle"></i></button>
                        <span className={styles.tooltiptext} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.section.description)}>{props.section.description}</span>
                    </div>

                    {(props.section.title != "Fully Executed Gift Letter" && props.section.title != "Credit Authorization") ?
                        <button onClick={(e: any) => modal(props.section.documentSectionId)}
                            disabled={props.section.status == DocumentSectionStatus.Requested ? false : isLoanOriginatedFromEnc ? true : false} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to document upload')}>
                            <i className="fa fa-upload"></i>
                        </button>
                        :
                        <button onClick={props.section.title == "Fully Executed Gift Letter" ? downloadGiftLetter : creditLoxDocument}
                            disabled={props.section.status == DocumentSectionStatus.Requested ? false : isLoanOriginatedFromEnc ? true : false}  >
                            <i className="fa fa-download"></i>
                        </button>
                    }
                    {props.section.title === "Fully Executed Gift Letter" &&
                        <button onClick={(e: any) => modal(props.section.documentSectionId)}
                            disabled={props.section.status == DocumentSectionStatus.Requested ? false : isLoanOriginatedFromEnc ? true : false}>
                            <i className="fa fa-upload"></i>
                        </button>
                    }
                </div>
                <hr></hr>
                <div>
                    <BrowserView>
                        <DocumentUpload
                            isOpen={openModel}
                            onCancel={setModalState}
                            sectionId={sectionId}
                            loanGuid={loanGuid}
                            onSubmit={submit}
                        />
                    </BrowserView>
                    <MobileView>
                        <DocumentUpload
                            isOpen={openModel}
                            onCancel={setModalState}
                            sectionId={sectionId}
                            loanGuid={loanGuid}
                            onSubmit={submit}
                        />
                    </MobileView>
                </div>

                <ModalPopup text="Are you sure you want to delete this document?"
                    onCancel={() => setOpenConfirmationModalPopup(false)}
                    isOpen={openConfirmationModalPopup} onSubmit={confirmDelete} />


            </div>
        </>
    );
};

export default DocumentSection;