import * as React from "react";
import { useNavigation } from "hooks/useNavigation";
import styles from "./manualAssets.module.scss";
import ManualAssetDetails from "../../components/manualAssetInformation/ManualAssetDetails";
import ManualTotalAsset from "../../components/manualAssetInformation/ManualTotalAsset";
import ManualAssetSummary from "../../components/manualAssetInformation/ManualAssetSummary";
import { ManualAssetFields } from "../../components/manualAssetInformation/ManualAssetFields";
import { ApplicationState } from "../../store";
import { ManualAssetInformationActionCreators } from "../../actions/manualAssetInformationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { ManualAssetInformation as ManualAsset, ManualAssetInformationState } from "../../store/ManualAssetInformationStore";
import { connect, useSelector } from "react-redux";
import * as assetInformationService from "../../services/assetInformationService";
import { BorrowerTypeEnum } from "../../common/BorrowerTypeEnum";
import { useEffect } from "react";
import { AssetInformationState } from "../../store/assetInformationStore";
import { AccountCreationState } from "../../store/accountCreationStore";
import { assetInformationActionCreators } from "../../actions/assetInformationActions";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type ManualAssetDetailsProps = {
    manualAssetInfo: ManualAsset;
    manualAssetList: ManualAsset[];
    validation: any;
    isError: any;
    isCoBorrowerPresent: boolean;
    loanGuid: string;
    history: any;
    isLoanComplated: boolean;
    interimLoanStagingId: number;
}
    & typeof ManualAssetInformationActionCreators
    & typeof TaskMenuCreators
    & typeof assetInformationActionCreators
    & typeof loanUpdateResponseActionCreators
    & ManualAssetInformationState;

export enum ManualAssetStep {
    ManualAssetForm = 1,
    ManualTotal = 2,
    ManualSummary = 3,
}
const ManualAssetInformation = (props: ManualAssetDetailsProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [currentStep, changeStep] = React.useState(0);
    const navigation = useNavigation();
    const { history } = props;
    const [disabled, setDisabled] = React.useState(false);
    const [showLoader, setLoader] = React.useState(false);
    const [borrowerType, setBorrowerType] = React.useState("");
    const assetInfo = useSelector((state: ApplicationState) => state.assetInformation as AssetInformationState);
    const accountInfo = useSelector((state: ApplicationState) => state.accountCreation as AccountCreationState);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);
    React.useEffect(() => {
        if (history.location.state?.args === "coborrower") {
            setBorrowerType("CoBorrower");
        }
        else if (history.location.state?.args === "borrower") {
            setBorrowerType("Borrower");
        }
    }, [history.location]);

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    useEffect(() => {
        if (props.loanGuid && borrowerType !== "") {
            getAssetInformation();
        }
    }, [props.loanGuid, borrowerType]);

    const getAssetInformation = async () => {
        if (props.interimLoanStagingId && props.interimLoanStagingId > 0) {
            setLoader(true);
            let response = await assetInformationService.getAll(borrowerType, props.interimLoanStagingId);
            if (response.ok && response.parsedBody) {
                let assets = response.parsedBody as ManualAsset[];
                if (assets && assets.length > 0) {
                    props.setAssetsInformation(assets);
                    changeStep(ManualAssetStep.ManualTotal);
                }
                else {
                    if (!props.isCoBorrowerPresent) {
                        props.updateManualAssetProprty(BorrowerTypeEnum.Borrower, ManualAssetFields.Owner);
                    }
                    else {
                        props.updateManualAssetProprty("", ManualAssetFields.Owner);
                    }
                    props.updateManualAssetProprty("", ManualAssetFields.HolderName);
                    props.updateManualAssetProprty("", ManualAssetFields.AccountIdentifier);
                    props.updateManualAssetProprty("", ManualAssetFields.Urla2020CashOrMarketValueAmount);
                    props.updateManualAssetProprty("", ManualAssetFields.AssetType);
                    changeStep(ManualAssetStep.ManualAssetForm);
                }
            }
            setLoader(false);
        }
    }

    const editAssetSet = (e: any) => {
        props.GetManualAssetFromState(e);
        changeStep(ManualAssetStep.ManualAssetForm);
    }

    const deleteAssetSet = (e: any) => {
        props.removeAssetFromInterim(e, props.interimLoanStagingId);
        props.removeManualAssetFromState(e);
    }

    const AddAnother = () => {
        if (!props.isCoBorrowerPresent) {
            props.updateManualAssetProprty(BorrowerTypeEnum.Borrower, ManualAssetFields.Owner);
        }
        else {
            props.updateManualAssetProprty("", ManualAssetFields.Owner);
        }
        props.updateManualAssetProprty("", ManualAssetFields.HolderName);
        props.updateManualAssetProprty("", ManualAssetFields.AccountIdentifier);
        props.updateManualAssetProprty("", ManualAssetFields.Urla2020CashOrMarketValueAmount);
        props.updateManualAssetProprty("", ManualAssetFields.AssetType);
        changeStep(ManualAssetStep.ManualAssetForm)
    }

    const onBack = () => {
        // for borrower
        if (currentStep === ManualAssetStep.ManualAssetForm && borrowerType === "Borrower") {
            history.push({ pathname: "/asset", state: { args: "borrower" } });
        }
        else if (currentStep === ManualAssetStep.ManualTotal && borrowerType === "Borrower") {
            if (!props.isCoBorrowerPresent) {
                props.updateManualAssetProprty(BorrowerTypeEnum.Borrower, ManualAssetFields.Owner);
            }
            history.push({ pathname: "/asset", state: { args: "borrower" } });
        }
        else if (currentStep === ManualAssetStep.ManualSummary) {
            changeStep(ManualAssetStep.ManualTotal)
        }
        // for coborrower
        if (currentStep === ManualAssetStep.ManualAssetForm && borrowerType === "CoBorrower") {
            history.push({ pathname: "/asset", state: { args: "coborrower" } });
        }
        else if (currentStep === ManualAssetStep.ManualTotal && borrowerType === "CoBorrower") {
            if (!props.isCoBorrowerPresent) {
                props.updateManualAssetProprty(BorrowerTypeEnum.Borrower, ManualAssetFields.Owner);
            }
            history.push({ pathname: "/asset", state: { args: "coborrower" } });
        }
    }

    const onContinue = async () => {
        if (currentStep === ManualAssetStep.ManualAssetForm) {
            if (!props.isLoanComplated) {
                await createManualAssetInformation(props.manualAssetInfo);
            }
            else {
                await props.getAssetInformation(borrowerType, props.interimLoanStagingId); //Get all asset by LoanGuid
            }
            changeStep(ManualAssetStep.ManualTotal)
        }
        else if (currentStep === ManualAssetStep.ManualTotal) {
            await props.getAssetInformation(borrowerType, props.interimLoanStagingId);
            changeStep(ManualAssetStep.ManualSummary)
        }
        else if (currentStep === ManualAssetStep.ManualSummary && borrowerType === "Borrower") {
            if (!props.isLoanComplated) {
                await updateProgressTracker();
            }
            else if (accountInfo.borrower.printOnAdditionalBorrowerPage && borrowerType == "Borrower") {
                history.push({ pathname: "/asset", state: { args: "coborrower" } });
            }
            else {
                history.push({ pathname: "/reo-properties" });
            }
        }
        else if (currentStep === ManualAssetStep.ManualSummary && borrowerType === "CoBorrower") {
            if (!props.isLoanComplated) {
                await updateProgressTracker();
            }
            history.push({ pathname: "/reo-properties" });
        }
    }

    const createManualAssetInformation = async (data: ManualAsset) => {
        setLoader(true);
        let response = await assetInformationService.updateAsset(data, props.interimLoanStagingId);
        if (response.ok) {
            await props.getAssetInformation(borrowerType, props.interimLoanStagingId);
        }
        setLoader(false);
    }

    const updateProgressTracker = async () => {
        setLoader(true);
        let response = await assetInformationService.updateProgressTracker(borrowerType, props.loanGuid,
            accountInfo.borrower.printOnAdditionalBorrowerPage ? true : false);
        if (response) {
            if (currentStep === ManualAssetStep.ManualSummary && accountInfo.borrower.printOnAdditionalBorrowerPage && borrowerType == "Borrower") {
                history.push({ pathname: "/asset", state: { args: "coborrower" } });
            }
            else {
                history.push({ pathname: "/reo-properties" });
            }
        }
    }

    let manualAssetPage = <></>;
    if (currentStep === ManualAssetStep.ManualAssetForm) {
        manualAssetPage = (
            <>
                <ManualAssetDetails isDisable={setDisabled} isLoanComplated={props.isLoanComplated} borrowerType={borrowerType} />
                {navigation.SetBackOnClick(
                    () => onBack()
                )}
                {navigation.ShowContinue(
                    () => onContinue(),
                    ((!disabled &&
                        (props.manualAssetInfo?.assetType == "" ||
                            props.manualAssetInfo?.holderName == "" ||
                            props.manualAssetInfo?.urla2020CashOrMarketValueAmount == "" ||
                            props.manualAssetInfo?.owner == "")
                    ) || (disabled &&
                        (
                            props.manualAssetInfo?.assetType == "" ||
                            props.manualAssetInfo?.owner == ""
                        )) ||
                        showLoader || isEncompassDown
                    )
                )}
            </>
        );
    }
    else if (currentStep === ManualAssetStep.ManualTotal) {
        manualAssetPage = (
            <>
                <ManualTotalAsset />
                <div className={styles.addCartBtn}>
                    <button disabled={props.isLoanComplated} onClick={() => AddAnother()} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press buttton to Add Another Account')}>
                        <i className="fa fa-plus-circle" aria-hidden="true"> </i>Add Another Account
                        </button>
                </div>
                {navigation.SetBackOnClick(
                    () => onBack()
                )}
                {navigation.ShowContinue(
                    () => onContinue(),
                    isEncompassDown
                )}
            </>
        );
    }
    else if (currentStep === ManualAssetStep.ManualSummary) {
        manualAssetPage = (
            <>
                <ManualAssetSummary editAsset={editAssetSet} deleteAsset={deleteAssetSet} disabled={props.isLoanComplated} />
                {navigation.SetBackOnClick(
                    () => onBack()
                )}
                {navigation.ShowContinue(
                    () => onContinue(), showLoader || isEncompassDown
                )}
            </>
        );
    }


    return (
        <div className="w-100-p">
            {manualAssetPage}
            {showLoader &&
                <div className="screenLoader"><div className="loader medium"></div></div>
            }
        </div>
    );
}
const mapStateToProps = (state: ApplicationState): any => {
    const { manualAssetInformation } = state;
    let manualAssetInfo;
    let manualAssetList;
    let validation;
    let isCoBorrowerPresent;
    let loanGuid;
    let interimLoanStagingId;
    let isLoanComplated = state.loan?.isLoanCompleted;

    if (manualAssetInformation) {
        manualAssetInfo = manualAssetInformation.data;
        manualAssetList = manualAssetInformation.details;
        validation = manualAssetInformation.validation;
    }
    if (state.accountCreation !== undefined) {
        if (state.accountCreation?.coBorrower?.firstName != undefined) {
            isCoBorrowerPresent = true;
        }
        loanGuid = state.accountInformation?.loanGuid
    }
    if (state.loan) {
        interimLoanStagingId = state.loan.interimLoanStagingId;
    }
    return {
        manualAssetInfo,
        manualAssetList,
        validation,
        isCoBorrowerPresent,
        loanGuid,
        isLoanComplated,
        interimLoanStagingId
    }
};
const mapDispatchToProps = (dispatch: any) => ({
    createManualAssetInformation: (interimLoanStagingId: number, manualAssetInformationdetail: ManualAsset, borrowerType: string) => {
        dispatch(ManualAssetInformationActionCreators.createManualAssetInformation(interimLoanStagingId, manualAssetInformationdetail, borrowerType));
    },
    removeManualAssetFromState: (id: string) => {
        dispatch(ManualAssetInformationActionCreators.removeManualAssetFromState(id));
    },
    GetManualAssetFromState: (id: string) => {
        dispatch(ManualAssetInformationActionCreators.GetManualAssetFromState(id));
    },
    updateManualAssetProprty: (value: string, propertyName: string) => {
        dispatch(ManualAssetInformationActionCreators.updateManualAssetProprty(value, propertyName));
    },
    getAssetInformation: (borrowerType: string, interimLoanStagingId: number) => {
        dispatch(ManualAssetInformationActionCreators.getAssetInformation(borrowerType, interimLoanStagingId));
    },
    removeAssetFromInterim: (id: string, interimLoanStagingId: number) => {
        dispatch(ManualAssetInformationActionCreators.removeAssetFromInterim(id, interimLoanStagingId));
    },
    updateNavigationStatus: (taskItemId: any, subTaskItemId: any, taskStatusId: any, subTaskStatusId: any, loanGuid: any) => {
        dispatch(TaskMenuCreators.updateNavigationStatus(taskItemId, subTaskItemId, taskStatusId, subTaskStatusId, loanGuid));
    },
    setLoanIsDirty: (isDirty: boolean) => {
        dispatch(TaskMenuCreators.setLoanIsDirty(isDirty));
    },
    setAssetsInformation: (assets: ManualAsset[]) => {
        dispatch(ManualAssetInformationActionCreators.setAssetsInformation(assets));
    },
    createAsset: (borrowerType: string, interimLoanStagingId: number) => {
        dispatch(ManualAssetInformationActionCreators.createAsset(borrowerType, interimLoanStagingId));
    },
    updateCoBorrowerAssetStatus: (value: boolean) => {
        dispatch(assetInformationActionCreators.updateCoBorrowerAssetStatus(value));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(ManualAssetInformation as any);