import React from "react";
import styles from "./LoanOfficerDocument.module.scss";
import DocumentSection from "./DocumentSection";
import { DocumentSectionDto } from "../models/DocumentSectionDto";

interface DocumentGroupProps {
    sections: DocumentSectionDto[];
    category: string | null | undefined;
    onRemoveSectionClick: (section: DocumentSectionDto) => void;
}
const DocumentGroup = (props: DocumentGroupProps) => {
    return (
        <div>
            <h5 className={styles.groupHeader}>{props.category}</h5>
            {props.sections?.map((value,index) => {
                return (
                    <>
                        <DocumentSection
                            key={value.documentSectionId}
                            section={value}
                            onRemoveClick={props.onRemoveSectionClick}
                        ></DocumentSection>
                    </>
                );
            })}
        </div>
    );
};

export default DocumentGroup;
