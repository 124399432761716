export enum AccountInformationInterfaces {
    LoanPurpose = 1,
    OccupancyType = 2,
    PropertyType = 3,
    ClosingDate = 4,
    Priority = 5,
    BorrowerInformation = 6,
    HowManyUnits = 7
}

export enum LoanPurposeChoices {
    Purchase = "Purchase",
    Refinance = "Refinance"
}

export enum OccupancyTypeChoices {
    PrimaryResidence = "Primary Residence",
    SecondHome = "Second Home",
    Investment = "Investment"
}

export enum PropertyTypeChoices {
    SingleFamily = "Single Family",
    MultiUnit = "Multi-Unit",
    Condominium = "Condominium"
}

export enum ClosingDateChoices {
    Asap = "ASAP",
    WithinThirtyDays = "Within 30 Days",
    TwoToThreeMonths = "2 - 3 Months",
    ThreeToSixMonths = "3 - 6 Months",
    NotSure = "Not Sure",
    JustBeginningToLook = "Just Beginning To Look"
}

export enum PriorityChoices {
    LowerMonthlyPayment = "Lower Monthly Payment",
    PayOffMortgageFaster = "Pay Off Mortgage Faster",
    TakeCashOutOfMyHome = "Take Cash Out Of My Home"
}

export enum HowManyUnitsChoices {
    Two = 2,
    Three = 3,
    Four = 4
}