import React, { useEffect, useState } from "react";
import AddDocumentSectionOptionList from "./AddDocumentSectionOptionList";
import styles from "./AddDocumentSection.module.scss";
import { useInitialMount } from "hooks/useInitialMount";
import { getSectionOptions, addNewSection } from "services/documentService";
import { from } from "linq";
import { DocumentRequiredViewModel } from "../models/DocumentRequiredViewModel";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { useNavigation } from "hooks/useNavigation";

const AddDocumentSection = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [documentList, setDocumentList] = useState([""]);
    const [documentTypes, setDocumentTypes] = useState([""]);
    const [selectedType, setSelectedType] = useState("");
    const [selectedDocument, setSelectedDocument] = useState("");
    const [allOptions, setAllOptions] = useState<DocumentRequiredViewModel[]>([]);
    const [notes, setNotes] = useState<string | undefined>(undefined);
    const navigation = useNavigation();
    const isInitialMount = useInitialMount();
    const history = useHistory();

    const loanGuid = useSelector((state: ApplicationState) => state.loan?.loanGuid ?? "");
    if (loanGuid === "") {
        history.push({ pathname: "/" });
    }

    const getOptions = async () => {
        var response = await getSectionOptions();
        if (response.ok && !response.parsedBody?.hasError) {
            setAllOptions(response.parsedBody?.body ?? []);
        }
    };

    const addSection = async () => {
        var response = await addNewSection({
            category: selectedType,
            description: notes ?? "",
            loanGuid: loanGuid,
            title: selectedDocument,
        });
        if (response.ok && !response.parsedBody?.hasError) {
            history.goBack();
        }
    };

    useEffect(() => {
        if (isInitialMount) {
            getOptions();
        }
    }, [isInitialMount]);

    useEffect(() => {
        const distinctTypes = from(allOptions)
            .select((x) => x.documentType)
            .distinct()
            .toArray();

        setDocumentTypes(distinctTypes);

        if (distinctTypes?.length > 0) {
            setSelectedType(distinctTypes[0]);
        }
    }, [allOptions]);

    useEffect(() => {
        setSelectedDocument("");
    }, [documentList]);

    useEffect(() => {
        if (selectedType) {
            setDocumentList(
                from(allOptions)
                    .where((x) => x.documentType === selectedType)
                    .select((x) => x.document)
                    .distinct()
                    .toArray()
            );
            setSelectedDocument("");
        }
    }, [selectedType]);

    return (
        <div className={styles.addDocumentSection}>
            <div className={styles.documentTypeSelector}>
                <h5 className={styles.headerLabel}>Document Type:</h5>
                <div className={styles.select}>
                <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>                 
                    {documentTypes?.map((value) => {
                        return (
                            <option value={value} key={value}>
                                {value}
                            </option>
                        );
                    })}
                </select>
                    </div>
            </div>
            <AddDocumentSectionOptionList
                document={selectedDocument}
                documentList={documentList}
                onSelectionMade={(doc) => setSelectedDocument(doc)}
            ></AddDocumentSectionOptionList>
            <div className={`card ${styles.addDocumentSectionDialog}`}>
                <h5 className={styles.headerLabel}>Add Document?</h5>
                <div>
                    <label>Document:</label>
                    <p>{selectedDocument}</p>
                </div>
                <div className="customNotes">
                    <label>Custom Notes:</label>
                    <textarea onChange={(e) => setNotes(e.target.value)}></textarea>
                </div>
                <div className={styles.btnContainer}>
                    <button className={`common-green-button ${styles.basicBtn}`} onClick={() => addSection()}>
                        Confirm
          </button>
                    <button className={`common-button ${styles.warningBtn}`} onClick={() => history.goBack()}>
                        Cancel
          </button>
                </div>
                <>{isLoading && <span className="loader medium"></span>}</>
            </div>

            {navigation.SetBackOnClick(
                () => history.goBack()
            )}
        </div>
    );
};

export default AddDocumentSection;
