import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ApplicationState } from "store";
import { DocumentDto } from "../models/DocumentDto";
import { DocumentStatus } from "../models/DocumentStatus";
import styles from "./LoanOfficerDocument.module.scss";
import Tooltip from "../../common/InfoIconTooltip";

interface DocumentProps {
    fileName?: string | null | undefined;
    status?: DocumentStatus | null | undefined;
    documentDto?: DocumentDto | null | undefined;
    documentList?: any;
}

const Document = (props: DocumentProps) => {
    const history = useHistory();
    const loanGuid = useSelector(
        (state: ApplicationState) => state.loanOfficerMainTab?.loanGuid ?? ""
    );
    const openDocument = () => {
        history.push("/view-document", {
            loanGuid: loanGuid,
            documentDto: props.documentDto,
            viewerActions: true,
            documentList: props.documentList
        });
    };

    const getStatus = (status: DocumentStatus | null | undefined) => {
        switch (status) {
            case DocumentStatus.Accepted:
                return styles.accepted;
            case DocumentStatus.Replaced:
                return styles.discarded;
            case DocumentStatus.Rejected:
                return styles.rejected;
            case DocumentStatus.Submitted:
                return styles.received;
            default:
                return <></>;
        }
    }

    return (
        <div className={styles.document} onClick={() => openDocument()}>
            <h5>{props.fileName}</h5>
            <div className={props.status === DocumentStatus.Rejected ? `${styles.documentStatus} ${styles.rejectedStatus}` : `${styles.documentStatus} ${styles.status}`}>
                <p className={`${styles.statusText} ${getStatus(props.status)}`}>{DocumentStatus[props.status as any]}</p>
                <span>{props?.documentDto?.rejectionComment && <Tooltip iconClass="fa fa-exclamation-circle">
                    {props?.documentDto?.rejectionComment}</Tooltip>}</span>
            </div>
        </div>
    );
};
export default Document;
