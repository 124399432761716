export enum BorrowerLiabilityFields {
    Id = "id",
    LiabilityType = "liabilityType",
    HolderName = "holderName",
    AccountIdentifier = "accountIdentifier",
    UnpaidBalanceAmount = "unpaidBalanceAmount",
    MonthlyPaymentAmount = "monthlyPaymentAmount",
    Owner = "owner",
    PrintOnAdditionalBorrowerPage = "printOnAdditionalBorrowerPage",
    IsNew = "isNew"
}