import { ResponseDto } from "common/models/ResponseDto";
import { post, get } from "../common/dataFetch";
import { BorrowerTypeEnum } from "../components/common/Enums/BorrowerTypeEnum";

export const eConsent = (guid: any, borrowerType: any, hasConsented: boolean, interimLoanStagingId:number) => {
	return post<ResponseDto<any>>("api/EConsent/SetLoanEConsent", {
		LoanGuid: guid,
		BorrowerType: borrowerType === BorrowerTypeEnum.CoBorrower ? 2 : 1,
		UserHasConsented: hasConsented,
		InterimLoanStagingId: interimLoanStagingId
	});
};

export const generateSignedEConsentDocument = (guid: any, borrowerType: any, currentTimestamp: any) => {
	return post<ResponseDto<any>>("api/EConsent/GenerateSignedEConsentDocument", {
		LoanGuid: guid,
		BorrowerType: borrowerType === BorrowerTypeEnum.CoBorrower ? 2 : 1,
		CurrentTimestamp: currentTimestamp
	});
};
export const UpdateSsnFields = (loanGuid: any) => {
	return get<ResponseDto<any>>(`api/EConsent/UpdateSsnFields?loanGuid=${loanGuid}`);
};