import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useOktaAuth } from "@okta/okta-react";
import * as OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import userSignInStyles from "./UserSignIn.module.scss";
import styles from "../common/MessageBox.module.scss";
import SessionInfo from "../../SessionDataTracker";
import { duplicateEmail, validateSocialSecurityNumber } from '../../services/accountInformationService';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";

const UserSignIn = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const history = useHistory();
    const location = useLocation();
    const [loginHint, setLoginHint] = React.useState("");

    // modal popup diplay change for shared email
    const [openModal, setOpenModal] = React.useState(false);
    const [ssn, setSsn] = React.useState("");
    const [showError, setShowError] = React.useState(false);
    const [user, setUser] = React.useState({ email: "" });
    const [isLoading, setLoading] = React.useState(false);

    useEffect(() => {
        //This is from new user registration email and Okta redirects the 
        //user here after the new account has been verified.
        //Set the location.state to let the user sign in.
        if (location.state == undefined) {
            const queryStringValues = queryString.parse(location.search);
            if (queryStringValues["type_hint"] === "EMAIL_VERIFICATION" ||
                queryStringValues["type_hint"] === "PASSWORD_RECOVERY") {
                location.state = { action: "sign-in", borrowerType: "Unknown" };
                setLoginHint(queryStringValues["login_hint"] as string);
            }
            else if (queryStringValues["type_hint"] === "OKTA_REDIRECT") {
                location.state = { action: "sign-in", borrowerType: "Unknown" };
                oktaAuth.getUser().then((user) => {
                    history.push({ pathname: "/sign-in", state: location.state });
                    return () => { };
                });
            } else {
                history.push({ pathname: "/" });
                return () => { };
            }
        }

        const config = SessionInfo.config;
        const issuer = config.issuer;
        const scopes = config.scopes;
        const clientId = config.clientId;
        const redirectUri = config.redirectUri;
        const widget = new OktaSignIn({
            baseUrl: issuer.split("/oauth2")[0],
            clientId,
            redirectUri,
            logo: "/uhm-logo.png",
            username: loginHint,
            i18n: {
                en: {
                    "primaryauth.title": "Sign in with your email address",
                },
            },
            colors: {
                brand: "#3d1951"
            },
            authParams: {
                // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
                issuer,
                scopes,
            },
            useInteractionCodeFlow: config.useInteractionCode, // Set to true, if your org is OIE enabled
            features: {
                rememberMe: false,
                showPasswordToggleOnSignInPage: true,
                registration: false
            },
        });

        const autoLaunchDisclosureTile = async (oktaWidget: any) => {
            if (location.state.action === "SSO" && location.state.qs === "") {
                const oktaAuthClient = oktaWidget.authClient;
                const sessionExists = await oktaAuthClient.session.exists();
                if (sessionExists) {
                    const session = await oktaAuthClient.token.getWithoutPrompt({ responseType: ["token", "id_token"] });
                    if (session?.tokens?.accessToken?.value) {
                        history.push({ pathname: "/ecc", search: location.state.qs, state: location.state });
                        return true;
                    }
                }
            }

            return false;
        }

        autoLaunchDisclosureTile(widget).then((autoLaunch: Boolean) => {
            if (autoLaunch === false) {
                widget.renderEl(
                    { el: "#OktaLoginWidget" },
                    (res: any) => {
                        SessionInfo.tokens = res?.tokens;
                        oktaAuth.tokenManager.setTokens(SessionInfo.tokens);

                        if (res.status === "FORGOT_PASSWORD_EMAIL_SENT") {
                            history.push({ pathname: "/sign-in", state: { action: "password-reset" } });
                        }
                        else if (res.tokens == undefined) {
                            history.push({ pathname: "/" });
                        }
                        else {
                            oktaAuth.handleLoginRedirect(res.tokens).then(result => {
                                //fetch login detail from Okta.
                                oktaAuth.getUser().then(async (user: any) => {
                                    setUser({ email: user.email });
                                    if (user.email) {
                                        let response = await isSharedEmail(user.email);
                                        if (response) {
                                            setOpenModal(true);
                                        } else {
                                            history.push({ pathname: "/sign-in", state: location.state });
                                        }
                                    } else {
                                        console.error("User details not found!");
                                    }
                                });

                            });
                        }
                    },
                    (err: any) => {
                        console.error(err);
                        throw err;
                    },
                );
            }
        });

        widget.on("afterError", (context: any, error: any) => {
            console.error("Okta", error);
        });

        return () => widget.remove();
    }, [oktaAuth]);

    const isSharedEmail = async (email: string) => {
        return await duplicateEmail(email).then((res) => {
            return res.body && res.parsedBody;
        });
    }

    const checkValidSsn = (ssn: string, email: string) => {
        validateSocialSecurityNumber(ssn, email).then((res) => {
            setLoading(false);
            if (isNullOrWhiteSpace(res.parsedBody)) {
                setShowError(true);
            } else {
                setOpenModal(false);
                history.push({ pathname: "/sign-in", state: { ...location.state, borrowerType: res.parsedBody } });
            }
        }).catch((e: any) => {
            setLoading(false);
            console.error("Following exception occurred:", e);
        });
    }

    const closeModal = () => {
        setOpenModal(false);
        setSsn("");
        window.location.reload();
    }

    return (<>
        <div>
            <div id="OktaLoginWidget" />
        </div>

        <Modal className={`${userSignInStyles.signInModal} ${styles.modal}`} isOpen={openModal}>
            <ModalHeader className={styles.modalHeader} toggle={closeModal}>
                <div className={styles.title}>Need SSN Verification</div>
            </ModalHeader>
            <ModalBody className={styles.modalBody}>
                <label>Enter last 4 digits of your social security number <span className={styles.requiredMsg}> * </span></label>

                <NumberFormatInput
                    name="socialSecurityNumber"
                    className={styles.input}
                    value={ssn ?? ""}
                    format="####"
                    onValueChange={(values: { formattedValue: any; value?: any; }) => {
                        setSsn(values.value);
                    }}
                />

                {showError &&
                    <span className={styles.errmsg}>Please enter a valid social security number.</span>}
            </ModalBody>
            {isLoading && <div className="loader small" />}

            <ModalFooter>
                <div className="content align-center">
                    <button className={`common-green-button ${styles.button}`} onClick={() => {
                        if (isNullOrWhiteSpace(ssn)) {
                            setShowError(true);
                        } else {
                            setLoading(true);
                            checkValidSsn(ssn, user.email);
                        }
                    }}> SUBMIT </button>

                    <button className={`common-button ${styles.button}`} onClick={closeModal}> CLOSE </button>
                </div>
            </ModalFooter>
        </Modal>

    </>
    );
};

export default UserSignIn;
