import { Action, Reducer } from "redux";
import { DocumentState, KnownAction } from "../store/documentStore";
import * as ActionTypes from "../common/ActionTypes";
import { from } from "linq";

const unloadedState: DocumentState = {
  selectedTab: 0,
  expandedSections: [],
};

export const documentReducer: Reducer<DocumentState> = (
  state: DocumentState | undefined,
  incomingAction: Action
): DocumentState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case ActionTypes.AlterSectionExpandedStatus: {
      if (action.isOpen) {
        return {
          ...state,
          expandedSections: from([...state.expandedSections, action.sectionId])
            .distinct()
            .toArray(),
        };
      } else {
        return {
          ...state,
          expandedSections:
            state.expandedSections?.filter((x) => x !== action.sectionId) ?? null,
        };
      }
    }
    case ActionTypes.AlterSelectedTab: {
      return {
        ...state,
        selectedTab: action.tabIndex,
      };
    }

    default:
      return state;
  }
};
