import * as React from "react";
import { post } from "../../common/dataFetch";

let loanGuid: string;

const callExecuteTask = function () {

    //<TODO> may check here if user session is alive otherwise return.

    if (!loanGuid) { return; }

    post<boolean>(`LoanApplicationBackgroundTask/ExecuteNext?LoanGuid=${loanGuid}`, {})
        .then(response => {
            var haveMoreTasksToRun = response.parsedBody;
            if (haveMoreTasksToRun) {                   //if have more tasks in the bucket,
                TriggerOnceImmediate();                //then call again almost immediately.
            }
        });
}

const TriggerOnceImmediate = () => {
    setTimeout(callExecuteTask, 200);
}

const Initialize = (_loanGuid: string) => {
    loanGuid = _loanGuid;
    TriggerOnceImmediate();                 //call once, upon initialization
    setInterval(callExecuteTask, 30000);    //keep triggering on retular intervals.
};

class backgroundEngine {
    InitializeAndRun = Initialize;
    TriggerOnceImmediate = TriggerOnceImmediate;
}

export var BackgroundEngine = new backgroundEngine();