import * as React from "react";
import OtherIncomeSourceInformationItem from "./OtherIncomeSourceInformationItem";
import { OtherIncomeSourceDetail, AdditionalIncomeState, AdditionaIIncome } from "../../store/additionalIncomeStore";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import { AdditionalIncomeActionCreators } from "../../actions/additionalIncomeActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import * as additionalIncomeService from "../../services/additionalIncomeService";
import { BorrowerTypeEnum } from "components/common/Enums/BorrowerTypeEnum";
import _uniqueId from 'lodash/uniqueId';
import styles from "./additionalIncome.module.scss";
import NavigationWrapper from "../common/NavigationWrapper";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { IsNarrator } from "../../common/CommonMethods";

export enum AdditionalIncomeStep {
    BorrowerAdditonalIncomeStatus = 1,
    CoBorrowerAdditonalIncomeStatus = 2
}

interface AdditionlIncomeInterface {
    currentStep: number;
    owner: string;
    additionalIncomeValid: boolean;
    borrowerAdditionalIncomeStatus: any;
    coBorrowerAdditionalIncomeStatus: any;
    IsContinueDisabled?: boolean;
    OnContinueClick?: () => void;
    OnBackClick?: () => void;
    ResetContinue?: any;
    navigateToHome?: () => void;
}

type AdditionaIIncomeProps = {
    additionalIncomeDetail: AdditionaIIncome;
    isLoading: boolean;
    loanGuid: string;
    hasData: boolean;
    validation: any;
    borrowerName: string;
    IsCoborrowerPresent: boolean;
    coborrowerName: string;
    history: any;
    isLoanCompleted: boolean;
    isEncompassDown: boolean;
    interimLoanStagingId: number;
}
    & typeof AdditionalIncomeActionCreators
    & typeof TaskMenuCreators
    & typeof loanUpdateResponseActionCreators
    & AdditionalIncomeState;

class AdditionalIncomeInformation extends React.Component<AdditionaIIncomeProps, AdditionlIncomeInterface> {
    unlisten: any;
    constructor(props: any) {
        super(props);
        this.state = {
            currentStep: 0,
            owner: "",
            additionalIncomeValid: false,
            borrowerAdditionalIncomeStatus: undefined,
            coBorrowerAdditionalIncomeStatus: undefined,
            IsContinueDisabled: false
        };
        this.addOtherIncomeSourceItembtnClick = this.addOtherIncomeSourceItembtnClick.bind(this);

        this.unlisten = this.props.history.listen((location: any) => {
            if (location?.pathname === "/additional-income") {
                this.resetContinue();
                if (location?.state?.args === "borrower") {
                    this.changeStep(AdditionalIncomeStep.BorrowerAdditonalIncomeStatus);
                } else {
                    this.changeStep(AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus);
                }
            }

        });
    }

    speakText = (textToSpeak: string) => {
        if (IsNarrator()) {
            let text = new SpeechSynthesisUtterance(textToSpeak);
            speechSynthesis.speak(text);
        }
    }

    stopSpeaking = () => {
        speechSynthesis.cancel();
    }

    componentWillUnmount() {
        //preventing history.listen call in every route change.
        this.unlisten();
    }

    async componentWillMount() {
        this.props.isLoadingAdditionalIncome(true);

        if (this.props.additionalIncomeDetail?.otherIncomeSourceDetails.length <= 0) {
            await additionalIncomeService.getAll(this.props.interimLoanStagingId)
                .then((response: any) => {
                    return response.parsedBody as OtherIncomeSourceDetail[];
                })
                .then((data: OtherIncomeSourceDetail[]) => {
                    if (data && data.length > 0) {
                        data.forEach((e: OtherIncomeSourceDetail) => {
                            const emptyaddOtherIncomeSourceDetail: OtherIncomeSourceDetail = {
                                id: _uniqueId('uniqueId-'),
                                description: e.description == null ? "" : e.description,
                                monthlyAmount: e.monthlyAmount == null ? "" : e.monthlyAmount,
                                owner: e.owner == null ? "" : e.owner,
                                isNew: false
                            };
                            this.props.addOtherIncomeSourceToState(emptyaddOtherIncomeSourceDetail);
                        });
                    }
                })
        }

        await additionalIncomeService.readIncomeStatus(this.props.interimLoanStagingId).then((res: any) => {
            if (res) {
                this.props.isLoadingAdditionalIncome(false);
                this.setState({
                    borrowerAdditionalIncomeStatus: res.parsedBody.borrowerAdditionalIncomeStatus,
                    coBorrowerAdditionalIncomeStatus: res.parsedBody.coBorrowerAdditionalIncomeStatus
                })
            }
        }).catch((e: any) => {
            console.error("Following ex occurred: ", e);
            this.props.isLoadingAdditionalIncome(false);
        })   
    }

    changeStep(step: number) {
        this.setState({
            ...this.state,
            currentStep: step
        }, () => { this.setOwner() });
    };

    moveToEmployment = () => {
        const { history } = this.props;
        if (history) {
            history.push({ pathname: "/employment-information", state: { args: this.props.IsCoborrowerPresent ? "coborrower" : "borrower" } });
        }
    };

    moveToAsset = () => {
        const { history } = this.props;
        if (history) {
            history.push({ pathname: "/asset" });
        }
    };

    resetContinue() {
        this.setState({
            ...this.state,
            OnContinueClick: undefined,
            OnBackClick: () => { this.moveBack(); }
        }, () => {
            this.ShowContinue();
        });
    }

    setOwner = () => {
        if (this.state.currentStep == AdditionalIncomeStep.BorrowerAdditonalIncomeStatus) {
            this.setState({ ...this.state, owner: BorrowerTypeEnum.Borrower });
        }
        else if (this.state.currentStep == AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus) {
            this.setState({ ...this.state, owner: BorrowerTypeEnum.CoBorrower });
        }
    };

    isContinueDisableBorrower = () => {
        if ((this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && (this.state.borrowerAdditionalIncomeStatus === undefined ||
            this.state.borrowerAdditionalIncomeStatus === null))
            || (this.state.borrowerAdditionalIncomeStatus && (this.validateOtherIncomeSoureList()) || (this.props?.validation && Object.keys(this.props?.validation).length > 0))
        ) {
            if (!this.state.IsContinueDisabled) {
                this.setState({ ...this.state, IsContinueDisabled: true });
            }
        } else {
            if (this.state.IsContinueDisabled) {
                this.setState({ ...this.state, IsContinueDisabled: false });
            }
        }
    };

    isContinueDisableCoBorrower = () => {
        if ((this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus && (this.state.coBorrowerAdditionalIncomeStatus === undefined ||
            this.state.coBorrowerAdditionalIncomeStatus === null))
            || (this.state.coBorrowerAdditionalIncomeStatus && this.validateOtherIncomeSoureList() || (this.props?.validation && Object.keys(this.props?.validation).length > 0))
        ) {
            if (!this.state.IsContinueDisabled) {
                this.setState({ ...this.state, IsContinueDisabled: true });
            }
        } else {
            if (this.state.IsContinueDisabled) {
                this.setState({ ...this.state, IsContinueDisabled: false });
            }
        }
    };

    async componentDidUpdate() {
        // set continue button disabled/enabled.
        if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus) {
            this.isContinueDisableBorrower();
        } else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus) {
            this.isContinueDisableCoBorrower();
        }

        if (this.state.additionalIncomeValid) {
            this.addNewItem();
            this.props.setLoanIsDirty(true);
            this.setState({ additionalIncomeValid: false });
        }

        if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && this.state.borrowerAdditionalIncomeStatus && this.props.additionalIncomeDetail?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === this.state.owner).length <= 0) {
            this.addEmptyOtherIncomeSource();
        } else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus && this.state.coBorrowerAdditionalIncomeStatus && this.props.additionalIncomeDetail?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === this.state.owner).length <= 0) {
            this.addEmptyOtherIncomeSource();
        }
    };

    async componentDidMount() {
        const { history } = this.props;
        if (history.location?.state?.args === "borrower" || history.location?.state === undefined) {
            await this.changeStep(AdditionalIncomeStep.BorrowerAdditonalIncomeStatus);
        } else if (history.location?.state?.args === "coborrower") {
            await this.changeStep(AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus);
        }

        this.SetBackClick();
    }

    addEmptyOtherIncomeSource = () => {
        const OtherIncomeSourceDetails = this.props.additionalIncomeDetail?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === this.state.owner)
        if (OtherIncomeSourceDetails.length <= 0) {
            for (var i = 0; i < 1; i++) {
                this.addNewItem();
            }
        }
    }

    validateAdditionalData = () => {
        let otherIncomeSourceDetails = this.props.additionalIncomeDetail?.otherIncomeSourceDetails
            .filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === this.state.owner);
        for (let i = 0; otherIncomeSourceDetails.length > i; i++) {
            if (isNullOrWhiteSpace(otherIncomeSourceDetails[i].description)) {
                this.setState({ additionalIncomeValid: false });
                break;
            }
            else {
                this.setState({ additionalIncomeValid: true });
            }
            if (isNullOrWhiteSpace(otherIncomeSourceDetails[i].monthlyAmount) || otherIncomeSourceDetails[i].monthlyAmount <= "0.00") {
                this.setState({ additionalIncomeValid: false });
                break;
            }
            else {
                this.setState({ additionalIncomeValid: true });
            }
        }
    };

    addOtherIncomeSourceItembtnClick = () => {
        this.validateAdditionalData();
    };

    addNewItem = () => {
        const EmptyOtherIncomeSourceDetail: OtherIncomeSourceDetail = {
            id: _uniqueId('uniqueId-'),
            description: "",
            monthlyAmount: "",
            owner: this.state.owner,
            isNew: true
        };
        this.props.addOtherIncomeSourceToState(EmptyOtherIncomeSourceDetail);
    };

    setBorrowerAdditionalIncomeStatus = (value: boolean) => {
        this.props.setLoanIsDirty(true);
        this.setState({ borrowerAdditionalIncomeStatus: value });
    }

    setCoBorrowerAdditionalIncomeStatus = (value: boolean) => {
        this.props.setLoanIsDirty(true);
        this.setState({ coBorrowerAdditionalIncomeStatus: value });
    }

    async navigateToPropertyInfo() {        
        //remove existing from list if selected "no"
        if ((this.state.owner === BorrowerTypeEnum.Borrower && !this.state.borrowerAdditionalIncomeStatus)) {
            this.props.removeOtherIncomeSourceItemListFromState(BorrowerTypeEnum.Borrower);
        } else if ((this.state.owner === BorrowerTypeEnum.CoBorrower && !this.state.coBorrowerAdditionalIncomeStatus)) {
            this.props.removeOtherIncomeSourceItemListFromState(BorrowerTypeEnum.CoBorrower);
        }

        let otherIncomeSourceDetailList = this.props.additionalIncomeDetail?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === this.state.owner);

        let result;
        if (!this.props.isLoanCompleted) {
            this.props.isLoadingAdditionalIncome(true);
            result = await additionalIncomeService.upsertAsync(otherIncomeSourceDetailList, this.state.owner, this.props.interimLoanStagingId);

            result = result.parsedBody as boolean;
            this.props.isLoadingAdditionalIncome(false);

                if (result) {
                    additionalIncomeService.updateProgressTracker(this.state.owner, this.props.loanGuid, this.props.IsCoborrowerPresent);
                    additionalIncomeService.updateAdditionalIncomeStatus(this.props.interimLoanStagingId, otherIncomeSourceDetailList, this.state.owner);

                if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && !this.state.borrowerAdditionalIncomeStatus
                    && this.props.IsCoborrowerPresent) {
                    this.props.history.replace({ state: { args: "coborrower" } });
                    this.changeStep(AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus);
                } else if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && !this.state.borrowerAdditionalIncomeStatus
                    && !this.props.IsCoborrowerPresent) {
                    this.moveToAsset();
                } else if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && this.props.IsCoborrowerPresent) {
                    this.changeStep(AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus);
                    this.props.history.replace({ state: { args: "coborrower" } });
                }
                else if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && !this.props.IsCoborrowerPresent) {
                    this.moveToAsset();
                }
                else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus && !this.state.coBorrowerAdditionalIncomeStatus) {
                    this.moveToAsset();
                }
                else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus) {
                    this.moveToAsset();
                }
            }
        }
        else {
            result = this.props.isLoanCompleted;
        }
        if (this.props.isLoanCompleted && !this.props.isEncompassDown) {
            if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && !this.state.borrowerAdditionalIncomeStatus
                && this.props.IsCoborrowerPresent) {
                this.props.history.replace({ state: { args: "coborrower" } });
                this.changeStep(AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus);
            } else if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && !this.state.borrowerAdditionalIncomeStatus
                && !this.props.IsCoborrowerPresent) {
                this.moveToAsset();
            } else if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && this.props.IsCoborrowerPresent) {
                this.changeStep(AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus);
                this.props.history.replace({ state: { args: "coborrower" } });
            } else if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus && !this.props.IsCoborrowerPresent) {
                this.moveToAsset();
            } else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus && !this.state.coBorrowerAdditionalIncomeStatus) {
                this.moveToAsset();
            } else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus) {
                this.moveToAsset();
            }
        }
    }

    moveBack = () => {
        if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus) {
            this.props.setLoanIsDirty(false);
            this.moveToEmployment();
        }
        else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus) {
            this.changeStep(AdditionalIncomeStep.BorrowerAdditonalIncomeStatus);
            this.props.history.replace({ state: { args: "borrower" } });
        }
    }

    validateOtherIncomeSoureList = () => {
        let otherIncomeSourceDetailList = this.props.additionalIncomeDetail?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === this.state.owner);
        let invalidList = otherIncomeSourceDetailList.filter(tt => tt.description == "" || tt.monthlyAmount == "" || tt.monthlyAmount <= "000")

        if (invalidList.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    ShowContinue() {
        this.setState({
            ...this.state,
            OnContinueClick: () => { this.navigateToPropertyInfo(); },
        });
    }

    SetBackClick() {
        this.setState({
            ...this.state,
            OnBackClick: () => { this.moveBack(); },
        });
    }

    render() {
        let additionalIncomePage = <></>;
        let navigationButton = <></>;
        if (this.state.currentStep === AdditionalIncomeStep.BorrowerAdditonalIncomeStatus) {

            const otherIncomeSourceDetails = this.props.additionalIncomeDetail?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === BorrowerTypeEnum.Borrower)

            const rows = <>
                <div className={styles.additionalIncomeCardRow}>{
                    otherIncomeSourceDetails?.map((item, i) => {
                        return (
                            <OtherIncomeSourceInformationItem
                                disabled={this.props.isLoanCompleted}
                                key={i}
                                loanGuid={this.props.loanGuid}
                                otherIncomeSourceDetail={item}
                                additionaIIncomeInfo={this.props.additionalIncomeDetail}
                                owner={this.state.owner}
                            />
                        );
                    })
                }
                </div>
            </>

            additionalIncomePage = <>

                <div className={styles.display_table}>
                    <div className={styles.display_cell}>
                        <div className={styles.income_source}>
                            <h1 onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Additional Income Information")}> Additional Income Information</h1>
                            <div className="question-row">
                                <div className="questionItem" onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Does" + this.props.borrowerName + "receive any additional income")}>
                                    Does {this.props.borrowerName} receive any additional income?
                                </div>
                                <div className="answerItem">
                                    <button onClick={() => !this.props.isLoanCompleted ? this.setBorrowerAdditionalIncomeStatus(true) : ""}
                                        disabled={this.props.isLoanCompleted} className={`option-button ${this.state.borrowerAdditionalIncomeStatus === true ? "active" : ""}`} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Press button to yes")}>
                                        Yes
                                    </button>
                                    <button onClick={() => !this.props.isLoanCompleted ? this.setBorrowerAdditionalIncomeStatus(false) : ""}
                                        disabled={this.props.isLoanCompleted} className={`option-button ${this.state.borrowerAdditionalIncomeStatus === false ? "active" : ""}`} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Press button to no")}>
                                        No
                                    </button>
                                </div>
                            </div>
                            <div>
                                {this.state.borrowerAdditionalIncomeStatus && rows}
                            </div>
                            <div className={styles.addmoreButtonRow}>                               
                                {this.state.borrowerAdditionalIncomeStatus && otherIncomeSourceDetails.length < 8 &&
                                    <button disabled={this.props.isLoanCompleted} onClick={() => this.addOtherIncomeSourceItembtnClick()} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Press button to add another income source")}>
                                        <i className="fa fa-plus-circle"> </i>
                                        add another income source
                                    </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </>;
        }

        else if (this.state.currentStep === AdditionalIncomeStep.CoBorrowerAdditonalIncomeStatus) {

            const otherIncomeSourceDetails = this.props.additionalIncomeDetail?.otherIncomeSourceDetails.filter(otherIncomeSourceDetail => otherIncomeSourceDetail.owner === BorrowerTypeEnum.CoBorrower)

            const rows = <>
                <div className={styles.additionalIncomeCardRow}>{
                    otherIncomeSourceDetails?.map((item, i) => {
                        return (
                            <OtherIncomeSourceInformationItem
                                disabled={this.props.isLoanCompleted}
                                key={i}
                                loanGuid={this.props.loanGuid}
                                otherIncomeSourceDetail={item}
                                additionaIIncomeInfo={this.props.additionalIncomeDetail}
                                owner={this.state.owner}
                            />
                        );
                    })
                }
                </div>
            </>

            additionalIncomePage = <>
                <div className={styles.display_table}>
                    <div className={styles.display_cell}>
                        <div className={styles.income_source}>
                            <h1 onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Co-Borrower Additional Income Information")}>Co-Borrower Additional Income Information</h1>
                            <div className="question-row">
                                <div className="questionItem" onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Does " + this.props.coborrowerName + " receive any additional income?")}>Does {this.props.coborrowerName} receive any additional income?</div>
                                <div className="answerItem">
                                    <button disabled={this.props.isLoanCompleted}
                                        className={`option-button ${this.state.coBorrowerAdditionalIncomeStatus == true ? "active" : ""}`}
                                        onClick={() => !this.props.isLoanCompleted ? this.setCoBorrowerAdditionalIncomeStatus(true) : ""} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Press button to yes")}>
                                        Yes
                                    </button>
                                    <button disabled={this.props.isLoanCompleted}
                                        onClick={() => !this.props.isLoanCompleted ? this.setCoBorrowerAdditionalIncomeStatus(false) : ""}
                                        className={`option-button ${this.state.coBorrowerAdditionalIncomeStatus == false ? "active" : ""}`} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Press button to no")}>
                                        No
                                    </button>
                                </div>
                            </div>                           
                            <div>
                                {this.state.coBorrowerAdditionalIncomeStatus && rows}
                            </div>
                            <div className={styles.addmoreButtonRow}>                                
                                {this.state.coBorrowerAdditionalIncomeStatus && otherIncomeSourceDetails.length < 8 &&
                                    <button disabled={this.props.isLoanCompleted} onClick={() => this.addOtherIncomeSourceItembtnClick()} onMouseOut={() => this.stopSpeaking()} onMouseOver={() => this.speakText("Press button to add another income source")}>
                                    <i className="fa fa-plus-circle"> </i>
                                    add another income source
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </>;
        }

        return (
            <>
                <div className={styles.vquestionContainer}>
                    {additionalIncomePage}
                    <div className={styles.textAlingLeft}>
                        {navigationButton}
                    </div>
                </div>
                {this.props.isLoading && <div className="screenLoader"><div className="loader medium" /></div>}
                <NavigationWrapper
                    IsContinueDisabled={this.state.IsContinueDisabled || this.props.isLoading || this.props.isEncompassDown}
                    OnContinueClick={this.state.OnContinueClick}
                    OnBackClick={this.state.OnBackClick}
                    ResetContinue={this.state.ResetContinue}
                    OnInitialize={() => this.ShowContinue()}
                    OnResetFromRoute={() => this.resetContinue()}
                ></NavigationWrapper>
            </>
        );
    }
}

const mapStateToProps = (appState: ApplicationState): any => {
    const { additionalIncome, loan, accountCreation } = appState;
    let isLoading;
    let loanGuid = undefined;
    let hasData = false;
    let additionalIncomeDetail;
    let validation;
    let IsCoborrowerPresent = false;
    let borrowerName;
    let coborrowerName;
    let isLoanCompleted = appState.loan?.isLoanCompleted;
    let isEncompassDown = appState.loanUpdateResponse?.isEncompassDown;
    let interimLoanStagingId = undefined;

    if (loan !== undefined) {
        loanGuid = loan.loanGuid;
        interimLoanStagingId = loan.interimLoanStagingId;
    }

    if (accountCreation !== undefined) {
        borrowerName = accountCreation?.borrower?.firstName + " " + accountCreation?.borrower?.lastName;

        if (accountCreation?.coBorrower?.firstName != undefined) {
            IsCoborrowerPresent = true;
            coborrowerName = accountCreation?.coBorrower?.firstName + " " + accountCreation?.coBorrower?.lastName;
        }
    }

    if (additionalIncome && additionalIncome.data) {
        additionalIncomeDetail = additionalIncome.data;
        isLoading = additionalIncome.isLoading;
        hasData = true;
        validation = additionalIncome.validation;
    }

    return {
        additionalIncomeDetail,
        isLoading,
        loanGuid,
        hasData,
        validation,
        borrowerName,
        IsCoborrowerPresent,
        coborrowerName,
        isLoanCompleted,
        isEncompassDown,
        interimLoanStagingId
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    addOtherIncomeSourceToState: (otherIncomeSourceDetail: OtherIncomeSourceDetail) => {
        dispatch(AdditionalIncomeActionCreators.addOtherIncomeSourceToState(otherIncomeSourceDetail));
    },
    removeOtherIncomeSourceFromState: (otherIncomeSourceId: string) => {
        dispatch(AdditionalIncomeActionCreators.removeOtherIncomeSourceFromState(otherIncomeSourceId));
    },
    getAdditionalIncomeInformation: (interimLoanStagingId: number) => {
        dispatch(AdditionalIncomeActionCreators.getAdditionalIncomeInformation(interimLoanStagingId));
    },
    removeOtherIncomeSourceItemListFromState: (owner: string) => {
        dispatch(AdditionalIncomeActionCreators.removeOtherIncomeSourceItemListFromState(owner));
    },
    isLoadingAdditionalIncome: (loading: boolean) => {
        dispatch(AdditionalIncomeActionCreators.isLoadingAdditionalIncome(loading))
    },
    updateNavigationStatus: (taskItemId: any, subTaskItemId: any, taskStatusId: any, subTaskStatusId: any, loanGuid: any) => {
        dispatch(TaskMenuCreators.updateNavigationStatus(taskItemId, subTaskItemId, taskStatusId, subTaskStatusId, loanGuid));
    },
    setLoanIsDirty: (isDirty: boolean) => {
        dispatch(TaskMenuCreators.setLoanIsDirty(isDirty));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalIncomeInformation as any);