import * as React from "react";
import styles from "./milestoneNavigation.module.scss";
import { MilestoneDetails } from "../common/Enums/EnumMilestoneNavigation";
import { MilestoneNavigationState } from "../../store/MilestoneNavigationStore";
import { Modal, ModalBody } from "reactstrap";
import LoadScript from "./LoadScript";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const MilestoneDescription = (props: { milestone: any, data: MilestoneNavigationState, loanPurpose: any }) => {
    const [modal, setModal] = React.useState(false);
    const [modalScript, setModalScript] = React.useState("");
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    const renderSwitch = (name: any) => {
        if (props.loanPurpose === "Purchase") {
            switch (name) {
                case 'Loan Estimate':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="pPmSL" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Documentation':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="0yGIn" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Appraisal':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="RKYc7" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Processing':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="MjwIJ" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Underwriting':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="2dqsL" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Conditional Approval':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="q5Wsb" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Clear to close':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="Jggho" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Congrats':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="e98Uk" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                default:
                    return "";
            }
        } else {
            switch (name) {
                case 'Loan Estimate':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="GbghE" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Documentation':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="yR2hE" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Appraisal':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="dmyH2" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Processing':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="PKjtM" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Underwriting':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="zd2Td" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Conditional Approval':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="AjATl" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Clear to close':
                    return <LoadScript html={
                        <div className="surefire-interactive" data-dynamic-video="rnOuP" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                case 'Congrats':
                    return <LoadScript html={<div className="surefire-interactive" data-dynamic-video="mR6Tw" data-user-email="sf3admin@topofmind.com" data-first-name="Borrower Name" data-last-name="Borrower Last Name" data-street="1234 Main St."></div>
                    } />;
                default:
                    return "";
            }
        }
    }

    const renderModal = () => {
        // Check to see if there's a selected video. If so, render it.
        if (!isNullOrWhiteSpace(modalScript)) {
            return (
                <Modal isOpen={modal} centered={true} returnFocusAfterClose={false}>
                    <ModalBody className={`${styles.modalBody}`}>
                        <div className="align-center">
                            {renderSwitch(modalScript)}
                            <div>
                                <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to close")} className="common-button" onClick={() => setModal(false)}> Close </button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            );
        }
    }

    return (
        <>
            <div className={styles.description}>
                <h3 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.milestone?.value)}>{props.milestone?.value}</h3>
                <div className={styles.flex_row}>
                    {props.milestone?.keyDate && <div>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.milestone?.keyDate)}>{props.milestone?.keyDate}</h4>
                        {props.milestone?.keyDate === MilestoneDetails.completingTheApplication.keyDate &&
                            <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(new Date(props.data?.estimatedClosingDate) >= new Date() ? props.data?.estimatedClosingDate : "TBD")}>{new Date(props.data?.estimatedClosingDate) >= new Date() ? props.data?.estimatedClosingDate : "TBD"}</span>
                        }
                        {props.milestone?.keyDate === MilestoneDetails.readyForClosing.keyDate &&
                            <span onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.data?.actualClosingDate)}>{props.data?.actualClosingDate}</span>
                        }
                    </div>
                    }
                    <div>
                        <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Educational Videos")}>Educational Videos</h4>
                        <div className={styles.estimatedRow}>
                            {props.milestone?.videos.map((value: any) => (
                                <span key={value}
                                    onClick={() => {
                                        setModal(!modal);
                                        setModalScript(value);
                                    }}
                                    className={styles.cursor} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(value)}
                                >
                                    {value}
                                </span>

                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.textDetails}>
                    <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.milestone?.description)}>
                        {props.milestone?.description}
                    </p>
                </div>
            </div>
            {renderModal()}
        </>
    );
};

export default MilestoneDescription;
