import { get } from "../common/dataFetch";

export const readLoanSummary = (email: string) => {
    return get<any>(`api/InterimLoanSummary/ReadInterimLoanSummary?email=${email}`);
};
export const ReadLoanOfficerDetailByInterimLoanStagingAsync = (interimId: any) => {
    return get<any>(`api/InterimLoanSummary/ReadLoanOfficerDetailByInterimLoanStagingAsync?interimId=${interimId}`);
};
export const ReadActiveLoanUserDetail = (interimLoanStagingId: number) => {
    return get<any>(`api/InterimLoanSummary/ReadActiveLoanUserDetail?interimLoanStagingId=${interimLoanStagingId}`);
};