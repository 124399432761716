import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as LoanOfficerMainTabStore from "../store/LoanOfficerMainTabStore";
import { KnownAction } from '../store/LoanOfficerMainTabStore';
import * as loanOfficerMainTabService from "../services/loanOfficerMainTabService";


const request = (loanGuid: string): KnownAction => {
    return {
        type: ActionTypes.RequestApplicationFee,
        loanGuid,
    };
};
const receive = (data: LoanOfficerMainTabStore.LoanOfficerMainTab): KnownAction => {
    return { type: ActionTypes.ReceiveApplicationFee, data};
};

export const loanOfficerMainTabActionCreators = {

    setCreditReports : (creditReports: LoanOfficerMainTabStore.DocumentAttachment[]): AppThunkAction<LoanOfficerMainTabStore.KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.ReceiveCreditReports, creditReports });
    },
    setApplicationFee: (data: LoanOfficerMainTabStore.LoanOfficerMainTab): AppThunkAction<LoanOfficerMainTabStore.KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.ReceiveApplicationFee, data });
    },
    setLoanOfficerViewLoanGuid: (loanGuid: string): AppThunkAction<LoanOfficerMainTabStore.KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetLoanOfficerViewLoanGuid, loanGuid });
    },
    setSelectedTab: (selectedTab: number): AppThunkAction<LoanOfficerMainTabStore.KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.SetSelectedTab, selectedTab });
    },
    updatePreApproval: (value: any, propertyName: string, section: string): AppThunkAction<LoanOfficerMainTabStore.KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdatePreApproval, value: value, propertyName: propertyName, section: section });
    },
    getApplicationFee: (loanGuid: string): AppThunkAction<LoanOfficerMainTabStore.KnownAction> => (dispatch) => {
        dispatch(request(loanGuid));
        loanOfficerMainTabService.getApplicationFee(loanGuid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + loanGuid + " .");
                }
                return response.parsedBody as LoanOfficerMainTabStore.LoanOfficerMainTab;
            })
            .then((data: any) => {
                dispatch(receive(data));
            })
            .catch((error: Error) => {
                console.error(error);
            });
    },
    sendSecureInvitation: (loanGuid: string): AppThunkAction<LoanOfficerMainTabStore.KnownAction> => (dispatch) => {
        loanOfficerMainTabService.sendSecureInvitation(loanGuid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + loanGuid + " .");
                }
                return response.parsedBody as boolean;
            })
            .then((data: boolean) => {
                return data;
            })
            .catch((error: Error) => {
                console.error(error);
                return false;
            });
    },
    
}