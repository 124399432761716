import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { LoanOfficerMainTabState, KnownAction } from "../store/LoanOfficerMainTabStore";

const unloadedState: LoanOfficerMainTabState = {
    data: {
        posLoanIdentifier: false,
        appFeeCollectedStatus: undefined,
        appFeePendingStatus: undefined,
        loanPurpose:"",
        contactChoices: [],
        loanOriginationDate: undefined,
        loanType: undefined,
        propertyEstimatedValueAmount: undefined
    },
    creditReports: [],
    loanGuid: '',
    isLoading: false,
    selectedTab: 0,
    preApproval: {
        letterStyle: undefined,
        loanType: undefined,
        posMaxPurchasePrice: undefined,
        letterName: undefined,
        posAlterPurchasePriceIndicator: undefined,
        timeStamp: new Date(),
        preApprovalLetterId: 0,
        posPreApprovalLetterComments: undefined,
        isRequestedPreApproval: false,
        loPreApprovalLetterComments: ""
    },
}

export const loanOfficerMainTabReducer: Reducer<LoanOfficerMainTabState> = (state: LoanOfficerMainTabState | undefined, incomingAction: Action): LoanOfficerMainTabState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.RequestApplicationFee:
            return {
                ...state,
                isLoading: true
            };
        case ActionTypes.ReceiveApplicationFee:
            {
                let result;
                let data = action.data;
                
                    result = {

                        ...state,
                        data: data,
                        isLoading: false
                    }
                    return result;
            };
        case ActionTypes.ReceiveCreditReports:
            {
                let result;
                let reports = action.creditReports;

                result = {
                    ...state,
                    creditReports: reports,
                    isLoading: false
                }
                return result;
            };
        case ActionTypes.SetLoanOfficerViewLoanGuid:
            return {
            ...state,
            loanGuid: action.loanGuid
            };
        case ActionTypes.SetSelectedTab:
            return {
                ...state,
                selectedTab: action.selectedTab
            };
        case ActionTypes.UpdatePreApproval:
            return {

                ...state,
                preApproval: {
                    ...state.preApproval,
                    [action.propertyName]: action.value
                }
            };
        default:
            return state;
    }
}