import { Action, Reducer } from "redux";
import { CreditReportState, KnownAction } from "../store/creditReportStore";
import * as ActionTypes from "../common/ActionTypes";
import { ReportStatus } from "store/Enum";

const unloadedState: CreditReportState = {
  showSpinner: true,
  status: null,
  reportId: null,
  borrowerScore: null,
  coBorrowerScore: null,
};

export const creditReportReducer: Reducer<CreditReportState> = (
  state: CreditReportState | undefined,
  incomingAction: Action
): CreditReportState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case ActionTypes.SetCreditReportStatus: {
      return {
        ...state,
        status: action.status,
      };
    }

    case ActionTypes.StartCreditReport: {
      return {
        ...state,
        reportId: action.reportId,
        showSpinner: true,
        status: ReportStatus.InProgress
      };
    }
    case ActionTypes.CompleteCreditReport: {
      return {
        ...state,
        status: action.status,
        borrowerScore: action.borrowerScore,
        coBorrowerScore: action.coBorrowerScore,
        showSpinner: false,
      };
    }

    default:
      return state;
  }
};
