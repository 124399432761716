import { Action, Reducer } from "redux";
import * as ActionTypes from "../common/ActionTypes";
import { UserAccountState, KnownAction } from "../store/userAccountStore";

const unloadedState: UserAccountState = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    borrowerType: undefined,
    hasConsent: undefined,
    initialUserAccountRegistrationInformation: false,
    isLoancreated: false
}

export const userAccountReducer: Reducer<UserAccountState> = (state: UserAccountState | undefined, incomingAction: Action): UserAccountState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.UpdateUserFirstName:
            return {
                ...state,
                firstName: action.firstName
            };
        case ActionTypes.UpdateUserLastName:
            return {
                ...state,
                lastName: action.lastName
            };
        case ActionTypes.UpdateUserEmail:
            return {
                ...state,
                email: action.email
            };
        case ActionTypes.UpdateUserBorrowerType:
            return {
                ...state,
                borrowerType: action.borrowerType
            };
        case ActionTypes.UpdateUserConsent:
            return {
                ...state,
                hasConsent: action.hasConsent
            };
        case ActionTypes.IsLoancreated: {
            return { ...state, isLoancreated: action.isLoancreated };
        };
        default:
            return state;
    }
}