import * as React from "react";
import { useNavigation } from "../..///hooks/useNavigation";

export interface NavigationWrapper {
    IsContinueDisabled?: boolean;
    OnContinueClick?: () => void;
    OnBackClick?: () => void;
    OnInitialize?: () => void;
    OnResetFromRoute?: () => void;
    ResetContinue?: any;
}

const NavigationWrapper = (props: NavigationWrapper) => {
    var navigation = useNavigation(props.OnInitialize);
    React.useEffect(() => {
        if (props.OnBackClick) {
            navigation.SetBackOnClick(props.OnBackClick);
        }
    }, [props.OnBackClick]);
    React.useEffect(() => {
        if (props.OnContinueClick) {

            navigation.ShowContinue(props.OnContinueClick, props.IsContinueDisabled);
        }
    }, [props.OnContinueClick]);
    React.useEffect(() => {
        if (props.IsContinueDisabled != undefined) {
            navigation.SetContinueDisabled(props.IsContinueDisabled);
        }
    }, [props.IsContinueDisabled]);
    React.useEffect(() => {
        if (props.ResetContinue != undefined) {
            navigation.ResetContinue();
        }
    }, [props.ResetContinue]);
    React.useEffect(() => {
        if (navigation.OnResetFromRoute && props.OnResetFromRoute != undefined) {
            props.OnResetFromRoute();
        }
    }, [navigation.OnResetFromRoute]);
    return <></>;
};
export default NavigationWrapper;