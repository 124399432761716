import * as ActionTypes from "../common/ActionTypes";

// STATE

export interface EmploymentInformationState {
    borrower: Borrower;
    borrowerComplete: boolean;
    submittedBorrower: boolean;
    hasCoBorrower: boolean;
    coBorrower: Borrower;
    coBorrowerComplete?: boolean;
    submittedCoBorrower: boolean;
    previousId: string;
    previousBorrower?: BorrowerType;
}

export interface Borrower {
    name?: string;
    loanGuid?: string;
    borrowerType?: BorrowerType;
    veteran?: boolean;
    activeMilitaryDuty?: boolean;
    currentEmploymentDoesNotApply?: boolean;
    selfDeclaredMilitaryServiceIndicator?: boolean;
    additionalEmploymentDoesNotApply?: boolean;
    previousGrossMonthlyIncome?: string;
    printOnAdditionalBorrowerPage?: boolean;
    previousEmploymentDoesNotApply?: boolean;
    employments: Employment[];
    interimLoanStagingId?: number;
}

export enum BorrowerType {
    PrimaryBorrower = 0,
    CoBorrower = 1
}

export interface Employment {
    id: string;
    currentEmploymentIndicator?: boolean;
    owner: string;
    selfEmployedIndicator?: boolean;
    ownershipInterestType?: string;
    employmentMonthlyIncomeAmount?: number;
    employerName: string;
    positionDescription: string;
    employmentStartDate: string;
    endDate?: Date;
    addressCity: string;
    addressState: string;
    addressPostalCode: string;
    timeOnJobTermYears?: number;
    timeOnJobTermMonths?: number;
    basePayAmount: string;
    overtimeAmount: string;
    bonusAmount: string;
    commissionsAmount: string;
    otherAmount: string;
    militaryEntitlement?: number;
    urlA2020StreetAddress: string;
    employmentId: number;
    totalAmount: number;
    needAdditionalEmployment: boolean;
}

// ACTIONS
interface UpdateEmploymentInformationProperty {
    type: typeof ActionTypes.UpdateEmploymentInformationProperty,
    value: any,
    propertyName: string,
    borrowerType: BorrowerType,
    employmentItemId: string
}

interface SubmitEmploymentInformation {
    type: typeof ActionTypes.SubmitEmploymentInformation,
    borrowerData: Borrower
}

interface SubmittedEmploymentInformation {
    type: typeof ActionTypes.SubmittedEmploymentInformation,
    borrowerType: BorrowerType,
    value: boolean
}

interface AddEmploymentItem {
    type: typeof ActionTypes.AddEmploymentItem,
    data: Employment,
    borrowerType: BorrowerType,
    id: string
}

interface DeleteEmploymentItem {
    type: typeof ActionTypes.DeleteEmploymentItem,
    id: string,
    borrowerType: BorrowerType,
}

interface ResetEmploymentItem {
    type: typeof ActionTypes.ResetEmploymentItem,
    employmentItemId: string,
    borrowerType: BorrowerType,
}

interface GetEmploymentInformation {
    type: typeof ActionTypes.GetEmploymentInformation,
    data: Borrower,
    borrowerType: string
}

interface ReIndexingEmployment {
    type: typeof ActionTypes.ReIndexingEmployment,
    data: Employment[],
    borrowerType: BorrowerType
}

export type KnownAction =
    UpdateEmploymentInformationProperty |
    SubmitEmploymentInformation |
    AddEmploymentItem |
    SubmittedEmploymentInformation |
    DeleteEmploymentItem |
    ResetEmploymentItem |
    GetEmploymentInformation |
    ReIndexingEmployment;