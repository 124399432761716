import * as React from "react";
import DatePicker from "react-datepicker";
import datePickerStyles from "./CustomDatePicker.module.scss";
import { getMonth, getYear } from 'date-fns';
import dropDownStyles from "../common/Input/SelectDropDown.module.scss";

const CustomYearMonthPicker = (props: {
    handleChange: (month: number, year: number) => void,
    fieldValue: Date | null | undefined,
    className?: string,
    popperPlacement: boolean,
    maxDate?: Date,
    disabled: boolean,
    defaultYear?: number,
    defaultMonth?: number,
    format: string,
    placeholderText: string
}) => {
    const [years] = React.useState([...Array(51).keys()].map(i => i + new Date().getFullYear() - 50).reverse());
    const [isOpen, setIsOpen] = React.useState(false);
    const dateChange = (value: any) => {
        props.handleChange(getMonth(value ? value : props.fieldValue), getYear((value ? value : props.fieldValue)));
    }
    const yearChange = (value: any) => {
        props.handleChange(getMonth(value), value);
    }

    return (
        <div className={`${datePickerStyles.customDatepicker} ${datePickerStyles.customYearMonthpicker} ${props.className}`}>
            <DatePicker
                focusSelectedMonth={true}
                selected={props.fieldValue}
                disabled={true}
                placeholderText={props.placeholderText}
                dateFormat={props.format}
                popperPlacement={props.popperPlacement ? "top-start" : "auto"}
                renderCustomHeader={({
                    date,
                    changeYear,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                        <div className={datePickerStyles.datePickerHeader}>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </button>
                            <div className={`${dropDownStyles.selectDropDown} select ${datePickerStyles.datepickerYears}`}>
                                <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) => { yearChange(Number(value)); changeYear(Number(value)) }}>
                                    {years.map((option: any) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    )}
                className={`${datePickerStyles.input}`}
                onChange={(date: any, e: any) => {
                    dateChange(e.target.value ? e.target.value : date);
                    setIsOpen(false);
                }}
                open={isOpen}
                maxDate={props.maxDate}
                showMonthYearPicker
            />
            <i className={`${datePickerStyles.calendarIcon} fa fa-calendar`} aria-hidden="true"
                onClick={() => !props.disabled ? setIsOpen(!isOpen) : setIsOpen(isOpen)}
            ></i>
        </div >
    );
};
export default CustomYearMonthPicker;