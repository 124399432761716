import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { LoanUpdateResponseState, KnownAction } from "../store/loanUpdateResponseStore";

const unloadedState: LoanUpdateResponseState = {
    loanGuid: undefined,
    isEncompassDown: false
}

export const loanUpdateResponseReducer: Reducer<LoanUpdateResponseState> = (state: LoanUpdateResponseState | undefined, incomingAction: Action): LoanUpdateResponseState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.SetIsEncompassDown:
            return {
                ...state,
                isEncompassDown: action.isEncompassDown,
            };
        default:
            return state;
    }
}