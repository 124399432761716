import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as GovernmentQuestionStore from "../store/GovernmentQuestionStore";
import { KnownAction } from '../store/GovernmentQuestionStore';
import * as governmentQuestionService from "../services/governmentQuestionService";

const submitGovernmentQuestions = (borrowerType: GovernmentQuestionStore.BorrowerType, value: boolean): KnownAction => {
    return { type: ActionTypes.SubmitGovernmentQuestions, borrowerType: borrowerType, value: value };
};
const request = (borrowerType: string, interimLoanStagingId:number): KnownAction => {
    return {
        type: ActionTypes.RequestGovernmentQuestions,
        borrowerType,
        interimLoanStagingId
    };
};
const receive = (data: GovernmentQuestionStore.GovernmentQuestion, borrowerType: GovernmentQuestionStore.BorrowerType): KnownAction => {
    return { type: ActionTypes.ReceiveGovernmentQuestions, data, borrowerType };
};

export const governmentQuestionActionCreators = {
    updateGovernmentQuestions: (value: string, propertyName: string, borrowerType: GovernmentQuestionStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateGovernmentQuestions, value: value, propertyName: propertyName, borrowerType: borrowerType });
    },
    updateGovernmentAdditionalQuestions: (value: boolean, propertyName: string, borrowerType: GovernmentQuestionStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateGovernmentAdditionalQuestions, value: value, propertyName: propertyName, borrowerType: borrowerType });
    },
    updateBorrowerCompleted: (borrowerType: GovernmentQuestionStore.BorrowerType, value: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: ActionTypes.UpdateBorrowerCompleted, borrowerType: borrowerType, value: value });
    },
    submittedGovernmentQuestions: (borrowerData: GovernmentQuestionStore.GovernmentQuestion): AppThunkAction<KnownAction> => async (dispatch) => {
        await governmentQuestionService.updateGovernmentQuestion(borrowerData)
            .then((response) => {
                if (!response.ok) {
                    dispatch(submitGovernmentQuestions(GovernmentQuestionStore.BorrowerType.PrimaryBorrower, true));
                    console.error(response);
                }
                return true;
            })
            .then(isSuccessful => {
                dispatch(submitGovernmentQuestions(GovernmentQuestionStore.BorrowerType.PrimaryBorrower, true));
                if (!isSuccessful) {
                    console.error("Government question failed to saved");
                    return false;
                }
                return true;
            });
    },
    getGovernmentQuestions: (borrowerType: string, interimLoanStagingId:number): AppThunkAction<GovernmentQuestionStore.KnownAction> => (dispatch) => {
        dispatch(request(borrowerType, interimLoanStagingId));
        governmentQuestionService.getGovernmentQuestion( borrowerType, interimLoanStagingId)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + interimLoanStagingId + " .");
                }
                return response.parsedBody as GovernmentQuestionStore.GovernmentQuestion;
            })
            .then((data: any) => {
                dispatch(receive(data, data.borrowerType));
            })
            .catch((error: Error) => {
            });
    },

    setGovernmentQuestions: (data: GovernmentQuestionStore.GovernmentQuestion, borrowerType: GovernmentQuestionStore.BorrowerType): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(receive(data, borrowerType));
    },

    updateToInterim: (borrowerData: GovernmentQuestionStore.GovernmentQuestion): AppThunkAction<KnownAction> => (dispatch) => {
        governmentQuestionService.updateGovernmentQuestion(borrowerData)
            .then((response) => {
                if (!response.ok) {
                    console.error(response);
                }
                return true;
            })
            .then(isSuccessful => {
                if (!isSuccessful) {
                    console.error("Government question failed to saved in Interim table");
                }
            });
    },
}