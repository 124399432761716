import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';

import * as AccountInformation from "./accountInformationStore";
import { accountInformationReducer } from "../reducers/accountInformationReducer";
import * as AccountCreation from "./accountCreationStore";
import { accountCreationReducer } from "../reducers/accountCreationReducer";
import * as EmploymentInformation from "./employmentInformationStore";
import { employmentInformationReducer } from "../reducers/employmentInformationReducer";

import * as UserAccountInformation from "./userAccountStore";
import { userAccountReducer } from "../reducers/userAccountReducer";
import * as  ManualAssetInformation from "./ManualAssetInformationStore";
import { manualAssetInformationReducer } from "../reducers/manualAssetInformationReducer";

import * as PropertyInformation from "./propertyInformationStore";
import { propertyInformationReducer } from "../reducers/propertyInformationReducer";

import * as ZipCodeLookUp from "./ZipCodeLookUpStore";
import { zipCodeLookupReducer } from "../reducers/zipCodeLookupReducer";

import { loanReducer } from "../reducers/loanReducer";
import * as Loan from "./loanStore";

import * as additionalIncome from "./additionalIncomeStore";
import { additionalIncomeReducer } from "../reducers/additonalIncomeReducer";

import * as Navigation from "./navigationStore";
import { navigationReducer } from "../reducers/navigationReducer";

import * as GovernmentQuestion from "./GovernmentQuestionStore";
import { governmentQuestionReducer } from "../reducers/governmentQuestionReducer";

import * as BorrowerLiability from "./borrowerLiabilityStore";
import { borrowerLiabilityReducer } from "../reducers/borrowerLiabilityReducer";

import * as BorrowerReo from "./borrowerReoStore";
import { borrowerReoReducer } from "../reducers/borrowerReoReducer";
import * as ReviewApplication from "./reviewApplicationStore";
import { reviewApplicationReducer } from "../reducers/reviewApplicationReducer";

import * as DemographicQuestions from "./demographicQuestionsStore";
import { demographicQuestionsStateReducer } from "../reducers/demographicQuestionsReducer";

import * as assetInformation from "./assetInformationStore";
import { assetInformationReducer } from "../reducers/assetInformationReducer";

import * as ConsumerCreditAuthorization from "./consumerCreditAuthorizationStore";
import { consumerCreditAuthorizationReducer } from "../reducers/consumerCreditAuthorizationReducer";

import * as CreditReport from "./creditReportStore";
import { creditReportReducer } from "../reducers/creditReportReducer";

import * as CreditInformation from "./creditInformationStore";
import { creditInformationReducer } from "../reducers/creditInformationReducer";

import * as SystematicLetter from "./systematicLetterStore";
import { systematicLetterReducer } from "../reducers/systematicLetterReducer";

import * as Document from "./documentStore";
import { documentReducer } from "../reducers/documentReducer";

import * as taskMenu from "./taskMenuStore";
import { taskMenuReducer } from "../reducers/taskMenuReducer";

import * as Dashboard from "./dashboardStore";
import { dashboardReducer } from "../reducers/dashboardReducer";

import { MilestoneNavigationState } from './MilestoneNavigationStore';
import { milestoneNavigationReducer } from "../reducers/milestoneNavigationReducer";

import { LoanOfficerMainTabState } from './LoanOfficerMainTabStore';
import { loanOfficerMainTabReducer } from "../reducers/loanOfficerMainTabReducer";

import { LoanUpdateResponseState } from './loanUpdateResponseStore';
import { loanUpdateResponseReducer } from "../reducers/loanUpdateResponseReducer";

import * as CountyLookUp from "./CountyLookUpStore";
import { countyLookupReducer } from "../reducers/countyLookupReducer";

import * as interimLoanSummary from "./interimLoanSummaryStore";
import { interimLoanSummaryReducer } from "../reducers/interimLoanSummaryReducer";

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    accountInformation: AccountInformation.AccountInformationState | undefined;
    userAccountInformation: UserAccountInformation.UserAccountState | undefined;
    zipCodeLookUp: ZipCodeLookUp.ZipCodeLookUpState | undefined;
    accountCreation: AccountCreation.AccountCreationState | undefined;
    propertyInformation: PropertyInformation.PropertyInformationState | undefined;
    employmentInformation: EmploymentInformation.EmploymentInformationState | undefined;
    loan: Loan.loanState | undefined;
    additionalIncome: additionalIncome.AdditionalIncomeState | undefined;
    consumerCreditAuthorization: ConsumerCreditAuthorization.ConsumerCreditAuthorizationState | undefined;
    navigation: Navigation.NavigationState | undefined;
    assetInformation: assetInformation.AssetInformationState | undefined;
    creditInformation: CreditInformation.CreditInformationState | undefined;
    manualAssetInformation: ManualAssetInformation.ManualAssetInformationState | undefined;
    governmentQuestions: GovernmentQuestion.GovernmentQuestionState | undefined;
    borrowerLiability: BorrowerLiability.BorrowerLiabilityState | undefined;
    borrowerReo: BorrowerReo.BorrowerReoState | undefined;
    creditReport: CreditReport.CreditReportState | undefined;
    demographicQuestions: DemographicQuestions.DemographicQuestionsState | undefined;
    reviewApplication: ReviewApplication.ReviewApplicationState | undefined;
    document: Document.DocumentState | undefined;
    taskMenu: taskMenu.TaskMenuState | undefined;
    dashboard: Dashboard.DashboardState | undefined;
    systematicLetter: SystematicLetter.SystematicLetterState | undefined;
    milestoneNavigation: MilestoneNavigationState | undefined;
    loanOfficerMainTab: LoanOfficerMainTabState | undefined;
    loanUpdateResponse: LoanUpdateResponseState | undefined;
    countyLookUp: CountyLookUp.CountyLookUpState | undefined;
    interimLoanSummary: interimLoanSummary.LoanSummaryState | undefined;

}
// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    accountInformation: accountInformationReducer,
    userAccountInformation: userAccountReducer,
    zipCodeLookUp: zipCodeLookupReducer,
    accountCreation: accountCreationReducer,
    propertyInformation: propertyInformationReducer,
    employmentInformation: employmentInformationReducer,
    navigation: navigationReducer,
    loan: loanReducer,
    additionalIncome: additionalIncomeReducer,
    manualAssetInformation: manualAssetInformationReducer,
    governmentQuestions: governmentQuestionReducer,
    borrowerLiability: borrowerLiabilityReducer,
    borrowerReo: borrowerReoReducer,
    demographicQuestions: demographicQuestionsStateReducer,
    assetInformation: assetInformationReducer,
    creditReport: creditReportReducer,
    consumerCreditAuthorization: consumerCreditAuthorizationReducer,
    creditInformation: creditInformationReducer,
    reviewApplication: reviewApplicationReducer,
    document: documentReducer,
    systematicLetter: systematicLetterReducer,
    taskMenu: taskMenuReducer,
    dashboard: dashboardReducer,
    milestoneNavigation: milestoneNavigationReducer,
    loanOfficerMainTab: loanOfficerMainTabReducer,
    countyLookUp: countyLookupReducer,
    loanUpdateResponse: loanUpdateResponseReducer,
    interimLoanSummary: interimLoanSummaryReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}