import * as React from 'react';
import UhmLogo from "assets/logo/uhm-logo.png";
import * as loanOfficerInviteService from "../../services/loanOfficerInviteService";
import styles from "./LoanOfficerInvite.module.scss";
import { isNullOrWhiteSpace } from '../../common/helper/formatHelper';

const LoanOfficerInvite = () => {

    const [borrowerData, setBorrowerData] = React.useState<any>([]);
    const [borrowers, setBorrowers] = React.useState<any>([]);
    const [coborrowers, setCoBorrowers] = React.useState<any>([]);
    const [loanGuid, setLoanGuid] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDisclosureDateEmpty, setDisclosureDate] = React.useState(false);

    const [checkBox, setCheckBox] = React.useState({
        borrowerCheckBox: false,
        coBorrowerCheckBox: false
    });
    const [responseMessage, setResponseMessage] = React.useState("");

    const successMessage = "The invitation(s) to UHMNOW has been sent!";
    const errorMessage = "The invitation(s) to UHMNOW could not be sent.";
    const validationFailMessage = "You cannot invite this borrower to UHMNOW because disclosures have already been sent in Encompass.";
    const borrowerCheckMessage = "You must select a Borrower before sending an invitation.";

    React.useEffect(() => {
        const loanGuid = window.location.pathname.split("/")[2];
        setLoanGuid(loanGuid);
        setIsLoading(true);

        loanOfficerInviteService.getBorrowersData(loanGuid)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Unable to fetch borrower data.");
                }
                return response.parsedBody as any[];
            })
            .then((data) => {
                //setBorrowerData(data);
                let borrowerDetails = data.filter(x => isNullOrWhiteSpace(x.disclosureProvidedDate));
                if (borrowerDetails && borrowerDetails.length > 0) {
                    setDisclosureDate(true);
                }
                let borrowers = data.filter(x => x.borrowerType == "Borrower");
                setBorrowers(borrowers);
                let coborrowers = data.filter(x => x.borrowerType == "CoBorrower");
                setCoBorrowers(coborrowers);
                setIsLoading(false);
            })
            .catch((error: Error) => {
                setIsLoading(false);
                console.log(error.message);
            });
    }, []);

    React.useEffect(() => {
        if ((checkBox.borrowerCheckBox || checkBox.coBorrowerCheckBox) && responseMessage == borrowerCheckMessage) {
            setResponseMessage("");
        }
    }, [checkBox]);

    React.useEffect(() => {
        if (responseMessage == successMessage || responseMessage == errorMessage) {
            setTimeout(() => setResponseMessage(""), 5000);
        }
    }, [responseMessage]);

    const handleOnCheck = (e: any, borrower: any) => {
        const name = e.target.name;
        const value = e.target.checked;

        setCheckBox({
            ...checkBox,
            [name]: value
        });
        if (value) {
            borrowerData.push(borrower);
        }
        else {
            const index = borrowerData?.findIndex((x: { emailAddress: any; }) => x.emailAddress == borrower.emailAddress);
            borrowerData?.splice(index, 1);
        }
        setBorrowerData(borrowerData)
        console.log(borrowerData)
    }

    const handleOnSend = () => {
        console.log(borrowerData)

        if (!checkBox.borrowerCheckBox && !checkBox.coBorrowerCheckBox) {
            setResponseMessage(borrowerCheckMessage);
        }
        else {
            if (borrowerData && borrowerData.length > 0 && isDisclosureDateEmpty
                //&& isNullOrWhiteSpace(borrowerData[0]?.disclosureProvidedDate)) {
            ) {
                let data = { loanNumber: borrowerData[0].loanNumber, borrowerDetailsDto: new Array() };

                borrowerData.forEach((borrower: any) => {
                    data.borrowerDetailsDto.push({
                        firstName: borrower?.firstName.trim(),
                        lastName: borrower?.lastName.trim(),
                        email: borrower?.email.trim(),
                        borrowerType: borrower?.borrowerType.trim()
                    })
                });
                setIsLoading(true);
                loanOfficerInviteService.sendInviteMail(data)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("Unable to send invite mail.");
                        }
                        return response.parsedBody as any;
                    })
                    .then((data) => {
                        if (data) {
                            setResponseMessage(successMessage);
                        }
                        else {
                            setResponseMessage(errorMessage);
                        }
                        setIsLoading(false);
                    })
                    .catch((error: Error) => {
                        setResponseMessage(errorMessage);
                        setIsLoading(false);
                        console.log(error.message);
                    });
            }
            else {
                setResponseMessage(validationFailMessage);
            }
        }
    }

    return (
        <>
            {isLoading ? <div className="screenLoader"><span className="loader medium"></span></div> :
                <div className={styles.selectRecipientsModal}>
                    <div className={styles.recipientsModalDialog}>
                        <div className={styles.recipientsModalContent}>
                            <div className={styles.recipientsModalHeader}>
                                <div className="uhm-logo">
                                    <img src={UhmLogo} alt="Union Home Mortgage" />
                                </div>
                                {(borrowers.length > 0 || coborrowers.length > 0) &&
                                    <div className="title">
                                        Select Recipients
                            </div>
                                }
                            </div>
                            <>
                                {
                                        <>
                                            <div className={styles.recipientsModalBody}>
                                                {borrowers != undefined && borrowers?.length > 0 &&
                                                    <>
                                                <div className={styles.formGroup}>
                                                    <div className={styles.formTitle}>
                                                        <label>BORROWER</label>
                                                        <label>EMAIL</label>
                                                    </div>
                                                   
                                                        {borrowers?.map((borrower: any, index: any) => (
                                                            <div className={styles.formRow}>
                                                                <div className={styles.formGroup}>
                                                                    <div className={styles.borrowerName}>
                                                                        <input type={borrower?.firstName ? "checkbox" : "hidden"} name={"borrowerCheckBox"} onChange={(e) => handleOnCheck(e, borrower)} />
                                                                        <label>{`${borrower?.firstName ?? ""} ${borrower?.lastName ?? ""}`}</label>
                                                                    </div>
                                                                </div>
                                                                <div className={`${styles.formGroup}`}>
                                                                    <span>{borrower?.email ?? ""}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    </>}

                                                {coborrowers != undefined && coborrowers.length > 0 &&
                                                <>
                                                <div className={styles.formTitle}>
                                                <label>CO-BORROWER</label>
                                                    <label>EMAIL</label>
                                                    </div>
                                                        {coborrowers?.map((coborrower: any, index: any) => (
                                                            <div className={styles.formRow}>
                                                                <div className={styles.formGroup}>
                                                                    <div className={styles.borrowerName}>
                                                                        <input type={coborrower?.firstName ? "checkbox" : "hidden"} name={"coBorrowerCheckBox"} onChange={(e) => handleOnCheck(e, coborrower)} />
                                                                        <label >{`${coborrower?.firstName ?? ""} ${coborrower?.lastName ?? ""}`}</label>
                                                                    </div>
                                                                </div>
                                                                <div className={styles.formGroup}>
                                                                    <span>{coborrower?.email ?? ""}</span>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>}

                                                {responseMessage != "" && < div className={styles.message}>
                                                    <h2 className={responseMessage == successMessage ? styles.success : styles.error}>
                                                        {responseMessage}
                                                    </h2>
                                                </div>}
                                            </div>
                                            <div className={styles.recipientsModalFooter}>
                                                <button type="button"
                                                    className="button green"
                                                    onClick={handleOnSend}>
                                                    <i className="fa fa-envelope-o"></i>Send Invite
                                </button>
                                            </div>
                                        </>
                                }
                            </>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LoanOfficerInvite