import * as React from "react";
import styles from "./milestoneNavigation.module.scss";
import { connect } from "react-redux";
import Infographic from "../common/Infographic";
import ClosedInfographic from "assets/infographics/milestoneNavigation/Closed.svg";
import CompletingTheApplicationInfographic from "assets/infographics/milestoneNavigation/CompletingTheApplication.svg";
import CreditAnalysisInfographic from "assets/infographics/milestoneNavigation/CreditAnalysis.svg";
import ProcessingTheApplicationInfographic from "assets/infographics/milestoneNavigation/ProcessingTheApplication.svg";
import ReadyForClosingInfographic from "assets/infographics/milestoneNavigation/ReadyForClosing.svg";
import DefaultMilestoneDescription from "./DefaultMilestoneDescription";
import MilestoneDescription from "./MilestoneDescription";
import { EnumMilestoneNavigation, MilestoneDetails } from "../common/Enums/EnumMilestoneNavigation";
import { ApplicationState } from "../../store";
import * as MilestoneNavigationActions from "../../actions/milestoneNavigationActions";
import { MilestoneNavigationState } from "../../store/MilestoneNavigationStore";
import { TaskStatus } from "../common/progressTracker/TaskStatus";
import * as TaskMenuActions from "../../actions/taskMenuAction";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type MilestoneNavigationProps = MilestoneNavigationState & {
    loanGuid: string;
    loanPurpose: string;
    interimLoanStagingId: number;
} & typeof MilestoneNavigationActions.MilestoneNavigationServiceActionCreators
    & typeof TaskMenuActions.TaskMenuCreators;

const MilestoneNavigation = (props: MilestoneNavigationProps) => {
    const [activeIndex, setActiveIndex] = React.useState("");
    const [milestone, setMilestone] = React.useState({ value: "", keyDate: "", videos: [] as any, description: "" });
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }

    React.useEffect(() => {
        if (props.loanGuid && props.interimLoanStagingId && props.interimLoanStagingId > 0) {
            props.getMilestoneDetails(props.loanGuid);
            props.borrowerLoanSummary(props.loanGuid, props.interimLoanStagingId);
        }
    }, []);

    const returnInfographic = (param: any) => {
        switch (param) {
            case EnumMilestoneNavigation.Closed:
                return ClosedInfographic;
            case EnumMilestoneNavigation.CompletingTheApplication:
                return CompletingTheApplicationInfographic;
            case EnumMilestoneNavigation.CreditAnalysis:
                return CreditAnalysisInfographic;
            case EnumMilestoneNavigation.ProcessingTheApplication:
                return ProcessingTheApplicationInfographic;
            case EnumMilestoneNavigation.ReadyForClosing:
                return ReadyForClosingInfographic;
            default:
                return '';
        }
    }

    const returnMilestoneDetail = (param: any) => {
        switch (param) {
            case MilestoneDetails.closed.value:
                return MilestoneDetails.closed;
            case MilestoneDetails.completingTheApplication.value:
                return MilestoneDetails.completingTheApplication;
            case MilestoneDetails.creditAnalysis.value:
                return MilestoneDetails.creditAnalysis;
            case MilestoneDetails.processingTheApplication.value:
                return MilestoneDetails.processingTheApplication;
            case MilestoneDetails.readyForClosing.value:
                return MilestoneDetails.readyForClosing;
            default:
                return;
        }
    }

    const handleClick = (key: any) => {
        setActiveIndex(key);
        let response = returnMilestoneDetail(key);
        if (response) {
            setMilestone({ value: response.value, keyDate: response.keyDate, videos: response.videos, description: response.description });
        }
    }

    const returnMilestoneEnableStatus = (param: any) => {
        switch (param) {
            case EnumMilestoneNavigation.Closed:
                return props.closed;
            case EnumMilestoneNavigation.CompletingTheApplication:
                return props.completingTheApplication;
            case EnumMilestoneNavigation.CreditAnalysis:
                return props.creditAnalysis;
            case EnumMilestoneNavigation.ProcessingTheApplication:
                return props.processingTheApplication;
            case EnumMilestoneNavigation.ReadyForClosing:
                return props.readyForClosing;
            default:
                return '';
        }
    }

    return (
        <div className="w-100-p">
            <div className={styles.milestone} style={{ whiteSpace: "pre-line" }}>
                <div className={styles.milestoneContent}>
                    {Object.entries(EnumMilestoneNavigation).map(([key, value]) => (
                        <div key={key}
                            className={`${styles.milestoneImage} 
                            ${returnMilestoneEnableStatus(value) === TaskStatus.InProgress ? styles.inProgress : styles.disabled}
                            ${(value === activeIndex) ? styles.active : ""}
                            ${returnMilestoneEnableStatus(value) === TaskStatus.Completed ? styles.completed : ""}`}>

                            <div className={styles.infographic} onClick={() => handleClick(value)}>
                                <Infographic src={returnInfographic(value)} />
                            </div>
                            <p>{value}</p>
                        </div>
                    ))}
                </div>
                <div className={styles.milestoneSideContent}>
                    {
                        (activeIndex === "") ?
                            <DefaultMilestoneDescription /> :
                            <MilestoneDescription milestone={milestone} data={props} loanPurpose={props.loanPurpose} />
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState): any => {
    let estimatedClosingDate;
    let actualClosingDate;
    let closed;
    let completingTheApplication;
    let creditAnalysis;
    let processingTheApplication;
    let readyForClosing;
    let loanGuid;
    let loanPurpose;
    let interimLoanStagingId = state.loan?.interimLoanStagingId;

    const { accountInformation, milestoneNavigation, taskMenu } = state;

    if (accountInformation?.loanGuid) {
        loanGuid = accountInformation?.loanGuid;
    }

    if (milestoneNavigation) {
        estimatedClosingDate = milestoneNavigation?.estimatedClosingDate;
        actualClosingDate = milestoneNavigation?.actualClosingDate;
        closed = milestoneNavigation?.closed;
        completingTheApplication = milestoneNavigation?.completingTheApplication;
        creditAnalysis = milestoneNavigation?.creditAnalysis;
        processingTheApplication = milestoneNavigation?.processingTheApplication;
        readyForClosing = milestoneNavigation?.readyForClosing;
    }

    if (taskMenu?.loanSummary?.loanPurpose) {
        loanPurpose = taskMenu?.loanSummary?.loanPurpose;
    }

    return {
        estimatedClosingDate,
        actualClosingDate,
        loanGuid,
        closed,
        completingTheApplication,
        creditAnalysis,
        processingTheApplication,
        readyForClosing,
        loanPurpose,
        interimLoanStagingId
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    getMilestoneDetails: (loanGuid: string) => {
        dispatch(MilestoneNavigationActions.MilestoneNavigationServiceActionCreators.getMilestoneDetails(loanGuid));
    },
    borrowerLoanSummary: (loanGuid: string, interimLoanStagingId: number) => {
        dispatch(TaskMenuActions.TaskMenuCreators.borrowerLoanSummary(loanGuid, interimLoanStagingId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneNavigation as any);