import { get, post } from "../common/dataFetch";
import { ResponseDto } from "../common/models/ResponseDto";
import { CreditReportScoresDto } from "../components/creditReport/models/CreditReportScoresDto";

export const getCreditReportStatus = (loanGuid: string,reportId: number) => {
  return get<ResponseDto<any>>(
    "api/CreditReport/ReadByLoanGuidAsync?loanGuid=" + loanGuid +"&reportId="+reportId
  );
};

export const getCreditReportValues = (loanGuid: string) => {
  return get<ResponseDto<CreditReportScoresDto>>(
    "api/CreditReport/ReadCreditScoresByLoanGuidAsync?loanGuid=" + loanGuid
  );
};


export const startCreditReport = (guid: string) => {
  return post<ResponseDto<any>>("api/CreditReport/StartNewCreditReport?loanGuid=" + guid, null);
};
