import { AppThunkAction } from '../store';
import * as ActionTypes from "../common/ActionTypes";
import * as SystematicLetterStore from "../store/systematicLetterStore";
import { KnownAction } from '../store/systematicLetterStore';
import * as systematicLetterService from "../services/systematicLetterService";

const request = (loanGuid: string): KnownAction => {
    return {
        type: ActionTypes.RequestSystematicLetter,
        loanGuid
    };
};
const receive = (data: SystematicLetterStore.SystematicLetter): KnownAction => {
    return { type: ActionTypes.ReceiveSystematicLetter, data };
};

export const systematicLetterActionCreators = {
    
    getSystematicLetterData: (loanGuid: string): AppThunkAction<SystematicLetterStore.KnownAction> => (dispatch) => {
        dispatch(request(loanGuid));
        systematicLetterService.getSystematicLetterData(loanGuid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan " + loanGuid + " .");
                }
                return response.parsedBody as SystematicLetterStore.SystematicLetter;
            })
            .then((data: any) => {
                dispatch(receive(data));
            })
            .catch((error: Error) => {
                console.error(error);
            });
    },
    getData: (data: SystematicLetterStore.SystematicLetter): AppThunkAction<SystematicLetterStore.KnownAction> => (dispatch) => {
                dispatch(receive(data));
    }
}
