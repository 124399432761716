import * as React from "react";
import { ApplicationState } from "../../store";
import PdfDocumentViewer from "../documents/documentViewers/PdfDocumentViewer";
import { useState } from "react";
import { useSelector, connect } from "react-redux";
import * as LoanOfficerMainTabStore from "../../store/LoanOfficerMainTabStore";
import * as LoanOfficerMainTabActions from "../../actions/loanOfficerMainTabActions";
import styles from "./LoanOfficer.module.scss";

type LOMainTabDocumentViewerProps = {
    data: LoanOfficerMainTabStore.DocumentAttachment[]; 
} & typeof LoanOfficerMainTabActions.loanOfficerMainTabActionCreators;

const LOMainTabDocumentViewer = (props: LOMainTabDocumentViewerProps) => {
    const [document, setDocument] = useState("");

    const setPdfData = (documentId: string) => {
        if (props.data != null && props.data.length > 0) {
            let creditDocument = props.data.find(x => x.documentId === documentId);
            if (creditDocument) {
                setDocument(creditDocument.data);
            }
        }
    }

    React.useEffect(() => {
        if (props.data.length > 0) {
            setDocument(props.data[0].data);
        }
    },[])
    

    return (
        <div className="w-100-p">
            {(props.data.length > 0 && (
                <div className={styles.pdfLinkContainer}>
                    <div className={styles.pdfBox}>
                        <PdfDocumentViewer data={document}></PdfDocumentViewer>;
                    </div>
                    <div className={styles.linkBox}>
                        {props.data?.map((file, index) => (
                            <a onClick={() => setPdfData(file.documentId)}>{file.fileName}</a>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state: ApplicationState): any => {
    const { loanOfficerMainTab } = state;
    let data;
    if (loanOfficerMainTab && loanOfficerMainTab.creditReports.length > 0) {
        data = loanOfficerMainTab.creditReports;
    }
    return {
        data
    }
}

export default connect(
    mapStateToProps,
    LoanOfficerMainTabActions.loanOfficerMainTabActionCreators
)(LOMainTabDocumentViewer as any);