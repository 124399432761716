import React, { useState } from "react";
import styles from "./borrowerReo.module.scss";
import * as BorrowerReoActionCreators from "../../actions/borrowerReoActions";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { useNavigation } from "hooks/useNavigation";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { update } from "../../services/borrowerReoService";
import { BorrowerReoState } from "../../store/borrowerReoStore";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { AccountCreationState } from "../../store/accountCreationStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import {
    TaskStatusId,
    SubTaskItem,
    TaskItem,
} from "../common/progressTracker/TaskStatus";
import ReoPropertiesSummary from "./ReoPropertiesSummary";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const ReoPropertyLanding = () => {
    const actions = {
        ...bindActionCreators(
            {
                ...BorrowerReoActionCreators.BorrowerReoActionCreators,
                ...TaskMenuCreators,
                ...loanUpdateResponseActionCreators
            },
            useDispatch()
        ),
    };
    const [isLoading, setLoading] = React.useState(false);
    const [isDisable, setDisable] = React.useState(true);

    const hasAdditionalProperties = useSelector(
        (state: ApplicationState) => state.borrowerReo?.hasAdditionalProperties
    );

    const realEstateDoesNotApply = useSelector(
        (state: ApplicationState) => state.borrowerReo?.realEstateDoesNotApply
    );

    useEffect(() => {
        if (interimLoanStagingId && interimLoanStagingId > 0) {
            actions.getBorrowerReo(interimLoanStagingId);
        }
    }, [])

    useEffect(() => {
        if (interimLoanStagingId && interimLoanStagingId > 0) {
            var reoProperties = state.reoProperties;
            if (reoProperties.length > 0 && realEstateDoesNotApply) {
                reoProperties.forEach((reoProperty) => {
                    actions.removeProperty(reoProperty.id);
                });
            }
        }
    }, [realEstateDoesNotApply])

const [showNoProperties, setShowNoProperties] = useState(false);
const history = useHistory();
const navigation = useNavigation();
const accountInfo = useSelector((state: ApplicationState) => state.accountCreation as AccountCreationState);

const loanGuid = useSelector(
    (state: ApplicationState) => state.accountInformation?.loanGuid
);
const interimLoanStagingId = useSelector(
    (state: ApplicationState) => state.loan?.interimLoanStagingId
);

const isLoanCompleted = useSelector(
    (state: ApplicationState) => state.loan?.isLoanCompleted
);

const state: BorrowerReoState = useSelector(
    (state: ApplicationState) => state.borrowerReo as BorrowerReoState
);

const { speak, cancel } = useSpeechSynthesis();

function textToSpeech(value: any) {
    if (IsNarrator()) {
        speak({ text: value });
    }
}
function stopSpeech() {
    cancel();
}

useEffect(() => {
    navigation.SetBackOnClick(() => {
        actions.setLoanIsDirty(false);
        history.push({ pathname: "/asset", state: { args: accountInfo.borrower?.printOnAdditionalBorrowerPage ? "coborrower" : "borrower" } });
    });
}, [navigation]);

useEffect(() => {
    navigation.SetBackOnClick(
        showNoProperties ? () => setShowNoProperties(false) : undefined
    );
}, [showNoProperties]);

useEffect(() => {
    (state.reoProperties.length > 0 && hasAdditionalProperties) || realEstateDoesNotApply ? setDisable(false) : setDisable(true)

    navigation.ShowContinue(() => {
        if (showNoProperties || (hasAdditionalProperties == false && isLoanCompleted)) {
            history.push("liability");
            return;
        }
        if (loanGuid == undefined && interimLoanStagingId == undefined && interimLoanStagingId == 0) {
            return;
        }
        if (!isLoanCompleted && loanGuid !== undefined && interimLoanStagingId !== undefined && interimLoanStagingId > 0) {
            setLoading(true);
            update(state.reoProperties, state.reoProperties.length > 0, loanGuid, interimLoanStagingId).then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem updating user information.");
                }
                let result = response.parsedBody as boolean;
                setLoading(false);
                if (result) {
                    actions.setLoanIsDirty(false);
                    setShowNoProperties(true);
                    actions.updateNavigationStatus(
                        TaskItem.Liability,
                        SubTaskItem.Liability,
                        TaskStatusId.InProgress,
                        TaskStatusId.InProgress,
                        loanGuid
                    );
                    actions.updateNavigationStatus(
                        TaskItem.BorrowerREO,
                        SubTaskItem.BorrowerREO,
                        TaskStatusId.Completed,
                        TaskStatusId.Completed,
                        loanGuid
                    );
                    history.push("liability");
                }
            })
                .catch((error: Error) => {
                    throw new Error(`Following error message occurred ${error.message}`);
                });
        }
    }, isDisable);
}, [hasAdditionalProperties, state.reoProperties, isDisable, realEstateDoesNotApply]);

return (
    <>
        {!showNoProperties && (
            <div className={styles.reoPropertyMain}>
                <h1 className={styles.summaryHeading} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Properties Summary")}>Properties Summary</h1>
                <div className="question-row">
                    <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Do you own additional properties?")}>Do you own additional properties?</div>
                    <div className="answerItem">
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to yes")}
                            disabled={isLoanCompleted}
                            className={`option-button ${hasAdditionalProperties ? "active" : ""}`}
                            onClick={() => { actions.updateHasAdditionalProperties(true, "HasAdditionalProperties"); actions.setLoanIsDirty(true) }}>
                            Yes
                        </button>
                        <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Press button to no")}
                            disabled={isLoanCompleted}
                            className={`option-button ${realEstateDoesNotApply ? "active" : ""}`}
                            onClick={() => { actions.updateHasAdditionalProperties(true, "RealEstateDoesNotApply"); actions.setLoanIsDirty(true) }}>
                            No
                        </button>
                    </div>
                </div>

                {state.reoProperties.length > 0 && hasAdditionalProperties && (
                    <ReoPropertiesSummary></ReoPropertiesSummary>
                )}

                {hasAdditionalProperties && (
                    <div className={styles.addmoreButtonRow} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Add Real Estate Owned Property")}>
                        <button onClick={() => !isLoanCompleted ? history.push("/add-reo-property") : ""}>
                            <i className={`fa fa-plus-circle`} />
                        Add Real Estate Owned Property</button>
                    </div>
                )}
                {isLoading && (<div className="screenLoader"><div className="loader medium"></div></div>)}
            </div>
        )}
    </>
);
};

export default ReoPropertyLanding;
