export enum DemographicFields {
    SectionEthnicity = "Ethnicity",
    SectionRace="Race",
    SectionSex="Sex",

    HispanicLatino = "hmdaEthnicityHispanicLatinoIndicator",
    NotHispanicLatino = "hmdaEthnicityNotHispanicLatinoIndicator",
    ethnicityDoNotWishProvide = "hmdaEthnicityDoNotWishIndicator",

    AmericanIndianIndicator="hmdaAmericanIndianIndicator",
    AfricanAmericanIndicator = "hmdaAfricanAmericanIndicator",
    PacificIslanderIndicator = "hmdaPacificIslanderIndicator",
    WhiteIndicator ="hmdaWhiteIndicator",
    RaceDoNotWishProvideIndicator ="hmdaRaceDoNotWishProvideIndicator",

    GendertypeFemale = "hmdaGendertypeFemaleIndicator",
    GendertypeMale = "hmdaGendertypeMaleIndicator",
    GendertypeDoNotWishProvide = "hmdaGendertypeDoNotWishIndicator"
};