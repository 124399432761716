import * as React from "react";
import TaskMenuHeaders from "./TaskMenuHeaders";
import { Spring, animated } from "react-spring/renderprops";
import TaskList from "./TaskList";
import styles from "./TaskMenu.module.scss";

type TaskMenuLocalState = {
    isOpen: boolean;
};

type TaskMenuProps = {
    isNavigationClicked: boolean;
    setNavigationClicked: (value: boolean) => void;
}

class TaskMenu extends React.PureComponent<TaskMenuProps, TaskMenuLocalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    UNSAFE_componentWillReceiveProps(props: any) {
        this.setState({ isOpen: props.isNavigationClicked });
    }

    toggleMenu() {
        this.setState({ isOpen: !this.state.isOpen });
        this.props.setNavigationClicked(!this.state.isOpen);
    }

    public render() {
        return (
            <Spring native to={{
                opacity: this.state.isOpen ? 1 : 0,
                transform: this.state.isOpen ? `translateX(0%)` : `translateX(-100%)`,
            }} config={{ duration: 1 }}>
                {({ opacity, transform }) => (
                    <animated.nav className={`${styles.uhmTaskMenu}` + ((this.state.isOpen) ? ` ${styles.open}` : ` ${styles.closed}`)} >
                        <button className={styles.toggleButton} onClick={() => this.toggleMenu()}>
                            <span></span>
                            <span></span>
                        </button>
                        <div className={styles.tasklistmenuBg}>
                        {this.state.isOpen &&
                            <TaskMenuHeaders />
                        }
                        <animated.div className={styles.taskListMenu} style={{ opacity: opacity, transform: transform }}>
                            <TaskList open={this.state.isOpen}/>
                            </animated.div>
                            </div>
                    </animated.nav>
                )}
            </Spring>
        );
    }
}

export default TaskMenu;