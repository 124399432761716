import * as React from "react";
import { connect, useSelector } from "react-redux";
import * as AccountCreationActions from "../../actions/accountCreationActions";
import * as EmploymentInformationActions from "../../actions/employmentInformationActions";
import * as PropertyInformationActions from "../../actions/propertyInformationActions";
import * as AditionalIncomeActions from "../../actions/additionalIncomeActions";
import * as AccountCreationStore from "../../store/accountCreationStore";
import * as EmploymentInformationStore from "../../store/employmentInformationStore";
import * as PropertyInformationStore from "../../store/propertyInformationStore";
import * as AdditionalIncomeStore from "../../store/additionalIncomeStore";
import { ApplicationState } from '../../store/index';
import styles from "./reviewApplication.module.scss";
import ReviewBorrowerInformation from "./ReviewBorrowerInformation";
import ReviewAddressInformation from "./ReviewAddressInformation";
import ReviewEmploymentInformation from "./ReviewEmploymentInformation";
import ReviewAdditionalIncome from "./ReviewAdditionalIncome";
import { BorrowerTypeEnum } from "../common/Enums/BorrowerTypeEnum";
import ReviewPropertyInformation from "./ReviewPropertyInformation";
import ReviewReoInformation from "./ReviewReoInformation";
import * as Property from '../../services/propertyInformationService';
import * as ReoService from '../../services/borrowerReoService';
import * as AdditionalIncomeService from '../../services/additionalIncomeService';
import * as EmploymentInformationService from '../../services/employmentInformationService';
import * as AccountCreationService from '../../services/accountCreationService';
import { addRequiredDocuments, SendLeadCompletionNotificationToLoanOfficer } from "services/documentService";
import * as BorrowerReoActions from "../../actions/borrowerReoActions";
import * as BorrowerReoStore from "../../store/borrowerReoStore";
import coBorrower from "../../assets/infographics/reviewScreen/coBorrower.png";
import realEstate from "../../assets/infographics/reviewScreen/realEstate.png";
import Infographic from "../common/Infographic";
import { useNavigation } from "hooks/useNavigation";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { useHistory } from "react-router";
import { SubTaskItem, TaskStatusId, TaskItem } from "../common/progressTracker/TaskStatus";
import * as systematicLetterService from "../../services/systematicLetterService";
import { loanActionCreators } from "../../actions/loanActions";
import { LoanSummaryDto } from "../../store/taskMenuStore";
import { LoanUpdateResponseState } from "../../store/loanUpdateResponseStore";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { loanState } from "../../store/loanStore";
import { reviewApplicationActionCreators } from "../../actions/reviewApplicationActions";
import * as loanService from "../../services/loanSevice";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type ReviewApplicationProps = {
    loanGuid: string,
    interimLoanStagingId: number;
    borrowerInformation: AccountCreationStore.Borrower;
    coborrowerInformation: AccountCreationStore.Borrower;
    borrowerEmploymentInformation: EmploymentInformationStore.Borrower;
    coborrowerEmploymentInformation: EmploymentInformationStore.Borrower;
    additionalIncomeList: AdditionalIncomeStore.AdditionaIIncome;
    validation: any;
    section: any;
    loanPurpose: any;
    borrowerPropertyInformation: PropertyInformationStore.PropertyInformationState;
    reoInformation: BorrowerReoStore.BorrowerReoState;
    hasCoBorrower: boolean;
    isLoanComplated: boolean;
    loanSummary: LoanSummaryDto;
    userType: any;
}
    & AccountCreationStore.AccountCreationState
    & PropertyInformationStore.PropertyInformationState
    & AdditionalIncomeStore.AdditionalIncomeState
    & BorrowerReoStore.BorrowerReoState
    & typeof AccountCreationActions.accountCreationActionCreators
    & typeof EmploymentInformationActions.employmentInformationActionCreators
    & typeof AditionalIncomeActions.AdditionalIncomeActionCreators
    & typeof PropertyInformationActions.propertyInformationActionCreators
    & typeof BorrowerReoActions.BorrowerReoActionCreators
    & typeof TaskMenuCreators
    & typeof loanActionCreators
    & typeof loanUpdateResponseActionCreators
    & typeof reviewApplicationActionCreators

const ReviewApplication = (props: ReviewApplicationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const history = useHistory();

    //loading
    const [isLoading, setLoading] = React.useState(false);

    //accordians open close
    const [isBorrowerExpand, setBorrowerExpand] = React.useState(false);
    const [isCoBorrowerExpand, setCoBorrowerExpand] = React.useState(false);
    const [isReoExpand, setReoExpand] = React.useState(false);
    const [isPropertyExpand, setPropertyExpand] = React.useState(false);
    const { isEncompassDown } = useSelector((state: ApplicationState) => state.loanUpdateResponse as LoanUpdateResponseState);

    //set initial values in state
    const [initialBorrower, setBorrowerInfo] = React.useState({} as AccountCreationStore.Borrower);
    const [initialCoBorrower, setCoBorrowerInfo] = React.useState({} as AccountCreationStore.Borrower);

    //set validation on each section according to borrower type 
    const [propertyAddressValid, setPropertyAddressValid] = React.useState(false);
    const [reoValid, setReoValid] = React.useState(false);
    const [borrowerInformationValid, setBorrowerInformationValid] = React.useState({ isBorrowerValid: false, isCoBorrowerValid: false });
    const [addressInformationValid, setAddressInformationValid] = React.useState({ isBorrowerValid: false, isCoBorrowerValid: false });
    const [employmentInformationValid, setEmploymentInformationValid] = React.useState({ isBorrowerValid: false, isCoBorrowerValid: false });
    const [additionalInformationValid, setAdditionalInformationValid] = React.useState({ isBorrowerValid: false, isCoBorrowerValid: false });
    const { isLoanFromEncompassFlag } = useSelector((state: ApplicationState) => state.loan as loanState);

    const setValid = (isValid: any, borrowerType: any) => {
        if (borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower) {
            setBorrowerInformationValid({ isBorrowerValid: isValid, isCoBorrowerValid: borrowerInformationValid.isCoBorrowerValid });
        } else {
            setBorrowerInformationValid({ isBorrowerValid: borrowerInformationValid.isBorrowerValid, isCoBorrowerValid: isValid });
        }
    }

    const setReoInformationValid = () => {
        let isValid = true;
        props.reoInformation?.reoProperties.forEach(x => {
            if (isValid) {
                isValid =
                    !isNullOrWhiteSpace(x.occupancyType) &&
                    !isNullOrWhiteSpace(x.owner) &&
                    !isNullOrWhiteSpace(x.propertyType);

                if (x.willContinueToOwnPropertyAfterTransaction) {
                    isValid = isValid ?
                        !isNullOrWhiteSpace(x.street) &&
                        !isNullOrWhiteSpace(x.city) &&
                        !isNullOrWhiteSpace(x.state) &&
                        !isNullOrWhiteSpace(x.zip) &&
                        !(x.zip.length < 5) &&
                        !isNullOrWhiteSpace(x.marketValue)
                        : isValid;
                }

                if (x.propertyType === "Multi-Unit") {
                    isValid = isValid ?
                        x.noOfUnits !== 0
                        : isValid;
                }
            }
        });
        setReoValid(isValid);
    }

    const setAddressValid = (borrowerType: any) => {
        let borrowerInfo = borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
            props.borrowerInformation : props.coborrowerInformation;
        let isValid =
            !isNullOrWhiteSpace(borrowerInfo?.currentAddress?.addressStreetLine1) &&
            !isNullOrWhiteSpace(borrowerInfo?.currentAddress?.addressCity) &&
            !isNullOrWhiteSpace(borrowerInfo?.currentAddress?.addressState) &&
            !isNullOrWhiteSpace(borrowerInfo?.currentAddress?.addressPostalCode);

        if ((borrowerInfo?.currentAddress?.addressPostalCode && borrowerInfo?.currentAddress?.addressPostalCode.length < 5)) {
            isValid = false;
        }

        let validList = [];
        if (isValid && borrowerInfo?.addresses && borrowerInfo?.addresses?.length > 0) {
            validList = borrowerInfo?.addresses.filter(x =>
                !isNullOrWhiteSpace(x.addressStreetLine1) &&
                !isNullOrWhiteSpace(x.addressCity) &&
                !isNullOrWhiteSpace(x.addressState) &&
                !isNullOrWhiteSpace(x.addressPostalCode) &&
                !(x.addressPostalCode && x.addressPostalCode.length < 5));

            if (borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower) {
                setAddressInformationValid({
                    isBorrowerValid: validList.length < borrowerInfo?.addresses?.length ? false : true,
                    isCoBorrowerValid: addressInformationValid.isCoBorrowerValid
                });
            } else {
                setAddressInformationValid({
                    isBorrowerValid: addressInformationValid.isBorrowerValid,
                    isCoBorrowerValid: validList.length < borrowerInfo?.addresses?.length ? false : true
                });
            }
        } else {
            if (borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower) {
                setAddressInformationValid({
                    isBorrowerValid: isValid, isCoBorrowerValid: addressInformationValid.isCoBorrowerValid
                });
            } else {
                setAddressInformationValid({
                    isBorrowerValid: addressInformationValid.isBorrowerValid, isCoBorrowerValid: isValid
                });
            }
        }
    }

    const setEmploymentValid = (isValid: any, borrowerType: any) => {
        if (borrowerType === EmploymentInformationStore.BorrowerType.PrimaryBorrower) {
            setEmploymentInformationValid({ isBorrowerValid: isValid, isCoBorrowerValid: employmentInformationValid.isCoBorrowerValid });
        } else {
            setEmploymentInformationValid({ isBorrowerValid: employmentInformationValid.isBorrowerValid, isCoBorrowerValid: isValid });
        }
    }

    const setAdditionalIncomeValid = (isValid: any, borrowerType: any) => {
        let owner = borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower ?
            BorrowerTypeEnum.Borrower : BorrowerTypeEnum.CoBorrower;
        let totalIncomeList = props.additionalIncomeList.otherIncomeSourceDetails.filter(x => x.owner === owner);
        let validIncomeList = totalIncomeList.filter(x =>
            !isNullOrWhiteSpace(x.monthlyAmount) &&
            !isNullOrWhiteSpace(x.owner));

        if (borrowerType === AccountCreationStore.BorrowerType.PrimaryBorrower) {
            setAdditionalInformationValid({
                isBorrowerValid: !isValid ? isValid : (validIncomeList.length < totalIncomeList.length ? false : true),
                isCoBorrowerValid: additionalInformationValid.isCoBorrowerValid
            });
        } else {
            setAdditionalInformationValid({
                isBorrowerValid: additionalInformationValid.isBorrowerValid,
                isCoBorrowerValid: !isValid ? isValid : (validIncomeList.length < totalIncomeList.length ? false : true),
            });
        }
    }

    //set the editing section in state
    const [editSection, setEditSection] = React.useState({
        borrowerGeneralInformation: false,
        borrowerAddressInformation: false,
        borrowerEmploymentInformation: false,
        borrowerAdditionalIncomeInformation: false,
        coBorrowerGeneralInformation: false,
        coBorrowerAddressInformation: false,
        coBorrowerEmploymentInformation: false,
        coBorrowerAdditionalIncomeInformation: false,
        reoInformation: false,
        propertyInformation: false
    });
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
    //get data from apis
    React.useEffect(() => {
        if (props.loanGuid && props.interimLoanStagingId !== undefined && props.interimLoanStagingId > 0) {
            //borrower info
            props.getBorrowerInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);

            if (props.hasCoBorrower) {
                props.getBorrowerInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
                props.getEmploymentInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
            }

            //employment
            props.getEmploymentInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);


            //additional income
            props.getAdditionalIncomeInformation(props.interimLoanStagingId);

            //property info
            props.getPropertyInformation(props.interimLoanStagingId);

            //REO info
            props.getBorrowerReo(props.interimLoanStagingId);

            //get initial loan information
            props.borrowerLoanSummary(props.loanGuid, props.interimLoanStagingId);
        }
    }, []);

    React.useEffect(() => {
        //assign initial borrower and co borrower values
        if (props.borrowerInformation && props.borrowerInformation?.firstName) {
            if (Object.keys(initialBorrower).length === 0) {
                setBorrowerInfo(props.borrowerInformation);
            }
        }

        if (props.coborrowerInformation && props.coborrowerInformation?.firstName) {
            if (Object.keys(initialCoBorrower).length === 0) {
                setCoBorrowerInfo(props.coborrowerInformation);
            }
        }
    }, [props.borrowerInformation, props.coborrowerInformation]);

    //discard changes
    const discardChanges = async (borrowerType: string, section: string) => {
        setLoading(true);
        props.setLoanIsDirty(false);
        switch (borrowerType) {
            case 'Borrower':
                switch (section) {
                    case 'GeneralInformation':
                        props.getBorrowerInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                        setEditSection({ ...editSection, borrowerGeneralInformation: false });
                        setLoading(false);
                        return;
                    case 'AddressInformation':
                        props.getBorrowerInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                        setEditSection({ ...editSection, borrowerAddressInformation: false });
                        setLoading(false);
                        return;
                    case 'EmploymentInformation':
                        props.getEmploymentInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId)
                        setEditSection({ ...editSection, borrowerEmploymentInformation: false });
                        setLoading(false);
                        return;
                    case 'AdditionalIncomeInformation':
                        props.getAdditionalIncomeInformation(props.interimLoanStagingId);
                        setEditSection({ ...editSection, borrowerAdditionalIncomeInformation: false });
                        setLoading(false);
                        return;
                }
            case 'CoBorrower':
                switch (section) {
                    case 'GeneralInformation':
                        props.getBorrowerInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
                        setEditSection({ ...editSection, coBorrowerGeneralInformation: false });
                        setLoading(false);
                        return;
                    case 'AddressInformation':
                        props.getBorrowerInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
                        setEditSection({ ...editSection, coBorrowerAddressInformation: false });
                        setLoading(false);
                        return;
                    case 'EmploymentInformation':
                        props.getEmploymentInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId)
                        setEditSection({ ...editSection, coBorrowerEmploymentInformation: false });
                        setLoading(false);
                        return;
                    case 'AdditionalIncomeInformation':
                        props.getAdditionalIncomeInformation(props.interimLoanStagingId);
                        setEditSection({ ...editSection, coBorrowerAdditionalIncomeInformation: false });
                        setLoading(false);
                        return;
                }
            case 'REO':
                await props.getBorrowerReo(props.interimLoanStagingId);
                setEditSection({ ...editSection, reoInformation: false });
                setLoading(false);
                return;
            case 'PropertyInformation':
                props.getPropertyInformation(props.interimLoanStagingId);
                setEditSection({ ...editSection, propertyInformation: false });
                setLoading(false);
                return;
        }
    }

    //save changes
    const saveInformation = async (borrowerType: string, section: string) => {
        if (props.isLoanComplated) {
            return;
        }
        setLoading(true);
        props.setLoanIsDirty(false);
        switch (borrowerType) {
            case 'Borrower':
                switch (section) {
                    case 'GeneralInformation':
                        if (props.borrowerInformation) {
                            props.borrowerInformation.loanGuid = props.loanGuid;
                            if (props.borrowerInformation.addresses && props.borrowerInformation.currentAddress) {
                                props.borrowerInformation.addresses?.push(props.borrowerInformation.currentAddress);
                            }
                            let borrowerGeneralResponse = await AccountCreationService.create(props.borrowerInformation, props.userType);
                            if (borrowerGeneralResponse.ok) {
                                let result = borrowerGeneralResponse.parsedBody as LoanUpdateResponseState;
                                setLoading(false);
                                if (result.isEncompassDown) {
                                    props.setIsEncompassDown(true);
                                    goToDashboard();
                                }
                                else {
                                    props.setIsEncompassDown(false);
                                    props.getBorrowerInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                                }
                            }
                        }
                        setEditSection({ ...editSection, borrowerGeneralInformation: false });
                        return;
                    case 'AddressInformation':
                        props.borrowerInformation.loanGuid = props.loanGuid;
                        if (props.borrowerInformation.addresses && props.borrowerInformation.currentAddress) {
                            props.borrowerInformation.addresses?.push(props.borrowerInformation.currentAddress);
                        }
                        let borrowerResponse = await AccountCreationService.create(props.borrowerInformation, props.userType);
                        if (borrowerResponse.ok) {
                            let result = borrowerResponse.parsedBody as LoanUpdateResponseState;
                            setLoading(false);
                            if (result.isEncompassDown) {
                                props.setIsEncompassDown(true);
                                goToDashboard();
                            }
                            else {
                                props.setIsEncompassDown(false);
                                props.getBorrowerInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                            }
                        }
                        setEditSection({ ...editSection, borrowerAddressInformation: false });
                        return;
                    case 'EmploymentInformation':
                        props.borrowerEmploymentInformation.loanGuid = props.loanGuid;
                        props.coborrowerEmploymentInformation.borrowerType = EmploymentInformationStore.BorrowerType.PrimaryBorrower;
                        let empResponse = await EmploymentInformationService.updateEmploymentInformation(props.borrowerEmploymentInformation);
                        if (empResponse.ok) {
                            let result = empResponse.parsedBody as boolean;
                            EmploymentInformationService.updateEmploymentQuestionnaireToInterimLoanStaging(props.borrowerEmploymentInformation);

                            setLoading(false);
                            if (result) {
                                props.setIsEncompassDown(false);
                                props.getEmploymentInformation(BorrowerTypeEnum.Borrower, props.interimLoanStagingId);
                            }
                        }
                        setEditSection({ ...editSection, borrowerEmploymentInformation: false });
                        return;
                    case 'AdditionalIncomeInformation':
                        let borrowerIncome = props.additionalIncomeList.otherIncomeSourceDetails.filter(x => x.owner === BorrowerTypeEnum.Borrower);
                        let borrowerincomeResponse = await AdditionalIncomeService.upsertAsync(borrowerIncome, BorrowerTypeEnum.Borrower, props.interimLoanStagingId);

                        if (borrowerincomeResponse.ok) {
                            let result = borrowerincomeResponse.parsedBody as boolean;
                            setLoading(false);
                            if (result) {
                                props.setIsEncompassDown(false);
                                props.getAdditionalIncomeInformation(props.interimLoanStagingId);
                            }
                        }
                        setEditSection({ ...editSection, borrowerAdditionalIncomeInformation: false });
                        return;
                }
            case 'CoBorrower':
                switch (section) {
                    case 'GeneralInformation':
                        if (props.coborrowerInformation) {
                            props.coborrowerInformation.loanGuid = props.loanGuid;
                            if (props.coborrowerInformation.addresses && props.coborrowerInformation.currentAddress) {
                                props.coborrowerInformation.addresses.push(props.coborrowerInformation.currentAddress);
                            }
                            let coBorrowerGeneralResponse = await AccountCreationService.create(props.coborrowerInformation, props.userType);
                            if (coBorrowerGeneralResponse.ok) {
                                let result = coBorrowerGeneralResponse.parsedBody as LoanUpdateResponseState;
                                setLoading(false);
                                if (result.isEncompassDown) {
                                    props.setIsEncompassDown(true);
                                    goToDashboard();
                                }
                                else {
                                    props.setIsEncompassDown(false);
                                    props.getBorrowerInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
                                }
                            }
                        }
                        setEditSection({ ...editSection, coBorrowerGeneralInformation: false });
                        return;
                    case 'AddressInformation':
                        props.coborrowerInformation.loanGuid = props.loanGuid;
                        if (props.coborrowerInformation.addresses && props.coborrowerInformation.currentAddress) {
                            props.coborrowerInformation.addresses?.push(props.coborrowerInformation.currentAddress);
                        }
                        let coBorrowerResponse = await AccountCreationService.create(props.coborrowerInformation, props.userType);
                        if (coBorrowerResponse.ok) {
                            let result = coBorrowerResponse.parsedBody as LoanUpdateResponseState;
                            setLoading(false);
                            if (result.isEncompassDown) {
                                props.setIsEncompassDown(true);
                                goToDashboard();
                            }
                            else {
                                props.setIsEncompassDown(false);
                                props.getBorrowerInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
                            }
                        }

                        setEditSection({ ...editSection, coBorrowerAddressInformation: false });
                        return;
                    case 'EmploymentInformation':
                        props.coborrowerEmploymentInformation.loanGuid = props.loanGuid;
                        props.coborrowerEmploymentInformation.borrowerType = EmploymentInformationStore.BorrowerType.CoBorrower;
                        let empResponse = await EmploymentInformationService.updateEmploymentInformation(props.coborrowerEmploymentInformation);
                        if (empResponse.ok) {

                            let result = empResponse.parsedBody as boolean;

                            EmploymentInformationService.updateEmploymentQuestionnaireToInterimLoanStaging(props.coborrowerEmploymentInformation);
                            setLoading(false);

                            if (result) {
                                props.setIsEncompassDown(false);
                                props.getEmploymentInformation(BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);
                            }
                        }

                        setEditSection({ ...editSection, coBorrowerEmploymentInformation: false });
                        return;
                    case 'AdditionalIncomeInformation':
                        let borrowerIncome = props.additionalIncomeList.otherIncomeSourceDetails.filter(x => x.owner === BorrowerTypeEnum.CoBorrower);
                        let incomeResponse = await AdditionalIncomeService.upsertAsync(borrowerIncome, BorrowerTypeEnum.CoBorrower, props.interimLoanStagingId);

                        if (incomeResponse.ok) {
                            let result = incomeResponse.parsedBody as boolean;
                            setLoading(false);
                            if (result) {
                                props.setIsEncompassDown(false);
                                props.getAdditionalIncomeInformation(props.interimLoanStagingId);
                            }
                        }
                        setEditSection({ ...editSection, coBorrowerAdditionalIncomeInformation: false });
                        return;
                }
            case 'REO':
                let reoResponse = await ReoService.update(props.reoInformation.reoProperties, true, props.loanGuid, props.interimLoanStagingId);
                if (reoResponse.ok) {
                    let result = reoResponse.parsedBody as boolean;
                    setLoading(false);
                    if (result) {
                        props.setIsEncompassDown(false);
                        props.getBorrowerReo(props.interimLoanStagingId);
                    }
                }
                setEditSection({ ...editSection, reoInformation: false });
                return;
            case 'PropertyInformation':
                let propertyResponse = await Property.save(props.borrowerPropertyInformation, props.loanPurpose, props.loanGuid);
                if (propertyResponse.ok) {
                    let result = propertyResponse.parsedBody;
                    setLoading(false);
                    if (result) {
                        props.setIsEncompassDown(false);
                        props.getPropertyInformation(props.interimLoanStagingId);
                    }
                }
                setEditSection({ ...editSection, propertyInformation: false });
                return;
        }
    }

    const goToDashboard = () => {
        history.push("/dashboard");
        navigation.SetBackOnClick(() => {
            history.push({ pathname: "/loan-view" });
        });
    }

    const updatePreQualLetterStatus = async () => {
        if (!props.isLoanComplated) {
            let response = await systematicLetterService.updatePreQualInitiateStatus(props.loanGuid);
            if (response.ok) {
                goToDashboard();
            }
        }
        else {
            goToDashboard();
        }
    }

    const navigation = useNavigation(() => {
        navigation.RenameNavigation("Submit", "Back");
        navigation.ShowContinue(() => { }, true);
        navigation.SetBackOnClick(() => {
            history.push("/contact-preference");
            props.setLoanIsDirty(false);
        });
    });

    /*const addLoanInBatch = async () => {
        if (!props.isLoanComplated) {
            let response = await loanService.addLoanInBatch(props.interimLoanStagingId);
            if (response.ok) {
                goToDashboard();
            }
        }
        else {
            goToDashboard();
        }
    }*/

    navigation.ShowContinue(() => {
        if (isLoanFromEncompassFlag && !props.isLoanComplated) {
            props.setLoanFromEncompassMessageFlag(true);
        }
        if (!props.isLoanComplated && !isLoanFromEncompassFlag) {
            props.submitCheckFlag(true);
            SendLeadCompletionNotificationToLoanOfficer(props.loanGuid).then((emailResponse: any) => {
                if (emailResponse.parsedBody) {
                    console.log(`SendLeadCompletionNotificationToLoanOfficer send for loanGuid: ${props.loanGuid} and response: ${emailResponse.parsedBody}`);
                };
            })
        }
        // addLoanInBatch();
        if (props.isLoanComplated) {
            goToDashboard();
        }
        props.setLoanIsDirty(false);
        props.updateNavigationStatus(TaskItem.PreQualReviewScreen, SubTaskItem.PreQualReviewScreen, TaskStatusId.Completed, TaskStatusId.Completed, props.loanGuid);
        addRequiredDocuments(props.loanGuid, props.interimLoanStagingId, isLoanFromEncompassFlag);
        props.setIsLoanCompleted(true);
        props.setDocumentPopupFlag(true);
        if (props.loanSummary.loanPurpose === "Purchase") {
            updatePreQualLetterStatus();
        } else {
            goToDashboard();
        }
    }, (editSection.borrowerAdditionalIncomeInformation ||
        editSection.borrowerAddressInformation ||
        editSection.borrowerEmploymentInformation ||
        editSection.borrowerGeneralInformation ||
        editSection.coBorrowerAdditionalIncomeInformation ||
        editSection.coBorrowerAddressInformation ||
        editSection.coBorrowerEmploymentInformation ||
        editSection.coBorrowerGeneralInformation ||
        editSection.propertyInformation ||
        editSection.reoInformation ||
        isLoading || isEncompassDown));

    return <>
        <div className="w-100-p">
            <div className={styles.accordion}>
                <span className={styles.accordion__button} onClick={() => setBorrowerExpand(!isBorrowerExpand)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Borrower Information')}
>
                    <i className='fa fa-user'></i>
                    Borrower Information
                    <span className={styles.dropdown} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to expand')}
>
                        <i className={isBorrowerExpand ? 'fa fa-caret-left' : 'fa fa-caret-down'}></i>
                    </span>
                </span>
                {isBorrowerExpand &&
                    <div className={`${styles.accordion__content_active}`}>
                        <div className={styles.topHeading}>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('General Information')}>General Information </h2>
                            <div className={styles.editSaveIcon}>
                                {!isLoanFromEncompassFlag && !editSection.borrowerGeneralInformation && isBorrowerExpand &&
                                <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, borrowerGeneralInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}
></i>
                                }
                                {editSection.borrowerGeneralInformation && isBorrowerExpand &&
                                    <>
                                        <i className={`fa fa-check-circle ${styles.saveButton} ${!borrowerInformationValid.isBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                    onClick={() => saveInformation(BorrowerTypeEnum.Borrower, "GeneralInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}></i>

                                <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges(BorrowerTypeEnum.Borrower, "GeneralInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}></i>
                                    </>
                                }
                            </div>
                        </div>
                        <div className={`${(!editSection.borrowerGeneralInformation && isBorrowerExpand) ? `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                            <ReviewBorrowerInformation setBorrowerInformationValid={setValid} borrowerInformation={props.borrowerInformation} section="BorrowerGeneralInformation"
                                borrowerType={AccountCreationStore.BorrowerType.PrimaryBorrower} isEdit={!editSection.borrowerGeneralInformation} isLoanCompleted={props.isLoanComplated}
                            />
                        </div>

                        <hr />
                        <div className={styles.topHeading}>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Address Information')}>Address Information</h2>

                            <div className={styles.editSaveIcon}>
                                {!isLoanFromEncompassFlag && !editSection.borrowerAddressInformation && isBorrowerExpand &&
                                <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, borrowerAddressInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}></i>
                                }
                                {editSection.borrowerAddressInformation && isBorrowerExpand &&
                                    <>
                                        <i className={`fa fa-check-circle ${styles.saveButton} ${!addressInformationValid.isBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                    onClick={() => saveInformation(BorrowerTypeEnum.Borrower, "AddressInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}></i>

                                        <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() =>
                                    discardChanges(BorrowerTypeEnum.Borrower, "AddressInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}
                                        ></i>
                                    </>
                                }
                            </div>
                        </div>

                        <div className={`${(!editSection.borrowerAddressInformation && isBorrowerExpand) ?
                            `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>

                            {props.borrowerInformation.currentAddress &&
                                <ReviewAddressInformation
                                    setAddressInformationValid={setAddressValid}
                                    borrowerAddress={props.borrowerInformation.currentAddress}
                                    section="BorrowerAddressInformation"
                                    borrowerType={AccountCreationStore.BorrowerType.PrimaryBorrower}
                                    isEdit={!editSection.borrowerAddressInformation}
                                    addressType={AccountCreationStore.AddressType.CurrentAddress}
                                    isLoanCompleted={props.isLoanComplated}
                                />
                            }

                            {props.borrowerInformation.addresses && props.borrowerInformation.addresses?.length > 0 &&
                                props.borrowerInformation?.addresses?.filter(x => x.residencyType === "Prior").map((item, index) =>
                                    <React.Fragment key={index}>
                                        <ReviewAddressInformation
                                            setAddressInformationValid={setAddressValid}
                                            borrowerAddress={item}
                                            section="BorrowerAddressInformation"
                                            borrowerType={AccountCreationStore.BorrowerType.PrimaryBorrower}
                                            isEdit={!editSection.borrowerAddressInformation}
                                            addressType={AccountCreationStore.AddressType.FormerAddress}
                                            isLoanCompleted={props.isLoanComplated}
                                        />
                                    </React.Fragment>
                                )}
                        </div>

                        {props.borrowerEmploymentInformation &&
                            props.borrowerEmploymentInformation?.employments.length > 0 &&
                            props.borrowerEmploymentInformation?.currentEmploymentDoesNotApply &&
                            (props.borrowerEmploymentInformation?.employments[0].selfEmployedIndicator !== undefined &&
                                !props.borrowerEmploymentInformation?.employments[0].selfEmployedIndicator) &&
                            <>
                                <hr />
                                <div className={styles.topHeading}>
                            <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Employment & Income Information')}>Employment & Income Information</h2>
                                    <div className={styles.editSaveIcon}>
                                        {!isLoanFromEncompassFlag && !editSection.borrowerEmploymentInformation && isBorrowerExpand &&
                                    <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, borrowerEmploymentInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}></i>
                                        }
                                        {editSection.borrowerEmploymentInformation && isBorrowerExpand &&
                                            <>
                                                <i className={`fa fa-check-circle ${styles.saveButton} ${!employmentInformationValid.isBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                        onClick={() => saveInformation(BorrowerTypeEnum.Borrower, "EmploymentInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}></i>

                                                <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() =>
                                        discardChanges(BorrowerTypeEnum.Borrower, "EmploymentInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}></i>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>}

                        {props.borrowerEmploymentInformation &&
                            props.borrowerEmploymentInformation?.employments.length > 0 &&
                            props.borrowerEmploymentInformation?.currentEmploymentDoesNotApply &&
                            (props.borrowerEmploymentInformation?.employments[0].selfEmployedIndicator !== undefined &&
                                !props.borrowerEmploymentInformation?.employments[0].selfEmployedIndicator) &&
                            props.borrowerEmploymentInformation?.employments?.map((item, index) =>
                                <div key={index} className={`${(!editSection.borrowerEmploymentInformation && isBorrowerExpand) ?
                                    `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                                    <ReviewEmploymentInformation
                                        setEmploymentInformationValid={setEmploymentValid}
                                        employmentList={props.borrowerEmploymentInformation?.employments}
                                        borrowerType={EmploymentInformationStore.BorrowerType.PrimaryBorrower}
                                        section="BorrowerEmploymentInformation"
                                        employmentInformation={item}
                                        key={index}
                                        employerIndex={index}
                                        isEdit={!editSection.borrowerEmploymentInformation}
                                        militryIndicator={props.borrowerEmploymentInformation?.selfDeclaredMilitaryServiceIndicator ?? false}
                                        isLoanCompleted={props.isLoanComplated} />
                                </div>
                            )}

                        {props.additionalIncomeList && props.additionalIncomeList?.otherIncomeSourceDetails
                            .filter(x => x.owner === BorrowerTypeEnum.Borrower).length > 0 &&
                            <><hr />
                                <div className={styles.topHeading}>
                            <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Additional Income Information')}>Additional Income Information</h2>
                                    <div className={styles.editSaveIcon}>
                                        {!isLoanFromEncompassFlag && !editSection.borrowerAdditionalIncomeInformation && isBorrowerExpand &&
                                    <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, borrowerAdditionalIncomeInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}></i>
                                        }
                                        {editSection.borrowerAdditionalIncomeInformation && isBorrowerExpand &&
                                            <>
                                                <i className={`fa fa-check-circle ${styles.saveButton} ${!additionalInformationValid.isBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                        onClick={() => saveInformation(BorrowerTypeEnum.Borrower, "AdditionalIncomeInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button save')}></i>

                                    <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges(BorrowerTypeEnum.Borrower, "AdditionalIncomeInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}></i>
                                            </>
                                        }
                                    </div>
                                </div>
                            </>
                        }

                        {props.additionalIncomeList && props.additionalIncomeList?.otherIncomeSourceDetails
                            .filter(x => x.owner === BorrowerTypeEnum.Borrower).map((item, index) =>
                                <div key={index} className={`${(!editSection.borrowerAdditionalIncomeInformation && isBorrowerExpand) ? `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                                    <ReviewAdditionalIncome setAdditionalInformationValid={setAdditionalIncomeValid} borrowerType={AccountCreationStore.BorrowerType.PrimaryBorrower} section="BorrowerAdditionalIncomeInformation"
                                        otherIncomeSourceDetail={item} key={index} isEdit={!editSection.borrowerAdditionalIncomeInformation} isLoanCompleted={props.isLoanComplated} />
                                </div>
                            )}
                    </div>
                }
            </div>

            {props.borrowerInformation.printOnAdditionalBorrowerPage &&
                <div className={styles.accordion}>
                <span className={styles.accordion__button} onClick={() => setCoBorrowerExpand(!isCoBorrowerExpand)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Co-Borrower Information')}
>
                        <img src={coBorrower} /> Co-Borrower Information
                <span className={styles.dropdown}>
                        <i className={isCoBorrowerExpand ? 'fa fa-caret-left' : 'fa fa-caret-down'} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to expand')}
>
                            </i>
                        </span>
                    </span>
                    {isCoBorrowerExpand &&
                        <>{
                            props.coborrowerInformation &&
                            <div className={`${styles.accordion__content_active}`}>
                                <div className={styles.topHeading}>
                            <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('General Information')}
>General Information </h2>
                                    <div className={styles.editSaveIcon}>

                                        {!isLoanFromEncompassFlag && !editSection.coBorrowerGeneralInformation && isCoBorrowerExpand &&
                                            <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ?
                                    setEditSection({ ...editSection, coBorrowerGeneralInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}
></i>
                                        }


                                        {editSection.coBorrowerGeneralInformation && isCoBorrowerExpand &&
                                            <>
                                                <i className={`fa fa-check-circle ${styles.saveButton} ${!borrowerInformationValid.isCoBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                        onClick={() => saveInformation(BorrowerTypeEnum.CoBorrower, "GeneralInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}
></i>

                                    <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges(BorrowerTypeEnum.CoBorrower, "GeneralInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}></i>
                                            </>
                                        }

                                    </div>
                                </div>
                                <div className={`${(!editSection.coBorrowerGeneralInformation && isCoBorrowerExpand) ? `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                                    <ReviewBorrowerInformation setBorrowerInformationValid={setValid} borrowerInformation={props.coborrowerInformation} section="CoBorrowerGeneralInformation"
                                        borrowerType={AccountCreationStore.BorrowerType.CoBorrower} isEdit={!editSection.coBorrowerGeneralInformation}
                                        isLoanCompleted={props.isLoanComplated}
                                    />
                                </div>

                                <hr />
                                <div className={styles.topHeading}>
                            <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Address Information')}
>Address Information</h2>
                                    <div className={styles.editSaveIcon}>
                                        {!isLoanFromEncompassFlag && !editSection.coBorrowerAddressInformation && isCoBorrowerExpand &&
                                    <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, coBorrowerAddressInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}
></i>
                                        }
                                        {editSection.coBorrowerAddressInformation && isCoBorrowerExpand &&
                                            <>
                                                <i className={`fa fa-check-circle ${styles.saveButton} ${!addressInformationValid.isCoBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                        onClick={() => saveInformation(BorrowerTypeEnum.CoBorrower, "AddressInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}
></i>

                                    <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges(BorrowerTypeEnum.CoBorrower, "AddressInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}
></i>
                                            </>
                                        }

                                    </div>
                                </div>
                                <div className={`${(!editSection.coBorrowerAddressInformation && isCoBorrowerExpand) ?
                                    `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                                    {props.coborrowerInformation.currentAddress &&
                                        <ReviewAddressInformation
                                            setAddressInformationValid={setAddressValid}
                                            borrowerAddress={props.coborrowerInformation.currentAddress}
                                            section="CoBorrowerAddressInformation"
                                            borrowerType={AccountCreationStore.BorrowerType.CoBorrower}
                                            isEdit={!editSection.coBorrowerAddressInformation}
                                            addressType={AccountCreationStore.AddressType.CurrentAddress}
                                            isLoanCompleted={props.isLoanComplated}
                                        />
                                    }

                                    {props.coborrowerInformation.addresses && props.coborrowerInformation.addresses?.length > 0 &&
                                        props.coborrowerInformation?.addresses?.filter(x => x.residencyType === "Prior").map((item, index) =>
                                            <React.Fragment key={index}>
                                                <ReviewAddressInformation
                                                    setAddressInformationValid={setAddressValid}
                                                    borrowerAddress={item}
                                                    section="CoBorrowerAddressInformation"
                                                    borrowerType={AccountCreationStore.BorrowerType.CoBorrower}
                                                    isEdit={!editSection.coBorrowerAddressInformation}
                                                    addressType={AccountCreationStore.AddressType.FormerAddress}
                                                    isLoanCompleted={props.isLoanComplated}
                                                />
                                            </React.Fragment>
                                        )}

                                </div>


                                {props.coborrowerEmploymentInformation &&
                                    props.coborrowerEmploymentInformation?.currentEmploymentDoesNotApply &&
                                    !props.coborrowerEmploymentInformation?.employments[0].selfEmployedIndicator &&
                                    props.coborrowerEmploymentInformation?.employments.length > 0 &&
                                    <><hr />
                                        <div className={styles.topHeading}>
                                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Employment & Income Information')}
>Employment & Income Information</h2>
                                            <div className={styles.editSaveIcon}>
                                                {!isLoanFromEncompassFlag && !editSection.coBorrowerEmploymentInformation && isCoBorrowerExpand &&
                                        <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, coBorrowerEmploymentInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}
></i>
                                                }

                                                {editSection.coBorrowerEmploymentInformation && isCoBorrowerExpand &&
                                                    <>
                                                        <i className={`fa fa-check-circle ${styles.saveButton} ${!employmentInformationValid.isCoBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                            onClick={() => saveInformation(BorrowerTypeEnum.CoBorrower, "EmploymentInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}
></i>

                                        <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges(BorrowerTypeEnum.CoBorrower, "EmploymentInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}
></i>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </>
                                }

                                {props.coborrowerEmploymentInformation &&
                                    props.coborrowerEmploymentInformation?.employments.length > 0 &&
                                    props.coborrowerEmploymentInformation?.currentEmploymentDoesNotApply &&
                                    !props.coborrowerEmploymentInformation?.employments[0].selfEmployedIndicator &&
                                    props.coborrowerEmploymentInformation?.employments?.map((item, index) =>
                                        <div key={index} className={`${(!editSection.coBorrowerEmploymentInformation && isCoBorrowerExpand) ? `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                                            <ReviewEmploymentInformation
                                                employmentList={props.borrowerEmploymentInformation?.employments}
                                                setEmploymentInformationValid={setEmploymentValid}
                                                borrowerType={EmploymentInformationStore.BorrowerType.CoBorrower}
                                                section="CoBorrowerEmploymentInformation"
                                                militryIndicator={props.coborrowerEmploymentInformation?.selfDeclaredMilitaryServiceIndicator ?? false}
                                                employmentInformation={item}
                                                employerIndex={index}
                                                key={index}
                                                isEdit={!editSection.coBorrowerEmploymentInformation}
                                                isLoanCompleted={props.isLoanComplated}
                                            />
                                        </div>
                                    )}


                                {props.additionalIncomeList && props.additionalIncomeList?.otherIncomeSourceDetails
                                    .filter(x => x.owner === BorrowerTypeEnum.CoBorrower).length > 0 &&
                                    <>
                                        <hr />
                                        <div className={styles.topHeading}>
                                <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Additional Income Information')}
>Additional Income Information</h2>
                                            <div className={styles.editSaveIcon}>
                                                {!isLoanFromEncompassFlag && !editSection.coBorrowerAdditionalIncomeInformation && isCoBorrowerExpand &&
                                        <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, coBorrowerAdditionalIncomeInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}
></i>
                                                }

                                                {editSection.coBorrowerAdditionalIncomeInformation && isCoBorrowerExpand &&
                                                    <>
                                                        <i className={`fa fa-check-circle ${styles.saveButton} ${!additionalInformationValid.isCoBorrowerValid || isEncompassDown ? styles.disable : ''}`}
                                            onClick={() => saveInformation(BorrowerTypeEnum.CoBorrower, "AdditionalIncomeInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}
></i>

                                        <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges(BorrowerTypeEnum.CoBorrower, "AdditionalIncomeInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}
></i>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </>
                                }

                                {props.additionalIncomeList && props.additionalIncomeList?.otherIncomeSourceDetails
                                    .filter(x => x.owner === BorrowerTypeEnum.CoBorrower).map((item, index) =>
                                        <div key={index} className={`${(!editSection.coBorrowerAdditionalIncomeInformation && isCoBorrowerExpand) ? `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                                            <ReviewAdditionalIncome setAdditionalInformationValid={setAdditionalIncomeValid} borrowerType={AccountCreationStore.BorrowerType.CoBorrower} section="CoBorrowerAdditionalIncomeInformation"
                                                otherIncomeSourceDetail={item} key={index} isEdit={!editSection.coBorrowerAdditionalIncomeInformation}
                                                isLoanCompleted={props.isLoanComplated}
                                            />
                                        </div>
                                    )}

                            </div>
                        }
                        </>
                    }
                </div>
            }

            <div className={styles.accordion}>
                <span className={styles.accordion__button} onClick={() => setReoExpand(!isReoExpand)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Real Estate Owned Properties')}
>
                    <Infographic src={realEstate} />
                    Real Estate Owned Properties
                    <span className={styles.dropdown}>
                        <i className={isReoExpand ? 'fa fa-caret-left' : 'fa fa-caret-down'} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to expand')}
></i>
                    </span>
                </span>

                {isReoExpand && !props.reoInformation?.realEstateDoesNotApply &&
                    <div className={`${styles.accordion__content_active}`}>
                        <div className={styles.topHeading}>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('REO Information')}
>REO Information </h2>
                            <div className={styles.editSaveIcon}>
                                {!isLoanFromEncompassFlag && !editSection.reoInformation && isReoExpand &&
                                <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, reoInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}
></i>
                                }
                                {editSection.reoInformation && isReoExpand &&
                                    <>

                                        <i className={`fa fa-check-circle ${styles.saveButton} ${!reoValid || isEncompassDown ? styles.disable : ''}`}
                                    onClick={() => saveInformation("REO", "REO")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}
></i>

                                <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges("REO", "REO")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}
></i>
                                    </>
                                }
                            </div>
                        </div>
                        <div className={`${(!editSection.reoInformation && isReoExpand) ? `${styles.readonly} ${styles.formContainer}` :
                            `${styles.formContainer}`}`}>

                            {props.reoInformation &&
                                props.reoInformation?.reoProperties.map((item, index) =>
                                    <ReviewReoInformation key={index} reoData={item} isEdit={!editSection.reoInformation} setReoValid={setReoInformationValid} isLoanCompleted={props.isLoanComplated}></ReviewReoInformation>
                                )}

                        </div>
                    </div>
                }

                {isReoExpand && props.reoInformation?.realEstateDoesNotApply &&
                    <div className={`${styles.accordion__content_active}`} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('No records found')}
>
                        No records found!!
                    </div>
                }
            </div>

            <div className={styles.accordion}>
                <span className={styles.accordion__button} onClick={() => setPropertyExpand(!isPropertyExpand)} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Potential Property')}
>
                    <i className='fa fa-home'></i>
                    Potential Property
                    <span className={styles.dropdown}>
                        <i className={isPropertyExpand ? 'fa fa-caret-left' : 'fa fa-caret-down'} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to expand')}
></i>
                    </span>
                </span>

                {isPropertyExpand &&
                    <div className={`${styles.accordion__content_active} ${styles.propertyAccordion}`}>
                        <div className={styles.topHeading}>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Property Information')}
>Property Information</h2>
                            <div className={styles.editSaveIcon}>
                                {!isLoanFromEncompassFlag && !editSection.propertyInformation && isPropertyExpand &&
                                <i className="fa fa-pencil-square-o" onClick={() => !props.isLoanComplated ? setEditSection({ ...editSection, propertyInformation: true }) : ""} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to edit')}
></i>
                                }
                                {editSection.propertyInformation && isPropertyExpand &&
                                    <>
                                        <i className={`fa fa-check-circle ${styles.saveButton} ${!propertyAddressValid || isEncompassDown ? styles.disable : ''}`}
                                    onClick={() => saveInformation("PropertyInformation", "PropertyInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to save')}
></i>

                                <i className={`fa fa-times-circle ${styles.discardButton}`} onClick={() => discardChanges("PropertyInformation", "PropertyInformation")} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to discard changes')}
></i>
                                    </>
                                }
                            </div>
                        </div>

                        <div className={`${(!editSection.propertyInformation && isPropertyExpand) ? `${styles.readonly} ${styles.formContainer}` : `${styles.formContainer}`}`}>
                            <ReviewPropertyInformation setPropertyValid={setPropertyAddressValid} loanPurpose={props.loanPurpose} borrowerPropertyInformation={props.borrowerPropertyInformation}
                                isEdit={!editSection.propertyInformation} isLoanCompleted={props.isLoanComplated} />
                        </div>
                    </div>
                }
            </div>

            {isLoading && <div className={styles.reviewAppLoaderBg}>
                <div className="loader medium"></div>
            </div>}
        </div>
    </>;
};

const mapStateToProps = (state: ApplicationState): any => {
    const { accountInformation, accountCreation, employmentInformation, propertyInformation, additionalIncome, borrowerReo, reviewApplication, taskMenu } = state;
    let borrowerInformation;
    let coborrowerInformation;
    let borrowerEmploymentInformation;
    let coborrowerEmploymentInformation;
    let borrowerPropertyInformation;
    let additionalIncomeList;
    let reoInformation;
    let validation;
    let section;
    let loanPurpose;
    let loanGuid = "";
    let hasCoBorrower;
    let isLoanComplated = state.loan?.isLoanCompleted;
    let loanSummary;

    if (accountInformation?.loanGuid) {
        loanGuid = accountInformation?.loanGuid;
    }

    if (accountCreation?.borrower) {
        borrowerInformation = accountCreation?.borrower;
        hasCoBorrower = accountCreation?.borrower?.printOnAdditionalBorrowerPage
    }

    if (accountCreation?.coBorrower) {
        coborrowerInformation = accountCreation?.coBorrower;
    }

    if (reviewApplication) {
        validation = reviewApplication?.validation;
        section = reviewApplication?.section;
    }

    if (employmentInformation) {
        borrowerEmploymentInformation = employmentInformation?.borrower;
        coborrowerEmploymentInformation = employmentInformation?.coBorrower;
    }

    if (additionalIncome?.data) {
        additionalIncomeList = additionalIncome?.data;
    }

    if (propertyInformation) {
        borrowerPropertyInformation = propertyInformation;
        loanPurpose = propertyInformation.purposeType;
    }

    if (borrowerReo) {
        reoInformation = borrowerReo;
    }

    if (taskMenu) {
        loanSummary = taskMenu.loanSummary;
    }

    const userType = state.userAccountInformation?.borrowerType;
    let interimLoanStagingId = state.loan?.interimLoanStagingId;
    return {
        loanGuid,
        borrowerInformation,
        coborrowerInformation,
        borrowerPropertyInformation,
        coborrowerEmploymentInformation,
        borrowerEmploymentInformation,
        validation,
        additionalIncomeList,
        loanPurpose,
        section,
        reoInformation,
        hasCoBorrower,
        isLoanComplated,
        loanSummary,
        userType,
        interimLoanStagingId
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    //borrower
    getBorrowerInformation: (borrowerType: string, interimLoanStagingId: number) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.getBorrowerInformation(borrowerType, interimLoanStagingId));
    },

    //additional income
    getAdditionalIncomeInformation: (interimLoanStagingId: number) => {
        dispatch(AditionalIncomeActions.AdditionalIncomeActionCreators.getAdditionalIncomeInformation(interimLoanStagingId));
    },

    //employment
    getEmploymentInformation: (borrowerType: string, interimLoanStagingId: number) => {
        dispatch(EmploymentInformationActions.employmentInformationActionCreators.getEmploymentInformation(borrowerType, interimLoanStagingId));
    },

    //property
    getPropertyInformation: (interimLoanStagingId: number) => {
        dispatch(PropertyInformationActions.propertyInformationActionCreators.getPropertyInformation(interimLoanStagingId));
    },

    //reo
    getBorrowerReo: (interimLoanStagingId: number) => {
        dispatch(BorrowerReoActions.BorrowerReoActionCreators.getBorrowerReo(interimLoanStagingId));
    },

    //set loan dirty for task menu
    setLoanIsDirty: (value: boolean) => {
        dispatch(TaskMenuCreators.setLoanIsDirty(value));
    },
    setDocumentPopupFlag: (value: boolean) => {
        dispatch(TaskMenuCreators.setDocumentPopupFlag(value));
    },
    updateNavigationStatus: (taskItemId: any, subTaskItemId: any, taskStatusId: any, subTaskStatusId: any, loanGuid: any) => {
        dispatch(TaskMenuCreators.updateNavigationStatus(taskItemId, subTaskItemId, taskStatusId, subTaskStatusId, loanGuid));
    },
    setIsLoanCompleted: (value?: boolean) => {
        dispatch(loanActionCreators.setIsLoanCompleted(value));
    },

    //loan summary
    borrowerLoanSummary: (loanGuid: string, interimLoanStagingId: number) => {
        dispatch(TaskMenuCreators.borrowerLoanSummary(loanGuid, interimLoanStagingId));
    },
    setIsEncompassDown: (isEncompassDown?: boolean) => {
        dispatch(loanUpdateResponseActionCreators.setIsEncompassDown(isEncompassDown));
    },
    // ReviewApplication
    submitCheckFlag: (value: boolean) => {
        dispatch(reviewApplicationActionCreators.submitCheckFlag(value));
    },
    setLoanFromEncompassMessageFlag: (value: boolean) => {
        dispatch(reviewApplicationActionCreators.setLoanFromEncompassMessageFlag(value));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewApplication as any);