import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import FooterText from "./FooterText";
import UhmLogo from "assets/logo/uhm-logo.png";
import styles from "./LoanOfficerViewLayout.module.scss";
import Navigation from "../Buttons/Navigation";
import { ApplicationState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { NavigationState } from "store/navigationStore";
import * as ActionTypes from "common/ActionTypes";

const LoanOfficerViewLayout = (props: { children?: React.ReactNode }) => {
    const navigation = useSelector((state: ApplicationState) => state.navigation) as NavigationState;
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch({ type: ActionTypes.ResetNavigationFromRoute });
    }, [location]);

    return (
        <div className={styles.appContainer}>
            <div className={styles.topHeader}>
                <div className={styles.logo}>
                    <Link to="/loan-officer-view">
                        <img src={UhmLogo} alt="Union Home Mortgage" />
                    </Link>
                </div>
            </div>

            <div className={styles.borderedBackground}>
                <div className={styles.contentContainer}>
                    {props.children}
                </div>
                <Navigation
                    back={{
                        show: navigation.back.show,
                        disabled: navigation.back.disabled,
                        message: navigation.back.message,
                        onClick: () => {
                            if (navigation.back.onClick) {
                                navigation.back.onClick();
                            } else {
                                history.goBack();
                            }
                        },
                    }}
                    continue={{
                        show: navigation.continue.show,
                        disabled: navigation.continue.disabled,
                        message: navigation.continue.message,
                        onClick: () => {
                            if (navigation.continue.onClick) {
                                navigation.continue.onClick();
                            }
                        },
                    }}
                />
            </div>
            <div className={styles.footer}>
                <FooterText />
            </div>
        </div>
    );
};

export default LoanOfficerViewLayout;