import * as React from "react";
import { EmploymentScreensNew, ScreenDetails } from "./EmploymentInformationFields";
import styles from "./employmentInformation.module.scss";
import { useNavigation } from "hooks/useNavigation";
import Infographic from "../common/Infographic";
import MoreInformationRequiredSvg from "assets/infographics/employmentInformation/MoreInformationRequiered.svg";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type AdditionalInformationLandingProps =
    {
        info: ScreenDetails,
        changeScreen: React.Dispatch<React.SetStateAction<EmploymentScreensNew>>,
        isEncompassDown: boolean
    }
const AdditionalInformationLanding = (props: AdditionalInformationLandingProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const navigation = useNavigation();
    const onBack = () => {
        moveFrwdBackwrd(true)
    };
    const onContinue = () => {
        moveFrwdBackwrd(false)
    };

    const moveFrwdBackwrd = (back: boolean) => {
        if (back) {
            if (props.info.prevScreen) {
                props.changeScreen(props.info.prevScreen)
            }
        }
        else {
            if (props.info.nextScreen) {
                props.changeScreen(props.info.nextScreen)
            }
        }
    }

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className="">
            <div className={`infographic-side-layout-container ${styles.landingContainer}`}>
                <div className="content">
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('We Need A Little More Information')}>We Need A Little More Information.</h1>

                    <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('We need information from the past two years. Please take the time to fill out a few more fields')}>
                        We need information
                        from the past two
                        years. Please take the
                        time to fill out a few
                        more fields.
                    </h2>
                </div>
                <div className="infographic-side-panel">
                    <Infographic src={MoreInformationRequiredSvg} />
                </div>
            </div>


            {navigation.SetBackOnClick(
                () => onBack()
            )}
            {navigation.ShowContinue(
                () => onContinue(),
                props.isEncompassDown
            )}
        </div>
    );
}

export default React.memo(AdditionalInformationLanding) as any;