import { ResponseDto } from "common/models/ResponseDto";
import { DocumentSectionDto } from "components/documents/models/DocumentSectionDto";
import { DocumentRequiredViewModel } from "components/documents/models/DocumentRequiredViewModel";
import { AddSectionDto } from "components/documents/models/AddSectionDto";
import { RemoveSectionDto } from "components/documents/models/RemoveSectionDto";
import { DocumentDataDto } from "components/documents/models/DocumentDataDto";
import { UpdateDocumentStatusDto } from "components/documents/models/UpdateDocumentStatusDto";
import { UploadDocumentsDto } from "components/documents/models/UploadDocumentsDto";
import { get, post, postForm, getBlob } from "../common/dataFetch";

export const getSectionsByLoanguid = (loanGuid: string) => {
    return get<ResponseDto<DocumentSectionDto[]>>(
        `api/Document/GetSectionsForLoanGuid?loanGuid=${loanGuid}`
    );
};

export const getDocumentContents = (loanGuid: string, documentId: number) => {
    return get<ResponseDto<DocumentDataDto>>(
        `api/Document/GetDocumentContents?loanGuid=${loanGuid}&documentId=${documentId}`
    );
};

export const getSectionOptions = () => {
    return get<ResponseDto<DocumentRequiredViewModel[]>>(`api/Document/GetDropdownAsync`);
};

export const generateCreditLoxLetterPdf = (loanGuid: string) => {
    return getBlob<any>(`api/Document/ReadCreditLoxDocumentAsync?loanGuid=${loanGuid}`);
};

export const generateGiftLetterPdf = (loanGuid: string) => {
    return getBlob<any>(`api/Document/GenerateGiftLetterPdf?loanGuid=${loanGuid}`);
};

export const addNewSection = (dto: AddSectionDto) => {
    return post<ResponseDto<DocumentSectionDto>>("api/Document/AddSectionToLoan", dto);
};

export const removeSection = (dto: RemoveSectionDto) => {
    return post<ResponseDto<any>>("api/Document/RemoveSectionFromLoan", dto);
};

export const updateDocumentStatus = (dto: UpdateDocumentStatusDto) => {
    return post<ResponseDto<any>>("api/Document/UpdateDocumentStatus", dto);
};

export const uploadDocuments = (dto: UploadDocumentsDto) => {
    return post<ResponseDto<any>>("api/Document/UploadDocuments", dto);
};

export const uploadAttachment = (loanGuid: string, sectionId: number, formData?: any) => {
    return postForm<any>(`api/Document/UploadDocuments?loanGuid=${loanGuid}&sectionId=${sectionId}`, formData);
}

export const addRequiredDocuments = (loanGuid: string, interimLoanStagingId: number, isLoanFromEncompassFlag?: boolean) => {
    return post<ResponseDto<any>>(
        `api/Document/AddRequiredDocumentationToLoanAsync?interimLoanStagingId=${interimLoanStagingId}&isLoanFromEncompassFlag=${isLoanFromEncompassFlag}`, loanGuid
    );
};

export const deleteAttachment = (loanGuid: string, sectionId: number, documentDto: any) => {
    return post<any>(`api/Document/DeleteAsync?loanGuid=${loanGuid}&sectionId=${sectionId}`, documentDto);
}
export const SendLeadCompletionNotificationToLoanOfficer = (loanGuid: string) => {
    return get<any>(`api/Document/SendLeadCompletionNotificationToLoanOfficer?loanGuid=${loanGuid}`);
};