import * as React from "react";
import { connect } from "react-redux";
import * as ReviewApplicationActions from "../../actions/reviewApplicationActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import * as AccountCreationStore from "../../store/accountCreationStore";
import * as ReviewApplicationStore from "../../store/reviewApplicationStore";
import styles from "./reviewApplication.module.scss";
import { ApplicationState } from "../../store";
import TextInput from "../common/Input/TextInput";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import SelectDropDown from "../common/Input/SelectDropDown";
import { MaritalStatusEnum } from "../common/Enums/MaritalStatusEnum";
import moment from 'moment';
import * as AccountCreationActions from "../../actions/accountCreationActions";
import { isNullOrWhiteSpace, validateBirthDate, toDate, isValidDate } from "../../common/helper/formatHelper";
import { Rule } from "../constants/validation";
import CustomDatePicker from "../common/CustomDatePicker";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type ReviewBorrowerInformationProps = {
    borrowerInformation: AccountCreationStore.Borrower;
    borrowerType: AccountCreationStore.BorrowerType;
    isEdit: boolean;
    section: string;
    setBorrowerInformationValid: (isValid: any, borrowerType: number) => void;
    disabled: boolean;
}
    & ReviewApplicationStore.ReviewApplicationState
    & typeof ReviewApplicationActions.reviewApplicationActionCreators
    & typeof AccountCreationActions.accountCreationActionCreators
    & typeof TaskMenuCreators

const ReviewBorrowerInformation = (props: ReviewBorrowerInformationProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [birthdatemsg, setBirthdatemsg] = React.useState(false);
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    React.useEffect(() => {
        let isValid =
            !isNullOrWhiteSpace(props.borrowerInformation.firstName) &&
            !isNullOrWhiteSpace(props.borrowerInformation.lastName) &&
            !isNullOrWhiteSpace(props.borrowerInformation.emailAddressText) &&
            !isNullOrWhiteSpace(props.borrowerInformation.maritalStatusType);

        if (!isValidDate(toDate(props.borrowerInformation.birthDate)) || birthdatemsg) {
            isValid = false;
        }

        if (props.borrowerInformation.taxIdentificationIdentifier &&
            props.borrowerInformation.taxIdentificationIdentifier.length < 9) {
            isValid = false;
        }

        if (props.borrowerInformation.emailAddressText &&
            !(Rule.EmailValidation.value.test(props.borrowerInformation.emailAddressText))) {
            isValid = false;
        }

        if (props.borrowerInformation.phoneNumber &&
            props.borrowerInformation.phoneNumber.length < 10) {
            isValid = false;
        }

        props.setBorrowerInformationValid(isValid, props.borrowerType);
    }, [props.borrowerInformation]);

    return <>
        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('First Name')}>First Name <span className={styles.requiredMsg}> * </span>:  </label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#firstName"
                name="First Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.updateBorrowerProperty(e.target.value, "firstName", props.borrowerType);
                }}
                value={props.borrowerInformation?.firstName || ""}
                readOnly={props.isEdit}
                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.firstName || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Middle Name (Optional)')}>Middle Name <span className={styles.optionalMsg}> (Optional) </span>:  </label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#middleName"
                name="Middle Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.updateBorrowerProperty(e.target.value, "middleName", props.borrowerType);
                }}
                value={props.borrowerInformation?.middleName || ""}
                readOnly={props.isEdit}
                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.middleName || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Last Name')}>Last Name <span className={styles.requiredMsg}> * </span>: </label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#lastName"
                name="Last Name"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.updateBorrowerProperty(e.target.value, "lastName", props.borrowerType);
                }}
                value={props.borrowerInformation?.lastName || ""}
                readOnly={props.isEdit}
                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.lastName || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Suffix (Optional)')}>Suffix <span className={styles.optionalMsg}> (Optional) </span>: </label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#suffixToName"
                name="Suffix"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.updateBorrowerProperty(e.target.value, "suffixToName", props.borrowerType);
                }}
                value={props.borrowerInformation?.suffixToName || ""}
                readOnly={props.isEdit}
                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.suffixToName || "")} />
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Phone Number')}>Phone Number <span className={styles.requiredMsg}> * </span>: </label>
            <div className={styles.inputContainer}>

                <NumberFormatInput
                    disabled={props.disabled}
                    className={styles.numberFormat}
                    value={props.borrowerInformation?.phoneNumber || ''}
                    format="###-###-#### ####"
                    name="phoneNumber"
                    id="#phoneNumber"
                    onValueChange={(values: { formattedValue: any; value?: any; }) => {
                        props.updateBorrowerProperty(values.value, "phoneNumber", props.borrowerType);
                    }}
                    readOnly={props.isEdit}
                    onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.phoneNumber || '')}/>
            </div>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Email')}>Email <span className={styles.requiredMsg}> * </span>: </label>
            <TextInput
                disabled={props.disabled}
                className={styles.input}
                id="#emailAddressText"
                name="Email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.updateBorrowerProperty(e.target.value, "emailAddressText", props.borrowerType);
                }}
                value={props.borrowerInformation?.emailAddressText || ""}
                readOnly={props.isEdit}
                onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.emailAddressText || "")}/>
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Marital Status')}>Marital Status <span className={styles.requiredMsg}> * </span>: </label>
            {!props.isEdit ?
                <div className={styles.inputContainer}>
                    <SelectDropDown
                        disabled={props.disabled}
                        className={styles.selectDdl}
                        value={props.borrowerInformation?.maritalStatusType || ""}
                        name="Marital Status Type"
                        id="#maritalStatusType"
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            props.updateBorrowerProperty(e.target.value, "maritalStatusType", props.borrowerType);
                        }}
                        noArrow={false}
                        dropdownclass={styles.select}
                        readOnly={props.isEdit}
                        onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.maritalStatusType || "")}>
                        <option value="Select">Select..</option>
                        {Object.keys(MaritalStatusEnum).map(key => (
                            <option key={key} value={key}>
                                {key}
                            </option>
                        ))}
                    </SelectDropDown>
                </div>
                : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.maritalStatusType)}>{props.borrowerInformation?.maritalStatusType}</label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Date Of Birth')}>Date Of Birth <span className={styles.requiredMsg}> * </span>: </label>
            {!props.isEdit ? <>
                <div className={styles.inputContainer}>

                    <CustomDatePicker
                        disabled={props.disabled}
                        className={`review-birth-date`}
                        fieldValue={props.borrowerInformation?.birthDate ? new Date(props.borrowerInformation?.birthDate) : undefined}
                        onDateChange={(date: any) => {

                            let isValidDate = validateBirthDate(date);
                            setBirthdatemsg(isValidDate ? false : true);
                            props.updateBorrowerProperty(toDate(date), "birthDate", props.borrowerType);
                        }}
                        popperPlacement={false}
                        maxDate={new Date()}
                        />
                </div>
            </>
                : <label className={styles.inputLabel} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(
                    props.borrowerInformation?.birthDate !== "" ?
                        moment(props.borrowerInformation?.birthDate).format('MM/DD/YYYY') : ""
                )}>{props.borrowerInformation?.birthDate !== "" ?
                    moment(props.borrowerInformation?.birthDate).format('MM/DD/YYYY') : ""}</label>
            }
        </div>

        <div className={styles.inputGroup}>
            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('SSN')}>SSN <span className={styles.requiredMsg}> * </span>: </label>
            <div className={styles.inputContainer}>

                <NumberFormatInput
                    disabled={props.disabled}
                    className={styles.numberFormat} value={props.borrowerInformation?.taxIdentificationIdentifier || ''}
                    format="###-##-####"
                    name="socialSecurityNumber"
                    id="#taxIdentificationIdentifier"

                    onValueChange={(values: { formattedValue: any; value?: any; }) => {
                        props.updateBorrowerProperty(values.value, "taxIdentificationIdentifier", props.borrowerType);
                    }}
                    readOnly={props.isEdit}
                    onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.borrowerInformation?.taxIdentificationIdentifier || '')} />

            </div>
        </div>
    </>;
};

const mapStateToProps = (state: ApplicationState, ownProps: {
    borrowerInformation: AccountCreationStore.Borrower,
    borrowerType: AccountCreationStore.BorrowerType,
    isEdit: boolean,
    section: string,
    setBorrowerInformationValid: (isValid: any, borrowerType: any) => void;
    isLoanCompleted: boolean
}): any => {
    return state.accountCreation ?
        {
            borrowerInformation: ownProps.borrowerInformation,
            borrowerType: ownProps.borrowerType,
            isEdit: ownProps.isEdit,
            section: ownProps.section,
            setBorrowerInformationValid: ownProps.setBorrowerInformationValid,
            disabled: ownProps.isLoanCompleted
        } : state;
}

const mapDispatchToProps = (dispatch: any) => ({
    updateBorrowerProperty: (value: string, propertyName: string, borrowerType: AccountCreationStore.BorrowerType) => {
        dispatch(AccountCreationActions.accountCreationActionCreators.updateBorrowerProperty(value, propertyName, borrowerType));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewBorrowerInformation as any);