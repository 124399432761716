import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styles from "./ModalPopup.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const ModalPopup = (props: { text: string, isOpen: boolean, onCancel: () => void, onSubmit: () => void }) => {
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <Modal
            isOpen={props.isOpen}
            toggle={() => props.onCancel()}
            centered={true}
            contentClassName={styles.modalContent}
            returnFocusAfterClose={false}
        >
            <ModalBody className={`${styles.modalBody}`}>
                <div className="align-center">
                    <h4 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Are you sure')}>Are you sure?</h4>
                    <h5 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech(props.text)}>
                        {props.text}
                    </h5>
                    <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to yes')} className={`common-button small ${styles.Button}`} onClick={() => props.onSubmit()}>
                        Yes
          </button>
                    <button onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Press button to no')} className={`common-button small ${styles.NoButton} ${styles.Button}`} onClick={() => props.onCancel()}>
                        No
          </button>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ModalPopup;
