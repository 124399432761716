import * as React from "react";
import styles from "./milestoneNavigation.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

const DefaultMilestoneDescription = () => {
    const { speak, cancel } = useSpeechSynthesis();
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
    return (
        <div className={styles.defaultDescription}>
            <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Wondering What Each Step Means")}>Wondering What Each Step Means?</p>
            <p onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech("Click One For A Description")}>Click One For A Description!</p>
        </div>
    );
};

export default DefaultMilestoneDescription;
