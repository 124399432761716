import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import Infographic from "components/common/Infographic";
import preQualLandingInfographic from "assets/infographics/systematicLetter/prequal-landing.svg";
import * as SystematicLetterActions from "../../actions/systematicLetterActions";
import * as SystematicLetterStore from "../../store/systematicLetterStore";
import { Link, useHistory } from "react-router-dom";
import { useNavigation } from "hooks/useNavigation";
import SystematicLetterDisplay from "components/systematicLetter/SystematicLetterDisplay";
import yesNotAgreeGraphic from "assets/infographics/consumerCreditAuthorization/yes-do-not-agree.svg";
import * as systematicLetterService from "../../services/systematicLetterService";
import styles from "./SystematicLetter.module.scss";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"
type SystematicLetterProps =
    {
        loanGuid: string
    } & SystematicLetterStore.SystematicLetterState &
    typeof SystematicLetterActions.systematicLetterActionCreators;

export enum SystematicLetterStep {
    Landing = 1,
    SystematicLetterDisplay = 2,
    SystematicLetterNotFound = 3,
    Finished = 4
}

const SystematicLetter = (props: SystematicLetterProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [currentStep, changeStep] = React.useState(SystematicLetterStep.Landing);
    const [isEnable, SetEnable] = React.useState(false);
    const navigation = useNavigation();
    const [showLoader, setShowLoader] = React.useState(false);
    const history = useHistory();

    const onBack = () => {
        if (currentStep === SystematicLetterStep.Landing) {
            history.push("/dashboard");
        }
        else if (currentStep === SystematicLetterStep.SystematicLetterDisplay) {
            changeStep(SystematicLetterStep.Landing);
        }
        else if (currentStep === SystematicLetterStep.SystematicLetterNotFound) {
            history.push("/dashboard");
        }
    };

    const onContinue = () => {
        if (currentStep === SystematicLetterStep.Landing) {
            handleButton();
        }
    }
    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }
    const handleButton = () => {
        SetEnable(true);
        setShowLoader(true);
        systematicLetterService.getSystematicLetterData(props.loanGuid)
            .then((response: any) => {
                if (!response.ok) {
                    throw new Error("There was a problem getting loan ");
                }
                return response.parsedBody as SystematicLetterStore.SystematicLetter;
            })
            .then((data: SystematicLetterStore.SystematicLetter) => {
                if (isNullOrWhiteSpace(data.firstName) ||
                    isNullOrWhiteSpace(data.lastName) ||
                    isNullOrWhiteSpace(data.loanAmount) ||
                    isNullOrWhiteSpace(data.subjectPropertyAddress) ||
                    isNullOrWhiteSpace(data.propertyType)) {
                    changeStep(SystematicLetterStep.SystematicLetterNotFound)
                }
                else {
                    props.getData(data);
                    changeStep(SystematicLetterStep.SystematicLetterDisplay)
                }
                setShowLoader(false);
            })
            .catch((error: Error) => {
                setShowLoader(false);
                console.error(error);
            });
    };

    let systematicLetterPage = <></>;

    if (currentStep === SystematicLetterStep.Landing) {
        systematicLetterPage = (
            <>
                <div className="infographic-side-layout-container">
                    <div className="content">
                        <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Congratulations')}>Congratulations!</h1>
                        <h2 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Based on the information you provided and a review of your credit report, we are giving you preliminary qualification')}>Based on the information you
                        provided and a review of your
                        credit report, we are giving you
                        preliminary qualification!</h2>

                        <button className={`common-green-button ${isEnable ? styles.selectActive : ""}`} onClick={() => { handleButton() }} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('press button to View Prequalification Letter')}>View Prequalification Letter</button>
                        {showLoader && (<div className="screenLoader"><div className="loader medium"></div></div>)}
                    </div>
                    <div className="infographic-side-panel">
                        <Infographic src={preQualLandingInfographic} />
                    </div>
                    {navigation.ResetContinue()}
                    {navigation.SetBackOnClick(
                        () => onBack()
                    )}
                </div>
            </>
        );

    }
    else if (currentStep === SystematicLetterStep.SystematicLetterDisplay) {
        systematicLetterPage = (
            <>
                <SystematicLetterDisplay />
                {navigation.ResetContinue()}
                {navigation.SetBackOnClick(
                    () => onBack()
                )}
            </>
        );
    }
    else if (currentStep === SystematicLetterStep.SystematicLetterNotFound) {
        systematicLetterPage = (
            <div className="infographic-side-layout-container">
                <div className="content">
                    <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Your Loan Officer will be in touch soon to go over options')}>Your Loan Officer will be in touch soon to go over options.</h1>
                </div>
                <div className="infographic-side-panel">
                    <Infographic src={yesNotAgreeGraphic} />
                </div>
                {navigation.ResetContinue()}
                {navigation.SetBackOnClick(
                    () => onBack()
                )}
            </div>
        );
    }

    return <div className="w-100-p">{systematicLetterPage}

    </div>;
};
const mapStateToProps = (state: ApplicationState): any => {
    const { accountInformation } = state;
    let loanGuid = accountInformation?.loanGuid;
    return {
        loanGuid
    }
}

export default connect(
    mapStateToProps,
    SystematicLetterActions.systematicLetterActionCreators
)(SystematicLetter as any);