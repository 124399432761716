import { post, get, getBlob} from "../common/dataFetch";
import { LoanOfficerMainTab, DocumentAttachment, PreApproval, PreApprovalLetterEmailTemplate } from "../store/LoanOfficerMainTabStore";


export const getApplicationFee = (loanGuid: string) => {
    return get<LoanOfficerMainTab>(`LoanOfficerDashboard/ReadByLoanGuidAsync?loanGuid=${loanGuid}`);
};
export const sendSecureInvitation = (loanGuid: string) => {
    return get<boolean>(`EmailNotification/SendSecureInvitation?loanGuid=${loanGuid}`);
};

export const getCreditReportAttachment = (loanGuid: string) => {
    return get<DocumentAttachment[]>(`api/CreditReport/GetCreditAttachment?loanGuid=${loanGuid}`);
};

export const CreatePreApprovalLetterAsync = (preApprovalLetterDto: PreApproval, loanGuid: string) => {

    return post<any>(`PreApprovalLetter/CreatePreApprovalLetter?loanGuid=${loanGuid}`, preApprovalLetterDto);
};

export const GeneratePreApprovalLetterAndUploadToEncompassAsync = (letterStyle: string, loanGuid: string, preApprovalId: any) => {
    return post<any>(`PreApprovalLetter/GeneratePreApprovalLetterAndUploadToEncompass?loanGuid=${loanGuid}&letterStyle=${letterStyle}&preApprovalId=${preApprovalId}`, null);
};

export const ReadPreApprovalLettersByLoanGuidAsync = (loanGuid: string) => {
    return get<any>(`PreApprovalLetter/ReadPreApprovalLettersByLoanGuid?loanGuid=${loanGuid}`);
};

export const ReadActivePreApprovalLetterByLoanGuidAsync = (loanGuid: string) => {
    return get<any>(`PreApprovalLetter/ReadActivePreApprovalLetterByLoanGuidAsync?loanGuid=${loanGuid}`);
};

export const UncheckInactivePreApprovalLettersIntoEncompass = (loanGuid: string) => {
    return post<any>(`PreApprovalLetter/UncheckInactivePreApprovalLettersIntoEncompass?loanGuid=${loanGuid}`, null);
};

export const GeneratePreApprovalInvoicePdf = (loanGuid: string, preApprovalId: any) => {
    return getBlob<any>(`PreApprovalLetter/GeneratePreApprovalInvoicePdf?loanGuid=${loanGuid}&preApprovalId=${preApprovalId}`);
};

export const UpdateAsync = (preApprovalLetterDto: PreApproval) => {
    return post<any>(`PreApprovalLetter/UpdateAsync`, preApprovalLetterDto);
};

export const SendPreApprovalLetterRequestNotificationToLoanOfficer = (loanGuid: string) => {
    return get<boolean>(`EmailNotification/SendPreApprovalLetterRequestNotificationToLoanOfficer?loanGuid=${loanGuid}`);
};

export const UpdateRequestedPreApprovalFlag = (preApprovalId: any) => {
    return post<any>(`PreApprovalLetter/UpdateRequestedPreApprovalFlag?preApprovalId=${preApprovalId}`, null);
};

export const PreApprovalLetterBaseTemplate = (loanGuid: string, preApprovalLetterEmailTemplateDto: PreApprovalLetterEmailTemplate) => {
    return post<boolean>(`EmailNotification/PreApprovalLetterBaseTemplate?loanGuid=${loanGuid}`, preApprovalLetterEmailTemplateDto);
};