import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { KnownAction, TaskMenuState } from "../store/taskMenuStore"

const unloadedState: TaskMenuState = {
    isLoading: false,
    errorMessage: "",
    data: [],
    loanSummary: undefined,
    isDirty: undefined,
    isPopupFlag: false
}

export const taskMenuReducer: Reducer<TaskMenuState> = (state: TaskMenuState | undefined, incomingAction: Action): TaskMenuState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.SetPopupFlag: {
            return { ...state, isPopupFlag: action.isPopupFlag };
        };
        case ActionTypes.SetLoanIsDirty: {
            return { ...state, isDirty: action.isDirty };
        };
        case ActionTypes.ReceiveTaskMenu: {
            if (action.data) {
                return {
                    ...state,
                    data: action.data,
                    isLoading: false
                };
            }
            return { ...state }
        };

        case ActionTypes.ReceiveBorrowerInformation: {
            if (action.data) {
                return {
                    ...state,
                    loanSummary: {
                        firstName: action.data.firstName,
                        lastName: action.data.lastName,
                        loanAmount: action.data.loanAmount,
                        loanNumber: action.data.loanNumber,
                        loanPurpose: action.data.loanPurpose,
                        propertyCity: action.data.propertyCity,
                        propertyState: action.data.propertyState,
                        propertyZip: action.data.propertyZip,
                        isLoading: false
                    }
                };
            }
            return { ...state }
        };

        case ActionTypes.RequestTaskMenu: {
            if (action.loadingType === "menu") {
                return {
                    ...state,
                    isLoading: action.isLoading
                };
            }

            return {
                ...state,
                loanSummary: {
                    isLoading: action.isLoading
                }
            };
        };
        default:
            return state;
    }
}
