import React, { useEffect, useState } from "react";
import styles from "./borrowerReo.module.scss";
import { ApplicationState } from "../../store";
import { BorrowerReoActionCreators } from "../../actions/borrowerReoActions";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { ReoProperty, BorrowerReoState } from "../../store/borrowerReoStore";
import { connect, useSelector, useDispatch } from "react-redux";
import ChoiceQuestion from "../common/ChoiceQuestion";
import { Choice } from "../../interfaces/Question";
import { useNavigationWithHistory } from "hooks/useNavigationWithHistory";
import InfographicSideLayout from "../common/Layout/InfographicSideLayout";
import LocationSearch from "components/common/Input/LocationSearch";
import PrimaryResidenceInfographic from "../../assets/infographics/borrowerReo/primary-residence.svg";
import SecondaryHomeInfographic from "../../assets/infographics/borrowerReo/secondary-home.svg";
import InvestmentPropertyInfographic from "../../assets/infographics/borrowerReo/investment-property.svg";
import SingleFamilyInfographic from "../../assets/infographics/borrowerReo/single-family.svg";
import CondoInfographic from "../../assets/infographics/borrowerReo/condo.svg";
import MultiUnitInfographic from "../../assets/infographics/borrowerReo/multi-unit.svg";
import NumberOfUnitsInfographic from "../../assets/infographics/borrowerReo/number-of-units.svg";
import ContinueToOwnInfographic from "../../assets/infographics/borrowerReo/continue-to-own.svg";
import PropertyAddressInfographic from "../../assets/infographics/borrowerReo/property-address.svg";
import PresentMarketValueInfographic from "../../assets/infographics/borrowerReo/present-market-value.svg";
import TextInput from "components/common/Input/TextInput";
import { useHistory } from "react-router";
import ReoPropertyOwner from "./ReoPropertyOwner";
import Tooltip from "../common/InfoIconTooltip";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import _uniqueId from 'lodash/uniqueId';
import { bindActionCreators } from "redux";
import { loanUpdateResponseActionCreators } from "../../actions/loanUpdateResponseActions";
import { update } from "../../services/borrowerReoService";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

type AddReoPropertyProps =
    {
        loanGuid: string,
        hasCoBorrower: boolean
        borrowerName?: string
        coBorrowerName?: string
        disabled: boolean
    }
    & BorrowerReoState
    & typeof BorrowerReoActionCreators
    & typeof TaskMenuCreators;

export enum AddReoPropertyStep {
    OccupancyType = 2,
    Owner = 3,
    PropertyType = 4,
    NoOfUnits = 5,
    ContinueToOwn = 6,
    Address = 7,
    PresentMarketValue = 8
}

const AddReoProperty = (props: AddReoPropertyProps) => {
    const { speak, cancel } = useSpeechSynthesis();
    const [zipFormat, setZipFormat] = React.useState("#########");

    const history = useHistory();

    const state: BorrowerReoState = useSelector(
        (state: ApplicationState) => state.borrowerReo as BorrowerReoState
    );

    const actions = { ...bindActionCreators({ ...BorrowerReoActionCreators, ...TaskMenuCreators, ...loanUpdateResponseActionCreators }, useDispatch()) };

    const loanGuid = useSelector(
        (state: ApplicationState) => state.accountInformation?.loanGuid
    );

    const [isLoading, setLoading] = React.useState(false);

    const [newReoProperty, changeNewReoProperty] = useState({
        id: _uniqueId('uniqueId-'),
        owner: "",
        occupancyType: "",
        propertyType: "",
        noOfUnits: 0,
        willContinueToOwnPropertyAfterTransaction: undefined,
        street: "",
        city: "",
        state: "",
        zip: "",
        marketValue: ""
    } as ReoProperty);

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    useEffect(() => {
        if (history.location && history.location?.state?.id) {
            let reo = state.reoProperties.filter(x => x.id === history.location?.state?.id)[0];
            if (reo) {
                newReoProperty.id = reo.id;
                newReoProperty.owner = reo.owner;
                newReoProperty.occupancyType = reo.occupancyType;
                newReoProperty.propertyType = reo.propertyType;
                newReoProperty.noOfUnits = reo.noOfUnits;
                newReoProperty.willContinueToOwnPropertyAfterTransaction = reo.willContinueToOwnPropertyAfterTransaction;
                newReoProperty.street = reo.street;
                newReoProperty.city = reo.city;
                newReoProperty.state = reo.state;
                newReoProperty.zip = reo.zip;
                newReoProperty.marketValue = reo.marketValue;
            }
        }
    }, [])

    useEffect(() => {
        if (newReoProperty.zip &&
            newReoProperty.zip.length > 5) {
            setZipFormat("#####-####");
            return;
        }
        setZipFormat("#########");
    }, [newReoProperty.zip]);

    const setAddress = (streetValue: string, cityValue: string, stateValue: any, zipValue: any) => {
        let updatedReoProperty = {
            ...newReoProperty,
            street: streetValue,
            city: cityValue,
            state: stateValue,
            zip: zipValue
        };

        changeNewReoProperty(updatedReoProperty);
        props.setLoanIsDirty(true);
    };

    const interimLoanStagingId = useSelector((state: ApplicationState) => state.loan?.interimLoanStagingId);

    const saveReoProperty = async () => {
        setLoading(true);
        var response = await update(state.reoProperties, true, loanGuid ?? "", interimLoanStagingId ?? 0);
        if (!response.ok) {
            console.log("There was a problem updating REO Information.");
        }
        let result = response.parsedBody as boolean;
        setLoading(false);
        if (result) {
            actions.setLoanIsDirty(false);
        }
        history.goBack();
    };

    const {
        CurrentStep: currentStep,
        ChangeStep: changeStep,
        Navigation: navigation,
        IsAtInitialStep,
    } = useNavigationWithHistory<AddReoPropertyStep>(AddReoPropertyStep.OccupancyType);

    useEffect(() => {
        if (IsAtInitialStep) {
            navigation.SetBackOnClick();
            navigation.ShowContinue(
                () => changeStep(AddReoPropertyStep.Owner),
                newReoProperty.occupancyType === ""
            );
        }
    }, [IsAtInitialStep]);

    useEffect(() => {
        if (!newReoProperty.willContinueToOwnPropertyAfterTransaction) {
            changeNewReoProperty({ ...newReoProperty, street: "", city: "", state: "", zip: "", marketValue: "" });
        }
    }, [newReoProperty.willContinueToOwnPropertyAfterTransaction]);

    useEffect(() => {
        props.addReoProperty(newReoProperty);     
    }, [newReoProperty]);

    useEffect(() => {
        if (currentStep === AddReoPropertyStep.ContinueToOwn) {
            if (newReoProperty.propertyType !== "Multi-Unit") {
                changeNewReoProperty({ ...newReoProperty, noOfUnits: 0 });
            }
        }
    }, [currentStep]);


    useEffect(() => {
        switch (currentStep) {
            case AddReoPropertyStep.OccupancyType: {
                navigation.ShowContinue(
                    () => changeStep(AddReoPropertyStep.Owner),
                    newReoProperty.occupancyType === ""
                );
                break;
            }
            case AddReoPropertyStep.Owner: {
                navigation.ShowContinue(
                    () => changeStep(AddReoPropertyStep.PropertyType),
                    newReoProperty.owner === ""
                );
                break;
            }
            case AddReoPropertyStep.PropertyType: {
                let nextStep =
                    newReoProperty.propertyType === "Multi-Unit"
                        ? AddReoPropertyStep.NoOfUnits
                        : AddReoPropertyStep.ContinueToOwn;

                navigation.ShowContinue(
                    () => changeStep(nextStep),
                    newReoProperty.propertyType === ""
                );
                break;
            }
            case AddReoPropertyStep.NoOfUnits: {
                navigation.ShowContinue(
                    () => changeStep(AddReoPropertyStep.ContinueToOwn),
                    newReoProperty.noOfUnits === 0
                );
                break;
            }
            case AddReoPropertyStep.ContinueToOwn: {
                navigation.ShowContinue(
                    () => { newReoProperty.willContinueToOwnPropertyAfterTransaction ? changeStep(AddReoPropertyStep.Address) : saveReoProperty() },
                    newReoProperty.willContinueToOwnPropertyAfterTransaction === undefined
                );
                break;
            }
            case AddReoPropertyStep.Address: {
                navigation.ShowContinue(
                    () => changeStep(AddReoPropertyStep.PresentMarketValue),
                    isNullOrWhiteSpace(newReoProperty.street) ||
                    isNullOrWhiteSpace(newReoProperty.city) ||
                    isNullOrWhiteSpace(newReoProperty.state) ||
                    isNullOrWhiteSpace(newReoProperty.zip) ||
                    newReoProperty?.zip?.length < 5
                );
                break;
            }
            case AddReoPropertyStep.PresentMarketValue: {
                navigation.ShowContinue(
                    saveReoProperty,
                    (isNullOrWhiteSpace(newReoProperty.marketValue.toString()) || newReoProperty.marketValue === "")
                );
                break;
            }
        }
    }, [currentStep, props.hasAdditionalProperties, newReoProperty]);

    let addReoPropertyMarkup = <></>;

    if (currentStep === AddReoPropertyStep.OccupancyType) {
        let occupancyTypeChoices: Choice[] = [
            {
                text: "Primary Residence",
                onClick: () => {
                    changeNewReoProperty({ ...newReoProperty, occupancyType: "Primary Residence" });
                    props.setLoanIsDirty(true);
                },
                icon: PrimaryResidenceInfographic,
                toolTipText: "Your main home",
                className: newReoProperty.occupancyType === "Primary Residence" ? styles.selected : ""
            },
            {
                text: "Second Home",
                onClick: () => {
                    props.setLoanIsDirty(true); changeNewReoProperty({ ...newReoProperty, occupancyType: "Second Home" })
                },
                icon: SecondaryHomeInfographic,
                toolTipText: "Your second home",
                className: newReoProperty.occupancyType === "Second Home" ? styles.selected : ""
            },
            {
                text: "Investment",
                onClick: () => {
                    props.setLoanIsDirty(true); changeNewReoProperty({ ...newReoProperty, occupancyType: "Investment" })
                },
                icon: InvestmentPropertyInfographic,
                toolTipText: "Your investment property",
                className: newReoProperty.occupancyType === "Investment" ? styles.selected : ""
            }
        ];

        addReoPropertyMarkup = (
            <ChoiceQuestion
                disabled={props.disabled}
                intro="Tell us about the other properties you own."
                question=""
                choices={occupancyTypeChoices}
                isIntroRequired={true}
            />
        );
    } else if (currentStep === AddReoPropertyStep.Owner) {
        addReoPropertyMarkup = (
            <ReoPropertyOwner
                borrowerName={props.borrowerName}
                coBorrowerName={props.coBorrowerName}
                hasCoBorrower={props.hasCoBorrower}
                valueChange={(value) => { changeNewReoProperty({ ...newReoProperty, owner: value }); props.setLoanIsDirty(true); }}
                value={newReoProperty.owner}
            ></ReoPropertyOwner>
        );
    } else if (currentStep === AddReoPropertyStep.PropertyType) {
        let propertyTypeChoices: Choice[] = [
            {
                text: "Single Family",
                onClick: () => {
                    props.setLoanIsDirty(true);
                    changeNewReoProperty({ ...newReoProperty, propertyType: "Single Family" })
                },
                icon: SingleFamilyInfographic,
                toolTipText: "One family home",
                className: newReoProperty.propertyType === "Single Family" ? styles.selected : ""
            },
            {
                text: "Condominium",
                onClick: () => {
                    props.setLoanIsDirty(true); changeNewReoProperty({ ...newReoProperty, propertyType: "Condominium" })
                },
                icon: CondoInfographic,
                toolTipText: "Condo",
                className: newReoProperty.propertyType === "Condominium" ? styles.selected : ""
            },
            {
                text: "Multi-Unit",
                onClick: () => {
                    props.setLoanIsDirty(true); changeNewReoProperty({ ...newReoProperty, propertyType: "Multi-Unit" })
                },
                icon: MultiUnitInfographic,
                toolTipText: "Multiple family home",
                className: newReoProperty.propertyType === "Multi-Unit" ? styles.selected : ""
            }
        ];

        addReoPropertyMarkup = (
            <ChoiceQuestion
                disabled={props.disabled}
                intro="What type of property is this?"
                question=""
                choices={propertyTypeChoices}
                isIntroRequired={true}
            />
        );
    } else if (currentStep === AddReoPropertyStep.NoOfUnits) {
        let occupancyTypeChoices: Choice[] = [
            {
                text: "1",
                onClick: () => {
                    props.setLoanIsDirty(true); changeNewReoProperty({ ...newReoProperty, noOfUnits: 1 })
                },
                className: newReoProperty.noOfUnits === 1 ? styles.selected : ""
            },
            {
                text: "2",
                onClick: () => {
                    props.setLoanIsDirty(true); changeNewReoProperty({ ...newReoProperty, noOfUnits: 2 })
                },
                className: newReoProperty.noOfUnits === 2 ? styles.selected : ""
            },
            {
                text: "3",
                onClick: () => {
                    props.setLoanIsDirty(true);; changeNewReoProperty({ ...newReoProperty, noOfUnits: 3 })
                },
                className: newReoProperty.noOfUnits === 3 ? styles.selected : ""
            }
        ];

        addReoPropertyMarkup = (
            <ChoiceQuestion
                disabled={props.disabled}
                intro="A Multi-Unit! Before we continue..."
                question="How many units?"
                choices={occupancyTypeChoices}
                infographic={NumberOfUnitsInfographic}
            />
        );
    } else if (currentStep === AddReoPropertyStep.ContinueToOwn) {
        let continueToOwnChoices: Choice[] = [
            {
                text: "Yes",
                onClick: () => {
                    changeNewReoProperty({ ...newReoProperty, willContinueToOwnPropertyAfterTransaction: true });
                    props.setLoanIsDirty(true);
                },
                className: newReoProperty.willContinueToOwnPropertyAfterTransaction === true ? styles.yesornoselected : undefined
            },
            {
                text: "No",
                onClick: () => {
                    changeNewReoProperty({ ...newReoProperty, willContinueToOwnPropertyAfterTransaction: false });
                    props.setLoanIsDirty(true);
                },
                className: newReoProperty.willContinueToOwnPropertyAfterTransaction === false ? styles.yesornoselected : undefined
            },
        ];

        addReoPropertyMarkup = (
            <div className={styles.yesNoQuestion}>
                <ChoiceQuestion
                    disabled={props.disabled}
                    intro="After this transaction will you continue to own this property?"
                    question=""
                    choices={continueToOwnChoices}
                    infographic={ContinueToOwnInfographic}
                    isIntroRequired={true}
                />
                {isLoading && (<div className="screenLoader"><div className="loader medium"></div></div>)}
            </div>
        );
    } else if (currentStep === AddReoPropertyStep.Address && newReoProperty.willContinueToOwnPropertyAfterTransaction) {
        addReoPropertyMarkup = (
            <InfographicSideLayout infographic={PropertyAddressInfographic}>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What is the address of this property')}>What is the address of this property? <Tooltip>Please provide the address for REO property.</Tooltip></h1>
                <div className={styles.reoInputGroup}>

                    <div className={styles.inputGroupSmall}>
                        <div className={styles.locationSearch}>
                            <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Street')}>Street </label>
                            <LocationSearch isDisabled={props.disabled} addressStreetLine1={newReoProperty.street ?? ""} addresssFieldName={"street"} borrowerType={"Primary"}
                                customType={"Custom"} setFullAddress={setAddress} styles={""}
                            />
                        </div>
                    </div>

                    <div className={styles.inputGroupSmall}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('City')}>City </label>
                        <TextInput disabled={props.disabled} type="text" name="City"
                            value={newReoProperty.city ?? ""} className={styles.input}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                changeNewReoProperty({ ...newReoProperty, city: e.target.value }); props.setLoanIsDirty(true);
                            }}
                        />
                    </div>

                    <div className={styles.inputGroupSmall}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('State')}>State </label>
                        <TextInput disabled={props.disabled} type="text" maxLength="2" name="State" value={newReoProperty.state ?? ""} className={styles.input}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                changeNewReoProperty({ ...newReoProperty, state: e.target.value });
                                props.setLoanIsDirty(true);
                            }}
                        />
                    </div>

                    <div className={styles.inputGroupSmall}>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Zip Code')}>Zip Code </label>
                        <NumberFormatInput
                            disabled={props.disabled}
                            className={styles.numberFormat}
                            value={newReoProperty.zip ?? ""}
                            format={zipFormat}
                            name="Zip Code"
                            id="#zip"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => {
                                changeNewReoProperty({ ...newReoProperty, zip: values.value });
                                props.setLoanIsDirty(true);
                            }}
                        />
                    </div>
                </div>
            </InfographicSideLayout>
        );
    } else if (currentStep === AddReoPropertyStep.PresentMarketValue) {
        addReoPropertyMarkup = (
            <InfographicSideLayout infographic={PresentMarketValueInfographic}>
                <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('What is the present market value')}>What is the present market value? </h1>
                <div className={styles.inputGroupSmall}>

                    <NumberFormatInput
                        disabled={props.disabled}
                        name="presentMarketValue"
                        className={styles.marketValueInput}
                        value={newReoProperty.marketValue ?? ""}
                        id="#presentMarketValue"
                        onValueChange={(values: { formattedValue: any; value?: any; }) => {
                            changeNewReoProperty({ ...newReoProperty, marketValue: (values.value.replace(/^0+/, '')) });
                            props.setLoanIsDirty(true);
                        }}
                        thousandSeparator={true}
                        prefix={"$"}
                    />

                </div>
                {isLoading && (<div className="screenLoader"><div className="loader medium"></div></div>)}
            </InfographicSideLayout >
        );
    }

    return <div className="add-reo-property">{addReoPropertyMarkup}</div>;
};

const mapStateToProps = (state: ApplicationState): any => {
    let borrowerName = "Borrower";

    if (state.accountCreation?.borrower) {
        let borrower = state.accountCreation?.borrower;
        borrowerName = borrower?.firstName + " " + borrower?.lastName;
    }

    let coBorrowerName = "CoBorrower";
    let hasCoBorrower;

    if (state.accountCreation?.borrower?.printOnAdditionalBorrowerPage) {
        let coBorrower = state.accountCreation?.coBorrower;
        coBorrowerName = coBorrower?.firstName + " " + coBorrower?.lastName;
        hasCoBorrower = true;
    }

    return {
        loanGuid: state.accountInformation?.loanGuid,
        borrowerName: borrowerName,
        coBorrowerName: coBorrowerName,
        hasCoBorrower: hasCoBorrower,
        ...state.borrowerReo,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    addReoProperty: (newReoProperty: ReoProperty) => {
        dispatch(BorrowerReoActionCreators.addReoProperty(newReoProperty));
        dispatch(TaskMenuCreators.setLoanIsDirty(true));
    },
    setLoanIsDirty: (isDirty: boolean) => {
        dispatch(TaskMenuCreators.setLoanIsDirty(isDirty));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddReoProperty as any);