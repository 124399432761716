import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import * as PropertyInformationActions from "../../actions/propertyInformationActions";
import styles from "./propertyInformation.module.scss";
import { PropertyInformationState } from "../../store/propertyInformationStore";
import { bindActionCreators } from "redux";
import { NumberFormatInput } from "../common/Input/NumberFormatInput";
import TextInput from "../common/Input/TextInput";
import { Rule } from "../constants/validation";
import { isNullOrWhiteSpace } from "../../common/helper/formatHelper";
import { TaskMenuCreators } from "../../actions/taskMenuAction";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

interface PropertyInformationRealtorProps {
    disabledRealtor: (isValid: boolean) => any;
    disabled: boolean | undefined;
}

const PropertyInformationRealtor = (props: PropertyInformationRealtorProps) => {
    const [numbers, setNumbers] = React.useState("");
    const { speak, cancel } = useSpeechSynthesis();
    const actions = {
        ...bindActionCreators(
            {
                ...PropertyInformationActions.propertyInformationActionCreators, ...TaskMenuCreators
            },
            useDispatch()
        ),
    };

    const state: PropertyInformationState = useSelector(
        (state: ApplicationState) => state.propertyInformation as PropertyInformationState
    );

    const handlePhoneInput = (value: string) => {
        setNumbers(value.replace(/-/g, "").replace(/ /, ""))
        actions.updatePropertyInformationField(value, "realtorPhoneNumber")
        actions.setLoanIsDirty(true);
    };

    useEffect(() => {
        let isInvalid = false;
        if ((!isNullOrWhiteSpace(state.realtorEmailAddress) && !Rule.EmailValidation.value.test(String(state.realtorEmailAddress).toLowerCase()))
            || (!isNullOrWhiteSpace(state.realtorPhoneNumber) && state.realtorPhoneNumber && state.realtorPhoneNumber.length < 10)
        ) {
            isInvalid = true;
        } else {
            isInvalid = false;
        }
        props.disabledRealtor(isInvalid);
    }, [state.realtorEmailAddress, state.realtorPhoneNumber]);

     function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }

    function stopSpeech() {
        cancel();
    }

    return (
        <div className="question-row">
            <div className="fullWidthItem">
                <div className="questionItem" onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Tell us about this realtor optional')}>Tell us about this realtor <span className={styles.optionalMsg}>(Optional)</span></div>
                <div className={styles.realtorRow}>
                    <div>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Realtor Company Name')}>Realtor Company Name</label>
                        <input
                            disabled={props.disabled}
                            type="text"
                            value={state.realtorCompany ?? ""}
                            name="realtorCompany"
                            onChange={(e) => {
                                actions.updatePropertyInformationField(e.target.value, e.target.name);
                                actions.setLoanIsDirty(true);
                            }}
                        />
                    </div>
                    <div>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Realtor Name')}>Realtor Name</label>
                        <input
                            disabled={props.disabled}
                            type="text"
                            value={state.realtorName ?? ""}
                            name="realtorName"
                            onChange={(e) => {
                                actions.updatePropertyInformationField(e.target.value, e.target.name);
                                actions.setLoanIsDirty(true);
                            }}
                        />
                    </div>
                    <div>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Phone Number')}>Phone Number</label>

                        <NumberFormatInput
                            disabled={props.disabled}                            
                            value={state.realtorPhoneNumber ?? ""}
                            format="###-###-#### ####"
                            name="realtorPhoneNumber"
                            id="#realtorPhoneNumber"
                            onValueChange={(values: { formattedValue: any; value?: any; }) => handlePhoneInput(values.value)}
                        />
                        {(numbers.length > 1 && numbers.length < 10) && <span className={styles.errmsg} onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Invalid Number')}>Invalid number.
                                    </span>}
                    </div>
                    <div>
                        <label onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Email Address')}>Email Address</label>

                        <TextInput
                            disabled={props.disabled}
                            value={state.realtorEmailAddress ?? ""}
                            name="realtorEmailAddress"
                            onChange={(e: any) => {
                                actions.updatePropertyInformationField(e.target.value, e.target.name);
                                actions.setLoanIsDirty(true);
                            }}
                            rule={{
                                pattern: Rule.EmailValidation
                            }}
                        />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyInformationRealtor;
