import { AppThunkAction } from "../store";
import * as ActionTypes from "../common/ActionTypes";
import * as ZipCodeLookup from "../store/ZipCodeLookUpStore";
import { KnownAction } from "../store/ZipCodeLookUpStore";
import * as ZipCodeLookupService from "../services/zipCodeLookupService";

const request = (loanGuid: string): KnownAction => {
    return {
        type: ActionTypes.RequestZipCodeLookup
    };
};

const clear = (): KnownAction => {
    return { type: ActionTypes.ClearExceptionZipCodeLookup };
};

const handleError = (errorMessage: string): KnownAction => {
    return { type: ActionTypes.HandleErrorZipCodeLookup, errorMessage: errorMessage };
};

const receive = (data: ZipCodeLookup.ZipDetail[]): KnownAction => {
   
    return { type: ActionTypes.ReceiveZipCodeLookup, data };
};

const clearZipDetail = (): KnownAction => {
    return { type: ActionTypes.ClearZipDetails };
};

export const zipCodeLookupActionCreators = {
    getZipCodeDetail: (zipCode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        var state = getState();
        if (state.zipCodeLookUp) {
            state.zipCodeLookUp.isLoading = true;
        }
        dispatch(clear());
        dispatch(request(zipCode));
         
        ZipCodeLookupService.getZipCodeDetail(zipCode)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`There was a problem getting Zip code details.`);
                }
                return response.parsedBody as ZipCodeLookup.ZipDetail[];
            })
            .then((data) => {
                dispatch(receive(data));
            })
            .catch((error: Error) => {
                dispatch(handleError(error.message));
               
            });
        if (state.zipCodeLookUp) {
            state.zipCodeLookUp.isLoading = false;
        }
    },
    getZipCodeByCityState: (zipDetail: ZipCodeLookup.ZipDetail): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(clear());
         
        dispatch(request(zipDetail.city));
        ZipCodeLookupService.getZipCodeByCityState(zipDetail)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`There was a problem getting Zip code details.`);
                }
                return response.parsedBody as ZipCodeLookup.ZipDetail[];
            })
            .then((data) => {
                dispatch(receive(data));
            })
            .catch((error: Error) => {
                dispatch(handleError(error.message));
            });
    },
    handleError: (errorMessage: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(handleError(errorMessage));
    },
    clearZipDetails: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch(clearZipDetail());
    }
};
