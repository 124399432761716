import React, { useEffect, useState } from "react";
import ChoiceQuestion from "../common/ChoiceQuestion";
import loanTypeGraphic from "assets/infographics/accountInformation/loan-type.svg";
import purchaseIcon from "assets/icons/accountInformation/purchase.svg";
import refinanceIcon from "assets/icons/accountInformation/refinance.svg";
import { Choice } from "../../interfaces/Question";
import styles from "./AccountInformation.module.scss";

const LoanPurpose = (props: {
    updateLoanPurposeChoice: (value: string) => any, data: any, loanPurpose: any,
    loanPurposeParam: boolean
}) => {
    const [loanPurposeChoices, setLoanPurposeChoices] = useState([]);

    useEffect(() => {
        if (props.data.length > 0) {
            setLoanPurposeChoices(props.data);
        }
    }, [props.data]);

    const loadSvg = (name: string) => {
        switch (name) {
            case 'purchase':
                return purchaseIcon
            case 'refinance':
                return refinanceIcon
        }
    };
    const choices: Choice[] = [];
    loanPurposeChoices.map((res: any) => {
        let choice: Choice = {
            text: res.name,
            icon: loadSvg(res.icon),
            onClick: () => props.updateLoanPurposeChoice(res.name),
            toolTipText: res.description,
            className: props.loanPurpose === res.name ? styles.selected : ""
        };

        choices.push(choice);
    })



    return (
        <ChoiceQuestion
            disabled={false}
            intro="Let's Get Started!"
            question="Are you looking to purchase a new home or refinance an existing property?"
            choices={choices}
            infographic={loanTypeGraphic}
        />
    )
}

export default LoanPurpose;

