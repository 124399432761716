import { Action, Reducer } from 'redux';
import * as ActionTypes from "../common/ActionTypes";
import { AdditionalIncomeState, KnownAction, OtherIncomeSourceDetail } from "../store/additionalIncomeStore"

const unloadedState: AdditionalIncomeState = {
    data: {
        otherIncomeSourceDetails: [],
    },
    isLoading: false,
    errorMessage: '',
    validation: undefined
}

export const additionalIncomeReducer: Reducer<AdditionalIncomeState> = (state: AdditionalIncomeState | undefined, incomingAction: Action): AdditionalIncomeState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case ActionTypes.IsLoading: {
            return {
                ...state,
                isLoading: action.isLoading
            }
        };
        case ActionTypes.CreateOtherIncomeSourceItem: {
            if (state.data) {
                let details = state.data.otherIncomeSourceDetails.filter(x => x.owner == action.data.owner && x.description == "");
                if (details.length < 1) {
                    const copyItems = [...state.data.otherIncomeSourceDetails];
                    let additionalIncome = action.data;
                    additionalIncome.isNew = true;
                    copyItems.splice(copyItems.length, 0, additionalIncome);

                    let additionaIIncome = { ...state.data };
                    additionaIIncome.otherIncomeSourceDetails = copyItems;

                    return {
                        ...state, data: additionaIIncome
                    }
                }
            }
            return { ...state };
        };
        case ActionTypes.UpdateOtherIncomeSourceInformation: {
            if (state.data) {
                const otherIncomeSourceDetails = [...state.data.otherIncomeSourceDetails];
                otherIncomeSourceDetails.map((otherIncomeSource: any) => {
                    if (action.otherIncomeSourceId === otherIncomeSource.id) {
                        otherIncomeSource[action.field] = action.value;
                        otherIncomeSource.isNew = action.isNew;
                    }
                    return otherIncomeSource;
                });
                let additionaIIncome = { ...state.data };
                additionaIIncome.otherIncomeSourceDetails = otherIncomeSourceDetails;
                return {
                    ...state,
                    data: additionaIIncome
                }
            }
            return state;
        };
        case ActionTypes.DeleteOtherIncomeSourceItem: {

            if (state.data && state.data.otherIncomeSourceDetails) {
                const copyItems = [...state.data.otherIncomeSourceDetails];
                const index = copyItems.findIndex(i => i.id === action.id);
                copyItems.splice(index, 1);

                let additionaIIncome = state.data;
                additionaIIncome.otherIncomeSourceDetails = copyItems;

                return {
                    ...state, data: { ...additionaIIncome },


                }
            }
            return { ...state };
        };
        case ActionTypes.SetOtherIncomeSourceValidation: {
            let copyValidation = { ...state.validation };
            if (action.validationError) {
                copyValidation[action.field] = action.validationError;
            }
            else {
                if (copyValidation[action.field]) {
                    delete copyValidation[action.field];
                }
            }
            return {
                ...state,
                validation: copyValidation
            };
        }
        case ActionTypes.RemoveOtherIncomeSourceItemList: {
            if (state.data && state.data.otherIncomeSourceDetails) {
                let copyItems = state.data.otherIncomeSourceDetails.filter(x => x.owner !== action.owner).slice();
                let additionaIIncome = state.data;
                additionaIIncome.otherIncomeSourceDetails = copyItems;
                return {
                    ...state,
                    data: { ...additionaIIncome },


                }
            }
            return { ...state };
        };

        case ActionTypes.ReceiveAdditionalIncomeSource: {
            if (state.data) {
                let additionalIncome = action.data as OtherIncomeSourceDetail[];

                let list = { ...state.data }
                list.otherIncomeSourceDetails = additionalIncome;

                return {
                    ...state, data: list
                }
            }
            return { ...state };
        };
        case ActionTypes.UpdateIncomeState: {
            if (state.data && state.data.otherIncomeSourceDetails) {
                let list = { ...state.data }
                list.otherIncomeSourceDetails.filter(x => x.owner === action.borrowerType).slice();
                list.otherIncomeSourceDetails = action.data;

                return {
                    ...state,
                    data: list
                }
            }
        };

        default:
            return state;
    }
}