import React, { useEffect } from "react";
import InfographicSideLayout from "../common/Layout/InfographicSideLayout";
import SelectDropDown from "components/common/Input/SelectDropDown";
import OwnerInfographic from "../../assets/infographics/borrowerReo/owner.svg";
import styles from "./borrowerReo.module.scss";
import { IsNarrator } from "../../common/CommonMethods";
// @ts-ignore
import { useSpeechSynthesis } from "react-speech-kit"

interface ReoPropertyOwnerProps {
  valueChange: (value: string) => void;
  hasCoBorrower: boolean;
  borrowerName?: string;
  coBorrowerName?: string;
  value?: string;
}

const ReoPropertyOwner = (props: ReoPropertyOwnerProps) => {
    const { speak, cancel } = useSpeechSynthesis();

    function textToSpeech(value: any) {
        if (IsNarrator()) {
            speak({ text: value });
        }
    }
    function stopSpeech() {
        cancel();
    }
  useEffect(() => {
      if(!props.value && !props.hasCoBorrower){
        props.valueChange("Borrower");
      }
  }, [props.hasCoBorrower, props.value]);

  return (
      <InfographicSideLayout infographic={OwnerInfographic}>
          <h1 onMouseOut={() => stopSpeech()} onMouseOver={() => textToSpeech('Who owns this property')}>Who owns this property? <span className={styles.requiredMsg}> * </span></h1>
      <SelectDropDown
        name="owner"
        value={props.value ?? ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.valueChange(e.target.value);
        }}
        noArrow={false}
        className={styles.ownerDropdown}
      >
        <option value="" disabled>
          Select...
        </option>
        {props.borrowerName && <option value="Borrower">{props.borrowerName}</option>}
        {props.hasCoBorrower && (
          <>
            <option value="CoBorrower">{props.coBorrowerName}</option>
            <option value="Both">Both</option>
          </>
        )}
      </SelectDropDown>
    </InfographicSideLayout>
  );
};

export default ReoPropertyOwner;
