import * as React from "react";
import DatePicker from "react-datepicker";
import datePickerStyles from "./CustomDatePicker.module.scss";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import dropDownStyles from "../common/Input/SelectDropDown.module.scss";
import MaskedInput from "react-text-mask";

const CustomDatePicker = (props: {
    fieldValue: Date | null | undefined,
    onDateChange: React.Dispatch<React.SetStateAction<any>>,
    className?: string,
    popperPlacement: boolean,
    maxDate?: Date,
    disabled: boolean
}) => {
    const years = range(1900, getYear(new Date()) + 100, 1);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div className={`${datePickerStyles.customDatepicker} ${props.className}`}>
            <DatePicker
                disabled={props.disabled}
                placeholderText="mm/dd/yyyy"
                dateFormat="MM/dd/yyyy"
                popperPlacement={props.popperPlacement ? "top-start" : "auto"}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled
                }) => (
                        <div className={datePickerStyles.datePickerHeader}>
                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                            </button>
                            <div className={`${dropDownStyles.selectDropDown} select ${datePickerStyles.datepickerYears}`}>
                                <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) => changeYear(Number(value))}
                                >
                                    {years.map((option: any) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={`${dropDownStyles.selectDropDown} select ${datePickerStyles.datepickerMonth}`}>
                                <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                            </button>
                        </div>
                    )}
                selected={props.fieldValue}
                className={`${datePickerStyles.input}`}
                onChange={(date: any, e: any) => {
                    props.onDateChange(e.target.value ? e.target.value : date);
                    setIsOpen(false);
                }}
                open={isOpen}
                onClickOutside={() => setTimeout(() => {
                    setIsOpen(false)
                }, 100)}
                customInput={
                    <MaskedInput
                        guide={false}
                        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                        onInput={(e: any) => {
                            if (e.target.value) {
                                if (e.target.value.split("/").length <= 2 ||
                                    (e.target.value.split("/").length === 3 && e.target.value.split("/")[2].length <= 4)) {
                                    props.onDateChange(e.target.value);
                                }
                            }
                        }}
                    />
                }
                maxDate={props.maxDate}
            />
            <i className={`${datePickerStyles.calendarIcon} fa fa-calendar`} aria-hidden="true"
                onClick={() => !props.disabled ? setIsOpen(!isOpen) : setIsOpen(isOpen)}
            ></i>
        </div >
    );
};

export default CustomDatePicker;